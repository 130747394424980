const tiposMonedas = [
  {
    nombre: 'Pesos',
    moneda: 'COP',
    simbolo: '$',
    isExportacion: true,
    defaultExportacion: true,
    default: true,
  },
  {
    nombre: 'Dolar',
    moneda: 'USD',
    simbolo: '$',
    isExportacion: false,
  },
  {
    nombre: 'Euro',
    moneda: 'EUR',
    simbolo: '€',
    isExportacion: false,
  },
];

export default tiposMonedas;
