import * as React from "react";
import Modal from "components/modal/ModalConfirmacion";
import Input from "components/Inputs/Input";
import TextArea from "components/Inputs/TextArea";
import SelectConceptosNotas from "./SelectConceptosNotas";
import FacturasReferenciaTable from "./FacturasReferenciaTable";
import { consultarDocumentoPrefijoNumero } from "network/Api/DocumentoSoporte";
// import { now } from "utils/DateUtils";

const AddDocumentoReferencia = (props) => {
  const { nombreTipo, tipoDocumento, doc, setDocumentoRef, errores = [] } = props;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setDocumentoRef({ [name]: getValue(name, value) });
  };

  const getValue = (name, value) => {
    if (name === "prefijo") return value.toUpperCase();
    if (name === "numero") {
      const newValue = value.replace(/^[a-zA-Z À-ÿ\u00f1\u00d1]*$/g, "");
      if (parseInt(newValue) <= 0) return 1;
      else return newValue;
    }
    return value;
  };

  const getError = (tag) => (errores.find((error) => error.tag === tag) || {}).error;

  return (
    <div>
      <div className="form-row">
        <div className="form-group col-md-12 col-xl-6 mb-0">
          <Input name="prefijo" label="Prefijo *" value={doc.prefijo} onChange={handleChange} />
        </div>
        <div className="form-group col-md-12 col-xl-6 mb-0">
          <Input name="numero" label="# Documento *" value={doc.numero} onChange={handleChange} error={getError("numero")} />
        </div>
      </div>
      <div className="form-row col-md-12 d-flex justify-content-between">
        <div className="form-group col-xl-6 col-sm-12 mt-2" />
        <div className="form-group col-xl-6 col-sm-12">
          <SelectConceptosNotas
            nombreTipo={nombreTipo}
            setDocumentoRef={setDocumentoRef}
            tipoDocumento={tipoDocumento}
            value={doc["conceptoNotaCredito" || "conceptoNotaDebito"]}
            error={getError("conceptoNotaCredito") || getError("conceptoNotaDebito")}
          />
        </div>
      </div>
      <div className="form-group col-md-12 mt-1">
        <TextArea
          label="Descripción *"
          style={{ height: "3.46rem" }}
          maxLength="100"
          rows="2"
          name="descripcion"
          value={doc.descripcion}
          onChange={handleChange}
          sinSalto={true}
          error={getError("descripcion")}
        />
      </div>
    </div>
  );
};

const DocumentosReferencias = (props) => {
  const { tipoDocumento, facturasReferencia = [], setDocumento, isDocumentoSoporte, emisor, isDocumentoExpress, ...rest } = props;
  const [modalState, setModalState] = React.useState({});
  const [doc, setDoc] = React.useState({});

  const setModal = (data) => setModalState((ms) => ({ ...ms, ...data }));

  const setDocumentoRef = (data) => setDoc((dd) => ({ ...dd, ...data }));

  React.useEffect(() => {
    if (tipoDocumento.includes("NOTA_") && !isDocumentoExpress) {
      setModal({ open: true });
      setDocumentoRef({
        [tipoDocumento.includes("CREDITO") ? "conceptoNotaCredito" : "conceptoNotaDebito"]: "OTROS",
        descripcion: "Anulacion Documento",
      });
    }
  }, [tipoDocumento]);

  const closeModal = () => setModal({ open: false, errores: [] });

  const getNombre = () => `nota ${tipoDocumento.includes("CREDITO") ? "crédito" : "debito"}`;

  const limpiarFormulario = () => {
    setDoc({
      [tipoDocumento.includes("CREDITO") ? "conceptoNotaCredito" : "conceptoNotaDebito"]: "OTROS",
    });
    closeModal();
  };

  const agregarReferencia = () => {
    const errores = validarReferencia();
    setModal({ errores });
    if (errores.length === 0) {
      setDocumento({
        facturasReferencia: [...facturasReferencia, doc],
      });
      setDoc({
        [tipoDocumento.includes("CREDITO") ? "conceptoNotaCredito" : "conceptoNotaDebito"]: "OTROS",
      });
    }
  };

  const validarReferencia = () => {
    const errores = [];
    const addError = (tag, error) => errores.push({ tag, error });
    const { numero, conceptoNotaCredito, conceptoNotaDebito, descripcion } = doc;
    if (!numero) addError("numero", "El numero del documento es obligatorio");
    if (tipoDocumento === "NOTA_CREDITO" && !conceptoNotaCredito) addError("conceptoNotaCredito", "El concepto es obligatorio");
    if (tipoDocumento === "NOTA_DEBITO" && !conceptoNotaDebito) addError("conceptoNotaDebito", "El concepto es obligatorio");
    if (isDocumentoSoporte && !descripcion) addError("descripcion", "La descripcion es obligatoria");
    return errores;
  };

  const agregarReferenciaSoporte = () => {
    const { prefijo, numero } = doc;
    const errores = validarReferencia();
    setModal({ errores });
    if (errores.length === 0) {
      setDocumento({ dsPrefijo: "SNC" });
      consultarDocumentoPrefijoNumero({ emisor, prefijo, numero }).then(({ data }) => {
        if (data) {
          const { dsJsonDocumento } = data;
          const jsonDocumento = JSON.parse(dsJsonDocumento);
          const {
            identificacionVendedor,
            digitoVerificacionVendedor,
            tipoOperacion,
            identificadorTributarioVendedor,
            nombresVendedor,
            segundoNombre,
            primerApellido,
            segundoApellido,
            ciudadVendedor,
            direccionVendedor,
            telefonoVendedor,
            emailVendedor,
            regimenVendedor,
            responsabilidadesFiscales,
            tipoPersonaVendedor,
            tipoIdentificacionVendedor,
            VendedorResponsable,
          } = jsonDocumento;
          setDocumento({
            identificacionVendedor,
            digitoVerificacionVendedor,
            tipoOperacion,
            identificadorTributarioVendedor,
            nombresVendedor,
            segundoNombre,
            primerApellido,
            segundoApellido,
            ciudadVendedor,
            direccionVendedor,
            telefonoVendedor,
            emailVendedor,
            regimenVendedor,
            responsabilidadesFiscales,
            tipoPersonaVendedor,
            tipoIdentificacionVendedor,
            VendedorResponsable,
          });
        }
        agregarReferencia();
        limpiarFormulario();
      });
    }
  };

  const botones = [{ onClick: agregarReferencia, name: "agregar" }, { onClick: limpiarFormulario, name: "cerrar" }];

  const botonesSoporte = [{ onClick: agregarReferenciaSoporte, name: "agregar" }, { onClick: () => (window.location.href = "Home"), name: "cerrar" }];

  return (
    <Modal
      open={modalState.open}
      title={`Documento referencia ${getNombre()}`}
      content={
        <>
          <AddDocumentoReferencia
            nombreTipo={getNombre()}
            tipoDocumento={tipoDocumento}
            setDocumentoRef={setDocumentoRef}
            doc={doc}
            errores={modalState.errores}
            {...rest}
          />
          <FacturasReferenciaTable facturasReferencia={facturasReferencia} tipoDocumento={tipoDocumento} setDocumento={setDocumento} />
        </>
      }
      fullWidth
      maxWidth="md"
      scroll="paper"
      aria-labelledby="form-dialog-title"
      extraButtons={!isDocumentoSoporte ? botones : botonesSoporte}
    />
  );
};

export default DocumentosReferencias;
