import React from 'react';
import { now, DATE_FORMAT } from 'utils/DateUtils';

const fechaActual = now().format(DATE_FORMAT);

const useFiltroFechas = () => {
  const [feInicio, setFeInicio] = React.useState(fechaActual);
  const [feFin, setFeFin] = React.useState(fechaActual);

  const updateFechas = (inicio, fin) => {
    setFeInicio(inicio);
    setFeFin(fin);
  };

  return { feInicio, feFin, updateFechas };
};

export default useFiltroFechas;
