import React, { useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from 'components/molecules/Input';
import Typography from 'components/atoms/Typography';

function BeneficiosComprador({ documento, setDocumento, ...rest }) {
  const beneficios = useMemo(
    () => documento.beneficiosComprador || {},
    [documento.beneficiosComprador],
  );

  useEffect(() => {
    setBeneficios({ nombresApellidos: documento.nombresAdquiriente });
  }, [documento.nombresAdquiriente]);

  const setBeneficios = (data) => {
    setDocumento((doc) => {
      const { beneficiosComprador } = doc;
      return { ...doc, beneficiosComprador: { ...beneficiosComprador, ...data } };
    });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setBeneficios({ [name]: value });
  };

  return (
    <Grid container {...rest}>
      <Grid item lg={12}>
        <Typography variant='labelEncabezado'>Beneficios del Cliente</Typography>
      </Grid>
      <Grid container lg={12}>
        <Input
          className='form-group col-lg-6'
          label='Código*'
          name='codigo'
          value={beneficios.codigo}
          onChange={handleChange}
        />
        <Input
          className='form-group col-lg-6'
          label='Puntos*'
          name='puntos'
          value={beneficios.puntos}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}

export default BeneficiosComprador;
