import * as React from "react";
import DatosDocumento from "components/Documento/DatosDocumento";
import DatosAdicionalesDocumento from "components/Documento/DatosAdicionalesDocumento";

const CompletarDocumento = ({ emisor, documento, setDocumento }) => {
  return (
    <>
      <div className="form-row col-12">
        <div className="form-group col-12">
          <DatosDocumento
            emisor={emisor}
            documento={documento}
            setDocumento={setDocumento}
            esSoloResolucion={true}
          />
        </div>
      </div>
      <div className="form-row col-12">
        <div className="form-group col-12">
          <DatosAdicionalesDocumento
            documento={documento}
            setDocumento={setDocumento}
          />
        </div>
      </div>
    </>
  );
};

export default CompletarDocumento;
