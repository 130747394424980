import * as React from 'react';
import Select from 'components/molecules/Select';
import MedioPago from 'static/enums/tiposMediosPago';
const { useMemo } = React;

function SelectMedioPago({ className, type, ...rest }) {
  const options = useMemo(() => {
    const mapFunction = (m) => ({ label: m.nombre, value: m.codigo });
    switch (type) {
      case 'pos':
        return MedioPago.filter(({ pos }) => pos).map(mapFunction);
      case 'nomina':
        return MedioPago.filter(({ nomina }) => nomina).map(mapFunction);
      case 'documento':
        return MedioPago.filter(({ documento }) => documento).map(mapFunction);
      case 'soporte':
        return MedioPago.filter(({ soporte }) => soporte).map(mapFunction);
      default:
        return MedioPago.map(mapFunction);
    }
  }, []);

  return (
    <div className={className}>
      <Select options={options} {...rest} />
    </div>
  );
}

export default SelectMedioPago;
