import * as React from 'react';
import Button from 'components/atoms/Button';
import { generarTokenAutoRegistro } from 'network/Api/Adquirente';
import QRCode from 'qrcode';
import { downloadImage } from 'utils/BlobUtils';
import { QrcodeOutlined } from '@ant-design/icons';
const { useCallback } = React;

function ButtonClienteQR({ emisor }) {
  const generarToken = useCallback(async () => {
    const { data: token = '' } = await generarTokenAutoRegistro({ id: emisor });
    const url = `${window.location.origin}/registro-adquirente?id=${token}`;
    const qrBase64 = await QRCode.toDataURL(url);
    downloadImage({ href: qrBase64 });
  }, [emisor]);

  return (
    <Button onClick={generarToken}>
      <QrcodeOutlined />
      Generar QR para Registro de Clientes
    </Button>
  );
}

export default ButtonClienteQR;
