import React, { useEffect } from 'react';
import Input from 'components/molecules/Input';
import SelectMoneda from 'components/organisms/SelectMoneda';

function CambioMoneda({ setState, state, totalesFactura, detalles, setDetalles, valorBruto }) {
  useEffect(() => {
    const { valorCambioTrm } = state;
    const { valorNeto = 0, impuestosRetenciones = [] } = totalesFactura;
    let totalPagar = valorNeto;
    impuestosRetenciones.forEach((ir) => {
      let tributo = ir.subtotales[0].tributo;
      if (tributo === 'RETE_ICA' || tributo === 'RETE_FUENTE' || tributo === 'RETE_IVA')
        totalPagar -= ir.valorTotal;
    });
    setState({
      valorNetoOtraMoneda: totalPagar / valorCambioTrm || 0,
      valorBrutoOtraMoneda: valorBruto / valorCambioTrm || 0,
    });
    setDetalles(
      detalles.map((detalle) => {
        const { precioUnitario, valorTotalDescuento, valorTotalArticuloDetalle } = detalle;
        return {
          ...detalle,
          precioUnitarioOtraMoneda: precioUnitario / valorCambioTrm || 0,
          valorTotalDescuentoOtraMoneda: valorTotalDescuento / valorCambioTrm || 0,
          valorTotalArticuloOtraMoneda: valorTotalArticuloDetalle / valorCambioTrm || 0,
        };
      }),
    );
  }, [valorBruto, state.valorCambioTrm, detalles.length]);

  const handleChangeMoneda = ({ value, simbolo }) => {
    setState({ otraMoneda: value, simboloOtraMoneda: simbolo });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  return (
    <div className='form-row col-lg-12'>
      <SelectMoneda
        className='form-group col-lg-3'
        label='Tipo Moneda'
        name='otraMoneda'
        onChange={handleChangeMoneda}
        value={state.otraMoneda}
      />
      <Input
        className='form-group col-lg-3'
        label='Simbolo moneda'
        name='simboloOtraMoneda'
        value={state.simboloOtraMoneda}
        readOnly
      />
      <Input
        className='form-group col-lg-3'
        label='Tasa de cambio'
        name='valorCambioTrm'
        value={state.valorCambioTrm}
        onChange={handleChange}
        validacion='only-num-decimals'
        disabled={!state.otraMoneda}
      />
      <Input
        className='form-group col-lg-3'
        label='Monto'
        name='valorNetoOtraMoneda'
        value={state.valorNetoOtraMoneda}
        readOnly
      />
    </div>
  );
}

export default CambioMoneda;
