import React, { useCallback } from 'react';
import { Row } from 'antd';
import { getPorcentajeTributo } from 'utils/DocumentoUtils';
import { Table, TableRow, TableCell } from '@material-ui/core';
import { TableHead, TableBody } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  cellHead: {
    backgroundColor: theme.palette.primary.main,
    border: '5px solid #fff',
    color: '#fff',
  },
  table: {
    marginTop: '50px',
  },
});

const Detalles = ({ detalles = [], classes, documento }) => {
  const getPorcentaje = useCallback(
    (tributos = [], nombreTributo) => getPorcentajeTributo(tributos, nombreTributo),
    [detalles],
  );

  const getValorDescuento = useCallback(
    (cargosDescuentos) => {
      const descuento = cargosDescuentos && cargosDescuentos[0];
      return descuento ? descuento.valor : 0;
    },
    [detalles],
  );
  return (
    <Row>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHead}>Nro</TableCell>
            <TableCell className={classes.cellHead}>Código</TableCell>
            <TableCell className={classes.cellHead}>Descripción</TableCell>
            <TableCell className={classes.cellHead}>U.M.</TableCell>
            <TableCell className={classes.cellHead}>Cantidad</TableCell>
            {documento.tipoDocumentoElectronico === 'EXPORTACION' && (
              <TableCell className={classes.cellHead}>
                Valor Unitario {documento.otraMoneda}
              </TableCell>
            )}
            <TableCell className={classes.cellHead}>Valor Unitario COP</TableCell>
            {documento.tipoDocumentoElectronico === 'VENTA' && (
              <TableCell className={classes.cellHead}>% IVA</TableCell>
            )}
            {documento.tipoDocumentoElectronico === 'VENTA' && (
              <TableCell className={classes.cellHead}>% INC</TableCell>
            )}
            {documento.tipoDocumentoElectronico === 'EXPORTACION' && (
              <TableCell className={classes.cellHead}>Descuento {documento.otraMoneda}</TableCell>
            )}
            <TableCell className={classes.cellHead}>Descuento COP</TableCell>
            <TableCell className={classes.cellHead}>Otros impuestos COP</TableCell>
            {documento.tipoDocumentoElectronico === 'EXPORTACION' && (
              <TableCell className={classes.cellHead}>Valor Total {documento.otraMoneda}</TableCell>
            )}
            <TableCell className={classes.cellHead}>Valor Total COP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {detalles.map((detalle, key) => (
            <TableRow key={key}>
              <TableCell>{parseInt(key) + 1}</TableCell>
              <TableCell>{detalle.codigoArticulo}</TableCell>
              <TableCell>{detalle.descripcionArticulo}</TableCell>
              <TableCell>{detalle.unidadMedida}</TableCell>
              <TableCell>{detalle.cantidad}</TableCell>
              {documento.tipoDocumentoElectronico === 'EXPORTACION' && (
                <TableCell>{detalle.precioUnitarioOtraMoneda}</TableCell>
              )}
              <TableCell>{detalle.precioUnitario}</TableCell>
              {documento.tipoDocumentoElectronico === 'VENTA' && (
                <TableCell>{getPorcentaje(detalle.impuestosRetenciones, 'IVA')}</TableCell>
              )}
              {documento.tipoDocumentoElectronico === 'VENTA' && (
                <TableCell>{getPorcentaje(detalle.impuestosRetenciones, 'INC')}</TableCell>
              )}
              {documento.tipoDocumentoElectronico === 'EXPORTACION' && (
                <TableCell>{detalle.valorTotalDescuentoOtraMoneda}</TableCell>
              )}
              <TableCell>{getValorDescuento(detalle.cargosDescuentos)}</TableCell>
              <TableCell>{detalle.otroImpuestoTotal}</TableCell>
              {documento.tipoDocumentoElectronico === 'EXPORTACION' && (
                <TableCell>{detalle.valorTotalArticuloOtraMoneda}</TableCell>
              )}
              <TableCell>{detalle.valorTotalArticuloDetalle}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Row>
  );
};

export default withStyles(styles)(Detalles);
