export const styles = (theme) => ({
  tableHeader: {},
  titleHeader: {
    fontWeight: 'bold',
    fontSize: '.9rem',
  },
  tablePagination: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
  },
});
