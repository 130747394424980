export const styles = () => ({
  filter: { display: "flex" },
  filterItem: { padding: "0px 5px" },
  gridContainer: {
    display: "grid",
    columnGap: ".5%",
  },
  contenedorSolicitud: { display: "flex" },
  infoSolicitud: { width: "80%" },
  contenedorAdjuntos: { padding: "10px 0px" },
  allAdjuntos: { display: "flex", overflow: "auto" },
  adjunto: {
    margin: "10px",
    borderRadius: "3px",
    width: "156px",
    height: "125px",
    border: "1px solid #000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: ".5s",
    backgroundColor: "rgb(238, 238, 238)",
    "&:hover": {},
  },
  buttonsFiles: { display: "flex", justifyContent: "flex-end" },
  buttonAdjunto: {
    borderRadius: "3px",
    margin: "3px",
    border: "none",
    backgroundColor: "rgb(158, 158, 158)",
    color: "rgb(33, 33, 33)",
    width: "40px",
    height: "40px",
  },
  fileNameContainer: {
    borderTop: "1px solid #000",
    backgroundColor: "#FFF",
    textDecoration: "none",
    // "&:hover": { textDecoration: "none" },
  },
});
