const planesPrepago = [
  {
    id: 74,
    valorIndividual: "$ 1.000",
    valor: "$ 50.000 ->",
    valorPlan: 230000,
    cantidadFacturas: " 50 documentos",
    total: "$ 230.000",
    redirect: "https://portalfactura.com/nuevo-emisor?codigoPlan=74",
    dsDescripcion: "XS",
  },
  {
    id: 75,
    valorIndividual: "$ 920",
    valor: "$ 92.000 ->",
    valorPlan: 272000,
    cantidadFacturas: "100 documentos",
    total: "$ 272.000",
    redirect: "https://portalfactura.com/nuevo-emisor?codigoPlan=75",
    dsDescripcion: "S",
  },
  {
    id: 76,
    valorIndividual: "$ 840",
    valor: "$ 168.000 ->",
    valorPlan: 348000,
    cantidadFacturas: "200 documentos",
    total: "$ 348.000",
    redirect: "https://portalfactura.com/nuevo-emisor?codigoPlan=76",
    dsDescripcion: "M",
  },
  {
    id: 77,
    valorIndividual: "$ 760",
    valor: "$ 380.000 ->",
    valorPlan: 560000,
    cantidadFacturas: "500 documentos",
    total: "$ 560.000",
    redirect: "https://portalfactura.com/nuevo-emisor?codigoPlan=77",
    dsDescripcion: "L",
  },
  {
    id: 78,
    valorIndividual: "$ 680",
    valor: "$ 680.000 ->",
    valorPlan: 860000,
    cantidadFacturas: "1000 documentos",
    total: "$ 860.000",
    redirect: "https://portalfactura.com/nuevo-emisor?codigoPlan=78",
    dsDescripcion: "XL",
  },
];

export default planesPrepago;
