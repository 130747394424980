import * as React from 'react';
import { connect } from 'react-redux';
import TablaGenerica from 'components/Tablas/GenericaOrdenadaColumna';
import { consultarDocumentosPorEmisor, regenerarPdfDocumentoPos } from 'network/Api/DocumentoPos';
import { moneyFormat } from 'utils/TextFormattingUtils';
import Button from 'components/atoms/Button';
import Input from 'components/Inputs/Input';
import { now, getDateTimeFromArray, getDateFromArray } from 'utils/DateUtils';
import { exportTableToExcel } from 'utils/ExportarExcelUtil';
import ExportExcelIco from 'static/images/excel.png';
import { anularDocumentoPos } from 'network/Api/DocumentoPos';
import Modal from 'components/modal/ModalConfirmacion';
import TablaExcel from 'components/Tablas/TablaGenerica';
import FormularioCierre from './FormularioCierre';
import ButtonDescargaArchivos from 'components/organisms/ButtonDescargaArchivos';

function DocumentosPosEnviados({ userAttributes }) {
  const [state, setData] = React.useState({
    feInicio: now().subtract(1, 'month').format('YYYY-MM-DD'),
    feFin: now().format('YYYY-MM-DD'),
    primerIntento: true,
    openModalCierre: false,
  });

  React.useEffect(() => consultarDocumentos(), [userAttributes, state.feInicio, state.feFin]);

  const consultarDocumentos = () => {
    const { codigoEmisor } = userAttributes;
    if (codigoEmisor) {
      setState({ loading: true });
      consultarDocumentosPorEmisor({
        id: codigoEmisor,
        feInicio: state.feInicio,
        feFin: state.feFin,
        dsPrefijo: state.dsPrefijo,
        dsNumDoc: state.dsNumDoc,
      }).then((res) => {
        if (res.status)
          setState({
            documentos: res.data.map((d) => ({
              ...d,
              valorNeto: JSON.parse(d.dsJsonDocumento).valorNeto,
              totalImpuesto: sumImpuestos(d.dsJsonDocumento),
            })),
            loading: false,
          });
      });
    }
  };

  const sumImpuestos = (json) => {
    let impuesto = 0;
    JSON.parse(json).detalles.forEach((d) => (impuesto += d.valorTotalImpuestosRetenciones));
    return impuesto;
  };

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  const handleChange = (e) => setState({ [e.target.name]: e.target.value });

  const anularDocumento = (nmId) => {
    openModal({
      content: 'Anulando documento',
      onAccept: undefined,
    });
    anularDocumentoPos({ nmId }).then((res) => {
      if (res.data !== 'false') {
        consultarDocumentos();
        openModal({
          content: 'Documento anulado correctamente',
          onAccept: undefined,
        });
      } else {
        openModal({
          content: 'Error anulando documento',
          onAccept: undefined,
        });
      }
    });
  };

  const closeModal = () => setState({ open: false });

  const openModal = ({ content, onAccept }) => setState({ open: true, content, onAccept });

  const cerrarCaja = () => {
    setState({ openModalCierre: true });
  };

  const cerrarModal = () => setState({ openModalCierre: false });

  const regenerarPdf = async (id) => {
    await regenerarPdfDocumentoPos([id]);
    consultarDocumentos();
  };

  return (
    <div>
      <FormularioCierre
        open={state.openModalCierre}
        userAttributes={userAttributes}
        handleClose={cerrarModal}
      />
      <div className='form-row' style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div className='form-group col-xl-3'>
          <Input
            label='Fecha Inicio'
            value={state.feInicio}
            type='date'
            onChange={handleChange}
            name='feInicio'
          />
        </div>
        <div className='form-group col-xl-3'>
          <Input
            label='Fecha Final'
            value={state.feFin}
            type='date'
            onChange={handleChange}
            name='feFin'
            max={now().format('YYYY-MM-DD')}
          />
        </div>
        <div className='form-group col-xl-3'>
          <Input label='Prefijo' value={state.dsPrefijo} onChange={handleChange} name='dsPrefijo' />
        </div>
        <div className='form-group col-xl-3'>
          <Input
            label='Num. Documento'
            value={state.dsNumDoc}
            onChange={handleChange}
            name='dsNumDoc'
          />
        </div>
        <div className='form-group col-xl-2'>
          <Button onClick={consultarDocumentos}>Consultar</Button>
        </div>
        <div className='form-group col-xl-2'>
          <Button onClick={cerrarCaja}>Cerrar Día</Button>
        </div>
        <div className='form-group col-xl-2'>
          <Button
            variant='flat'
            aria-label='Descargar Excel'
            onClick={() => exportTableToExcel('tablaExcel', 'documentosEnviados')}
          >
            <img alt='exportarExcel' src={ExportExcelIco} />
          </Button>
        </div>
      </div>
      <TablaGenerica
        loading={state.loading || false}
        headers={[
          { label: 'Resolución', id: 'dsResolucion', disablePadding: true },
          {
            label: 'Tipo Documento',
            id: 'tipoDocumentoElectronico',
            disablePadding: true,
          },
          { label: 'Prefijo', id: 'dsPrefijo', disablePadding: true },
          { label: 'Numero', id: 'dsNumDoc', disablePadding: true },
          {
            label: 'Nombre Cliente',
            id: 'nombresAdquiriente',
            disablePadding: true,
          },
          {
            label: 'Celular',
            id: 'telefonoAdquiriente',
            disablePadding: true,
          },
          {
            label: 'Total Impuestos',
            id: 'totalImpuesto',
            disablePadding: true,
          },
          {
            label: 'Total Documento',
            id: 'valorNeto',
            disablePadding: true,
          },
          {
            label: 'Fecha Documento',
            id: 'feDocumento',
            disablePadding: true,
          },
          { label: 'Descargar PDF', id: 'dsUrlPdf', disablePadding: true },
          {
            label: 'Anular Documento',
            id: 'anularDoc',
            disablePadding: true,
          },
        ]}
        keys={[
          'dsResolucion',
          'tipoDocumentoElectronico',
          'dsPrefijo',
          'dsNumDoc',
          'nombresAdquiriente',
          'telefonoAdquiriente',
          'totalImpuesto',
          'valorNeto',
          'feDocumento',
          'dsUrlPdf',
          'anularDoc',
        ]}
        detalles={(state.documentos || []).map((d) => {
          const {
            nombresAdquiriente,
            telefonoAdquiriente,
            tipoDocumentoElectronico = 'VENTA',
          } = JSON.parse(d.dsJsonDocumento);
          return {
            ...d,
            nombresAdquiriente,
            telefonoAdquiriente,
            tipoDocumentoElectronico,
            valorNeto: moneyFormat(d.valorNeto),
            totalImpuesto: moneyFormat(d.totalImpuesto),
            feDocumento: getDateTimeFromArray(d.feDocumento).format('DD/MM/YYYY HH:mm:ss'),
            dsUrlPdf: (
              <ButtonDescargaArchivos
                text={'Descargar'}
                fileName={d.dsUrlPdf}
                onFailDownload={() => regenerarPdf(d.nmId)}
                onFailDownloadMessage='Error generando archivo. Inténtelo nuevamente en unos minutos.'
              />
            ),
            anularDoc:
              JSON.parse(d.dsJsonDocumento).tipoDocumentoElectronico !== 'NOTA_CREDITO' ? (
                <Button
                  onClick={() =>
                    openModal({
                      onAccept: () => anularDocumento(d.nmId),
                      content: '¿Seguro desea anular el documento?',
                    })
                  }
                  disabled={JSON.parse(d.dsJsonDocumento).snNotaGenerada}
                >
                  Anular
                </Button>
              ) : (
                <></>
              ),
          };
        })}
      />
      <TablaExcel
        id='tablaExcel'
        withPagination={false}
        tableStyle={{ display: 'none' }}
        headers={[
          'Resolucion',
          'Tipo Documento',
          'Prefijo',
          'Numero',
          'Nombre Cliente',
          'Celular',
          'Total Impuestos',
          'Total Documento',
          'Medio Pago',
          'Fecha Documento',
        ]}
        keys={[
          'dsResolucion',
          'tipoDocumentoElectronico',
          'dsPrefijo',
          'dsNumDoc',
          'nombresAdquiriente',
          'telefonoAdquiriente',
          'totalImpuesto',
          'valorNeto',
          'medioPago',
          'feDocumento',
        ]}
        detalles={(state.documentos || []).map((d) => {
          const {
            nombresAdquiriente,
            telefonoAdquiriente,
            tipoDocumentoElectronico,
            pago = {},
          } = JSON.parse(d.dsJsonDocumento);
          return {
            ...d,
            nombresAdquiriente,
            telefonoAdquiriente,
            tipoDocumentoElectronico: tipoDocumentoElectronico || 'VENTA',
            feDocumento: getDateFromArray(d.feDocumento).format('DD/MM/YYYY HH:mm:ss'),
            totalImpuesto: (d.totalImpuesto + '').replace('.', ','),
            valorNeto: (d.valorNeto + '').replace('.', ','),
            medioPago: pago.medioPago,
          };
        })}
      />
      <Modal
        open={state.open}
        content={state.content}
        onAccept={state.onAccept}
        onCancel={closeModal}
      />
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentosPosEnviados);
