import React, { useEffect, useState } from "react";
import Tabla from "../../../Tablas/TablaGenerica";
import Button from 'components/atoms/Button';
import Checkbox from 'components/molecules/Checkbox';
import SelectTipoMoneda from "../../../Inputs/SelectTipoMoneda";
import Select from "../../../Inputs/Select";
import SelectTipoContrato from "../../../Inputs/SelectTiposContratos";
import Input from "../../../Inputs/Input";
import * as DateUtils from "../../../../utils/DateUtils";
import * as TextFormattingUtils from "../../../../utils/TextFormattingUtils";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import {
  getContratos,
  guardarContrato,
  actualizarContrato,
} from "network/Api/Contrato";

export default function contrato({ empleado, openModal }) {
  const [contratos, setContratos] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [contrato, setContrato] = useState({
    cdEstado: { label: "ACTIVO", value: "A" },
    cdSalarioIntegral: false,
    cdMoneda: { label: "Pesos", value: "COP" },
    feInicio: DateUtils.now().format("YYYY-MM-DD"),
  });

  const body = {
    ...contrato,
    empleado: { nmId: empleado && empleado.nmId },
    cdTipoContrato: contrato.cdTipoContrato && contrato.cdTipoContrato.value,
    cdMoneda: contrato.cdMoneda && contrato.cdMoneda.value,
    cdEstado: contrato.cdEstado && contrato.cdEstado.value,
  };

  const guardar = () => {
    let errores = validarFormulario(body);
    if (errores.length === 0) {
      guardarContrato(body).then((res) => {
        if (res.status === 200) {
          consultarContratos();
          clearForm();
        } else openModal("No fue posible guardar contrato");
      });
    } else {
      openModal(errores.join(", "));
    }
  };

  useEffect(() => consultarContratos(), [empleado]);

  const consultarContratos = () => {
    setLoadingTable(true);
    if (empleado)
      getContratos(empleado.nmId).then((res) => {
        if (res.status === 200) setDataTable(res.data);
      });
    else setDataTable([]);
  };

  const setDataTable = (data) => {
    setContratos(data);
    setLoadingTable(false);
  };

  const setData = (data) => setContrato({ ...contrato, ...data });

  const removerContrato = (contrato) => {
    actualizarContrato({
      ...contrato,
      empleado: { nmId: contrato.empleado.nmId },
      cdEstado: "I",
      feInicio: contrato.feInicio && DateUtils.formatDate(contrato.feInicio),
      feFin: DateUtils.formatDate(new Date()),
      feProceso: null,
    }).then((res) => {
      if (res.status === 200) {
        consultarContratos();
      } else {
        openModal("No se pudo inactivar el contrato");
      }
    });
  };

  const clearForm = () => {
    setContrato({
      cdEstado: { label: "ACTIVO", value: "A" },
      cdTipoContrato: {},
      cdMoneda: { label: "Pesos", value: "COP" },
      cdSalarioIntegral: false,
    });
  };

  return (
    <div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <SelectTipoContrato
            label="Tipo contrato *"
            onChange={(e) => setData({ cdTipoContrato: e })}
            value={contrato.cdTipoContrato || ""}
          />
        </div>
        <div className="form-group col-xl-1">
          <SelectTipoMoneda
            label="Moneda *"
            onChange={(e) => setData({ cdMoneda: e })}
            value={body.cdMoneda || ""}
          />
        </div>
        <div className="form-group col-xl-2">
          <Input
            label="Sueldo *"
            value={contrato.nmSueldoVisual || ""}
            onChange={(e) =>
              setData({
                nmSueldoVisual: e.target.value,
                nmSueldo: e.target.value,
              })
            }
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.numeros)}
            onBlur={() =>
              setData({
                nmSueldoVisual: TextFormattingUtils.decimalFormat(
                  contrato.nmSueldo
                ),
              })
            }
          />
        </div>
        <div className="form-group col-xl-3">
          <Input
            label="Fecha ingreso *"
            type="date"
            value={contrato.feInicio || ""}
            onChange={(e) => setData({ feInicio: e.target.value })}
          />
        </div>
        <div className="form-group col-xl-3">
          <Select
            label="Estado"
            options={[
              { label: "ACTIVO", value: "A" },
              { label: "INACTIVO", value: "I" },
            ]}
            value={contrato.cdEstado}
            onChange={(e) => setData({ cdEstado: e })}
          />
        </div>
        <div
          className="form-group col-xl-1"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Checkbox
            checked={contrato.cdSalarioIntegral || ""}
            onChange={(_) =>
              setData({ cdSalarioIntegral: !contrato.cdSalarioIntegral })
            }
            label="Es salario integral"
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button onClick={guardar} disabled={!empleado}>
            Guardar Contrato
          </Button>
        </div>
      </div>
      {contratos.length > 0 && (
        <Tabla
          loading={loadingTable}
          headers={[
            "Empleado",
            "Tipo Contrato",
            "Salario Integral",
            "Sueldo",
            "Moneda",
            "Fecha Inicio",
            "Fecha Fin",
            "Estado del contrato",
            "",
          ]}
          keys={[
            "nombreEmpleado",
            "cdTipoContrato",
            "cdSalarioIntegral",
            "nmSueldo",
            "cdMoneda",
            "feInicio",
            "feFin",
            "cdEstado",
            "btn",
          ]}
          detalles={contratos.map((contrato) => ({
            ...contrato,
            nombreEmpleado: `${contrato.empleado.dsNombre} ${
              contrato.empleado.dsSegundoNombre
            } ${contrato.empleado.dsPrimerApellido} ${
              contrato.empleado.dsSegundoApellido
            }`,
            feInicio: DateUtils.formatDate(
              DateUtils.getDateFromArray(contrato.feInicio)
            ),
            feFin: contrato.feFin
              ? DateUtils.formatDate(DateUtils.getDateFromArray(contrato.feFin))
              : "",
            cdSalarioIntegral: contrato.cdSalarioIntegral
              ? "Salario Integral"
              : "Salario No Integral",
            nmSueldo: TextFormattingUtils.moneyFormat(contrato.nmSueldo),
            cdEstado: contrato.cdEstado === "A" ? "ACTIVO" : "INACTIVO",
            btn: (
              <Button
                onClick={() => removerContrato(contrato)}
                disabled={contrato.cdEstado === "I"}
              >
                <DeleteRoundedIcon />
              </Button>
            ),
          }))}
        />
      )}
    </div>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.empleado.nmId) errores.push("Por favor selecciona un empleado");
  if (!body.cdTipoContrato) errores.push("El tipo de contrato es obligatorio");
  if (!body.cdMoneda) errores.push("La moneda es obligatoria");
  if (!body.nmSueldo || body.nmSueldo < 1)
    errores.push("El salario es obligatorio o no puede ser menor a cero");
  if (!body.feInicio) errores.push("La fecha de inicio es obligatoria");
  return errores;
}
