import * as React from "react";
import Modal from "components/modal/ModalConfirmacion";
import SelectProductos from "components/Inputs/SelectProductos";
import Input from "components/Inputs/Input";
import Checkbox from 'components/molecules/Checkbox';
import AgregarDescuentos from "./AgregarDescuentos";
import { consultarProductoPorId, crearActualizarProducto } from "network/Api/Producto";
import Obsequio from "./../ModalAddDetalle/Obsequio";
import InfoExportacion from "./../ModalAddDetalle/InfoExportacion";
import LoaderScreen from "components/Loaders/LoadingScreen";
import SelectUnidadMedida from "components/Inputs/SelectMedida";
import SelectPorcentajes from "components/Inputs/SelectPorcentajesImpuestos";

const AddDetalle = (props) => {
  const { emisor, setDetalle, detalle, isExportacion, errores, guardarProducto, isSoporte } = props;
  const [state, setDataState] = React.useState({});

  const setState = (data) => setDataState((s) => ({ ...s, ...data }));

  React.useEffect(() => {
    const valorTotalBruto = parseFloat(detalle.precioUnitario) * parseFloat(detalle.cantidad) || 0;
    if (parseFloat(valorTotalBruto) !== parseFloat(detalle.valorTotalBruto)) setDetalle({ valorTotalBruto });
  }, [detalle.cantidad, detalle.precioUnitario]);

  const handleChangeProducto = ({ value }) => {
    setState({ loader: true });
    consultarProductoPorId({ id: value }).then(({ data }) => {
      const { snIvaIncluido, nmValorUnitario, nmPorcentajeIva } = data;
      setState({
        precioReferenciaDefault: data.dsPrecioReferencia,
        valorUnitarioBase: nmValorUnitario,
        loader: false,
        snIvaIncluido,
      });
      setDetalle({
        cantidad: 1,
        precioUnitario: snIvaIncluido ? parseFloat(nmValorUnitario / (1 + nmPorcentajeIva / 100)).toFixed(2) : nmValorUnitario,
        descripcionArticulo: data.dsDescripcion,
        unidadMedida: data.nmUnidadMedida,
        codigoArticulo: data.dsCodigo,
        porcentajeIva: nmPorcentajeIva,
        porcentajeConsumo: data.nmPorcentajeInc,
        cargoTotal: 0,
      });
    });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setDetalle({ [name]: value });
  };

  const handleChangeObsequio = ({ target }) => {
    const { checked } = target;
    setState({ precioAnterior: checked ? detalle.precioUnitario : 0 });
    setDetalle({
      esObsequio: checked,
      precioUnitario: checked ? 0 : state.precioAnterior,
    });
  };

  const handleChangeSinIva = ({ target }) => {
    const { checked } = target;
    setState({ ivaAnterior: checked ? detalle.porcentajeIva : 0 });
    setDetalle({
      porcentajeIva: checked ? 0 : state.ivaAnterior,
      esSinIva: checked,
    });
  };

  const getError = (tag) => (errores.find((error) => error.tag === tag) || {}).err;

  const validarDecimal = ({ target }) => {
    const { value, name } = target;
    if (!/^[0-9]+([.][0-9]+)?$/.test(value)) setDetalle({ [name]: 0 });
    else {
      if (parseFloat(value) < 0) setDetalle({ [name]: 0 });
    }
  };

  const recalcularValorIvaIncluido = ({ value }) => {
    const { porcentajeIva } = detalle;
    setDetalle({
      precioUnitario: calcularPrecioUnitarioIva(value, porcentajeIva),
    });
    setState({ valorUnitarioBase: value });
  };

  const calcularPrecioUnitarioIva = (value, porcentaje) => parseFloat(value / (1 + porcentaje / 100)).toFixed(2);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="form-row col-md-12">
        {guardarProducto && (
          <div className="form-group col-md-12">
            <SelectProductos emisor={emisor} label="Producto" soloActivos={true} isExportacion={false} onChange={handleChangeProducto} />
          </div>
        )}
        <div className="form-group col-md-6">
          {!guardarProducto ? (
            <SelectProductos emisor={emisor} label="Producto *" isExportacion={false} onChange={handleChangeProducto} soloActivos={true} />
          ) : (
            <Input
              label="Codigo Producto"
              name="codigoArticulo"
              maxLength="50"
              value={detalle.codigoArticulo}
              onChange={handleChange}
              error={getError("codigoArticulo")}
            />
          )}
        </div>
        <div className="form-group col-md-2 col-xl-2">
          <Input
            label="Cantidad *"
            name="cantidad"
            onChange={handleChange}
            onBlur={(e) => {
              validarDecimal(e);
            }}
            min={0}
            value={detalle.cantidad}
            error={getError("cantidad")}
          />
        </div>
        <div className="form-group col-md-2 col-xl-2">
          <Input
            label="Valor unitario *"
            name="precioUnitario"
            onChange={handleChange}
            onBlur={(e) => {
              validarDecimal(e);
              recalcularValorIvaIncluido(e.target);
            }}
            value={detalle.precioUnitario}
            error={getError("precioUnitario")}
          />
        </div>
        <div className="custom-control custom-checkbox text-left col-md-2" style={{ marginTop: "30px" }}>
          <input type="checkbox" disabled={true} checked={state.snIvaIncluido} id="snIvaIncluido" style={{ margin: "5px" }} />
          <label htmlFor="snIvaIncluido">Iva Incluido</label>
        </div>
      </div>
      <div className="form-group col-md-12">
        <Input
          label="Descripción"
          maxLength="300"
          name="descripcionArticulo"
          onChange={handleChange}
          value={detalle.descripcionArticulo}
          error={getError("descripcionArticulo")}
        />
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-md-4 col-xl-4">
          <SelectUnidadMedida
            label="Unidad de medida *"
            isDisabled={!guardarProducto}
            name="unidadMedida"
            onChange={({ value }) => setDetalle({ unidadMedida: value })}
            value={detalle.unidadMedida}
            error={getError("unidadMedida")}
          />
        </div>
        <div className="form-group col-md-4 col-xl-4">
          <SelectPorcentajes
            label="Porcentaje IVA"
            isDisabled={!guardarProducto}
            name="porcentajeIva"
            onChange={({ value }) => {
              setDetalle({
                porcentajeIva: value,
                precioUnitario: calcularPrecioUnitarioIva(state.valorUnitarioBase, value),
              });
            }}
            value={detalle.porcentajeIva}
            error={getError("porcentajeIva")}
            tributo="IVA"
          />
        </div>
        <div className="form-group col-md-4 col-xl-4">
          <SelectPorcentajes
            label="Porcentaje INC"
            isDisabled={!guardarProducto || isSoporte}
            name="porcentajeConsumo"
            onChange={({ value }) => setDetalle({ porcentajeConsumo: value })}
            value={detalle.porcentajeConsumo}
            error={getError("porcentajeConsumo")}
            tributo="INC"
          />
        </div>
      </div>
      <div className="form-row col-md-12" style={{ padding: "10px" }}>
        <div className="form-group col-md-4 col-xl-4">
          <label className="text-muted">Seleccione si es:</label>
          <Checkbox label="Obsequio" id="esObsequio" onChange={handleChangeObsequio} />
          <Checkbox label="Día sin IVA" id="esSinIva" onChange={handleChangeSinIva} />
        </div>
        <Obsequio esObsequio={detalle.esObsequio} setDetalle={setDetalle} precioReferenciaDefault={state.precioReferenciaDefault} errores={errores} />
      </div>
      {isExportacion && <InfoExportacion setDetalle={setDetalle} detalle={detalle} />}
      {!isSoporte && <AgregarDescuentos setDetalle={setDetalle} cargosDescuentos={detalle.cargosDescuentos} valorBase={detalle.valorTotalBruto} />}
      <LoaderScreen open={state.loader} />
    </form>
  );
};

const ModalAddDetalle = ({ modalState, handleClose, addDetalle, guardarProducto, setMessageState, emisor, isSoporte, ...rest }) => {
  const [detalle, setDataDetalle] = React.useState({
    porcentajeIva: 0,
    porcentajeConsumo: 0,
  });
  const [errores, setErrores] = React.useState([]);
  const [modalMensaje, setModalMensaje] = React.useState({});

  const setDetalle = (data) => setDataDetalle((s) => ({ ...s, ...data }));

  const agregarDetalle = () => {
    const errors = validarDetalle();
    if (errors.length === 0) {
      const valorTotalDescuento = getTotalDescuento(detalle.cargosDescuentos);
      const valorBase = detalle.valorTotalBruto - valorTotalDescuento;
      const iva = generarImpRet("IVA", detalle.porcentajeIva);
      const inc = generarImpRet("INC", detalle.porcentajeConsumo);
      const valorTotalImpuestosRetenciones = sumarIvaInc(iva, inc);
      const detalleCompleto = {
        ...detalle,
        valorTotalDescuento,
        valorTotalImpuestosRetenciones,
        valorTotalArticuloDetalle: valorBase + parseFloat(valorTotalImpuestosRetenciones),
        impuestosRetenciones: [iva, inc].filter((ir) => ir),
      };
      addDetalle(detalleCompleto);
      closeModal();
      guardarProducto &&
        setModalMensaje({
          open: true,
          content: "¿Registrar o actualizar producto?",
          onAccept: () => {
            crearActualizarProducto({
              ...detalleCompleto,
              nmIdEmisor: emisor,
            });
            setModalMensaje((s) => ({ ...s, open: false }));
          },
        });
    } else setErrores(errors);
  };

  const validarDetalle = () => {
    const fallos = [];
    const addError = (tag, err) => fallos.push({ tag, err });
    const {
      cantidad,
      precioUnitario,
      descripcionArticulo,
      unidadMedida,
      esObsequio,
      porcentajeIva,
      porcentajeConsumo,
      codigoArticulo,
      obsequio,
    } = detalle;
    if (!cantidad || cantidad <= 0) addError("cantidad", "La cantidad es obligatoria");
    if (!esObsequio) {
      if (!precioUnitario) addError("precioUnitario", "El precio unitario es obligatorio");
    }
    if (!descripcionArticulo || descripcionArticulo.length < 5)
      addError("descripcionArticulo", "La descripcion del articulo debe ser superor a 5 caracteres");
    if (esObsequio) {
      const { precioReferencia, tipoPrecioReferencia } = obsequio[0];
      if (precioReferencia === undefined || precioReferencia < 1) {
        addError("precioReferencia", "El precio de referencia es obligatorio");
      }
      if (tipoPrecioReferencia === undefined) addError("tipoPrecioReferencia", "El tipo de precio de referencia es obligatorio");
    }
    if (!unidadMedida) addError("unidadMedida", "La unidad de medida es obligatoria");
    if (!porcentajeIva && porcentajeIva !== 0) addError("porcentajeIva", "El porcentaje IVA es obligatorio");
    if (!porcentajeConsumo && porcentajeConsumo !== 0) addError("porcentajeConsumo", "El porcentaje INC es obligatorio");
    if (!codigoArticulo) addError("codigoArticulo", "El codigo del producto es obligatorio");
    return fallos;
  };

  const sumarIvaInc = (iva, inc) => (iva ? iva.valorTotal : 0 + inc ? inc.valorTotal : 0);

  const getTotalDescuento = (cargosDescuentos) => (cargosDescuentos || []).reduce((total, cd) => total + cd.valor || 0, 0);

  const generarImpRet = (tributo, porcentaje) => {
    return (
      porcentaje > 0 && {
        tributo,
        porcentaje,
      }
    );
  };

  const closeModal = () => {
    setDataDetalle({
      porcentajeIva: 0,
      porcentajeConsumo: 0,
    });
    handleClose();
    setErrores([]);
  };

  return (
    <>
      <Modal
        open={modalState.open}
        title="Agregar nuevo item al documento"
        content={
          <AddDetalle
            setDetalle={setDetalle}
            guardarProducto={guardarProducto}
            detalle={detalle}
            errores={errores}
            emisor={emisor}
            isSoporte={isSoporte}
            {...rest}
          />
        }
        fullWidth
        maxWidth="md"
        scroll="paper"
        aria-labelledby="form-dialog-title"
        onAccept={agregarDetalle}
        onCancel={closeModal}
      />
      <Modal
        open={modalMensaje.open}
        content={modalMensaje.content}
        onAccept={modalMensaje.onAccept}
        onCancel={() => setModalMensaje((s) => ({ ...s, open: false }))}
      />
    </>
  );
};

export default ModalAddDetalle;
