import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import LabelTitulos from "components/layout/labelTitulos";

export default class ModalMensaje extends Component {
  render() {
    const {
      content = "",
      handleClose,
      open = false,
      title = "PortalFactura",
    } = this.props;
    return (
      <Dialog open={open}>
        <DialogTitle style={{ textAlign: "center" }}>
          <LabelTitulos tipo="encabezado" texto={title} />
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
