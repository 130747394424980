import * as React from 'react';
import Icon from 'components/menu/Icon';
import InfVentasIcon from 'static/images/infventas.png';
import DocNominaIcon from 'static/images/documentosNomina.png';
import Cotizacion from 'static/images/consultacotizacion.png';
import ConsultaIcon from '@material-ui/icons/AllInboxRounded';
import MovimientoIcon from '@material-ui/icons/EqualizerRounded';
import ConsultaFacturaIcon from '@material-ui/icons/Receipt';

const getMenu = (items) => ({
  subMenu: true,
  title: 'Consultas',
  icon: <ConsultaIcon />,
  items,
});

const ConsultaSoportesNomina = {
  title: 'Soportes nomina emitidas',
  icon: <Icon src={DocNominaIcon} alt='Documentos nomina emitidas' />,
  pathname: '/informenomina',
};

const DocumentosEmitidos = {
  isNoView: 'true',
  title: 'Documentos emitidos',
  icon: <ConsultaFacturaIcon />,
  pathname: '/facturas-enviadas',
};

const DocumentosPosEmitidos = {
  title: 'Informe Ventas POS',
  icon: <Icon src={Cotizacion} alt='Informe Ventas POS' />,
  pathname: '/info-ventas-pos',
};

export const DocumentosEmitidosSoporte = {
  title: 'Documentos soportes enviados',
  icon: <ConsultaFacturaIcon />,
  pathname: '/facturas-enviadas?tipo=SOPORTE',
};

export const DocumentosEmitidosFacturacion = {
  title: 'Consultar facturas enviadas',
  icon: <ConsultaFacturaIcon />,
  pathname: '/facturas-enviadas?tipo=FACTURACION',
};

export const Consultas = getMenu([
  DocumentosEmitidos,
  {
    title: 'Movimientos de inventario',
    icon: <MovimientoIcon />,
    pathname: '/movimientoInventario',
  },
  {
    title: 'Informe de ventas',
    icon: <Icon src={InfVentasIcon} alt='Informe de ventas' />,
    pathname: '/EstadisticasEmisor',
  },
  ConsultaSoportesNomina,
  {
    title: 'Mis movimientos',
    icon: <Icon src={InfVentasIcon} alt='Mis Movimientos' />,
    pathname: '/historialcaja',
  },
  {
    title: 'Mis transacciones',
    icon: <Icon src={InfVentasIcon} alt='Mis Transacciones' />,
    pathname: '/historial-transacciones',
  },
  {
    title: 'Consultar cotización',
    icon: <Icon src={Cotizacion} alt='Consultar Cotización' />,
    pathname: '/consultarcotizacion',
  },
  DocumentosPosEmitidos,
  {
    title: 'Reporte Compras',
    icon: <Icon src={InfVentasIcon} alt='Consultar Compras' />,
    pathname: '/compras',
  },
]);

export const ConsultasPos = getMenu([DocumentosPosEmitidos]);

export const ConsultasNomina = getMenu([ConsultaSoportesNomina]);

export const ConsultasFacturacion = getMenu([DocumentosEmitidos]);
