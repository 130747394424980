import React, { useMemo } from 'react';
import Select from 'components/molecules/Select';

function SelectOtroImpuesto({ label = 'Otros impuestos', value, ...rest }) {
  const options = useMemo(
    () => [
      { label: 'Selecciona un tipo de impuesto', value: undefined },
      { label: 'Impuesto al consumo de licores', value: 'ICL' },
      { label: 'Impuesto nacional productos plasticos', value: 'INPP' },
      { label: 'Impuesto a las bebidas ultraprocesadas azucaradas', value: 'IBUA' },
      {
        label:
          'Impuesto a los productos comestibles ultraporcesados industrialmente y/o con alto contenido de azucares añadidos, sodio o grasas saturadas',
        value: 'ICUI',
      },
    ],
    [],
  );

  return <Select options={options} label={label} value={value} {...rest} />;
}

export default SelectOtroImpuesto;
