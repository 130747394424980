import React, { useState } from "react";
import Input from "../../../Inputs/Input";
import Button from 'components/atoms/Button';
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function salud({ baseBody, guardarDeduccion, xlCol = "2" }) {
  const [nmPorcentaje, setNmPorcentaje] = useState();
  const [nmDeduccion, setNmDeduccion] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `SALUD`,
    nmDeduccion,
    nmPorcentaje,
  };

  const guardar = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDeduccion(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmDeduccion();
    setNmPorcentaje();
  };

  return (
    <form onSubmit={guardar}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Porcentaje del pago *"
            value={nmPorcentaje}
            onChange={(e) => setNmPorcentaje(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Debe corresponder al porcentaje de deducción de salud que paga el trabajador"
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor pagado *"
            value={nmDeduccion}
            onChange={(e) => setNmDeduccion(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="El trabajador debe estar afiliado al sistema de salud. La cotización por salud que corresponde al 12.5% de la base del aporte, se hace en conjunto con la empresa. Ésta última aporta el 8.5%, y el empleado debe aportar el 4% restante. Ese 4% es el valor que se debe descontar (deducir) del total devengado a cargo del empleado."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  // if (!(body.nmPorcentaje > 0 && body.nmPorcentaje <= 100))
  //   errores.push("El porcentaje se esta enviando fuera del rango de 1 a 100");
  // if (body.nmDeduccion < 0 || !body.nmDeduccion)
  //   errores.push(
  //     "El valor de la deduccion no puede ser negativa o no se esta enviando"
  //   );
  return errores;
}
