import React, { useMemo } from 'react';
import IconAtom from 'components/atoms/Icon';
import IconPdf from 'icons/pdf.png';
import IconXls from 'icons/xls.svg';
import Download from 'icons/download.svg';

const DEFAULT_ICONS_PORPS = {
  pdf: { src: IconPdf, alt: 'Icon PDF' },
  xls: { src: IconXls, alt: 'Icon Excel' },
  downloadFile: { src: Download, alt: 'Icon Descarga Archivo' },
};

function Icon({ src, icon, ...rest }) {
  const props = useMemo(() => (Boolean(icon) ? DEFAULT_ICONS_PORPS[icon] : { src }), []);

  return <IconAtom {...props} {...rest} />;
}

export default Icon;
