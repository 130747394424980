import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUTable from '@material-ui/core/Table';
import MUTableHead from '@material-ui/core/TableHead';
import MUTableBody from '@material-ui/core/TableBody';
import MUTableRow from '@material-ui/core/TableRow';
import MUTableCell from '@material-ui/core/TableCell';
import MUTablePagination from '@material-ui/core/TablePagination';
import useTable from 'hooks/useTable';
import { styles } from './styles';

function Table({
  headers = [],
  detalles = [],
  keys = [],
  withPagination,
  classes,
  display,
  loading,
  CustomTableHeadCell = MUTableCell,
}) {
  const { getDetalles, ...pagination } = useTable({ detalles, withPagination });

  return (
    <MUTable style={{ display }}>
      <MUTableHead className={classes.tableHeader}>
        <MUTableRow>
          {headers.map(({ label }, index) => (
            <CustomTableHeadCell key={index} align='center' className={classes.titleHeader}>
              {label}
            </CustomTableHeadCell>
          ))}
        </MUTableRow>
      </MUTableHead>
      <MUTableBody>
        {!loading ? (
          detalles.length > 0 ? (
            getDetalles().map((detalle, rowIndex) => (
              <MUTableRow key={rowIndex} hover>
                {keys.map((key, detalleIndex) => (
                  <MUTableCell key={rowIndex + detalleIndex}>{detalle[key]}</MUTableCell>
                ))}
              </MUTableRow>
            ))
          ) : (
            <MUTableRow>
              <MUTableCell colSpan={headers.length} align='center'>
                Consulta sin registros
              </MUTableCell>
            </MUTableRow>
          )
        ) : (
          <MUTableRow>
            <MUTableCell colSpan={headers.length} align='center'>
              Cargando...
            </MUTableCell>
          </MUTableRow>
        )}
      </MUTableBody>
      <MUTablePagination
        className={classes.tablePagination}
        style={{ display: withPagination ? 'table-cell' : 'none' }}
        {...pagination}
      />
    </MUTable>
  );
}

export default withStyles(styles)(Table);
