import moment from 'moment';

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const START_OF_DAY = `YYYY-MM-DD 00:00:00`;
export const END_OF_DAY = `YYYY-MM-DD 23:59:59`;
export const FIRST_MONTH_DAY = 'YYYY-MM-01 00:00:00';
export const LAST_MONTH_DAY = `YYYY-MM-${moment().daysInMonth()} 23:59:59`;
export const FIRST_YEAR_DAY = `YYYY-01-01 00:00:00`;
export const LAST_YEAR_DAY = `YYYY-12-31 23:59:59`;

export function getLastDayOf(date, timeData) {
  switch (timeData) {
    case 'DAY':
      return moment(date).format(END_OF_DAY);
    case 'WEEK':
      return moment(getFirstDayOf(date, timeData)).add(6, 'day').format(END_OF_DAY);
    case 'MONTH':
      return moment(date).format(`YYYY-MM-${moment(date).daysInMonth()} 23:59:59`);
    case 'YEAR':
      return moment(date).format(LAST_YEAR_DAY);
    default:
      return moment(date).format(DATETIME_FORMAT);
  }
}

export function getFirstDayOf(date, timeData) {
  switch (timeData) {
    case 'DAY':
      return moment(date).format(START_OF_DAY);
    case 'WEEK':
      return moment(date).format(
        `YYYY-MM-${
          new Date(moment(date).format(DATETIME_FORMAT)).getDate() - moment(date).weekday()
        } 00:00:00`,
      );
    case 'MONTH':
      return moment(date).format(FIRST_MONTH_DAY);
    case 'YEAR':
      return moment(date).format(FIRST_YEAR_DAY);
    default:
      return moment(date).format(DATETIME_FORMAT);
  }
}

export function dateBetweenDates(firstDate, lastDate, date) {
  return date >= firstDate && date <= lastDate;
}

export function formatDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatDateTime(dateTime) {
  return moment(dateTime).format('YYYY-MM-DDTHH:mm:ss');
}

export function getDateFromArray(dateArray) {
  return (
    dateArray &&
    moment(
      `${dateArray[0]}-${
        dateArray[1] < 10 ? '0' + dateArray[1] : dateArray[1]
      }-${dateArray[2] < 10 ? '0' + dateArray[2] : dateArray[2]}`,
    )
  );
}

export function getDateTimeFromArray(dateArray) {
  const year = dateArray[0];
  const month = dateArray[1] < 10 ? '0' + dateArray[1] : dateArray[1];
  const day = dateArray[2] < 10 ? '0' + dateArray[2] : dateArray[2];
  const hour = dateArray[3] < 10 ? '0' + dateArray[3] : dateArray[3];
  const minute = dateArray[4] < 10 ? '0' + dateArray[4] : dateArray[4];
  const second = dateArray[5] < 10 ? '0' + dateArray[5] : dateArray[5];
  return dateArray && moment(`${year}-${month}-${day} ${hour}:${minute}:${second}`);
}

export function now() {
  return moment();
}

export const getTime = () => moment().format('HH:mm:ss');

export function dateString(date) {
  return moment(date);
}

export function getLastDayOfMonth(momentIn) {
  return moment(momentIn.format(`YYYY-MM-${momentIn.daysInMonth()}T23:59:59`));
}

export function getLastDayOfWeek(moment) {
  return moment.format(
    `YYYY-MM-${new Date(moment.format(DATETIME_FORMAT)).getDate() - moment.weekday() + 6}T23:59:59`,
  );
}

export function getLastDayOfYear(moment) {
  return moment.format(`YYYY-12-31T23:59:59`);
}

export function getFirstDayOfLastYear() {
  return moment().subtract(1, 'year').startOf('year');
}
