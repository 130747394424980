import React from 'react';
import { Row } from 'antd';
import { Container, Observaciones } from './styles';
import InformacionDocumento from './InformacionDocumento';
import Detalles from './Detalles';
import Totales from './Totales';

const Previsualisacion = ({ documento, emisor }) => {
  return (
    <Container>
      {emisor.logoCloud && (
        <Row>
          <img src={emisor.logoCloud} alt='Logo Emisor' />
        </Row>
      )}
      <InformacionDocumento emisor={emisor} documento={documento} />
      <Detalles detalles={documento.detalles} documento={documento}/>
      <Totales documento={documento} detalles={documento.detalles}/>
      <Observaciones>
        Observaciones: {documento.dsObservaciones || documento.dsObservacion}
      </Observaciones>
    </Container>
  );
};

export default Previsualisacion;
