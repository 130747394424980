import * as React from 'react';
import Typography from 'components/atoms/Typography';

const InputFile = ({ label, inputText, styleContainer, tooltip, ...rest }) => {
  return (
    <div style={styleContainer}>
      <Typography variant='labelInput'>{label}</Typography>
      <div className='custom-file'>
        <input className='custom-file-input' type='file' {...rest} />
        <label className='custom-file-label'>{inputText}</label>
      </div>
      {tooltip && <label>{tooltip}</label>}
    </div>
  );
};

export default InputFile;
