import * as React from 'react';
import Button from 'components/atoms/Button';
const { useRef, useCallback } = React;

function ButtonFile({ onChange, accept, ...rest }) {
  const inputFileRef = useRef();

  const onClick = useCallback(function () {
    inputFileRef.current.click();
  }, []);

  return (
    <>
      <input hidden type='file' ref={inputFileRef} onChange={onChange} accept={accept} />
      <Button onClick={onClick} {...rest} />
    </>
  );
}

export default ButtonFile;
