import * as React from 'react';
import ButtonFile from 'components/molecules/ButtonFile';
import Modal from 'components/molecules/Modal';
import { subirExcelCufesGastos } from 'network/Api/Gasto';
import useModal from 'hooks/useModal';
const { useCallback } = React;

function ButtonExcel({ emisor }) {
  const [modalProps, openModal] = useModal();

  const onChangeFile = useCallback(
    async function (event) {
      const [file] = event.target.files;
      const errores = validarFile(file);
      if (!errores.length) {
        subirExcelCufesGastos({ emisor, file });
        openModal({
          content: 'Archivo en proceso. Puedes cerrar, y el sistema seguirá trabajando.',
        });
      } else {
        openModal({ content: errores.join(', ') });
      }
    },
    [emisor],
  );

  const validarFile = useCallback(function (file) {
    let errores = [];
    const { name } = file;
    if (!(name.endsWith('.xls') || name.endsWith('.xlsx')))
      errores.push('El archivo no es de tipo Excel');
    return errores;
  }, []);

  return (
    <>
      <ButtonFile onChange={onChangeFile}>Subir y Procesar</ButtonFile>
      <Modal {...modalProps} />
    </>
  );
}

export default ButtonExcel;
