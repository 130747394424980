import * as React from "react";
import { moneyFormat } from "utils/TextFormattingUtils";
import { TableRow, TableCell } from "@material-ui/core";

const RowTotal = ({ classes, valor = 0, title }) => {
  return (
    <TableRow>
      <TableCell colSpan={4} />
      <TableCell className={classes.bodyTotales} colSpan={2}>
        {title}
      </TableCell>
      <TableCell className={classes.bodyTotales} colSpan={4} align="right">
        {moneyFormat(valor)}
      </TableCell>
    </TableRow>
  );
};

export default RowTotal;
