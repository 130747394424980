import * as React from "react";
import { Typography } from "@material-ui/core";

const SolicitudRealizada = () => (
  <div style={{ textAlign: "center", margin: "50px auto" }}>
    <Typography variant="h3">
      Tu turno ha sido asignado exitosamente, en breve recibirás más información
      vía mensaje de texto a tu celular.
    </Typography>
  </div>
);

export default SolicitudRealizada;
