import React, { useEffect, useState } from 'react';
import { consultarSaldoCajaMenorEmisor } from 'network/Api/SaldoDinero';
import ModalConfirmacion from './ModalConfirmacion';
import { consultarTransaccionRecarga } from 'network/Api/TransaccionRecarga';
import * as TextFormattin from 'utils/TextFormattingUtils';
import { formatDateTime } from 'utils/DateUtils';

const MENSAJE_SALDO_MENOR_A_DIEZMIL =
  '¡Alerta! Saldo próximo a agotarse. Recarga ahora para seguir disfrutando.';
const MENSAJE_SALDO_CERO = '¡Alerta! Saldo agotado. Recarga ahora para continuar disfrutando.';

function ModalNotificacionSaldo({ idEmisor, tarifa, docEmisor }) {
  const [state, setState] = useState({});

  useEffect(() => {
    if (idEmisor) {
      consultarSaldoEmisor();
    }
  }, [idEmisor]);
  async function consultarSaldoEmisor() {
    const response = await consultarTransaccionRecarga({ identificacion: docEmisor });
    if (response.data.snConsulta) {
      const fechaNotificacion = formatDateTime(new Date(response.data.date));
      if (response.data.status === 'APPROVED') {
        setState({
          open: true,
          content:
            'Su transacción con referencia ' +
            response.data.reference +
            ', realizada en la fecha ' +
            fechaNotificacion +
            ' por valor de $ ' +
            TextFormattin.decimalFormat(response.data.nmValor) +
            ', se encuentra APROBADA por la entidad financiera.',
        });
      }
      if (response.data.status === 'PENDING') {
        setState({
          open: true,
          content:
            'En estos momentos su transacción con referencia ' +
            response.data.reference +
            ', se encuentra pendiente de validación por la entidad financiera. Por favor espera unos minutos a que esta se resuelva. En caso de no recibir una respuesta, te recomendamos ponerte en contacto con nuestra línea de soporte en ' +
            process.env.REACT_APP_TELEFONO_SOPORTE,
        });
      }
      if (response.data.status === 'REJECTED') {
        setState({
          open: true,
          content:
            'Su transacción con referencia ' +
            response.data.reference +
            ', realizada en la fecha ' +
            fechaNotificacion +
            ' por valor de $ ' +
            TextFormattin.decimalFormat(response.data.nmValor) +
            ', se encuentra RECHAZADA. ' +
            response.data.message,
        });
      }
    } else {
      const result = await consultarSaldoCajaMenorEmisor({ emisor: idEmisor });
      if (result.data > 0 && result.data <= 10000) {
        setState({ open: true, content: MENSAJE_SALDO_MENOR_A_DIEZMIL });
      }

      if (parseInt((result.data / tarifa).toFixed(0)) === 0) {
        setState({ open: true, content: MENSAJE_SALDO_CERO });
      }
    }
  }

  function cerrarModal() {
    setState((oldState) => {
      return { ...oldState, open: false };
    });
  }

  return <ModalConfirmacion open={state.open} content={state.content} onAccept={cerrarModal} />;
}

export default ModalNotificacionSaldo;
