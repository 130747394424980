/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper, Grid, AppBar, Tabs, Typography, Tab, Button } from '@material-ui/core';
import { SetSpinnerModal, SetConfirmacionModalState } from 'reducers/actions/facturaActions';
import { SetEmisor } from 'reducers/actions/maestrosActions';
import InformacionEmisor from './InformacionEmisor';
import InformacionPersona from './InformacionPersona';
import { RegistrarResolucionesDomina } from 'network/Domina';
import ResolucionModal from './ResolucionModal';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { subirArchivo } from 'utils/UploadS3';
import SelectConsultaEmisores from 'components/Inputs/SelectConsultaEmisores';
import {
  consultarConfiguracion,
  actualizarConfiguracion,
  configurarEmisorDomina,
} from 'network/Api/Emisor';
import { registrarResolucion } from 'network/Api/Resolucion';
import { habilitarEmisorNomina, habilitarEmisorFacturacion } from 'network/Api/DocHabilitacion';
import ButtonPos from 'components/organisms/ButtonHabilitarEmisionPos';
import { descargarDocumento } from 'network/Api/BucketS3Aws';

const styles = {
  content: {
    padding: 10,
    minHeight: '400px',
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`Tab${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}

class ConfiguracionEmisor extends Component {
  state = {
    tabSelect: 0,
    emisorConsultado: false,
    activeButtons: true,
    datosParametros: null,
    emisorSeleccionado: '',
    resoluciones: [],
    showModal: false,
  };

  mensajes = (close, texto, onClick, parametro) => {
    let modal;
    if (close) {
      modal = { open: false };
      this.props.setConfirmacionModalState(modal);
      return;
    }

    if (parametro != null) {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(parametro),
        cancelarVisible: true,
      };
    } else {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(),
        cancelarVisible: true,
      };
    }

    this.props.setConfirmacionModalState(modal);
  };

  handleChange = (event, tab) => {
    this.setState({ tabSelect: tab });
  };

  handleConsultarEmisor = (event, element) => {
    this.setState({ emisorSeleccionado: event.id });
    this.consultarEmisorSeleccionado(event.id);
  };

  handleConsultarEmisorSeleccionado = () => {
    const { emisorSeleccionado } = this.state;
    if (emisorSeleccionado !== '') {
      this.consultarEmisorSeleccionado(emisorSeleccionado);
    } else {
      this.mensajes(false, 'Seleccione un emisor', this.mensajes, true);
    }
  };

  consultarEmisorSeleccionado = async (idEmisor) => {
    const { status, data } = await consultarConfiguracion({ nmId: idEmisor });
    if (status === 200) {
      const { logoCloud: fileName } = data;
      const logoBlob = await descargarDocumento({ fileName });
      this.setState({ emisorConsultado: true, activeButtons: false });
      this.props.setEmisor({ ...data, logoEmisorS3: URL.createObjectURL(logoBlob) });
    } else {
      this.mensajes(false, result.data, this.mensajes, true);
    }
  };

  handleActualizarEmisor = async (habilitado) => {
    const { emisorSeleccionado } = this.state;
    const { emisor, setEmisor } = this.props;
    let validacion = this.validarCamposVacios();
    if (validacion.isOk) {
      const { key: logoCloud } = emisor.logoLoaded
        ? await subirArchivo(emisor.logoLoaded.file, emisor.codigoEmisor)
        : {};
      if (logoCloud !== 'ERROR') {
        setEmisor({ ...emisor, logoCloud });
        const { data } = await configurarEmisorDomina({
          body: { ...emisor, logoCloud },
          habilitado,
        });
        this.consultarEmisorSeleccionado(emisorSeleccionado);
        this.mensajes(false, data ? 'Emisor configurado correctamente' : '', this.mensajes, true);
      } else {
        this.mensajes(false, `No se pudo guardar la imagen`, this.mensajes, true);
      }
    } else {
      this.mensajes(false, validacion.errores.toString(), this.mensajes, true);
    }
  };

  validarCamposVacios = () => {
    const { emisor } = this.props;
    let listaErrores = [];
    if (emisor.tipoPersona === 'NATURAL') {
      if (emisor.nombres === '') {
        listaErrores.push('Campo nombre es obligatorio');
      }
      if (emisor.segundoNombre === '') {
        listaErrores.push('Campo Segundo Nombre es obligatorio');
      }
      if (emisor.primerApellido === '') {
        listaErrores.push('Campo Primer Apellido es obligatorio');
      }
    }
    if (emisor.direccion === undefined || emisor.direccion === '') {
      listaErrores.push('Campo Direccion es obligatorio');
    }
    if (emisor.telefono === undefined || emisor.telefono === '') {
      listaErrores.push('Campo Telefono es obligatorio');
    }
    if (emisor.email === undefined || emisor.email === '') {
      listaErrores.push('Campo Email es obligatorio');
    }
    if (emisor.emailRemitenteEnvios === undefined || emisor.emailRemitenteEnvios === '') {
      listaErrores.push('Campo Email Remitente es obligatorio');
    }
    if (emisor.codigoCiiu === undefined || emisor.codigoCiiu === '') {
      listaErrores.push('Campo Código CIIU es obligatorio');
    }
    if (emisor.respFiscales === undefined || emisor.respFiscales === '') {
      listaErrores.push('Campo Responsabilidad Fiscal es obligatorio');
    }
    if (listaErrores.length > 0) {
      return { isOk: false, errores: listaErrores };
    }
    return { isOk: true };
  };

  handleHabilitar = () => {
    const { emisorSeleccionado } = this.state;
    habilitarEmisorFacturacion({ nmId: emisorSeleccionado });
    this.mensajes(
      false,
      `Se está realizando el envío de las facturas para la habilitación en la DIAN, por favor valide que el emisor haya sido habilitado de manera correcta.`,
      this.mensajes,
      true,
    );
  };

  habilitarNomina = () => {
    const { emisorSeleccionado } = this.state;
    habilitarEmisorNomina({ nmId: emisorSeleccionado });
    this.mensajes(
      false,
      `Se está realizando el envío de los documentos para la habilitación en la DIAN, por favor valide que el emisor haya sido habilitado de manera correcta.`,
      this.mensajes,
      true,
    );
  };

  validarResultadoAndModificar(result, emisor, emisorSeleccionado) {
    const { setEmisor } = this.props;
    if (result.status == 200) {
      emisor.tokenSeguridad = result.data.tokenEmisor;
      emisor.codigoEmisor = emisorSeleccionado;
      setEmisor({ ...emisor, tokenSeguridad: result.data.tokenEmisor });
      this.saveFileImageAndSaveEmisor();
    } else {
      this.mensajes(
        false,
        `No se pudo obtener el token desde Domina ni se actualizó el emisor`,
        this.mensajes,
        true,
      );
    }
  }

  saveFileImageAndSaveEmisor = async () => {
    const { emisor } = this.props;
    if (emisor.logoLoaded) {
      return await subirArchivo(emisor.logoLoaded.file, emisor.codigoEmisor).key;
    }
  };

  handleRegistrarResolucionDomina = () => {
    const { emisor } = this.props;
    RegistrarResolucionesDomina(
      emisor.identificacionEmisor,
      this.props.setSpinnerModal,
      emisor.tokenSeguridad,
    ).then((response) => {
      if (response.status === 200) {
        if (response.data.resoluciones.length > 0) {
          this.setState({ resoluciones: response.data.resoluciones }, () => {
            this.LlamarServicioResolucionEmisor();
          });
        } else {
          this.mensajes(false, `No hay resoluciones para configurar`, this.mensajes, true);
        }
      } else {
        if (response.status === 404) {
          this.mensajes(false, `No hay resoluciones para configurar`, this.mensajes, true);
        } else {
          this.mensajes(
            false,
            `ERROR: No se pudo conectar con el servicio de Domina`,
            this.mensajes,
            true,
          );
        }
      }
    });
  };

  LlamarServicioResolucionEmisor = () => {
    const { resoluciones, emisorSeleccionado } = this.state;
    registrarResolucion(
      resoluciones.map(
        ({
          nmInicio,
          nmFin,
          dsPrefijo,
          resolucionDian,
          feResolucion,
          feVencimiento,
          snContingencia,
        }) => ({
          emisor: { nmId: emisorSeleccionado },
          nmInicio,
          nmFin,
          dsPrefijo,
          dsResolucionDian: resolucionDian,
          feInicio: [...feResolucion.split('-'), 0, 0].map((a) => parseInt(a)),
          feVencimiento: [...feVencimiento.split('-'), 0, 0].map((a) => parseInt(a)),
          snContingencia,
        }),
      ),
    ).then((response) => {
      if (response.status === 200) {
        this.setState({ resoluciones: response.data, showModal: true });
      } else {
        this.mensajes(
          false,
          `Error al crear las resoluciones en PortalFactura`,
          this.mensajes,
          true,
        );
      }
    });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  guardarEmisor() {
    const { emisor } = this.props;
    actualizarConfiguracion(emisor).then((res) => {});
  }

  render() {
    const { tabSelect, emisorConsultado, activeButtons, showModal, resoluciones } = this.state;
    const { emisor } = this.props;
    let habilitado = emisor.habilitado === 'S';
    return (
      <div>
        <Paper style={styles.content}>
          <Grid container direction='column' justify='space-between' style={{ minHeight: '400px' }}>
            <div>
              <Grid container spacing={8}>
                <Grid item xs={11}>
                  <SelectConsultaEmisores handleConsultarEmisor={this.handleConsultarEmisor} />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={this.handleConsultarEmisorSeleccionado}
                  >
                    <AutorenewIcon />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <AppBar position='static'>
                    <Tabs value={tabSelect} label='Persona' onChange={this.handleChange}>
                      <Tab label='Emisor' id='Tab0' />
                      <Tab label='Persona' id='Tab1' />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tabSelect} index={0}>
                    {emisorConsultado && <InformacionEmisor />}
                  </TabPanel>
                  <TabPanel value={tabSelect} index={1}>
                    {emisorConsultado && <InformacionPersona />}
                  </TabPanel>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container justify='space-between' direction='row'>
                <Button
                  variant='contained'
                  disabled={activeButtons || habilitado}
                  color='primary'
                  onClick={() => this.handleActualizarEmisor(false)}
                >
                  Actualizar datos habilitación
                </Button>
                <Button
                  variant='contained'
                  disabled={activeButtons || habilitado}
                  color='primary'
                  onClick={this.handleHabilitar}
                >
                  Habilitar Emisor DIAN
                </Button>
                <ButtonPos
                  disabled={activeButtons || !habilitado || emisor.cdHabilitadoPos === 'S'}
                  nmIdEmisor={emisor.nmId}
                  openModal={(mensaje) => this.mensajes(false, mensaje, this.mensajes, true)}
                />
                <Button
                  variant='contained'
                  disabled={activeButtons || !habilitado || emisor.habilitadoNomina === 'S'}
                  color='primary'
                  onClick={this.habilitarNomina}
                >
                  Habilitar Emisor Nomina
                </Button>
                <Button
                  variant='contained'
                  disabled={activeButtons || !habilitado}
                  color='primary'
                  onClick={() => this.handleActualizarEmisor(true)}
                >
                  Configurar Producción
                </Button>
                <Button
                  variant='contained'
                  disabled={activeButtons || !habilitado}
                  color='primary'
                  onClick={this.handleRegistrarResolucionDomina}
                >
                  Configurar Resolución
                </Button>
              </Grid>
            </div>
          </Grid>
        </Paper>
        <ResolucionModal
          openModal={showModal}
          resoluciones={resoluciones}
          handleClose={this.handleClose}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    emisor: state.maestros.emisor,
    ciudadesArray: state.factura.ciudadesArray,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
    setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
    setEmisor: (item) => dispatch(SetEmisor(item)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracionEmisor);
