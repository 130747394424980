import * as React from 'react';
import { connect } from 'react-redux';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import Table from 'components/Tablas/GenericaOrdenadaColumna';
import { now, getTime, dateString } from 'utils/DateUtils';
import * as CotizacionApi from 'network/Api/CotizarDocumento';
import Button from 'components/atoms/Button';
import Modal from 'components/modal/ModalConfirmacion';
import CompletarDocumento from './CompletarDocumento';
import Loader from 'components/Loaders/LoadingScreen';

const hoy = now().format('YYYY-MM-DD');

const ESTADOS_DOC = [
  { label: 'Cualquiera', value: null },
  { label: 'Cotización Creada', value: 'COTIZACION' },
  { label: 'Cotiazción Facturada', value: 'FACTURA' },
  { label: 'Error Facturando Cotización', value: 'ERROR_FACTURA' },
];

const ConsultasCotizacion = ({ userAttributes }) => {
  const [state, setData] = React.useState({
    feCotizacion: now().format('YYYY-MM-01'),
    feFinCotizacion: hoy,
    dsNombreAdquirente: '',
    dsIdentificacion: '',
    estado: null,
  });

  React.useEffect(() => {
    const { codigoEmisor } = userAttributes;
    if (codigoEmisor) {
      setState({ loader: true });
      consultarCotizaciones();
    }
  }, [state.feCotizacion, userAttributes]);

  const consultarCotizaciones = () => {
    const { codigoEmisor } = userAttributes;
    CotizacionApi.consultarCotizaciones({
      emisor: codigoEmisor,
      estado: state.estado,
      adquirente: state.dsNombreAdquirente,
      identificacion: state.dsIdentificacion,
      fechacreacion: state.feCotizacion,
      fefin: state.feFinCotizacion,
    }).then(({ data }) => setState({ cotizaciones: data, loader: false }));
  };

  React.useEffect(() => {
    const { enviar, documento, nmId } = state;
    if (enviar) {
      enviarDocumentoRegistro(
        {
          ...documento,
          fechaEmision: documento.fechaEmision + ` ${getTime()}`,
        },
        nmId,
      );
      setState({ enviar: false });
    }
  }, [state.enviar]);

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  const openModal = ({
    contentModal,
    onAcceptModal,
    onCancelModal = () => setState({ openModal: false }),
    fullWidthModal = false,
  }) =>
    setState({
      openModal: true,
      contentModal,
      onAcceptModal,
      onCancelModal,
      fullWidthModal,
    });

  const handleChange = ({ target }) => setState({ [target.name]: target.value });

  const descargarPdf = (cotizacionid) => {
    setState({ loader: true });
    CotizacionApi.generarPdfCotizacion({ cotizacionid }).then((blobPdf) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = URL.createObjectURL(new Blob([blobPdf], { type: 'application/pdf' }));
      a.id = `id`;
      a.download = `cotizacion.pdf`;
      a.click();
      setState({ loader: false });
    });
  };

  const enviarDocumentoRegistro = (documento, nmId) => {
    setState({ loader: true });
    CotizacionApi.facturarCotizacion({
      dsJsonDocumento: JSON.stringify(documento),
      nmId,
    }).then((res) => {
      consultarCotizaciones();
      setState({ openModal: false });
    });
  };

  const facturarCotizacion = (documento, nmId) => {
    setState({
      documento: {
        ...JSON.parse(documento),
        fechaEmision: now().format('YYYY-MM-DD'),
        fechaVencimiento: now().format('YYYY-MM-DD'),
      },
      nmId,
    });
    openModal({
      open: true,
      fullWidthModal: true,
      onAcceptModal: () => setState({ enviar: true }),
    });
  };

  const setDocumento = (newDoc) => setState({ documento: { ...state.documento, ...newDoc } });

  return (
    <>
      <div className='form-row col-xl-12'>
        <div className='col-md-2 col-xs-2'>
          <Input
            label='Fecha de inicio'
            name='feCotizacion'
            value={state.feCotizacion}
            onChange={handleChange}
            type='date'
            max={hoy}
          />
        </div>
        <div className='col-md-2 col-xs-2'>
          <Input
            label='Fecha de fin'
            name='feFinCotizacion'
            value={state.feFinCotizacion}
            onChange={handleChange}
            type='date'
            max={hoy}
          />
        </div>
        <div className='col-md-2 col-xs-2'>
          <Input
            label='Nombre Adquirente'
            name='dsNombreAdquirente'
            value={state.dsNombreAdquirente}
            onChange={handleChange}
          />
        </div>
        <div className='col-md-2 col-xs-2'>
          <Input
            label='Identificacion Adquirente'
            name='dsIdentificacion'
            value={state.dsIdentificacion}
            onChange={handleChange}
          />
        </div>
        <div className='col-md-2 col-xs-2'>
          <Select
            label='Estado'
            name='estado'
            options={ESTADOS_DOC}
            value={ESTADOS_DOC.filter((e) => e.value === state.estado)}
            onChange={({ value }) => setState({ estado: value })}
          />
        </div>
        <div className='col-md-2 col-xs-2'>
          <Button onClick={consultarCotizaciones}>Consultar</Button>
        </div>
      </div>
      <Table
        headers={[
          { label: 'Descargar PDF', id: 'downloadPdf', disablePadding: true },
          { label: 'Prefijo Factura', id: 'dsPrefijoFactura', disablePadding: true },
          { label: 'Número Factura', id: 'dsNumeroFactura', disablePadding: true },
          { label: 'Fecha de Cotizacion', id: 'feCreacion', disablePadding: true },
          { label: 'Número Cotización', id: 'dsNumeroDoc', disablePadding: true },
          { label: 'Nombre Adquirente', id: 'dsNombre', disablePadding: true },
          { label: 'Identificación Adquirente', id: 'dsIdentificacion', disablePadding: true },
          { label: 'Valor Total', id: 'nmTotal', disablePadding: true },
          { label: 'Estado', id: 'cdEstado', disablePadding: true },
          { label: 'Convertir Factura', id: 'btnFactura', disablePadding: true },
        ]}
        keys={[
          'downloadPdf',
          'dsPrefijoFactura',
          'dsNumeroFactura',
          'feCreacion',
          'dsNumeroDoc',
          'dsNombre',
          'dsIdentificacion',
          'nmTotal',
          'cdEstado',
          'btnFactura',
        ]}
        detalles={(state.cotizaciones || []).map((c) => ({
          ...c,
          cdEstado: ESTADOS_DOC.find((e) => e.value === c.cdEstado).label,
          feCreacion: dateString(c.feCreacion).format('DD/MM/YYYY HH:mm:ss'),
          btnFactura: (
            <Button
              onClick={() => facturarCotizacion(c.dsJsonDocumento, c.nmId)}
              disabled={c.cdEstado !== 'COTIZACION'}
            >
              Convertir Factura
            </Button>
          ),
          downloadPdf: <Button onClick={() => descargarPdf(c.nmId)}>Descarga PDF</Button>,
        }))}
        loading={false}
      />
      <Loader open={state.loader} />
      <Modal
        title='Completar Documento'
        open={state.openModal}
        content={
          <CompletarDocumento
            emisor={userAttributes.codigoEmisor}
            documento={state.documento}
            setDocumento={setDocumento}
          />
        }
        onAccept={state.onAcceptModal}
        onCancel={state.onCancelModal}
        fullWidth={state.fullWidthModal}
        maxWidth='md'
      />
    </>
  );
};

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

export default connect(mapStateToProps)(ConsultasCotizacion);
