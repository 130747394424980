import React from 'react';
import { Card } from 'antd';
import DetalleServicio from './DetalleServicio';
import Button from 'components/atoms/Button';

function AliadoCard({ servicio, aliado, portal, vigencia, onClick, urlImagen }) {
  return (
    <Card
      cover={urlImagen && <img alt={`Imagen Servicio ${servicio}`} src={urlImagen} />}
      style={{ width: '350px' }}
    >
      <DetalleServicio
        dsServicio={servicio}
        dsAliado={aliado}
        nmValorPortal={portal}
        feVigencia={vigencia}
      />
      <Button fullWidth onClick={onClick} style={{ marginTop: '15px' }}>
        Ver mas detalle
      </Button>
    </Card>
  );
}

export default AliadoCard;
