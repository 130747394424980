import * as React from "react";
import Select from "components/Inputs/Select";
import TiposRegimen from "static/enums/tiposRegimen";

const SelectRegimen = ({ value, ...rest }) => {
  const options = TiposRegimen.map((tipo) => ({
    label: tipo.nombre,
    value: tipo.codigo,
  }));

  return (
    <Select
      options={options}
      value={options.find((o) => o.value === value)}
      {...rest}
    />
  );
};

export default SelectRegimen;
