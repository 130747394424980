import React from 'react';

const useModal = () => {
  const [modalProps, setModalProps] = React.useState({});

  const setModal = (props) => setModalProps((mp) => ({ ...mp, ...props }));

  const openModal = ({ onCancel = closeModal, ...props }) =>
    setModal({ onCancel, ...props, open: true });

  const closeModal = () => setModal({ open: false });

  return [modalProps, openModal, closeModal, setModal];
};

export default useModal;
