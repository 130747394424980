import React from 'react';
import { moneyFormat } from 'utils/TextFormattingUtils';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'components/atoms/Button';
import Typography from 'components/atoms/Typography';
import './styles.css';

function Detalles({ documento, setDocumento, eliminarDetalle, classes }) {
  const detleteDetalle = (id) => {
    if (setDocumento) {
      const nuevosDetalles = documento.detalles.filter((d) => d.id !== id);
      if (nuevosDetalles.length >= 1) {
        setDocumento((d) => ({
          ...d,
          detalles: nuevosDetalles.map((p, key) => ({ ...p, id: key })),
        }));
      }
    }
    if (eliminarDetalle) eliminarDetalle(id);
  };

  return (
    <div>
      <div className={`col-12 ${classes.borderedContainer}`}>
        <Typography variant='labelEncabezado'>Lista de Pedidos</Typography>
        {(documento.detalles || []).map((detalle, key) => (
          <div key={key}>
            <div className='col-lg-12 style-pedidos' style={{ display: 'flex' }}>
              <div className='col-lg-5'>
                <p style={{ color: 'black' }}>{detalle.descripcionArticulo}</p>
              </div>
              <div className='col-lg-1'>
                <p style={{ color: 'black' }}>{detalle.cantidad}</p>
              </div>
              <div className='col-lg-3'>
                <p style={{ color: 'black' }}>
                  {moneyFormat(
                    parseFloat(
                      detalle.valorBruto + (detalle.valorTotalImpuestosRetenciones || 0),
                    ).toFixed(0),
                  )}
                </p>
              </div>
              <div className='col-lg-3'>
                <Button variant='text' color='default' onClick={() => detleteDetalle(detalle.id)}>
                  <DeleteIcon />
                </Button>
              </div>
            </div>
          </div>
        ))}
        {documento.netoMostrar ? (
          <h4 className='titulo_principal'>
            Total: {moneyFormat(documento.netoMostrar.toFixed(0))}
          </h4>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Detalles;
