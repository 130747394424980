import * as React from 'react';
import { registrarAdquirentePos } from 'network/Api/Adquirente';
import ReCAPTCHA from 'react-google-recaptcha';
import FormularioAdquirente from 'screens/Adquirentes/FormularioAdquirente';
import useForm from 'hooks/useForm';
import Typography from 'components/atoms/Typography';
import Layoyt from 'components/layout/Layout';
const { useEffect, useCallback } = React;

function RegistroAdquirente() {
  const { state, setState } = useForm();

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    setState({ token: url.get('id') });
  }, []);

  const registrar = useCallback(
    async (adquirente) => {
      const { captcha, token } = state;
      if (captcha) {
        const { data } = await registrarAdquirentePos({ ...adquirente, captcha }, token);
        alert(data ? 'El usuario ha sido registrado' : 'El usuario ya existe');
        window.location.reload();
      } else {
        alert('El Captcha es obligatorio');
      }
    },
    [state],
  );

  const onCaptcha = useCallback((captcha) => setState({ captcha }), []);

  return (
    <Layoyt>
      <div style={{ margin: '0px 30px' }}>
        <div className='form-row'>
          <div className='col-md-12' style={{ textAlign: 'center' }}>
            <Typography variant='labelEncabezado'>Registro de Cliente</Typography>
          </div>
        </div>
        <FormularioAdquirente
          showDatosSalud={false}
          onRegistro={registrar}
          updateAdquirente={{ ciudad: { nmId: 1 }, nmResponFiscales: 'R-99-PN' }}
        />
        <div className='form-group'>
          <ReCAPTCHA sitekey='6LcnrgYpAAAAAC2F1RM8cSk702ds3vDoRcmEuQbh' onChange={onCaptcha} />
        </div>
      </div>
    </Layoyt>
  );
}

export default RegistroAdquirente;
