import React from 'react';
import MUButton from '@material-ui/core/Button';

const Button = ({
  variant = 'contained',
  color = 'primary',
  children,
  loading = false,
  disabled,
  ...rest
}) => (
  <MUButton variant={variant} color={color} disabled={loading || disabled} {...rest}>
    {children}
  </MUButton>
);

export default Button;
