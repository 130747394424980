export default [
  {
    id: "FIJO",
    nombre: "Término Fijo",
  },
  {
    id: "INDEFINIDO",
    nombre: "Término Indefinido",
  },
  {
    id: "OBRALABOR",
    nombre: "Obra o Labor",
  },
  {
    id: "APRENDIZAJE",
    nombre: "Aprendizaje",
  },
  {
    id: "PRACTICAS",
    nombre: "Prácticas o Pasantías",
  },
];
