/* eslint-disable */
import React, { Component } from "react";
import { Drawer } from "@material-ui/core";
import LabelTitulos from "../../components/layout/labelTitulos";
import { moneyFormat } from "utils/TextFormattingUtils";
import { consultarSaldoCajaMenorEmisor } from "network/Api/SaldoDinero";

class DraweProfile extends Component {
  state = {};

  componentDidMount() {
    const { userAttributes } = this.props;
    if (userAttributes.codigoEmisor) {
      const { codigoEmisor } = userAttributes;
      consultarSaldoCajaMenorEmisor({ emisor: codigoEmisor }).then((res) => this.setState({ cajaMenor: res.data }));
    }
  }

  render() {
    const { userAttributes, open, handleClose, classesContentDrawer, classesToolbar, classesSaldo } = this.props;
    const { cajaMenor } = this.state;
    return (
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <div tabIndex={0} role="button" onClick={handleClose} onKeyDown={handleClose} className={classesContentDrawer}>
          <div className={classesToolbar} />

          <ol>
            <li className={classesSaldo}>
              <LabelTitulos texto={`Saldo actual ${moneyFormat(cajaMenor)}`} className="form-control" />
            </li>
            <li className="text-muted text-center mt-4">Usuario</li>
            <li>
              <LabelTitulos texto={`${userAttributes["custom:custom:username"]}`} />
            </li>
            <li className="text-muted text-center">Identificación</li>
            <li>
              <LabelTitulos texto={`${userAttributes.identificacionEmisor}`} />
            </li>
            <li className="text-muted text-center">Teléfono</li>
            <li>
              <LabelTitulos texto={`${userAttributes.phone_number}`} />
            </li>
            <li className="text-muted text-center">E-mail envios</li>
            <li>
              <LabelTitulos texto={`${userAttributes.emailRemitenteEnvios}`} />
            </li>
          </ol>
        </div>
      </Drawer>
    );
  }
}

export default DraweProfile;
