import React, { useState, useEffect } from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { moneyFormat } from "../../utils/TextFormattingUtils";
import { Button } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import Tooltip from 'components/atoms/Tooltip';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(133, 198, 56, 1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledHeaderTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(133, 198, 56, 1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function tablaDeducciones({ detalles, eliminarItem }) {
  const [nmPorcentaje, setNmPorcentaje] = useState(false);
  const [nmDeduccion, setNmDeduccion] = useState(false);
  const [nmDeduccionSP, setDeduccionSP] = useState(false);
  const [nmPorcentajeSub, setPorcentajeSub] = useState(false);
  const [nmDeduccionSub, setDeduccionSub] = useState(false);
  const [nmSancionPublic, setNmSancionPublic] = useState(false);
  const [nmSancionPriv, setNmSancionPriv] = useState(false);
  const [dsDescripcion, setDsDescripcion] = useState(false);

  useEffect(() => {
    setNmPorcentaje(detalles.filter((d) => d.nmPorcentaje).length > 0);
    setNmDeduccion(detalles.filter((d) => d.nmDeduccion).length > 0);
    setDeduccionSP(detalles.filter((d) => d.nmDeduccionSp).length > 0);
    setPorcentajeSub(detalles.filter((d) => d.nmPorcentajeSub).length > 0);
    setDeduccionSub(detalles.filter((d) => d.nmDeduccionSub).length > 0);
    setNmSancionPublic(detalles.filter((d) => d.nmSancionPublic).length > 0);
    setNmSancionPriv(detalles.filter((d) => d.nmSancionPriv).length > 0);
    setDsDescripcion(detalles.filter((d) => d.dsDescripcion).length > 0);
  }, [detalles]);

  return (
    detalles.length > 0 && (
      <div style={{ overflowX: "auto" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledHeaderTableCell />
              {nmPorcentaje && <StyledHeaderTableCell>Porcentaje</StyledHeaderTableCell>}
              {nmDeduccion && <StyledHeaderTableCell>Deduccion</StyledHeaderTableCell>}
              {nmDeduccionSP && (<StyledHeaderTableCell>DeduccionSP<Tooltip text={"Deduccion del Fondo de Solidaridad Pensional"} /></StyledHeaderTableCell>)}
              {nmPorcentajeSub && (<StyledHeaderTableCell>PorcentajeSub<Tooltip text={"Porcentaje del Fondo de Subsistencia"} /></StyledHeaderTableCell>)}
              {nmDeduccionSub && (<StyledHeaderTableCell>DeduccionSub<Tooltip text={"Deduccion del Fondo de Subsistencia"} /></StyledHeaderTableCell>)}
              {nmSancionPublic && (<StyledHeaderTableCell>SancionPublic<Tooltip text={"Deduccion de Sancion Publica"} /></StyledHeaderTableCell>)}
              {nmSancionPriv && (<StyledHeaderTableCell>sancionPriv<Tooltip text={"Deduccion de Sancion Privada"} /></StyledHeaderTableCell>)}
              {dsDescripcion && <StyledHeaderTableCell>Descripcion</StyledHeaderTableCell>}
              <StyledHeaderTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
          {detalles.map((d, key) => (
            <StyledTableRow key={key}>
              <StyledHeaderTableCell>{getName(d.dsTipo)}</StyledHeaderTableCell>
              {nmPorcentaje && <StyledTableCell>{d.nmPorcentaje && `${parseFloat(d.nmPorcentaje)} %`}</StyledTableCell>}
              {nmDeduccion && <StyledTableCell>{moneyFormat(d.nmDeduccion)}</StyledTableCell>}
              {nmDeduccionSP && <StyledTableCell>{moneyFormat(d.nmDeduccionSp)}</StyledTableCell>}
              {nmPorcentajeSub && <StyledTableCell>{d.nmPorcentajeSub && `${parseFloat(d.nmPorcentajeSub)} %`}</StyledTableCell>}
              {nmDeduccionSub && <StyledTableCell>{moneyFormat(d.nmDeduccionSub)}</StyledTableCell>}
              {nmSancionPublic && <StyledTableCell>{moneyFormat(d.nmSancionPublic)}</StyledTableCell>}
              {nmSancionPriv && <StyledTableCell>{moneyFormat(d.nmSancionPriv)}</StyledTableCell>}
              {dsDescripcion && <StyledTableCell>{d.dsDescripcion}</StyledTableCell>}
              <StyledTableCell>
                <Button onClick={() => eliminarItem(d)}>
                  <DeleteRoundedIcon/>
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          </TableBody>
        </Table>
      </div>
    )
  );
}

function getName(code) {
  switch (code) {
    case "SALUD": return "Salud";
    case "PENSION": return "Fondo de Pension";
    case "FONDO_SP": return "Fondo SP";
    case "SINDICATO": return "Sindicatos";
    case "SANCION": return "Sanciones";
    case "LIBRANZA": return "Libranzas";
    case "COOPERATIVA": return "Cooperativa";
    case "DEUDA": return "Deuda";
    case "EDUCACION": return "Educacion";
    case "EMBARGO_FISCAL": return "Embargo Fiscal";
    case "PENSION_VOLUNTARIA": return "Pension Voluntaria";
    case "PLAN_COMPLEMENTARIO": return "Plan Complementario";
    case "REINTEGRO": return "Reintegro";
    case "RETENCION_FUENTE": return "Retencion Fuente";
    case "PAGO_TERCERO": return "Pagos de Terceros";
    case "OTRA_DEDUCCION": return "Otras Deducciones";
    case "ANTICIPO": return "Anticipo";
    default: return code;
  }
};