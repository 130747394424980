/* eslint-disable */
import React, { Component } from 'react';
import LogoPortal from 'static/images/logo_portal_factura.png';
import { Link } from 'react-router-dom';
import { Button, Input } from 'antd';
import { FacebookOutlined, InstagramOutlined, TikTokOutlined } from '@ant-design/icons';
import {
  SetModalCodigoConfirmacion,
  SetSnackBarState,
  SetConfirmacionModalState,
  SetLoadingValue,
  SetUserAttributes,
} from 'reducers/actions/facturaActions';
import { connect } from 'react-redux';
import { SignIn, GetUserData } from './auth/amplifyAuth';
import SnackBarMensajes from 'components/modals/snackBarMensajes';
import CodigoVerificacionModal from './CodigoVerificacion';
import * as MenuOptions from 'components/layout/Menus/MenuOptions';
import LogoTecno from 'static/images/LogoTecnofactor.png';
import { Redirect } from 'react-router';

import './styles.css';
class Login extends Component {
  state = {
    username: '',
    password: '',
    // usernameCodigoVerificacion: "",
    redirect: false,
  };

  componentWillMount() {
    GetUserData().then((result) => {
      if (result != 'not authenticated') {
        this.setState({ redirect: true });
      }
    });
  }

  componentDidMount() {
    this.props.setModalCodigoConfirmacion(false);
    this.props.setConfirmacionModalState({ open: false });
    this.props.setLoadingValue(false);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name == 'username') {
      const valorEnMayusculas = value.toUpperCase();
      this.setState({ username: valorEnMayusculas });
    } else this.setState({ [name]: value });
  };

  handleLogin = async (event) => {
    var sw = true;
    let allState = this.state;
    Object.keys(this.state).map(function (key) {
      if (key == 'redirect') return;
      var item = allState[key];
      if (item == '') sw = false;
    });
    if (sw) {
      event.preventDefault();
      SignIn({ ...this.state }, this.props.setLoadingValue).then((result) => {
        this.validarRespuestaSignIn(result);
      });
    } else {
      return;
    }
  };

  validarRespuestaSignIn = (result) => {
    if (result == 'SUCCESS') {
      GetUserData().then((result) => {
        let grupo = result.signInUserSession.accessToken.payload['cognito:groups'];
        let fechaExpiracionToken = result.signInUserSession.idToken.payload.exp;
        localStorage.setItem('fechaActual', fechaExpiracionToken);
        if (localStorage.getItem('adquirentes') == null) {
          localStorage.setItem('adquirentes', JSON.stringify([]));
        } else {
          let adquirentesLocalStorage = JSON.parse(localStorage.getItem('adquirentes'));
          localStorage.removeItem('adquirentes');
          localStorage.setItem('adquirentes', JSON.stringify(adquirentesLocalStorage));
        }
        this.setState({
          pathToRedirect: MenuOptions.getPrincipalPath(),
          redirect: true,
        });
      });
      return;
    }

    switch (result) {
      case 'UserNotConfirmedException':
        this.setState({ username: '', password: '' }, () => {
          this.props.setModalCodigoConfirmacion(true);
        });
        break;
      case 'PasswordResetRequiredException':
        //Que hacer en caso de que cognito resetee la contraseña dle usuario y deba cambiarse
        break;
      case 'NotAuthorizedException':
        this.setState({ password: '' }, () => {
          this.props.setSnackBarState({
            open: true,
            message: 'Usuario o contraseña incorrectos',
          });
        });
        break;
      case 'UserNotFoundException':
        this.setState({ username: '', password: '' }, () => {
          this.props.setSnackBarState({
            open: true,
            message: 'El ususario no existe',
          });
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { username, password, usernameCodigoVerificacion, redirect, pathToRedirect } = this.state;
    const { loading } = this.props;
    if (redirect) return <Redirect to={{ pathname: '/Home' }} />;
    return (
      <div style={{ maxWidth: '400px', margin: '0 auto' }}>
        <a href='https://tecnofactor.co/portalfactura.html'>
          <div className='logo-container'>
            <img src={LogoTecno} alt='Logo Tecnofactor' className='logo-tecnofactor' />
          </div>
        </a>
        <div className='card-body'>
          <div className='container-logo'>
            <img src={LogoPortal} className='logo-login' />
          </div>
          <form className='form-signin' autoComplete='off'>
            <div className='container-input'>
              <Input
                placeholder='Nombre de usuario'
                className='ant-input'
                onChange={this.handleChange}
                required
                value={username}
                name='username'
              />
            </div>
            <div className='container-input'>
              <Input
                placeholder='Contraseña'
                className='ant-input'
                onChange={this.handleChange}
                required
                name='password'
                type='password'
              />
            </div>
            <Button
              className='btn btn-lg btn-block botton-login'
              onClick={this.handleLogin}
              disabled={loading}
            >
              {loading && (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
              Ingresar
            </Button>
            <hr className='my-2' />
            <Link
              style={{ textDecoration: 'none' }}
              to={{
                pathname: '/recordar-password',
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  textAlign: 'center',
                  borderBottom: '1px solid #F2F2F2',
                }}
              >
                <span className='text-muted'>Olvidé mi contraseña</span>
              </div>
            </Link>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <p>
                <span style={{ color: 'black' }}>¿No tienes una cuenta? </span>
                <Link
                  to={{
                    pathname: '/nuevo-emisor',
                  }}
                >
                  <span style={{ color: '#3C3588', textDecorationLine: 'underline' }}>
                    Registrarse
                  </span>
                </Link>
              </p>
            </div>
          </form>
          <div className='container-icons'>
            <a
              className='icon-fm'
              target='_blank'
              href='https://www.facebook.com/profile.php?id=61551547765425&mibextid=LQQJ4d'
            >
              <FacebookOutlined />
            </a>
            <a className='icon-fm' target='_blank' href='https://www.instagram.com/portal.factura/'>
              <InstagramOutlined />
            </a>
            <a
              className='icon-fm'
              target='_blank'
              href='https://www.tiktok.com/@portal.factura?_t=8jMGSS305Li&_r=1'
            >
              <TikTokOutlined />
            </a>
          </div>
        </div>

        <CodigoVerificacionModal username={usernameCodigoVerificacion} />
        <SnackBarMensajes />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    loading: state.factura.loading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setLoadingValue: (item) => dispatch(SetLoadingValue(item)),
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  setSnackBarState: (item) => dispatch(SetSnackBarState(item)),
  setModalCodigoConfirmacion: (item) => dispatch(SetModalCodigoConfirmacion(item)),
  setUserAttributes: (item) => dispatch(SetUserAttributes(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
