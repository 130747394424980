import * as React from 'react';
import Button from 'components/atoms/Button';
import Modal from 'components/molecules/Modal';
import FormulariodatosTransaccion from './datosTransaccion';
import FormularioDatosUsuarios from './datosUsuarios';
import FormularioConsultas from './formularioConsultas';
import useModal from 'hooks/useModal';
const DEFAULT = {};
const steps = {
  'Datos de la transacción': {
    name: 'Datos de la transacción',
    nextStep: 'Datos de los usuarios',
    validar: false,
  },
  'Datos de los usuarios': {
    name: 'Datos de los usuarios',
    previousStep: 'Datos de la transacción',
    nextStep: 'Datos de los servicios',
    validar: false,
  },
  'Datos de los servicios': {
    name: 'Datos de los servicios',
    previousStep: 'Datos de los usuarios',
    nextStep: 'finish',
    validar: false,
  },
};
const formularioSalud = () => {
  const [currentStep, setCurrentStep] = React.useState(steps['Datos de la transacción']);
  const [salud, setDataSalud] = React.useState(DEFAULT);
  const [modalProps, openModal, closeModal] = useModal();

  const changeStep = async (esSiguiente) => {
    const { nextStep, previousStep } = currentStep;
    const stepChanged = esSiguiente ? nextStep : previousStep;

    if (stepChanged !== 'finish') {
      setCurrentStep(steps[stepChanged]);
    } else {
      setSalud({ openContrato: true });
    }
  };

  React.useEffect(() => {
    setSalud({ isSalud: salud.msTipoUsuarios ? true : false });
  }, []);

  const setSalud = (data) => setDataSalud((a) => ({ ...a, ...data }));

  const submitForm = (e) => {
    callOpenmodal();
  };

  function callOpenmodal() {
    openModal({
      textAlignContent: 'justify',
      content:
        'Según el Ministerio de Salud y Protección Social, se modifican las Resoluciones 2275 de 2023 y 558 de 2024 en relación con la transición prevista para la implementación del RIPS como soporte de la FEV en salud, y la plataforma del mecanismo único de validación estará en funcionamiento a partir del 1 de febrero de 2025.',
      onCancel: null,
    });
  }
  return (
    <form onSubmit={submitForm} id='formSalud'>
      <main className='nd-main'>
        <header className='nd-header'>
          <div className='nd-container'>
            <div className='nd-header-bottom'>
              <button
                className={
                  currentStep.name === 'Datos de la transacción' ? 'nd-button-act' : 'nd-button'
                }
              >
                Datos de la transacción
              </button>
              <button
                className={
                  currentStep.name === 'Datos de los usuarios' ? 'nd-button-act' : 'nd-button'
                }
              >
                Datos de los usuarios
              </button>
              <button
                className={
                  currentStep.name === 'Datos de los servicios' ? 'nd-button-act' : 'nd-button'
                }
              >
                Datos de los servicios
              </button>
            </div>
          </div>
        </header>
        <div className='nd-content'>
          <div className='nd-container'>
            {currentStep.name === 'Datos de la transacción' && <FormulariodatosTransaccion />}
            {currentStep.name === 'Datos de los usuarios' && <FormularioDatosUsuarios />}
            {currentStep.name === 'Datos de los servicios' && <FormularioConsultas />}
            <div className='nd-footer'>
              {currentStep.nextStep !== 'finish' && (
                <Button onClick={() => changeStep(true)}>Continuar</Button>
              )}
              {currentStep.nextStep === 'finish' && (
                <Button onClick={() => submitForm()}>Enviar</Button>
              )}
              {currentStep.previousStep && (
                <Button onClick={() => changeStep(false)}>Anterior</Button>
              )}
            </div>
          </div>
        </div>
      </main>
      <Modal
        title='Funcionalidad en Progreso - En funcionamiento el 1 de febrero de 2025'
        onAccept={closeModal}
        {...modalProps}
      />
    </form>
  );
};

export default formularioSalud;
