import React, { useEffect, useState } from 'react';
import Select from 'components/molecules/Select';
import UnidadesMedidads from 'static/enums/tiposUnidadMedida';

function SelectUnidadMedida({ label = 'Unidad de medida', ...rest }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(
      UnidadesMedidads.map(({ codigo, nombre }) => ({
        label: nombre,
        value: codigo,
      })),
    );
  }, []);

  return <Select label={label} options={options} {...rest} />;
}

export default SelectUnidadMedida;
