const tiposPago = [
    {
        codigo:"CONTADO",
        nombre:"Pago de contado"
    },
    {
        codigo:"CREDITO",
        nombre:"Pago a crédito"
    }]

export default tiposPago;