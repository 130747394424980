import { serviceRoutes, get, post } from "./index";

export const getDevengadosByEmpleado = async (empleado) =>
  await get(serviceRoutes.consultarDevengadosPorEmpleado, `${empleado}`);

export const guardarDevengado = async (body) =>
  await post(serviceRoutes.guardarDevengado, body);

export const eliminarDevengado = async (body) =>
  await post(serviceRoutes.eliminarDevengado, body);
