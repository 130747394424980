import * as React from 'react';
import File from 'components/Inputs/File';
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button';
import Input from 'components/molecules/Input';
import { registrarGasto } from 'network/Api/Gasto';
import { consultarSaldoEmisor } from 'network/Api/Saldo';
import { registrarRecarga } from 'network/Api/Recarga';
import { consultarParametroPorNombre } from 'network/Api/Parametro';
import EstadosEventos from './ActualizarEstadosEventos.jsx';
import ButtonUploadExcel from 'components/organisms/ButtonUploadExcelCufe/index.jsx';
import Typography from 'components/atoms/Typography';

const RegistrarGasto = ({ emisor, consultarGastos, loading, openModal, openModalOptions }) => {
  const [state, setData] = React.useState({});

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  const handleChangeFile = (event) => {
    const file = event.target.files[0];
    const { type } = file;
    const disableButton =
      type !== 'text/xml' && type !== 'application/x-zip-compressed' && type !== 'application/zip';
    setState({ file, fileName: file.name, disableButton });
  };

  const guardarGasto = () => {
    validarSaldoEmisor().then((isEnvioInvalido) => {
      if (isEnvioInvalido)
        openModalOptions({
          mensaje: '¿Comprar 1 documento?',
          onAccept: comprarDocumento,
        });
      else enviarGasto();
    });
  };

  const enviarGasto = () => {
    const { file, cufe = '' } = state;
    if (file || cufe) {
      if (cufe.length > 90 || cufe.length === 0) {
        setState({ disableButton: true });
        registrarGasto({ file, emisor, cufe }).then(({ data }) => {
          setState({ disableButton: false });
          openModal(getMessage(data));
          consultarGastos();
          loading(false);
          setState({ file: undefined, fileName: '', cufe: '' });
        });
      } else openModal('CUFE invalido');
    } else openModal('Agrega un CUFE o sube un XML por favor');
  };

  const getMessage = (response) => {
    if (response === 'EXITOSO') return 'XML procesado correctamente';
    return response.startsWith('ERROR') ? 'Error procesando XML' : response;
  };

  const comprarDocumento = () => {
    loading(true);
    consultarParametroPorNombre({ parametro: 'PLAN_RECARGA_UNO' }).then(({ data }) => {
      registrarRecarga({
        emisor: { nmId: emisor },
        plan: { nmId: data },
        cdCanal: 'PARALELA',
      }).then(({ data, status }) => {
        loading(false);
        if (status === 200)
          openModalOptions(
            data
              ? {
                  mensaje: 'Compra realizada correctamente',
                  onAccept: enviarGasto,
                  onCancel: enviarGasto,
                }
              : { mensaje: 'Saldo insuficiente' },
          );
      });
    });
  };

  const validarSaldoEmisor = async () => {
    const { data } = await consultarSaldoEmisor({ emisor });
    const documentosFaltantes = await consultarParametroPorNombre({
      parametro: 'DOCUMENTOS_FALTANTES_RECARGA',
    });
    return data <= documentosFaltantes.data;
  };

  return (
    <>
      <Grid
        container
        spacing={8}
        style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-end' }}
      >
        <Grid item xs={4}>
          <File
            label='Selecciona el archivo a subir (XML o ZIP)'
            inputText={state.fileName}
            onChange={handleChangeFile}
          />
        </Grid>
        <Grid item xs={4} style={{ margin: '3px' }}>
          <Input
            labelVariant='labelInput'
            label='Ingresar CUFE del documento'
            value={state.cufe}
            onChange={({ target }) => setState({ cufe: target.value })}
          />
        </Grid>
        <Grid item xs={2}>
          <Button disabled={state.disableButton} onClick={guardarGasto}>
            Procesar
          </Button>
        </Grid>
        <Grid container spacing={8} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='labelInput'>Realizar eventos masivamente Recibo</Typography>
            <EstadosEventos emisor={emisor} />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='labelInput'>Subir y procesar archivo masivo de CUFES</Typography>
            <ButtonUploadExcel emisor={emisor} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RegistrarGasto;
