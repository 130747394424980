import React, { Component } from "react";
import DocumentosChart from "../../../../components/Chart/Documentos";
import Select from "../../../../components/Inputs/Select";
import Input from "../../../../components/Inputs/Input";
import SelectEmisores from "../../../../components/ServicioCliente/SelectConsultaEmisores";
import { PERIODOS_DISPONIBLES } from "../../../../static/PeriodosConsultaEstadisticas";

export default class Estadisticas extends Component {
  state = {
    intervalo: 1,
    periodo: "WEEKLY",
    nmIdEmisor: null,
    documentos: [],
    loading: false,
  };

  handleChange = (event) => {
    let label = event.target.id;
    let value = event.target.value;
    switch (label) {
      case "periodo":
        this.setState({ [label]: value });
        break;
      default:
        this.setState({ [label]: value });
        break;
    }
  };

  handleChangeSelect = (event, label) =>
    this.setState({ [label]: event.value });

  handleConsultarEmisor = (event) => {
    this.setState({ nmIdEmisor: event.id });
  };

  render() {
    const { intervalo, periodo, nmIdEmisor } = this.state;
    return (
      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <SelectEmisores handleConsultarEmisor={this.handleConsultarEmisor} />
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <Input
              margin="5px"
              label="Seleccione la cantidad de periodos"
              id="intervalo"
              value={intervalo}
              onChange={this.handleChange}
              type="number"
            />
            <Select
              margin="5px"
              label="Seleccione el tipo de periodo"
              defaultValue={PERIODOS_DISPONIBLES.filter(
                (p) => p.value === periodo
              )}
              options={PERIODOS_DISPONIBLES}
              onChange={(e) => this.handleChangeSelect(e, "periodo")}
            />
          </div>
        </form>
        <DocumentosChart
          intervalo={intervalo}
          periodo={periodo}
          emisor={nmIdEmisor}
        />
      </div>
    );
  }
}
