import React from 'react';
import MUExpansionPanel from '@material-ui/core/ExpansionPanel';
import MUExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';

const ExpansionPanel = ({ title, body }) => {
  return (
    <MUExpansionPanel>
      <MUExpansionPanelSummary expandIcon={<ExpandMore />}>
        <b>{title}</b>
      </MUExpansionPanelSummary>
      {body}
    </MUExpansionPanel>
  );
};

export default ExpansionPanel;
