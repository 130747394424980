import * as React from "react";
import LabelTitulos from "components/layout/labelTitulos";
import Input from "components/Inputs/Input";
import Button from 'components/atoms/Button';
import { consultarTiqueteraPorSerial } from "network/Api/Tiquetera";
import { consultarJsonDocumentoExpress } from "network/Api/DocumentoExpress";
import { now } from "utils/DateUtils";

const DatosExpress = ({ setDocumento, limpiarFormulario, documento, openModal }) => {
  const [serial, setSerial] = React.useState();
  const [serialReferencia, setSerialReferencia] = React.useState();
  const [adquirente, setAdquirente] = React.useState();

  const consultarTiquetera = async () => {
    limpiarFormulario();
    const { data } = await consultarTiqueteraPorSerial({ serial });
    if (data) setDocumento({ codigoEmisor: data ? data.emisor.nmId : false, serial });
    else openModal({ content: `El serial ${serial} no fue encontrado o ya fue utlizado anteriormente` });
  };

  const consultarDocumentoReferencia = async () => {
    const { data } = await consultarJsonDocumentoExpress({ serialReferencia, adquirente });
    if (data) {
      setDocumento({
        ...data,
        serial: serialReferencia,
        dsPrefijo: "NC",
        dsNumeroFactura: "",
        tipoDocumentoElectronico: "NOTA_CREDITO",
        facturasReferencia: [{ prefijo: data.dsPrefijo, numero: data.dsNumeroFactura, conceptoNotaCredito: "OTROS" }],
        fechaEmision: now().format("YYYY-MM-DD"),
        fechaVencimiento: now()
          .add(1, "month")
          .format("YYYY-MM-DD"),
      });
    } else openModal({ content: `No fue encontrado ningun documento relacionado al serial ${serialReferencia}` });
  };

  return (
    <div className="form-row" style={{ padding: "5px" }}>
      <div className="form-group col-md-12 col-xs-12">
        <LabelTitulos texto="Datos Documento Express" />
      </div>
      {documento.tipoDocumentoElectronico === "VENTA" && (
        <div className="form-group col-md-3 col-xl-3">
          <Input label="Serial *" value={serial} onChange={({ target }) => setSerial(target.value)} />
        </div>
      )}
      {documento.tipoDocumentoElectronico === "NOTA_CREDITO" && (
        <>
          <div className="form-group col-md-3 col-xl-3">
            <Input label="Identificacion Cliente *" value={adquirente} onChange={({ target }) => setAdquirente(target.value)} />
          </div>
          <div className="form-group col-md-3 col-xl-3">
            <Input label="Serial Documento Referencia *" value={serialReferencia} onChange={({ target }) => setSerialReferencia(target.value)} />
          </div>
        </>
      )}
      <div className="form-group col-md-1 col-xl-1" style={{ display: "flex", alignItems: "flex-end" }}>
        <Button onClick={documento.tipoDocumentoElectronico === "VENTA" ? consultarTiquetera : consultarDocumentoReferencia}>Validar Serial</Button>
      </div>
    </div>
  );
};

export default DatosExpress;
