import { serviceRoutes, post, get, postBlob, getBlob } from "./index";

export async function enviar(body) {
  return await post(serviceRoutes.enviarDocumentoPos, body);
}

export async function consultarUrlPorPin({ pin }) {
  return await get(serviceRoutes.consultarUrlPorPin, `?pin=${pin}`);
}

export async function consultarDocumentosPorEmisor({ id, feInicio, feFin, dsPrefijo = "", dsNumDoc = "" }) {
  return await get(serviceRoutes.consultarDocumentosPorEmisor, `?id=${id}&inicio=${feInicio}&fin=${feFin}&prefijo=${dsPrefijo}&numdoc=${dsNumDoc}`);
}

export async function anularDocumentoPos(body) {
  return await post(serviceRoutes.anularDocumentoPos, body);
}

export async function regenerarPdfDocumentoPos(body) {
  return await post(serviceRoutes.regenerarPdfDocumentoPos, body);
}

export async function generarDocumentoElectronico(body, idEmisor) {
  return await post(`${serviceRoutes.generarDocumentoElectronico}?id=${idEmisor}`, body);
}

export async function generarPrevisualizacionPos(body, idEmisor) {
  return await postBlob(`${serviceRoutes.generarPrevisualizacionPos}?id=${idEmisor}`, body);
}

export const generarCierreCajaPos = async (body) => await postBlob(`${serviceRoutes.generarCierreCajaPos}`, body);

export const generarConsolidadoVentasPos = async ({ feInicio, feFin, puntosSelected, emisor }) =>
  await getBlob(
    `${serviceRoutes.generarConsolidadoVentasPos}`,
    `?inicio=${feInicio}&fin=${feFin}&puntos=${puntosSelected.join(",")}&emisor=${emisor}`
  );

export const generarDetalleCierreCajaPos = async ({ feInicio, feFin, puntosSelected, emisor }) =>
  await getBlob(
    `${serviceRoutes.generarDetalleCierreCajaPos}`,
    `?inicio=${feInicio}&fin=${feFin}&puntos=${puntosSelected.join(",")}&emisor=${emisor}`
  );
