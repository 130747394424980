import * as React from "react";
import Select from "components/Inputs/Select";
import TipoPersona from "static/enums/tiposPersona";

const SelectTipoOperacion = ({ value, ...rest }) => {
  const options = TipoPersona.map(({ nombre, tipoPersonaAdquiriente }) => ({
    label: nombre,
    value: tipoPersonaAdquiriente,
  }));

  return (
    <Select
      value={options.find((to) => to.value === value)}
      options={options}
      {...rest}
    />
  );
};

export default SelectTipoOperacion;
