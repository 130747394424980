const tiposResponsabilidadesFiscales = [
  { codigo: "O-13", nombre: "Gran contribuyente" },
  { codigo: "O-15", nombre: "Autorretenedor" },
  { codigo: "O-23", nombre: "Agente de retención IVA" },
  { codigo: "O-47", nombre: "Régimen simple de tributación" },
  { codigo: "R-99-PN", nombre: "No responsable" },
];

export const selectOptions = tiposResponsabilidadesFiscales.map(
  ({ codigo, nombre }) => ({ label: nombre, value: codigo })
);

export default tiposResponsabilidadesFiscales;
