import * as React from "react";
import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Input from "components/Inputs/Input";
import Button from 'components/atoms/Button';
import { validarConsultaDocumentoExpress } from "network/Api/DocumentoExpress";
import { descargarFactura } from "network/Domina";
import Lucy from "static/images/Lucy.png";
import Modal from "components/modal/ModalConfirmacion";

const styles = (theme) => ({
  paper: {
    width: "50%",
    margin: "50px auto",
    padding: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "5px",
  },
  inputContainer: {
    padding: "10px",
  },
  lucy: { display: "flex", justifyContent: "center" },
});

const ConsultarDocumentoExpress = ({ classes }) => {
  const [state, setData] = React.useState({});

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  const hanldeChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  const validarConsulta = async () => {
    const { identificacion, serial } = state;
    const { data } = await validarConsultaDocumentoExpress({ identificacion, serial });
    if (data) {
      const body = getBody(data);
      const { token } = data;
      const response = await descargarFactura(body, token);
      const blob = await response.blob();
      descargar({ body, blob });
    } else openModal("Los datos ingresados no coinciden");
  };

  const descargar = ({ body, blob }) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const pdf = new Blob([blob], { type: "application/pdf" });
    const url = URL.createObjectURL(pdf);
    a.href = url;
    a.id = `${body.factura.prefijo}${body.factura.numero}`;
    a.download = `${body.factura.prefijo}${body.factura.numero}.pdf`;
    a.click();
  };

  const getBody = ({ identificacion, jsonDocumento }) => ({
    tipoIdentificacion: "NIT",
    identificacion,
    factura: {
      prefijo: jsonDocumento.dsPrefijo,
      numero: jsonDocumento.dsNumeroFactura,
      tipo: jsonDocumento.tipoDocumentoElectronico === "EXPORTACION" ? "VENTA" : jsonDocumento.tipoDocumentoElectronico,
      formato: "PDF",
      desencriptaPDF: "S",
    },
  });

  const openModal = (content) => setState({ open: true, content });

  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.lucy}>
          <img src={Lucy} alt="Lucy" />
        </div>
        <div className={classes.inputContainer}>
          <Input label="Ingresa el número de documento del emisor *" name="identificacion" value={state.identificacion} onChange={hanldeChange} />
          <Input label="Ingresa el serial de la ticketera *" name="serial" value={state.serial} onChange={hanldeChange} />
        </div>
        <div className={classes.buttonContainer}>
          <Button onClick={validarConsulta}>Consultar</Button>
        </div>
      </Paper>
      <Modal open={state.open} title="Error" content={state.content} onCancel={() => setState({ open: false })} />
    </div>
  );
};

export default withStyles(styles)(ConsultarDocumentoExpress);
