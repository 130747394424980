import React, { useMemo } from 'react';
import { Flex, Row, Col } from 'antd';
import { TotalTitle, StrongTotal, GridTotales } from './styles';
import { moneyFormat } from 'utils/TextFormattingUtils';
import { numeroALetras } from 'utils/funcionesUtiles';

const Totales = ({ detalles = [], documento }) => {
  const totalBruto = useMemo(() => {
    return detalles.reduce((total, { cantidad, precioUnitario, valorTotalDescuento }) => {
      return total + (cantidad * precioUnitario - valorTotalDescuento);
    }, 0);
  }, [detalles]);

  const totalReteIca = useMemo(
    () => (documento.dsRetencionIca * totalBruto) / 100,
    [documento.dsRetencionIca, totalBruto],
  );

  const isExportacion = useMemo(
    () => documento.tipoDocumentoElectronico === 'EXPORTACION',
    [documento.tipoDocumentoElectronico],
  );

  const totalIva = useMemo(() => {
    return detalles.reduce((total, { porcentajeIva, cantidad, precioUnitario }) => {
      return total + (porcentajeIva * (cantidad * precioUnitario)) / 100;
    }, 0);
  }, [detalles]);

  const totalConsumo = useMemo(() => {
    return detalles.reduce((total, { ico = 0, cantidad, precioUnitario }) => {
      return total + (ico * (cantidad * precioUnitario)) / 100;
    }, 0);
  }, [detalles]);

  const totalPagar = useMemo(
    () => totalBruto + totalIva + totalConsumo,
    [totalBruto, totalIva, totalConsumo],
  );

  const valorLetras = useMemo(() => numeroALetras(totalPagar), [detalles]);

  const handleNaN = (value) => (isNaN(value) ? 0 : value);

  return (
    <Flex vertical align='end' style={{ margin: '30px 0px' }}>
      {isExportacion && (
        <Row style={{ width: '600px' }}>
          <TotalTitle variant='h6'>
            Tasa de cambio aplicada: COP ${documento.valorCambioTrm}
          </TotalTitle>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Totales</TotalTitle>
            </Col>
            <Col span={8}>
              <TotalTitle>{documento.otraMoneda}</TotalTitle>
            </Col>
            <Col span={8}>
              <TotalTitle>{documento.moneda}</TotalTitle>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Otros Impuestos:</TotalTitle>
            </Col>
            <Col span={8}>
              <StrongTotal></StrongTotal>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(documento.otroImpuestoTotal)}</StrongTotal>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Descuento: </TotalTitle>
            </Col>
            <Col span={8}>
              <StrongTotal>
                {moneyFormat(handleNaN(documento.descuentoTotalOtraMoneda))}
              </StrongTotal>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(documento.descuentoTotal)}</StrongTotal>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Total IVA: </TotalTitle>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(handleNaN(documento.valorIvaOtraMoneda))}</StrongTotal>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(documento.valorIva)}</StrongTotal>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Total INC: </TotalTitle>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(handleNaN(documento.valorIncOtraMoneda))}</StrongTotal>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(documento.valorTotalImpuestoConsumo)}</StrongTotal>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Rete ICA:</TotalTitle>
            </Col>
            <Col span={8}>
              <StrongTotal>
                {moneyFormat(handleNaN(totalReteIca / documento.valorCambioTrm))}
              </StrongTotal>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(totalReteIca)}</StrongTotal>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Total Bruto: </TotalTitle>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(handleNaN(documento.valorBrutoOtraMoneda))}</StrongTotal>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(documento.valorBruto)}</StrongTotal>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Total a Pagar:</TotalTitle>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(handleNaN(documento.valorNetoOtraMoneda))}</StrongTotal>
            </Col>
            <Col span={8}>
              <StrongTotal>{moneyFormat(documento.totalPagar)}</StrongTotal>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <TotalTitle>Total en letras: </TotalTitle>
            </Col>
            <Col span={8}>
              <StrongTotal>{documento.valorNetoLetras}</StrongTotal>
            </Col>
          </Row>
        </Row>
      )}
      {!isExportacion && (
        <GridTotales>
          <TotalTitle>
            Otros impuestos: <StrongTotal>{moneyFormat(documento.otroImpuestoTotal)}</StrongTotal>
          </TotalTitle>
          <TotalTitle>
            Rete ICA: <StrongTotal>{moneyFormat(handleNaN(totalReteIca))}</StrongTotal>
          </TotalTitle>
          <TotalTitle>
            Rete Fuente: <StrongTotal>{moneyFormat(documento.dsRetencionFuente)}</StrongTotal>
          </TotalTitle>
          <TotalTitle>
            Total Bruto:{' '}
            <StrongTotal>{moneyFormat(documento.valorBruto || totalBruto)}</StrongTotal>
          </TotalTitle>
          <TotalTitle>
            Descuento: <StrongTotal>{moneyFormat(documento.descuentoTotal)}</StrongTotal>
          </TotalTitle>
          <TotalTitle>
            Rete IVA: <StrongTotal>{moneyFormat(documento.dsRetencionIva)}</StrongTotal>
          </TotalTitle>
          <TotalTitle>
            Total IVA: <StrongTotal>{moneyFormat(documento.valorIva || totalIva)}</StrongTotal>
          </TotalTitle>
          <TotalTitle>
            Total a Pagar:{' '}
            <StrongTotal>{moneyFormat(documento.totalPagar || totalPagar)}</StrongTotal>
          </TotalTitle>
          <TotalTitle>
            Total INC:{' '}
            <StrongTotal>
              {moneyFormat(documento.valorTotalImpuestoConsumo || totalConsumo)}
            </StrongTotal>
          </TotalTitle>
          <TotalTitle style={{ gridArea: 'letras' }}>
            Total en letras: <StrongTotal>{documento.valorNetoLetras || valorLetras}</StrongTotal>
          </TotalTitle>
        </GridTotales>
      )}
    </Flex>
  );
};

export default Totales;
