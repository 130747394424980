import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { Table, TableBody, Paper } from '@material-ui/core';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import Button from 'components/atoms/Button';
import { moneyFormat } from 'utils/TextFormattingUtils';

const styles = (theme) => ({
  root: { marginTop: theme.spacing.unit * 1, overflowX: 'scroll' },
  table: { minWidth: 600 },
  head: {
    backgroundColor: '#3C3588',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  headText: { color: theme.palette.common.white },
  bodyTotales: {
    fontWeight: 'bold',
    backgroundColor: '#3C3588',
    color: theme.palette.common.white,
  },
  bodyDetalles: { fontWeight: 'bold', color: theme.palette.common.black },
  icon: { margin: theme.spacing.unit * 2 },
  buttonAdd: { backgroundColor: '#92C63E', color: theme.palette.common.white },
});

const TablaDescuento = ({
  descuentos = [],
  isCopagoModeradora = false,
  classes,
  setDetalle,
  isRegistroDoc = false,
}) => {
  const deleteDescuento = (id) =>
    setDetalle({
      cargosDescuentos: descuentos.filter((descuento) => descuento.id !== id),
    });

  const getArray = () =>
    isRegistroDoc
      ? descuentos
      : isCopagoModeradora
        ? descuentos.filter((d) => d.razon.includes('CUOTA'))
        : descuentos.filter((d) => !d.razon.includes('CUOTA'));
  return getArray().length > 0 ? (
    <React.Fragment>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.headText}>Borrar</TableCell>
              {!isRegistroDoc && <TableCell className={classes.headText}>Codigo</TableCell>}
              {!isRegistroDoc && <TableCell className={classes.headText}>Porcentaje</TableCell>}
              <TableCell className={classes.headText}>Valor</TableCell>
              {!isRegistroDoc && <TableCell className={classes.headText}>Valor base</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getArray().map((row) => (
              <TableRow className={classes.bodyDetalles} key={row.id}>
                <TableCell>
                  <Button onClick={() => deleteDescuento(row.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
                {!isRegistroDoc && <TableCell>{row.razon}</TableCell>}
                {!isRegistroDoc && <TableCell align='right'>{row.porcentaje} %</TableCell>}
                <TableCell align='right'>{moneyFormat(row.valor)}</TableCell>
                {!isRegistroDoc && <TableCell align='right'>{row.valorBase}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  ) : (
    <></>
  );
};

TablaDescuento.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TablaDescuento);
