import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Select from "components/Inputs/Select";
import Input from "components/Inputs/Input";
import DocumentosChart from "components/Chart/Documentos";
import { PERIODOS_DISPONIBLES } from "static/PeriodosConsultaEstadisticas";

const styles = () => ({
  inputs: { display: "flex", alignItems: "flex-end" },
});

function Estadisticas({ classes, userAttributes }) {
  const [intervalo, setIntervalo] = useState(3);
  const [maxIntervalo, setMaxIntervalo] = useState(365);
  const [periodo, setPeriodo] = useState("DAYLY");

  const setMaxConsulta = (maxConsulta, inter) => {
    if (maxConsulta < inter) setIntervalo(maxConsulta);
  };

  const getPeriodo = () => PERIODOS_DISPONIBLES.find((p) => p.value === periodo);

  return (
    <div>
      <form className={`form-row col-xl-12 ${classes.inputs}`}>
        <div className="form-group col-xl-6">
          <Input
            margin="5px"
            label="Ingrese la cantidad de periodos a consultar"
            id="intervalo"
            value={intervalo}
            onChange={({ target }) => {
              let value = target.value.replace(/[^0-9]/g, "");
              if (value === "0") value = "1";
              setIntervalo(value);
              setMaxConsulta(getPeriodo().maxConsulta, value);
            }}
          />
        </div>
        <div className="form-group col-xl-6">
          <Select
            margin="5px"
            label="Seleccione el tipo de periodo"
            options={PERIODOS_DISPONIBLES}
            value={[getPeriodo()]}
            onChange={(e) => {
              setPeriodo(e.value);
              setMaxIntervalo(e.maxIntervalSync);
              setMaxConsulta(e.maxConsulta, intervalo);
            }}
          />
        </div>
      </form>
      <DocumentosChart periodo={periodo} intervalo={intervalo} emisor={userAttributes.codigoEmisor} maxIntervalo={maxIntervalo} />
    </div>
  );
}

const mapStateToProps = (state, _) => ({ userAttributes: state.factura.userAttributes });

const mapDispatchToProps = (_) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Estadisticas));
