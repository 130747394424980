import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import TablaGenerica from 'components/Tablas/TablaGenerica';
import { exportTableToExcel } from 'utils/ExportarExcelUtil';
import ExportExcelIco from 'static/images/excel.png';
import { SetSpinnerModal } from 'reducers/actions/facturaActions';
import { Button, Table, TableCell, TableBody, TableRow, TableHead } from '@material-ui/core';
import { consultarEntreFechas } from 'network/Api/HistoricoInventario';
import Input from 'components/molecules/Input';
const { useState, useCallback, useMemo, useEffect } = React;

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0px 10px',
  },
  filtersContainer: {
    justifyContent: 'center',
    marginBottom: '3%',
  },
};

const ID_TABLE = 'detallesDocumentosTable';
const format = moment().format('YYYY-MM-DD');

const MovimientoInventario = ({ userAttributes }) => {
  const [form, setFormData] = useState({ inicio: format, fin: format });
  const [historico, setHistorico] = useState([]);

  const setForm = useCallback((data) => setFormData((d) => ({ ...d, ...data })), [setFormData]);

  const consultar = useCallback(async () => {
    const { codigoEmisor: emisor } = userAttributes;
    const { inicio, fin } = form;
    const { data = [] } = await consultarEntreFechas({ inicio, fin, emisor });
    setHistorico(data);
  }, [form, userAttributes]);

  useEffect(() => {
    if (userAttributes.codigoEmisor) consultar();
  }, [userAttributes]);

  const handleChange = useCallback(
    ({ target }) => {
      const { name, value } = target;
      setForm({ [name]: value });
    },
    [setForm],
  );

  const tablaHistorico = useMemo(
    () =>
      historico.map((h) => {
        const { producto, dsMovimiento, nmAjuste, nmNuevaCantidad, feCreacion, cdTipoMovimiento } =
          h;
        const { dsCodigo, dsDescripcion } = producto;
        return {
          cdCodigo: dsCodigo,
          dsDescripcion,
          dsMovimiento,
          nmCantidad: nmAjuste,
          nmNuevaCantidad,
          feMovimiento: feCreacion,
          cdTipoMovimiento,
          nmAnteriorCantidad: nmNuevaCantidad - nmAjuste,
        };
      }),
    [historico],
  );

  return (
    <div>
      <div className='form-row' style={styles.filtersContainer}>
        <div className='col-xs-6'>
          <Input
            label='Fecha Inicio'
            onChange={handleChange}
            name='inicio'
            type='date'
            value={form.inicio}
          />
        </div>
        <div className='col-xs-6'>
          <Input
            label='Fecha Fin'
            onChange={handleChange}
            type='date'
            value={form.fin}
            name='fin'
          />
        </div>
        <div style={styles.buttonContainer}>
          <Button onClick={consultar} color='primary' variant='contained'>
            Consultar
          </Button>
        </div>
        <div style={styles.buttonContainer}>
          <Button onClick={() => exportTableToExcel(ID_TABLE, 'Detalles Facturas')}>
            <img src={ExportExcelIco} alt='Exportar a excel' />
          </Button>
        </div>
      </div>
      <TablaGenerica
        headers={[
          'Codigo',
          'Producto',
          'Movimiento',
          'Tipo de movimiento',
          'Cantidad antes del movimiento',
          'Cantidad Movida',
          'Cantidad actual',
          'Fecha Movimiento',
        ]}
        keys={[
          'cdCodigo',
          'dsDescripcion',
          'dsMovimiento',
          'cdTipoMovimiento',
          'nmAnteriorCantidad',
          'nmCantidad',
          'nmNuevaCantidad',
          'feMovimiento',
        ]}
        rowsPerPage={10}
        detalles={tablaHistorico}
      />
      <Table style={{ display: 'none' }} id={ID_TABLE}>
        <TableHead>
          <TableRow>
            <TableCell>Codigo</TableCell>
            <TableCell>Producto</TableCell>
            <TableCell>Movimiento</TableCell>
            <TableCell>Tipo de movimiento</TableCell>
            <TableCell>Cantidad antes del movimiento</TableCell>
            <TableCell>Cantidad Movida</TableCell>
            <TableCell>Cantidad actual</TableCell>
            <TableCell>Fecha Movimiento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tablaHistorico.map((item, key) => (
            <TableRow key={key}>
              <TableCell>{item.cdCodigo}</TableCell>
              <TableCell>{item.dsDescripcion}</TableCell>
              <TableCell>{item.dsMovimiento}</TableCell>
              <TableCell>{item.cdTipoMovimiento}</TableCell>
              <TableCell>{item.nmAnteriorCantidad}</TableCell>
              <TableCell>{item.nmCantidad}</TableCell>
              <TableCell>{item.nmNuevaCantidad}</TableCell>
              <TableCell>{item.feMovimiento}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MovimientoInventario);
