import * as React from "react";
import Input from "components/Inputs/Input";
import { validarString, TIPOS_DATOS } from "utils/ValidarDatos";
import { consultarDocumentoExistente } from "network/Api/Documento";

const InputNumDocumento = ({
  maximo,
  minimo,
  automatico,
  value,
  setDocumento,
  onChange,
  documento,
  emisor,
  ...rest
}) => {
  const handleBlur = () => {
    validarRango();
    validarDocumentoPreviamenteEnviado();
  };

  const validarRango = () => {
    const { tipoDocumentoElectronico } = documento;
    const numDoc = parseInt(documento.dsNumeroFactura);
    if (
      (numDoc < minimo || numDoc > maximo) &&
      !tipoDocumentoElectronico.startsWith("NOTA_")
    ) {
      alert("El documento se encuentra fuera del rango de facturacion");
      setDocumento({ dsNumeroFactura: "" });
    }
  };

  const validarDocumentoPreviamenteEnviado = () => {
    const { dsPrefijo, dsNumeroFactura } = documento;
    consultarDocumentoExistente({
      emisorId: emisor,
      prefijo: dsPrefijo !== "SIN_PREFIJO" ? dsPrefijo : "",
      numDoc: dsNumeroFactura,
    }).then(({ data }) => {
      if (data) {
        alert(
          "El número de factura ya se encuentra procesado en nuestro sistema."
        );
        setDocumento({ dsNumeroFactura: "" });
      }
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (validarString(value, TIPOS_DATOS.numeros) || "") onChange(event);
  };

  const isDisabled = () => {
    const { tipoDocumentoElectronico } = documento;
    if (tipoDocumentoElectronico.startsWith("NOTA_")) return false;
    if (automatico === "S") return true;
    return false;
  };

  return (
    <Input
      value={parseInt(value) || ""}
      disabled={isDisabled()}
      onBlur={handleBlur}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default InputNumDocumento;
