import React, { Component } from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { registrarEntradaParqueadero } from "network/Api/DocumentoParqueadero";
import {
  handleKeyPressTextoNumeros,
  handleKeyPressNumeros,
} from "utils/funcionesUtiles";

const style = {
  form: { padding: "20px", maxWidth: "600px", margin: "0 auto" },
  formRow: { margin: "10px" },
};

class FormularioEntrada extends Component {
  state = {
    dsPlaca: "",
    cdTipoVehiculo: "CARRO",
    nmCelular: "",
    dsPinEntrada: null,
    dialogRegisterOpen: false,
    mensajeRespuesta: null,
  };

  handleRegistrarEntrada = () => {
    const { dsPlaca, cdTipoVehiculo, nmCelular } = this.state;
    const { nmIdEmisor, spinnerModal } = this.props;
    let mensaje = this.validarBodyRegistroEntrada();
    if (mensaje) {
      this.setState({
        mensajeRespuesta: mensaje,
        dialogRegisterOpen: true,
      });
    } else {
      spinnerModal(true);
      registrarEntradaParqueadero({
        nmCelular,
        cdTipoVehiculo,
        dsPlaca,
        emisor: { nmId: nmIdEmisor },
      }).then((res) => {
        spinnerModal(false);
        let mensaje;
        if (res.status === 200) {
          mensaje = "Se registro correctamente la entrada";
          this.handleClearBody();
        } else mensaje = "Error registrando la entrada";
        this.setState({
          dsPinEntrada: res.mensaje,
          mensajeRespuesta: mensaje,
          dialogRegisterOpen: true,
        });
      });
    }
  };

  validarBodyRegistroEntrada = () => {
    const { dsPlaca, cdTipoVehiculo, nmCelular } = this.state;
    if (!cdTipoVehiculo) return "El tipo de vehículo es obligatorio";
    if (!(dsPlaca.length >= 2 && dsPlaca.length <= 10))
      return "La placa debe estar entre 2 y 10 caracteres";
    if (nmCelular.length !== 10)
      return "El numero de celular debe tener 10 números";
    return false;
  };

  handleChangeBody = (event) =>
    this.setState({
      [event.target.id]: event.target.value.replace(" ", "").toUpperCase(),
    });

  handleChangeTipoVehiculo = (event) =>
    this.setState({ cdTipoVehiculo: event.target.value });

  handleClearBody = () =>
    this.setState({
      dsPlaca: "",
      nmCelular: "",
    });

  onlyText = (e) => {
    if (!handleKeyPressTextoNumeros(e)) e.preventDefault();
  };

  onlyNumber = (e) => {
    if (!handleKeyPressNumeros(e)) e.preventDefault();
  };

  render() {
    const {
      dialogRegisterOpen,
      mensajeRespuesta,
      dsPlaca,
      cdTipoVehiculo,
      nmCelular,
    } = this.state;
    return (
      <div>
        <form style={style.form}>
          <div style={style.formRow}>
            <RadioGroup
              style={{ display: "flex", flexDirection: "row" }}
              onChange={this.handleChangeTipoVehiculo}
              value={cdTipoVehiculo}
            >
              <FormControlLabel
                value="CARRO"
                control={<Radio />}
                label="Carro"
              />
              <FormControlLabel value="MOTO" control={<Radio />} label="Moto" />
            </RadioGroup>
          </div>
          <div style={style.formRow}>
            <TextField
              value={dsPlaca}
              id="dsPlaca"
              label="Placa"
              autoComplete="off"
              fullWidth
              variant="outlined"
              onChange={this.handleChangeBody}
              onKeyPress={this.onlyText}
            />
          </div>
          <div style={style.formRow}>
            <TextField
              value={nmCelular}
              id="nmCelular"
              label="Celular"
              autoComplete="off"
              fullWidth
              variant="outlined"
              onChange={this.handleChangeBody}
              onKeyPress={this.onlyNumber}
            />
          </div>
          <div style={style.formRow}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRegistrarEntrada}
            >
              Guardar
            </Button>
          </div>
        </form>
        <Dialog open={dialogRegisterOpen}>
          <DialogTitle>PortalFactura</DialogTitle>
          <DialogContent>{mensajeRespuesta}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogRegisterOpen: false })}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default FormularioEntrada;
