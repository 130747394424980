import React, { useState, useEffect } from "react";
import Select from "components/Inputs/Select";
import TablaDevengados from "components/Tablas/TablaDevengados";
import * as ApiDevengado from "network/Api/Devengado";
import { tipoDevengados } from "static/enums/Devengados";
import ModalMensaje from "components/modal/ModalConfirmacion";

export default function devengado({ empleado, emisor }) {
  const [tipo, setTipo] = useState(tipoDevengados[0]);
  const [devengados, setDevengados] = useState([]);
  const [modal, setModal] = useState({});

  const guardarDevengado = (body, resetForm) => {
    const puedeEnviar = validarUnicos(body);
    if (!puedeEnviar) {
      ApiDevengado.guardarDevengado(body).then((res) => {
        if (res.status === 200) {
          resetForm();
          consultarDevengados();
        }
      });
    } else {
      setModal({
        texto: "No puede agregar este devengado mas de una vez",
        open: true,
      });
    }
  };

  const consultarDevengados = () => {
    if (empleado)
      ApiDevengado.getDevengadosByEmpleado(empleado.nmId).then((res) => {
        if (res.status === 200) {
          setDevengados(res.data);
        }
      });
    else setDevengados([]);
  };

  useEffect(() => consultarDevengados(), [empleado]);

  const eliminarItemTabla = (devengado) => {
    ApiDevengado.eliminarDevengado(devengado).then((_) =>
      consultarDevengados()
    );
  };

  const validarUnicos = (nuevoDevengado) => {
    const devengadosUnicos = devengados.filter((d) => {
      const tipo = d.dsTipo;
      return (
        tipo === "BASICO" ||
        tipo === "TRANSPORTE" ||
        tipo === "VACACIONES_COMPENSADAS" ||
        tipo === "PRIMA" ||
        tipo === "CESANTIA" ||
        tipo === "BONIFICACION" ||
        tipo === "AUXILIO" ||
        tipo === "BONO_EPCTV" ||
        tipo === "DOTACION" ||
        tipo === "APOYO_SOST" ||
        tipo === "TELETRABAJO" ||
        tipo === "BONIFI_RETIRO" ||
        tipo === "INDEMNIZACION" ||
        tipo === "REINTEGRO"
      );
    });
    return devengadosUnicos.filter(
      (d) => d.dsTipo === nuevoDevengado.dsTipo
    )[0];
  };

  return (
    <div style={{ padding: "10px" }}>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-4">
          <Select
            label="Selecciona el tipo de devengado"
            options={tipoDevengados}
            value={tipo}
            onChange={setTipo}
          />
        </div>
      </div>
      {tipo.component({ emisor, empleado: empleado }, guardarDevengado)}
      <TablaDevengados detalles={devengados} eliminarItem={eliminarItemTabla} />
      <ModalMensaje
        open={modal.open || false}
        content={modal.texto || ""}
        onAccept={() => setModal((s) => ({ ...s, open: false }))}
      />
    </div>
  );
}
