import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import SelectConsultaEmisores from "../SelectConsultaEmisores";
import ResolucionTable from "../../GestionResoluciones/ResolucionTable";
import { SetSpinnerModal } from "../../../reducers/actions/facturaActions";
import { consultarResolucionesPosEmisor } from "network/Api/Resolucion";

class GestionarResoluciones extends Component {
  state = {
    emisorSeleccionado: null,
    resoluciones: null,
  };

  handleConsultarResoluciones = (id) => {
    const { setSpinnerModal } = this.props;
    setSpinnerModal(true);
    consultarResolucionesPosEmisor({ emisor: id }).then((res) => {
      this.setState({ resoluciones: res.data });
      setSpinnerModal(false);
    });
  };

  handleConsultarEmisor = (event) => {
    this.setState({ emisorSeleccionado: event.id, nitEmisor: event.value });
    this.handleConsultarResoluciones(event.id);
  };

  render() {
    const { emisorSeleccionado, resoluciones, nitEmisor } = this.state;
    return (
      <div>
        <Grid container direction="column" justify="space-between">
          <div style={{ padding: "10px 0px" }}>
            <Grid container spacing={8}>
              <Grid item xs={11} style={{ zIndex: "9" }}>
                <SelectConsultaEmisores
                  handleConsultarEmisor={this.handleConsultarEmisor}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={() =>
                    this.handleConsultarResoluciones(emisorSeleccionado)
                  }
                  style={{ width: "100%" }}
                >
                  <Autorenew />
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <ResolucionTable
          emisor={emisorSeleccionado}
          identificacionEmisor={nitEmisor}
          resoluciones={resoluciones}
          handleConsultarResoluciones={this.handleConsultarResoluciones}
        />
      </div>
    );
  }
}

function mapStateToProps() {}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionarResoluciones);
