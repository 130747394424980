import React, { Component } from "react";
import Select from "react-select";
import { consultarDistribuidores } from "network/Api/Distribuidor";

export default class InputDistribuidores extends Component {
  state = { distribuidores: [] };

  componentDidMount() {
    this.setState({ distribuidores: [{ label: "Cargando..." }] });
    consultarDistribuidores().then((res) =>
      this.setState({
        distribuidores: res.data.map((d) => ({
          label: d.dsNombre,
          value: d.dsClave,
        })),
      })
    );
  }

  render() {
    const { handleChange, style, className, stateKey } = this.props;
    const { distribuidores } = this.state;
    return (
      <Select
        options={distribuidores}
        styles={style}
        onChange={(e) => handleChange(e, stateKey)}
        className={className}
      />
    );
  }
}
