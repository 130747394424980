import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {SetOpenModalGenerico} from '../../reducers/actions/UtilsActions'
import LabelTitulos from '../layout/labelTitulos';

class ModalGenerico extends Component {

    handleClose = () =>{
        this.props.setOpenModalGenerico(false);
    }

    render() {
        const { title, content, openModalGenerico, buttons, handleCloseHigher } = this.props;
        
        return (
            <Dialog
                open={openModalGenerico}
                onClose={() => { handleCloseHigher ? handleCloseHigher() : this.handleClose(); }}
                maxWidth="xl"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    <LabelTitulos texto={title} />
                </DialogTitle>
                <DialogContent>
                    {content}
                </DialogContent>
                <DialogActions>
                    {buttons}
                    <Button onClick={() => { handleCloseHigher ? handleCloseHigher() : this.handleClose(); }} color="primary" autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ModalGenerico.propTypes = {
    title: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        openModalGenerico: state.utils.openModalGenerico
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setOpenModalGenerico: item => dispatch(SetOpenModalGenerico(item))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalGenerico);