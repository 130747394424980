/* eslint-disable */
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { SetModalDocumentosAdicionales, SetDocumentosAdicionales } from '../../../reducers/actions/facturaActions';
import LabelTitulos from '../../layout/labelTitulos';
import { ordenarArray, handleKeyPressTextoNumeros, handleKeyPressNumeros, handleKeyPressTextoNumerosCaracteresEspeciales } from '../../../utils/funcionesUtiles';
import Select from 'react-select';
import DocumentosAdicionalesList from '../Components/DocumentosAdicionalesList';

const styles = {
    buttonAdd: {
        fontWeight: 'bold',
        backgroundColor: '#128BCC',
        color: 'white',
    }
}

const customStyles = {
    input: styles => {
        return {
            ...styles,
            height: '2.25rem'
        };
    }
}

function formatearDecimales(num) {
    let value = `${num.toFixed(2)}`;
    return value;
}

function getTime() {
    let date = new Date();
    let seconds = date.getSeconds();
    let minutes = date.getMinutes();
    let hour = date.getHours();
    if (seconds.toString().length == 1) seconds = `0${seconds}`;
    if (minutes.toString().length == 1) minutes = `0${minutes}`;
    if (hour.toString().length == 1) hour = `0${hour}`;
    return ` ${hour}:${minutes}:${seconds}`
}

class DocumentosAdicionalesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prefijo: '',
            numero: '',
            tipo: 'ORDEN_COMPRA',
            fechaEmision: '',
            fechaEmisionFull: '',
            camposObligatoriosPrefijo: false,
            camposObligatoriosNumero: false,
            id: ''
        }
        this.handleChangeReferenciaDocumento = this.handleChangeReferenciaDocumento.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickAgregar = this.handleClickAgregar.bind(this);
        this.handleChangeFechaEmision = this.handleChangeFechaEmision.bind(this);
    }

    componentDidMount() {
        this.setState({ fechaEmisionFull: this.obtenerDiaActual(), fechaEmision: this.obtenerDiaActual() + getTime() }, () => { })
    }

    handleChangeReferenciaDocumento = (value) => {
        let codigo = value.value;
        this.setState({ tipo: codigo }, () => { })
    }

    handleKeyPressTextoNumeros = (event) => {
        if (!handleKeyPressTextoNumeros(event)) {
            event.preventDefault();
        }
    }

    handleKeyPressTextoNumerosCaracteresEspeciales = (event) => {
        if (!handleKeyPressTextoNumerosCaracteresEspeciales(event)) {
            event.preventDefault();
        }
    }

    handleKeyPressNumeros = (event) => {
        if (!handleKeyPressNumeros(event)) {
            event.preventDefault();
        }
    }

    handleClose = () => {
        this.props.SetModalDocumentosAdicionales(false);
    }

    handleChange = (event) => {
        let value = event.target.value.toUpperCase();
        this.setState({ [event.target.id]: value }, () => { })
    }

    handleClickAgregar = () => {
        if (!this.validarCamposObligatorios()) {
            return;
        }
        this.agregarId().then(async () => {
            let data = [...this.props.listaDocumentosAdicionales, {
                ...this.state
            }];
            await this.props.SetDocumentosAdicionales(data);
            this.setState({ prefijo: '', numero: '', id: '', fechaEmisionFull: this.obtenerDiaActual(), fechaEmision: this.obtenerDiaActual() + getTime() });
        });
    }

    agregarId = async () => {
        const { listaDocumentosAdicionales } = this.props;
        let id = 0;
        let tamanoArray = await listaDocumentosAdicionales.length;
        if (tamanoArray > 0) {
            id = await listaDocumentosAdicionales[tamanoArray - 1].id + 1
        }
        this.setState({ id: id });
    }

    validarCamposObligatorios = () => {
        let sw = 0;
        const { prefijo, numero } = this.state;
        const { open } = this.props;
        if (prefijo.length === 0) {
            sw += 1;
            this.setState({ camposObligatoriosPrefijo: true })
        } else {
            this.setState({ camposObligatoriosPrefijo: false })
        }

        if (numero.length === 0) {
            sw += 1;
            this.setState({ camposObligatoriosNumero: true })
        } else {
            this.setState({ camposObligatoriosNumero: false })
        }

        if (sw > 0) {
            return false;
        }
        return true;
    }

    obtenerDiaActual = () => {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().split('T')[0];
        return today;
    }

    handleChangeFechaEmision = (event) => {
        let id = event.target.id;
        let value = event.target.value;

        if (id == 'fechaEmisionFull' && (value > this.obtenerDiaActual())) {
            return;
        }
        this.setState({ [event.target.id]: value, fechaEmision: value + getTime() }, () => { })
    }

    render() {
        const { tiposReferenciaDocumentos, listaDocumentosAdicionales, isOpen, handleClose } = this.props;
        const { prefijo, numero, camposObligatoriosPrefijo, camposObligatoriosNumero, fechaEmisionFull } = this.state;

        let tiposReferenciaDocumentosSelect = [];
        (tiposReferenciaDocumentos.map((item) => {
            tiposReferenciaDocumentosSelect.push({ "value": item.codigo, "label": item.nombre });
        }))
        tiposReferenciaDocumentosSelect = ordenarArray(tiposReferenciaDocumentosSelect);

        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    scroll='paper'
                    fullScreen={isWidthUp('md', this.props.width) ? false : true}
                    fullWidth={isWidthUp('xl', this.props.width) ? false : true}
                    maxWidth='md'
                >
                    <DialogTitle id="form-dialog-title">
                        <LabelTitulos texto='Documentos adicionales' />
                    </DialogTitle>
                    <DialogContent>
                        <div className="form-row" autoComplete="off">
                            <div className="form-group col-md-12 col-xl-6 mb-0">
                                <div className="form-label-group">
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="prefijo"
                                        label="Prefijo *"
                                        onKeyPress={this.handleKeyPressTextoNumerosCaracteresEspeciales}
                                        fullWidth
                                        value={prefijo}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        error={camposObligatoriosPrefijo}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-12 col-xl-6 mb-0">
                                <div className="form-label-group">
                                    <TextField
                                        margin="dense"
                                        id="numero"
                                        label="# Documento *"
                                        value={numero}
                                        onKeyPress={this.handleKeyPressTextoNumerosCaracteresEspeciales}
                                        fullWidth
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        error={camposObligatoriosNumero}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row col-md-12 d-flex justify-content-between">
                            <div className="form-group col-xl-12">
                                <small >Tipo documento: </small>
                                <Select
                                    defaultValue={tiposReferenciaDocumentosSelect[14]}
                                    onChange={this.handleChangeReferenciaDocumento}
                                    options={tiposReferenciaDocumentosSelect}
                                    placeholder='Referencia documento *'
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                        <div className="form-row col-md-12 d-flex justify-content-between">
                            <div className="form-group col-xl-12">
                                <small >Fecha emisión: </small>
                                <input type="date" id="fechaEmisionFull" min="1000-01-01" max={this.obtenerDiaActual()} value={fechaEmisionFull} onChange={this.handleChangeFechaEmision} className="form-control" required />
                            </div>
                        </div>
                        {listaDocumentosAdicionales.length > 0 &&
                            <DocumentosAdicionalesList />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button style={styles.buttonAdd} onClick={this.handleClickAgregar} name="txtAgregar" color="primary">Agregar</Button>
                        <Button style={styles.buttonAdd} onClick={handleClose} name="Cerrar" color="primary">Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    SetModalDocumentosAdicionales: (item) => dispatch(SetModalDocumentosAdicionales(item)),
    SetDocumentosAdicionales: (item) => dispatch(SetDocumentosAdicionales(item)),
})

function mapStateToProps(state, props) {
    return {
        open: state.factura.openModalDocumentosAdicionales,
        listaDocumentosAdicionales: state.factura.listaDocumentosAdicionales,
        tiposReferenciaDocumentos: state.factura.tiposReferenciaDocumentos,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(DocumentosAdicionalesModal));