import React from "react";
import Anticipos from "components/formularios/nomina/devengados/Anticipos";
import Auxilio from "components/formularios/nomina/devengados/Auxilio";
import Bonificacion from "components/formularios/nomina/devengados/Bonificacion";
import BonoEPCTV from "components/formularios/nomina/devengados/BonoEPCTV";
import Cesantias from "components/formularios/nomina/devengados/Cesantias";
import Comision from "components/formularios/nomina/devengados/Comision";
import Compensacion from "components/formularios/nomina/devengados/Compensacion";
import HorasExtra from "components/formularios/nomina/devengados/HorasExtra";
import HuelgaLegal from "components/formularios/nomina/devengados/HuelgaLegal";
import Incapacidad from "components/formularios/nomina/devengados/Incapacidad";
import Licencias from "components/formularios/nomina/devengados/Licencias";
import OtroConcepto from "components/formularios/nomina/devengados/OtroConcepto";
import PagosTerceros from "components/formularios/nomina/devengados/PagosTerceros";
import Prima from "components/formularios/nomina/devengados/Prima";
import Transporte from "components/formularios/nomina/devengados/Transporte";
import Vacaciones from "components/formularios/nomina/devengados/Vacaciones";
import Dotacion from "components/formularios/nomina/devengados/Dotacion";
import ApoyoSost from "components/formularios/nomina/devengados/ApoyoSost";
import Teletrabajo from "components/formularios/nomina/devengados/Teletrabajo";
import BonifRetiro from "components/formularios/nomina/devengados/BonifRetiro";
import Indemnizacion from "components/formularios/nomina/devengados/Indemnizacion";
import Reintegro from "components/formularios/nomina/devengados/Reintegro";
import Basico from "components/formularios/nomina/devengados/Basico";

export const tipoDevengados = [
  {
    label: "Sueldo Trabajado",
    value: "BASICO",
    component: (b, g, colsDefaultXl) => (
      <Basico baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Anticipo",
    value: "ANTICIPOS",
    component: (b, g, colsDefaultXl) => (
      <Anticipos baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Apoyo Sostenible",
    value: "APOYO_SOST",
    component: (b, g, colsDefaultXl) => (
      <ApoyoSost baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Auxilio",
    value: "AUXILIO",
    component: (b, g, colsDefaultXl) => (
      <Auxilio baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Bonificación",
    value: "BONIFICACION",
    component: (b, g, colsDefaultXl) => (
      <Bonificacion baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Bonificación Retiro",
    value: "BONIFI_RETIRO",
    component: (b, g, colsDefaultXl) => (
      <BonifRetiro baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Bono EPCTV",
    value: "BONOEPCTV",
    component: (b, g, colsDefaultXl) => (
      <BonoEPCTV baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Cesantías",
    value: "CESANTIAS",
    component: (b, g, colsDefaultXl) => (
      <Cesantias baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Comisión",
    value: "COMISION",
    component: (b, g, colsDefaultXl) => (
      <Comision baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Compensación",
    value: "COMPENSACION",
    component: (b, g, colsDefaultXl) => (
      <Compensacion baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Dotación",
    value: "DOTACION",
    component: (b, g, colsDefaultXl) => (
      <Dotacion baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Horas Extras",
    value: "HORAS_EXTRA",
    component: (b, g, colsDefaultXl) => (
      <HorasExtra baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Huelga Legal",
    value: "HUELGA_LEGAL",
    component: (b, g, colsDefaultXl) => (
      <HuelgaLegal baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Incapacidad",
    value: "INCAPACIDAD",
    component: (b, g, colsDefaultXl) => (
      <Incapacidad baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Licencia",
    value: "LICENCIAS",
    component: (b, g, colsDefaultXl) => (
      <Licencias baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Indemnización",
    value: "INDEMNIZACION",
    component: (b, g, colsDefaultXl) => (
      <Indemnizacion baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Otro Concepto",
    value: "OTRO_CONCEPTO",
    component: (b, g, colsDefaultXl) => (
      <OtroConcepto baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Pago de Terceros",
    value: "PAGO_TERCEROS",
    component: (b, g, colsDefaultXl) => (
      <PagosTerceros baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Prima",
    value: "PRIMA",
    component: (b, g, colsDefaultXl) => (
      <Prima baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Reintegro",
    value: "REINTEGRO",
    component: (b, g, colsDefaultXl) => (
      <Reintegro baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Teletrabajo",
    value: "TELETRABAJO",
    component: (b, g, colsDefaultXl) => (
      <Teletrabajo baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Transporte",
    value: "TRANSPORTE",
    component: (b, g, colsDefaultXl) => (
      <Transporte baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Vacaciones",
    value: "VACACIONES",
    component: (b, g, colsDefaultXl) => (
      <Vacaciones baseBody={b} guardarDevengado={g} xlCol={colsDefaultXl} />
    ),
  },
];
