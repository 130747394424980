import { serviceRoutes, put, get, post } from "./index";

export const retirarEmpleado = async (body) =>
  await put(serviceRoutes.retirarEmpleado, body);

export const consultarEmpleadoPorId = async ({ nmId }) =>
  await get(serviceRoutes.consultarEmpleadoPorId, `?id=${nmId}`);

export const getEmpleados = async (body) =>
  await get(serviceRoutes.getEmpleados, body);

export const guardarEmpleado = async (body) =>
  await post(serviceRoutes.guardarEmpleado, body);

export const actualizarEmpleado = async (body) =>
  await put(serviceRoutes.actualizarEmpleado, body);
