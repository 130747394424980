import * as React from 'react';
import { moneyFormat, percentFormat } from 'utils/TextFormattingUtils';
import QRCode from 'qrcode';

const VisualizacionDocumento = ({ documentoJson = {}, userAttributes, response, qr }) => {
  const [qrState, setQr] = React.useState();

  const getVacio = React.useCallback((value) => value || '', []);

  const getValorTributo = React.useCallback((tributo) => {
    let valor = 0;
    documentoJson.impuestosRetenciones.forEach((impuestoRetencion) => {
      let subtotal = impuestoRetencion.subtotales[0].tributo;
      if (subtotal === tributo) valor = impuestoRetencion.valorTotal;
    });
    return valor;
  }, []);

  React.useEffect(() => {
    const { qrCode = '' } = documentoJson;
    QRCode.toDataURL(qrCode, (_, url) => setQr(url));
  }, [documentoJson]);

  return (
    <div className='body'>
      <style>
        {`
          .body {
            margin: 0px;
            font-family: Arial, Helvetica, sans-serif;
            --width: 95%;
            background-color: white;
          }
          p {
            margin: 0px;
          }
          .header_custom {
            width: var(--width);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0px auto;
          }
          .title_custom_visual {
            width: 60%;
            padding-left: 50px;
            padding-bottom: 45px;
          }
          .wrap_img {
            width: 200px;
            margin: 100px 100px 0px 0px;
          }
          .img {
            width: 100%;
          }
          .content_custom_visual {
            width: var(--width);
            margin: 0px auto;
          }
          .data_factura,
          .data_factura ul {
              width: 100%;
              text-align: left;
              margin-bottom: 20px;
              list-style-type: none;
          }
          .wrap_data {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .data_list {
            list-style: none;
            padding: 0px;
          }
          .data_list:nth-child(1) {
            margin-bottom: 20px;
          }
          
          .table {
            width: 100%;
            margin: 50px 0px;
          }
          .table_header th {
            background: grey;
            color: white;
            text-align: center;
            font-size: 0.9rem;
          }
          .table_row td {
            text-align: center;
          }
          .wrap_columns {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-bottom: 10px;
          }
          .col_custom {
            width: 35%;
          }
          .col_custom:nth-child(1) {
            margin: 0px 20px 0px 0px;
          }
          .row_custom {
            display: flex;
            justify-content: space-between;
            margin: 5px 0px;
            width: 50%;
          }
          .row_title {
            color: grey;
            font-weight: 900;
            margin-right: 30px;
            width: 50%;
          }
          .row_value {
            font-weight: 900;
            text-align: left;
            width: 50%;
          }
          .total {
            display: flex;
            justify-content: center;
            width: 70%;
            margin-left: 28%;
            font-weight: 900;
          }
          .total_title {
            margin-right: 10px;
            color: grey;
          }
          .footer_doc {
            width: 70%;
            margin: 30px auto;
          }
          .footer_doc p {
            margin: 15px 0px;
          }
          .footer_doc p:nth-last-child(1) {
            text-align: right;
          }

          @media print {
            .body{
              margin-top: -10px;
              font-size: 13px;
            }
            .wrap_img{
              width: 100px;
              height: 100px;
              margin-top: 20px;
            }

            .data_factura,
            .data_factura ul{
              margin-left: -20px;
            }

            .cuds {
              font-size: 13px;
            }

            .wrap_columns {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 10px;            
            }

            .table {
              width: 100%;
              margin: 20px 0px;
            }
            .row_title {              
              width: 100%;
            }

            .col_custom {
              width: 50%;
            }

            .row_custom {
              display: flex;
              justify-content: space-beetwen;
              width: 100%;
            }

            .row_title {
              font-weight: 900;
              width: 50%;
            }

            .row_value {
              font-weight: 900;
              width: 50%;
            }

            .total {
              display: flex;
              justify-content: center;
              width: 90%;
              margin-left: -5px;
              font-weight: 900;
            }

            .footer_doc {
              width: 90%;
              margin-top: 30px;            
            }
          }
          `}
      </style>
      <div className='header_custom'>
        <h3 className='title_custom_visual'>
          Documento Soporte en Adquisiciones Efectuadas a No Obligados a Facturar
        </h3>
        <figure className='wrap_img'>
          <img src={qr || qrState} alt='codigo qr' className='img' />
        </figure>
      </div>
      <div className='content_custom_visual'>
        <div className='data_factura'>
          <ul>
            <li>CUDS:</li>
            <li className='cuds'>
              {(response || {}).cuds || (response || {}).cufe || (response || {}).cude}
            </li>
            <li>Fecha de certificación: {(response || {}).fecha}</li>
            <li>
              Documento de Soporte: {documentoJson.dsPrefijo + '-' + documentoJson.dsNumeroFactura}
            </li>
            <li>Fecha Emisión: {documentoJson.fechaEmision}</li>
            <li>Forma de Pago: {documentoJson.pago.formaPago}</li>
            <li>Medio de Pago: {documentoJson.pago.medioPago}</li>
          </ul>
        </div>
        <div className='wrap_data'>
          <div>
            <ul className='data_list'>
              <li style={{ fontWeight: 'bold' }}>Información del Vendedor</li>
              <li>{`${getVacio(documentoJson.nombresVendedor)} ${getVacio(
                documentoJson.segundoNombre,
              )} ${getVacio(documentoJson.primerApellido)} ${getVacio(
                documentoJson.segundoApellido,
              )}`}</li>
              <li>
                {documentoJson.tipoIdentificacionVendedor}: {documentoJson.identificacionVendedor}
              </li>
              <li>Dirección: {documentoJson.direccionVendedor}</li>
              <li>Teléfono: {documentoJson.telefonoVendedor}</li>
              <li>Correo: {documentoJson.emailVendedor}</li>
              <li>
                {documentoJson.ciudadVendedor.dsNombre} -{' '}
                {documentoJson.ciudadVendedor.departamento.dsNombre}
              </li>
              <li>Responsabilidad fiscal: {documentoJson.responsabilidadesFiscales}</li>
              <li>Procedencia: {documentoJson.tipoOperacion}</li>
            </ul>
            <ul className='data_list'>
              <li style={{ fontWeight: 'bold' }}>Información del Comprador</li>
              <li>{`${getVacio(userAttributes.nombresEmisor)} ${getVacio(
                userAttributes.segundoNombre,
              )} ${getVacio(userAttributes.primerApellido)} ${getVacio(
                userAttributes.segundoApellido,
              )}`}</li>
              <li>
                {userAttributes.tipoIdentificacion}: {userAttributes.identificacionEmisor}
              </li>
              <li>Dirección: {userAttributes.direccion}</li>
              <li>Teléfono: {userAttributes.phone_number}</li>
              <li>Correo: {userAttributes.email}</li>
              <li>
                {userAttributes.ciudadEmisor && userAttributes.ciudadEmisor.dsNombre} -{' '}
                {userAttributes.ciudadEmisor && userAttributes.ciudadEmisor.departamento.dsNombre}
              </li>
            </ul>
          </div>
        </div>
        <table className='table'>
          <tr className='table_header'>
            <th>Nro</th>
            <th>Codígo</th>
            <th>Descripcíon</th>
            <th>U.M.</th>
            <th>Cantidad</th>
            <th>Valor Unitario</th>
            <th>% IVA</th>
            <th>% INC</th>
            <th>Descuento</th>
            <th>Valor Total</th>
          </tr>
          {documentoJson.detalles.map((detalle, index) => (
            <tr className='table_row'>
              <td>{index + 1}</td>
              <td>{detalle.codigoArticulo}</td>
              <td>{detalle.descripcionArticulo}</td>
              <td>{detalle.unidadMedida}</td>
              <td>{detalle.cantidad}</td>
              <td>{moneyFormat(detalle.precioUnitario)}</td>
              <td>{percentFormat(detalle.porcentajeIva)}</td>
              <td>{percentFormat(detalle.porcentajeConsumo)}</td>
              <td>{moneyFormat(detalle.valorTotalDescuento)}</td>
              <td>{moneyFormat(detalle.valorTotalArticulo)}</td>
            </tr>
          ))}
        </table>
        <div className='total_factura'>
          <div className='wrap_columns'>
            <div className='col_custom'>
              <div className='row_custom'>
                <div className='row_title'>Rete Fuente:</div>
                <div className='row_value'>{moneyFormat(getValorTributo('RETE_RENTA'))}</div>
              </div>
              <div className='row_custom'>
                <div className='row_title'>Descuento:</div>
                <div className='row_value'>{moneyFormat(documentoJson.descuentoTotal)}</div>
              </div>
              <div className='row_custom'>
                <div className='row_title'>Total IVA:</div>
                <div className='row_value'>{moneyFormat(getValorTributo('IVA'))}</div>
              </div>
              <div className='row_custom'>
                <div className='row_title'>Total INC:</div>
                <div className='row_value'>{moneyFormat(getValorTributo('INC'))}</div>
              </div>
            </div>
            <div className='col_custom'>
              <div className='row_custom'>
                <div className='row_title'>Rete ICA:</div>
                <div className='row_value'>{moneyFormat(getValorTributo('RETE_ICA'))}</div>
              </div>
              <div className='row_custom'>
                <div className='row_title'>Total Bruto:</div>
                <div className='row_value'>{moneyFormat(documentoJson.valorBruto)}</div>
              </div>
              <div className='row_custom'>
                <div className='row_title'>Rete IVA:</div>
                <div className='row_value'>{moneyFormat(getValorTributo('RETE_IVA'))}</div>
              </div>
              <div className='row_custom'>
                <div className='row_title'>Total a Pagar:</div>
                <div className='row_value'>{moneyFormat(documentoJson.totalPagar)}</div>
              </div>
            </div>
          </div>
          <div className='total'>
            <div className='total_title'>Total en letras:</div>
            <div className='total_value'>{documentoJson.valorNetoLetras}</div>
          </div>
        </div>
      </div>
      <div className='footer_doc'>
        {documentoJson.resolucionObject && (
          <p>
            {`AUTORIZACION DE NUMERACION ${
              documentoJson.resolucionObject.dsResolucionDian
            } Fecha: ${documentoJson.resolucionObject.feInicio} Factura ${
              documentoJson.resolucionObject.dsPrefijo + documentoJson.resolucionObject.nmInicio
            } al ${
              documentoJson.resolucionObject.dsPrefijo + documentoJson.resolucionObject.nmFin
            } con fecha de vencimiento: ${documentoJson.resolucionObject.feVencimiento}`}
          </p>
        )}
        <p>Observaciones: {documentoJson.dsObservacion}</p>
        <p>Documento generador en WWW.PORTALFACTURA.COM</p>
      </div>
    </div>
  );
};

export default VisualizacionDocumento;
