import React from "react";
import Anticipos from "components/formularios/nomina/deducciones/Anticipos";
import FondoPension from "components/formularios/nomina/deducciones/FondoPension";
import FondoSP from "components/formularios/nomina/deducciones/FondoSP";
import Libranza from "components/formularios/nomina/deducciones/Libranza";
import OtraDeduccion from "components/formularios/nomina/deducciones/OtraDeduccion";
import PagosTerceros from "components/formularios/nomina/deducciones/PagosTerceros";
import Salud from "components/formularios/nomina/deducciones/Salud";
import Sancion from "components/formularios/nomina/deducciones/Sancion";
import Sindicato from "components/formularios/nomina/deducciones/Sindicato";
import PensionVoluntaria from "components/formularios/nomina/deducciones/PensionVoluntaria";
import AFC from "components/formularios/nomina/deducciones/AFC";
import Cooperativa from "components/formularios/nomina/deducciones/Cooperativa";
import EmbargoFiscal from "components/formularios/nomina/deducciones/EmbargoFiscal";
import PlanComplementario from "components/formularios/nomina/deducciones/PlanComplementario";
import Educacion from "components/formularios/nomina/deducciones/Educacion";
import RetencionFuente from "components/formularios/nomina/deducciones/RetencionFuente";
import Reintegro from "components/formularios/nomina/deducciones/Reintegro";
import Deuda from "components/formularios/nomina/deducciones/Deuda";

export const tipoDeducciones = [
  {
    label: "Salud",
    value: "SALUD",
    component: (b, g, colsDefaultXl) => (
      <Salud baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Fondos de Pension",
    value: "FONDO_PENSION",
    component: (b, g, colsDefaultXl) => (
      <FondoPension baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Anticipo",
    value: "ANTICIPO",
    component: (b, g, colsDefaultXl) => (
      <Anticipos
        cipos
        baseBody={b}
        guardarDeduccion={g}
        xlCol={colsDefaultXl}
      />
    ),
  },
  {
    label: "Fondo SP",
    value: "FONDO_SP",
    component: (b, g, colsDefaultXl) => (
      <FondoSP baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Libranza",
    value: "LIBRANZA",
    component: (b, g, colsDefaultXl) => (
      <Libranza baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Otra Deduccion",
    value: "OTRA_DEDUCCION",
    component: (b, g, colsDefaultXl) => (
      <OtraDeduccion baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Pago de Terceros",
    value: "PAGO_TERCEROS",
    component: (b, g, colsDefaultXl) => (
      <PagosTerceros baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Sancion",
    value: "SANCION",
    component: (b, g, colsDefaultXl) => (
      <Sancion baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Sindicato",
    value: "SINDICATO",
    component: (b, g, colsDefaultXl) => (
      <Sindicato baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Pension Voluntaria",
    value: "PENSION_VOLUNTARIA",
    component: (b, g, colsDefaultXl) => (
      <PensionVoluntaria
        baseBody={b}
        guardarDeduccion={g}
        xlCol={colsDefaultXl}
      />
    ),
  },
  {
    label: "AFC",
    value: "AFC",
    component: (b, g, colsDefaultXl) => (
      <AFC baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Cooperativa",
    value: "COOPERATIVA",
    component: (b, g, colsDefaultXl) => (
      <Cooperativa baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Embargo Fiscal",
    value: "EMBARGO_FISCAL",
    component: (b, g, colsDefaultXl) => (
      <EmbargoFiscal baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Plan Complementario",
    value: "PLAN_COMPLEMENTARIO",
    component: (b, g, colsDefaultXl) => (
      <PlanComplementario
        baseBody={b}
        guardarDeduccion={g}
        xlCol={colsDefaultXl}
      />
    ),
  },
  {
    label: "Educacion",
    value: "EDUCACION",
    component: (b, g, colsDefaultXl) => (
      <Educacion baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Retencion Fuente",
    value: "RETENCION_FUENTE",
    component: (b, g, colsDefaultXl) => (
      <RetencionFuente
        baseBody={b}
        guardarDeduccion={g}
        xlCol={colsDefaultXl}
      />
    ),
  },
  {
    label: "Reintegro",
    value: "REINTEGRO",
    component: (b, g, colsDefaultXl) => (
      <Reintegro baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
  {
    label: "Deuda",
    value: "DEUDA",
    component: (b, g, colsDefaultXl) => (
      <Deuda baseBody={b} guardarDeduccion={g} xlCol={colsDefaultXl} />
    ),
  },
];
