import React from "react";
import TipoContrato from "../../static/enums/TiposContratos";
import Select from "./Select";

export default function selectTipoContratos({ label, ...rest }) {
  return (
    <Select
      label={label}
      options={TipoContrato.map((tc) => ({
        label: tc.nombre,
        value: tc.id,
      }))}
      {...rest}
    />
  );
}
