import React, { useEffect, useState } from 'react';
import { Col, Row, Flex } from 'antd';
import AliadoCard from './AliadoCard';
import { consultarServiciosAliados } from 'network/Api/ServicioAliado';

function TablaAliados({ openDetalles }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    consultarServiciosAliados().then(({ data }) => {
      let matriz = [];
      let filaActual = [];
      data.forEach((d, index) => {
        filaActual.push(d);
        if (filaActual.length === 3) {
          matriz.push(filaActual);
          filaActual = [];
        }
        if (index === data.length - 1) matriz.push(filaActual);
      });
      setOptions(matriz);
    });
  }, []);

  return (
    <Flex vertical align='center'>
      {options.map((rowOptions, rowKey) => (
        <Row key={rowKey}>
          {rowOptions.map((servicio, colKey) => (
            <Col key={colKey + rowKey} style={{ margin: '5px' }}>
              <AliadoCard
                servicio={servicio.dsServicio}
                aliado={servicio.emisor.nombreCompleto}
                portal={servicio.nmValorPortal}
                vigencia={servicio.feVigencia}
                urlImagen={servicio.dsUrlImagen}
                onClick={() => openDetalles({ servicio })}
              />
            </Col>
          ))}
        </Row>
      ))}
    </Flex>
  );
}

export default TablaAliados;
