import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as TextFormattin from 'utils/TextFormattingUtils';
import HomeCard from 'components/Card/HomeCard';
import { consultarSaldoCajaMenorEmisor } from 'network/Api/SaldoDinero';
import Graph from 'static/images/graph1.svg';
import Saldo from 'static/images/saldo1.svg';
import SuSaldo from 'static/images/susaldo1.svg';
import TeamOut from 'static/images/TeamOut.svg';
import { Col, Row } from 'antd';
import './index.css';
import ModalNotificacionSaldo from 'components/modal/ModalNotificacionSaldo';
import Parrafo from 'components/MainPage/Parrafo';
import { descargarDocumento } from 'network/Api/BucketS3Aws';
const style = () => ({
  cards: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  body: {},
  bodyRow: { display: 'flex' },
});

function Home({ classes, userAttributes }) {
  const [state, setData] = useState({});
  const setState = (data) => setData((s) => ({ ...s, ...data }));
  const [imageSrc, setImageSrc] = useState(null);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userAttributes.codigoEmisor) {
      const { codigoEmisor, tarifas } = userAttributes;
      const tarifaDTE = tarifas.find(({ cdTarifa }) => cdTarifa === 'DTE');
      const tarifaPOE = tarifas.find(({ cdTarifa }) => cdTarifa === 'POE');
      consultarSaldoCajaMenorEmisor({ emisor: codigoEmisor }).then((res) =>
        setState({
          cajaMenor: res.data,
          totalDocumentos: tarifaDTE && (res.data / tarifaDTE.nmValor).toFixed(0),
          totalDocumentosPos: tarifaPOE && (res.data / tarifaPOE.nmValor).toFixed(0),
        }),
      );
      async function fetchImage() {
        try {
          let imgBlob = await descargarDocumento({ fileName: userAttributes.logoCloud });
          if (imgBlob.size > 0) {
            let imageUrl = URL.createObjectURL(imgBlob);
            setImageSrc(imageUrl);
          } else {
            setImageError(true);
          }
        } catch (error) {
          setImageError(true);
        }
      }

      fetchImage();
    }
  }, [userAttributes]);

  return (
    <>
      <Row style={{ padding: '26px' }}>
        <Col xxl={8} xl={8} lg={8}>
          <div
            style={{
              paddingBottom: '25px',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              color: 'black',
            }}
          >
            {imageSrc && !imageError ? (
              <img
                width='200'
                height='200'
                viewBox='0 0 512 512'
                src={imageSrc}
                alt='Downloaded logo'
              />
            ) : (
              <svg xmlns='http://www.w3.org/2000/svg' width='70' height='70' viewBox='0 0 512 512'>
                <path d='M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z' />
              </svg>
            )}

            <div style={{ fontSize: '25px' }}>{userAttributes.nombresEmisor}</div>
          </div>
          <div style={{ justifyContent: 'center' }} id='cards-home-container'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <HomeCard
                redirect='/recargar_saldo'
                title='Su saldo es:'
                content={`$ ${TextFormattin.decimalFormat(state.cajaMenor)}`}
                iconImg={SuSaldo}
                small={`Equivalen a ${TextFormattin.decimalFormat(state.totalDocumentos)} documentos de facturación electrónica ${state.totalDocumentosPos ? `o ${TextFormattin.decimalFormat(state.totalDocumentosPos)} POS electrónico` : ''}`}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <HomeCard
                redirect='/aliados'
                content={`Marketplace`}
                iconImg={TeamOut}
                fullHeigth={true}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <HomeCard
                redirect='/historialcaja'
                content={`Mis Movimientos`}
                iconImg={Graph}
                fullHeigth={true}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <HomeCard
                redirect='/recargar_saldo'
                content={`Recargar Saldo`}
                iconImg={Saldo}
                fullHeigth={true}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <HomeCard
                redirect='/historial-transacciones'
                content={`Mis Transacciones`}
                iconImg={Graph}
                fullHeigth={true}
              />
            </div>
          </div>
        </Col>
        <Col xxl={16} xl={16} lg={16} className='container-messages'>
          <div className='column-left-home'>
            <div className='container-icon-alert'>
              <svg
                style={{ margin: '10px' }}
                width='70'
                height='70'
                fill='#3C3588'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 640 512'
              >
                <path d='M352 32V49.88C424.5 61.39 480 124.2 480 200V233.4C480 278.8 495.5 322.9 523.8 358.4L538.7 377C544.5 384.2 545.6 394.1 541.6 402.4C537.6 410.7 529.2 416 520 416H120C110.8 416 102.4 410.7 98.37 402.4C94.37 394.1 95.5 384.2 101.3 377L116.2 358.4C144.5 322.9 160 278.8 160 233.4V200C160 124.2 215.5 61.39 288 49.88V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V32zM312 96C254.6 96 208 142.6 208 200V233.4C208 281.3 194.1 328 168.3 368H471.7C445.9 328 432 281.3 432 233.4V200C432 142.6 385.4 96 328 96H312zM384 448C384 464.1 377.3 481.3 365.3 493.3C353.3 505.3 336.1 512 320 512C303 512 286.7 505.3 274.7 493.3C262.7 481.3 256 464.1 256 448H384zM0 200C0 186.7 10.75 176 24 176H104C117.3 176 128 186.7 128 200C128 213.3 117.3 224 104 224H24C10.75 224 0 213.3 0 200zM616 176C629.3 176 640 186.7 640 200C640 213.3 629.3 224 616 224H536C522.7 224 512 213.3 512 200C512 186.7 522.7 176 536 176H616zM597.5 21.27C603.4 33.12 598.6 47.54 586.7 53.47L522.7 85.47C510.9 91.39 496.5 86.59 490.5 74.73C484.6 62.88 489.4 48.46 501.3 42.53L565.3 10.53C577.1 4.606 591.5 9.412 597.5 21.27V21.27zM53.27 53.47C41.41 47.54 36.61 33.12 42.53 21.27C48.46 9.412 62.88 4.606 74.73 10.53L138.7 42.53C150.6 48.46 155.4 62.88 149.5 74.73C143.5 86.59 129.1 91.39 117.3 85.47L53.27 53.47z' />
              </svg>
            </div>
            <Parrafo isLoged={true} classStyle={'container-text-home'} />
          </div>
        </Col>
      </Row>
      <ModalNotificacionSaldo
        idEmisor={userAttributes.codigoEmisor}
        tarifa={userAttributes.cdTarifaDte}
        docEmisor={userAttributes['custom:custom:userid']}
      />
    </>
  );
}

function mapStateToProps(state, _) {
  return { userAttributes: state.factura.userAttributes };
}

function mapDispatchToProps(_) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Home));
