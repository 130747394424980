import * as React from "react";
import Icon from "components/menu/Icon";
import GastosIco from "static/images/Gastos.png";
import RegistroGastosIco from "static/images/RegistroGastos.png";

export const eventosMercantiles = {
  title: "Emitir eventos mercantiles",
  icon: <Icon src={RegistroGastosIco} alt="Registro Gastos" />,
  pathname: "/gastos",
};

const getMenu = (items) => ({
  subMenu: true,
  title: "Eventos mercantiles",
  icon: <Icon src={GastosIco} alt="Gastos" />,
  items,
});

export const Gastos = getMenu([eventosMercantiles]);
