import React, { useState } from "react";
import Select from "../../../Inputs/Select";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

const TIPO_HORAS_EXTRA = [
  { label: "Horas Extra Diarias", value: "HED" },
  { label: "Horas Extra Nocturnas", value: "HEN" },
  { label: "Horas Regargo Nocturnas", value: "HRN" },
  { label: "Horas Extras Diarias Dominicales y Festivas", value: "HEDDF" },
  { label: "Horas Recargo Diarias Dominicales y Festivas", value: "HRDDF" },
  { label: "Horas Extras Nocturnas Dominicales y Festivas", value: "HENDF" },
  { label: "Horas Recargo Nocturno Dominicales", value: "HRNDF" },
];

export default function horaExtra({ guardarDevengado, baseBody, xlCol = "2" }) {
  const [nmCantidad, setNmCantidad] = useState();
  const [nmPorcentaje, setNmPorcentaje] = useState();
  const [nmPago, setNmPago] = useState();
  const [tipo, setTipo] = useState(TIPO_HORAS_EXTRA[0]);
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: tipo.value,
    nmCantidad,
    nmPorcentaje,
    nmPago,
  };

  const guardarHoraExtra = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmCantidad();
    setNmPorcentaje();
    setNmPago();
  };

  const tooltip = getToolTip(tipo.value);
  return (
    <form onSubmit={guardarHoraExtra}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Select
            label="Seleccione el tipo de hora"
            options={TIPO_HORAS_EXTRA}
            defaultValue={[
              {
                label: "Horas Extra Diarias",
                value: "HED",
              },
            ]}
            value={tipo}
            onChange={(e) => setTipo(e)}
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Cantidad *"
            id="cantidad"
            value={nmCantidad || ""}
            onChange={(e) => setNmCantidad(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip={tooltip.cantidad}
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Porcentaje *"
            id="porcentaje"
            value={nmPorcentaje || ""}
            onChange={(e) => setNmPorcentaje(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip={tooltip.porcentaje}
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor del pago *"
            id="nmPago"
            value={nmPago || ""}
            onChange={(e) => setNmPago(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip={tooltip.pago}
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmCantidad) errores.push("El campo de cantidad es obligatorio");
  if (!body.nmPorcentaje) errores.push("El campo de porcentaje es obligatorio");
  if (!body.nmPago) errores.push("El campo de pago es obligatorio");
  return errores;
}

function getToolTip(tipo) {
  switch (tipo) {
    case "HED":
      return {
        cantidad: "Cantidad de Horas Extra Diurna",
        porcentaje:
          "Porcentaje al cual corresponde el calculo de 1 hora Extra Diurna",
        pago:
          "Es el valor pagado por el tiempo que se trabaja adicional a la jornada legal o pactada contractualmente.",
      };
    case "HEN":
      return {
        cantidad: "Cantidad de Horas Extras Nocturnas",
        porcentaje:
          "Porcentaje al cual corresponde el calculo de 1 hora Extra Nocturna",
        pago:
          "Es el valor pagado por el tiempo que se trabaja adicional a la jornada legal o pactada contractualmente.",
      };
    case "HRN":
      return {
        cantidad: "Cantidad de Horas Recargo Nocturno",
        porcentaje:
          "Porcentaje al cual corresponde el calculo de 1 hora Recargo Nocturno",
        pago:
          "Es el valor pagado por el tiempo que se trabaja adicional a la jornada legal o pactada contractualmente.",
      };
    case "HEDDF":
      return {
        cantidad: "Cantidad de Horas Extras Diurnas Dominical y Festivos",
        porcentaje:
          "Porcentaje al cual corresponde el calculo de 1 Hora Extra Diurna Dominical y Festivo",
        pago:
          "Es el valor pagado por el tiempo que se trabaja adicional a la jornada legal o pactada contractualmente.",
      };
    case "HRDDF":
      return {
        cantidad: "Cantidad de Horas Recargo Diurno Dominical y Festivos",
        porcentaje:
          "Porcentaje al cual corresponde el calculo de 1 Hora Recargo Diurno Dominical y Festivos",
        pago:
          "Es el valor pagado por el tiempo que se trabaja adicional a la jornada legal o pactada contractualmente.",
      };
    case "HENDF":
      return {
        cantidad: "Cantidad de Horas Extras Nocturna Dominical y Festivos",
        porcentaje:
          "Porcentaje al cual corresponde el calculo de 1 Hora Extra Nocturna Dominical y Festivos",
        pago:
          "Es el valor pagado por el tiempo que se trabaja adicional a la jornada legal o pactada contractualmente.",
      };
    case "HRNDF":
      return {
        cantidad: "Cantidad de Horas Recargo Nocturno Dominical y Festivos",
        porcentaje:
          "Porcentaje al cual corresponde el calculo de 1 Hora Recargo Nocturno Dominical y Festivos",
        pago:
          "Es el valor pagado por el tiempo que se trabaja adicional a la jornada legal o pactada contractualmente.",
      };
    default:
      break;
  }
}
