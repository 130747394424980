import React, { useEffect, useState } from "react";
import TablaVigencias from "components/Tablas/TablaVigencias";
import { consultarFechasVigencia } from "network/Api/Emisor";
import * as DateUtils from "utils/DateUtils";

export default function Anualiad() {
  const [state, setData] = useState({});

  function setState(data) {
    setData({ ...state, ...data });
  }

  useEffect(() => {
    setState({ loading: true });
    consultarFechasVigencia().then((r) => {
      setState({
        loading: false,
        detalles: r.data
          .map((detalle) => ({
            ...detalle,
            feInicioFirma:
              detalle.feInicioFirma &&
              DateUtils.getDateFromArray(detalle.feInicioFirma).format(
                "YYYY-MM-DD"
              ),
            feFinFirma:
              detalle.feFinFirma &&
              DateUtils.getDateFromArray(detalle.feFinFirma).format(
                "YYYY-MM-DD"
              ),
            feInicioAnualidad:
              detalle.feInicioAnualidad &&
              DateUtils.getDateFromArray(detalle.feInicioAnualidad).format(
                "YYYY-MM-DD"
              ),
            feFinAnualidad:
              detalle.feFinAnualidad &&
              DateUtils.getDateFromArray(detalle.feFinAnualidad).format(
                "YYYY-MM-DD"
              ),
          }))
          .sort((a, b) => {
            if (a.feInicioAnualidad < b.feFinAnualidad) return b - a;
            else if (a.feInicioAnualidad > b.feFinAnualidad) return a - b;
            else return 0;
          }),
      });
    });
  }, []);

  return (
    <div>
      <TablaVigencias detalles={state.detalles} loading={state.loading} />
    </div>
  );
}
