export function getPorcentajeTributo(tributos = [], nombreTributo) {
  let isSubtotal = false;
  const impuesto = tributos.find(({ tributo, subtotales }) => {
    if (subtotales) {
      isSubtotal = Boolean(subtotales);
      return subtotales.find((st) => st.tributo === nombreTributo);
    }
    return tributo === nombreTributo;
  });
  if (!impuesto) return 0;
  if (isSubtotal) return impuesto.subtotales[0].porcentaje;
  return impuesto.porcentaje;
}

export function getTributoDetalle(impuesto) {
  const { valorBase, tributo, valorUnitario, volumen, unidadMedida } = impuesto;
  const valorCalculado = calcularValorTotalImpuesto();
  const porcentajeCalculado = calcularPorcentajeTotalImpuesto();
  return {
    valorTotal: valorCalculado,
    subtotales: [
      {
        volumen,
        tributo,
        valorBase,
        valorUnitario,
        unidadMedida,
        porcentaje: porcentajeCalculado,
        valorImpuestoRetencion: valorCalculado,
      },
    ],
  };

  function calcularValorTotalImpuesto() {
    const { porcentaje, valorBase, valorImpuestoRetencion, valorUnitario, volumen } = impuesto;
    if (Boolean(valorUnitario) && Boolean(volumen)) {
      if (tributo === 'IBUA') return (valorUnitario * volumen) / 100;
      return valorUnitario * volumen;
    }
    if (!Boolean(valorImpuestoRetencion)) return (valorBase * porcentaje) / 100;
    return valorImpuestoRetencion;
  }

  function calcularPorcentajeTotalImpuesto() {
    const { porcentaje, valorBase, valorImpuestoRetencion, valorUnitario, volumen } = impuesto;
    if (Boolean(valorUnitario) && Boolean(volumen)) return;
    if (porcentaje === undefined && porcentaje === null)
      return (valorBase * 100) / valorImpuestoRetencion;
    return porcentaje;
  }
}

export function getTotalDescuento(cargosDescuentos = []) {
  return cargosDescuentos
    .filter(({ tipo }) => tipo === false)
    .reduce((total, { valor }) => total + valor, 0);
}

export function getTotalImpuestosRetenciones(impuestosRetenciones = []) {
  return impuestosRetenciones.reduce((total, { valorTotal }) => total + valorTotal, 0);
}

export function getTotalTributo(tributoDetalle = [], tributo) {
  return tributoDetalle
    .filter(({ subtotales }) => subtotales[0].tributo === tributo)
    .reduce((total, { valorTotal }) => total + valorTotal, 0);
}

export function acumularCargosDescuentos(detalles = []) {
  return detalles.flatMap(({ cargosDescuentos = [] }) => cargosDescuentos);
}

export function acumularImpuestosRetenciones(detalles = []) {
  let impuestos = {};
  detalles.forEach((detalle) => {
    const { impuestosRetenciones = [] } = detalle;
    impuestosRetenciones.forEach((impuestoRetencion) => {
      const { subtotales, valorTotal } = impuestoRetencion;
      const { tributo } = subtotales[0];
      const acumulados = impuestos[tributo];
      impuestos[tributo] = acumulados
        ? {
            valorTotal: valorTotal + acumulados.valorTotal,
            subtotales: [...acumulados.subtotales, ...subtotales],
          }
        : { subtotales, valorTotal };
    });
  });
  return Object.keys(impuestos).map((key) => impuestos[key]);
}

export function calcularTotal(detalles = [], campo) {
  return detalles.reduce((total, detalle) => parseFloat(total) + parseFloat(detalle[campo]), 0);
}

export function calcularTotalNoGravado(detalles = []) {
  return detalles.reduce((total, detalle) => {
    const { cargosDescuentos, valorBrutoItem, impuestosRetenciones = [] } = detalle;
    if (impuestosRetenciones.length === 0) {
      const descuento = getTotalDescuento(cargosDescuentos);
      return total + valorBrutoItem - descuento;
    }
    return total;
  }, 0);
}
