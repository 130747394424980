import React, { useEffect } from 'react';
import useFormularioContactoEmisor from 'hooks/useFromularioContactoEmisor';
import SelectCiudades from 'components/atoms/AntdSelect';

function FormularioContactoRegistroEmisor({
  setData,
  validarLongitudMinima,
  ciudades,
  validarEmail,
  errores,
  defaultContacto,
}) {
  const { contacto, setContacto, handleChange } = useFormularioContactoEmisor(defaultContacto);

  useEffect(() => {
    setData((d) => ({ ...d, ...contacto }));
  }, [contacto]);

  return (
    <div>
      <div className='nd-body'>
        <div className='nd-body-item'>
          <div className='nd-body-item-heading'>
            <label className='nd-body-item-label'>Residencia</label>
            <div className='nd-body-item-description'></div>
          </div>
          <div className='nd-body-item-content'>
            <div>
              <label>Ciudad</label>
              <div>
                <SelectCiudades
                  className='nd-select'
                  placeholder='Ciudad'
                  optionFilterProp='children'
                  value={contacto.nmIdCiudad}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  options={ciudades}
                  name='nmIdCiudad'
                  onChange={(value) => setContacto({ nmIdCiudad: value })}
                />
              </div>
              <label className='nd-label-error'>
                {errores.nmIdCiudad && <span>Este campo es requerido</span>}
              </label>
            </div>
            <div>
              <label>Dirección</label>
              <input
                className='nd-body-item-content-input'
                name='dsDireccion'
                label='Dirección'
                placeholder='Dirección'
                value={contacto.dsDireccion || ''}
                maxLength='100'
                onChange={handleChange}
                onBlur={(e) => validarLongitudMinima(e, 8)}
              />
              <label className='nd-label-error'>
                {errores.dsDireccion && <span>Este campo es requerido</span>}
              </label>
            </div>
          </div>
        </div>
        <div className='nd-body-item'>
          <div className='nd-body-item-heading'>
            <label className='nd-body-item-label'>Datos de contacto</label>
            <div className='nd-body-item-description'>
              Es súper importante que tengamos tus datos al día por si nuestros asesores necesitan
              contactarte.
            </div>
          </div>
          <div className='nd-body-item-content'>
            <div>
              <label>Nombre del contacto a cargo</label>
              <input
                className='nd-body-item-content-input'
                name='dsNombreContacto'
                id='dsNombreContacto'
                label='Nombre del contacto a cargo'
                placeholder='Nombre del contacto a cargo'
                value={contacto.dsNombreContacto || ''}
                maxLength='100'
                onChange={handleChange}
                onBlur={(e) => validarLongitudMinima(e, 3)}
              />
              <label className='nd-label-error'>
                {errores.dsNombreContacto && <span>Este campo es requerido</span>}
              </label>
            </div>
            <div>
              <label>Email registrado en el RUT</label>
              <input
                className='nd-body-item-content-input'
                name='dsEmail'
                label='E-Mail de contacto a cargo'
                placeholder='E-Mail registrado en el RUT'
                value={contacto.dsEmail || ''}
                maxLength='100'
                onChange={handleChange}
                onBlur={validarEmail}
              />
              <label className='nd-label-error'>
                {errores.dsEmail && <span>Este campo es requerido</span>}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormularioContactoRegistroEmisor;
