import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function transporte({
  guardarDevengado,
  baseBody,
  xlCol = "2",
}) {
  const [nmAuxilioTransporte, setNmAuxilioTransporte] = useState();
  const [nmViaticoManuAlojS, setNmViaticoManuAlojS] = useState();
  const [nmViaticoManuAlojNS, setNmViaticoManuAlojNS] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    nmAuxilioTransporte,
    nmViaticoManuAlojS,
    nmViaticoManuAlojNS,
    dsTipo: "TRANSPORTE",
  };

  const guardarTransporte = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmAuxilioTransporte();
    setNmViaticoManuAlojS();
    setNmViaticoManuAlojNS();
  };

  return (
    <form onSubmit={guardarTransporte}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor del auxilio de transporte"
            id="auxilioTransporte"
            value={nmAuxilioTransporte || ""}
            onChange={(e) => setNmAuxilioTransporte(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Parte de los viáticos pagado al trabajador correspondientes a medios de transporte y/o los gastos de representación."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor de Viaticos de carácter salarial"
            id="viaticoManuAlojS"
            value={nmViaticoManuAlojS || ""}
            onChange={(e) => setNmViaticoManuAlojS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Parte de los viáticos pagado al trabajador correspondientes a manutención y/o alojamiento."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor de Viaticos de carácter no salarial"
            id="viaticoManuAlojNS"
            value={nmViaticoManuAlojNS || ""}
            onChange={(e) => setNmViaticoManuAlojNS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Parte de los viáticos pagado al trabajador correspondientes a manutención y/o alojamiento No Salariales."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (
    !body.nmAuxilioTransporte &&
    !body.nmViaticoManuAlojS &&
    !body.nmViaticoManuAlojNS
  )
    errores.push("Por favor complete al menos un campo");
  return errores;
}
