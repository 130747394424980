const tiposConceptoDebito = [
  {
    codigo: 'INTERESES',
    nombre: 'Intereses',
  },
  {
    codigo: 'GASTOS_COBRAR',
    nombre: 'Gastos por cobrar',
  },
  {
    codigo: 'CAMBIO_VALOR',
    nombre: 'Cambio de valor',
  },
  {
    codigo: 'OTROS',
    nombre: 'Otros',
  },
];

export default tiposConceptoDebito;
