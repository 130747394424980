import { serviceRoutes, post, get, put } from "./index";

export const getContratos = async (body) =>
  await get(serviceRoutes.getContratos, body);

export const getContratosEmisor = async (body) =>
  await get(serviceRoutes.getContratosEmisor, body);

export const guardarContrato = async (body) =>
  await post(serviceRoutes.guardarContrato, body);

export const actualizarContrato = async (body) =>
  await put(serviceRoutes.actualizarContrato, body);
