import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CustomSlider from '../../molecules/slider/CustomCarousel';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import './styles/modalSlider.css';
import { withStyles } from '@material-ui/core/styles';
import { consultarParametroPorNombre } from 'network/Api/Parametro';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { descargarDocumento } from 'network/Api/BucketS3Aws';
const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: '360px',
    backgroundColor: theme.palette.background.paper,
  },
  closeBtn: {
    position: 'absolute',
    top: '0px',
    zIndex: '10',
    right: '0px',
    color: 'whitesmoke',
    mixBlendMode: 'difference',
    cursor: 'pointer',
  },
});

const ModalSlider = ({ classes, openM, onClose, width = '800px', checkNews, onLastSlide }) => {
  const [images, setImages] = useState([]);
  const [imageError, setImageError] = useState(null);

  useEffect(() => {
    const getImages = async () => {
      try {
        const newsImagesDB = await consultarParametroPorNombre({
          parametro: 'IMAGENES_PROMO',
        });
        if (newsImagesDB.data) {
          let localUrls = localStorage.getItem('newsUrls');
          if (localUrls && localUrls !== newsImagesDB.data) {
            checkNews(true);
          }
          localStorage.setItem('newsUrls', newsImagesDB.data);
          const imageUrls = newsImagesDB.data.split(';;;');
          const images = await Promise.all(
            imageUrls.map(async (element) => ({
              imgURL: await fetchImage(element),
            })),
          );
          setImages(images);
        }
      } catch (error) {
        setImageError(error);
        console.error('Error fetching images:', imageError);
      }
    };

    const fetchImage = async (url) => {
      try {
        const imgBlob = await descargarDocumento({
          fileName: url.split('.com/')[url.split('.com/').length - 1],
        });
        if (imgBlob.size > 0) {
          return URL.createObjectURL(imgBlob);
        } else {
          setImageError(true);
          return null;
        }
      } catch (error) {
        setImageError(error);
        console.error('Error fetching image:', imageError);
        return null;
      }
    };

    getImages();
  }, []);

  return (
    <div>
      <Dialog
        id='modalNews'
        open={openM}
        onClose={onClose}
        aria-labelledby='responsive-dialog-title'
      >
        <CloseOutlined className={classes.closeBtn} onClick={onClose} />
        {images.length > 0 && (
          <CustomSlider onLastSlide={onLastSlide}>
            {images.map(
              (image, index) =>
                image.imgURL && <img src={image.imgURL} alt={`promo-${index}`} key={index} />,
            )}
          </CustomSlider>
        )}
      </Dialog>
    </div>
  );
};

ModalSlider.propTypes = {
  fullScreen: PropTypes.bool,
  openModalNews: PropTypes.bool.isRequired,
  SetOpenModalNews: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withMobileDialog()(withStyles(styles)(ModalSlider));
