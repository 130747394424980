import CiudadesArray from '../../static/enums/ciudades';
import TiposIdentificacion from '../../static/enums/tiposIdentificacion';
import TiposPersona from '../../static/enums/tiposPersona';
import { tiposDocumentos } from '../../static/enums/tiposDocumentos';
import TiposRegimen from '../../static/enums/tiposRegimen';
import TiposMonedas from '../../static/enums/tiposMonedas';
import PlanesPrepago from '../../static/enums/planesPrepago';
import RegimenAdquirente from '../../static/enums/regimenAdquirente';
import TiposPago from '../../static/enums/tiposPago';
import TiposMediosPago from '../../static/enums/tiposMediosPago';
import TiposDocumentosValidacionPrevia from '../../static/enums/tiposDocumentosValidacionPrevia';
import TiposConceptoDebito from '../../static/enums/tiposConceptoDebito';
import TiposConceptoCredito from '../../static/enums/tiposConceptoCredito';
import TiposReferenciaDocumentos from '../../static/enums/tiposReferenciaDocumentos';
import TiposResponsabilidadesFiscales from '../../static/enums/tiposResponsabilidadesFiscales';
import TiposPorcentajesIVA from '../../static/enums/tiposPorcentajesIVA';
import TiposPorcentajesICO from '../../static/enums/tiposPorcentajesICO';
import CodigosDescuentos from '../../static/enums/CodigosDescuentos';
import TiposUnidadMedida from '../../static/enums/tiposUnidadMedida';
import TiposCondicionesEntrega from '../../static/enums/tiposCondicionesEntrega';
import TiposEstandarProducto from '../../static/enums/tiposEstandarProducto';
import RetenedorImpuestos from '../../static/enums/retenedorImpuestos';
import ResponsableIva from '../../static/enums/responsableIva';
import TiposPrecioReferencia from '../../static/enums/tiposPrecioReferencia';

const initialState = {
  ciudadesArray: CiudadesArray,
  tiposIdentificacion: TiposIdentificacion,
  tiposPersona: TiposPersona,
  tiposDocumentos: tiposDocumentos,
  tiposMonedas: TiposMonedas,
  tiposRegimen: TiposRegimen,
  planesPrepago: PlanesPrepago,
  regimenAdquirente: RegimenAdquirente,
  tiposPago: TiposPago,
  tiposMediosPago: TiposMediosPago,
  tiposDocumentosValidacionPrevia: TiposDocumentosValidacionPrevia,
  tiposConceptoDebito: TiposConceptoDebito,
  tiposConceptoCredito: TiposConceptoCredito,
  tiposReferenciaDocumentos: TiposReferenciaDocumentos,
  tiposResponsabilidadesFiscales: TiposResponsabilidadesFiscales,
  tiposPorcentajesIVA: TiposPorcentajesIVA,
  tiposPorcentajesICO: TiposPorcentajesICO,
  codigosDescuentos: CodigosDescuentos,
  tiposUnidadMedida: TiposUnidadMedida,
  tiposCondicionesEntrega: TiposCondicionesEntrega,
  tiposEstandarProducto: TiposEstandarProducto,
  retenedorImpuestos: RetenedorImpuestos,
  responsableIva: ResponsableIva,
  modal: { open: false },
  spinnerModal: false,
  openModalDetalleFactura: false,
  datosDocumento: {},
  datosCliente: {},
  datosAdicionalesDocumento: {},
  listaDetalleFactura: [],
  totalesFactura: {},
  openModalCodigoVerificacion: false,
  snackbar: { open: false },
  loading: false,
  userAttributes: {},
  attributesSuperEmisor: {},
  userGrupo: [],
  fechaExpiracionToken: '',
  openModalFiltrosFactura: false,
  facturasEnviadas: [],
  planes: [],
  openModalFacturasReferencia: {},
  listaFacturasReferencia: [],
  openModalDocumentosAdicionales: {},
  listaDocumentosAdicionales: [],
  cargosDescuentos: [],
  isFacturaExportacion: false,
  preDocumentoSelected: [],
  preDocumentos: [],
  detallesPreDocumento: [],
  openModal: false,
  openModalFiltrosVentas: false,
  tiposPrecioReferencia: TiposPrecioReferencia,
  emisorFacturasEnviadas: {}, //para reutilizar el componente de Facturas enviadas se abstrae los datos asociados al Login y se manejaran con esta variable
};

function factura(state = initialState, action) {
  switch (action.type) {
    case 'SET_TIPOS_IDENTIFICACION': {
      return {
        ...state,
        tiposIdentificacion: action.payload.item,
      };
    }
    case 'SET_DETALLE_FACTURA': {
      return {
        ...state,
        listaDetalleFactura: action.payload.item,
      };
    }
    case 'SET_TOTALES_FACTURA': {
      return {
        ...state,
        totalesFactura: action.payload.item,
      };
    }
    case 'SET_MODAL_CONFIRMACION_STATE': {
      return {
        ...state,
        modal: action.payload.item,
      };
    }
    case 'SET_SPINNER_MODAL': {
      return {
        ...state,
        spinnerModal: action.payload.item,
      };
    }
    case 'SET_MODAL_ADD_DETALLE_FACTURA': {
      return {
        ...state,
        openModalDetalleFactura: action.payload.item,
      };
    }
    case 'SET_DATOS_DOCUMENTO': {
      return {
        ...state,
        datosDocumento: action.payload.item,
      };
    }
    case 'SET_DATOS_CLIENTE': {
      return {
        ...state,
        datosCliente: action.payload.item,
      };
    }
    case 'SET_DATOS_ADICIONALES_DOCUMENTO': {
      return {
        ...state,
        datosAdicionalesDocumento: action.payload.item,
      };
    }
    case 'SET_MODAL_CODIGO_VERIFICACION': {
      return {
        ...state,
        openModalCodigoVerificacion: action.payload.item,
      };
    }
    case 'SET_SNACKBAR_STATE': {
      return {
        ...state,
        snackbar: action.payload.item,
      };
    }
    case 'SET_LOADING_VALUE': {
      return {
        ...state,
        loading: action.payload.item,
      };
    }
    case 'SET_USER_ATTRIBUTES': {
      return {
        ...state,
        userAttributes: action.payload.item,
        emisorFacturasEnviadas: action.payload.item,
      };
    }
    case 'SET_USER_GROUP': {
      return {
        ...state,
        userGrupo: action.payload.item,
      };
    }
    case 'LOGOUT': {
      return initialState;
    }
    case 'SET_OPEN_MODAL_FILTRO_FACTURA': {
      return {
        ...state,
        openModalFiltrosFactura: action.payload.item,
      };
    }
    case 'SET_FACTURAS_ENVIADAS': {
      return {
        ...state,
        facturasEnviadas: action.payload.item,
      };
    }
    case 'SET_PLANES': {
      return {
        ...state,
        planes: action.payload.item,
      };
    }
    case 'SET_ATTRIBUTES_SUPER_EMISOR': {
      return {
        ...state,
        attributesSuperEmisor: action.payload.item,
      };
    }
    case 'SET_MODAL_FACTURA_REFERENCIA': {
      return {
        ...state,
        openModalFacturasReferencia: action.payload.item,
      };
    }
    case 'SET_FACTURAS_REFERENCIA': {
      return {
        ...state,
        listaFacturasReferencia: action.payload.item,
      };
    }
    case 'SET_MODAL_DOCUMENTOS_ADICIONALES': {
      return {
        ...state,
        openModalDocumentosAdicionales: action.payload.item,
      };
    }
    case 'SET_DOCUMENTOS_ADICIONALES': {
      return {
        ...state,
        listaDocumentosAdicionales: action.payload.item,
      };
    }
    case 'SET_CARGOS_DESCUENTOS': {
      return {
        ...state,
        cargosDescuentos: action.payload.item,
      };
    }
    case 'SET_IS_FACTURA_EXPORTACION': {
      return {
        ...state,
        isFacturaExportacion: action.payload.item,
      };
    }
    case 'SET_PRE_DOCUMENTO_SELECCIONADO': {
      return {
        ...state,
        preDocumentoSelected: action.payload.item,
      };
    }
    case 'SET_PRE_DOCUMENTOS': {
      return {
        ...state,
        preDocumentos: action.payload.item,
      };
    }
    case 'SET_DETALLES_PREDOCUMENTO': {
      return {
        ...state,
        detallesPreDocumento: action.payload.item,
      };
    }
    case 'SET_OPEN_MODAL_PREDOCUMENTO': {
      return {
        ...state,
        openModal: action.payload.item,
      };
    }
    case 'SET_OPEN_MODAL_FILTROS_VENTAS': {
      return {
        ...state,
        openModalFiltrosVentas: action.payload.item,
      };
    }
    case 'SET_EMISOR_FACTURAS_ENVIADAS': {
      return {
        ...state,
        emisorFacturasEnviadas: action.payload.item,
      };
    }
    default:
      return state;
  }
}

export default factura;
