import React, { useCallback } from 'react';
import Button from 'components/atoms/Button';
import { habilitarEmisorPos } from 'network/Api/DocHabilitacion';

const mensaje =
  'Se está realizando el envío de las facturas para la habilitación en la DIAN, por favor valide que el emisor haya sido habilitado de manera correcta.';

const ButtonHabilitarPos = ({ nmIdEmisor, openModal, ...rest }) => {
  const onClick = useCallback(() => {
    habilitarEmisorPos({ nmId: nmIdEmisor });
    openModal(mensaje);
  }, [nmIdEmisor]);

  return (
    <Button onClick={onClick} {...rest}>
      Habilitar Emisor POS
    </Button>
  );
};

export default ButtonHabilitarPos;
