import React from "react";
import TipoIdentificacion from "../../static/enums/tiposIdentificacion";
import Select from "./Select";

export default function selectTipoIdentificacion({ ...rest }) {
  return (
    <Select
      options={TipoIdentificacion.map((ti) => ({
        label: ti.nombre,
        value: ti.tipoIdentificacionAdquiriente,
      }))}
      {...rest}
    />
  );
}

