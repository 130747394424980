import * as React from 'react';
import Select from 'components/molecules/Select';
import FormaPago from 'static/enums/tiposPago';
const { useMemo } = React;

function SelectFormaPago({ className, type, ...rest }) {
  const options = useMemo(() => {
    return FormaPago.map((fp) => ({ label: fp.nombre, value: fp.codigo }));
  }, []);

  return (
    <div className={className}>
      <Select options={options} {...rest} />
    </div>
  );
}

export default SelectFormaPago;
