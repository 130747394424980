import { getUserData, retrieveCurrentSessionRefreshToken } from 'network/Amplify';

const URL = process.env.REACT_APP_ELASTIC_URL;

export const serviceRoutes = {
  // Adquirente
  consultarAdquirentesPorEmisorYNombre: `${URL}adquirente/consultarporemisorynombre`,
  registrarAdquirente: `${URL}adquirente/registrar`,
  actualizarAdquirente: `${URL}adquirente/actualizar`,
  activarDesactivarAdquirente: `${URL}adquirente/activardesactivar`,
  consultarAdquirentePorId: `${URL}adquirente/consultarporid`,
  registrarActualizarAdquirente: `${URL}adquirente/registrar-actualizar`,
  registrarAdquirentePos: `${URL}adquirente/registrar-pos`,
  consultarAdquirentePorIdentificacion: `${URL}adquirente/consultar-identificacion`,
  generarTokenAutoRegistroAdquirente: `${URL}adquirente/generar-token`,
  // Anualidad
  actualizarFechasAnualidad: `${URL}membresia/actualizarfechas`,
  consultarAnualidad: `${URL}membresia/consultarporemisor`,
  // Auth
  registrarUsuario: `${URL}auth/registrar`,
  // Ayudas
  consultarAyudasActivas: `${URL}ayuda/consultaractivas`,
  consultarAyudas: `${URL}ayuda/consultar`,
  guardarAyuda: `${URL}ayuda/guardar`,
  actualizarAyuda: `${URL}ayuda/actualizar`,
  // Carga Masiva
  procesarDocumentosCargaMasiva: `${URL}cargamasiva/procesar`,
  registrarCargaMasiva: `${URL}cargamasiva/guardar`,
  // Cert Firma
  actualizarVerificadoFirma: `${URL}certfirma/actualizarverificado`,
  consultarFirmaPorEmisor: `${URL}certfirma/consultarporemisor`,
  registrarFirma: `${URL}certfirma/registrar`,
  actualizarFirma: `${URL}certfirma/actualizar`,
  notificarFirma: `${URL}certfirma/enviarnotificacion`,
  consultarFirmasVigentes: `${URL}certfirma/consultarvigentes`,
  // Ciudad
  consultarCiudades: `${URL}ciudad/consultar`,
  // Comentario
  consultarComentariosPorSolicitud: `${URL}comentario/consultar`,
  guardarNuevoComentario: `${URL}comentario/guardar`,
  // Contrato
  getContratos: `${URL}contrato/consultarempleado`,
  getContratosEmisor: `${URL}contrato/consultaremisor`,
  guardarContrato: `${URL}contrato/guardar`,
  actualizarContrato: `${URL}contrato/actualizar`,
  // CotizacionDocumento
  guardarCotizacion: `${URL}cotizaciondocumento/guardar`,
  consultarCotizaciones: `${URL}cotizaciondocumento/contultar`,
  facturarCotizacion: `${URL}cotizaciondocumento/facturarcotizacion`,
  generarPdfCotizacion: `${URL}cotizaciondocumento/generarpdf`,
  // Devengado
  consultarDevengadosPorEmpleado: `${URL}nominadevengado/consultarporempleado`,
  guardarDevengado: `${URL}nominadevengado/guardar`,
  eliminarDevengado: `${URL}nominadevengado/eliminar`,
  // Deduccion
  consultarDeduccionPorEmpleado: `${URL}nominadeduccion/consultarporempleado`,
  guardarDeduccion: `${URL}nominadeduccion/guardar`,
  eliminarDeduccion: `${URL}nominadeduccion/eliminar`,
  // DetalleDocumento
  consultarDocumentosEstadistica: `${URL}detalledocumento/consultartotalesventas`,
  // Distribuidor
  consultarDistribuidores: `${URL}distribuidor/consultartodos`,
  // Doc Habilitacion
  habilitarEmisorNomina: `${URL}habilitacion/nomina`,
  habilitarEmisorFacturacion: `${URL}habilitacion/facturador`,
  habilitarEmisorPos: `${URL}habilitacion/pos`,
  // Documentos
  consultarDocumentosEnviados: `${URL}documento/consultar`,
  consultarDocumentosErroneos: `${URL}documento/consultarerroneos`,
  consultarUltimosDiezDocumentos: `${URL}documento/consultarultimos`,
  enviarDocumento: `${URL}documento/enviardocumento`,
  reenviarDocumento: `${URL}documento/reenviardocumento`,
  consultarDocumentoExistente: `${URL}documento/consultarexistente`,
  registrarDocumento: `${URL}documento/registrardocumento`,
  calcularDetalleExcel: `${URL}documento/generardetalleexcel`,
  reenviarDocumentosErroneos: `${URL}documento/reenviarerroneos`,
  consultarDocumentoDomina: `${URL}documento/consultardomina`,
  generarExcelDocumentosEmitidos: `${URL}documento/generar-excel`,
  reenviarEmailDocumento: `${URL}documento/reenviar-email`,
  generarNotaCredito: `${URL}documento/generar-notacredito`,
  consultarAdquirenteDocumento: `${URL}documento/consultar-adquirente`,
  actualizarAdquirenteDocumento: `${URL}documento/actualizar-documento`,
  // Documento Express
  consultarJsonDocumentoExpress: `${URL}documentoexpress/consultar-json`,
  registrarDocumentoExpress: `${URL}documentoexpress/registrar`,
  validarConsultaDocumentoExpress: `${URL}documentoexpress/consultar`,
  // Documento Parqueadero
  registrarEntradaParqueadero: `${URL}documentoparqueadero/registrarentrada`,
  reenviarNotificacionParqueadero: `${URL}documentoparqueadero/reenviarnotificacion`,
  consultarReporteParqueadero: `${URL}documentoparqueadero/consultarreporte`,
  generarPdfParqueadero: `${URL}documentoparqueadero/generarpdf`,
  registrarSalidaParqueadero: `${URL}documentoparqueadero/registrarsalida`,
  // Documento Pos
  enviarDocumentoPos: `${URL}documentopos/enviardocumento`,
  consultarUrlPorPin: `${URL}documentopos/consultarporpin`,
  consultarDocumentosPorEmisor: `${URL}documentopos/consultarporemisor`,
  anularDocumentoPos: `${URL}documentopos/anulardoc`,
  regenerarPdfDocumentoPos: `${URL}documentopos/regenerarpdf`,
  generarDocumentoElectronico: `${URL}documentopos/generar-factura`,
  generarPrevisualizacionPos: `${URL}documentopos/generar-presisualizacion`,
  generarCierreCajaPos: `${URL}documentopos/generar-cierre`,
  generarConsolidadoVentasPos: `${URL}documentopos/generar-consolidado-ventas`,
  generarDetalleCierreCajaPos: `${URL}documentopos/generar-detalle-cierre`,
  // Documento Soporte
  enviarDocumentoSoporte: `${URL}documentosoporte/enviar`,
  consultarDocumentosSoporte: `${URL}documentosoporte/consultar`,
  consultarDocumentoPrefijoNumero: `${URL}documentosoporte/consultardocumento`,
  reenviarDocumentoSoporte: `${URL}documentosoporte/reenviar`,
  generarExcelDocumentosSoporte: `${URL}documentosoporte/generar-excel`,
  consultarDocumentosSoporteErroneos: `${URL}documentosoporte/consultar-erroneos`,
  reenviarDocumentosErroneosSoporte: `${URL}documentosoporte/reenviar-erroneos`,
  generarNotaCreditoSoporte: `${URL}documentosoporte/generar-notacredito`,
  // Emisor
  consultarEstadoTransaccion: `${URL}emisor/consultarestadotransaccion`,
  consultarConfiguracion: `${URL}emisor/consultarconfiguracion`,
  actualizarConfiguracion: `${URL}emisor/actualizarconfiguracion`,
  finalizarRegistro: `${URL}emisor/finalizarregistro`,
  consultarFechasVigencia: `${URL}emisor/fechasvigencia`,
  consultarEmisores: `${URL}emisor/consultartodos`,
  configurarEmisorDomina: `${URL}emisor/configurar`,
  consultarNombreEmisor: `${URL}emisor/consultar-nombre`,
  registrarEmisorExpress: `${URL}emisor/registro-express`,
  registrarNuevoEmisor: `${URL}emisor/registrar-emisor`,
  registrarNuevoSocio: `${URL}emisor/registrar-socio`,
  consultarDatosEmisor: `${URL}emisor/consultar-emisor`,
  subirArchivoEmisor: `${URL}emisor/uploadFile`,
  // Empleado
  retirarEmpleado: `${URL}empleado/retirar`,
  consultarEmpleadoPorId: `${URL}empleado/consultarporid`,
  getEmpleados: `${URL}empleado/consultar`,
  guardarEmpleado: `${URL}empleado/guardar`,
  actualizarEmpleado: `${URL}empleado/actualizar`,
  // Historial Saldo Dinero
  consultarHistorialCajaDinero: `${URL}historialsaldodinero/consultaremisor`,
  // Gasto
  registrarGasto: `${URL}gasto/guardarxml`,
  consultarGastos: `${URL}gasto/consultarregistros`,
  actualizarEventoGasto: `${URL}gasto/actualizarevento`,
  actualizarEstadosGastos: `${URL}gasto/actualizar-estados`,
  subirExcelCufesGastos: `${URL}gasto/procesar-cufes`,
  // Gasto Email
  consultarGastosEmailEntreFechas: `${URL}gastoemail/consultarfechas`,
  // Generic
  generarTokenRegistroDoc: `${URL}generic/generartokenregistrodoc`,
  getEnum: `${URL}generic/getenum`,
  // Mensaje
  consultarMensajesPorEmisor: `${URL}mensaje/consultarporemisor`,
  actualizarMensajesALeidos: `${URL}mensaje/actualizarleidos`,
  // Nomina
  enviarSoporteNomina: `${URL}documentonomina/enviarsoportes`,
  consultarSoportesEnviados: `${URL}documentonomina/consultarenviados`,
  reenviarEmail: `${URL}documentonomina/reenviaremail`,
  generarSoporteNomina: `${URL}documentonomina/generarsoporte`,
  remplazarSoporteNomina: `${URL}documentonomina/reemplazardocumento`,
  consultarDocumentoNominaPorId: `${URL}documentonomina/consultarporid`,
  regenerarDocumentoNomina: `${URL}documentonomina/regenerar`,
  // Notificacion
  consultarNotificacionesEntreFechas: `${URL}notificacion/consultarfechas`,
  guardarNotificacion: `${URL}notificacion/guardarnotificacion`,
  // Notificacion SMS
  consultarNotificacionesSmsEntreFechas: `${URL}notificacionsms/consultar`,
  guardarNotificacionSms: `${URL}notificacionsms/guardar`,
  // Parametro
  consultarParametros: `${URL}parametro/consultar`,
  consultarParametroPorNombre: `${URL}parametro/consultarnombre`,
  consultarParametroEmisor: `${URL}parametro/consultar`,
  consultarParametrosInicio: `${URL}parametro/consultar-inicio`,
  // Persona
  consultarPersonaPorIdentificacion: `${URL}persona/consultarporidentificacion`,
  consultarVentasPorAliado: `${URL}persona/consultarventas`,
  actualizaTransaccionPersona: `${URL}persona/actualizartransaccion`,
  consultarPersonas: `${URL}persona/consultartodas`,
  registrarPersona: `${URL}persona/registrar`,
  consultarAllVentas: `${URL}persona/consultartodasventas`,
  consultarEmailExistente: `${URL}persona/consultarEmail`,
  // Plan
  consultarPlanesPorDistribuidor: `${URL}plan/consultarpordistribuidor`,
  consultarPlanes: `${URL}plan/getplanes`,
  // Producto
  consultarProductosPorEmisor: `${URL}producto/consultarporemisor`,
  consultarProductosPorEmisorYDescripcion: `${URL}producto/consultarporemisorydescripcion`,
  registrarProducto: `${URL}producto/registrar`,
  actualizarProducto: `${URL}producto/actualizar`,
  actualizarEstadoProducto: `${URL}producto/actualizarestado`,
  consultarProductoPorEmisorYCodigo: `${URL}producto/consultarcodigo`,
  consultarProductoPorId: `${URL}producto/consultarid`,
  crearActualizarProducto: `${URL}producto/crearactualizar`,
  consultarProductosActivosPorEmisorYDescripcion: `${URL}producto/consultar-activos`,
  consultarCategoriasProductosPorEmisor: `${URL}producto/consultar-categorias`,
  // Punto Venta POS
  consultarPuntosVentaPos: `${URL}punto-venta-pos/consultar-todos`,
  consultarPuntoDeVentaPos: `${URL}punto-venta-pos/consultar-punto`,
  // Recarga
  actualizarEstadoRecarga: `${URL}recarga/actualizarporsolicitud`,
  registrarRecarga: `${URL}recarga/registrar`,
  guardarRecargaPin: `${URL}transaccion/recargarpin`,
  // Resolucion
  registrarResolucion: `${URL}resolucion/registrarnuevas`,
  actualizarListaResoluciones: `${URL}resolucion/actualizarlista`,
  consultarResolucionesFactutacionPosEmisor: `${URL}resolucion/consultarporemisor`,
  consultarResolucionesVigentes: `${URL}resolucion/consultarvigentes`,
  validarConsecutivos: `${URL}resolucion/validarconsecutivos`,
  registrarResolucionesDomina: `${URL}resolucion/registrodomina`,
  guardarNuevaResolucion: `${URL}resolucion/guardarnueva`,
  // Resolucion Pos
  consultarResolucionesPosEmisor: `${URL}resolucionpos/vigentesporemisor`,
  // Resolucion Soporte
  resolucionesSoporteVigentes: `${URL}resolucionsoporte/consultarvigentes`,
  guardarNuevaResolucionSoporte: `${URL}resolucionsoporte/guardarnueva`,
  actualizarListaResolucionesSoporte: `${URL}resolucionsoporte/actualizarlista`,
  // Saldo
  consultarSaldoPorEmisor: `${URL}saldo/consultarsaldoemisor`,
  // Saldo Dinero
  consultarCajaMenorPorEmisor: `${URL}cajamenor/consultarsaldoemisor`,
  transferirSaldoCajaMenor: `${URL}cajamenor/transferir-saldo`,
  // Saldo Pos
  consultarSaldoPosEmisor: `${URL}saldopos/consultar`,
  // Servicio Aliado
  consultarServiciosAliados: `${URL}servicio-aliado/vigentes`,
  // Solicitud
  guardarSolicitud: `${URL}solicitud/guardar`,
  consultarSolicitudesPorEmisor: `${URL}solicitud/consultar`,
  actualizarSolicitud: `${URL}solicitud/actualizar`,
  consultarSolicitudInformante: `${URL}solicitud/consultar-informante`,
  // Tiquetera
  consultarTiqueteraPorSerial: `${URL}tiquetera/consultar-serial`,
  asociarTiquetera: `${URL}tiquetera/asociar`,
  consultarTiqueterasPorEmisor: `${URL}tiquetera/consultar-emisor`,
  // Transaccion
  consultarEstadoPinesTransacciones: `${URL}transaccion/consultarporaliado`,
  generarPinTransaccion: `${URL}transaccion/generarpin`,
  validarPinTransaccion: `${URL}transaccion/validarpin`,
  registrarPersonaPin: `${URL}transaccion/registrarpin`,
  // Transaccion Recarga
  transaccionRecarga: `${URL}transaccionrecarga/registrar`,
  consultarTransaccionRecarga: `${URL}transaccionrecarga/consultar`,
  consultarHistoricoTransaccionRecarga: `${URL}transaccionrecarga/historico`,
  // Turno
  iniciarSiguienteTurno: `${URL}turno/siguiente-turno`,
  finalizarTruno: `${URL}turno/finalizar-turno`,
  solicitarTurno: `${URL}turno/solicitar-turno`,
  // Vendedor
  crearActualizarVendedor: `${URL}vendedor/registraractualizar`,
  consultarVendedores: `${URL}vendedor/consultar`,
  consultarVendedorId: `${URL}vendedor/consultarid`,
  // Venta aliado
  crearVentaAliado: `${URL}venta-aliado/registrar-venta`,
  // BucketS3
  descargarDocumentoS3: `${URL}s3/downloadFile`,
  // Historico Inventario
  consultarHistoricoInventarioEntreFechas: `${URL}historico-inventario/consultar`,
  // Compra
  registrarCompras: `${URL}compra/registrar`,
  consultarCompras: `${URL}compra/consultar`,
};

async function getToken() {
  try {
    await retrieveCurrentSessionRefreshToken();
    return await getUserData().then((result) =>
      result.signInUserSession ? result.signInUserSession.idToken.jwtToken : '',
    );
  } catch (ex) {
    return '';
  }
}

export async function getHeader() {
  return {
    Authorization: await getToken(),
    'Content-type': 'application/json',
  };
}

async function getReponse(res) {
  return {
    status: res.status,
    ...(await res.json()),
  };
}

export async function post(url, body) {
  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: await getHeader(),
  })
    .then((res) => getReponse(res))
    .catch((err) => err);
}

export async function postFormData(url, body) {
  let form = new FormData();
  Object.keys(body).forEach((key) => {
    if (key === 'files') {
      const files = body[key];
      if (files) {
        for (let index = 0; index < files.length; index++) {
          const file = files[index];
          form.append('files', file, file.name);
        }
      } else form.append('files', new Blob(), '');
    } else form.append(key, body[key]);
  });
  return await fetch(url, {
    method: 'POST',
    body: form,
    headers: { Authorization: await getToken() },
  })
    .then((res) => getReponse(res))
    .catch((err) => err);
}

export async function postFormDataa(url, body) {
  let form = new FormData();
  Object.keys(body).forEach((key) => {
    const itemBody = body[key];
    if (itemBody instanceof File) form.append(key, itemBody, itemBody.name);
    else if (itemBody instanceof FileList) {
      for (const file of itemBody) {
        form.append(key, file, file.name);
      }
    } else form.append(key, itemBody);
  });
  return await fetch(url, {
    method: 'POST',
    body: form,
    headers: { Authorization: await getToken() },
  })
    .then((res) => getReponse(res))
    .catch((err) => err);
}

export async function get(url, body) {
  return await fetch(`${url}/${body}`, {
    method: 'GET',
    headers: await getHeader(),
  })
    .then((res) => getReponse(res))
    .catch((err) => err);
}

export async function put(url, body) {
  return await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: await getHeader(),
  })
    .then((res) => getReponse(res))
    .catch((err) => err);
}

export async function getBlob(url, body) {
  return await fetch(`${url}/${body}`, {
    method: 'GET',
    headers: await getHeader(),
  })
    .then((res) => res.blob())
    .catch((err) => err);
}

export async function postBlob(url, body) {
  return await fetch(`${url}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: await getHeader(),
  })
    .then((res) => res.blob())
    .catch((err) => err);
}
