import styled from 'styled-components';

export const Paper = styled.div`
  padding: 2px;
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 23px -12px rgba(127, 143, 166, 0.87);
  border: 1px solid #eadadf;
  margin-bottom: 15px;
`;
