import React, { useCallback, useState } from 'react';
import Button from 'components/atoms/Button';
import { crearVentaAliado } from 'network/Api/VentaAliado';
import Modal from 'components/molecules/Modal';
import useModal from 'hooks/useModal';

function ButtonComprarServicio({ formulario, setErrores, closeModal, condiciones }) {
  const [disabled, setDisabled] = useState(false);
  const [modalProps, openModal] = useModal();

  const comprarServicio = useCallback(async () => {
    setDisabled(true);
    const errores = validarFormulario(formulario, condiciones);
    setErrores(errores);
    if (Object.keys(errores).length === 0) {
      const { data } = await crearVentaAliado(formulario);
      openModal({ content: data ? 'Compra exitosa' : 'Error realizando compra', onCancel: null });
    }
    setDisabled(false);
  }, [formulario]);

  return (
    <>
      <Button disabled={disabled} onClick={comprarServicio}>
        Canjear
      </Button>
      <Modal title='Resultado de la compra' onAccept={closeModal} {...modalProps} />
    </>
  );
}

const validarFormulario = (formulario, condiciones) => {
  const { terminos, dsNombre, dsIdentificacion, dsEmail, dsDireccion, dsCelular, autorizacion } =
    formulario;
  let errores = {};
  if (condiciones) if (!terminos) errores.terminos = 'Los terminos y condiciones son obligatorios';
  if (!dsNombre) errores.dsNombre = 'El nombre es obligatorio';
  if (!dsIdentificacion) errores.dsIdentificacion = 'La identificacion es obligatoria';
  if (!dsEmail) errores.dsEmail = 'El email es obligatorio';
  if (!dsDireccion) errores.dsDireccion = 'La direccion es obligatoria';
  if (!dsCelular) errores.dsCelular = 'El celular es obligatorio';
  if (!autorizacion) errores.autorizacion = 'Este campo es obligatorio';
  return errores;
};

export default ButtonComprarServicio;
