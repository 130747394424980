export default {
  Auth: {
    mandatorySignIn: false,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    storage: window.sessionStorage,
  },
  Storage: {
    bucket: process.env.REACT_APP_NOMBRE_BUCKET,
    region: process.env.REACT_APP_COGNITO_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
};
