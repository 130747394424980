export const styles = () => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    color: 'red',
  },
});
