import * as React from "react";
import { withStyles } from "@material-ui/core";
import { dateString } from "utils/DateUtils";

const styles = () => ({
  card: {
    width: "100%",
    borderRadius: "2px",
    padding: "10px",
    cursor: "pointer",
    boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.65)",
    "-webkit-box-shadow": "1px 1px 5px 0px rgba(0,0,0,0.65)",
    "-moz-box-shadow": "1px 1px 5px 0px rgba(0,0,0,0.65)",
    margin: "15px 0px",
    display: "flex",
  },
  asunto: { fontWeight: "bold" },
  colorHr: { border: "2px solid #4CAF50", margin: "0px 5px 0px 0px" },
});

const CardSolicitud = ({ classes, onClick, solicitud, responsables = [] }) => {
  const { dsEncargado, feProceso, feCreacion, dsAsunto, nmTicket } = solicitud;
  const responsable = responsables.find(({ value }) => dsEncargado === value);
  return (
    <div className={classes.card} onClick={onClick}>
      <div className={classes.colorHr} />
      <div>
        <h6 className={classes.asunto}>
          {nmTicket} - {dsAsunto}
        </h6>
        <h6>Creacion: {dateString(feCreacion).format("DD/MM/YYYY HH:mm:ss")}</h6>
        <h6>Modificado: {dateString(feProceso).format("DD/MM/YYYY HH:mm:ss")}</h6>
        <small>{responsable ? responsable.label : ""}</small>
      </div>
    </div>
  );
};

export default withStyles(styles)(CardSolicitud);
