import { serviceRoutes, post, put, get } from "./index";

export const registrarResolucion = async (body) =>
  await post(serviceRoutes.registrarResolucion, body);

export const actualizarListaResoluciones = async (body) =>
  await put(serviceRoutes.actualizarListaResoluciones, body);

export const consultarResolucionesPosEmisor = async ({ emisor }) =>
  await get(
    serviceRoutes.consultarResolucionesFactutacionPosEmisor,
    `?id=${emisor}`
  );

export const getResolucionesVigentes = async (nmIdEmisor) =>
  await get(serviceRoutes.consultarResolucionesVigentes, `${nmIdEmisor}`);

export const validarConsecutivos = async ({ resolucion, prefijo, emisor }) =>
  await get(
    serviceRoutes.validarConsecutivos,
    `?resolucion=${resolucion}&prefijo=${prefijo}&emisor=${emisor}`
  );

export const registrarResolucionesDomina = async ({ id }) =>
  await get(serviceRoutes.registrarResolucionesDomina, `?id=${id}`);

export const guardarNuevaResolucion = async (body) =>
  await post(serviceRoutes.guardarNuevaResolucion, body);
