import * as React from "react";
import { Typography } from "@material-ui/core";
import Input from "components/Inputs/Input";
import Button from 'components/atoms/Button';
import SolicitudRealizada from "./SolicitudRealizada";
import SolicitudCancelada from "./SolicitudCancelada";
import ListaSedes from "./ListaSedes";
import { solicitarTurno } from "network/Api/Turno";
import { withStyles } from "@material-ui/core/styles";
import { consultarNombreEmisor } from "network/Api/Emisor";
import Modal from "components/modal/ModalConfirmacion";
import { TIPOS_DATOS, validarString } from "utils/ValidarDatos";
import logo from "../../icons/logoItau.png";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import moment from "moment";

const style = (theme) => ({
  form: {
    width: "40%",
    margin: "30px auto",
    [theme.breakpoints.down(850)]: {
      width: "90%",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
    margin: "10px",
  },

  itauButton: {
    margin: "5px",
    backgroundColor: "#EC7404",
    color: "#ffff",
  },

  contenedor: {
    width: "140px",
    height: "140px",
    overflow: "hidden",
    backgroundColor: "#EC7404",
    padding: "0",
    borderRadius: "20px",
    margin: "auto",
  },

  logo: {
    margin: "20px",
  },

  title: {
    textAlign: "center",
    margin: "30px",
  },

  inputDiv: {
    marginBottom: "10px",
  },

  selectDiv: {
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    padding: "5px 8px 8px 8px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    height: "38px",
    marginBottom: "10px",
  },

  inputFecha: {
    marginTop: "2px",
  },

  select: {
    width: "100%",
  },
});

const SolicitarTurno = ({ classes }) => {
  const [state, setData] = React.useState({ comercio: {}, feProgramacion: moment().format("YYYY-MM-DTHH:mm:ss") });

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  React.useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const sede = url.get("sede");
    if (!sede || sede === "") {
      setState({ pantalla: "LISTASEDES" });
    } else {
      setState({ dsSede: sede });
    }

    consultarNombreEmisor({ id: url.get("emisor") }).then(({ data }) => {
      if (data) {
        const { nmId, nombreCompleto } = data;
        setState({ nombre: nombreCompleto, emisor: { nmId } });
      } else window.location.href = "/";
    });
  }, []);

  const handleChange = ({ target }) => setState({ [target.name]: target.value });

  const handleChangeNames = ({ target }) => {
    const { name, value } = target;
    const newValue = value.replace(/(?:\s)\s/g, " ");
    setState({ [name]: newValue });
  };

  const solicitarAtencion = () => {
    const { dsNombre, dsApellido, dsCelular, emisor, dsTipoIdentificacion, dsIdentificacion, feProgramacion, dsTipoServicio, dsSede } = state;
    const error = validarFormulario();
    if (!error)
      solicitarTurno({
        emisor,
        dsNombre,
        dsApellido,
        dsCelular,
        dsTipoIdentificacion,
        dsIdentificacion,
        dsTipoServicio,
        feProgramacion,
        dsSede,
      }).then((data) => {
        setPantallaInicial();
        if (data) setState({ pantalla: "SOLICITADO" });
      });
    else openModal(error);
  };

  const cancelarSolicitud = () => {
    setPantallaInicial();
    setState({ pantalla: "CANCELADO" });
  };

  const setPantallaInicial = () =>
    setTimeout(
      () =>
        setState({
          pantalla: "LISTASEDES",
          dsNombre: "",
          dsApellido: "",
          dsCelular: "",
          dsTipoIdentificacion: "",
          dsIdentificacion: "",
          dsTipoServicio: "",
          feProgramacion: "",
          dsSede: "",
        }),
      6000
    );

  const validarFormulario = () => {
    const { dsNombre = "", dsApellido = "", dsCelular = "", dsIdentificacion = "" } = state;
    if (!dsNombre || !dsApellido) return "Todos los campos del formulario son obligatorios"; //aca
    if (dsNombre.length > 50 || dsNombre === " ") return "El nombre no pueden superar los 50 caracteres";
    if (dsNombre === " ") return "El nombre no puede ser un campo vacio";
    if (dsApellido.length > 50 || dsApellido === " ") return "El apellido no pueden superar los 50 caracteres";
    if (dsApellido === " ") return "El apellido no pueden ser un campo vacio";
    if (dsCelular.length !== 10 || !validarString(dsCelular, TIPOS_DATOS.numeros)) return "Número celular invalido";
    if (dsIdentificacion.length > 20) return "la identificación no puede superar los 20 caracteres";
  };

  const openModal = (contentModal) => setState({ openModal: true, contentModal });

  return (
    <div className={classes.form}>
      {state.pantalla === "LISTASEDES" ? (
        <ListaSedes />
      ) : state.pantalla === "SOLICITADO" ? (
        <SolicitudRealizada />
      ) : state.pantalla === "CANCELADO" ? (
        <SolicitudCancelada />
      ) : (
        <>
          <div className={classes.contenedor}>
            <img className={classes.logo} src={logo} alt="Logo Itau" />
          </div>
          <div className={classes.title}>
            <Typography variant="h4">Bienvenido a</Typography>
            <Typography variant="h4">Itaú</Typography>
          </div>
          <div className="inputs">
            <div className={classes.inputDiv}>
              <Input label="Nombres" name="dsNombre" value={state.dsNombre} onChange={handleChangeNames} />
            </div>
            <div className={classes.inputDiv}>
              <Input label="Apellidos" name="dsApellido" value={state.dsApellido} onChange={handleChangeNames} />
            </div>
            <div className={classes.inputDiv}>
              <Input label="Celular" name="dsCelular" value={state.dsCelular} onChange={handleChange} />
            </div>

            <label>Tipo de Identificación</label>
            <div className={classes.selectDiv}>
              <Select
                className={classes.select}
                label="Tipo de Identificación"
                name="dsTipoIdentificacion"
                value={state.dsTipoIdentificacion}
                onChange={handleChange}
              >
                <MenuItem className={classes.menuItem} value={"CC"}>
                  Cédula de ciudadania
                </MenuItem>
                <MenuItem className={classes.menuItem} value={"CE"}>
                  Cédula extranjería
                </MenuItem>
                <MenuItem className={classes.menuItem} value={"PS"}>
                  Pasaporte
                </MenuItem>
                <MenuItem className={classes.menuItem} value={"NIT"}>
                  NIT
                </MenuItem>
              </Select>
            </div>
            <div className={classes.inputDiv}>
              <Input label="Identificación" name="dsIdentificacion" value={state.dsIdentificacion} onChange={handleChange} />
            </div>
            <label>Tipo de servicio</label>
            <div className={classes.selectDiv}>
              <Select className={classes.select} label="Tipo de Servicio" name="dsTipoServicio" value={state.dsTipoServicio} onChange={handleChange}>
                <MenuItem className={classes.menuItem} value={"Caja"}>
                  Caja
                </MenuItem>
                <MenuItem className={classes.menuItem} value={"Asesoria"}>
                  Asesoria
                </MenuItem>
              </Select>
            </div>

            <div className={classes.inputDiv}>
              <Input label="Fecha de solicitud" name="feProgramacion" type="datetime-local" value={state.feProgramacion} onChange={handleChange} />
            </div>
          </div>
          <div className={classes.buttons}>
            <Button className={classes.itauButton} onClick={solicitarAtencion}>
              Solicitar turno
            </Button>
            <Button className={classes.itauButton} onClick={cancelarSolicitud}>
              Cancelar turno
            </Button>
          </div>
        </>
      )}
      <Modal open={state.openModal} content={state.contentModal} onAccept={state.onAcceptModal} onCancel={() => setState({ openModal: false })} />
    </div>
  );
};

export default withStyles(style)(SolicitarTurno);
