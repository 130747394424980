import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from 'components/atoms/Button';
import Input from "components/Inputs/Input";
import TablaGenerica from "components/Tablas/TablaGenerica";
import * as ApiNomina from "network/Api/Nomina";
import * as DateUtils from "utils/DateUtils";
import * as TextFormattingUtils from "utils/TextFormattingUtils";
import ModalMensaje from "components/modal/ModalMensaje";
import ModalConfirmacion from "components/modal/ModalConfirmacion";
import { consultarParametroPorNombre } from "network/Api/Parametro";

function soportePago({ userAttributes }) {
  const [soportes, setSoportes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSoporte, setLoadingSoporte] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const [modal, setModal] = useState({});
  const [modalConfimacion, setModalConfimacion] = useState({});
  const [fechaPago, setFechaPago] = useState(
    DateUtils.getLastDayOfMonth(DateUtils.now().subtract(1, "month")).format(
      "YYYY-MM-DD"
    )
  );

  useEffect(() => consultarSoporteNomina(), [userAttributes.codigoEmisor]);

  useEffect(() => consultarDiasPruebas(), []);

  useEffect(() => validarMes(), [fechaPago]);

  const consultarDiasPruebas = () => {
    consultarParametroPorNombre({ parametro: "DIAS_SOPORTE_NOMINA" }).then(
      (res) => {
        if (res.status === 200) {
          const dias = parseInt(res.data);
          setButtonActive(!(DateUtils.now().format("DD") <= dias));
        }
      }
    );
  };

  const validarMes = () => {
    const fechaActual = DateUtils.now();
    const fechaPagoMoment = DateUtils.dateString(fechaPago);
    setButtonActive(fechaActual.format("MM-YYYY") === fechaPagoMoment.format("MM-YYYY"));
    consultarSoporteNomina();
  };

  const consultarSoporteNomina = () => {
    if (userAttributes.codigoEmisor) {
      setLoading(true);
      ApiNomina.generarSoporteNomina(
        userAttributes.codigoEmisor,
        fechaPago
      ).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setSoportes(res.data);
        }
      });
    }
  };

  const guardarSoportes = () => {
    let soportesSinConfiguracion = soportes
      .filter((s) => !s.enviarSoporte)
      .map(
        (s) =>
          `${getVacio(s.empleado.dsNombre)} ${getVacio(
            s.empleado.dsSegundoNombre
          )} ${getVacio(s.empleado.dsPrimerApellido)} ${getVacio(
            s.empleado.dsSegundoApellido
          )}`
      );
    if (soportesSinConfiguracion.length === 0) {
      setLoadingSoporte(true);
      setLoading(true);
      ApiNomina.enviarSoportes(
        soportes.map((s) => ({
          ...s,
          fechasPago: [fechaPago],
          empleado: {
            nmId: s.empleado.nmId,
          },
        }))
      );
      setLoadingSoporte(false);
      setLoading(false);
      setSoportes([]);
      closeModalConfirmacion();
      openModal("Los documentos de nomina se estan procesando");
    } else {
      openModal(
        `No se encuentra habilitado para enviar documentos de nomina o los siguientes empleados no tiene configurado las deducciones y/o devengados obligatorios. ${soportesSinConfiguracion.join(
          ", "
        )}`
      );
    }
  };

  const openModal = (text) => setModal({ open: true, text });

  const openModalConfirmacion = () => setModalConfimacion({ open: true });

  const closeModalConfirmacion = () =>
    setModalConfimacion({ ...modalConfimacion, open: false });

  const handleChangeDate = (event) => {
    const date =
      event.target.type === "month"
        ? event.target.value + "-01"
        : event.target.value;
    setFechaPago(
      DateUtils.getLastDayOfMonth(DateUtils.dateString(date)).format(
        "YYYY-MM-DD"
      )
    );
  };

  return (
    <div>
      <ModalMensaje
        content={modal.text}
        handleClose={() => setModal({ ...modal, open: false })}
        open={modal.open}
      />
      <ModalConfirmacion
        open={modalConfimacion.open}
        content={
          <Input
            type="date"
            label="Fecha pago de nomina"
            value={fechaPago}
            max={DateUtils.getLastDayOfMonth(
              DateUtils.now().subtract(1, "month")
            ).format("YYYY-MM-DD")}
            onChange={(e) => setFechaPago(e.target.value)}
          />
        }
        onAccept={guardarSoportes}
        onCancel={closeModalConfirmacion}
      />
      <div style={{ width: "200px" }}>
        <Input
          label="Fecha de soporte"
          type={
            window.navigator.userAgent.includes("Chrome/") ? "month" : "date"
          }
          onChange={handleChangeDate}
          value={
            window.navigator.userAgent.includes("Chrome/")
              ? DateUtils.dateString(fechaPago).format("YYYY-MM")
              : fechaPago
          }
          min={
            window.navigator.userAgent.includes("Chrome/")
              ? "2021-10"
              : "2021-10-01"
          }
          max={
            window.navigator.userAgent.includes("Chrome/")
              ? DateUtils.getLastDayOfMonth(DateUtils.now()).format("YYYY-MM")
              : DateUtils.getLastDayOfMonth(DateUtils.now()).format(
                  "YYYY-MM-DD"
                )
          }
        />
      </div>
      <TablaGenerica
        loading={loading}
        rowsPerPage={15}
        headers={[
          "Empleado",
          "Sueldo",
          "Total devengado",
          "Total deducciones",
          "Total nómina",
          "Mes",
        ]}
        keys={[
          "dsNombreEmpleado",
          "nmSueldo",
          "nmTotalDevengado",
          "nmTotalDeduccion",
          "nmTotalNomina",
          "feMesNomina",
        ]}
        detalles={soportes
          .map((s) => ({
            ...s,
            feMesNomina: DateUtils.getDateFromArray(s.feMesNomina).format(
              "MMMM / YYYY"
            ),
            nmSueldo: TextFormattingUtils.moneyFormat(s.nmSueldo),
            nmTotalDevengado: TextFormattingUtils.moneyFormat(
              s.nmTotalDevengado
            ),
            nmTotalDeduccion: TextFormattingUtils.moneyFormat(
              s.nmTotalDeduccion
            ),
            dsNombreEmpleado: `${getVacio(s.empleado.dsNombre)} ${getVacio(
              s.empleado.dsSegundoNombre
            )} ${getVacio(s.empleado.dsPrimerApellido)} ${getVacio(
              s.empleado.dsSegundoApellido
            )}`,
            nmTotalNomina: TextFormattingUtils.moneyFormat(
              s.nmTotalDevengado - s.nmTotalDeduccion
            ),
          }))
          .sort(function(a, b) {
            if (a.dsNombreEmpleado < b.dsNombreEmpleado) return -1;
            else if (a.dsNombreEmpleado > b.dsNombreEmpleado) return 1;
            else return 0;
          })}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          disabled={buttonActive || loadingSoporte || soportes.length === 0}
          onClick={() => openModalConfirmacion()}
        >
          Soporte DIAN
        </Button>
      </div>
    </div>
  );
}

function mapStateToProps(state, _) {
  return { userAttributes: state.factura.userAttributes };
}

function mapDispatchToProps(_) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(soportePago);

function getVacio(str) {
  return str ? str : "";
}
