/* eslint-disable */
import React from 'react';
import FacturaIcon from '@material-ui/icons/FileCopy';
import AllInboxRoundedIcon from '@material-ui/icons/AllInboxRounded';
import NuevoUsuarioIcon from '@material-ui/icons/AssignmentInd';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import VentasIcon from '@material-ui/icons/Assessment';
import DirectionsCarRoundedIcon from '@material-ui/icons/DirectionsCarRounded';
import ClearAllRoundedIcon from '@material-ui/icons/ClearAllRounded';
import { Emisor as EmisorMenu } from 'components/menu/Grupos/Emisor';
import { EmisorSinFirma as EmisorSinFirmaMenu } from 'components/menu/Grupos/EmisorSinFirma';
import { Nomina as NominaMenu } from 'components/menu/Grupos/Nomina';
import { Facturacion as FacturacionMenu } from 'components/menu/Grupos/Facturacion';
import { Gerencial as GerencialMenu } from 'components/menu/Grupos/Gerencial';
import { Contable as ContableMenu } from 'components/menu/Grupos/Contable';
import { EmisorPos as EmisorPosMenu } from 'components/menu/Grupos/EmisorPos';
import { Distribuidores as DistribuidoresMenu } from 'components/menu/Grupos/Distribuidores';
import { AsesorTurno as AsesorTurnoMenu } from 'components/menu/Grupos/AsesorTurno';
import { ServicioCliente as ServicioClienteMenu } from 'components/menu/Grupos/ServicioCliente';
import { DocumentosExpress as DocumentosExpressMenu } from 'components/menu/Grupos/DocumentoExpress';
import { Socio as SocioMenu } from 'components/menu/Grupos/Socio';
import { Medico as MedicoMenu } from 'components/menu/Grupos/Medico';

export function getPaths(grupos) {
  let paths = new Set();
  grupos.forEach((grupo) =>
    (grupo ? eval(grupo) : SinRol).forEach((item) => {
      if (!item.subMenu) paths.add(item.pathname);
      else paths = new Set([...paths, ...item.items.map((i) => i.pathname)]);
    }),
  );
  return [...paths, '/cambiar-password', '/resultado-recarga', '/recarga'];
}

export function getPrincipalPath() {
  return '/Home';
}

export function getTitle(grupos, path) {
  let a;
  let allItems = [];
  grupos.forEach((grupo) => {
    if (grupo) {
      eval(grupo).forEach((item) =>
        item.subMenu
          ? item.items.forEach((itemSub) => allItems.push(itemSub))
          : allItems.push(item),
      );
      let b = allItems.filter((item) => item.pathname === path)[0];
      if (b) a = b.title;
    } else {
      SinRol.forEach((item) =>
        item.subMenu
          ? item.items.forEach((itemSub) => allItems.push(itemSub))
          : allItems.push(item),
      );
      let b = allItems.filter((item) => item.pathname === path)[0];
      if (b) a = b.title;
    }
  });
  return a;
}

export const CargaMasiva = [
  {
    title: 'Carga Masiva de Documentos',
    icon: <ClearAllRoundedIcon />,
    pathname: '/CargaMasiva',
  },
];

export const EmisorSinFirma = EmisorSinFirmaMenu;

export const Administrador = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
  {
    title: 'Habilitar emisor',
    icon: <BuildRoundedIcon />,
    pathname: '/habilitar_emisor',
  },
  {
    title: 'Nuevo usuario',
    icon: <NuevoUsuarioIcon />,
    pathname: '/registrarse',
    principal: 'true',
  },
];

export const Predocumentos = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
  {
    title: 'Factura asistida',
    icon: <FacturaIcon />,
    pathname: '/factura_asistida',
    principal: 'true',
  },
];

export const Ventas = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
  {
    title: 'Ventas',
    icon: <VentasIcon />,
    pathname: '/ventas',
  },
  {
    title: 'Generar Pin',
    icon: <VentasIcon />,
    pathname: '/GenerarPin',
  },
  {
    title: 'Estados Pin',
    icon: <VentasIcon />,
    pathname: '/EstadosPin',
  },
];

export const Parqueadero = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
  {
    title: 'Parqueadero',
    icon: <DirectionsCarRoundedIcon />,
    pathname: '/parqueadero',
  },
  {
    title: 'Reporte Parqueadero',
    icon: <AllInboxRoundedIcon />,
    pathname: '/ReporteParqueadero',
  },
];

export const ServicioCliente = ServicioClienteMenu;

// NUEVOS ROLES

export const SinRol = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
];

export const Emisor = EmisorMenu;
export const Nomina = NominaMenu;
export const Facturacion = FacturacionMenu;
export const Gerencial = GerencialMenu;
export const Contable = ContableMenu;
export const EmisorPos = EmisorPosMenu;
export const Distribuidores = DistribuidoresMenu;
export const AsesorTurno = AsesorTurnoMenu;
export const DocumentoExpress = DocumentosExpressMenu;
export const Socio = SocioMenu;
export const Medico = MedicoMenu;
