/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  SetSpinnerModal,
  SetConfirmacionModalState,
} from "../../reducers/actions/facturaActions";
import { ordenarArray } from "../../utils/funcionesUtiles";
import { consultarEmisores } from "network/Api/Emisor";
import Select from "react-select";

class SelectConsultaEmisores extends Component {
  state = {
    emisoresData: [],
  };

  componentDidMount = () => {
    this.consultarEmisores();
  };

  campoVacio = (valor) => {
    let emptyValue = "";
    if (valor === undefined) return emptyValue;
    if (valor === null) return emptyValue;
    return valor;
  };

  mensajes = (close, texto, onClick, parametro) => {
    let modal;
    if (close) {
      modal = { open: false };
      this.props.setConfirmacionModalState(modal);
      return;
    }

    if (parametro != null) {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(parametro),
        cancelarVisible: true,
      };
    } else {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(),
        cancelarVisible: true,
      };
    }

    this.props.setConfirmacionModalState(modal);
  };

  consultarEmisores = () => {
    const { setSpinnerModal } = this.props;
    setSpinnerModal(true);
    consultarEmisores().then((response) => {
      setSpinnerModal(false);
      if (response.status === 200) {
        this.setState({ emisoresData: response.data });
      } else {
        this.mensajes(
          false,
          `ERROR: No se pudo obtener los emisores.`,
          this.mensajes,
          true
        );
      }
    });
  };

  render() {
    const { handleConsultarEmisor } = this.props;
    const { emisoresData } = this.state;
    let emisoresSelect = [];
    if (emisoresData.length > 0) {
      emisoresData.map((item) => {
        emisoresSelect.push({
          value: item.identificacionEmisor,
          label: `${item.fullName} - ${item.identificacionEmisor}`,
          id: item.codigoEmisor,
        });
      });
    }
    emisoresSelect = ordenarArray(emisoresSelect);
    return <Select options={emisoresSelect} onChange={handleConsultarEmisor} />;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
    setConfirmacionModalState: (item) =>
      dispatch(SetConfirmacionModalState(item)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectConsultaEmisores);
