export function recalcularJson(json) {
  const detalles = recalcularDetalles(json.detalles);
  const cargosDescuentos = getCargosDescuentosDetalle({ detalles });
  const impuestos = getImpuestosRetencionesDetalle({ detalles });
  const descuentoTotal = getTotalArray(cargosDescuentos, 'valor');
  const valorBruto = getTotalArray(detalles, 'valorTotalBruto');
  const valorImpuesto = getTotalArray(impuestos, 'valorTotal');
  const valorBrutoMasTributos = formatNumber(parseFloat(valorBruto) + parseFloat(valorImpuesto));
  const valorNeto = formatNumber(parseFloat(valorBrutoMasTributos) - parseFloat(descuentoTotal));
  const retenciones = json.impuestoRetencion || [];
  return {
    ...json,
    detalles,
    cargosDescuentos,
    impuestosRetenciones: [...impuestos, ...retenciones],
    descuentoTotal,
    valorBruto,
    valorBrutoMasTributos,
    valorNeto,
  };
}

function getImpuestosRetencionesDetalle({ detalles }) {
  const impuestosDetalles = detalles
    .filter((d) => d.impuestosRetenciones)
    .map((d) => d.impuestosRetenciones)
    .filter((d) => d);
  let subtotales = {};
  impuestosDetalles.forEach((detalleImpuestos) => {
    detalleImpuestos.forEach((detalleImpuesto) => {
      detalleImpuesto.subtotales.forEach((subtotal) => {
        let subtotalImpuesto = subtotales[subtotal.tributo];
        if (subtotalImpuesto) subtotales[subtotal.tributo].push(subtotal);
        else subtotales[subtotal.tributo] = [subtotal];
      });
    });
  });
  return Object.keys(subtotales).map((key) => ({
    valorTotal: formatNumber(parseFloat(getTotalArray(subtotales[key], 'valorImpuestoRetencion'))),
    subtotales: subtotales[key],
  }));
}

function getCargosDescuentosDetalle({ detalles }) {
  return detalles
    .filter((d) => d.cargosDescuentos)
    .map((d) => d.cargosDescuentos[0])
    .filter((d) => d);
}

function recalcularDetalles(detallesDoc) {
  return detallesDoc.map((detalle) => {
    const { cantidad, precioUnitario } = detalle;
    const valorBase = cantidad * precioUnitario;
    const cargosDescuentos = calcularCargosDescuentos({ valorBase, detalle });
    const valorTotalDescuento = getTotalArray(cargosDescuentos, 'valor');
    const valorBaseImponible = formatNumber(valorBase - valorTotalDescuento);
    const impuestosRetenciones = calcularImpuestosRetenciones({
      detalle,
      valorBase: valorBaseImponible,
    });
    const valorTotalImpuestosRetenciones = getTotalArray(impuestosRetenciones, 'valorTotal');
    return {
      ...detalle,
      cargosDescuentos,
      impuestosRetenciones,
      valorTotalImpuestosRetenciones,
    };
  });
}

function calcularCargosDescuentos({ valorBase, detalle }) {
  if (detalle.cargosDescuentos) {
    return detalle.cargosDescuentos.map((cargoDescuento) => {
      const valor = formatNumber((valorBase * cargoDescuento.porcentaje) / 100);
      return { ...cargoDescuento, valor };
    });
  }
}

function calcularImpuestosRetenciones({ detalle, valorBase }) {
  if (detalle.impuestosRetenciones) {
    return detalle.impuestosRetenciones.map((impuestoRetencion) => {
      const subtotales = impuestoRetencion.subtotales.map((subtotal) => {
        const valorImpuestoRetencion = (valorBase * subtotal.porcentaje) / 100;
        return {
          ...subtotal,
          valorBase,
          valorImpuestoRetencion: formatNumber(valorImpuestoRetencion),
        };
      });
      return {
        subtotales,
        valorTotal: getTotalArray(subtotales, 'valorImpuestoRetencion'),
      };
    });
  }
}

function getTotalArray(array, key) {
  let valor = 0;
  if (array) array.forEach((item) => item && (valor += parseFloat(item[key])));
  return formatNumber(valor);
}

function formatNumber(num) {
  return num.toFixed(4);
}
