import * as React from 'react';
import Input from 'components/Inputs/Input';

const DatosExportacion = ({ documento, setDocumento }) => {
  const handleChangeTipoCambio = ({ target }) => {
    const { name, value } = target;
    setDocumento({
      tipoCambio: {
        ...documento.tipoCambio,
        [name]: value,
      },
    });
  };

  const handleChangeIncoterms = ({ target }) => {
    const { name, value } = target;
    setDocumento({
      incoterms: {
        ...documento.incoterms,
        [name]: value,
      },
    });
  };

  const handleFechaCambio = ({ target }) => {
    const { name, value } = target;
    setDocumento({
      tipoCambio: {
        ...documento.tipoCambio,
        [name]: `${value} 00:00:00`,
      },
    });
  };

  return (
    <>
      <div className='form-row col-md-12'>
        <div className='form-group col-md-6 '>
          <Input
            label='Tipo entrega *'
            name='codigoIncoterm'
            value={(documento.incoterms || {}).codigoIncoterm}
            onChange={handleChangeIncoterms}
          />
        </div>
        <div className='form-group col-md-6 '>
          <Input
            label='Condiciones entrega *'
            name='condicionesEntrega'
            value={(documento.incoterms || {}).condicionesEntrega}
            onChange={handleChangeIncoterms}
          />
        </div>
      </div>
      {documento.moneda !== 'COP' && (
        <div className='form-row col-md-12'>
          <div className='form-group col-md-6 '>
            <Input
              label='Valor moneda acordado *'
              name='valorTipoCambio'
              value={(documento.tipoCambio || {}).valorTipoCambio}
              onChange={handleChangeTipoCambio}
            />
          </div>
          <div className='form-group col-md-6 '>
            <Input
              label='Fecha acuerdo'
              name='fechaTipoCambio'
              type='date'
              value={
                (documento.tipoCambio || {}).fechaTipoCambio
                  ? (documento.tipoCambio || {}).fechaTipoCambio.split(' ')[0]
                  : null
              }
              onChange={handleFechaCambio}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DatosExportacion;
