import * as React from "react";
// import Icon from "components/menu/Icon";
// import ConfigIcon from "static/images/config.png";
import ResolucionIcon from "@material-ui/icons/FormatListNumberedRounded";
import FirmaIcon from "@material-ui/icons/Gesture";

// const getMenu = (items) => ({
//   subMenu: true,
//   title: "Configuración",
//   icon: <Icon src={ConfigIcon} alt="Configuracion" />,
//   items,
// });

const gestionFirma = {
  title: "Firma digital",
  icon: <FirmaIcon />,
  pathname: "/gestionarFirmaEmisor",
};

export const gestionResoluciones = {
  title: "Configurar resolución",
  icon: <ResolucionIcon />,
  pathname: "/gestionarResolucionesEmisor",
};

export const Configuracion = gestionFirma;

export const ConfiguracionSinFirma = gestionFirma;

export const ConfiguracionGerencial = gestionFirma;
