import React from "react";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import "./styles/Spinner.css";

function LoadingScreen({ open }) {
  return (
    <Dialog open={open} aria-labelledby="responsive-dialog-title">
      <div className="loading">Loading&#8230;</div>
    </Dialog>
  );
}

export default withMobileDialog()(LoadingScreen);
