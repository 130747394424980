const initialState = {
    openModalGenerico: false
}

function utils(state = initialState, action) {
    switch (action.type) {
        case "SET_OPEN_MODAL_GENERICO":
        return {
            ...state,
            openModalGenerico: action.payload.item
        }
        default:
            return state;
    }
}

export default utils;