import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Typography from 'components/atoms/Typography';
import Button from 'components/atoms/Button';

function Modal({
  open = false,
  content = <></>,
  onAccept,
  onCancel,
  title = 'PortalFactura',
  fullWidth,
  maxWidth = 'sm',
  extraButtons = [],
  fullScreen,
  textAlignContent = 'unset',
  onAcceptText = 'Aceptar',
  onAcceptProps = { variant: 'text' },
  showBtns = true,
  disableButtons = false,
}) {
  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      onClose={onCancel}
    >
      {title && (
        <DialogTitle style={{ textAlign: 'center' }}>
          <Typography variant='labelEncabezado'>{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent style={{ textAlign: textAlignContent }}>{content}</DialogContent>
      {showBtns && (
        <DialogActions>
          {onAccept && (
            <Button onClick={onAccept} disabled={disableButtons}  {...onAcceptProps}>
              {onAcceptText}
            </Button>
          )}
          {extraButtons.map(({ onClick, name }) => (
            <Button variant='text' onClick={onClick} disabled={disableButtons} >
              {name}
            </Button>
          ))}
          {onCancel && (
            <Button variant='text' onClick={onCancel} disabled={disableButtons} >
              Cerrar
            </Button>
          )}
        </DialogActions>
      )}

    </Dialog>
  );
}

export default Modal;
