import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from 'screens/DocumentoExcel/styles';
import { Grid, Paper } from '@material-ui/core';
import DatosDocumento from 'components/Documento/DatosDocumento';
import DatosAdquirente from 'components/Documento/DatosCliente';
import DatosAdicionalesDocumento from 'components/Documento/DatosAdicionalesDocumento';
import { now } from 'utils/DateUtils';
import DetallesDocumento from 'components/Documento/DetallesDocumento';
import ModalAddDetalle from 'components/Documento/ModalAddDetalleRegistroDoc';
import ButtonLimpiar from './ButtonLimpiar';
import Modal from 'components/modal/ModalConfirmacion';
import ButtonCotizar from './ButtonCotizar';
import Button from 'components/atoms/Button';
import Previsualisacion from 'components/templates/PrevisualizacionDocumento';
import Visualizar from 'static/images/visualizar.png';
import Icon from 'components/menu/Icon';

const CotizacionDocumento = ({ userAttributes, classes }) => {
  const [documento, setDataDocumento] = React.useState({
    tipoDocumentoElectronico: 'VENTA',
    moneda: 'COP',
    fechaEmision: now().format('YYYY-MM-DD'),
    fechaVencimiento: now().add(1, 'month').format('YYYY-MM-DD'),
    pago: {
      formaPago: 'CONTADO',
      medioPago: 'OTRO',
    },
    tipoOperacion: 'ESTANDAR',
    snDistribucionFisica: 'N',
  });
  const [modalState, setModalState] = React.useState({});
  const [messageState, setDataMessageState] = React.useState({});

  const setDocumento = (doc) => setDataDocumento((d) => ({ ...d, ...doc }));

  const setMessageState = (msg) => setDataMessageState((d) => ({ ...d, ...msg }));

  const addDetalle = (detalle) =>
    setDocumento({ detalles: [...(documento.detalles || []), detalle] });

  const handleCloseModal = () => setModalState((m) => ({ ...m, open: false }));

  const handleOpeneModal = () => setModalState((m) => ({ ...m, open: true }));

  const isExportacion = () => documento.tipoDocumentoElectronico === 'EXPORTACION';

  const openModal = ({
    content,
    onAccept,
    onCancel = () => setMessageState({ open: false }),
    fullWidth = false,
    title,
  }) => setMessageState({ open: true, content, onAccept, onCancel, fullWidth, title });

  const limpiarFormulario = () => window.location.reload();

  const openPreview = (preview) => openModal({ content: preview, fullWidth: true, title: null });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Grid container spacing={8}>
        <Grid item md={12} xs={12}>
          <DatosDocumento
            setDocumento={setDocumento}
            documento={documento}
            emisor={userAttributes.codigoEmisor}
            isCotizacion={true}
            disableTipoDocumento={false}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Paper className={classes.paper}>
            <DatosAdquirente
              emisor={userAttributes.codigoEmisor}
              setDocumento={setDocumento}
              documento={documento}
            />
          </Paper>
        </Grid>
        <Grid item md={4} xs={12}>
          <Paper className={classes.paper}>
            <DatosAdicionalesDocumento documento={documento} setDocumento={setDocumento} />
          </Paper>
        </Grid>
      </Grid>
      <DetallesDocumento
        emisor={userAttributes.codigoEmisor}
        detalles={documento.detalles}
        setDocumento={setDocumento}
        addDetalles={handleOpeneModal}
      />
      <Grid item md={12} xs={12}>
        <div className='form-row col-md-12 d-flex justify-content-end pt-3'>
          <div className='form-group col-xs-6'>
            <Button
              style={{ backgroundColor: '#92C63E' }}
              onClick={() =>
                openPreview(<Previsualisacion documento={documento} emisor={userAttributes} />)
              }
            >
              <Icon style={{ margin: '0px 5px' }} src={Visualizar} alt='Previsualisar' />
              Previsualisar
            </Button>
          </div>
          <div className='form-group col-xs-6'>
            <ButtonLimpiar openModal={openModal} limpiarFormulario={limpiarFormulario} />
          </div>
          <div className='form-group col-xs-6'>
            <ButtonCotizar
              documento={documento}
              openModal={openModal}
              limpiarFormulario={limpiarFormulario}
              userAttributes={userAttributes}
            />
          </div>
        </div>
      </Grid>
      <ModalAddDetalle
        emisor={userAttributes.codigoEmisor}
        addDetalle={addDetalle}
        modalState={modalState}
        handleClose={handleCloseModal}
        isExportacion={isExportacion()}
      />
      <Modal
        open={messageState.open}
        content={messageState.content}
        onAccept={messageState.onAccept}
        onCancel={messageState.onCancel}
        fullWidth={messageState.fullWidth}
        title={messageState.title}
        maxWidth='xl'
      />
    </form>
  );
};

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

export default connect(mapStateToProps)(withStyles(styles)(CotizacionDocumento));
