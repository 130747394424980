import { serviceRoutes, put, post } from "./index";

export const actualizarEstadoRecarga = async (body) =>
  await put(serviceRoutes.actualizarEstadoRecarga, body);

export const registrarRecarga = async (body) =>
  await post(serviceRoutes.registrarRecarga, body);

export const guardarRecargaPin = async (body) =>
  await post(serviceRoutes.guardarRecargaPin, body);
