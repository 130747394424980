import React from 'react';
import { Typography } from 'antd';
import { moneyFormat } from 'utils/TextFormattingUtils';
const { Text } = Typography;

function DetalleServicio({ dsServicio, dsAliado, nmValorPortal, feVigencia }) {
  return (
    <>
      <Typography>
        <Text strong>Servicio: </Text>
        {dsServicio}
      </Typography>
      <Typography>
        <Text strong>Aliado: </Text>
        {dsAliado}
      </Typography>
      <Typography>
        <Text strong>Precio: </Text>
        {moneyFormat(nmValorPortal)}
      </Typography>
      <Typography>
        <Text strong>Vigencia: </Text>
        {feVigencia}
      </Typography>
    </>
  );
}

export default DetalleServicio;
