import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as DateUtils from "utils/DateUtils";

const styles = () => ({
  table: { maxWidth: "80%", margin: "0px auto", border: "1px solid #e1e0e0" },
  daysBefore: { backgroundColor: "#f44336", color: "#fff" },
});

function TablaVigencias({ classes, detalles, loading }) {

  function getClassName(feFinFirma, feFinAnualidad) {
    return DateUtils.dateString(feFinFirma).subtract(1, "month") < DateUtils.now() ||
      DateUtils.dateString(feFinAnualidad).subtract(1, "month") < DateUtils.now()
      ? classes.daysBefore
      : "";
  }

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Emisor</TableCell>
          <TableCell>Identificacion</TableCell>
          <TableCell>Fecha Inicio Firma Vigente</TableCell>
          <TableCell>Fecha Fin Firma Vigente</TableCell>
          <TableCell>Fecha Inicio Anualidad</TableCell>
          <TableCell>Fecha Fin Anualidad</TableCell>
        </TableRow>
      </TableHead>
      {loading ? (
        <TableBody>
          <TableRow>
            <TableCell colSpan={6} style={{ textAlign: "center" }}>
              <div className="spinner-border text-primary" />
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {detalles &&
            detalles.map((detalle) => (
              <TableRow
                className={getClassName(
                  detalle.feFinFirma,
                  detalle.feFinAnualidad
                )}
              >
                <TableCell
                  className={getClassName(
                    detalle.feFinFirma,
                    detalle.feFinAnualidad
                  )}
                >
                  {detalle.dsNombres}
                </TableCell>
                <TableCell
                  className={getClassName(
                    detalle.feFinFirma,
                    detalle.feFinAnualidad
                  )}
                >
                  {detalle.dsIdentificacion}
                </TableCell>
                <TableCell
                  className={getClassName(
                    detalle.feFinFirma,
                    detalle.feFinAnualidad
                  )}
                >
                  {detalle.feInicioFirma}
                </TableCell>
                <TableCell
                  className={getClassName(
                    detalle.feFinFirma,
                    detalle.feFinAnualidad
                  )}
                >
                  {detalle.feFinFirma}
                </TableCell>
                <TableCell
                  className={getClassName(
                    detalle.feFinFirma,
                    detalle.feFinAnualidad
                  )}
                >
                  {detalle.feInicioAnualidad}
                </TableCell>
                <TableCell
                  className={getClassName(
                    detalle.feFinFirma,
                    detalle.feFinAnualidad
                  )}
                >
                  {detalle.feFinAnualidad}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      )}
    </Table>
  );
}

export default withStyles(styles)(TablaVigencias);
