import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import LabelTitulos from 'components/layout/labelTitulos';
import { actualizarProducto } from 'network/Api/Producto';
import Form from './Formulario';
const { useCallback } = React;

const ACTUALIZADO_EXITOSO = 'El producto se actualizo exitosamente!';

function ModalUpdateProducto({
  userAttributes,
  productoUpdate,
  onClose,
  onOpenModal,
  setSpinnerModal,
  onConsulta,
  filter,
  open = false,
  ...rest
}) {
  const updateProducto = useCallback(async (producto) => {
    setSpinnerModal(true);
    const { data } = await actualizarProducto(producto);
    setSpinnerModal(false);
    if (data) {
      onOpenModal({
        open: true,
        text: ACTUALIZADO_EXITOSO,
        onClick: () => onOpenModal({ open: false }),
      });
      onConsulta(filter);
    } else
      onOpenModal({
        open: true,
        text: `Ocurrió un error guardando el producto, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte.`,
        onClick: () => onOpenModal({ open: false }),
      });
  }, []);

  return (
    <Dialog
      aria-labelledby='form-dialog-title'
      scroll='paper'
      fullWidth={true}
      maxWidth='xl'
      onClose={onClose}
      open={open}
      {...rest}
    >
      <DialogTitle id='form-dialog-title'>
        <LabelTitulos texto='Actualizar producto' />
      </DialogTitle>
      <DialogContent className='mb-5'>
        <Form
          handleClose={onClose}
          nmIdEmisor={userAttributes.codigoEmisor}
          productoUpdate={productoUpdate}
          handleSave={updateProducto}
          openModal={onOpenModal}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ModalUpdateProducto;
