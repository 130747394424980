const tiposConceptoCredito = [
  {
    codigo: "DEVOLUCION_BIENES",
    nombre:
      "Devolución de parte de los bienes; no aceptación de partes del servicio",
  },
  {
    codigo: "ANULACION_FACTURA",
    nombre: "Anulación de factura electrónica",
  },
  {
    codigo: "REBAJA_APLICADA",
    nombre: "Rebaja o descuento parcial o total",
  },
  {
    codigo: "AJUSTE_PRECIO",
    nombre: "Ajuste de precio",
  },
  {
    codigo: "OTROS",
    nombre: "Otros",
  },
];

export default tiposConceptoCredito;
