import React from "react";

export default function LoaderText({ text }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="spinner-border text-primary" />
      <p
        style={{ textAlign: "center", margin: "0px 5px" }}
        className="text-muted"
      >
        {text}
      </p>
    </div>
  );
}
