import React, { useState } from "react";
import DocumentosEnviados from "screens/DocumentosEnviados";
import InputEmisores from "components/Inputs/SelectConsultaEmisores";
import { consultarConfiguracion } from "network/Api/Emisor";

const DocumentosServicioCliente = () => {
  const [state, setData] = useState({});

  const setState = (data) => setData((s) => ({ ...data, ...s }));

  const handleChangeEmisor = ({ id, value }) => {
    consultarConfiguracion({ nmId: id }).then((emisor) => {
      setState({
        id: parseInt(id),
        identificacion: value,
        token: emisor.data.tokenSeguridad,
      });
    });
  };

  return (
    <div>
      <InputEmisores handleConsultarEmisor={handleChangeEmisor} />
      <DocumentosEnviados
        emisor={state.id}
        identificacion={state.identificacion}
        token={state.token}
      />
    </div>
  );
};

export default DocumentosServicioCliente;
