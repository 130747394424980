import * as React from "react";
import Select from "components/Inputs/Select";
import EstandarProducto from "static/enums/tiposEstandarProducto";

const SelectEstandarProducto = ({ value, ...rest }) => {
  const options = EstandarProducto.map(({ codigo, nombre }) => ({
    label: nombre,
    value: codigo,
  }));

  return (
    <Select
      options={options}
      value={options.find((ep) => ep.value === value)}
      {...rest}
    />
  );
};

export default SelectEstandarProducto;
