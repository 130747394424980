import * as React from 'react';
import { connect } from 'react-redux';
import Input from 'components/molecules/Input';
import useForm from 'hooks/useForm';
import Table from 'components/molecules/Table';
import Button from 'components/atoms/Button';
import { consultarCompras, registrarCompras } from 'network/Api/Compra';
import Modal from 'components/molecules/Modal';
import useModal from 'hooks/useModal';
const { useMemo, useCallback, useEffect } = React;

const DEFAULT_STATE = {
  inicio: '2024-10-01',
  fin: '2024-10-31',
};

function Compras({ userAttributes }) {
  const { state, handleChange, handleChangeFiles, setState } = useForm(DEFAULT_STATE);
  const [modalProps, openModal] = useModal();

  const detalles = useMemo(() => {
    const { compras = [] } = state;
    return compras;
  }, [state.compras]);

  useEffect(() => {
    const { codigoEmisor } = userAttributes;
    if (codigoEmisor) {
      consultar();
    }
  }, [userAttributes]);

  const consultar = useCallback(async () => {
    const { codigoEmisor: emisor } = userAttributes;
    const { inicio, fin } = state;
    setState({ loadingConsulta: true });
    const { data = [] } = await consultarCompras({ emisor, inicio, fin });
    setState({ compras: data, loadingConsulta: false });
  }, [state.inicio, state.fin, userAttributes]);

  const subirArchivo = useCallback(async () => {
    const { codigoEmisor: emisor } = userAttributes;
    const { documentosFiles: files } = state;
    if (!files) return;
    setState({ loading: true });
    const { data = {} } = await registrarCompras({ emisor, files });
    const keys = Object.keys(data);
    let mensajes = [];
    for (const key of keys) mensajes.push(`${key}: ${data[key]}`);
    openModal({ content: mensajes.join(', ') });
    consultar();
    clearSubir();
  }, [state.documentosFiles, userAttributes]);

  const clearSubir = useCallback(() => {
    setState({ documentos: undefined, documentosFiles: undefined, loading: false });
  }, [setState]);

  return (
    <>
      <div className='form-group'>
        <div className='form-row d-flex align-items-end'>
          <Input
            label='Selecciona el archivo'
            type='file'
            name='documentos'
            value={state.documentos}
            files={state.documentosFiles}
            onChange={handleChangeFiles}
            className='col-3'
            accept='application/x-zip-compressed,application/zip,text/xml'
            multiple
          />
          <div className='col-2'>
            <Button onClick={subirArchivo} loading={state.loading}>
              Subir
            </Button>
          </div>
        </div>
      </div>
      <div className='form-group'>
        <div className='form-row  d-flex align-items-end'>
          <Input
            label='Fecha Inicio'
            type='date'
            name='inicio'
            value={state.inicio}
            onChange={handleChange}
            className='col-2'
            max={state.fin}
          />
          <Input
            label='Fecha Fin'
            type='date'
            name='fin'
            value={state.fin}
            onChange={handleChange}
            className='col-2'
            min={state.inicio}
          />
          <div className='col-2'>
            <Button onClick={consultar} loading={state.loadingConsulta}>
              Consultar
            </Button>
          </div>
        </div>
      </div>
      <Table
        headers={[
          { label: 'CUFE' },
          { label: 'Prefijo' },
          { label: 'Numero' },
          { label: 'Forma de Pago' },
          { label: 'Nombre Emisor' },
          { label: 'Fecha del Documento' },
        ]}
        keys={['dsCufe', 'dsPrefijo', 'dsNumero', 'cdFormaPago', 'dsNombreEmisor', 'feDocumento']}
        detalles={detalles}
      />
      <Modal {...modalProps} />
    </>
  );
}

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

export default connect(mapStateToProps)(Compras);
