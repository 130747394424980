import { serviceRoutes, get } from "./index";

export const consultarPlanesPorDistribuidor = async ({ distribuidor }) =>
  await get(
    serviceRoutes.consultarPlanesPorDistribuidor,
    `?distribuidor=${distribuidor}`
  );

export const consultarPlanPorDistribuidorYTipo = async (distribuidor, tipoPlan) =>
  await get(
    serviceRoutes.consultarPlanes,
    `${distribuidor}/${tipoPlan}`
  );
