export const tiposDocumentos = [
  {
    idTipo: "VENTA",
    nombre: "Factura de venta",
    nombreCotizacion: "Cotización de venta",
    isCotizacion: true,
    isExcel: true,
  },
  {
    idTipo: "NOTA_DEBITO",
    nombre: "Nota débito",
    isCotizacion: false,
  },
  {
    idTipo: "NOTA_CREDITO",
    nombre: "Nota crédito",
    isCotizacion: false,
    isExcel: true,
  },
  {
    idTipo: "EXPORTACION",
    nombre: "Factura de exportación",
    nombreCotizacion: "Cotización de exportación",
    isCotizacion: true,
  },
  {
    idTipo: "NOTA_CREDITO_SOPORTE_ADQUISICION",
    nombre: "Nota credito soporte",
    isDocumentoSoporte: true,
  },
  {
    idTipo: "SOPORTE_ADQUISICION",
    nombre: "Soporte Adquisicion",
    isDocumentoSoporte: true,
  },
];

export function getTipoDocumentoByName(nombre) {
  return tiposDocumentos.filter((documento) => documento.nombre === nombre);
}
