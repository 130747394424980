import React, { useState } from "react";
import Input from "../../../Inputs/Input";
import Button from 'components/atoms/Button';
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function fondoSP({ baseBody, guardarDeduccion, xlCol = "2" }) {
  const [nmPorcentaje, setNmPorcentaje] = useState();
  const [nmDeduccionSp, setNmDeduccionSp] = useState();
  const [nmPorcentajeSub, setNmPorcentajeSub] = useState();
  const [nmDeduccionSub, setNmDeduccionSub] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `FONDO_SP`,
    nmPorcentaje,
    nmDeduccionSp,
    nmPorcentajeSub,
    nmDeduccionSub,
  };

  const guardar = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDeduccion(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmDeduccionSp("");
    setNmPorcentajeSub("");
    setNmPorcentaje("");
    setNmDeduccionSub("");
  };

  return (
    <form onSubmit={guardar}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Porcentaje del pago"
            value={nmPorcentaje}
            onChange={(e) => setNmPorcentaje(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Debe corresponder al porcentaje de deducción de fondo de seguridad pensional que paga el trabajador"
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago de Solidaridad Pensional"
            value={nmDeduccionSp}
            onChange={(e) => setNmDeduccionSp(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Todo trabajador que devengue un sueldo que sea igual o superior a 4 salarios mininos, debe aportar un 1% al Fondo de solidaridad pensional."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Porcentaje del Fondo de Subsistencia"
            value={nmPorcentajeSub}
            onChange={(e) => setNmPorcentajeSub(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Se debe colocar el Porcentaje que correspondiente al Fondo de Subsistencia correspondiente"
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago del Fondo de Subsistencia"
            value={nmDeduccionSub}
            onChange={(e) => setNmDeduccionSub(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor Pagado correspondiente a Fondo de Subsistencia por parte del trabajador"
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  const {
    nmPorcentaje = 0,
    nmPorcentajeSub = 0,
    nmDeduccionSp = 0,
    nmDeduccionSub = 0,
  } = body;
  let errores = [];
  if (
    (!nmPorcentaje || nmPorcentaje > 100) &&
    !nmDeduccionSp &&
    ((!nmPorcentajeSub || nmPorcentajeSub > 1) && !nmDeduccionSub)
  )
    errores.push(
      "El porcentaje del pago se esta enviando fuera del rango de 1 a 100"
    );
  return errores;
}
