/* eslint-disable */
function formatearDecimales(num) {
    if (num == '') {
        num = 0
    }
    num = parseFloat(num)
    let value = `${num.toFixed(2)}`;
    return value;
}

export function cargosDescuentosAgrupar(items) {
    if (items.length === 0) return [];
    let cargosDescuentosAgrupado = [];
    items.map(item => {
        item.cargosDescuentos.map(descuento => {
            let existe = cargosDescuentosAgrupado.filter(actual => {
                return actual.codigoDescuento == descuento.codigoDescuento && actual.porcentaje == descuento.porcentaje
            })

            if (existe.length === 0) {
                cargosDescuentosAgrupado.push(descuento);
            } else {
                let cargoDescuento = {
                    id: existe[0].id,
                    tipo: existe[0].tipo,
                    codigoDescuento: existe[0].codigoDescuento,
                    porcentaje: existe[0].porcentaje,
                    valor: formatearDecimales(parseFloat(existe[0].valor) + parseFloat(descuento.valor)),
                    valorBase: formatearDecimales(parseFloat(existe[0].valorBase) + parseFloat(descuento.valorBase))
                }

                cargosDescuentosAgrupado.push(cargoDescuento);
            }

        })        
        
        let data = cargosDescuentosAgrupado.reverse();
        cargosDescuentosAgrupado = data.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.codigoDescuento === item.codigoDescuento && t.porcentaje === item.porcentaje
            ))
        )
    })


    // let data = cargosDescuentosAgrupado.reverse();
    // let result = data.filter((item, index, self) =>
    //     index === self.findIndex((t) => (
    //         t.codigoDescuento === item.codigoDescuento && t.porcentaje === item.porcentaje 
    //     ))
    // )
    return cargosDescuentosAgrupado;
}


