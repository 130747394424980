const tiposPersona = [
  {
    idTipoPersona: "1",
    nombre: "Persona Natural y asimiladas",
    tipoPersonaAdquiriente: "NATURAL",
  },
  {
    idTipoPersona: "2",
    nombre: "Persona Jurídica y asimiladas",
    tipoPersonaAdquiriente: "JURIDICA",
  },
];

export const selectOptions = tiposPersona.map(
  ({ tipoPersonaAdquiriente, nombre }) => ({
    label: nombre,
    value: tipoPersonaAdquiriente,
  })
);

export default tiposPersona;
