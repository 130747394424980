/* eslint-disable */
import React, { Component } from "react";
import { Menu, MenuItem, Badge, IconButton } from "@material-ui/core";
import SaldoIcon from "@material-ui/icons/MonetizationOn";
import CambiarEmisor from "@material-ui/icons/ViewList";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Link } from "react-router-dom";

class MenuProfile extends Component {
  render() {
    const {
      anchorEl,
      userAttributes,
      isMenuOpen,
      mobileMoreAnchorEl,
      isMobileMenuOpen,
      confirmLogOut,
      handleMenuClose,
      handleChangeEmisor,
      profileMenuOpen,
      handleOpenDrawer,
    } = this.props;

    return (
      <div>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <Link style={{ textDecoration: "none" }} to={"/cambiar-password"}>
            <MenuItem onClick={handleMenuClose}>Cambiar contraseña</MenuItem>
          </Link>
          <Link style={{ textDecoration: "none" }} to={"/recargar_saldo"}>
            <MenuItem onClick={handleMenuClose}>Recargar saldo</MenuItem>
          </Link>
          <MenuItem onClick={confirmLogOut}>Cerrar sesión</MenuItem>
        </Menu>
        <Menu
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMobileMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleOpenDrawer}>
            <IconButton color="inherit">
              <Badge
                badgeContent={userAttributes.saldoEmisor}
                color="secondary"
              >
                <SaldoIcon />
              </Badge>
            </IconButton>
            <p>Mi saldo</p>
          </MenuItem>
          <MenuItem onClick={handleChangeEmisor}>
            <IconButton color="inherit">
              <CambiarEmisor />
            </IconButton>
            <p>Emisores</p>
          </MenuItem>
          <MenuItem onClick={profileMenuOpen}>
            <IconButton color="inherit">
              <AccountCircle />
            </IconButton>
            <p>Mi cuenta</p>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default MenuProfile;
