export default [
  {
    grupo: 'Esencial',
    config: true,
  },
  {
    grupo: 'Nomina',
    config: true,
  },
  {
    grupo: 'Facturacion',
    config: true,
  },
  {
    grupo: 'Gerencial',
    config: true,
  },
  {
    grupo: 'Contable',
    config: true,
  },
  {
    grupo: 'Parqueadero',
    config: true,
  },
  {
    grupo: 'EmisorSinFirma',
    config: true,
  },
  {
    grupo: 'CargaMasiva',
    config: true,
  },
  {
    grupo: 'Medico',
    config: true,
  },
  {
    grupo: 'Emisor',
    config: true,
  },
  {
    grupo: 'Ventas',
    config: false,
  },
  {
    grupo: 'ServicioCliente',
    config: false,
  },
  {
    grupo: 'Predocumentos',
    config: false,
  },
  {
    grupo: 'Administrador',
    config: false,
  },
  {
    grupo: 'EmisorPos',
    config: true,
  },
  {
    grupo: 'AsesorTurno',
    config: true,
  },
  {
    grupo: 'DocumentoExpress',
    config: false,
  },
  {
    grupo: 'Socio',
    config: true,
  },
];
