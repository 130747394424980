/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Checkbox, FormControlLabel, Input } from '@material-ui/core';
import { SetEmisor } from 'reducers/actions/maestrosActions';
import { SetConfirmacionModalState } from 'reducers/actions/facturaActions';
import Select from 'react-select';
import { extenciones } from 'utils/UploadS3';
import ButtonDescargaArchivos from 'components/organisms/ButtonDescargaArchivos';
import Icon from 'components/molecules/Icon';

const styles = {
  fontFamily: { whiteSpace: 'nowrap' },
  inputFile: { display: 'flex', alignItems: 'center' },
};

const customStyles = {
  input: (styles) => {
    return { ...styles, height: '2.25rem' };
  },
};

class InformacionEmisor extends Component {
  state = { multiValue: [] };

  handleMultiChange = async (option) => {
    let multiValue = await this.state.multiValue;
    let seleccionados = this.props.emisor.respFiscales;
    seleccionados = seleccionados.split(';');
    let existe = seleccionados.filter((item) => item == option.value);

    if (existe.length > 0) {
      let newItemsMultiValue = multiValue.filter((item) => item.value != option.value);
      let newItemsDsRespFiscales = '';
      newItemsMultiValue.map((item) => {
        newItemsDsRespFiscales = `${newItemsDsRespFiscales}${item.value};`;
      });
      this.setState({
        multiValue: newItemsMultiValue,
        DsRespFiscales: newItemsDsRespFiscales,
      });
    } else {
      let DsRespFiscales = '';
      if (this.props.emisor.respFiscales.length > 0) {
        DsRespFiscales = `${this.props.emisor.respFiscales}${option.value};`;
      } else {
        DsRespFiscales = `${option.value};`;
      }
      this.setState({
        multiValue: [...multiValue, option],
        DsRespFiscales: DsRespFiscales,
      });
    }
  };

  handleLoadImg = async (event) => {
    const { setEmisor, emisor } = this.props;
    const [file] = event.target.files;
    if (file && extenciones[file.type] !== undefined) {
      let img = new FileReader();
      img.onloadend = () => {
        setEmisor({
          ...emisor,
          logo: img.result,
          logoLoaded: {
            file: file,
            name: file.name,
          },
        });
      };
      img.readAsDataURL(file);
    } else {
      this.mensajes(false, `Tipo de archivo no admitido`, this.mensajes, true);
    }
  };

  mensajes = (close, texto, onClick, parametro) => {
    let modal;
    if (close) {
      modal = { open: false };
      this.props.setConfirmacionModalState(modal);
      return;
    }

    if (parametro != null) {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(parametro),
        cancelarVisible: true,
      };
    } else {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(),
        cancelarVisible: true,
      };
    }

    this.props.setConfirmacionModalState(modal);
  };

  ordenarArray = (array) => {
    array.sort(function (a, b) {
      let nombre = a.label.toString().toLowerCase();
      let nombreComparar = b.label.toString().toLowerCase();
      if (nombre > nombreComparar) {
        return 1;
      }
      if (nombre < nombreComparar) {
        return -1;
      }
      return 0;
    });
    return array;
  };

  handleChange = (event) => {
    const { setEmisor, emisor } = this.props;
    switch (event.target.id) {
      case 'tokenSeguridad':
        setEmisor({ ...emisor, tokenSeguridad: event.target.value });
        break;
      case 'emailRemitenteEnvios':
        setEmisor({ ...emisor, emailRemitenteEnvios: event.target.value });
        break;
      case 'codigoCiiu':
        setEmisor({ ...emisor, codigoCiiu: event.target.value });
        break;
      case 'respFiscales':
        setEmisor({ ...emisor, respFiscales: event.target.value });
        break;
      case 'testid':
        setEmisor({ ...emisor, testid: event.target.value });
        break;
      case 'piePagina':
        setEmisor({ ...emisor, piePagina: event.target.value });
        break;
      case 'cdTipoPlantilla':
        setEmisor({ ...emisor, cdTipoPlantilla: event.target.value });
        break;
      case 'checkGenerarPdf':
        setEmisor({ ...emisor, snPdfNomina: event.target.checked });
        break;
      default:
        break;
    }
  };

  getSnRespIva = (regimen) => {
    switch (regimen) {
      case 'RESPONSABLE_IVA':
        return 'S';
      case 'NO_RESPONSABLE_IVA':
        return 'N';
      default:
        return null;
    }
  };

  validarCamposVacios = (event, dataChange) => {
    const { setValidacionEmisor, validacionEmisor } = this.props;
    if (event.target.value === '') {
      setValidacionEmisor({ ...validacionEmisor, ...dataChange });
    }
  };

  handleChangeCdTipoPersona = (value) => {
    const { emisor } = this.props;
    this.props.setEmisor({ ...emisor, tipoPersona: value.value });
  };

  handleChangeRegimenAdquirente = (value) => {
    const { emisor } = this.props;
    this.props.setEmisor({
      ...emisor,
      tipoRegimen: value.value,
      respIva: this.getSnRespIva(value.value),
    });
  };

  handleChangeCdRetenedorImpuestos = (value) => {
    const { emisor } = this.props;
    this.props.setEmisor({ ...emisor, retenedorImpuesto: value.value });
  };

  handleChangeCdRespIva = (value) => {
    const { emisor } = this.props;
    this.props.setEmisor({ ...emisor, respIva: value.value });
  };

  render() {
    const {
      emisor,
      retenedorImpuestosList,
      responsableIvaList,
      tiposPersona,
      regimenAdquirente,
      tiposDsRespFiscales,
    } = this.props;

    let habilitado = emisor.habilitado === 'S';

    let responsableIvaSelect = [];
    responsableIvaList.map((item) => {
      responsableIvaSelect.push({ value: item.codigo, label: item.nombre });
    });

    let retenedorImpuestosSelect = [];
    retenedorImpuestosList.map((item) => {
      retenedorImpuestosSelect.push({ value: item.codigo, label: item.nombre });
    });

    let tiposPersonaSelect = [];
    tiposPersona.map((item) => {
      tiposPersonaSelect.push({
        value: item.tipoPersonaAdquiriente,
        label: item.nombre,
      });
    });

    let regimenAdquirenteSelect = [];
    regimenAdquirente.map((item) => {
      regimenAdquirenteSelect.push({ value: item.codigo, label: item.nombre });
    });

    let tiposDsRespFiscalesSelect = [];
    tiposDsRespFiscales.map((item) => {
      tiposDsRespFiscalesSelect.push({
        value: item.codigo,
        label: item.nombre,
      });
    });
    tiposDsRespFiscalesSelect = this.ordenarArray(tiposDsRespFiscalesSelect);

    let valueTipoPersona = tiposPersonaSelect.filter(
      (option) => option.value === emisor.tipoPersona,
    );
    let valueTipoRegimen = regimenAdquirenteSelect.filter(
      (option) => option.value === emisor.tipoRegimen,
    );
    let valueRetenedorImpuestos = retenedorImpuestosSelect.filter(
      (option) => option.value === emisor.retenedorImpuesto,
    );

    return (
      <Grid container spacing={8} direction='row' justify='center' style={{ padding: 10 }}>
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-xs-6 col-md-6'>
              <label style={styles.fontFamily}>Tipo Persona</label>
              <div className='form-group'>
                <Select
                  onChange={this.handleChangeCdTipoPersona.bind(this)}
                  options={tiposPersonaSelect}
                  placeholder='Tipo persona'
                  styles={customStyles}
                  value={valueTipoPersona}
                  id='selectEmisores'
                />
              </div>
            </div>
            <div class='col-xs-6  col-md-6'>
              <label style={styles.fontFamily}>Tipo Régimen</label>
              <div className='form-group'>
                <Select
                  onChange={this.handleChangeRegimenAdquirente.bind(this)}
                  options={regimenAdquirenteSelect}
                  placeholder='Tipo Régimen'
                  styles={customStyles}
                  value={valueTipoRegimen}
                />
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-xs-6 col-md-6'>
              <label style={styles.fontFamily}>Token Seguridad</label>
              <div className='form-group'>
                <input
                  maxLength='50'
                  disabled
                  autoComplete='off'
                  type='text'
                  className='form-control form-control-md'
                  id='tokenSeguridad'
                  value={emisor.tokenSeguridad || ''}
                />
              </div>
            </div>
            <div class='col-xs-6  col-md-6'>
              <label style={styles.fontFamily}>Email remitente</label>
              <div className='form-group'>
                <input
                  maxLength='50'
                  autoComplete='off'
                  type='text'
                  className='form-control form-control-md'
                  id='emailRemitenteEnvios'
                  value={emisor.emailRemitenteEnvios || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-xs-6 col-md-6'>
              <label style={styles.fontFamily}>Retenedor de Impuestos</label>
              <div className='form-group'>
                <Select
                  onChange={this.handleChangeCdRetenedorImpuestos.bind(this)}
                  options={retenedorImpuestosSelect}
                  placeholder='Retenedor de Impuestos'
                  styles={customStyles}
                  value={valueRetenedorImpuestos}
                />
              </div>
            </div>
            <div class='col-xs-6  col-md-6'>
              <div class='row'>
                <div class='col-3 bg-light' style={styles.inputFile}>
                  <Input
                    onChange={this.handleLoadImg}
                    type='file'
                    style={{
                      width: '159px',
                      height: '31px',
                    }}
                  />
                </div>
                <div class='col-9'>
                  <img
                    src={emisor.logo || emisor.logoEmisorS3}
                    width='300'
                    height='70'
                    alt='Logo emisor'
                  />
                </div>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-xs-6  col-md-6'>
              <label style={styles.fontFamily}>Contraseña Rut</label>
              <div className='form-group'>
                <input
                  maxLength='50'
                  autoComplete='off'
                  disabled
                  type='text'
                  className='form-control form-control-md'
                  id='contrasenaRut'
                  value={emisor.contrasenaRut || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class='col-xs-6 col-md-3' style={styles.inputFile}>
              <ButtonDescargaArchivos
                text={'Descargar RUT'}
                fileName={emisor.urlRut}
                icon={<Icon icon='downloadFile' />}
              />
            </div>
            <div class='col-xs-1 col-md-1'>
              <label style={styles.fontFamily}>Código tipo plantilla</label>
              <div className='form-group'>
                <input
                  maxLength='2'
                  autoComplete='off'
                  type='text'
                  className='form-control form-control-md'
                  id='cdTipoPlantilla'
                  value={emisor.cdTipoPlantilla || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/* <div class="col-xs-2">
              <InputCheckBox
                id="checkGenerarPdf"
                label="Genera PDF Nomina"
                name="snPdfNomina"
                onChange={this.handleChange}
                checked={emisor.snPdfNomina}
              />
            </div> */}
          </div>
          <div class='row'>
            <div class='col-xs-6 col-md-3'>
              <label style={styles.fontFamily}>Codigo CIIU</label>
              <div className='form-group'>
                <input
                  maxLength='50'
                  autoComplete='off'
                  type='text'
                  className='form-control form-control-md'
                  id='codigoCiiu'
                  value={emisor.codigoCiiu || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class='col-xs-6  col-md-3'>
              <label style={styles.fontFamily}>Responsabilidad Fiscal</label>
              <div className='form-group'>
                <input
                  maxLength='50'
                  autoComplete='off'
                  type='text'
                  className='form-control form-control-md'
                  id='respFiscales'
                  value={emisor.respFiscales || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class='col-xs-6  col-md-3'>
              <label style={styles.fontFamily}>TestID</label>
              <div className='form-group'>
                <input
                  maxLength='50'
                  autoComplete='off'
                  type='text'
                  className='form-control form-control-md'
                  id='testid'
                  value={emisor.testid || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-xs-12 col-md-12'>
              <label style={styles.fontFamily}>Pie Página</label>
              <div className='form-group'>
                <textarea
                  className='form-control form-control-md'
                  id='piePagina'
                  value={emisor.piePagina || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-xs-6 col-md-12'>
              <div className='form-group'>
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={habilitado} value={emisor.habilitado} />}
                  label='Habilitado'
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    emisor: state.maestros.emisor,
    retenedorImpuestosList: state.factura.retenedorImpuestos,
    responsableIvaList: state.factura.responsableIva,
    tiposPersona: state.factura.tiposPersona,
    regimenAdquirente: state.factura.regimenAdquirente,
    tiposDsRespFiscales: state.factura.tiposResponsabilidadesFiscales,
    ciudadesArray: state.factura.ciudadesArray,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setEmisor: (item) => dispatch(SetEmisor(item)),
    setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InformacionEmisor);
