import React from "react";

export default function Loader({ type = "text-primary" }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        role="status"
        aria-hidden="true"
        className={`spinner-border ${type}`}
      />
    </div>
  );
}
