import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import ResolucionFacturacionDetalle from "components/ServicioCliente/ResolucionFacturacion/ResolucionFacturacionDetalle";
import PropTypes from "prop-types";

class ResolucionModal extends Component {
  render() {
    const { openModal, resoluciones, handleClose } = this.props;
    return (
      <Dialog open={openModal} onClose={handleClose} maxWidth="xl">
        <DialogTitle id="alert-dialog-title">
          <p>Resoluciones</p>
        </DialogTitle>
        <DialogContent>
          <ResolucionFacturacionDetalle resoluciones={resoluciones} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ResolucionModal.propTypes = {
  openModal: PropTypes.object.isRequired,
  resoluciones: PropTypes.object.isRequired,
};

export default ResolucionModal;
