import * as React from "react";
import { styles } from "./style";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { Table, TableRow, TableCell } from "@material-ui/core";
import { TableHead, TableBody } from "@material-ui/core";
import Button from 'components/atoms/Button';
import Add from "@material-ui/icons/AddCircle";
import * as TextUtils from "utils/TextFormattingUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import RowTotal from "./RowTotal";
import Input from "components/Inputs/Input";

const DetallesDocumento = ({
  classes,
  detalles = [],
  addDetalles,
  setDocumento,
  showDiaIva = true,
  showTotal = true,
  showNumFactura = false,
  documento = {},
  isSoporte,
}) => {
  React.useEffect(() => {
    const { valorReteFuente } = documento;
    if (valorReteFuente) {
      setDocumento({
        dsRetencionFuente: valorReteFuente,
        impuestosRetenciones: [{ tributo: "RETE_RENTA", porcentaje: valorReteFuente }],
      });
    }
  }, [documento.valorReteFuente]);

  React.useEffect(() => {
    const { cargosDescuentos } = documento;
    if (cargosDescuentos && cargosDescuentos[0]) {
      const { valor } = cargosDescuentos[0];
      if (valor <= 0) setDocumento({ cargosDescuentos: undefined });
    }
  }, [documento.cargosDescuentos]);

  const deleteDetalle = (index) => {
    const nuevosDetalles = detalles.filter((_, i) => i !== index);
    setDocumento({ detalles: nuevosDetalles });
  };

  const setRetencion = ({ target }) => {
    const { value } = target;
    if (value <= 100 && value >= 0) setDocumento({ valorReteFuente: value });
  };

  const getTotal = ({ precioUnitario, cantidad, valorTotalDescuento, porcentajeIva, porcentajeConsumo }) => {
    const valorBruto = precioUnitario * cantidad;
    const valorBase = valorBruto - valorTotalDescuento;
    const valorIva = (parseFloat(porcentajeIva) * valorBase) / 100;
    const valorConsumo = (parseFloat(porcentajeConsumo) * valorBase) / 100;
    return valorBase + valorIva + valorConsumo;
  };

  const validarDecimal = ({ target }) => {
    const { value } = target;
    if (!/^[0-9]+([.][0-9]+)?$/.test(value)) setRetencion({ target: { value: 0 } });
    else {
      if (parseFloat(value) > 100) setRetencion({ target: { value: 100 } });
      if (parseFloat(value) < 0) setRetencion({ target: { value: 0 } });
    }
  };

  const validarDecimalDescuento = ({ target }) => {
    const { value } = target;
    if (!/^[0-9]+([.][0-9]+)?$/.test(value)) setDescuento({ target: { value: "", ...target } });
    else setDescuento({ target });
  };

  const setDescuento = ({ target }) => {
    const { value } = target;
    setDocumento({ cargosDescuentos: [{ valor: value, tipo: false }] });
  };

  const getTotalNetoDocumento = () => {
    const { cargosDescuentos } = documento;
    const totalDescuento = cargosDescuentos ? parseFloat(cargosDescuentos[0].valor) : 0;
    const totalDocumento = detalles.reduce((total, { cantidad, precioUnitario, valorTotalDescuento }) => total + (cantidad * precioUnitario - valorTotalDescuento), 0);
    return totalDocumento - totalDescuento;
  };

  const getTotalIvaDocumento = () =>
    detalles.reduce((total, detalle) => {
      const { porcentajeIva, cantidad, precioUnitario } = detalle;
      return total + (porcentajeIva * (cantidad * precioUnitario)) / 100;
    }, 0);

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              {showNumFactura && <TableCell className={classes.headText}>Numero Factura</TableCell>}
              <TableCell className={classes.headText}>Código</TableCell>
              <TableCell className={classes.headText}>Descripción</TableCell>
              <TableCell className={classes.headText} align="right">
                Cantidad
              </TableCell>
              <TableCell className={classes.headText} align="right">
                Valor Unitario
              </TableCell>
              <TableCell className={classes.headText} align="right">
                %IVA
              </TableCell>
              <TableCell className={classes.headText} align="right">
                %INC
              </TableCell>
              <TableCell className={classes.headText} align="right">
                Descuento
              </TableCell>
              {showTotal && (
                <TableCell className={classes.headText} align="right">
                  Valor Total
                </TableCell>
              )}
              <TableCell className={classes.headText} align="right">
                {showDiaIva && "Dia sin IVA"}
              </TableCell>
              <TableCell className={classes.headText}>
                {addDetalles && (
                  <Button className={classes.buttonAdd} onClick={addDetalles}>
                    <Add />
                    Agregar
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detalles.map((detalle, key) => (
              <TableRow className={classes.bodyDetalles} key={key}>
                {showNumFactura && <TableCell>{detalle.numeroFactura}</TableCell>}
                <TableCell>{detalle.codigoArticulo}</TableCell>
                <TableCell>{detalle.descripcionArticulo}</TableCell>
                <TableCell align="right">{TextUtils.decimalFormat(detalle.cantidad)}</TableCell>
                <TableCell align="right">{TextUtils.moneyFormat(detalle.precioUnitario)}</TableCell>
                <TableCell align="right">{TextUtils.percentFormat(detalle.porcentajeIva)}</TableCell>
                <TableCell align="right">{TextUtils.percentFormat(detalle.porcentajeConsumo)}</TableCell>
                <TableCell align="right">{TextUtils.moneyFormat(detalle.valorTotalDescuento)}</TableCell>
                {showTotal && <TableCell align="right">{TextUtils.moneyFormat(detalle.valorTotalArticuloDetalle || getTotal(detalle))}</TableCell>}
                {showDiaIva && (
                  <TableCell>
                    <input type="checkbox" checked={detalle.esSinIva} disabled={true} />
                  </TableCell>
                )}
                <TableCell>
                  <Button onClick={() => deleteDetalle(key)} aria-label="Delete">
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow />
            {documento && documento.tipoDocumentoElectronico !== "NOTA_CREDITO_SOPORTE_ADQUISICION" && (
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bodyTotales} colSpan={5}>
                  Porcentaje Retefuente
                </TableCell>
                <TableCell className={classes.bodyTotales} colSpan={1} align="right">
                  <Input onChange={setRetencion} onBlur={validarDecimal} value={documento.valorReteFuente} />
                </TableCell>
              </TableRow>
            )}
            {isSoporte && (
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bodyTotales} colSpan={5}>
                  Valor descuento
                </TableCell>
                <TableCell className={classes.bodyTotales} colSpan={1} align="right">
                  <Input
                    onChange={setDescuento}
                    onBlur={validarDecimalDescuento}
                    value={documento.cargosDescuentos ? documento.cargosDescuentos[0].valor : ""}
                    name="valor"
                    data-validation="only-num-decimals"
                  />
                </TableCell>
              </TableRow>
            )}
            <RowTotal classes={classes} title="Total neto" valor={getTotalNetoDocumento()} />
            <RowTotal classes={classes} title="Valor Iva" valor={getTotalIvaDocumento()} />
            <RowTotal classes={classes} title="Valor Total" valor={getTotalNetoDocumento() + getTotalIvaDocumento()} />
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default withStyles(styles)(DetallesDocumento);
