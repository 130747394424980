import React from 'react';
import { Paper } from './styles';
import Typography from 'components/atoms/Typography';
import Input from 'components/molecules/Input';
import SelectEstandarProducto from 'components/organisms/SelectEstandarProducto';

function FormularioExportacion({ className, handleChangeInput, detalle, setDetalle, errores }) {
  return (
    <Paper className={className}>
      <div className='form-row col-12 d-flex justify-content-center'>
        <Typography>Información para exportación</Typography>
      </div>
      <div className='form-row col-12'>
        <Input
          require
          name='nombreMarca'
          label='Marca del producto'
          value={detalle.nombreMarca}
          error={errores.nombreMarca}
          className='form-group col-4'
          onChange={handleChangeInput}
        />
        <Input
          require
          name='nombreModelo'
          label='Modelo del producto'
          className='form-group col-4'
          value={detalle.nombreModelo}
          error={errores.nombreModelo}
          onChange={handleChangeInput}
        />
        <SelectEstandarProducto
          require
          name='estandarProducto'
          className='form-group col-4'
          value={detalle.estandarProducto}
          error={errores.estandarProducto}
          onChange={({ value }) => setDetalle({ estandarProducto: value })}
        />
      </div>
    </Paper>
  );
}

export default FormularioExportacion;
