import React from "react";
import TipoMoneda from "../../static/enums/tiposMonedas";
import Select from "./Select";

export default function selectTipoMoneda({ value, ...rest }) {
  return (
    <Select
      options={TipoMoneda.map((tm) => ({
        label: tm.nombre,
        value: tm.moneda,
      }))}
      value={TipoMoneda.map((tm) => ({
        label: tm.nombre,
        value: tm.moneda,
      })).filter((m) => m.value === value)}
      {...rest}
    />
  );
}
