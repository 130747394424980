import React, { useMemo } from 'react';
import Select from 'components/molecules/Select';
import TiposPrecioReferencia from 'static/enums/tiposPrecioReferencia';

function SelectPrecioReferencia({ label = 'Tipo precio referencia', ...rest }) {
  const options = useMemo(
    () => TiposPrecioReferencia.map(({ codigo, nombre }) => ({ label: nombre, value: codigo })),
    [],
  );

  return <Select label={label} options={options} {...rest} />;
}

export default SelectPrecioReferencia;
