import { serviceRoutes, get } from "./index";

export const consultarGastosEmailEntreFechas = async ({
  feInicio,
  feFin,
  dsRemitente,
}) =>
  await get(
    serviceRoutes.consultarGastosEmailEntreFechas,
    `?inicio=${feInicio}&fin=${feFin}&email=${dsRemitente}`
  );
