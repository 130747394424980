export const tiposIdentificacionSalud = [
  { value: "CEDULA_CIUDADANIA", label: "Cédula de ciudadanía" },
  { value: "CEDULA_EXTRANJERIA", label: "Cédula de extranjería" },
  { value: "CARNE_DIPLOMATICO", label: "Carné diplomático" },
  { value: "PASAPORTE", label: "Pasaporte" },
  { value: "SALVOCONDUNTO", label: "Salvoconducto" },
  {
    value: "PERMISO_ESPECIAL_DE_PERMANENCIA",
    label: "Permiso especial de permanencia",
  },
  {
    value: "REGISTRO_CIVIL_DE_NACIMIENTO",
    label: "Registro civil de nacimiento",
  },
  { value: "TARJETA_IDENTIDAD", label: "Tarjeta de identidad" },
  { value: "CERTIFICADO_DE_NACIDO_VIVO", label: "Certificado de nacido" },
  { value: "ADULTO_SIN_IDENTIFICAR", label: "Adulto sin identificar" },
  { value: "MENOR_SIN_IDENTIFICAR", label: "Menor sin identificar" },
  { value: "DOCUMENTO_EXTRANJERO", label: "Documento extranjero" },
  { value: "SIN_IDENTIFICACION", label: "Sin identificación" },
];
