import * as React from "react";
import ModalFormulario from "components/modal/ModalConfirmacion";
import Tabs from 'components/molecules/Tabs';
import Select from "components/Inputs/Select";
import { withStyles } from "@material-ui/core/styles";
import { tipoDevengados } from "static/enums/Devengados";
import { tipoDeducciones } from "static/enums/Deducciones";
import { getDevengadosList, getDeduccionesList } from "./JsonAjusteServices";
import TablaDevengados from "components/Tablas/TablaDevengados";
import TablaDeducciones from "components/Tablas/TablaDeducciones";

const styles = () => ({
  formNovedades: { padding: "10px" },
});

const ModalAjuste = (props) => {
  const {
    open = false,
    classes,
    setState,
    jsonDocumento,
    enviarNomina,
    openMensaje,
  } = props;
  const [devengado, setDevengado] = React.useState(tipoDevengados[0]);
  const [deduccion, setDeduccion] = React.useState(tipoDeducciones[0]);
  const [body, setBody] = React.useState({
    listDevengados: [],
    listDeducciones: [],
  });

  const setDataBody = (newData) => setBody((b) => ({ ...b, ...newData }));

  React.useEffect(() => {
    if (jsonDocumento)
      setDataBody({
        listDevengados: setId(getDevengadosList(jsonDocumento)),
        listDeducciones: setId(getDeduccionesList(jsonDocumento)),
      });
  }, [jsonDocumento]);

  const closeModal = () => setState((s) => ({ ...s, openModalAjuste: false }));

  const setId = (array) => array.map((data, key) => ({ ...data, id: key }));

  const deleteItemArray = (array, itemDelete) =>
    array.filter((item) => item.id !== itemDelete.id);

  const deleteDevengado = (d) =>
    setDataBody({
      listDevengados: setId(deleteItemArray(body.listDevengados, d)),
    });

  const deleteDeduccion = (d) =>
    setDataBody({
      listDeducciones: setId(deleteItemArray(body.listDeducciones, d)),
    });

  const addItemDevengado = (d, rest) => {
    setDataBody({ listDevengados: setId([...body.listDevengados, d]) });
    rest();
  };

  const addItemDeduccion = (d, rest) => {
    setDataBody({ listDeducciones: setId([...body.listDeducciones, d]) });
    rest();
  };

  const enviar = () => {
    closeModal();
    openMensaje("Enviando ajuste documento...");
    enviarNomina({
      nmId: jsonDocumento.nmId,
      tipoAjuste: "REEMPLAZAR",
      devengados: body.listDevengados,
      deducciones: body.listDeducciones,
    });
  };

  return (
    <ModalFormulario
      maxWidth={"lg"}
      title="Selecciona las novedades a ajustar"
      fullWidth={true}
      open={open}
      content={
        <Tabs
          tabNames={["Devengados", "Deducciones"]}
          tabComponents={[
            <div className={classes.formNovedades}>
              <Select
                label="Selecciona el tipo de devengado"
                options={tipoDevengados}
                value={devengado}
                onChange={setDevengado}
              />
              <div style={{ margin: "10px 0px" }}>
                {devengado.component({ empleado: true }, addItemDevengado, "4")}
              </div>
              <TablaDevengados
                detalles={body.listDevengados}
                eliminarItem={deleteDevengado}
              />
            </div>,
            <div className={classes.formNovedades}>
              <Select
                label="Selecciona el tipo de devengado"
                options={tipoDeducciones}
                value={deduccion}
                onChange={setDeduccion}
              />
              <div style={{ margin: "10px 0px" }}>
                {deduccion.component({ empleado: true }, addItemDeduccion, "4")}
              </div>
              <TablaDeducciones
                detalles={body.listDeducciones}
                eliminarItem={deleteDeduccion}
              />
            </div>,
          ]}
        />
      }
      onAccept={enviar}
      onCancel={() => closeModal()}
    />
  );
};

export default withStyles(styles)(ModalAjuste);
