import { serviceRoutes, get } from "./index";

export const consultarDocumentosEstadistica = async ({
  emisor,
  intervalo,
  periodo,
}) =>
  await get(
    serviceRoutes.consultarDocumentosEstadistica,
    `?emisor=${emisor}&intervalo=${intervalo}&periodo=${periodo}`
  );
