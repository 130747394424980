export const modalidadesContratacionSalud = [
  {
    value: "PAQUETE_CANASTA_CONJUNTO_INTEGRAL_EN_SALUD",
    label: "Paquete / Canasta / Conjunto Integral en Salud",
  },
  {
    value: "GRUPOS_RELACIONADOS_POR_DIAGNOSTICO",
    label: "Grupos Relacionados por Diagnóstico",
  },
  {
    value: "INTEGRAL_POR_GRUPO_DE_RIESGO",
    label: "Integral por grupo de riesgo",
  },
  {
    value: "PAGO_POR_CONTACTO_POR_ESPECIALIDAD",
    label: "Pago por contacto por especialidad",
  },
  {
    value: "PAGO_POR_ESCENARIO_DE_ATENCION",
    label: "Pago por escenario de atención",
  },
  { value: "PAGO_POR_TIPO_DE_SERVICIO", label: "Pago por tipo de servicio" },
  {
    value: "PAGO_GLOBAL_PROSPECTIVO_POR_EPISODIO",
    label: "Pago global prospectivo por episodio",
  },
  {
    value: "PAGO_GLOBAL_PROSPECTIVO_POR_GRUPO_DE_RIESGO",
    label: "Pago global prospectivo por grupo de riesgo",
  },
  {
    value: "PAGO_GLOBAL_PROSPECTIVO_POR_ESPECIALIDAD",
    label: "Pago global prospectivo por especialidad",
  },
  {
    value: "PAGO_GLOBAL_PROSPECTIVO_POR_NIVEL_DE_COMPLEJIDAD",
    label: "Pago global prospectivo por nivel de complejidad",
  },
  { value: "CAPITACION", label: "Capitación" },
  { value: "POR_SERVICIO", label: "Por servicio" },
];
