import { EmisionNomina } from "./SubMenus/Emision";
import { Nomina as NominaMenu } from "./SubMenus/Nomina";
import { ConsultasNomina } from "./SubMenus/Consultas";

export const Nomina = [
  {
    isNoView: "true",
    pathname: "/Home",
    principal: "true",
  },
  NominaMenu,
  EmisionNomina,
  ConsultasNomina,
];
