import React from 'react';
import { Paper } from './styles';
import Typography from 'components/atoms/Typography';
import Radio from 'components/molecules/Radio';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import TablaCargosDescuentos from './TablaCargosDescuentos';
import useCargoDescuento from 'hooks/useCargosDescuentos';

function validarCargoDescuento(cargosDescuentos = [], cargoDescuento) {
  const { valorBase, valor, razon } = cargoDescuento;
  const descuentoTotalAnterior = cargosDescuentos.reduce((suma, { valor }) => valor + suma, 0);
  const descuentoTotal = parseFloat(valor) + descuentoTotalAnterior;
  return descuentoTotal <= valorBase && razon;
}

function FormularioModeradoraCopago({
  className,
  valorBase,
  addCargoDescuento,
  cargosDescuentos,
  removeCargoDescuento,
}) {
  const { cargoDescuento, limpiar, handleChange } = useCargoDescuento(false, valorBase);

  const agregarDescuento = () => {
    const isValido = validarCargoDescuento(cargosDescuentos, cargoDescuento);
    if (isValido) {
      addCargoDescuento(cargoDescuento);
      limpiar();
    }
  };

  return (
    <Paper className={className}>
      <div className='form-row col-12'>
        <div className='form-group col-12'>
          <Typography>Agregar Copago o Cuota Moderadora</Typography>
        </div>
      </div>
      <div className='form-row col-12'>
        <div className='form-group col-12 d-flex justify-content-center'>
          <Radio
            name='razon'
            onChange={handleChange}
            value={cargoDescuento.razon}
            options={[
              { label: 'Copago', value: 'CUOTA_COPAGO' },
              { label: 'Cuota Moderadora', value: 'CUOTA_MODERADORA' },
            ]}
          />
        </div>
      </div>
      <div className='form-row col-12 d-flex align-items-end'>
        <div className='form-group col-2' />
        <div className='form-group col-4'>
          <Input
            name='valor'
            onChange={handleChange}
            label='Ingrese el valor'
            value={cargoDescuento.valor}
            validacion='only-num-decimals'
          />
        </div>
        <div className='form-group col-4'>
          <Button onClick={agregarDescuento}>Agregar valor</Button>
        </div>
        <div className='form-group col-2' />
      </div>
      <TablaCargosDescuentos
        tipoSalud
        className='form-row col-12'
        cargosDescuentos={cargosDescuentos}
        deleteItem={removeCargoDescuento}
      />
    </Paper>
  );
}

export default FormularioModeradoraCopago;
