import React, { useMemo } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import Table from 'components/molecules/Table';
import Button from 'components/atoms/Button';
import { moneyFormat, percentFormat } from 'utils/TextFormattingUtils';

function TablaCargosDescuentos({ className, cargosDescuentos = [], deleteItem, tipoSalud }) {
  const detalles = useMemo(() => {
    return cargosDescuentos
      .filter(({ razon }) => (tipoSalud ? razon : !razon))
      .map(({ porcentaje, valor, valorBase, id, ...rest }) => ({
        id,
        porcentaje: percentFormat(porcentaje),
        valor: moneyFormat(valor),
        valorBase: moneyFormat(valorBase),
        btnBorrar: (
          <Button variant='text' onClick={() => deleteItem(id)}>
            <DeleteFilled />
          </Button>
        ),
        ...rest,
      }));
  }, [cargosDescuentos]);

  return (
    <div className={className}>
      {detalles.length > 0 && (
        <Table
          withPagination={false}
          headers={[
            { label: 'Borrar' },
            { label: 'Codigo' },
            { label: 'Porcentaje' },
            { label: 'Valor' },
            { label: 'Valor base' },
          ]}
          keys={[
            'btnBorrar',
            tipoSalud ? 'razon' : 'codigoDescuento',
            'porcentaje',
            'valor',
            'valorBase',
          ]}
          detalles={detalles}
        />
      )}
    </div>
  );
}

export default TablaCargosDescuentos;
