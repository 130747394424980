import React from 'react';
import Button from 'components/atoms/Button';
import useModal from 'hooks/useModal';
import Modal from 'components/molecules/Modal';
import Visualizar from 'static/images/visualizar.png';
import Icon from 'components/menu/Icon';
import Previsualisacion from './index';

function ButtonPrevisaulizacion({ emisor, documento }) {
  const [props, openModal] = useModal();

  return (
    <>
      <Button onClick={() => openModal({})} disabled={!documento}>
        <Icon style={{ marginLeft: '5px' }} src={Visualizar} alt='Previsualisar' />
        Previsualizar
      </Button>
      <Modal
        fullWidth
        title={null}
        maxWidth='xl'
        content={<Previsualisacion emisor={emisor} documento={documento} />}
        {...props}
      />
    </>
  );
}

export default ButtonPrevisaulizacion;
