import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";
import Select from "../../../Inputs/Select";

const TIPO_INCAPACIDAD = [
  { label: "Común", value: "1", tipodb: "COMUN" },
  { label: "Profesional", value: "2", tipodb: "PROFESIONAL" },
  { label: "Laboral", value: "3", tipodb: "LABORAL" },
];

export default function incapacidad({
  baseBody,
  guardarDevengado,
  xlCol = "2",
}) {
  const [tipo, setTipo] = useState(TIPO_INCAPACIDAD[0]);
  const [nmCantidad, setNmCantidad] = useState();
  const [nmPago, setNmPago] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `INCAPACIODAD_${tipo.tipodb}`,
    nmPago,
    nmCantidad,
  };

  const guardarIncapacidad = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmCantidad();
    setNmPago();
  };

  return (
    <form onSubmit={guardarIncapacidad}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Select
            label="Tipo del pago *"
            options={TIPO_INCAPACIDAD}
            onChange={(e) => setTipo(e)}
            value={tipo}
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Cantidad *"
            value={nmCantidad || ""}
            onChange={(e) => setNmCantidad(parseInt(e.target.value))}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Número de días que el trabajador o aprendiz estuvo inactivo por incapacidad (sin importar su origen)."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor pagado por incapacidad *"
            value={nmPago || ""}
            onChange={(e) => setNmPago(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor de la prestación económica pagada al trabajador por consecuencia de la falta de capacidad laboral sin importar su origen."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmCantidad) errores.push("La cantidad es obligatoria");
  if (!body.nmPago)
    errores.push("El valor pagado por incapacidad es obligatorio");
  return errores;
}
