import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { SetSpinnerModal, SetConfirmacionModalState } from 'reducers/actions/facturaActions';
import LabelTitulos from 'components/layout/labelTitulos';
import { registrarAdquirente } from 'network/Api/Adquirente';
import FormularioAdquirente from './FormularioAdquirente';
import Button from 'components/atoms/Button';

const ERROR_DUPLICADO = 'El adquirente que se intenta crear ya se encuentra registrado.';
const ERROR_GUARDANDO =
  'Ocurrió un error guardando el adquirente, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte.';
const GUARDADO_EXITOSO = 'El cliente se guardo exitosamente!';

const ModalNuevoAdquirente = ({
  userAttributes,
  setSpinnerModal,
  onConsulta,
  setConfirmacionModalState,
  onClose,
  open,
}) => {
  const guardarAdquirente = async (adquirente) => {
    const { codigoEmisor: nmId } = userAttributes;
    const { status, data } = await registrarAdquirente(
      { ...adquirente, emisor: { nmId } },
      setSpinnerModal,
    );
    if (status === 200) {
      if (data) {
        openModalExitoso({ text: GUARDADO_EXITOSO, onClick: () => onConsulta('') });
      } else openModal(ERROR_DUPLICADO);
    } else openModal(ERROR_GUARDANDO);
  };

  const openModal = (text = '') =>
    setConfirmacionModalState({
      open: true,
      text,
      onClick: () => setConfirmacionModalState({ open: false }),
    });

  const openModalExitoso = ({ text = '', onClick }) =>
    setConfirmacionModalState({
      open: true,
      text,
      onClick,
    });

  return (
    <Dialog
      open={open}
      aria-labelledby='form-dialog-title'
      scroll='paper'
      fullWidth={true}
      maxWidth='xl'
    >
      <DialogTitle id='form-dialog-title'>
        <LabelTitulos texto='Crear nuevo cliente' />
      </DialogTitle>
      <DialogContent>
        <div className='form-group col-md-12 mt-0 mb-0'>
          <LabelTitulos texto='Información cliente' />
        </div>
        <FormularioAdquirente onRegistro={guardarAdquirente} openModal={openModal} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} name='Cerrar'>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoAdquirente);
