export function moneyFormat(num) {
  return num !== undefined && num !== null && num !== ''
    ? new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
      }).format(num)
    : ``;
}

export function decimalFormat(num) {
  return num !== undefined && num !== null && num !== ''
    ? new Intl.NumberFormat('es-CO', {
        style: 'decimal',
        minimumFractionDigits: 0,
      }).format(num)
    : ``;
}

export function percentFormat(num) {
  return num !== undefined && num !== null && num !== ''
    ? `${new Intl.NumberFormat('es-CO', {
        style: 'decimal',
        minimumFractionDigits: 0,
      }).format(num)} %`
    : ``;
}
