import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@material-ui/icons/";

export default function SubMenu(props) {
  const { items, title, icon } = props;
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);
  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open}>
        <List>
          {items.map((item) =>
            item.isNoView ? (
              <></>
            ) : (
              <Link
                style={{ textDecoration: "none" }}
                key={item.title}
                to={item.pathname}
              >
                <ListItem button key={0}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            )
          )}
        </List>
      </Collapse>
    </div>
  );
}
