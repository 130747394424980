import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function basico({ baseBody, guardarDevengado, xlCol = "2" }) {
  const [nmCantidad, setNmCantidad] = useState();
  const [nmPago, setNmPago] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `BASICO`,
    nmCantidad,
    nmPago,
  };

  const guardar = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmPago();
    setNmCantidad();
  };

  return (
    <form onSubmit={guardar}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Dias Trabajados *"
            id="diasTrabajados"
            value={nmCantidad || ""}
            onChange={(e) => setNmCantidad(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Número de días que el trabajador o aprendiz efectivamente estuvo ejecutando sus labores en la empresa."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Sueldo trabajado *"
            id="sueldoTrabajado"
            value={nmPago || ""}
            onChange={(e) => setNmPago(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Corresponde al valor que el empleador paga de forma periódica al trabajador como contraprestación por el trabajo realizado, este puede ser fijo o variable de acuerdo a la unidad de tiempo en que las partes hayan acordado el pago, teniendo como base el día o la hora trabajada."
          />
        </div>
        <div className="form-row col-md-12">
          <div className="form-group col-xl-2">
            <Button type="submit" disabled={!baseBody.empleado}>
              Agregar
            </Button>
          </div>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmCantidad) errores.push("El campo de cantidad es obligatorio");
  if (!body.nmPago) errores.push("El campo de pago es obligatorio");
  return errores;
}
