import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import LabelTitulos from 'components/layout/labelTitulos';

export default function ModalConfirmacion({
  open = false,
  content = <div />,
  onAccept,
  onCancel,
  title = 'PortalFactura',
  fullWidth,
  maxWidth = 'sm',
  extraButtons = [],
  fullScreen,
}) {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleAccept = useCallback(async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);
    try {
      await onAccept();
    } catch (error) {
    } finally {
      setIsProcessing(false);
    }
  }, [onAccept, isProcessing]);

  return (
    <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} fullScreen={fullScreen}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <LabelTitulos tipo='encabezado' texto={title} />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {onAccept && (
          <Button onClick={handleAccept} disabled={isProcessing}>
            {isProcessing ? 'Procesando...' : 'Aceptar'}
          </Button>
        )}
        {extraButtons.map((buttonOptions) => (
          <Button key={buttonOptions.name} onClick={buttonOptions.onClick}>
            {buttonOptions.name}
          </Button>
        ))}
        {onCancel && <Button onClick={onCancel}>Cerrar</Button>}
      </DialogActions>
    </Dialog>
  );
}
