/* eslint-disable */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { connect } from 'react-redux';
import 'components/Factura/styles.css';
import { SetConfirmacionModalState, SetFacturasEnviadas, SetOpenModalFiltrosVentas, SetSpinnerModal } from '../../reducers/actions/facturaActions';
import LabelTitulos from 'components/layout/labelTitulos';

const styles = {
    buttonAdd: {
        fontWeight: 'bold',
        backgroundColor: '#128BCC',
        color: 'white',
    }
}

class CamposFiltros extends React.Component {
    state = {
        fechaInicial: '',
        fechaFinal: '',
    };

    componentDidMount() {
        this.setState({ fechaInicial: this.obtenerPrimerDiaMes(), fechaFinal: this.obtenerDiaActual() })
    }

    obtenerPrimerDiaMes = () => {
        var date = new Date();
        var firstday = new Date(date.getFullYear(), date.getMonth(), '01').toISOString().split('T')[0];
        return firstday;
    }

    obtenerDiaActual = () => {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().split('T')[0];
        return today;
    }


    handleClose = () => {
        this.limpiarEstado();
    };

    handleChange = (event) => {
        let value = event.target.value;
        if ([event.target.id] == 'fechaInicial') {
            if (value < '2019-05-01') return;

            if (value > this.state.fechaFinal) return;
        }

        if ([event.target.id] == 'fechaFinal') {
            if (value < this.state.fechaInicial) return;
        }
        this.setState({ [event.target.id]: value }, () => { })
    }

    limpiarEstado = () => {
        this.setState({ fechaInicial: this.obtenerPrimerDiaMes(), fechaFinal: this.obtenerDiaActual()}, () => {
            this.props.setOpenModalFiltrosVentas(false);
        })
    }


    render() {
        const { fechaInicial, fechaFinal } = this.state;
        const { open, onClick } = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    scroll='paper'
                >
                    <form>
                        <DialogTitle id="form-dialog-title"><LabelTitulos texto='Filtrar búsqueda' /></DialogTitle>
                        <DialogContent>
                            <div className="form-row mt-3">
                                <div className="form-row col-md-12 d-flex justify-content-between">
                                    <div className="form-group col-xl-6">
                                        <small className="text-muted">Fecha incial</small>
                                        <input type="date" id="fechaInicial" min="2019-05-01" max={this.obtenerDiaActual()} value={fechaInicial} onChange={this.handleChange} className="form-control" required />
                                    </div>
                                    <div className="form-group col-xl-6">
                                        <small className="text-muted" >Fecha final</small>
                                        <input type="date" id="fechaFinal" min={fechaInicial} max={this.obtenerDiaActual()} value={fechaFinal} onChange={this.handleChange} className="form-control" required />
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button style={styles.buttonAdd} onClick={()=>onClick(fechaInicial, fechaFinal)} name="txtAgregar" color="primary">Consultar</Button>
                            <Button style={styles.buttonAdd} onClick={this.handleClose} name="Cerrar" color="primary">Cerrar</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        open: state.factura.openModalFiltrosVentas,
        userAttributes: state.factura.userAttributes,
        tiposDocumentos: state.factura.tiposDocumentos
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
    setOpenModalFiltrosVentas: (item) => dispatch(SetOpenModalFiltrosVentas(item)),
    setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
    setFacturasEnviadas: (item) => dispatch(SetFacturasEnviadas(item)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CamposFiltros);