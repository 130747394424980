import React from 'react';
import Routes from 'routes/Routes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import theme from 'utils/theme';
import FooterComponet from 'components/layout/footer';
import 'index.css';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <div className='app-container'>
          <Routes />
          {window.location.pathname !== '/' && <FooterComponet />}
        </div>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default App;
