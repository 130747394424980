import * as React from 'react';
import Select from 'components/molecules/Select';
import Input from 'components/molecules/Input';
import TextArea from 'components/Inputs/TextArea';
import { selectOptionsNatural as tipoIdentificacionNatural } from 'static/enums/tiposIdentificacion';
import { tiposDiagnostico } from 'static/enums/tiposDiagnostico';
import * as TextFormattingUtils from 'utils/TextFormattingUtils';
import { TIPOS_DATOS, validarInput } from 'utils/ValidarDatos';
import Modal from 'components/molecules/Modal';
import useModal from 'hooks/useModal';
const { useEffect, useState } = React;
const DEFAULT = {};
const formularioConsultas = () => {
  const [consultas, setDataConsultas] = useState(DEFAULT);
  const [modalProps, openModal, closeModal] = useModal();

  useEffect(() => {
    callOpenmodal();
    setConsultas({ isConsultas: consultas.msTipoUsuarios ? true : false });
  }, []);

  const setConsultas = (data) => setDataConsultas((a) => ({ ...a, ...data }));

  const handleChange = ({ target }) => {
    callOpenmodal();
    const { name, value } = target;
    setConsultas({ [name]: value });
  };

  function callOpenmodal() {
    openModal({
      textAlignContent:"justify",
      content:
        'Según el Ministerio de Salud y Protección Social, se modifican las Resoluciones 2275 de 2023 y 558 de 2024 en relación con la transición prevista para la implementación del RIPS como soporte de la FEV en salud, y la plataforma del mecanismo único de validación estará en funcionamiento a partir del 1 de febrero de 2025.',
      onCancel: null,
    });
  }

  const submitForm = (e) => {
    callOpenmodal();
  };
  return (
    <form onSubmit={submitForm}>
      <div className='form-row'>
        <div className='col-md-4'>
          <Input
            label='Código del prestador de servicios de consultas *'
            name='codPrestador'
            value={consultas.codPrestador}
            onChange={handleChange}
            required
            maxLength='200'
          />
        </div>
        <div className='col-md-3'>
          <Input
            label='Fecha y hora de la consulta'
            name='fechaInicioAtencion'
            onChange={handleChange}
            type='datetime-local'
            value={consultas.fechaInicioAtencion}
          />
        </div>
        <div className='col-md-2'>
          <Input
            label='Número de autorización'
            onChange={handleChange}
            value={consultas.numAutorizacion}
            name='numAutorizacion'
            maxLength='35'
            validacion='only-num-text'
            required
          />
        </div>
        <div className='col-md-3'>
          <Input
            label='Código CUPS de la consulta'
            onChange={handleChange}
            value={consultas.codConsulta}
            name='codConsulta'
            maxLength='35'
            validacion='only-num-text'
            required
          />
        </div>
        <div className='col-md-6'>
          <TextArea
            label='Finalidad de la consulta'
            style={{ height: '10.55rem' }}
            maxLength='300'
            rows='3'
            name='finalidadTecnologiaConsultas'
            value={consultas.finalidadTecnologiaConsultas}
            onChange={handleChange}
            textMuted={`${consultas.finalidadTecnologiaConsultas ? consultas.finalidadTecnologiaConsultas.length : 0}/300 Caracteres`}
            sinSalto={true}
          />
        </div>
        <div className='col-md-6'>
          <TextArea
            label='Causa o motivo de la consulta'
            style={{ height: '10.55rem' }}
            maxLength='300'
            rows='3'
            name='causaMotivoAtencion'
            value={consultas.causaMotivoAtencion}
            onChange={handleChange}
            textMuted={`${consultas.causaMotivoAtencion ? consultas.causaMotivoAtencion.length : 0}/300 Caracteres`}
            sinSalto={true}
          />
        </div>
        <div className='col-md-4'>
          <Input
            label='Código CIE-10 del diagnóstico'
            name='codDiagnosticoPrincipal'
            value={consultas.codDiagnosticoPrincipal}
            onChange={handleChange}
            required
            maxLength='200'
          />
        </div>
        <div className='col-md-4'>
          <Select
            label='Tipo de diagnóstico '
            placeholder='Seleccione un tipo'
            name='tipoDiagnosticoPrincipal'
            options={tiposDiagnostico}
            onChange={({ value }) =>
              setConsultas({
                tipoDiagnosticoPrincipal: value,
              })
            }
            value={consultas.tipoDiagnosticoPrincipal}
          />
        </div>
        <div className='col-md-4'>
          <Select
            label='Tipo identificación del profesional que realizó la consulta'
            placeholder='Seleccione un tipo'
            name='tipoDocumentoIdentificacion'
            onChange={({ value }) => setConsultas({ tipoDocumentoIdentificacion: value })}
            options={tipoIdentificacionNatural}
            value={consultas.tipoDocumentoIdentificacion}
          />
        </div>
        <div className='col-md-4'>
          <Input
            label='Nro. Documento del profesional'
            onChange={handleChange}
            value={consultas.numDocumentoIdentificacion}
            name='numDocumentoIdentificacion'
            maxLength='35'
            validacion='only-num-text'
            required
          />
        </div>
        <div className='form-group col-md-4'>
          <Input
            label='Valor de la consulta'
            value={consultas.vrServicioVisual || ''}
            onChange={(e) =>
              setConsultas({
                vrServicioVisual: e.target.value,
                vrServicio: e.target.value,
              })
            }
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.numeros)}
            onBlur={() =>
              setConsultas({
                vrServicioVisual: TextFormattingUtils.decimalFormat(consultas.vrServicio),
              })
            }
          />
        </div>
        <div className='col-md-4'>
          <Input
            label='Tipo de pago moderador'
            name='conceptoRecaudo'
            value={consultas.conceptoRecaudo}
            onChange={handleChange}
            required
            maxLength='200'
          />
        </div>
        <div className='form-group col-md-4'>
          <Input
            label='Valor del pago moderador'
            value={consultas.valorPagoModeradorVisual || ''}
            onChange={(e) =>
              setConsultas({
                valorPagoModeradorVisual: e.target.value,
                valorPagoModerador: e.target.value,
              })
            }
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.numeros)}
            onBlur={() =>
              setConsultas({
                valorPagoModeradorVisual: TextFormattingUtils.decimalFormat(
                  consultas.valorPagoModerador,
                ),
              })
            }
          />
        </div>
        <div className='col-md-4'>
          <Input
            label='Número de la FEV del pago moderador'
            onChange={handleChange}
            value={consultas.numFEVPagoModerador}
            name='numFEVPagoModerador'
            maxLength='35'
            validacion='only-num-text'
            required
          />
        </div>
        <div className='col-md-4'>
          <Input
            label='Número consecutivo que identifica la consulta'
            onChange={handleChange}
            value={consultas.consecutivo}
            name='consecutivo'
            maxLength='35'
            validacion='only-num-text'
            required
          />
        </div>
      </div>
      <Modal
        title='Funcionalidad en Progreso - En funcionamiento el 1 de febrero de 2025'
        onAccept={closeModal}
        {...modalProps}
      />
    </form>
  );
};

export default formularioConsultas;
