import * as React from "react";
import { Typography } from "@material-ui/core";
import "./ListaSedes.css"
import logo from "../../icons/logoItau.png"
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";



function ListaSedes(classes) {

    const [state, setData] = React.useState("");
    const setState = (data) => setData((s) => ({ ...s, ...data }));
    const handleChange = ({ target }) => setState({ [target.name]: target.value });
    const url = process.env.REACT_APP_PUBLIC_URL;


    return ( 
        <div>
          <div className="contenedor-logo">
            <img className ="img-logo" src={logo} alt="Logo Itau"/>
          </div>
          <div className="tittle-bienvenida">
            <Typography variant="h4">Bienvenido</Typography>
            <Typography variant="h8">Seleccione la sede dónde desea realizar la solicitud de su turno </Typography>
          </div>
          
          <div className = "selectDiv">
              <Select className="select" value={state.dsSede} name="dsSede" onChange={handleChange} >
                <MenuItem className={classes.menuItem} value={"Medellín"}>Medellín</MenuItem>
                <MenuItem className={classes.menuItem} value={"Bogotá"}>Bogotá</MenuItem>
              </Select>
          </div>
          
          <div className ="contenedor-sedes">
              <div className ="tittle-ciudades">
              <Typography variant="h4"> {state.dsSede} </Typography>
              </div>

              {state.dsSede === "Bogotá" && (
              <div>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=ANDINO"}>
                  <div className="sede" >
                    <div className="Nombre" >ANDINO CALLE 82</div>
                    <div className="Direccion">Cra. 11 No. 82-01 C.C. Andino L - 3</div>
                  </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=DORADO"}>
                  <div className="sede">
                    <div className="Nombre">AVENIDA EL DORADO</div>
                    <div className="Direccion">Av. El Dorado No. 102A-19</div>
                  </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=CALLE73"}>
                <div className="sede">
                  <div className="Nombre">CALLE 73</div>
                  <div className="Direccion">Cra. 7 No. 73-45 </div>
                </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=CEDRITOS"}>
                  <div className="sede">
                    <div className="Nombre">CEDRITOS</div>
                    <div className="Direccion">Cll. 140 No. 17-34</div>
                  </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=CENTROCHIA"}>
                  <div className="sede">
                    <div className="Nombre">CENTRO CHÍA</div>
                    <div className="Direccion">Av. Pradilla 900 Este Centro Chía L - 1184</div>
                  </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=CCBULEVA"}>
                  <div className="sede">
                    <div className="Nombre">CENTRO COMERCIAL BULEVA</div>
                    <div className="Direccion">Cra. 52 No. 125A - 59 C.C. Bulevar Niza L - 1-24/25</div>
                  </div>
                </a>
              </div>)}

              {state.dsSede === "Medellín" && (
              <div>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=ELPOBLADO"}>
                  <div className="sede" >
                    <div className="Nombre" >EL POBLADO</div>
                    <div className="Direccion">Calle 7 No.39-215 L - 107</div>
                  </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=ENVIGADO"}>
                  <div className="sede">
                    <div className="Nombre">ENVIGADO</div>
                    <div className="Direccion">Carrera 42 No. 36 Sur - 69 Envigado</div>
                  </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=LAPLAYA"}>
                <div className="sede">
                  <div className="Nombre">LA PLAYA</div>
                  <div className="Direccion">Calle 52 No. 45 - 90</div>
                </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=UNICENTROMEDELLÍN"}>
                  <div className="sede">
                    <div className="Nombre">UNICENTRO MEDELLÍN</div>
                    <div className="Direccion">Carrera  66B No. 34A - 76  L - 089</div>
                  </div>
                </a>
                <a href={url + "solicitarturno?emisor=AHJ92GLK&sede=PABCLÍNICALASVEGAS"}>
                  <div className="sede">
                    <div className="Nombre">PAB CLÍNICA LAS VEGAS</div>
                    <div className="Direccion">Calle 2 Sur 46-55 L -  113</div>
                  </div>
                </a>
              </div>)}
             

          
              
          
          </div>

        </div>
      );
}

export default ListaSedes;

