import React from "react";
import { withStyles } from "@material-ui/core/styles";

const style = () => ({
  icon: { height: "25px", width: "25px" },
});

function iconMenu({ alt = "Icon Menu", classes, ...rest }) {
  return <img className={classes.icon} alt={alt} {...rest} />;
}

export default withStyles(style)(iconMenu);
