import * as React from "react";
import Select from "components/Inputs/Select";
import TipoOperacionesSoporte from "static/enums/TipoOperacionSoporte";

const SelectTipoOperacion = ({ value, ...rest }) => {
  return (
    <Select
      value={TipoOperacionesSoporte.find((to) => to.value === value)}
      options={TipoOperacionesSoporte}
      {...rest}
    />
  );
};

export default SelectTipoOperacion;
