import React, { Component } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  reenviarNotificacionParqueadero,
  registrarSalidaParqueadero,
} from "network/Api/DocumentoParqueadero";
import { handleKeyPressTextoNumeros } from "utils/funcionesUtiles";

const style = {
  form: { padding: "20px", maxWidth: "600px", margin: "0 auto" },
  formRow: { margin: "10px" },
  buttons: { margin: "5px" },
};

class FormularioSalida extends Component {
  state = {
    dsPlaca: "",
    dsCodigo: "",
    dialogIsOpen: false,
    mensajeRespuesta: "",
  };

  formatMoney = (number) => {
    return new Intl.NumberFormat("es-CO", {
      style: "decimal",
    }).format(number);
  };

  handleReenviarNotificacion = () => {
    const { dsPlaca, dsCodigo } = this.state;
    const { nmIdEmisor, spinnerModal } = this.props;
    if (!(dsPlaca.length >= 2 && dsPlaca.length <= 10))
      this.setState({
        mensajeRespuesta: "La placa debe estar entre 2 y 10 caracteres",
        dialogIsOpen: true,
      });
    else {
      spinnerModal(true);
      reenviarNotificacionParqueadero({
        dsPlaca: dsPlaca,
        dsCodigo: dsCodigo,
        emisor: { nmId: nmIdEmisor },
      }).then((res) => {
        spinnerModal(false);
        if (res.status === 200) {
          this.setState({
            mensajeRespuesta: "Se reenvió la notificación correctamente",
            dialogIsOpen: true,
          });
        } else {
          this.setState({
            mensajeRespuesta:
              "Fallo el reenvió de la notificación, verificar que la placa sea correcta",
            dialogIsOpen: true,
          });
        }
      });
    }
  };

  handleRegistarSalida = () => {
    const { dsPlaca, dsCodigo } = this.state;
    const { nmIdEmisor, spinnerModal, usuarioRegistro } = this.props;
    let mensaje = this.validarFormulario();
    if (mensaje)
      this.setState({
        mensajeRespuesta: mensaje,
        dialogIsOpen: true,
      });
    else {
      spinnerModal(true);
      registrarSalidaParqueadero({
        dsPlaca: dsPlaca,
        dsCodigo: dsCodigo,
        emisor: { nmId: nmIdEmisor },
        dsUsuarioRegistro: usuarioRegistro,
      }).then((res) => {
        spinnerModal(false);
        if (res.status === 200 && res.data.dsMensaje === "SALIDA_EXITOSA") {
          let data = res.data;
          this.setState({
            mensajeRespuesta: `El vehículo registrado con la placa 
            <b>${data.dsPlaca}</b> debe pagar 
            <b>$ ${this.formatMoney(data.nmValor)}</b> pesos`,
            dialogIsOpen: true,
          });
          this.handleClearForm();
        } else {
          this.setState({
            mensajeRespuesta: this.errorCodeConvert(res.data.dsMensaje),
            dialogIsOpen: true,
          });
        }
      });
    }
  };

  errorCodeConvert = (errorCode) => {
    switch (errorCode) {
      case "ERROR_SALIDA":
        return `El vehiculo tiene registrado la salida`;
      case "ERROR_TARIFA_NO_ENCONTRADA":
        return `No se encontro una tarifa valida`;
      default:
        return `Error registrando salida del vehículo, verificar que la placa y el código sean validos`;
    }
  };

  validarFormulario = () => {
    const { dsPlaca, dsCodigo } = this.state;
    if (!(dsPlaca.length >= 2 && dsPlaca.length <= 10))
      return "La placa debe estar entre 2 y 10 caracteres";
    if (!(dsCodigo.length >= 5 && dsCodigo.length <= 8))
      return "Código de salida invalido, debe estar entre 5 y 8 caracteres";
    return false;
  };

  onlyText = (e) => {
    if (!handleKeyPressTextoNumeros(e)) e.preventDefault();
  };

  handleChange = (event) => {
    let name = event.target.id;
    switch (name) {
      case "dsPlaca":
        this.setState({
          [name]: event.target.value.replace(" ", "").toUpperCase(),
        });
        break;
      default:
        this.setState({ [name]: event.target.value.replace(" ", "") });
    }
  };

  handleClearForm = () => this.setState({ dsPlaca: "", dsCodigo: "" });

  render() {
    const { dsPlaca, dsCodigo, mensajeRespuesta, dialogIsOpen } = this.state;
    return (
      <div>
        <form style={style.form}>
          <div style={style.formRow}>
            <TextField
              value={dsPlaca}
              id="dsPlaca"
              label="Placa"
              autoComplete="off"
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              onKeyPress={this.onlyText}
            />
          </div>
          <div style={style.formRow}>
            <TextField
              value={dsCodigo}
              id="dsCodigo"
              label="Codigo"
              autoComplete="off"
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              onKeyPress={this.onlyText}
            />
          </div>
          <div style={style.formRow}>
            <Button
              style={style.buttons}
              variant="contained"
              color="primary"
              onClick={this.handleReenviarNotificacion}
            >
              Reenvió
            </Button>
            <Button
              style={style.buttons}
              variant="contained"
              color="primary"
              onClick={this.handleRegistarSalida}
            >
              Salida
            </Button>
          </div>
        </form>
        <Dialog open={dialogIsOpen}>
          <DialogTitle>PortalFactura</DialogTitle>
          <DialogContent
            dangerouslySetInnerHTML={{ __html: mensajeRespuesta }}
          />
          <DialogActions>
            <Button onClick={() => this.setState({ dialogIsOpen: false })}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default FormularioSalida;
