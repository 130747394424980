const tiposCondicionesEntrega = [
    { codigo: 'CFR', nombre: 'Costo y flete' },
    { codigo: 'CIF', nombre: 'Costo, flete y seguro' },
    { codigo: 'CIP', nombre: 'Transporte y Seguro Pagados hasta' },
    { codigo: 'CPT', nombre: 'Transporte Pagado Hasta' },
    { codigo: 'DAP', nombre: 'Entregado en un Lugar' },
    { codigo: 'DAT', nombre: 'Entregado en Terminal' },
    { codigo: 'DDP', nombre: 'Entregado con Pago de Derechos' },
    { codigo: 'EXW', nombre: 'En Fábrica' },
    { codigo: 'FAS', nombre: 'Franco al costado del buque' },
    { codigo: 'FCA', nombre: 'Franco transportista' },
    { codigo: 'FOB', nombre: 'Franco a bordo' }
]

export default tiposCondicionesEntrega;