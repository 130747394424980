import React from "react";
import FacturaIcon from "@material-ui/icons/FileCopy";
import VentasIcon from "@material-ui/icons/Assessment";
import BuildIcon from "@material-ui/icons/Build";
import BuscarFacturaIcon from "@material-ui/icons/Receipt";
import PhonelinkEraseRoundedIcon from "@material-ui/icons/PhonelinkEraseRounded";
import NuevoUsuarioIcon from "@material-ui/icons/AssignmentInd";
import GestureIcon from "@material-ui/icons/Gesture";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import FormatListNumberedRoundedIcon from "@material-ui/icons/FormatListNumberedRounded";
import AlternateEmailRoundedIcon from "@material-ui/icons/AlternateEmailRounded";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";

export const ServicioCliente = [
  {
    isNoView: "true",
    pathname: "/Home",
    principal: "true",
  },
  {
    title: "Gestionar Solicitudes",
    icon: <FacturaIcon />,
    pathname: "/gestionar-solicitudes",
  },
  {
    title: "Factura asistida",
    icon: <FacturaIcon />,
    pathname: "/factura_asistida",
    principal: "true",
  },
  {
    title: "Ventas",
    icon: <VentasIcon />,
    pathname: "/ventas",
  },
  {
    title: "Configuración emisor",
    icon: <BuildIcon />,
    pathname: "/configuracion_emisor",
  },
  {
    title: "Documentos enviados",
    icon: <BuscarFacturaIcon />,
    pathname: "/facturas-enviadas-servicio-cliente",
  },
  {
    title: "Documentos Erroneos",
    icon: <PhonelinkEraseRoundedIcon />,
    pathname: "/facturasErroneas",
  },
  {
    title: "Nuevo usuario",
    icon: <NuevoUsuarioIcon />,
    pathname: "/registrarse",
  },
  {
    title: "Gestionar Firmas",
    icon: <GestureIcon />,
    pathname: "/gestionarFirma",
  },
  {
    title: "Gestionar Ayudas",
    icon: <HelpOutlineRoundedIcon />,
    pathname: "/nuevaAyuda",
  },
  {
    title: "Gestionar resoluciones",
    icon: <FormatListNumberedRoundedIcon />,
    pathname: "/gestionarResoluciones",
  },
  {
    title: "Notificaciones",
    icon: <AlternateEmailRoundedIcon />,
    pathname: "/Notificaciones",
  },
  {
    title: "Estadisticas",
    icon: <EqualizerRoundedIcon />,
    pathname: "/EstadisticasServicio",
  },
  {
    title: "Anualidades",
    icon: <EqualizerRoundedIcon />,
    pathname: "/anualidad",
  },
  {
    title: "Gestionar Anualidad",
    icon: <EqualizerRoundedIcon />,
    pathname: "/gestionaranualidad",
  },
  {
    title: "Reporte Gastos Email",
    icon: <EqualizerRoundedIcon />,
    pathname: "/reportegastosemail",
  },
];
