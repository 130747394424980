export default [
  {
    label: "No Aplica",
    value: "NO_APLICA",
    codigoDian: "00",
  },
  {
    label: "Dependiente pensionado por vejez activo",
    value: "DEPENDIENTE_PENSIONADO",
    codigoDian: "01",
  },
];
