import React, { useState } from 'react';
import { Paper, AppBar, Tabs, Tab, Typography } from '@material-ui/core';

function TabsPanel({ tabNames, tabComponents, defaultTab = 0, variant, scrollButtons, ...rest }) {
  const [tabSelected, setTabSelected] = useState(defaultTab);

  const handleChangeTab = (_, tab) => setTabSelected(tab);

  return (
    <Paper {...rest}>
      <AppBar position='static'>
        <Tabs
          value={tabSelected}
          onChange={handleChangeTab}
          variant={variant}
          scrollButtons={scrollButtons}
        >
          {tabNames.map((tab, index) => (
            <Tab label={tab} id={`Tab${index}`} key={index} />
          ))}
        </Tabs>
      </AppBar>
      {tabComponents.map((Component, index) => (
        <Typography
          component='div'
          role='tabpanel'
          hidden={tabSelected !== index}
          id={`Tab${index}`}
          key={index}
        >
          <div style={{ padding: '10px' }}>{tabSelected === index && Component}</div>
        </Typography>
      ))}
    </Paper>
  );
}

export default TabsPanel;
