import React, { useEffect, useState } from "react";
import SelectEmisor from "components/Inputs/SelectConsultaEmisores";
import { consultarAnualidad } from "network/Api/Anualidad";
import Input from "components/Inputs/Input";
import Button from 'components/atoms/Button';
import { getDateFromArray } from "utils/DateUtils";
import { actualizarFechas } from "network/Api/Anualidad";

function GestionarAnualidad() {
  const [state, setState] = useState({});

  useEffect(consultarAnualidades, [state.emisorId]);

  function consultarAnualidades() {
    const { emisorId } = state;
    if (emisorId) {
      consultarAnualidad(emisorId).then((res) => {
        const anualidad = res.data;
        setState((s) => ({
          ...s,
          feInicio: getDateFromArray(anualidad.feInicio).format("YYYY-MM-DD"),
          feFin: getDateFromArray(anualidad.feFin).format("YYYY-MM-DD"),
          nmId: anualidad.nmId,
        }));
      });
    }
  }

  const updateAnualidad = () => {
    const { nmId, feInicio, feFin } = state;
    if (nmId) {
      actualizarFechas({
        nmId,
        feInicio: [...feInicio.split("-").map((date) => parseInt(date)), 0, 0],
        feFin: [...feFin.split("-").map((date) => parseInt(date)), 0, 0],
      }).then((_) => consultarAnualidades());
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState((s) => ({ ...s, [name]: value }));
  };

  return (
    <div>
      <SelectEmisor
        handleConsultarEmisor={(emisor) =>
          setState((s) => ({ ...s, emisorId: emisor.id }))
        }
      />
      <div>
        <div style={{ display: "flex" }}>
          <Input
            margin="5px"
            label="Fecha Inicio"
            name="feInicio"
            type="date"
            value={state.feInicio || ""}
            disabled={!state.feInicio}
            onChange={handleChange}
          />
          <Input
            margin="5px"
            label="Fecha Fin"
            name="feFin"
            type="date"
            value={state.feFin || ""}
            disabled={!state.feFin}
            onChange={handleChange}
          />
        </div>
        <Button style={{ margin: "5px 5px" }} onClick={updateAnualidad}>
          Actualizar
        </Button>
      </div>
    </div>
  );
}

export default GestionarAnualidad;
