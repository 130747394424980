import React from 'react';
import {
  Emision,
  emitirFactura,
  emitirDocumentoSoporte,
  emitirFacturaExoprtacion,
  emitirNotaCredito,
  emitirNotaCreditoDocumentoSoporte,
  emitirNotaDebito,
} from './SubMenus/Emision';
import { Nomina } from './SubMenus/Nomina';
import {
  Consultas,
  DocumentosEmitidosSoporte,
  DocumentosEmitidosFacturacion,
} from './SubMenus/Consultas';
import { productos, adquirentes } from './SubMenus/Maestros';
import { Configuracion, gestionResoluciones } from './SubMenus/Configuracion';
import { eventosMercantiles } from './SubMenus/Gastos';
import { Pqrs } from './SubMenus/PQRS';
import { TeamOutlined } from '@ant-design/icons';

export const Emisor = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
  {
    pathname: '/recargar_saldo',
    isNoView: 'true',
  },
  {
    title: 'Marketplace',
    icon: <TeamOutlined />,
    pathname: '/aliados',
  },
  gestionResoluciones,
  adquirentes,
  productos,
  emitirFactura,
  emitirFacturaExoprtacion,
  Nomina,
  eventosMercantiles,
  emitirDocumentoSoporte,
  emitirNotaCredito,
  emitirNotaDebito,
  emitirNotaCreditoDocumentoSoporte,
  DocumentosEmitidosFacturacion,
  DocumentosEmitidosSoporte,
  Configuracion,
  Emision,
  Consultas,
  Pqrs,
];
