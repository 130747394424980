import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { SetEmisor } from "reducers/actions/maestrosActions";
import Select from "react-select";

const styles = {
  fontFamily: {
    whiteSpace: "nowrap",
  },
};

class InformacionPersona extends Component {
  handleChange = (event) => {
    const { persona, setEmisor } = this.props;
    switch (event.target.id) {
      case "nombresEmisor":
        setEmisor({ ...persona, nombresEmisor: event.target.value });
        break;
      case "segundoNombre":
        setEmisor({ ...persona, segundoNombre: event.target.value });
        break;
      case "primerApellido":
        setEmisor({ ...persona, primerApellido: event.target.value });
        break;
      case "segundoApellido":
        setEmisor({ ...persona, segundoApellido: event.target.value });
        break;
      case "direccion":
        setEmisor({ ...persona, direccion: event.target.value });
        break;
      case "telefono":
        setEmisor({ ...persona, telefono: event.target.value });
        break;
      case "celular":
        setEmisor({ ...persona, celular: event.target.value });
        break;
      case "email":
        setEmisor({ ...persona, email: event.target.value });
        break;
      default:
        break;
    }
  };

  handleChangeCiudad = (value) =>
    this.props.setEmisor({
      ...this.props.persona,
      ciudadEmisor: { nmId: value.value },
    });

  handleChangeAccess() {
    const { persona, setEmisor } = this.props;
    const { cdEstadoTransaccion } = persona;
    setEmisor({ ...persona, cdEstadoTransaccion: cdEstadoTransaccion === "F" ? "B" : "F" });
  }

  render() {
    const { persona, ciudadesArray } = this.props;
    const { cdEstadoTransaccion } = persona;

    let ciudadesSelect = ciudadesArray.map((item) => ({ value: item.nmId, label: item.dsNombre }));
    let valueCiudad = persona.ciudadEmisor && ciudadesSelect.filter((option) => parseInt(persona.ciudadEmisor.nmId) === option.value);

    return (
      <Grid container spacing={8} direction="row" justify="center" style={{ padding: 10 }}>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-6  col-md-6">
              <label style={styles.fontFamily}>Tipo identificación</label>
              <div className="form-group">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  disabled
                  className="form-control form-control-md"
                  value={persona.tipoIdentificacion || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class="col-xs-6  col-md-6">
              <label style={styles.fontFamily}>Identificación</label>
              <div className="form-group">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  disabled
                  className="form-control form-control-md"
                  value={persona.identificacionEmisor || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6  col-md-6">
              <label style={styles.fontFamily}>Nombre o razón social</label>
              <div className="form-group">
                <input
                  maxLength="150"
                  autoComplete="off"
                  type="text"
                  id="nombresEmisor"
                  required
                  className="form-control form-control-md"
                  value={persona.nombresEmisor || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class="col-xs-6  col-md-6">
              <label style={styles.fontFamily}>Segundo nombre</label>
              <div className="form-group has-error">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  id="segundoNombre"
                  className="form-control form-control-md"
                  value={persona.segundoNombre || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6  col-md-6">
              <label style={styles.fontFamily}>Primer apellido</label>
              <div className="form-group">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  id="primerApellido"
                  className="form-control form-control-md"
                  value={persona.primerApellido || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class="col-xs-6  col-md-6">
              <label style={styles.fontFamily}>Segundo apellido</label>
              <div className="form-group">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  id="segundoApellido"
                  className="form-control form-control-md"
                  value={persona.segundoApellido || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6  col-md-6">
              <label style={styles.fontFamily}>Telefono</label>
              <div className="form-group">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  id="telefono"
                  className="form-control form-control-md"
                  value={persona.telefono || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class="col-xs-6  col-md-6">
              <label style={styles.fontFamily}>Celular</label>
              <div className="form-group">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  id="celular"
                  className="form-control form-control-md"
                  value={persona.celular || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-4  col-md-4">
              <label style={styles.fontFamily}>Ciudad</label>
              <Select onChange={this.handleChangeCiudad} options={ciudadesSelect} placeholder="Ciudad" value={valueCiudad} />
            </div>
            <div class="col-xs-4  col-md-4">
              <label style={styles.fontFamily}>Dirección</label>
              <div className="form-group">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  id="direccion"
                  className="form-control form-control-md"
                  value={persona.direccion || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class="col-xs-4  col-md-4">
              <label style={styles.fontFamily}>Email</label>
              <div className="form-group">
                <input
                  maxLength="50"
                  autoComplete="off"
                  type="text"
                  id="email"
                  className="form-control form-control-md"
                  value={persona.email || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div class="col-xs-4  col-md-4">
              <label style={styles.fontFamily}>Acceso</label>
              <div className="form-group">
                <FormControlLabel
                  control={<Checkbox checked={cdEstadoTransaccion === "F"} onChange={() => this.handleChangeAccess()} />}
                  label="Acceso a Portal"
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    persona: state.maestros.emisor,
    ciudadesArray: state.factura.ciudadesArray,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setEmisor: (item) => dispatch(SetEmisor(item)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformacionPersona);
