import { serviceRoutes, get, post } from "./index";

export const getDeduccionesByEmpleado = async (empleado) =>
  await get(serviceRoutes.consultarDeduccionPorEmpleado, `${empleado}`);

export const guardarDeduccion = async (body) =>
  await post(serviceRoutes.guardarDeduccion, body);

export const eliminarDeduccion = async (body) =>
  await post(serviceRoutes.eliminarDeduccion, body);
