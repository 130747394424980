import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextoContrato from './textoContrato';

const tituloModal = 'PortalFactura';

const styles = {
    fontBody:{
    fontWeight: 'bold',
    color: 'black',
    },
    fontButtons:{
        fontWeight:'bold'
    },
}

class ContratoModal extends React.Component {
  render() {
    const { openAccion, handleCloseModal, setAceptarChange } = this.props;

    return (
      <div>
        <Dialog
          open={openAccion}
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{tituloModal}</DialogTitle>
          <DialogContent>
            <DialogContentText style={styles.fontBody}>
            <TextoContrato/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { handleCloseModal(); setAceptarChange(true)}} style={styles.fontButtons} color={"primary"} autoFocus>
              Aceptar
            </Button>
            <Button onClick={handleCloseModal} style={styles.fontButtons} color={"primary"} autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ContratoModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ContratoModal);
