import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import React, { Component } from "react";
import Encabezado from "./TablaEncabezado";
import { getDateFromArray } from "utils/DateUtils";

class ResolucionFacturacionDetalle extends Component {
  state = {
    order: "desc",
    orderBy: "feProceso",
    page: 0,
    rowsPerPage: 5,
    selected: [],
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    let orderFuture = "desc";
    if (orderBy === property && order === "desc") {
      orderFuture = "asc";
    }
    this.setState({ order: orderFuture, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const { resoluciones } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;

    return (
      <Paper>
        <div>
          <Table>
            <Encabezado
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={resoluciones.length}
            />
            <TableBody>
              {stableSort(resoluciones, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((resolucion, index) => {
                  return (
                    <TableRow key={index} align="center">
                      <TableCell align="center">
                        {resolucion.resolucionDian}
                      </TableCell>
                      <TableCell align="center">{`${
                        resolucion.dsPrefijo
                      }`}</TableCell>
                      <TableCell align="center">
                        {resolucion.nmInicio}
                      </TableCell>
                      <TableCell align="center">{resolucion.nmFin}</TableCell>
                      <TableCell align="center">
                        {getDateFromArray(resolucion.feInicio).format(
                          "YYYY-MM-DD"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {getDateFromArray(resolucion.feVencimiento).format(
                          "YYYY-MM-DD"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {resolucion.resultado}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={resoluciones.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Página anterior",
              }}
              nextIconButtonProps={{
                "aria-label": "Página siguiente",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        </div>
      </Paper>
    );
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default ResolucionFacturacionDetalle;
