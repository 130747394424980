import { Turno } from "./SubMenus/Turno";

export const AsesorTurno = [
  {
    isNoView: "true",
    pathname: "/Home",
    principal: "true",
  },
  Turno,
];
