import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SetCargosDescuentos } from '../../../reducers/actions/facturaActions';

const styles = theme => ({
    root: {
      marginTop: theme.spacing.unit * 1,
      overflowX: 'scroll',
    },
    table: {
      minWidth: 600,
    },
    head: {
      backgroundColor: '#128BCC',
      color: theme.palette.common.white,
      fontWeight: 'bold'
    },
    headText: {
      color: theme.palette.common.white,
    },
    bodyTotales: {
      fontWeight: 'bold',
      backgroundColor: '#128BCC',
      color: theme.palette.common.white,
    },
    bodyDetalles: {
      fontWeight: 'bold',
      color: theme.palette.common.black,
    },
    icon: {
      margin: theme.spacing.unit * 2,
    },
    buttonAdd: {
      backgroundColor: '#92C63E',
      color: theme.palette.common.white,
    }
  });

class CargosDescuentosList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            idItemBorrar: ''
        }
    }

    handleClickBorrar = (event) => {
        const { cargosDescuentos } = this.props;
        this.setState({ items: cargosDescuentos, idItemBorrar: event }, () => {
            let idItemBorrar = this.state.idItemBorrar;
            let newItems = this.state.items.filter(function (item) { return item.id !== idItemBorrar });
            this.props.setCargosDescuentos(newItems);
        })
    }

    render() {
        const { cargosDescuentos,classes } = this.props;
        return (
            <Fragment>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell className={classes.headText}>Borrar</TableCell>
                                <TableCell className={classes.headText}>Codigo</TableCell>
                                <TableCell className={classes.headText}>Porcentaje</TableCell>
                                <TableCell className={classes.headText}>Valor</TableCell>
                                <TableCell className={classes.headText} >Valor base</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cargosDescuentos &&
                                cargosDescuentos.filter(item => item.razon === undefined).map(row => (
                                    <TableRow className={classes.bodyDetalles} key={row.id}>
                                        <TableCell><Button onClick={() => { this.handleClickBorrar(row.id) }} aria-label="Delete"><DeleteIcon /></Button></TableCell>
                                        <TableCell>{row.codigoDescuento}</TableCell>
                                        <TableCell align="right">{row.porcentaje} %</TableCell>
                                        <TableCell align="right">{row.valor}</TableCell>
                                        <TableCell align="right">{row.valorBase}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Fragment>
        )
    }
}

CargosDescuentosList.propTypes = {
    classes: PropTypes.object.isRequired,
  };

const mapDispatchToProps = (dispatch) => ({
  setCargosDescuentos: (item) => dispatch(SetCargosDescuentos(item)),
})

function mapStateToProps(state, props) {
    return {
        cargosDescuentos: state.factura.cargosDescuentos,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CargosDescuentosList));
