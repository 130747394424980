import React, { useState, useEffect } from "react";
import NuevoEmpleado from "components/formularios/nomina/empleados/NuevoEmpleado";
import EditEmpleado from "components/formularios/nomina/empleados/EditEmpleado";
import Contrato from "components/formularios/nomina/empleados/Contrato";
import ModalMensaje from "components/modal/ModalMensaje";

export default function empleado({ emisor, empleado, resetEmpleado }) {
  const [isEdit, setIsEdit] = useState(empleado instanceof Object);
  const [modal, setModal] = useState({});

  useEffect(() => setIsEdit(empleado instanceof Object), [empleado]);

  const openModal = (text) => setModal({ text, open: true });

  return (
    <div>
      {isEdit ? (
        <EditEmpleado
          emisor={emisor}
          empleado={empleado}
          openModal={openModal}
          resetEmpleado={resetEmpleado}
        />
      ) : (
        <NuevoEmpleado
          emisor={emisor}
          openModal={openModal}
          resetEmpleado={resetEmpleado}
        />
      )}
      <hr style={{ border: "7px solid #128bcc" }} />
      <Contrato empleado={empleado} emisor={emisor} openModal={openModal} />
      <ModalMensaje
        content={modal.text || ""}
        handleClose={() => setModal({ ...modal, open: false })}
        open={modal.open || false}
      />
    </div>
  );
}
