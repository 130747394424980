const env = {
  dev: {
    env: "dev",
    urlClickingpay: "https://app-pruebas.clickingpay.com.co/",
    clickingpayToken: "eyJhbGciOiJIUzI1NiJ9.eyJjb21lcmNpbyI6IjkwMDA2MzQwMC04In0.ZYl-8P5xqJTbMKnxgOTz1xi_W1jj2vfSGtzDVFz6oS8",
    awsRegion: "us-east-2",
    userPoolId: "us-east-2_0RQLxGls8",
    cognitoClient: "15lp38mqkio5o5h9ome56c4oor",
    cognitoIdentityPoolId: "us-east-2:4c7aa168-5f5c-4fe3-a13e-cec012376018",
    dominaUrl: "https://demo.dominadigital.com.co/DCloudServices/rest/",
    clckingpayTokenRecarga: "eyJhbGciOiJIUzI1NiJ9.eyJjb21lcmNpbyI6IjkwMDA2MzQwMCJ9.WiL4LBZD53ySvVL-gLsfPeDa_C_qC2b0Hv7ULJbnuwg",
    publicUrl: "http://localhost:8080/",
    elasticUrl: "http://localhost:8080/",
    urlS3: "https://s3.us-east-2.amazonaws.com/pruebas.portalfactura.com",
    bucketS3: "pruebas.portalfactura.com",
  },
  qa: {
    env: "qa",
    urlClickingpay: "https://app-pruebas.clickingpay.com.co/",
    clickingpayToken: "eyJhbGciOiJIUzI1NiJ9.eyJjb21lcmNpbyI6IjkwMDA2MzQwMC04In0.ZYl-8P5xqJTbMKnxgOTz1xi_W1jj2vfSGtzDVFz6oS8",
    awsRegion: "us-east-2",
    userPoolId: "us-east-2_0RQLxGls8",
    cognitoClient: "15lp38mqkio5o5h9ome56c4oor",
    cognitoIdentityPoolId: "us-east-2:4c7aa168-5f5c-4fe3-a13e-cec012376018",
    dominaUrl: "https://demo.dominadigital.com.co/DCloudServices/rest/",
    clckingpayTokenRecarga: "eyJhbGciOiJIUzI1NiJ9.eyJjb21lcmNpbyI6IjkwMDA2MzQwMCJ9.WiL4LBZD53ySvVL-gLsfPeDa_C_qC2b0Hv7ULJbnuwg",
    publicUrl: "https://pruebas.portalfactura.com/",
    elasticUrl: "http://portalfactura-pru.eba-vtm68y3c.us-east-2.elasticbeanstalk.com/",
    urlS3: "https://s3.us-east-2.amazonaws.com/pruebas.portalfactura.com",
    bucketS3: "pruebas.portalfactura.com",
  },
  pro: {
    env: "pro",
    urlClickingpay: "https://app.clickingpay.com/",
    clickingpayToken: "eyJhbGciOiJIUzI1NiJ9.eyJjb21lcmNpbyI6IjkwMDA2MzQwMC04In0.ZYl-8P5xqJTbMKnxgOTz1xi_W1jj2vfSGtzDVFz6oS8",
    awsRegion: "us-east-2",
    userPoolId: "us-east-2_XKalGK3eq",
    cognitoClient: "ngq4mvkebr1tiuq5br24sv8il",
    cognitoIdentityPoolId: "us-east-2:4c7aa168-5f5c-4fe3-a13e-cec012376018",
    dominaUrl: "https://www.dominadigital.com.co/DCloudServices/rest/",
    clckingpayTokenRecarga: "eyJhbGciOiJIUzI1NiJ9.eyJjb21lcmNpbyI6IjkwMDA2MzQwMCJ9.WiL4LBZD53ySvVL-gLsfPeDa_C_qC2b0Hv7ULJbnuwg",
    publicUrl: "https://portalfactura.com/",
    elasticUrl: "https://api.portalfactura.com/",
    urlS3: "https://s3.us-east-2.amazonaws.com/portalfactura.com",
    bucketS3: "portalfactura.com",
  },
};

export default function getEnvironment() {
  return env[process.env.REACT_APP_ENVIRONMENT || "dev"];
}
