import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function auxilio({ baseBody, guardarDevengado, xlCol = "2" }) {
  const [nmAuxilioS, setNmAuxilioS] = useState();
  const [nmAuxilioNS, setNmAuxilioNS] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `AUXILIO`,
    nmAuxilioS,
    nmAuxilioNS,
  };

  const guardarAuxilio = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmAuxilioS();
    setNmAuxilioNS();
  };

  return (
    <form onSubmit={guardarAuxilio}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago por Auxilios Salariales"
            value={nmAuxilioS || ""}
            onChange={(e) => setNmAuxilioS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            onBlur={({ target }) => target.value === "0" && setNmAuxilioS()}
            tooltip="Son beneficios, ayudas o apoyos económicos, pagados al trabajador de forma habitual o pactados entre las partes como factor salarial."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago por Auxilios No Salariales"
            value={nmAuxilioNS || ""}
            onChange={(e) => setNmAuxilioNS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            onBlur={({ target }) => target.value === "0" && setNmAuxilioNS()}
            tooltip="Son beneficios, ayudas o apoyos económicos, pagados al trabajador de forma ocasional y por mera liberalidad o los pactados entre las partes de forma expresa como pago no salarial."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (
    (body.nmAuxilioS <= 0 || !body.nmAuxilioS) &&
    (body.nmAuxilioNS <= 0 || !body.nmAuxilioNS)
  )
    errores.push(
      "El campo de auxilios salariales o no salariales no se está mandando o se está enviado en cero"
    );
  return errores;
}
