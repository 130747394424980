import { serviceRoutes, post, get } from "./index";

export const crearActualizarVendedor = async (body) =>
  await post(serviceRoutes.crearActualizarVendedor, body);

export const consultarVendedores = async ({ idEmisor, filtro = "" }) =>
  await get(
    serviceRoutes.consultarVendedores,
    `?emisor=${idEmisor}&identificacion=${filtro}`
  );

export const consultarVendedorId = async ({ id }) =>
  await get(serviceRoutes.consultarVendedorId, `?id=${id}`);
