import React, { useMemo } from 'react';
import Select from 'components/molecules/Select';
import Monedas from 'static/enums/tiposMonedas';

function SelectMoneda({ className, ...rest }) {
  const options = useMemo(
    () => Monedas.map(({ moneda, nombre, simbolo }) => ({ label: nombre, value: moneda, simbolo })),
    [],
  );

  return (
    <div className={className}>
      <Select options={options} {...rest} />
    </div>
  );
}

export default SelectMoneda;
