import React from 'react';
import ConsultaPosPDF from 'components/templates/ConsultaPosPDF';

function Tirilla() {
  return (
    <div style={{ margin: '40px auto', width: '500px' }}>
      <ConsultaPosPDF />
    </div>
  );
}

export default Tirilla;
