import React, { useMemo } from 'react';
import Input from 'components/molecules/Input';
import SelectOtroImpuesto from 'components/organisms/SelectOtroImpuesto';

function FormularioOtroImpuesto({
  className,
  handleChangeSelect,
  handleChangeInput,
  otroImpuesto: impuesto = {},
}) {
  const { otroImpuesto, otroImpuestoValor, otroImpuestoVolumen, otroImpuestoPorcentaje } =
    useMemo(() => {
      return impuesto;
    }, [impuesto]);

  const isIcui = useMemo(() => otroImpuesto === 'ICUI', [otroImpuesto]);

  return (
    <div className={className}>
      <SelectOtroImpuesto
        name='otroImpuesto'
        className='form-group col-lg-6'
        value={otroImpuesto}
        onChange={handleChangeSelect}
      />
      {!isIcui ? (
        <>
          <Input
            label='Valor unitario'
            name='otroImpuestoValor'
            className='form-group col-lg-3 col-sm-6'
            onChange={handleChangeInput}
            validacion='only-num-decimals'
            disabled={!otroImpuesto}
            value={otroImpuestoValor}
            require={Boolean(otroImpuesto)}
          />
          <Input
            label='Volumen'
            name='otroImpuestoVolumen'
            className='form-group col-lg-3 col-sm-6'
            onChange={handleChangeInput}
            validacion='only-num-decimals'
            disabled={!otroImpuesto}
            value={otroImpuestoVolumen}
            require={Boolean(otroImpuesto)}
          />
        </>
      ) : (
        <Input
          label='Porcentaje'
          name='otroImpuestoPorcentaje'
          className='form-group col-lg-6'
          onChange={handleChangeInput}
          validacion='only-num-decimals'
          disabled={!otroImpuesto}
          value={otroImpuestoPorcentaje}
          require={Boolean(otroImpuesto)}
        />
      )}
    </div>
  );
}

export default FormularioOtroImpuesto;
