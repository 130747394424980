const retenedorImpuestos = [
    {
        codigo:"S",
        nombre:"SI"
    },
    {
        codigo:"N",
        nombre:"NO"
    }]

export default retenedorImpuestos;