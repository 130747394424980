const tiposEstandarProducto = [
  {
    codigo: "UNSPSC",
    nombre: "Código Estándar de Productos y Servicios de Naciones Unidas",
  },
  { codigo: "GTIN", nombre: "Números Globales de Identificación de Productos" },
  {
    codigo: "PARTIDA_ARANCELARIA",
    nombre: "Números Globales de Identificación de Productos",
  },
  {
    codigo: "ESTANDAR_CONTRIBUYENTE",
    nombre: "Estándar de adopción del contribuyente",
  },
];

export default tiposEstandarProducto;
