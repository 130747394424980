import React, { useMemo } from 'react';
import Select from 'components/molecules/Select';
import EstandarProductos from 'static/enums/tiposEstandarProducto';

function SelectEstandarProducto({ label = 'Estandar del producto', ...rest }) {
  const options = useMemo(
    () => EstandarProductos.map(({ codigo, nombre }) => ({ label: nombre, value: codigo })),
    [],
  );

  return <Select label={label} options={options} {...rest} />;
}

export default SelectEstandarProducto;
