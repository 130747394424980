import * as React from "react";
import FacturaIcon from "@material-ui/icons/FileCopy";
// import AyudasIcon from "@material-ui/icons/HelpOutlineRounded";

export const Pqrs = {
  subMenu: true,
  title: "PQR",
  icon: <FacturaIcon />,
  items: [
    {
      title: "Solicitud",
      icon: <FacturaIcon />,
      pathname: "/solicitudes",
    },
    {
      title: "Mis solicitudes",
      icon: <FacturaIcon />,
      pathname: "/mis-solicitudes",
    },
    // {
    //   title: "Ayudas",
    //   icon: <AyudasIcon />,
    //   pathname: "/ayudas",
    //   isNotView: "true",
    // },
  ],
};
