import React, { useEffect, useMemo, useState } from 'react';
import { consultarProductosActivosPorEmisorYDescripcion as consultarPorductos } from 'network/Api/Producto';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import Button from 'components/atoms/Button';
import './styles.css';
import Detalles from './Detalles';

const Botonera = ({ codigoEmisor, closeModal, oldDetalles = [], classes }) => {
  const [productos, setProductos] = useState({});
  const [categoria, setCategoria] = useState('SIN_CATEGORIA');
  const [detalles, setDetalles] = useState(oldDetalles);

  useEffect(() => {
    completarProductos();
  }, []);

  const completarProductos = async () => {
    const { data } = await consultarPorductos({ idEmisor: codigoEmisor });
    let productosCategorias = {};
    if (Array.isArray(data)) {
      data.forEach((producto) => {
        const { cdCategoria } = producto;
        let allCategoria = productosCategorias[cdCategoria || 'SIN_CATEGORIA'] || [];
        allCategoria.push(producto);
        productosCategorias = {
          ...productosCategorias,
          [cdCategoria || 'SIN_CATEGORIA']: allCategoria,
        };
      });
      setProductos(productosCategorias);
    }
  };

  const changeTab = (e, _) => setCategoria(e.target.innerText);

  const addDetalleProducto = (product) => {
    const {
      nmValorUnitario: precioUnitario,
      nmPorcentajeIva: porcentajeIva,
      nmPorcentajeInc: porcentajeConsumo,
      valorTotalDescuento = 0,
      nmId,
      dsCodigo: codigoArticulo,
      dsDescripcion: descripcionArticulo,
    } = product;
    setDetalles((allDetalles) => {
      const nuevosDetalles = allDetalles.filter(
        ({ codigoArticulo: codAnter }) => codAnter !== codigoArticulo,
      );
      const anterior = allDetalles.find(
        ({ codigoArticulo: codAnter }) => codAnter === codigoArticulo,
      );
      const cantidad = anterior ? anterior.cantidad + 1 : 1;
      const valorBruto = precioUnitario * cantidad;
      const valorBase = valorBruto - valorTotalDescuento;
      const valorTotalIva = (porcentajeIva * valorBase) / 100;
      const valorTotalInc = (porcentajeConsumo * valorBase) / 100;
      const valorTotalImpuestosRetenciones = valorTotalIva + valorTotalInc;
      return [
        ...nuevosDetalles,
        {
          nmId,
          codigoArticulo,
          precioUnitario,
          cantidad,
          porcentajeIva,
          porcentajeConsumo,
          valorTotalDescuento,
          descripcionArticulo,
          valorTotalImpuestosRetenciones,
          valorBase,
          valorBruto,
        },
      ].map((detalle, index) => ({ id: index, ...detalle }));
    });
  };

  const deleteDetalle = (id) =>
    setDetalles((oldDetalles) => {
      const newDetalles = oldDetalles.filter((od) => od.id !== id);
      return newDetalles.length >= 1 ? newDetalles : oldDetalles;
    });

  const categorias = useMemo(() => Object.keys(productos).sort(), [productos]);

  return (
    <>
      <AppBar position='static'>
        <Tabs
          value={categorias.findIndex((cat) => cat === categoria)}
          onChange={changeTab}
          variant='scrollable'
          scrollButtons='auto'
        >
          {categorias.map((categoria, key) => (
            <Tab
              label={categoria}
              id={`Tab${key}`}
              key={categoria}
              style={{ fontWeight: 'bold' }}
            />
          ))}
        </Tabs>
      </AppBar>
      <div className='style-movil'>
        <div className='col-lg-7 justify-content-center align-items-center tarjeta-detalle columns-tarjetas'>
          <div className='row'>
            {(productos[categoria] || []).map((producto, index) => (
              <div className='col-lg-3'>
                <Button
                  className='botonera justify-content-center align-items-center'
                  onClick={() => addDetalleProducto(producto)}
                  key={index}
                >
                  <div className=''>{`${producto.dsDescripcion}`}</div>
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className='col-lg-5 justify-content-center align-items-center'>
          <div>
            <Detalles documento={{ detalles }} eliminarDetalle={deleteDetalle} classes={classes} />
          </div>
          <div className='justify-content-center align-items-center col-12'>
            <Button onClick={() => closeModal(detalles)}>Cerrar</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Botonera;
