import * as React from 'react';
import SelectResolucion from './SelectResoluciones';
import SelectPrefijos from './SelectPrefijos';
import SelectTipoDocumento from 'components/Inputs/SelectTipoDocumento';
import Input from 'components/Inputs/Input';
import InputNumFactura from './InputNumFactura';
import { getResolucionesVigentes } from 'network/Api/Resolucion';
import { consultarResolucionAcumulada } from 'network/Api/ResolucionSoporte';
// import UltimosDocumentos from "./UltimosDocumentos";
// import DocumentosAdicionales from "./DocumentosAdicionales";
import DocumentosReferencia from './DocumentosReferencia';

const DatosDocumento = ({
  documento,
  setDocumento,
  emisor,
  disableTipoDocumento = true,
  isCotizacion = false,
  esSoloResolucion = false,
  isExcel = false,
  showTipoDoc = true,
  showPrefijo = true,
  showConsecutivo = true,
  isDocumentoSoporte = false,
  isDocumentoExpress = false,
}) => {
  const [state, setData] = React.useState({});

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  React.useEffect(() => consultarResoluciones(), [emisor]);

  React.useEffect(() => {
    const { tipoDocumentoElectronico } = documento;
    if (tipoDocumentoElectronico !== 'VENTA' || tipoDocumentoElectronico !== 'EXPORTACION') {
      setDocumento({ dsPrefijo: '', prefijoAnterior: documento.dsPrefijo });
    } else {
      setDocumento({
        dsPrefijo: documento.prefijoAnterior,
        prefijoAnterior: documento.dsPrefijo,
      });
    }
  }, [documento.tipoDocumentoElectronico]);

  const consultarResoluciones = () => {
    if (emisor && !isCotizacion)
      (isDocumentoSoporte
        ? consultarResolucionAcumulada({ emisor })
        : getResolucionesVigentes(emisor)
      ).then(({ status, data }) => {
        if (status === 200 && data.length > 0) {
          setState({ resoluciones: data });
          const { dsResolucionDian, dsPrefijo } = data
            .map((resolucion) =>
              (resolucion.prefijos || resolucion.prefijosSoporte).find(
                (prefijo) => prefijo.snPrincipal === 'S',
              ),
            )
            .find((item) => item);
          setDocumento({
            dsResolucionDian,
            dsPrefijo: dsPrefijo || 'SIN_PREFIJO',
          });
        }
      });
  };

  const handleChange = ({ target }) => setDocumento({ [target.name]: target.value });

  const handleChangeSelect = ({ name, value }) => setDocumento({ [name]: value });

  const handleChangeTipoDoc = ({ name, value }) => {
    const { tipoDocumentoElectronico } = documento;
    setDocumento({
      [name]: value,
      snConsecutivoAutomatico: tipoDocumentoElectronico.startsWith('NOTA_') ? 'N' : 'S',
    });
  };

  return (
    <div className='form-row'>
      {!isCotizacion && (
        <div className={`form-group col-md-12 col-xl-${esSoloResolucion ? 3 : 2}`}>
          <SelectResolucion
            label='Resolución *'
            placeholder='Seleccione una resolución'
            name='dsResolucionDian'
            onChange={handleChangeSelect}
            resoluciones={state.resoluciones}
            value={documento.dsResolucionDian}
            setDocumento={setDocumento}
          />
        </div>
      )}
      {showTipoDoc && (
        <div className={`form-group col-md-12 col-xl-${esSoloResolucion ? 3 : 2}`}>
          <SelectTipoDocumento
            label='Tipo de Documento *'
            name='tipoDocumentoElectronico'
            value={documento.tipoDocumentoElectronico}
            onChange={handleChangeTipoDoc}
            isDisabled={disableTipoDocumento}
            isCotizacion={isCotizacion}
            isExcel={isExcel}
            isDocumentoSoporte={isDocumentoSoporte}
          />
        </div>
      )}
      {!isCotizacion && showPrefijo && (
        <div className={`form-group col-md-12 col-xl-${esSoloResolucion ? 3 : 2}`}>
          {!documento.tipoDocumentoElectronico.includes('NOTA_') ? (
            <SelectPrefijos
              label='Prefijo'
              placeholder='Seleccione un prefijo'
              name='dsPrefijo'
              value={documento.dsPrefijo}
              resoluciones={state.resoluciones}
              resolucionSeleccionada={documento.dsResolucionDian}
              onChange={handleChangeSelect}
              setDocumento={setDocumento}
              tipoDocumento={documento.tipoDocumentoElectronico}
            />
          ) : (
            <Input
              label='Prefijo'
              name='dsPrefijo'
              value={documento.dsPrefijo}
              onChange={({ target }) => setDocumento({ [target.name]: target.value.toUpperCase() })}
            />
          )}
        </div>
      )}
      {!isCotizacion && (
        <>
          <div className={`form-group col-md-12 col-xl-${esSoloResolucion ? 2 : 1}`}>
            {showConsecutivo && (
              <InputNumFactura
                label='Nro. Documento *'
                id='dsNumeroFactura'
                onChange={handleChange}
                name='dsNumeroFactura'
                automatico={documento.snConsecutivoAutomatico}
                maximo={documento.consecutivoMax}
                minimo={documento.consecutivoMin}
                value={documento.dsNumeroFactura}
                documento={documento}
                setDocumento={setDocumento}
                emisor={emisor}
              />
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {/* <UltimosDocumentos /> */}
            {/* <DocumentosAdicionales /> */}
            <DocumentosReferencia
              tipoDocumento={documento.tipoDocumentoElectronico}
              setDocumento={setDocumento}
              facturasReferencia={documento.facturasReferencia}
              isDocumentoSoporte={isDocumentoSoporte}
              isDocumentoExpress={isDocumentoExpress}
              emisor={emisor}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DatosDocumento;
