import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import { SetSpinnerModal } from "reducers/actions/facturaActions";

import FormularioEntrada from "./FormularioEntrada";
import FormularioSalida from "./FormularioSalida";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`Tab${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}

class Parqueadero extends Component {
  state = {
    tabSelected: 0,
  };

  handleChangeTab = (_, tab) => this.setState({ tabSelected: tab });

  render() {
    const { tabSelected } = this.state;
    const { userAttributes, setSpinnerModal } = this.props;
    return (
      <Paper>
        <AppBar position="static">
          <Tabs
            value={tabSelected}
            label="Salida"
            onChange={this.handleChangeTab}
          >
            <Tab label="Entrada" id="Tab0" />
            <Tab label="Salida" id="Tab1" />
          </Tabs>
        </AppBar>
        <TabPanel value={tabSelected} index={0}>
          <FormularioEntrada
            nmIdEmisor={userAttributes.codigoEmisor}
            spinnerModal={setSpinnerModal}
          />
        </TabPanel>
        <TabPanel value={tabSelected} index={1}>
          <FormularioSalida
            nmIdEmisor={userAttributes.codigoEmisor}
            spinnerModal={setSpinnerModal}
            usuarioRegistro={userAttributes["custom:custom:username"]}
          />
        </TabPanel>
      </Paper>
    );
  }
}

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Parqueadero);
