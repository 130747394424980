import { Auth } from "aws-amplify";

export async function signUp(data) {
  const { username, password, email, phone_number, fullname, userid } = data;
  return await Auth.signUp({
    username,
    password,
    attributes: {
      email,
      phone_number,
      "custom:custom:fullname": fullname,
      "custom:custom:userid": userid,
      "custom:custom:username": username,
    },
    validationData: [],
  });
}

export async function signIn({ username, password }) {
  return await Auth.signIn(username, password);
}

export async function confirmSignUp({ username, verificationCode }) {
  return await Auth.confirmSignUp(username, verificationCode, {});
}

export async function getUserData() {
  try {
    return await Auth.currentAuthenticatedUser({ bypassCache: false });
  } catch {
    return "";
  }
}

export async function retrieveCurrentSessionRefreshToken() {
  return await Auth.currentSession();
}

export async function completeNewPassword(username, newPassword, attributes) {
  return await Auth.completeNewPassword(
    username,
    newPassword,
    attributes //es un objeto
  );
}

export async function resendUserCodeSignUp(username) {
  return await Auth.resendSignUp(username);
}

export async function changePassword(oldPassword, newPassword) {
  return await getUserData().then((user) =>
    Auth.changePassword(user, oldPassword, newPassword)
  );
}

export async function forgotPassword(username) {
  return await Auth.forgotPassword(username);
}

export async function forgotPasswordSubmit(username, code, newPassword) {
  return await Auth.forgotPasswordSubmit(username, code, newPassword);
}

export async function signOut() {
  await Auth.signOut();
}
