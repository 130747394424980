import React, { Component, Fragment } from "react";

class Saldo extends Component {
  handleLogin = (event) => {
    event.preventDefault();
    localStorage.setItem("access_token", "token");
  };
  
  render() {
    return (
      <Fragment>
        <form>
          <label>Info de saldo</label>
        </form>
      </Fragment>
    );
  }
}

export default Saldo;
