const tiposMediosPago = [
  {
    codigo: "INSTRUMENTO_NO_DEFINIDO",
    nombre: "Instrumento no definido",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CREDITO_ACH",
    nombre: "Crédito ACH",
    nomina: false,
    documento: true,
    soporte: true,
  },
  { codigo: "DEBITO_ACH", nombre: "Débito ACH", nomina: true, documento: true },
  {
    codigo: "REVERSION_DEBITO_DEMANDA_ACH",
    nombre: "Reversión débito de demanda ACH",
    nomina: false,
    documento: true,
    soporte: false,
  },
  {
    codigo: "REVERSION_CREDITO_DEMANDA_ACH",
    nombre: "Reversión crédito de demanda ACH",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CREDITO_DEMANDA_ACH",
    nombre: "Crédito de demanda ACH",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "DEBITO_DEMANDA_ACH",
    nombre: "Débito de demanda ACH",
    nomina: false,
    documento: true,
  },
  {
    codigo: "CLEARING_NACIONAL_O_REGIONAL",
    nombre: "Clearing Nacional o Regional",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "EFECTIVO",
    nombre: "Efectivo",
    nomina: true,
    documento: true,
    soporte: true,
    pos: true,
  },
  {
    codigo: "REVERSION_CREDITO_AHORRO",
    nombre: "Reversión Crédito Ahorro",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "REVERSION_DEBITO_AHORRO",
    nombre: "Reversión Débito Ahorro",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CREDITO_AHORRO",
    nombre: "Crédito Ahorro",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "DEBITO_AHORRO",
    nombre: "Débito Ahorro",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "BOOKENTRY_CREDITO",
    nombre: "Bookentry Crédito",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "BOOKENTRY_DEBITO",
    nombre: "Bookentry Débito",
    nomina: false,
    documento: true,
    soporte: false,
  },
  {
    codigo: "CONCENTRACION_DEMANDA_EFECTIVO_CREDITO",
    nombre: "Desembolso Crédito ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CONCENTRACION_DEMANDA_EFECTIVO_DEBITO",
    nombre: "Desembolso",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CREDITO_PAGO_NEGOCIO_CORPORATIVO",
    nombre: "Crédito Pago negocio corporativo ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CHEQUE",
    nombre: "Cheque",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "PROYECTO_BANCARIO",
    nombre: "Poyecto bancario",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "PROYECTO_BANCARIO_CERTIFICADO",
    nombre: "Proyecto bancario certificado",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CHEQUE_BANCARIO",
    nombre: "Cheque bancario de gerencia",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_CAMBIARIA_ESPERANDO_ACEPTACION",
    nombre: "Nota cambiaria esperando aceptación",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CHEQUE_CERTIFICADO",
    nombre: "Cheque certificado",
    nomina: true,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CHEQUE_LOCAL",
    nombre: "Cheque Local",
    nomina: true,
    documento: true,
    soporte: true,
  },
  {
    codigo: "DEBITO_PAGO_NEGOCIO_CORPORATIVO",
    nombre: "Débito Pago Neogcio Corporativo ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CREDITO_PAGO_INTERCAMBIO_CORPORATIVO",
    nombre: "Crédito Negocio Intercambio Corporativo ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "DEBITO_NEGOCIO_INTERCAMBIO_CORPORATIVO",
    nombre: "Débito Negocio Intercambio Corporativo ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "TRANSFERENCIA_CREDITO",
    nombre: "Transferecia Crédito",
    nomina: false,
    documento: true,
    soporte: true,
    pos: true,
  },
  {
    codigo: "TRANSFERENCIA_DEBITO",
    nombre: "Transferencia Débito",
    nomina: false,
    documento: true,
    soporte: true,
    pos: true,
  },
  {
    codigo: "CONCENTRACION_EFECTIVO_DESEMBOLSO_CREDITO",
    nombre: "Desembolso Crédito plus ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CONCENTRACION_EFECTIVO_DESEMBOLSO_DEBITO",
    nombre: "Desembolso Débito plus ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "PAGO_DEPOSITO_PRE_ACORDADO",
    nombre: "Pago y depósito pre acordado ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CONCENTRACION_EFECTIVO_AHORROS_CREDITO",
    nombre: "Desembolso Crédito ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CONCENTRACION_EFECTIVO_AHORROS_DEBITO",
    nombre: "Desembolso Débito ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "PAGO_NEGOCIO_CORPORATIVO_AHORROS_CREDITO",
    nombre: "Pago Negocio Corporativo Ahorros Crédito ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "PAGO_NEGOCIO_CORPORATIVO_AHORROS_DEBITO",
    nombre: "Pago Negocio Corporativo Ahorros Débito ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CREDITO_NEGOCIO_INTERCAMBIO_CORPORATIVO",
    nombre: "Crédito Negocio Intercambio Corporativo ",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CONSIGNACION_BANCARIA",
    nombre: "Consignación bancaria",
    nomina: true,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_CAMBIARIA",
    nombre: "Nota cambiaria",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "TRANSFERENCIA_CREDITO_BANCARIO",
    nombre: "Transferencia Crédito Bancario",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "TRANSFERENCIA_DEBITO_INTERBANCARIO",
    nombre: "Transferencia Débito Interbancario",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "TRANSFERENCIA_DEBITO_BANCARIA",
    nombre: "Transferencia Débito Bancaria",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "TARJETA_CREDITO",
    nombre: "Tarjeta Crédito",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "TARJETA_DEBITO",
    nombre: "Tarjeta Débito",
    nomina: true,
    documento: true,
    soporte: true,
  },
  {
    codigo: "POSTGIRO",
    nombre: "Postgiro",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "TELEX_ESTANDAR_BANCARIO_FRANCES",
    nombre: "Telex estándar bancario",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "PAGO_COMERCIAL_URGENTE",
    nombre: "Pago comercial urgente",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "PAGO_TESORERIA_URGENTE",
    nombre: "Pago Tesorería Urgente",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_PROMISORIA",
    nombre: "Nota promisoria",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_PROMISORIA_FIRMADA_ACREEDOR",
    nombre: "Nota promisoria firmada por el acreedor",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_PROMISORIA_FIRMADA_ACREEDOR_AVALADA_BANCO",
    nombre: "Nota promisoria firmada por el acreedor, avalada por el banco",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_PROMISORIA_FIRMADA_ACREEDOR_AVALADA_TERCERO",
    nombre: "Nota promisoria firmada por el acreedor, avalada por un tercero",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_PROMISORIA_FIRMADA_BANCO",
    nombre: "Nota promisoria firmada pro el banco",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_PROMISORIA_FIRMADA_BANCO_AVALADA_BANCO",
    nombre: "Nota promisoria firmada por un banco avalada por otro banco",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_PROMISORIA_FIRMADA",
    nombre: "Nota promisoria firmada",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_PROMISORIA_FIRMADA_TERCERO_AVALADA_BANCO",
    nombre: "Nota promisoria firmada por un tercero avalada por un banco",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "RETIRO_NOTA_ACREEDOR",
    nombre: "Retiro de nota por el por el acreedor",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "BONOS",
    nombre: "Bonos",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "VALES",
    nombre: "Vales",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "RETIRO_NOTA_ACREEDOR_SOBRE_BANCO",
    nombre: "Retiro de nota por el por el acreedor sobre un banco",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "RETIRO_NOTA_ACREEDOR_AVALADA_BANCO",
    nombre: "Retiro de nota por el acreedor, avalada por otro banco",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "RETIRO_NOTA_ACREEDOR_SOBRE_BANCO_AVALADA_TERCERO",
    nombre: "Retiro de nota por el acreedor, sobre un banco avalada por un tercero",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "RETIRO_NOTA_ACREEDOR_SOBRE_TERCERO",
    nombre: "Retiro de una nota por el acreedor sobre un tercero",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "RETIRO_NOTA_ACREEDOR_SOBRE_TERCERO_AVALADA_BANCO",
    nombre: "Retiro de una nota por el acreedor sobre un tercero avalada por un banco",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "NOTA_BANCARIA_TRANSFERIBLE",
    nombre: "Nota bancaria tranferible",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CHEQUE_LOCAL_TRANSFERIBLE",
    nombre: "Cheque local traferible",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "GIRO_REFERENCIADO",
    nombre: "Giro referenciado",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "GIRO_URGENTE",
    nombre: "Giro urgente",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "GIRO_FORMATO_ABIERTO",
    nombre: "Giro formato abierto",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "METODO_PAGO_NO_USADO",
    nombre: "Método de pago solicitado no usuado",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "CLEARING_ENTRE_PARTNERS",
    nombre: "Clearing entre partners",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "OTRO",
    nombre: "Otro",
    nomina: false,
    documento: true,
    soporte: true,
  },
  {
    codigo: "ACUERDO_MUTUO",
    nombre: "Acuerdo mutuo",
    nomina: true,
    documento: false,
    soporte: false,
  },
  {
    codigo: "CUENTA_COBRAR",
    nombre: "Cuenta por cobrar",
    pos: true,
  },
  {
    codigo: "TRANSFERENCIA_QR",
    nombre: "Transferencia QR",
    pos: true,
  },
];

export default tiposMediosPago;
