import React from 'react';
import { Col, Row, Divider } from 'antd';
import Modal from 'components/molecules/Modal';
import DetalleServicio from 'components/organisms/TablaAliados/DetalleServicio';
import FormularioCompra from './FormularioCompra';

function DetalleAliado({ closeModal, open, servicio, emisor }) {
  return (
    <Modal
      open={open}
      fullScreen
      title={null}
      content={
        servicio && (
          <Row>
            <Col xl={12} xs={24} style={{ padding: '25px' }}>
              <Row>
                <Col span={24}>
                  <DetalleServicio
                    dsServicio={servicio.dsServicio}
                    dsAliado={servicio.emisor.nombreCompleto}
                    nmValorPortal={servicio.nmValorPortal}
                    feVigencia={servicio.feVigencia}
                  />
                  <Divider />
                  <FormularioCompra
                    emisor={emisor}
                    condiciones={servicio.dsCondiciones}
                    servicioId={servicio}
                    closeModal={closeModal}
                    valorServicio={servicio.nmValorPortal}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={12} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
              {servicio.dsUrlImagen && (
                <Row>
                  <div>
                    <img src={servicio.dsUrlImagen} alt='Imagen del Servicio' />
                  </div>
                </Row>
              )}
            </Col>
          </Row>
        )
      }
    />
  );
}

export default DetalleAliado;
