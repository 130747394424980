import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function pension({ baseBody, guardarDeduccion, xlCol = "2" }) {
  const [nmPorcentaje, setNmPorcentaje] = useState();
  const [nmDeduccion, setNmDeduccion] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `PENSION`,
    nmPorcentaje,
    nmDeduccion,
  };

  const guardar = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDeduccion(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmDeduccion();
    setNmPorcentaje();
  };

  return (
    <form onSubmit={guardar}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Porcentaje del pago *"
            id="porcentaje"
            value={nmPorcentaje || ""}
            onChange={(e) => setNmPorcentaje(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Debe corresponder al porcentaje de deducción de fondo de pension que paga el trabajador"
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor pagado *"
            id="deduccion"
            value={nmDeduccion || ""}
            onChange={(e) => setNmDeduccion(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="El trabajador también debe estar afiliado al sistema de pensiones. La cotización por pensión está a cargo tanto de la empresa como del empleado. Del total del aporte (16%), la empresa aporta el 75% (12%) y el trabajador aporta el restante 25% (4%). Como el trabajador debe aportar un 4% por concepto de pensión, este valor se le descuenta (deduce) del valor devengado en el respectivo periodo (mes o quincena)."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  // if (!(body.nmPorcentaje > 0 && body.nmPorcentaje <= 100))
  //   errores.push("El porcentaje se esta enviando fuera del rango de 1 a 100");
  // if (body.nmDeduccion < 0 || !body.nmDeduccion)
  //   errores.push(
  //     "El valor de la deduccion no puede ser negativa o no se esta enviando"
  //   );
  return errores;
}
