import * as React from 'react';
import { Grid } from '@material-ui/core';
import Input from 'components/Inputs/Input';
import Button from 'components/atoms/Button';
import Add from '@material-ui/icons/AddCircle';

let timeout;

const FormularioConsulta = ({ onConsulta, onOpenAgregar, filter, setFilter }) => {
  const onChangeInput = ({ target }) => {
    const name = target.value;
    setFilter(name);
    clearTimeout(timeout);
    timeout = setTimeout(() => onConsulta(name), 1000);
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={5} md={2}>
        <Button fullWidth onClick={onOpenAgregar}>
          <Add />
          Agregar
        </Button>
      </Grid>
      <Grid item xs={7} md={10}>
        <Input placeholder='Busqueda por nombre...' value={filter} onChange={onChangeInput} />
      </Grid>
    </Grid>
  );
};

export default FormularioConsulta;
