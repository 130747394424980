import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { Autorenew } from '@material-ui/icons';

import { consultarPorEmisor } from 'network/Api/CertFirma';
import SelectConsultaEmisores from '../SelectConsultaEmisores';
import FirmasTable from './FirmasTable';
import { SetSpinnerModal } from '../../../reducers/actions/facturaActions';
import { consultarConfiguracion } from 'network/Api/Emisor';

class GestionFirmaDigital extends Component {
  state = {
    emisorSeleccionado: null,
    listFirmas: [],
  };

  handleConsultarFirmas = (emisorId) => {
    const { setSpinnerModal } = this.props;
    setSpinnerModal(true);
    consultarPorEmisor({ nmIdEmisor: emisorId }).then((result) => {
      this.setState({
        listFirmas: result.data,
      });
      setSpinnerModal(false);
    });
  };

  handleConsultarEmisor = (event) => {
    const { id, value } = event;
    consultarConfiguracion({ dsIdentificacion: value }).then((res) => {
      this.setState({ emisorSeleccionado: id, nitEmisor: value, urlRut: res.data.urlRut });
      this.handleConsultarFirmas(id);
    });
  };

  render() {
    const { listFirmas, emisorSeleccionado, nitEmisor, urlRut } = this.state;
    return (
      <div>
        <Grid container direction='column' justify='space-between'>
          <div>
            <Grid container spacing={8}>
              <Grid item xs={11} style={{ zIndex: '9' }}>
                <SelectConsultaEmisores handleConsultarEmisor={this.handleConsultarEmisor} />
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={() => this.handleConsultarFirmas(emisorSeleccionado)}
                  style={{ width: '100%' }}
                >
                  <Autorenew />
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <FirmasTable
          firmas={listFirmas}
          handleConsultarFirmas={this.handleConsultarFirmas}
          nitEmisor={nitEmisor}
          emisorId={emisorSeleccionado}
          urlRutEmisor={urlRut}
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GestionFirmaDigital);
