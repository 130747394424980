import * as React from "react";
import Input from "components/Inputs/Input";

const CustomInput = ({ label, tooltip, ...rest }) => {
  return (
    <div className="form-group col-xl-12 col-sm-12 d-flex align-items-center">
      <label className="col-xl-2 col-sm-2 text-right">{label}</label>
      <div className="col-xl-1 col-sm-1">
        <Input {...rest} />
      </div>
      <div className="col-xl-9 col-sm-9 text-left">{tooltip}</div>
    </div>
  );
};

export default CustomInput;
