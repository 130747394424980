import React, { useState, useEffect } from "react";
import { Create, Visibility, VisibilityOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import {
  consultarAyudas,
  actualizarAyuda,
  guardarAyuda,
} from "network/Api/Ayuda";
import Input from "components/Inputs/Input";
import Select from "components/Inputs/Select";
import Button from 'components/atoms/Button';
import TablaGenerica from "components/Tablas/TablaGenerica";
import PreviewAyuda from "components/Ayudas/Preview";
import LoaderScreen from "components/Loaders/LoadingScreen";

const styles = () => ({
  formRow: { display: "flex", margin: "10px 0px" },
  buttonActions: { margin: "0px 5px" },
});

const defaultState = { cdEstado: "A" };

function TablaAyudas({ classes }) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    consultar();
  }, []);

  function handleChangeAyuda(event) {
    const label = event.target.name;
    const value = event.target.value;
    switch (label) {
      default:
        setState((s) => ({ ...s, [label]: value }));
        break;
    }
  }

  function consultar() {
    setState((s) => ({ ...s, loading: true }));
    consultarAyudas().then((res) => {
      setState((s) => ({ ...s, ayudas: res.data, loading: false }));
    });
  }

  function guardar() {
    const { dsAyuda, cdEstado, dsCuerpoAyuda } = state;
    setState((s) => ({ ...s, loading: true }));
    guardarAyuda({ dsAyuda, cdEstado, dsCuerpoAyuda }).then((_) => {
      cancelarEdicion();
      consultar();
    });
  }

  function iniciarEdicion(ayuda) {
    setState((s) => ({ ...s, ...ayuda, edit: true }));
  }

  function finalizarEdicion() {
    const { nmId, dsAyuda, cdEstado, dsCuerpoAyuda } = state;
    setState((s) => ({ ...s, loading: true }));
    actualizarAyuda({ nmId, dsAyuda, cdEstado, dsCuerpoAyuda }).then((_) => {
      cancelarEdicion();
      consultar();
    });
  }

  function cancelarEdicion() {
    setState((s) => ({
      ...s,
      edit: false,
      nmId: null,
      dsAyuda: null,
      cdEstado: "A",
      dsCuerpoAyuda: null,
    }));
  }

  function actualizarEstado(ayuda, cdEstado) {
    actualizarAyuda({ ...ayuda, cdEstado }).then((_) => consultar());
  }

  function filtro(palabra) {
    let ayudasFilter = [];
    state.ayudas &&
      state.ayudas
        .map((item) => ({
          nmId: item.nmId,
          dsAyuda: item.dsAyuda,
          dsCuerpoAyuda: item.dsCuerpoAyuda,
        }))
        .forEach((item) => {
          Object.keys(item).some((key) => {
            if (
              item[key]
                .toString()
                .toLowerCase()
                .includes(palabra)
            ) {
              ayudasFilter.push(item);
              return true;
            } else {
              return false;
            }
          });
        });
    setState((s) => ({ ...s, ayudasFilter }));
  }

  return (
    <>
      <div>
        <div className={classes.formRow}>
          <Input
            label="Titulo Pregunta"
            name="dsAyuda"
            value={state.dsAyuda || ""}
            onChange={handleChangeAyuda}
            margin={"5px"}
          />
          <Select
            label="Estado"
            name="cdEstado"
            onChange={(e) => setState((s) => ({ ...s, cdEstado: e.value }))}
            options={[
              { label: "Activo", value: "A" },
              { label: "Inactivo", value: "I" },
            ]}
            value={[
              { label: "Activo", value: "A" },
              { label: "Inactivo", value: "I" },
            ].filter((o) => o.value === state.cdEstado)}
            margin={"5px"}
            width={"30%"}
          />
        </div>
        <div className={classes.formRow}>
          <Input
            label="Cuerpo"
            name="dsCuerpoAyuda"
            value={state.dsCuerpoAyuda || ""}
            onChange={handleChangeAyuda}
            margin={"5px"}
          />
        </div>
        <div className={classes.formRow}>
          <PreviewAyuda title={state.dsAyuda} body={state.dsCuerpoAyuda} />
        </div>
        <div className={classes.formRow}>
          {state.edit ? (
            <>
              <Button
                className={classes.buttonActions}
                onClick={finalizarEdicion}
              >
                Actualizar
              </Button>
              <Button
                className={classes.buttonActions}
                onClick={cancelarEdicion}
              >
                Cancelar
              </Button>
            </>
          ) : (
            <Button className={classes.buttonActions} onClick={guardar}>
              Guardar
            </Button>
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Input
          label="Buscar"
          onChange={(event) => filtro(event.target.value)}
          width="30%"
        />
      </div>
      <TablaGenerica
        rowsPerPage={100}
        headers={["Visualizar", "Estado", "Editar", "Habilitar / Deshabilitar"]}
        keys={["preview", "estado", "editar", "changeEstado"]}
        detalles={(state.ayudasFilter || state.ayudas || []).map((a) => ({
          preview: <PreviewAyuda title={a.dsAyuda} body={a.dsCuerpoAyuda} />,
          estado: a.cdEstado === "A" ? "Activo" : "Inactivo",
          editar: (
            <Button
              color="inherit"
              variant="text"
              onClick={() => iniciarEdicion(a)}
            >
              <Create />
            </Button>
          ),
          changeEstado: (
            <Button
              color="inherit"
              variant="text"
              onClick={() =>
                actualizarEstado(a, a.cdEstado === "I" ? "A" : "I")
              }
            >
              {a.cdEstado === "I" ? (
                <VisibilityOff style={{ color: "#e74c3c" }} />
              ) : (
                <Visibility style={{ color: "#2ecc71" }} />
              )}
            </Button>
          ),
        }))}
      />
      <LoaderScreen open={state.loading} />
    </>
  );
}

export default withStyles(styles)(TablaAyudas);
