import * as React from "react";
import Select from "components/Inputs/Select";
import RespFiscales from "static/enums/tiposResponsabilidadesFiscales";

const SelectRespFiscales = ({ responsabilidades, setDocumento }) => {
  const getOptions = () =>
    RespFiscales.map((rf) => ({ label: rf.nombre, value: rf.codigo }));

  const handleChange = ({ value }) => {
    let respFiscales = responsabilidades ? responsabilidades.split(";") : [];
    const existe = respFiscales.filter((r) => r === value)[0];
    const newResp = existe
      ? respFiscales.filter((rf) => rf !== value)
      : [...respFiscales, value];
    setDocumento({ responsabilidadesFiscales: newResp.join(";") });
  };

  const getValues = () =>
    (responsabilidades ? responsabilidades.split(";") : []).map(
      (value) => getOptions().filter((rf) => rf.value === value)[0]
    );

  return (
    <Select
      label="Responsabilidades fiscales *"
      name="responsabilidadesFiscales"
      placeholder="Responsabilidades fiscales"
      options={getOptions()}
      onChange={handleChange}
      value={getValues()}
      multi
    />
  );
};

export default SelectRespFiscales;
