import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography as TypoAntd } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
const { Text, Link, Title, Paragraph } = TypoAntd;

const Typography = ({
  children,
  classes,
  variant = 'label',
  className = '',
  component = 'label',
  type,
  ...rest
}) => {
  const Element = useMemo(() => getElement(type), [type]);
  return (
    <Element
      className={[!type ? classes.typography : '', !type ? classes[variant] : '', className].join(
        ' ',
      )}
      component={component}
      {...rest}
    >
      {children}
    </Element>
  );
};

Typography.prototype = {
  variant: PropTypes.oneOf(['labelEncabezado', 'label', 'labelBlanco', 'labelInput']),
};

function getElement(type) {
  switch (type) {
    case 'Text':
      return Text;
    case 'Link':
      return Link;
    case 'Title':
      return Title;
    case 'Paragraph':
      return Paragraph;
    default:
      return TypoAntd;
  }
}

export default withStyles(styles)(Typography);
