import React from 'react';
import PropTypes from 'prop-types';

const Input = ({ type = 'text', className = 'form-control', ...rest }) => {
  return <input type={type} className={className} autoComplete='off' {...rest} />;
};

Input.prototype = {
  type: PropTypes.string,
  className: PropTypes.string,
};

export default Input;
