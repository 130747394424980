import React from 'react';
import { calcularDigitoVerificacion } from 'utils/funcionesUtiles';

const useFormularioPersonaEmisor = (defaultPersona = { cdCodigoPais: 'Colombia' }) => {
  const [persona, setDataPersona] = React.useState(defaultPersona);

  const setPersona = (dp) => setDataPersona((p) => ({ ...p, ...dp }));

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'dsCelular' && !/^[0-9]*$/.test(value)) return;
    if (name === 'dsIdentificacion') {
      const dsDigitoVerificacion = calcularDigitoVerificacion(value);
      setPersona({ dsDigitoVerificacion, [name]: value });
      return;
    }
    setPersona({ [name]: value });
  };

  const disableApellidos = React.useMemo(
    () => persona.cdTipoIdentificacion === 'NIT',
    [persona.cdTipoIdentificacion],
  );

  return {
    persona,
    setPersona,
    handleChange,
    disableApellidos,
  };
};

export default useFormularioPersonaEmisor;
