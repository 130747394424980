export const GruposUsuario = [
  {
    nombre: "Esencial",
    value: "EMISOR",
  },
  {
    nombre: "Servicio Cliente",
    value: "SERVICIO_CLIENTE",
  },
  {
    nombre: "Ventas",
    value: "VENTAS",
  },
  {
    nombre: "Parqueadero",
    value: "PARQUEADERO",
  },
  {
    nombre: "Facturacion",
    value: "FACTURACION",
  },
  {
    nombre: "Gerencial",
    value: "GERENCIAL",
  },
  {
    nombre: "Nomina",
    value: "NOMINA",
  },
  {
    nombre: "Contable",
    value: "CONTABLE",
  },
  {
    nombre: "Emisor Pos",
    value: "EMISOR_POS",
  },
];
