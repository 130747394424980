import * as React from "react";
import { Paper } from "@material-ui/core";
import Input from "components/Inputs/Input";
import Button from 'components/atoms/Button';
import TablaDescuento from "./../ModalAddDetalle/TableDescuentos";

const defaultState = { tipoDescuento: "valor" };

const AgregarDescuentos = ({
  valorBase = 0,
  setDetalle,
  cargosDescuentos = [],
}) => {
  const [state, setData] = React.useState(defaultState);

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  const handleChange = ({ target }) =>
    setState({ [target.name]: target.value });

  const addDescuento = () => {
    const { valorDescuento } = state;
    const valor = parseFloat(valorDescuento);
    setDetalle({
      cargosDescuentos: [
        ...cargosDescuentos,
        {
          tipo: false,
          valor: valor,
        },
      ].map((d, id) => ({ ...d, id })),
    });
    setData(defaultState);
  };

  const getMaxValue = () =>
    valorBase ? (state.tipoDescuento === "porcentaje" ? 100 : valorBase) : 0;

  const validarDecimal = ({ target }) => {
    const { value, name } = target;
    if (!/^[0-9]+([.][0-9]+)?$/.test(value)) setState({ [name]: 0 });
    else {
      if (parseFloat(value) < 0) setState({ [name]: 0 });
      if (parseFloat(value) > getMaxValue())
        setState({ [name]: getMaxValue() });
    }
  };

  return (
    <Paper style={{ textAlign: "center", margin: "15px 0px" }}>
      <div className="form-row col-md-12 d-flex justify-content-center">
        <div className="form-group col-md-12 col-xl-12">
          <label className="text-muted">Agregar descuentos</label>
        </div>
      </div>
      <div className="form-row col-md-12 d-flex justify-content-center">
        <div className="form-group col-md-4 col-xl-4 mt-4">
          <label className="text-muted">
            Descuento por {state.tipoDescuento}
          </label>
        </div>
        <div className="form-group col-md-4 col-xl-4">
          <Input
            label={`Ingrese el ${state.tipoDescuento} del descuento`}
            name={`${state.tipoDescuento}Descuento`}
            value={state[`${state.tipoDescuento}Descuento`]}
            onChange={handleChange}
            onBlur={validarDecimal}
          />
        </div>
        <div className="form-group col-md-4 col-xl-4 mt-4" />
        <div className="form-group col-md-12 col-xl-12">
          <div className="mt-3">
            <Button
              style={{
                fontWeight: "bold",
                backgroundColor: "#92C63E",
                color: "white",
              }}
              name="Cerrar"
              color="primary"
              onClick={addDescuento}
            >
              Agregar descuento
            </Button>
          </div>
        </div>
      </div>
      <TablaDescuento
        descuentos={cargosDescuentos}
        setDetalle={setDetalle}
        isRegistroDoc={true}
      />
    </Paper>
  );
};

export default AgregarDescuentos;
