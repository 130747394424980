import * as React from "react";
import Icon from "components/menu/Icon";
import AsesorIcon from "static/images/asesor.png";
import MiTurnoIcon from "static/images/MiTurno.png";

const getMenu = (items) => ({
  subMenu: true,
  title: "Turno",
  icon: <Icon src={MiTurnoIcon} alt="Turno" />,
  items,
});

const asesor = {
  title: "Asesor",
  icon: <Icon src={AsesorIcon} alt="Asesor" />,
  pathname: "/asesor",
};

export const Turno = getMenu([asesor]);
