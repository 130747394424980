import React, { useState, useEffect } from 'react';
import Input from '../../../Inputs/Input';
import Button from 'components/atoms/Button';
import SelectTipoIdentificacion from '../../../Inputs/SelectTipoIdentificacion';
import SelectCiudades, { getById } from '../../../Inputs/SelectCiudades';
import { TIPOS_DATOS, validarInput } from '../../../../utils/ValidarDatos';
import * as DateUtils from '../../../../utils/DateUtils';
import { actualizarEmpleado } from 'network/Api/Empleado';
import ModalConfirmacion from 'components/modal/ModalConfirmacion';
import Checkbox from 'components/molecules/Checkbox';
import Select from 'components/Inputs/Select';
import FormasPago from 'static/enums/tiposPago';
import MediosPago from 'static/enums/tiposMediosPago';
import TipoTrabajador from 'static/enums/TipoTrabajador';
import SubTipoTrabajador from 'static/enums/SubTipoTrabajador';
import Loader from 'components/Loaders/Loader';
import { retirarEmpleado } from 'network/Api/Empleado';

export default function empleado(props) {
  const [empleado, setEmpleado] = useState(props.empleado);
  const [modal, setModal] = useState({});

  useEffect(() => {
    if (props.empleado)
      setEmpleado({
        ...props.empleado,
        emisor: { nmId: props.emisor },
        feNacimiento: DateUtils.formatDate(DateUtils.getDateFromArray(props.empleado.feNacimiento)),
      });
  }, [props.empleado]);

  const setData = (data) => {
    setEmpleado({ ...empleado, ...data });
  };

  const actualizar = () =>
    actualizarEmpleado({
      ...empleado,
      feIngreso: empleado.feIngreso + 'T00:00:00',
    }).then((res) => {
      if (res.status === 200) {
        props.resetEmpleado();
        props.openModal('Empleado actualizado correctamente');
      } else {
        props.openModal('Error actualizando el empleado');
      }
    });

  const openConfirmacion = () =>
    setModal({
      text: '¿Seguro desea retirar el empleado?',
      open: true,
    });

  const closeModal = () => setModal({ ...modal, open: false });

  const retirar = () => {
    closeModal();
    retirarEmpleado({ nmId: empleado.nmId }).then((res) => {
      if (res.status === 200) {
        props.resetEmpleado();
        props.openModal('Empleado retirado correctamente');
      } else {
        props.openModal('Error retirando el empleado');
      }
    });
  };

  return (
    <div>
      <ModalConfirmacion
        content={modal.text || ''}
        onCancel={closeModal}
        onAccept={retirar}
        open={modal.open || false}
      />
      <div className='form-row col-md-12' style={{ padding: '10px' }}>
        <div className='form-group col-xl-3'>
          <SelectTipoIdentificacion
            label='Tipo de identificación *'
            onChange={(e) => setData({ cdTipoIdentificacion: e.value })}
            value={{
              label: empleado.cdTipoIdentificacion,
              value: empleado.cdTipoIdentificacion,
            }}
            disabled
          />
        </div>
        <div className='form-group col-xl-2'>
          <Input
            label='Identificación *'
            value={empleado.dsIdentificacion || ''}
            onChange={(e) => setData({ dsIdentificacion: e.target.value })}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.textoNumeros)}
            disabled
          />
        </div>
        <div className='form-group col-xl-3'>
          <Input
            label='Email *'
            value={empleado.dsEmail || ''}
            onChange={(e) => setData({ dsEmail: e.target.value })}
          />
        </div>
        <div className='form-group col-xl-2'>
          <Select
            label='Tipo trabajador'
            options={TipoTrabajador}
            value={TipoTrabajador.filter((tt) => tt.value === empleado.cdTipoTrabajador)}
            onChange={(e) => setData({ cdTipoTrabajador: e.value })}
          />
        </div>
        <div className='form-group col-xl-2'>
          <Select
            label='Sub tipo trabajador'
            options={SubTipoTrabajador}
            value={SubTipoTrabajador.filter((stt) => stt.value === empleado.cdSubTipoTrabajador)}
            onChange={(e) => setData({ cdSubTipoTrabajador: e.value })}
          />
        </div>
        <div className='form-row col-md-12'>
          <div className='form-group col-md-3 mb-0'>
            <Input
              label='Primer nombre *'
              value={empleado.dsNombre || ''}
              onChange={(e) => setData({ dsNombre: e.target.value })}
            />
          </div>
          <div className='form-group col-md-3 mb-0'>
            <Input
              label='Segundo nombre'
              value={empleado.dsSegundoNombre || ''}
              onChange={(e) => setData({ dsSegundoNombre: e.target.value })}
            />
          </div>
          <div className='form-group col-md-3 mb-0'>
            <Input
              label='Primer apellido *'
              value={empleado.dsPrimerApellido || ''}
              onChange={(e) => setData({ dsPrimerApellido: e.target.value })}
            />
          </div>
          <div className='form-group col-md-3 mb-0'>
            <Input
              label='Segundo apellido'
              value={empleado.dsSegundoApellido || ''}
              onChange={(e) => setData({ dsSegundoApellido: e.target.value })}
            />
          </div>
        </div>
        <div className='form-row col-md-12'>
          <div className='form-group col-xl-2'>
            <Input
              label='Dirección *'
              value={empleado.dsDireccion || ''}
              onChange={(e) => setData({ dsDireccion: e.target.value })}
            />
          </div>
          <div className='form-group col-xl-2'>
            {empleado.ciudad ? (
              <SelectCiudades
                label='Ciudad *'
                onChange={(e) => {
                  setData({ ciudad: null });
                  getById(e.value).then((ciudad) => {
                    setData({ ciudad });
                  });
                }}
                value={{
                  label: `${empleado.ciudad.dsNombre} - ${empleado.ciudad.departamento.dsNombre} - ${empleado.ciudad.departamento.pais.dsNombre}`,
                  value: empleado.ciudad.nmId,
                }}
              />
            ) : (
              <Loader />
            )}
          </div>
          <div className='form-group col-xl-2'>
            <Select
              label='Medio de pago'
              options={MediosPago.filter((mp) => mp.nomina).map((f) => ({
                label: f.nombre,
                value: f.codigo,
              }))}
              value={MediosPago.filter((mp) => mp.nomina)
                .map((f) => ({
                  label: f.nombre,
                  value: f.codigo,
                }))
                .filter((f) => f.value === empleado.cdMedioPago)}
              onChange={(e) => setData({ cdMedioPago: e.value })}
            />
          </div>
          <div className='form-group col-xl-2'>
            <Select
              label='Forma de pago'
              options={FormasPago.map((f) => ({
                label: f.nombre,
                value: f.codigo,
              }))}
              value={FormasPago.map((f) => ({
                label: f.nombre,
                value: f.codigo,
              })).filter((f) => f.value === empleado.cdFormaPago)}
              isDisabled={true}
            />
          </div>
          <div className='form-group col-xl-2'>
            <Input
              label='Fecha ingreso *'
              type='date'
              value={empleado.feIngreso || ''}
              onChange={(e) => setData({ feIngreso: e.target.value })}
            />
          </div>
          <div className='form-group col-xl-2' style={{ marginTop: '20px' }}>
            <Checkbox
              label='Alto riesgo de pensión'
              id='salario'
              tooltip='Si el trabajador desarrollo durante el presente periodo alguna de las actividades descritas en el Decreto 2090 de 2003, o la norma que lo modifique, adicione o sustituya'
              onChange={(e) => setData({ snAltoRiesgoPension: e.target.checked })}
              checked={empleado.snAltoRiesgoPension || false}
            />
          </div>
        </div>
        <div className='form-row col-md-12'>
          <div className='form-group col-xl-12'>
            <Button style={{ margin: '0px 3px' }} onClick={actualizar}>
              Actualizar
            </Button>
            <Button
              style={{ margin: '0px 3px' }}
              onClick={openConfirmacion}
              disabled={props.empleado && props.empleado.cdEstadoEmpleado !== 'A'}
            >
              Retirar Empleado
            </Button>
            <Button style={{ margin: '0px 3px' }} onClick={props.resetEmpleado}>
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
