export const styles = (theme) => ({
  detalles: { margin: '20px 0px' },
  productos: { display: 'flex', alignItems: 'flex-end' },
  productoInput: { margin: '0px 5px' },

  table: {
    minWidth: 600,
  },
  head: {
    backgroundColor: '#128BCC',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  headText: {
    color: theme.palette.common.white,
  },
  bodyTotales: {
    fontWeight: 'bold',
    backgroundColor: '#128bcc',
    color: theme.palette.common.black,
  },
  bodyDetalles: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  buttonAdd: {
    backgroundColor: '#92C63E',
    color: theme.palette.common.white,
  },
  buttonSended: {
    backgroundColor: '#3C3588',
    color: 'white',
    margin: '0px 5px',
    padding: '10px 15px',
  },
  buttonRappi: {
    backgroundColor: 'rgb(255, 68, 31);',
    color: 'white',
    margin: '0px 5px',
    padding: '10px 15px',
  },
  borderedContainer: {
    border: 'solid #BFDEFF',
    padding: '5px',
    borderRadius: '10px',
    marginBottom: '10px',
    marginTop: '10px',
  },
});
