export const coverturasSalud = [
  {
    value: "PLAN_DE_BENEFICIOS_EN_SALUD_FINANCIADO_CON_UPC",
    label: "Plan de beneficios en salud financiado con UPC",
  },
  { value: "PRESUPUESTO_MAXIMO", label: "Presupuesto máximo" },
  {
    value: "PRIMA_EPS_EOC_NO_ASEGURADOS_SOAT",
    label: "Prima EPS / EOC, no asegurados SOAT",
  },
  { value: "COBERTURA_POLIZA_SOAT", label: "Cobertura Póliza SOAT" },
  { value: "COBERTURA_ARL", label: "Cobertura ARL" },
  { value: "COBERTURA_ADRES", label: "Cobertura ADRES" },
  { value: "COBERTURA_SALUD_PUBLICA", label: "Cobertura Salud Pública" },
  {
    value: "COBERTURA_ENTIDAD_TERRITORIAL_RECURSOS_DE_OFERTA",
    label: "Cobertura entidad territorial, recursos de oferta",
  },
  {
    value: "URGENCIAS_POBLACION_MIGRANTE",
    label: "Urgencias población migrante",
  },
  {
    value: "PLAN_COMPLEMENTARIO_EN_SALUD",
    label: "Plan complementario en salud",
  },
  { value: "PLAN_MEDICINA_PREPAGADA", label: "Plan medicina prepagada" },
  { value: "OTRAS_POLIZAS_EN_SALUD", label: "Otras pólizas en salud" },
  {
    value: "COBERTURA_REGIMEN_ESPECIAL_O_EXCEPCION",
    label: "Cobertura Régimen Especial o Excepción",
  },
  {
    value: "COBERTURA_FONDO_NACIONAL_DE_SALUD_DE_LAS_PERSONAS",
    label: "Cobertura fondo nacional de salud de las personas",
  },
  {
    value: "PRIVADAS_DE_LA_LIBERTAD",
    label:
      "Cobertura Fondo Nacional de Salud de las Personas Privadas de la Libertad",
  },
  { value: "PARTICULAR", label: "Particular" },
];
