import * as React from "react";
import Select from "components/Inputs/Select";
import TiposConceptoCredito from "static/enums/tiposConceptoCredito";
import TiposConceptoDebito from "static/enums/tiposConceptoDebito";

const SelectConceptosNotas = (props) => {
  const { setDocumentoRef, nombreTipo, tipoDocumento, value, ...rest } = props;

  const getConceptos = () =>
    (tipoDocumento === "NOTA_CREDITO" ||
    tipoDocumento === "NOTA_CREDITO_SOPORTE_ADQUISICION"
      ? TiposConceptoCredito
      : tipoDocumento === "NOTA_DEBITO"
      ? TiposConceptoDebito
      : []
    ).map((c) => ({ label: c.nombre, value: c.codigo }));

  const handleChangeConcepto = ({ value }) =>
    setDocumentoRef({ [getTipoConcepto()]: value });

  const getTipoConcepto = () =>
    tipoDocumento === "NOTA_CREDITO" ||
    tipoDocumento === "NOTA_CREDITO_SOPORTE_ADQUISICION"
      ? "conceptoNotaCredito"
      : tipoDocumento === "NOTA_DEBITO"
      ? "conceptoNotaDebito"
      : "";

  const getValue = (val) => getConceptos().find((co) => co.value === val);

  return (
    <Select
      label={`Concepto ${nombreTipo} *`}
      options={getConceptos()}
      onChange={handleChangeConcepto}
      value={getValue(value)}
      {...rest}
    />
  );
};

export default SelectConceptosNotas;
