import { Consultas } from "./SubMenus/Consultas";
import { ConfiguracionGerencial } from "./SubMenus/Configuracion";

export const Gerencial = [
  {
    isNoView: "true",
    pathname: "/Home",
    principal: "true",
  },
  Consultas,
  ConfiguracionGerencial,
];
