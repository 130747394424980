import * as React from 'react';
import RSelect from 'react-select';
import RAsync from 'react-select/async';
const { useMemo, useCallback } = React;

function Select({ options = [], value = '', async, multi, onChange, name, ...rest }) {
  const objectValue = useMemo(() => {
    if (multi) {
      return options.filter((o) => {
        const valueArray = value.split(';');
        return valueArray.some((va) => va === o.value);
      });
    } else {
      return options.filter((o) => o.value === value);
    }
  }, [value]);

  const handleChange = useCallback(
    function (event) {
      if (onChange) onChange({ ...event, name });
    },
    [onChange],
  );

  return async ? (
    <RAsync value={objectValue} options={options} name={name} onChange={handleChange} {...rest} />
  ) : (
    <RSelect value={objectValue} options={options} name={name} onChange={handleChange} {...rest} />
  );
}

export default Select;
