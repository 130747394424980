import React, { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';

import InformacionPasarela from './InformacionPasarela';

import Button from 'components/atoms/Button';
import axios from 'axios';
import { transaccionRecarga } from 'network/Api/TransaccionRecarga';
import ModalConfirmarTransaccion from 'components/molecules/Modal/ModalConfirmarTransaccion';
import ModalMensaje from 'components/modal/ModalMensaje';
import * as TextFormattin from 'utils/TextFormattingUtils';
import Typography from 'components/atoms/Typography';

const TransferirSaldo = ({ userAttributes }) => {
  const [state, setData] = useState({});
  const [stateModal, setStateModal] = useState({});
  const cierraModal = () => setStateModal((m) => ({ ...m, open: false }));
  const [ipAddress, setIpAddress] = useState('');

  const setState = (data) => {
    setData((s) => ({ ...s, ...data }));
  };

  const handleChangeNumericValues = (value, name) => {
    const parseValue = value ? parseInt(value) : null;
    setState({ [name]: parseValue });
  };

  const fetchIP = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_GET_IP);
      const data = await response.text();
      setIpAddress(data);
    } catch (error) {
      console.error('Falló al generar la IP: ' + error);
    }
  };

  const abrirModalRecarga = async () => {
    try {
      if (state.recargaValor >= 30000) {
        setState({
          open: true,
          content:
            'Se realizará una compra por valor de $ ' +
            TextFormattin.decimalFormat(state.recargaValor),
        });
      } else {
        setStateModal({
          open: true,
          cancel: cierraModal,
          content: 'Estimado usuario, la recarga mínima es de $ 30.000 mil pesos ',
        });
      }
    } catch (error) {
      console.error('Falló al consumir GOU: ' + error);
    }
  };

  const recargarAplicacion = async () => {
    setState((s) => ({ ...s, open: false }));
    const fecha = new Date();
    const dateISO = fecha.toISOString();
    const body = InformacionPasarela(userAttributes, state, ipAddress, fecha, dateISO);
    const response = await axios.post(process.env.REACT_APP_PASARELA_GOU_URL, body);
    const resData = await response.data;
    const statusData = resData.status;
    if (statusData.status === 'OK' || statusData.status === 'PENDING') {
      await transaccionRecarga({
        dsIdentificacion: userAttributes['custom:custom:userid'],
        reference: body.payment.reference,
        nmValor: state.recargaValor,
        status: {
          status: statusData.status,
          reason: statusData.reason,
          message: statusData.message,
          date: dateISO,
        },
        tranKey: body.auth.tranKey,
        nonce: body.auth.nonce,
        requestId: resData.requestId,
      });
      window.open(resData.processUrl, '_self');
    }
  };

  useEffect(() => {
    fetchIP();
  }, []);

  return (
    <div className='col-sm-12 col-md-12 col-lg-12 mx-auto'>
      {/*
        <div className='card card-signin'>
          <div className='card-body'>
            <div className='form-row justify-content-center'>
              <a
                href={process.env.REACT_APP_PASARELA_PAGO}
                target='_blank'
                rel='noopener noreferrer'
                className='btn btn-lg btn-primary btn-block text-uppercase'
                style={{ margin: '7px 0px' }}
              >
                Recarga en linea Wompi
              </a>
            </div>
          </div>
        </div>
      */}
      <div className='card-signin'>
        <div className='card-body'>
          <div className='form-row justify-content-center'>
            <div className='form-group col-12'>
              <Typography variant='labelEncabezado'>Valor a recargar</Typography>
              <CurrencyInput
                label='Valor a recargar'
                className='input-money'
                name='recargaValor'
                value={state.recargaValor}
                decimalsLimit={2}
                onValueChange={(e) => handleChangeNumericValues(e, 'recargaValor')}
                decimalSeparator=','
                groupSeparator='.'
                prefix='$'
              />
            </div>
            <Button onClick={() => abrirModalRecarga()}>
              Recarga en línea pasarela de pagos GOU
            </Button>
            <div className='form-group col-12 mt-3'>
              <small>
                Descargue
                <a
                  href='https://s3.us-east-2.amazonaws.com/portalfactura.com/public/Documentos/preguntas_frecuentes_sobre_pagos_electronicos.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {' '}
                  AQUÍ{' '}
                </a>{' '}
                las preguntas frecuentes.
              </small>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmarTransaccion
        open={state.open}
        content={state.content}
        onAccept={recargarAplicacion}
      />
      <ModalMensaje
        content={stateModal.content}
        handleClose={stateModal.cancel}
        open={stateModal.open}
      />
    </div>
  );
};

export default TransferirSaldo;
