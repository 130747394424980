import * as React from "react";
import Select from "components/Inputs/Select";

const SelectPrefijos = ({
  resolucionSeleccionada,
  resoluciones = [],
  name,
  value,
  onChange,
  setDocumento,
  tipoDocumento,
  ...rest
}) => {
  React.useEffect(() => {
    const prefijos = filtrarPrefijos();
    prefijos.length === 1 && setDocumento({ dsPrefijo: prefijos[0].value });
  }, [resolucionSeleccionada, tipoDocumento]);

  React.useEffect(() => {
    if (value)
      setDocumento({
        consecutivoMax: parseInt(getPrefijo().consecutivoMax),
        consecutivoMin: parseInt(getPrefijo().consecutivoMin),
        snConsecutivoAutomatico: getPrefijo().snConsecutivoAutomatico,
        dsNumeroFactura: "",
      });
  }, [value]);

  const filtrarPrefijos = () =>
    resolucionSeleccionada
      ? (
          resoluciones.find(
            (r) => r.dsResolucionDian === resolucionSeleccionada
          ).prefijos ||
          resoluciones.find(
            (r) => r.dsResolucionDian === resolucionSeleccionada
          ).prefijosSoporte
        ).map((p) => ({
          label: p.dsPrefijo || "SIN_PREFIJO",
          value: p.dsPrefijo || "SIN_PREFIJO",
          consecutivoMax: p.nmFin,
          consecutivoMin: p.nmInicio,
          snConsecutivoAutomatico: p.snConsecutivoAutomatico,
        }))
      : [];

  const getPrefijo = () => filtrarPrefijos().find((p) => p.value === value);

  return (
    <Select
      options={filtrarPrefijos()}
      name={name}
      onChange={(event) => onChange({ name, ...event })}
      value={getPrefijo()}
      {...rest}
    />
  );
};

export default SelectPrefijos;
