import * as React from "react";
import Icon from "components/menu/Icon";
import DocumentosPos from "static/images/pos.png";
import EmisionIco from "static/images/EmisionDocumentoPos.png";
import DocEmitidosIco from "static/images/documentosNomina.png";

export const DocumentoPos = {
  subMenu: true,
  title: "Documento pos",
  icon: <Icon src={DocumentosPos} alt="Documentos Pos" />,
  items: [
    {
      title: "Enviar documento",
      icon: <Icon src={EmisionIco} alt="Emision Documento" />,
      pathname: "/documentopos",
    },
    {
      title: "Documentos pos emitidos",
      icon: <Icon src={DocEmitidosIco} alt="Documentos Pos Emitidos" />,
      pathname: "/documentosposenviados",
    },
  ],
};
