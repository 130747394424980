import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { getUserData } from "network/Amplify";
import InputPlanes, { Types } from "components/Inputs/InputPlanes";
import { generarPinTransaccion } from "network/Api/Transaccion";
import ModalMensaje from "components/modal/ModalMensaje";
import Input from "components/Inputs/Input";
import { consultarParametroPorNombre } from "network/Api/Parametro";
import { TIPOS_DATOS, validarString } from "utils/ValidarDatos";

class GeneracionPin extends Component {
  state = {
    nmIdPlan: null,
    celular: null,
    dsAliado: null,
    notificacion: "",
    distribuidor: "",
    onClose: () => this.setState({ nOpen: false }),
  };

  componentDidMount() {
    getUserData().then((res) => {
      this.setState({
        dsAliado: res.attributes["custom:custom:userid"],
        distribuidor: res.attributes["custom:clavedistribuidor"],
      });
    });
    consultarParametroPorNombre({
      parametro: "MINIMA_RECARGA_DINERO",
    }).then((res) => this.setState({ compraMinima: res.data }));
  }

  handleChange = ({ target }) => {
    const { id, value } = target;
    switch (id) {
      default:
        this.setState({ [id]: value });
        break;
    }
  };

  handleChangePlan = (event) => {
    this.setState({ nmIdPlan: event.value, tipoPlan: event.tipo });
  };

  generarPin = (event) => {
    event.preventDefault();
    const {
      nmIdPlan,
      celular,
      dsAliado,
      distribuidor,
      nmValor,
      tipoPlan,
    } = this.state;
    this.setState({ loading: true });
    let errores = this.validarFormulario();
    if (errores.length === 0) {
      generarPinTransaccion({
        distribuidor: { cdClave: distribuidor },
        plan: { nmId: nmIdPlan },
        persona: { dsCelular: celular },
        dsAliado,
        nmValor: tipoPlan === "5" ? nmValor : null,
      }).then((res) => {
        this.limpiarFormulario();
        if (res.status)
          this.setState({
            notificacion: "PIN Creado correctamente",
            nOpen: true,
            onClose: () => window.location.reload(),
          });
      });
    } else
      this.setState({
        notificacion: `Errores en validaciones: ${errores.join(", ")}`,
        nOpen: true,
      });
    this.setState({ loading: false });
  };

  validarFormulario = () => {
    const { tipoPlan, celular, nmIdPlan, compraMinima, nmValor } = this.state;
    let errores = [];
    if (celular.length < 10)
      errores.push("El celular tiene un formato invalido");
    if (!nmIdPlan) errores.push("Es obligatorio seleccionar un plan");
    if (tipoPlan === "5")
      if (
        parseFloat(compraMinima) > parseFloat(nmValor) ||
        !validarString(nmValor, TIPOS_DATOS.numeros)
      )
        errores.push(`La compra minima es ${compraMinima}`);
    return errores;
  };

  limpiarFormulario = () =>
    this.setState({ celular: "", nmValor: null, tipoPlan: null });

  render() {
    const {
      celular,
      loading,
      notificacion,
      nOpen,
      distribuidor,
      nmValor,
      tipoPlan,
      onClose,
    } = this.state;
    return (
      <div>
        <ModalMensaje
          open={nOpen}
          content={notificacion}
          handleClose={onClose}
        />
        <form onSubmit={this.generarPin}>
          <Grid
            container
            spacing={8}
            className="justify-content-center align-items-center"
          >
            <Grid item xs={8}>
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <div className="card card-signin">
                  <div className="card-body">
                    <div className="form-row col-md-12 d-flex justify-content-between">
                      <div className="form-group col-md-12 col-xl-8 mb-0">
                        {distribuidor && (
                          <InputPlanes
                            consulta={Types.consultarDistribuidor}
                            distribuidor={distribuidor}
                            handleChange={this.handleChangePlan}
                          />
                        )}
                        <Input
                          placeholder="Celular"
                          id="celular"
                          value={celular}
                          onChange={this.handleChange}
                          style={{ height: "2.5rem", marginTop: "5px" }}
                        />
                        {tipoPlan === "5" && (
                          <Input
                            placeholder="Valor Recarga"
                            id="nmValor"
                            value={nmValor}
                            onChange={this.handleChange}
                            style={{ height: "2.5rem", marginTop: "5px" }}
                          />
                        )}
                      </div>
                      <div className="form-group col-md-12 col-xl-4 mb-0">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn btn-lg btn-primary btn-block text-uppercase"
                        >
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Generar Pin
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default GeneracionPin;
