import * as React from 'react';
import { getTime } from 'utils/DateUtils';
import Button from 'components/atoms/Button';
import EnviarFactura from '@material-ui/icons/AssignmentTurnedIn';
import { registrarDocumento } from 'network/Api/Documento';
import { generarTokenRegistroDoc as generarToken } from 'network/Api/Generic';
import Loader from 'components/Loaders/Loader';

const ButtonEnviar = ({
  openModal,
  limpiarFormulario,
  userAttributes,
  documento,
  envioMultiple = false,
}) => {
  const [loading, setLoading] = React.useState(false);

  const emviarDocumento = async () => {
    setLoading(true);
    const errores = validarDocumento(documento);
    if (!errores.length) {
      const body = await completarDocumento(documento);
      registrarDocumento(body);
      openModal({
        content:
          'Su documento será procesado por nuestro sistema y podrá consultar su estado por la opción Documentos Emitidos de nuestro menú.',
        onCancel: limpiarFormulario,
      });
    } else openModal({ content: `Documento con errores: ${errores.join(' - ')}` });
    setLoading(false);
  };

  const envioMultipleDocumento = () => {
    const { multiplesDocumentos, dsPrefijo } = documento;
    setLoading(true);
    if (Array.isArray(multiplesDocumentos)) {
      const documentosNoEnviados = [];
      multiplesDocumentos.forEach((docMultiple) => {
        const numPref = docMultiple.detalles[0].numeroFactura;
        const dsNumeroFactura = numPref.replace(dsPrefijo, '');
        const docMultiPlusDoc = {
          ...documento,
          ...docMultiple,
          dsNumeroFactura,
          detalles: docMultiple.detalles.map((detalle) => ({
            ...detalle,
            numeroFactura: dsNumeroFactura,
          })),
        };
        const errores = validarDocumento(docMultiPlusDoc);
        if (!errores.length) {
          completarDocumento(docMultiPlusDoc).then((body) => registrarDocumento(body));
        } else {
          documentosNoEnviados.push({
            errores,
            enviado: false,
            documento: docMultiPlusDoc.dsNumeroFactura,
          });
        }
      });
      openModal({
        content:
          documentosNoEnviados.length === 0
            ? 'Sus documentos serán procesado por nuestro sistema y podrá consultarlos su estado por la opción Documentos Emitidos de nuestro menú.'
            : `Error en validaciones en los documentos ${documentosNoEnviados
                .map(({ documento, errores = [] }) => `${documento}: ${errores.join(' - ')}.`)
                .join(', ')}`,
      });
    }
  };

  const validarDocumento = (docValidar) => {
    const errores = [];
    const { facturasReferencia, detalles } = docValidar;
    const addError = (error) => errores.push(error);
    if (!docValidar.ciudadAdquiriente) addError('No se ha seleccionado ningun adquirente');
    if (!docValidar.detalles || !docValidar.detalles.length)
      addError('No se a cargado ningun detalle');
    if (!docValidar.fechaEmision) addError('No se a seleccionado fecha de emision');
    if (!docValidar.fechaVencimiento) addError('No se a seleccionado fecha de vencimiento');
    if (!docValidar.pago || !docValidar.pago.formaPago)
      addError('No se a seleccionado una forma de pago');
    if (!docValidar.pago || !docValidar.pago.medioPago)
      addError('No se a seleccionado un medio de pago');
    if (!docValidar.dsPrefijo) addError('El prefijo del documento es obligatorio');
    if (!docValidar.dsResolucionDian) addError('La resolucion del documento es obligatoria');
    if (docValidar.snConsecutivoAutomatico === 'N')
      if (!docValidar.dsNumeroFactura) addError('El numero del documento es obligatorio'); 
    if (docValidar.tipoDocumentoElectronico === 'NOTA_CREDITO') {
      if (!facturasReferencia || facturasReferencia.length === 0)
        addError('Las facturas referencia son obligatorias');
    }
    validarDetalles({ addError, detalles });
    return errores;
  };

  const validarDetalles = ({ addError, detalles }) => {
    const msg = (cod, num, msg) =>
      `El detalle con el codigo del articulo ${cod} de la factura con numero ${num} ${msg}`;
    detalles.forEach((detalle) => {
      const { cantidad, precioUnitario, valorTotalDescuento, numeroFactura, codigoArticulo } =
        detalle;
      if (cantidad > 0) {
        const valorTotalBruto = cantidad * precioUnitario;
        if (valorTotalBruto - valorTotalDescuento < 1)
          addError(msg(numeroFactura, codigoArticulo, 'tiene un descuento superor al valor bruto'));
      } else addError(msg(numeroFactura, codigoArticulo, 'tiene cantidad negativa'));
    });
  };

  const completarDocumento = async (docCompletar) => {
    const { data } = await generarToken({
      id: userAttributes.codigoEmisor,
      identificacion: userAttributes.identificacionEmisor,
    });
    const {
      dsPrefijo,
      fechaEmision,
      fechaVencimiento,
      ciudadAdquiriente, 
      pago,
      dsNumeroFactura,
      snConsecutivoAutomatico,
    } = docCompletar;
    return {
      ...docCompletar,
      dsNumeroFactura: dsNumeroFactura.length === 0 ? null : dsNumeroFactura,
      dsPrefijo: dsPrefijo !== 'SIN_PREFIJO' ? dsPrefijo : '',
      emisorId: data,
      fechaEmision: `${fechaEmision} ${getTime()}`,
      pago: {
        ...pago,
        fechaVencimientoPago: `${fechaVencimiento} ${getTime()}`,
      },
      ciudadAdquiriente: {
        cdDane: ciudadAdquiriente.departamento.cdDane + ciudadAdquiriente.cdDane,
      },
      snConsecutivoAutomatico: snConsecutivoAutomatico === 'S' ? 'true' : 'false',
    };
  };

  return (
    <div className='form-label-group'>
      <Button
        style={{ backgroundColor: '#92C63E' }}
        onClick={() =>
          openModal({
            content: '¿Enviar documento?',
            onAccept: () => (envioMultiple ? envioMultipleDocumento() : emviarDocumento()),
          })
        }
        disabled={loading}
      >
        {loading && <Loader type='spinner-border-sm' />}
        <EnviarFactura /> ENVIAR
      </Button>
    </div>
  );
};

export default ButtonEnviar;
