import * as React from 'react';
import { connect } from 'react-redux';
import { SetSpinnerModal } from 'reducers/actions/facturaActions';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import Button from 'components/atoms/Button';
import { now } from 'utils/DateUtils';
import Table from 'components/Tablas/GenericaOrdenadaColumna';
import { consultarNotificacionesFechas, guardarNotificacion } from 'network/Api/Notificacion';
import sendIcon from 'icons/send.png';
import { Button as ButtonReenvio } from '@material-ui/core';
import ExpansionPanel from 'components/molecules/ExpansionPanel';
import Modal from 'components/modal/ModalConfirmacion';
import {
  consultarNotificacionesSmsEntreFechas,
  guardarNotificacionSms,
} from 'network/Api/NotificacionSms';

const REG_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const tiposNotificacion = [
  { label: 'Email', value: 'EMAIL' },
  { label: 'SMS', value: 'SMS' },
];

const Notificaciones = ({ setSpinnerModal }) => {
  const [state, setData] = React.useState({
    tipo: 'EMAIL',
    feInicio: now().subtract(7, 'days').format('YYYY-MM-DD'),
    feFin: now().format('YYYY-MM-DD'),
    notificaciones: [],
    notificacionesFilter: [],
  });

  React.useEffect(() => {
    setState({ notificaciones: [], notificacionesFilter: [] });
    consultarNotificaciones();
  }, [state.tipo]);

  const consultarNotificaciones = async () => {
    const { feInicio, feFin, tipo } = state;
    setSpinnerModal(true);
    const body = { feInicio, feFin };
    const { data } =
      tipo === 'SMS'
        ? await consultarNotificacionesSmsEntreFechas(body)
        : await consultarNotificacionesFechas(body);
    setState({ notificaciones: data, notificacionesFilter: data });
    setSpinnerModal(false);
  };

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  const handleChange = ({ target }) => {
    const { name, value } = target;
    switch (name) {
      case 'dsEmailDestinatario':
        handleEmail(value);
        break;
      default:
        setState({ [name]: value });
        break;
    }
  };

  const handleEmail = (dsEmailDestinatario) => {
    setState({ dsEmailDestinatario });
    const emails = dsEmailDestinatario.includes(',')
      ? dsEmailDestinatario.split(',')
      : dsEmailDestinatario.split(';');
    let invalidEmails = '';
    emails.forEach((emailSelected, index) => {
      if (!REG_EMAIL.test(emailSelected)) invalidEmails = `Error correo ${index + 1}`;
    });
    setState({ error: invalidEmails.toString() });
  };

  const guardarNotificacionEmail = async () => {
    const { notificacion, dsEmailDestinatario, error } = state;
    if (!error) {
      setSpinnerModal(true);
      const { dsAsunto, dsCuerpo, dsEmailRemitente } = notificacion;
      await guardarNotificacion({ dsAsunto, dsCuerpo, dsEmailDestinatario, dsEmailRemitente });
      closeModal();
      consultarNotificaciones();
      setState({ dsEmailDestinatario: '', notificacion: undefined });
    }
  };

  const enviarNotificacionSms = async () => {
    const { notificacion, dsNumeroDestinatario, error } = state;
    if (!error) {
      setSpinnerModal(true);
      const { dsSms } = notificacion;
      await guardarNotificacionSms({ dsSms, dsNumeroDestinatario });
      closeModal();
      consultarNotificaciones();
      setState({ dsNumeroDestinatario: '', notificacion: undefined });
    }
  };

  const closeModal = () =>
    setState({ openModal: false, dsEmailDestinatario: undefined, error: undefined });

  const openModal = () => setState({ openModal: true });

  const handleFilter = ({ target }) => {
    const { value } = target;
    const { notificaciones, tipo } = state;
    setState({
      filter: value,
      notificacionesFilter: notificaciones.filter((n) =>
        (tipo === 'SMS' ? n.dsNumeroDestinatario : n.dsEmailDestinatario)
          .toLowerCase()
          .includes(value.toLowerCase()),
      ),
    });
  };

  return (
    <div>
      <div className='form-row col-xl-12'>
        <div className='form-group col-xl-1'>
          <Select
            name='tipo'
            label='Tipo Notificacion'
            options={tiposNotificacion}
            onChange={({ value }) => setState({ tipo: value })}
            value={tiposNotificacion.find(({ value }) => value === state.tipo)}
          />
        </div>
        <div className='form-group col-xl-1'>
          <Input
            label='Fecha de Inicio'
            type='date'
            onChange={handleChange}
            name='feInicio'
            value={state.feInicio}
            max={state.feFin}
          />
        </div>
        <div className='form-group col-xl-1'>
          <Input
            label='Fecha de Fin'
            type='date'
            onChange={handleChange}
            name='feFin'
            value={state.feFin}
            min={state.feInicio}
          />
        </div>
        <div className='form-group col-xl-1'>
          <Button onClick={consultarNotificaciones}>Consultar</Button>
        </div>
        <div className='form-group col-xl-5' />
        <div className='form-group col-xl-3'>
          <Input
            label='Filtrar por destinatario'
            name='filter'
            onChange={handleFilter}
            value={state.filter}
          />
        </div>
      </div>
      <div className='form-row col-xl-12'>
        <div className='form-group col-xl-12'>
          <Table
            loading={false}
            headers={
              state.tipo === 'EMAIL'
                ? [
                    { label: 'Cuerpo Email', id: 'dsEmail', disablePadding: true },
                    {
                      label: 'Email Destinatario',
                      id: 'dsEmailDestinatario',
                      disablePadding: true,
                    },
                    { label: 'Estado', id: 'dsEstado', disablePadding: true },
                    { label: 'Fecha envio', id: 'feEnvio', disablePadding: true },
                    { label: 'Reenviar correo', id: 'btnReenvio', disablePadding: true },
                  ]
                : [
                    { label: 'SMS', id: 'dsSms', disablePadding: true },
                    { label: 'Destinatario', id: 'dsNumeroDestinatario', disablePadding: true },
                    { label: 'Estado', id: 'cdEstado', disablePadding: true },
                    { label: 'Fecha proceso', id: 'feProceso', disablePadding: true },
                    { label: 'Reenviar SMS', id: 'btnReenvio', disablePadding: true },
                  ]
            }
            keys={
              state.tipo === 'EMAIL'
                ? ['dsEmail', 'dsEmailDestinatario', 'dsEstado', 'feEnvio', 'btnReenvio']
                : ['dsSms', 'dsNumeroDestinatario', 'cdEstado', 'feProceso', 'btnReenvio']
            }
            detalles={state.notificacionesFilter.map((n) => ({
              ...n,
              dsEmail: state.tipo === 'EMAIL' && (
                <ExpansionPanel
                  title={n.dsAsunto}
                  body={
                    <div
                      style={{ padding: '0px 24px 0px 24px' }}
                      dangerouslySetInnerHTML={{ __html: n.dsCuerpo }}
                    />
                  }
                />
              ),
              dsSms: state.tipo === 'SMS' && (
                <ExpansionPanel
                  title={n.dsNumeroDestinatario}
                  body={
                    <div
                      style={{ padding: '0px 24px 0px 24px' }}
                      dangerouslySetInnerHTML={{ __html: n.dsSms }}
                    />
                  }
                />
              ),
              btnReenvio: (
                <ButtonReenvio
                  onClick={() => {
                    openModal();
                    setState({ notificacion: n });
                  }}
                >
                  <img src={sendIcon} width='35' height='35' alt='Reenvio Icon' />
                </ButtonReenvio>
              ),
            }))}
          />
        </div>
      </div>
      <Modal
        open={state.openModal}
        content={
          <Input
            label={state.tipo === 'EMAIL' ? 'Email' : 'Numero'}
            name={state.tipo === 'EMAIL' ? 'dsEmailDestinatario' : 'dsNumeroDestinatario'}
            onChange={handleChange}
            value={state.tipo === 'EMAIL' ? state.dsEmailDestinatario : state.dsNumeroDestinatario}
            error={state.error}
          />
        }
        onAccept={state.tipo === 'EMAIL' ? guardarNotificacionEmail : enviarNotificacionSms}
        onCancel={closeModal}
        fullWidth={true}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(null, mapDispatchToProps)(Notificaciones);
