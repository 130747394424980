import { serviceRoutes, get, post } from "./index";

export const consultarJsonDocumentoExpress = async ({ serialReferencia, adquirente }) =>
  await get(serviceRoutes.consultarJsonDocumentoExpress, `?serialReferencia=${serialReferencia}&adquirente=${adquirente}`);

export const registrarDocumentoExpress = async ({ serial, documento }) =>
  await post(serviceRoutes.registrarDocumentoExpress + "?serial=" + serial, documento);

export const validarConsultaDocumentoExpress = async ({ serial, identificacion }) =>
  await get(serviceRoutes.validarConsultaDocumentoExpress, `?serial=${serial}&identificacion=${identificacion}`);
