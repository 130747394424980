import { serviceRoutes, post, get } from "./index";

export async function actualizarVerificado(body) {
  return await post(serviceRoutes.actualizarVerificadoFirma, body);
}

export async function consultarPorEmisor({ nmIdEmisor }) {
  return await get(serviceRoutes.consultarFirmaPorEmisor, `?id=${nmIdEmisor}`);
}

export async function registrarFirma(body) {
  return await post(serviceRoutes.registrarFirma, body);
}

export async function actualizarFirma(body) {
  return await post(serviceRoutes.actualizarFirma, body);
}

export async function notificarFirma(body) {
  return await post(serviceRoutes.notificarFirma, body);
}

export const consultarFirmasVigentes = async ({ emisorId }) =>
  await post(serviceRoutes.consultarFirmasVigentes, `?emisorId=${emisorId}`);
