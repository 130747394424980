import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import DatosDocumento from 'components/Documento/DatosDocumento';
import DetallesDocumento from 'components/Documento/DetallesDocumento';
import DatosAdquirente from 'components/Documento/DatosCliente';
import DatosAdicionalesDocumento from 'components/Documento/DatosAdicionalesDocumento';
import AgregarDetallesExcel from 'components/Documento/AgregarDetallesExcel';
import { Grid, Paper } from '@material-ui/core';
import { styles } from './styles';
import { now } from 'utils/DateUtils';
import Modal from 'components/modal/ModalConfirmacion';
import ButtonEnviar from './ButtonEnviar';
import ButtonLimpiar from './ButtonLimpiar';
import Button from 'components/atoms/Button';
import Previsualisacion from 'components/templates/PrevisualizacionDocumento';
import Visualizar from 'static/images/visualizar.png';
import Icon from 'components/menu/Icon';

const UnicoEnvio = ({ userAttributes, classes }) => {
  const [modalState, setModalState] = React.useState({
    onCancel: () => setModalState((ms) => ({ ...ms, open: false })),
  });
  const [documento, setDataDocumento] = React.useState({
    tipoDocumentoElectronico: 'VENTA',
    moneda: 'COP',
    fechaEmision: now().format('YYYY-MM-DD'),
    fechaVencimiento: now().add(1, 'month').format('YYYY-MM-DD'),
    pago: {
      formaPago: 'CONTADO',
      medioPago: 'OTRO',
    },
    tipoOperacion: 'ESTANDAR',
    snDistribucionFisica: 'N',
  });

  const setDocumento = (doc) => setDataDocumento((d) => ({ ...d, ...doc }));

  const openModal = ({
    content,
    onAccept,
    onCancel = () => setModalState((ms) => ({ ...ms, open: false })),
    fullWidth = false,
    title,
  }) => setModalState({ open: true, content, onAccept, onCancel, fullWidth, title });

  const limpiarFormulario = () => window.location.reload();

  const openPreview = (preview) => openModal({ content: preview, fullWidth: true, title: null });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <DatosDocumento
            setDocumento={setDocumento}
            documento={documento}
            emisor={userAttributes.codigoEmisor}
            isExcel={true}
            disableTipoDocumento={false}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper}>
            <DatosAdquirente
              emisor={userAttributes.codigoEmisor}
              setDocumento={setDocumento}
              documento={documento}
              mostrarDetalles={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <AgregarDetallesExcel
              setDocumento={setDocumento}
              emisor={userAttributes.codigoEmisor}
              detalles={documento.detalles}
              openModal={openModal}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <DatosAdicionalesDocumento documento={documento} setDocumento={setDocumento} />
          </Paper>
        </Grid>
      </Grid>
      <DetallesDocumento
        emisor={userAttributes.codigoEmisor}
        detalles={documento.detalles}
        setDocumento={setDocumento}
        showDiaIva={false}
        showTotal={false}
      />
      <Grid item xs={12}>
        <div className='form-row col-md-12 d-flex justify-content-end pt-3'>
          <div className='form-group col-xs-6'>
            <Button
              style={{ backgroundColor: '#92C63E' }}
              onClick={() =>
                openPreview(<Previsualisacion documento={documento} emisor={userAttributes} />)
              }
            >
              <Icon style={{ margin: '0px 5px' }} src={Visualizar} alt='Previsualisar' />
              Previsualisar
            </Button>
          </div>
          <div className='form-group col-xs-6'>
            <ButtonLimpiar openModal={openModal} limpiarFormulario={limpiarFormulario} />
          </div>
          <div className='form-group col-xs-6'>
            <ButtonEnviar
              documento={documento}
              openModal={openModal}
              limpiarFormulario={limpiarFormulario}
              userAttributes={userAttributes}
            />
          </div>
        </div>
      </Grid>
      <Modal
        open={modalState.open}
        content={modalState.content}
        onAccept={modalState.onAccept}
        onCancel={modalState.onCancel}
        fullWidth={modalState.fullWidth}
        title={modalState.title}
        maxWidth='xl'
      />
    </form>
  );
};

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

export default connect(mapStateToProps)(withStyles(styles)(UnicoEnvio));
