export const exportTableToExcel = (tableId, nombreFile) => {
  let filename = nombreFile;
  let downloadLink;
  let dataType = 'application/vnd.ms-excel';
  let tableSelect = document.getElementById(tableId);
  let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
  filename = filename ? filename + '.xls' : 'excel_data.xls';
  downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  if (navigator.msSaveOrOpenBlob) {
    let blob = new Blob(['ufeff', tableHTML], { type: dataType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    downloadLink.download = filename;
    downloadLink.click();
  }
};
