import React, { useState, useEffect } from "react";
import Select from "components/Inputs/Select";
import TablaDeducciones from "components/Tablas/TablaDeducciones";
import * as ApiDeduccion from "network/Api/Deduccion";
import { tipoDeducciones } from "static/enums/Deducciones";
import ModalMensaje from "components/modal/ModalConfirmacion";

export default function deduccion({ empleado, emisor }) {
  const [tipo, setTipo] = useState(tipoDeducciones[0]);
  const [deducciones, setDeducciones] = useState([]);
  const [modal, setModal] = useState({});

  const guardarDeduccion = (body, resetForm) => {
    const puedeEnviar = validarUnicos(body);
    if (!puedeEnviar) {
      ApiDeduccion.guardarDeduccion(body).then((res) => {
        if (res.status === 200) {
          resetForm();
          consultarDeducciones();
        }
      });
    } else {
      setModal({
        texto: "No se puede agregar este deduccion mas de una vez",
        open: true,
      });
    }
  };

  const consultarDeducciones = () => {
    if (empleado)
      ApiDeduccion.getDeduccionesByEmpleado(empleado.nmId).then((res) => {
        if (res.status === 200) {
          setDeducciones(res.data);
        }
      });
    else setDeducciones([]);
  };

  useEffect(() => consultarDeducciones(), [empleado]);

  const eliminarItemTabla = (deduccion) => {
    ApiDeduccion.eliminarDeduccion(deduccion).then((_) =>
      consultarDeducciones()
    );
  };

  const validarUnicos = (nuevo) => {
    const unicos = deducciones.filter((d) => {
      const tipo = d.dsTipo;
      return (
        tipo === "SALUD" ||
        tipo === "PENSION" ||
        tipo === "FONDO_SP" ||
        tipo === "PENSION_VOLUNTARIA" ||
        tipo === "AFC" ||
        tipo === "COOPERATIVA" ||
        tipo === "EMBARGO_FISCAL" ||
        tipo === "PLAN_COMPLEMENTARIO" ||
        tipo === "EDUCACION" ||
        tipo === "REINTEGRO" ||
        tipo === "DEUDA"
      );
    });
    return unicos.filter((d) => d.dsTipo === nuevo.dsTipo)[0];
  };

  return (
    <div style={{ padding: "10px" }}>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-4">
          <Select
            label="Selecciona el tipo de deduccion"
            options={tipoDeducciones}
            value={tipo}
            onChange={setTipo}
          />
        </div>
      </div>
      {tipo.component({ emisor, empleado }, guardarDeduccion)}
      <TablaDeducciones
        detalles={deducciones}
        eliminarItem={eliminarItemTabla}
      />
      <ModalMensaje
        open={modal.open || false}
        content={modal.texto || ""}
        onAccept={() => setModal((s) => ({ ...s, open: false }))}
      />
    </div>
  );
}
