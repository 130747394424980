/* eslint-disable */
import React, { Component, Fragment } from "react";
import LabelTitulos from "components/layout/labelTitulos";
import LogoPortal from "static/images/logo_portal_factura.png";
import "screens/login/styles.css";
import { SetSpinnerModal } from "reducers/actions/facturaActions";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { GruposUsuario } from "static/enums/GrupoUsuario";
import InputDistribuidores from "components/Inputs/InputDistribuidores";
import { registrarUsuario } from "network/Api/Auth";
import { consultarPersonas } from "network/Api/Persona";

const Styles = {
  rootLogo: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "3em",
  },
  logo: { maxHeight: "inherit" },
};

const regEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Login extends Component {
  state = {
    identificaciones: [],
    email: "",
    celular: "",
    nombresEmisor: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    identificacionEmisor: "",
    mensaje: "",
    grupoUsuario: "",
    userName: "",
    claveDistribuidor: "",
    grupoUsuario: "Emisor",
  };

  componentDidMount() {
    this.handleConsultarEmisores();
  }

  handleConsultarEmisores = () => {
    const { setSpinnerModal } = this.props;
    setSpinnerModal(true);
    consultarPersonas().then((res) =>
      this.setState(
        {
          identificaciones: res.data.map((id) => id.dsIdentificacion),
        },
        () => setSpinnerModal(false)
      )
    );
  };

  handleChange = (event) => {
    let label = event.target.id;
    let value = event.target.value;
    switch (label) {
      case "userName":
        this.setState({ [label]: value.toUpperCase() });
        break;
      default:
        this.setState({ [label]: value });
        break;
    }
  };

  handleChangeSelect = (event, label) =>
    this.setState({ [label]: event.value });

  handleRegistrarUsuario = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let responseValidation = this.validarBody();
    if (!responseValidation.errors.length)
      registrarUsuario(responseValidation.body).then((res) => {
        if (res.data === "USUARIO_CREADO") {
          this.resetForm();
          this.setState({
            mensaje: "Usuario creado correctamente",
            loading: false,
          });
        } else {
          this.setState({
            mensaje:
              res.data === "USUARIO_EXISTE"
                ? "Usuario ya existe"
                : "Error creando el usuario",
            loading: false,
          });
        }
      });
    else
      this.setState({
        mensaje: `Error en las siguientes validaciones del formulario: <br> ${responseValidation.errors.join(
          "<br>"
        )}`,
        loading: false,
      });
  };

  validarBody = () => {
    const {
      email,
      celular,
      nombresEmisor,
      identificacionEmisor,
      grupoUsuario,
      userName,
      claveDistribuidor,
    } = this.state;
    let errors = [];
    if (!regEmail.test(email)) errors.push("Email invalido");
    if (celular.length < 1) errors.push("El celular es obligatorio");
    if (nombresEmisor.length < 1) errors.push("El nombre es obligatorio");
    if (identificacionEmisor.length < 1)
      errors.push("La identificacion es obligatoria");
    if (grupoUsuario.length < 1)
      errors.push("El grupo del usuario es obligatorio");
    if (userName.length < 1) errors.push("El nombre de usuario es obligatorio");
    if (grupoUsuario === "Venta" && claveDistribuidor.length < 1)
      errors.push("La clave del distribuidor es obligatoria");
    return {
      body: {
        email,
        phone: `+57${celular}`,
        fullName: nombresEmisor,
        userId: identificacionEmisor,
        grupoUsuario,
        username: userName,
        claveDistribuidor,
      },
      errors,
    };
  };

  resetForm = () =>
    this.setState({
      email: "",
      celular: "",
      nombresEmisor: "",
      userName: "",
      identificacionEmisor: "",
    });

  render() {
    const {
      identificaciones,
      email,
      celular,
      nombresEmisor,
      mensaje,
      loading,
      userName,
      grupoUsuario,
      identificacionEmisor,
    } = this.state;
    return (
      <Fragment>
        <div className="body">
          <div className="container">
            <div className="row">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="card card-signin my-5">
                  <div className="card-body">
                    <div style={Styles.rootLogo}>
                      <img style={Styles.logo} src={LogoPortal} />
                    </div>
                    <LabelTitulos
                      texto="portal factura"
                      className="form-control"
                    />
                    <form
                      className="form-signin"
                      autoComplete="off"
                      onSubmit={this.handleRegistrarUsuario}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          textAlign: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <small className="text-muted">
                          Registrar nuevo usuario
                        </small>
                      </div>
                      <div className="form-group">
                        <label for="userName">Usuario</label>
                        <input
                          type="text"
                          id="userName"
                          value={userName}
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label for="nombresEmisor">Razón social</label>
                        <input
                          type="text"
                          id="nombresEmisor"
                          value={nombresEmisor}
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label for="celular">Número celular</label>
                        <input
                          type="text"
                          id="celular"
                          value={celular}
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      {grupoUsuario !== "VENTAS" ? (
                        <div className="form-group">
                          <label>Cédula ó Nit</label>
                          <Select
                            options={identificaciones.map((id) => ({
                              value: id,
                              label: id,
                            }))}
                            onChange={(e) =>
                              this.handleChangeSelect(e, "identificacionEmisor")
                            }
                          />
                        </div>
                      ) : (
                        <div className="form-group">
                          <label htmlFor="identificacionEmisor">
                            Identificador del Usuario
                          </label>
                          <input
                            type="text"
                            id="identificacionEmisor"
                            value={identificacionEmisor}
                            className="form-control"
                            onChange={this.handleChange}
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label for="grupo">Grupo de usuario</label>
                        <Select
                          id="grupo"
                          defaultValue={GruposUsuario.filter(
                            (gu) => gu.nombre === "Emisor"
                          ).map((gu) => ({
                            value: gu.value,
                            label: gu.nombre,
                          }))}
                          options={GruposUsuario.map((gu) => ({
                            value: gu.value,
                            label: gu.nombre,
                          }))}
                          onChange={(e) =>
                            this.handleChangeSelect(e, "grupoUsuario")
                          }
                        />
                      </div>
                      {grupoUsuario === "VENTAS" && (
                        <div className="form-group">
                          <label for="grupo">Distribuidor</label>
                          <InputDistribuidores
                            stateKey="claveDistribuidor"
                            handleChange={this.handleChangeSelect}
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label for="email">E-Mail</label>
                        <input
                          type="email"
                          id="email"
                          value={email}
                          className="form-control"
                          onChange={this.handleChange}
                        />
                        <small className="text-muted">
                          ejemplo@dominio.com
                        </small>
                      </div>
                      <button
                        className="btn btn-lg btn-primary btn-block text-uppercase"
                        type="submit"
                      >
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        Registrar usuario
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog open={mensaje}>
          <DialogTitle>PortalFactura</DialogTitle>
          <DialogContent dangerouslySetInnerHTML={{ __html: mensaje }} />
          <DialogActions>
            <Button onClick={() => this.setState({ mensaje: "" })}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  loading: state.factura.loading,
  userGrupo: state.factura.userGrupo,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
