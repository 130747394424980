import { serviceRoutes, post, get, put } from "./index";

export const consultarAyudasActivas = async () =>
  await get(serviceRoutes.consultarAyudasActivas, "");

export const consultarAyudas = async () =>
  await get(serviceRoutes.consultarAyudas, "");

export const guardarAyuda = async (body) =>
  await post(serviceRoutes.guardarAyuda, body);

export const actualizarAyuda = async (body) =>
  await put(serviceRoutes.actualizarAyuda, body);
