import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function cesantia({ baseBody, guardarDevengado, xlCol = "2" }) {
  const [nmPago, setNmPago] = useState();
  const [nmPorcentaje, setNmPorcentaje] = useState();
  const [nmPagoIntereses, setNmPagoIntereses] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `CESANTIA`,
    nmPago,
    nmPorcentaje,
    nmPagoIntereses,
  };

  const guardarCesantia = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmPago();
    setNmPorcentaje();
    setNmPagoIntereses();
  };

  return (
    <form onSubmit={guardarCesantia}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor del pago *"
            value={nmPago || ""}
            onChange={(e) => setNmPago(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Pago de la Cesantia otorgada por Ley."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Porcentaje *"
            value={nmPorcentaje || ""}
            onChange={(e) => setNmPorcentaje(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Porcentaje que corresponde al Interes de Cesantia de Ley"
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor de los intereses de la cesantia *"
            value={nmPagoIntereses || ""}
            onChange={(e) => setNmPagoIntereses(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Pago de los Intereses de Cesantia otorgada por Ley."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmPago && body.nmPagoIntereses < 0)
    errores.push("El valor del pago es obligatorio");
  if (!body.nmPagoIntereses && body.nmPagoIntereses < 0)
    errores.push("El valor de los intereses de la cesantia es obligatorio");
  if (
    !body.nmPorcentaje ||
    !(body.nmPorcentaje > 0 && body.nmPorcentaje <= 100)
  )
    errores.push("El porcentaje es obligatorio y debe estar entre 1 y 100");
  return errores;
}
