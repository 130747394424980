import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function bonificacion({
  baseBody,
  guardarDevengado,
  xlCol = "2",
}) {
  const [nmBonificacionS, setBonificacionS] = useState();
  const [nmBonificacionNS, setBonificacionNS] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `BONIFICACION`,
    nmBonificacionS,
    nmBonificacionNS,
  };

  const guardarBonificaciones = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setBonificacionS();
    setBonificacionNS();
  };

  return (
    <form onSubmit={guardarBonificaciones}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago por Bonificación Salarial"
            value={nmBonificacionS || ""}
            onChange={(e) => setBonificacionS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Son valores pagados al trabajador en forma de incentivo o recompensa por la contraprestación directa del servicio."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago por Bonificación No Salarial"
            value={nmBonificacionNS || ""}
            onChange={(e) => setBonificacionNS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Son valores de incentivos pagados al trabajador de forma ocasional y por mera liberalidad o los pactados entre las partes de forma expresa como pago no salarial."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmBonificacionS && !body.nmBonificacionNS)
    errores.push(
      "No se esta enviando ningun campo o se esta enviando en negativo o cero"
    );
  return errores;
}
