import axios from 'axios';

const url = process.env.REACT_APP_DOMINA_URL;

const serviceRoutes = {
  descargarFactura: 'descargaFacturaService',
  reenviarCorreo: 'reenviarCorreoService',
  creacionEmisor: 'CreacionEmisoresService/',
  registrarResolucion: 'RegistrarResoluciones',
};

function getHeaders(token) {
  return {
    'Content-Type': 'application/json',
    Authorization: token,
  };
}

export const descargarFactura = async (body, token) =>
  await fetch(`${url}${serviceRoutes.descargarFactura}`, {
    method: 'POST',
    headers: getHeaders(token),
    body: JSON.stringify(body),
  });

export const reenviarDocumento = async (body, token) =>
  await fetch(`${url}${serviceRoutes.reenviarCorreo}`, {
    method: 'POST',
    headers: getHeaders(token),
    body: JSON.stringify(body),
  }).then((response) => response.json());

export const registrarResolucionDomina = async (body, token) =>
  await fetch(`${url}${serviceRoutes.registrarResolucion}/${body}`, {
    method: 'GET',
    headers: { Authorization: token },
  }).then((response) => response.json());

export async function RegistrarResolucionesDomina(body, setLoading, token) {
  let result;
  setLoading(true);
  var headers = {
    'Content-Type': 'application/json',
    Authorization: token,
  };
  await axios
    .get(`${url}RegistrarResoluciones/${body}`, {
      mode: 'no-cors',
      headers: headers,
    })
    .then((res) => {
      result = { data: res.data, status: res.status };
      setLoading(false);
    })
    .catch((err) => {
      if (err.response) {
        result = { data: err.response.data, status: err.response.status };
      } else if (err.request) {
        result = { data: err.message, status: err.request.status };
      } else {
        result = { data: err.message, status: 0 };
      }
      setLoading(false);
    });
  return result;
}
