import React, { useMemo } from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import SelectProductos from 'components/organisms/SelectProductos';
import Input from 'components/molecules/Input';
import CheckBox from 'components/molecules/Checkbox';
import TextArea from 'components/molecules/TextArea';
import SelectUnidadMedida from 'components/organisms/SelectUnidadMedida';
import SelectPrecioReferencia from 'components/organisms/SelectPrecioReferencia';
import Typography from 'components/atoms/Typography';
import FormularioDescuento from './FormularioDescuento';
import FormularioModetadoraCopago from './FormularioModetadoraCopago';
import FormularioExportacion from './FormularioExportacion';
import useDetalles from 'hooks/useDetalles';
import Modal from 'components/molecules/Modal';
import FormularioOtroImpuesto from 'components/templates/FormularioOtroImpuesto';

function AgregarDetalle({
  emisor,
  disabledInputs = true,
  isExportacion,
  addDetalle,
  width,
  open,
  handleClose,
}) {
  const {
    reset,
    detalle,
    errores,
    setDetalle,
    validarCampos,
    getDetalleFinal,
    addCargoDescuento,
    handleChangeInput,
    handleChangeObsequio,
    removeCargoDescuento,
    handleChangeDiaSinIva,
    handleChangePrecioUnitario,
  } = useDetalles(isExportacion);

  const handleChangeProducto = (_, producto) => {
    const {
      nmId,
      nmValorUnitario,
      snIvaIncluido,
      dsDescripcion,
      nmUnidadMedida,
      nmPorcentajeIva,
      nmPorcentajeInc,
      dsCodigo,
    } = producto;
    reset();
    setDetalle({
      idProducto: nmId,
      cantidad: 1,
      snIvaIncluido,
      descripcionArticulo: dsDescripcion,
      unidadMedida: nmUnidadMedida,
      porcentajeIva: nmPorcentajeIva,
      porcentajeConsumo: nmPorcentajeInc,
      codigoArticulo: dsCodigo,
    });
    handleChangePrecioUnitario(nmValorUnitario, nmPorcentajeIva, snIvaIncluido);
  };

  const valorBase = useMemo(() => {
    const { precioUnitario = 0, cantidad = 0 } = detalle;
    return precioUnitario * cantidad;
  }, [detalle.precioUnitario, detalle.cantidad]);

  const onSubmit = (e) => {
    e.preventDefault();
    const errores = validarCampos();
    if (Object.keys(errores).length === 0) {
      addDetalle(getDetalleFinal());
      close();
    }
  };

  const close = () => {
    handleClose();
    reset();
  };

  const otroImpuesto = useMemo(() => {
    const { otroImpuesto, otroImpuestoValor, otroImpuestoVolumen, otroImpuestoPorcentaje } =
      detalle;
    return { otroImpuesto, otroImpuestoValor, otroImpuestoVolumen, otroImpuestoPorcentaje };
  }, [
    detalle.otroImpuesto,
    detalle.otroImpuestoValor,
    detalle.otroImpuestoVolumen,
    detalle.otroImpuestoPorcentaje,
  ]);

  return (
    <Modal
      open={open}
      maxWidth='md'
      scroll='paper'
      onAccept={onSubmit}
      onAcceptText='Agregar'
      onAcceptProps={{ variant: 'contained' }}
      onClose={close}
      onCancel={close}
      fullWidth={!isWidthUp('xl', width)}
      fullScreen={!isWidthUp('md', width)}
      title='Agregar nuevo ítem a la factura'
      content={
        <form onSubmit={onSubmit}>
          <div className='form-row col-12 d-flex align-items-center'>
            <SelectProductos
              require
              label='Producto'
              value={detalle.idProducto}
              className='form-group col-md-5'
              error={errores.codigoArticulo}
              idEmisor={emisor.codigoEmisor}
              onChange={handleChangeProducto}
            />
            <div className='form-row col-md-7 d-flex align-items-center'>
              <Input
                require
                name='cantidad'
                label='Cantidad'
                error={errores.cantidad}
                value={detalle.cantidad}
                className='form-group col-md col-6'
                onChange={handleChangeInput}
                validacion='only-num-decimals'
              />
              <Input
                require
                name='precioUnitario'
                label='Valor Unitario'
                className='form-group col-md col-6'
                onChange={({ target }) => {
                  const { value } = target;
                  handleChangePrecioUnitario(value, detalle.porcentajeIva, detalle.snIvaIncluido);
                }}
                disabled={detalle.esObsequio || detalle.snIvaIncluido}
                validacion='only-num-decimals'
                value={detalle.precioUnitario}
                error={errores.precioUnitario}
                small={`Valor mas IVA: ${detalle.valorMasIva}`}
              />
              <Input
                require
                name='valorMasIva'
                label='Val. Unit.+Iva'
                className='form-group col-md col-6'
                value={detalle.valorMasIva}
                onChange={({ target }) => {
                  const { value } = target;
                  handleChangePrecioUnitario(value, detalle.porcentajeIva, detalle.snIvaIncluido);
                }}
                disabled={detalle.esObsequio || !detalle.snIvaIncluido}
                validacion='only-num-decimals'
              />
              <CheckBox
                disabled
                label='Iva Incluido'
                className='form-group col-md col-6'
                checked={detalle.snIvaIncluido}
              />
            </div>
          </div>
          <div className='form-row col-12'>
            <TextArea
              require
              maxLength='300'
              label='Descripción'
              name='descripcionArticulo'
              validacion='only-num-text'
              onChange={handleChangeInput}
              className='form-group col-12'
              error={errores.descripcionArticulo}
              value={detalle.descripcionArticulo}
            />
          </div>
          <div className='form-row col-12'>
            <SelectUnidadMedida
              require
              name='unidadMedida'
              disabled={disabledInputs}
              className='form-group col-4'
              value={detalle.unidadMedida}
              error={errores.unidadMedida}
            />
            <Input
              name='porcentajeIva'
              label='Porcentaje IVA'
              disabled={disabledInputs}
              className='form-group col-4'
              value={detalle.porcentajeIva}
            />
            <Input
              label='Porcentaje INC'
              name='porcentajeConsumo'
              disabled={disabledInputs}
              className='form-group col-4'
              value={detalle.porcentajeConsumo}
            />
          </div>
          <FormularioOtroImpuesto
            className='form-row col-12'
            otroImpuesto={otroImpuesto}
            handleChangeInput={handleChangeInput}
            handleChangeSelect={({ value }) => setDetalle({ otroImpuesto: value })}
          />
          <div className='form-row col-12'>
            <SelectPrecioReferencia
              name='tipoPrecioReferencia'
              className='form-group col-4'
              value={detalle.tipoPrecioReferencia}
              error={errores.tipoPrecioReferencia}
              require={Boolean(detalle.esObsequio)}
              disabled={!Boolean(detalle.esObsequio)}
              onChange={({ value }) => setDetalle({ tipoPrecioReferencia: value })}
            />
            <Input
              name='precioReferencia'
              label='Precio referencia'
              className='form-group col-4'
              onChange={handleChangeInput}
              value={detalle.precioReferencia}
              error={errores.precioReferencia}
              require={Boolean(detalle.esObsequio)}
              disabled={!Boolean(detalle.esObsequio)}
            />
            <div className='form-group col-4'>
              <Typography>Seleccione si es:</Typography>
              <CheckBox
                label='Obsequio'
                name='esObsequio'
                checked={detalle.esObsequio}
                onChange={handleChangeObsequio}
              />
              <CheckBox
                name='esSinIva'
                label='Día sin IVA'
                checked={detalle.esSinIva}
                onChange={handleChangeDiaSinIva}
              />
            </div>
          </div>
          {isExportacion && (
            <FormularioExportacion
              detalle={detalle}
              errores={errores}
              setDetalle={setDetalle}
              className='form-row col-12'
              handleChangeInput={handleChangeInput}
            />
          )}
          <FormularioDescuento
            valorBase={valorBase}
            className='form-row col-12'
            addCargoDescuento={addCargoDescuento}
            cargosDescuentos={detalle.cargosDescuentos}
            removeCargoDescuento={removeCargoDescuento}
          />
          <FormularioModetadoraCopago
            valorBase={valorBase}
            className='form-row col-12'
            addCargoDescuento={addCargoDescuento}
            cargosDescuentos={detalle.cargosDescuentos}
            removeCargoDescuento={removeCargoDescuento}
          />
        </form>
      }
    />
  );
}

export default withWidth()(AgregarDetalle);
