import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, List, Divider, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";

class DrawerMenu extends Component {
  render() {
    const {
      handleDrawerClose,
      open,
      userAttributes,
      listaMenu,
      theme,
      closeSession,
      drawer,
      drawerOpen,
      drawerClose,
      toolbar,
    } = this.props;
    return (
      <Drawer
        onClose={handleDrawerClose}
        className={
          (classNames(drawer, {
            [drawerOpen]: open,
            [drawerClose]: !open,
          }),
          "styleDrawer")
        }
        classes={{
          paper: classNames({
            [drawerOpen]: open,
            [drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={toolbar}>
          <MenuItem style={{ fontSize: "10px" }}>
            {userAttributes["custom:custom:username"]}
          </MenuItem>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{listaMenu}</List>
        <Divider />
        {closeSession}
      </Drawer>
    );
  }
}

export default withStyles({ withTheme: true })(DrawerMenu);
