import * as React from 'react';
import { connect } from 'react-redux';
import Select from 'components/Inputs/Select';
import { now, dateString, DATE_FORMAT } from 'utils/DateUtils';
import { withStyles } from '@material-ui/core/styles';
import { consultarHistorialCajaDinero } from 'network/Api/HistorialSaldoDinero';
import Table from 'components/Tablas/TablaGenerica';
import { moneyFormat } from 'utils/TextFormattingUtils';
import { exportTableToExcel } from 'utils/ExportarExcelUtil';
import { Button } from '@material-ui/core';
import ExportExcelIco from 'static/images/excel.png';
import FiltroEntreFechas from 'components/organisms/FiltroEntreFechas';
import useFiltroFechas from 'hooks/useFiltroFechas';

const style = () => ({
  filter: { maxWidth: '100%', display: 'flex', justifyContent: 'space-between' },
});

const options = [
  { label: 'Transferencia', value: 'TRANSFERENCIA' },
  { label: 'Cashback', value: 'CASHBACK' },
  { label: 'Recarga', value: 'RECARGA' },
  { label: 'Emision de Documentos', value: 'EMISION_DOCUMENTO' },
  { label: 'Todas las transacciones', value: 'ALL' },
];

const fechaMinima = now().subtract('months', 6).format(DATE_FORMAT);

const Historial = ({ classes, userAttributes }) => {
  const { updateFechas, feInicio, feFin } = useFiltroFechas();
  const [state, setData] = React.useState({
    historial: [],
    tipoTransaccion: 'CASHBACK',
    loading: false,
  });

  React.useEffect(() => {
    const { codigoEmisor } = userAttributes;
    if (codigoEmisor) consultar();
  }, [feInicio, feFin, userAttributes, state.tipoTransaccion]);

  const consultar = React.useCallback(() => {
    setState({ loading: true });
    consultarHistorialCajaDinero({
      feInicio,
      feFin,
      emisor: userAttributes.codigoEmisor,
      tipoTransaccion: state.tipoTransaccion,
    }).then((res) => {
      setState({ loading: false });
      if (res.status === 200) setState({ historial: res.data });
    });
  }, [feInicio, feFin, userAttributes.codigoEmisor, state.tipoTransaccion]);

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  const valueTransaccion = React.useMemo(
    () => options.find((o) => o.value === state.tipoTransaccion),
    [state.tipoTransaccion],
  );

  return (
    <>
      <div className={classes.filter}>
        <div></div>
        <div>
          <Button
            aria-label='Descargar Excel'
            onClick={() => exportTableToExcel('excelTable', 'exportacion')}
          >
            <img alt='exportarExcel' src={ExportExcelIco} />
            <span style={{ margin: '0px 10px' }}>Exportar Excel</span>
          </Button>
        </div>
      </div>
      <FiltroEntreFechas
        updateFechas={updateFechas}
        fechaMinima={fechaMinima}
        buttonTexto='Consultar'
      />
      <Table
        loading={state.loading}
        headers={[
          'Fecha Transacción',
          'Receptor / Emisor',
          <Select
            label='Tipo Transacción'
            options={options}
            value={valueTransaccion}
            onChange={({ value }) => setState({ tipoTransaccion: value })}
          />,
          <div>
            <label>Movimiento</label>
            <div>
              <h6>
                Total: {moneyFormat(state.historial.reduce((t, n) => t + n.nmAjusteSaldo, 0))}
              </h6>
            </div>
          </div>,
          'Saldo Actual',
        ]}
        keys={['feProceso', 'dsReceptor', 'dsTransaccion', 'nmAjusteSaldo', 'nmNuevoSaldo']}
        detalles={state.historial.map((historia) => ({
          ...historia,
          feProceso: dateString(historia.feProceso).format('DD/MM/YYYY HH:mm:ss'),
          dsReceptor: historia.receptor
            ? `${historia.receptor.persona.dsIdentificacion} - ${historia.receptor.nombreCompleto}`
            : 'A nombre propio',
          nmNuevoSaldo: moneyFormat(historia.nmNuevoSaldo),
          nmAjusteSaldo: moneyFormat(historia.nmAjusteSaldo),
          cellStyle: {
            color: historia.nmAjusteSaldo > 0 ? 'rgb(0, 200, 83)' : 'red',
            fontSize: '.9rem',
          },
        }))}
      />
      <Table
        id={'excelTable'}
        tableStyle={{ display: 'none' }}
        withPagination={false}
        orderBy='feProceso'
        headers={[
          'Fecha Transaccion',
          'Receptor / Emisor',
          'Transaccion',
          'Movimiento',
          'Saldo Actual',
        ]}
        keys={['feProceso', 'dsReceptor', 'dsTransaccion', 'nmAjusteSaldo', 'nmNuevoSaldo']}
        detalles={state.historial.map((historia) => ({
          ...historia,
          feProceso: dateString(historia.feProceso).format('DD/MM/YYYY HH:MM:ss'),
          dsReceptor: historia.receptor
            ? historia.receptor.persona.dsIdentificacion
            : 'A nombre propio',
        }))}
      />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Historial));
