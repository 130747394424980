import * as React from "react";
import * as ApiComentario from "network/Api/Comentario";
import Button from 'components/atoms/Button';
import InputFile from "components/Inputs/File";
import { getUserData } from "network/Amplify";

const Comentarios = ({ solicitud, consultarSolucitudes }) => {
  const [state, setData] = React.useState({});
  const { comentarios = [] } = state;

  const setState = (s) => setData((d) => ({ ...d, ...s }));

  React.useEffect(() => consultarComentarios(), []);

  const guardarComentario = () => {
    const { dsMensaje, dsAdjuntos } = state;
    if (solicitud && dsMensaje) {
      const { nmId, dsEncargado, cdEstado } = solicitud;
      getUserData().then(({ attributes }) => {
        ApiComentario.guardarNuevoComentario({
          comentario: JSON.stringify({
            solicitud: { nmId },
            dsMensaje,
            dsInformante: attributes["custom:custom:fullname"],
            dsUsuarioInformante: attributes["custom:custom:username"],
            dsEncargado,
            cdEstado,
          }),
          files: dsAdjuntos,
        }).then((res) => {
          const { data } = res;
          if (data) {
            setState({ dsMensaje: "", dsAdjuntos: null, labelFiles: "" });
            consultarSolucitudes();
            consultarComentarios();
          }
        });
      });
    }
  };

  const consultarComentarios = () => {
    const { nmId } = solicitud;
    ApiComentario.consultarComentariosPorSolicitud({ nmId }).then((res) => {
      const { data } = res;
      setState({ comentarios: data });
    });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  const handleChangeFiles = ({ target }) => {
    const { name, files } = target;
    setState({
      [name]: files,
      labelFiles: Array.from(files)
        .map((file) => file.name)
        .join(" - "),
    });
  };

  return (
    <div>
      <b>Comentarios:</b>
      {comentarios.map((comentario, key) => (
        <div key={key}>
          <hr />
          <small>
            {comentario.dsInformante} - {comentario.dsUsuarioInformante} / {comentario.feCreacion}
          </small>
          <p>{comentario.dsMensaje}</p>
        </div>
      ))}
      <div>
        <hr />
        <b>Nuevo Comentario</b>
        <div className="form-group">
          <textarea className="form-control rounded-0" name="dsMensaje" onChange={handleChange} value={state.dsMensaje} />
          <InputFile label="Anexos" name="dsAdjuntos" onChange={handleChangeFiles} inputText={state.labelFiles} multiple />
        </div>
        <Button onClick={guardarComentario}>Enviar</Button>
      </div>
    </div>
  );
};

export default Comentarios;
