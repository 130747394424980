const tiposUnidadMedida = [
    { codigo: "SPRAY_PEQUENO", nombre: "SPRAY_PEQUENO" },
    { codigo: "LEVANTAR", nombre: "LEVANTAR" },
    { codigo: "LOTE_CALOR", nombre: "LOTE_CALOR" },
    { codigo: "GRUPO", nombre: "GRUPO" },
    { codigo: "EQUIPAR", nombre: "EQUIPAR" },
    { codigo: "RACION", nombre: "RACION" },
    { codigo: "DISPARO", nombre: "DISPARO" },
    { codigo: "PALO", nombre: "PALO" },
    { codigo: "TAMBOR_CIENTO_QUINCE_KG", nombre: "TAMBOR_CIENTO_QUINCE_KG" },
    { codigo: "TAMBOR_CIEN_LIBRAS", nombre: "TAMBOR_CIEN_LIBRAS" },
    { codigo: "TAMBOR_CINCUENTA_Y_CINCO_GALONES", nombre: "TAMBOR_CINCUENTA_Y_CINCO_GALONES" },
    { codigo: "CAMION_CISTERNA", nombre: "CAMION_CISTERNA" },
    { codigo: "CONTENEDOR_VEINTE_PIES", nombre: "CONTENEDOR_VEINTE_PIES" },
    { codigo: "CONTENEDOR_CUARENTA_PIES", nombre: "CONTENEDOR_CUARENTA_PIES" },
    { codigo: "DECILITRO_POR_GRAMO", nombre: "DECILITRO_POR_GRAMO" },
    { codigo: "GRAMO_POR_CENTIMETRO_CUBICO", nombre: "GRAMO_POR_CENTIMETRO_CUBICO" },
    { codigo: "LIBRA_TEORICA", nombre: "LIBRA_TEORICA" },
    { codigo: "GRAMO_POR_CENTIMETRO_CUADRADO", nombre: "GRAMO_POR_CENTIMETRO_CUADRADO" },
    { codigo: "TONELADA_REAL", nombre: "TONELADA_REAL" },
    { codigo: "TONELADA_TEORICA", nombre: "TONELADA_TEORICA" },
    { codigo: "KIOGRAMOS_POR_METRO_CUADRADO", nombre: "KIOGRAMOS_POR_METRO_CUADRADO" },
    { codigo: "LIBRA_POR_MIL_PIES_CUADRADOS", nombre: "LIBRA_POR_MIL_PIES_CUADRADOS" },
    { codigo: "DIA_DE_CABALLO_DE_POTENCIA_POR_TONELADA_METRICA_SECA_AL_AIRE", nombre: "DIA_DE_CABALLO_DE_POTENCIA_POR_TONELADA_METRICA_SECA_AL_AIRE" },
    { codigo: "COGER_PESO", nombre: "COGER_PESO" },
    { codigo: "KILOGRAMO_POR_AIRE_SECO_TONELADA_METRICA", nombre: "KILOGRAMO_POR_AIRE_SECO_TONELADA_METRICA" },
    { codigo: "KILOPASCALES_METROS_CUADRADOS_METRICA", nombre: "KILOPASCALES_METROS_CUADRADOS_METRICA" },
    { codigo: "KILOPASCALES_POR_MILIMETROS", nombre: "KILOPASCALES_POR_MILIMETROS" },
    { codigo: "KILOPASCALES_METROS_CUADRADOS_POR_GRAMO", nombre: "KILOPASCALES_METROS_CUADRADOS_POR_GRAMO" },
    { codigo: "MILILITROS_POR_CENTIMETRO_CUADRADO_SEGUNDO", nombre: "MILILITROS_POR_CENTIMETRO_CUADRADO_SEGUNDO" },
    { codigo: "PIES_CUBICOS_POR_MINUTO_POR_PIE_CUADRADO", nombre: "PIES_CUBICOS_POR_MINUTO_POR_PIE_CUADRADO" },
    { codigo: "ONZA_POR_PIE_CUADRADO", nombre: "ONZA_POR_PIE_CUADRADO" },
    { codigo: "ONZAS_POR_PIE_CUADRADO_POR_DECIMA_DE_PULGADA", nombre: "ONZAS_POR_PIE_CUADRADO_POR_DECIMA_DE_PULGADA" },
    { codigo: "MILILITRO_POR_SEGUNDO", nombre: "MILILITRO_POR_SEGUNDO" },
    { codigo: "MILILITRO_POR_MINUTO", nombre: "MILILITRO_POR_MINUTO" },
    { codigo: "BOLSA_SUPER_A_GRANEL", nombre: "BOLSA_SUPER_A_GRANEL" },
    { codigo: "BOLSA_A_GRANEL_DE_QUINIENTOS_KG", nombre: "BOLSA_A_GRANEL_DE_QUINIENTOS_KG" },
    { codigo: "BOLSA_A_GRANEL_DE_TRESCIENTOS_KG", nombre: "BOLSA_A_GRANEL_DE_TRESCIENTOS_KG" },
    { codigo: "BOLSA_A_GRANEL_DE_CINCUENTA_LIBRAS", nombre: "BOLSA_A_GRANEL_DE_CINCUENTA_LIBRAS" },
    { codigo: "BOLSA_DE_CINCUENTA_LIBRAS", nombre: "BOLSA_DE_CINCUENTA_LIBRAS" },
    { codigo: "CARGA_DE_AUTOMOVILES_A_GRANEL", nombre: "CARGA_DE_AUTOMOVILES_A_GRANEL" },
    { codigo: "KILOGRAMOS_TEORICOS", nombre: "KILOGRAMOS_TEORICOS" },
    { codigo: "TONELADA_TEORICA", nombre: "TONELADA_TEORICA" },
    { codigo: "SITAS", nombre: "SITAS" },
    { codigo: "MALLA", nombre: "MALLA" },
    { codigo: "KILOGRAMO_NETO", nombre: "KILOGRAMO_NETO" },
    { codigo: "PARTE_POR_MILLON", nombre: "PARTE_POR_MILLON" },
    { codigo: "PORCENTAJE_DE_PESO", nombre: "PORCENTAJE_DE_PESO" },
    { codigo: "PARTE_POR_BILLON", nombre: "PARTE_POR_BILLON" },
    { codigo: "PORCENTAJE_POR_1000_HORAS", nombre: "PORCENTAJE_POR_1000_HORAS" },
    { codigo: "TASA_DE_FRACASO_EN_EL_TIEMPO", nombre: "TASA_DE_FRACASO_EN_EL_TIEMPO" },
    { codigo: "LIBRA_POR_PULGADA_CUADRADA", nombre: "LIBRA_POR_PULGADA_CUADRADA" },
    { codigo: "OERSTED", nombre: "OERSTED" },
    { codigo: "PRUEBA_DE_ESCALA_ESPECIFICA", nombre: "PRUEBA_DE_ESCALA_ESPECIFICA" },
    { codigo: "VOLTIO_AMPERIO_POR_LIBRA", nombre: "VOLTIO_AMPERIO_POR_LIBRA" },
    { codigo: "VATIO_POR_LIBRA", nombre: "VATIO_POR_LIBRA" },
    { codigo: "AMPERIO_TUM_POR_CENTIMETRO", nombre: "AMPERIO_TUM_POR_CENTIMETRO" },
    { codigo: "MILIPASCAL", nombre: "MILIPASCAL" },
    { codigo: "GAUSS", nombre: "GAUSS" },
    { codigo: "MILI_PULGADAS", nombre: "MILI_PULGADAS" },
    { codigo: "KILOGAUSS", nombre: "KILOGAUSS" },
    { codigo: "LIBRAS_POR_PULGADA_CUADRADA_ABSOLUTA", nombre: "LIBRAS_POR_PULGADA_CUADRADA_ABSOLUTA" },
    { codigo: "ENRIQUE", nombre: "ENRIQUE" },
    { codigo: "KILOPOUND_POR_PULGADA_CUADRADA", nombre: "KILOPOUND_POR_PULGADA_CUADRADA" },
    { codigo: "FUERZA_LIBRA_PIE", nombre: "FUERZA_LIBRA_PIE" },
    { codigo: "LIBRA_POR_PIE_CUBICO", nombre: "LIBRA_POR_PIE_CUBICO" },
    { codigo: "EQUILIBRIO", nombre: "EQUILIBRIO" },
    { codigo: "SAYBOLD_SEGUNDO_UNIVERSAL", nombre: "SAYBOLD_SEGUNDO_UNIVERSAL" },
    { codigo: "ALIMENTA", nombre: "ALIMENTA" },
    { codigo: "CALORIAS_POR_CENTIMETRO_CUBICO", nombre: "CALORIAS_POR_CENTIMETRO_CUBICO" },
    { codigo: "CALORIAS_POR_GRAMO", nombre: "CALORIAS_POR_GRAMO" },
    { codigo: "UNIDAD térmica británica por hora.", nombre: "UNIDAD térmica británica por hora." },
    { codigo: "VEINTE_MIL_GALONES", nombre: "VEINTE_MIL_GALONES" },
    { codigo: "DIEZ_MIL_GALONES", nombre: "DIEZ_MIL_GALONES" },
    { codigo: "TAMBOR_DE_DIEZ_KG", nombre: "TAMBOR_DE_DIEZ_KG" },
    { codigo: "TAMBOR_DE_QUINCE_KG", nombre: "TAMBOR_DE_QUINCE_KG" },
    { codigo: "MILLA_DE_COCHE", nombre: "MILLA_DE_COCHE" },
    { codigo: "RECUENTO_DE_COCHES", nombre: "RECUENTO_DE_COCHES" },
    { codigo: "RECUENTO_DE_LOCOMOTORAS", nombre: "RECUENTO_DE_LOCOMOTORAS" },
    { codigo: "RECUENTO_DE_CABOS", nombre: "RECUENTO_DE_CABOS" },
    { codigo: "CARRO_VACIO", nombre: "CARRO_VACIO" },
    { codigo: "MILLAS_DE_TREN", nombre: "MILLAS_DE_TREN" },
    { codigo: "USO_DE_COMBUSTIBLE_GALON", nombre: "USO_DE_COMBUSTIBLE_GALON" },
    { codigo: "MILLA_DEL_CABOOSE", nombre: "MILLA_DEL_CABOOSE" },
    { codigo: "TIPO_DE_INTERES_FIJO", nombre: "TIPO_DE_INTERES_FIJO" },
    { codigo: "TONELADA_MILLA", nombre: "TONELADA_MILLA" },
    { codigo: "MILLA_LOCOMOTORA", nombre: "MILLA_LOCOMOTORA" },
    { codigo: "RECUENTO_TOTAL_DE_COCHES", nombre: "RECUENTO_TOTAL_DE_COCHES" },
    { codigo: "MILLA_DE_COCHE_TOTAL", nombre: "MILLA_DE_COCHE_TOTAL" },
    { codigo: "CUARTO_DE_MILLA", nombre: "CUARTO_DE_MILLA" },
    { codigo: "RADIANES_POR_SEGUNDO", nombre: "RADIANES_POR_SEGUNDO" },
    { codigo: "RADIANES_POR_SEGUNDO_AL_CUADRADO", nombre: "RADIANES_POR_SEGUNDO_AL_CUADRADO" },
    { codigo: "RONTGEN", nombre: "RONTGEN" },
    { codigo: "UNIDAD_TERMICA_BRITANICA_POR_HORA", nombre: "UNIDAD_TERMICA_BRITANICA_POR_HORA" },
    { codigo: "CENTIMETRO_CUBICO_POR_SEGUNDO", nombre: "CENTIMETRO_CUBICO_POR_SEGUNDO" },
    { codigo: "PIE_CUBICO_POR_HORA", nombre: "PIE_CUBICO_POR_HORA" },
    { codigo: "PIE_CUBICO_POR_MINUTO", nombre: "PIE_CUBICO_POR_MINUTO" },
    { codigo: "CENTIMETRO_POR_SEGUNDO", nombre: "CENTIMETRO_POR_SEGUNDO" },
    { codigo: "DECIBEL", nombre: "DECIBEL" },
    { codigo: "KILOBYTE", nombre: "KILOBYTE" },
    { codigo: "KILOBECQUEREL", nombre: "KILOBECQUEREL" },
    { codigo: "KILOCURIE", nombre: "KILOCURIE" },
    { codigo: "MEGAGRAMO", nombre: "MEGAGRAMO" },
    { codigo: "MEGAGRAMO_POR_HORA", nombre: "MEGAGRAMO_POR_HORA" },
    { codigo: "COMPARTIMIENTO", nombre: "COMPARTIMIENTO" },
    { codigo: "METRO_POR_MINUTO", nombre: "METRO_POR_MINUTO" },
    { codigo: "MILLIRONTGEN", nombre: "MILLIRONTGEN" },
    { codigo: "MILIVOLTIOS", nombre: "MILIVOLTIOS" },
    { codigo: "MEGAJULIO", nombre: "MEGAJULIO" },
    { codigo: "MANMONTH", nombre: "MANMONTH" },
    { codigo: "LIBRA_POR_LIBRA_DE_PRODUCTO", nombre: "LIBRA_POR_LIBRA_DE_PRODUCTO" },
    { codigo: "LIBRA_POR_PIEZA_DE_PRODUCTO", nombre: "LIBRA_POR_PIEZA_DE_PRODUCTO" },
    { codigo: "KILOGRAMO_POR_KILOGRAMO_DE_PRODUCTO", nombre: "KILOGRAMO_POR_KILOGRAMO_DE_PRODUCTO" },
    { codigo: "KILOGRAMO_POR_PIEZA_DE_PRODUCTO", nombre: "KILOGRAMO_POR_PIEZA_DE_PRODUCTO" },
    { codigo: "ESPIRAL", nombre: "ESPIRAL" },
    { codigo: "GORRA", nombre: "GORRA" },
    { codigo: "CENTISTOKES", nombre: "CENTISTOKES" },
    { codigo: "PAQUETE_DE_VEINTE", nombre: "PAQUETE_DE_VEINTE" },
    { codigo: "MICROLITRO", nombre: "MICROLITRO" },
    { codigo: "MICROMETRO", nombre: "MICROMETRO" },
    { codigo: "MILIAMPERIO", nombre: "MILIAMPERIO" },
    { codigo: "MEGABYTE", nombre: "MEGABYTE" },
    { codigo: "MILIGRAMO_POR_HORA", nombre: "MILIGRAMO_POR_HORA" },
    { codigo: "MEGABECQUEREL", nombre: "MEGABECQUEREL" },
    { codigo: "MICROFARAD", nombre: "MICROFARAD" },
    { codigo: "NEWTON_POR_METRO", nombre: "NEWTON_POR_METRO" },
    { codigo: "ONZA_PULGADA", nombre: "ONZA_PULGADA" },
    { codigo: "PIE_ONZA", nombre: "PIE_ONZA" },
    { codigo: "PICOFARAD", nombre: "PICOFARAD" },
    { codigo: "LIBRA_POR_HORA", nombre: "LIBRA_POR_HORA" },
    { codigo: "TONELADA_POR_HORA", nombre: "TONELADA_POR_HORA" },
    { codigo: "KILOLITRO_POR_HORA", nombre: "KILOLITRO_POR_HORA" },
    { codigo: "BARRIL_POR_MINUTO", nombre: "BARRIL_POR_MINUTO" },
    { codigo: "LOTE", nombre: "LOTE" },
    { codigo: "GALON_POR_MIL", nombre: "GALON_POR_MIL" },
    { codigo: "MMSCF_DIA", nombre: "MMSCF_DIA" },
    { codigo: "LIBRAS_POR_MIL", nombre: "LIBRAS_POR_MIL" },
    { codigo: "BOMBA", nombre: "BOMBA" },
    { codigo: "ESCENARIO", nombre: "ESCENARIO" },
    { codigo: "PIE_CUBICO_ESTANDAR", nombre: "PIE_CUBICO_ESTANDAR" },
    { codigo: "POTENCIA_HIDRAULICA_DE_CABALLOS", nombre: "POTENCIA_HIDRAULICA_DE_CABALLOS" },
    { codigo: "CONTAR_POR_MINUTO", nombre: "CONTAR_POR_MINUTO" },
    { codigo: "NIVEL_SISMICO", nombre: "NIVEL_SISMICO" },
    { codigo: "LINEA_SISMICA", nombre: "LINEA_SISMICA" },
    { codigo: "CALORIA_15_C", nombre: "CALORIA_15_C" },
    { codigo: "AMPERIO_METRO_CUADRADO_POR_JOULE_SEGUNDO", nombre: "AMPERIO_METRO_CUADRADO_POR_JOULE_SEGUNDO" },
    { codigo: "ANGSTROM", nombre: "ANGSTROM" },
    { codigo: "UNIDAD_ASTRONOMICA", nombre: "UNIDAD_ASTRONOMICA" },
    { codigo: "ATTOJOULE", nombre: "ATTOJOULE" },
    { codigo: "GRANERO", nombre: "GRANERO" },
    { codigo: "GRANERO_POR_ELECTRON_VOLTIO", nombre: "GRANERO_POR_ELECTRON_VOLTIO" },
    { codigo: "GRANERO_POR_VOLTIO_DE_ELECTRONES_ESTERADIANO", nombre: "GRANERO_POR_VOLTIO_DE_ELECTRONES_ESTERADIANO" },
    { codigo: "GRANERO_POR_STERDIAN", nombre: "GRANERO_POR_STERDIAN" },
    { codigo: "BECQUEREL_POR_KILOGRAMO", nombre: "BECQUEREL_POR_KILOGRAMO" },
    { codigo: "BECQUEREL_POR_METRO_CUBICO", nombre: "BECQUEREL_POR_METRO_CUBICO" },
    { codigo: "AMPERIO_POR_CENTIMETRO", nombre: "AMPERIO_POR_CENTIMETRO" },
    { codigo: "UNIDAD_TERMICA_BRITANICA_POR_SEGUNDO_PIE_CUADRADO_GRADO_RANKIN", nombre: "UNIDAD_TERMICA_BRITANICA_POR_SEGUNDO_PIE_CUADRADO_GRADO_RANKIN" },
    { codigo: "UNIDAD_TERMICA_BRITANICA_POR_LIBRA_GRADO_RANKIN", nombre: "UNIDAD_TERMICA_BRITANICA_POR_LIBRA_GRADO_RANKIN" },
    { codigo: "UNIDAD_TERMICA_BRITANICA_POR_SEGUNDO_PIE_GRADO_RANKIN", nombre: "UNIDAD_TERMICA_BRITANICA_POR_SEGUNDO_PIE_GRADO_RANKIN" },
    { codigo: "UNIDAD_TERMICA_BRITANICA_POR_HORA_PIE_CUADRADO_GRADO_RANKIN", nombre: "UNIDAD_TERMICA_BRITANICA_POR_HORA_PIE_CUADRADO_GRADO_RANKIN" },
    { codigo: "CANDELA_POR_METRO_CUADRADO", nombre: "CANDELA_POR_METRO_CUADRADO" },
    { codigo: "CHEVAL_VAPEUR", nombre: "CHEVAL_VAPEUR" },
    { codigo: "MEDIDOR_DE_CULOMBIO", nombre: "MEDIDOR_DE_CULOMBIO" },
    { codigo: "MEDIDOR_DE_CULOMBIO_AL_CUADRADO_POR_VOLTIO", nombre: "MEDIDOR_DE_CULOMBIO_AL_CUADRADO_POR_VOLTIO" },
    { codigo: "COULOMB_POR_CENTIMETRO_CUBICO", nombre: "COULOMB_POR_CENTIMETRO_CUBICO" },
    { codigo: "COULOMB_POR_METRO_CUBICO", nombre: "COULOMB_POR_METRO_CUBICO" },
    { codigo: "AMPERIO_POR_MILIMETRO", nombre: "AMPERIO_POR_MILIMETRO" },
    { codigo: "COULOMB_POR_MILIMETRO_CUBICO", nombre: "COULOMB_POR_MILIMETRO_CUBICO" },
    { codigo: "COULOMB_POR_KILOGRAMO_SEGUNDO", nombre: "COULOMB_POR_KILOGRAMO_SEGUNDO" },
    { codigo: "COULOMB_POR_MOL", nombre: "COULOMB_POR_MOL" },
    { codigo: "COULOMB_POR_CENTIMETRO_CUADRADO", nombre: "COULOMB_POR_CENTIMETRO_CUADRADO" },
    { codigo: "COULOMB_POR_METRO_CUADRADO", nombre: "COULOMB_POR_METRO_CUADRADO" },
    { codigo: "COULOMB_POR_MILIMETRO_CUADRADO", nombre: "COULOMB_POR_MILIMETRO_CUADRADO" },
    { codigo: "CENTIMETRO_CUBICO_POR_MOL", nombre: "CENTIMETRO_CUBICO_POR_MOL" },
    { codigo: "DECIMETRO_CUBICO_POR_MOL", nombre: "DECIMETRO_CUBICO_POR_MOL" },
    { codigo: "METRO_CUBICO_POR_COULOMB", nombre: "METRO_CUBICO_POR_COULOMB" },
    { codigo: "METRO_CUBICO_POR_KILOGRAMO", nombre: "METRO_CUBICO_POR_KILOGRAMO" },
    { codigo: "AMPERIO_POR_CENTIMETRO_CUADRADO", nombre: "AMPERIO_POR_CENTIMETRO_CUADRADO" },
    { codigo: "METRO_CUBICO_POR_MOL", nombre: "METRO_CUBICO_POR_MOL" },
    { codigo: "AMPERIO_POR_METRO_CUADRADO", nombre: "AMPERIO_POR_METRO_CUADRADO" },
    { codigo: "CURIE_POR_KILOGRAMO", nombre: "CURIE_POR_KILOGRAMO" },
    { codigo: "TONELAJE_DE_PESO_MUERTO", nombre: "TONELAJE_DE_PESO_MUERTO" },
    { codigo: "DECALITRO", nombre: "DECALITRO" },
    { codigo: "DECAMETRO", nombre: "DECAMETRO" },
    { codigo: "DECITEX", nombre: "DECITEX" },
    { codigo: "GRADO_RANKIN", nombre: "GRADO_RANKIN" },
    { codigo: "NEGADOR", nombre: "NEGADOR" },
    { codigo: "AMPERIO_METRO_CUADRADO", nombre: "AMPERIO_METRO_CUADRADO" },
    { codigo: "DYN_SEGUNDO_POR_CENTIMETRO_CUBICO", nombre: "DYN_SEGUNDO_POR_CENTIMETRO_CUBICO" },
    { codigo: "DINA_SEGUNDO_POR_CENTIMETRO", nombre: "DINA_SEGUNDO_POR_CENTIMETRO" },
    { codigo: "DINA_SEGUNDO_POR_CENTIMETRO_AL_QUINTO", nombre: "DINA_SEGUNDO_POR_CENTIMETRO_AL_QUINTO" },
    { codigo: "ELECTRONVOLT", nombre: "ELECTRONVOLT" },
    { codigo: "ELECTRONVOLTIO_POR_METRO", nombre: "ELECTRONVOLTIO_POR_METRO" },
    { codigo: "METRO_ELECTRONVOLT_CUADRADO", nombre: "METRO_ELECTRONVOLT_CUADRADO" },
    { codigo: "ELECTRONVOLTIO_DE_METRO_CUADRADO_POR_KILOGRAMO", nombre: "ELECTRONVOLTIO_DE_METRO_CUADRADO_POR_KILOGRAMO" },
    { codigo: "ERGIO", nombre: "ERGIO" },
    { codigo: "ERG_POR_CENTIMETRO", nombre: "ERG_POR_CENTIMETRO" },
    { codigo: "AMPERIO_POR_METRO_CUADRADO_KELVIN_AL_CUADRADO", nombre: "AMPERIO_POR_METRO_CUADRADO_KELVIN_AL_CUADRADO" },
    { codigo: "ERG_POR_CENTIMETRO_CUBICO", nombre: "ERG_POR_CENTIMETRO_CUBICO" },
    { codigo: "ERG_POR_GRAMO", nombre: "ERG_POR_GRAMO" },
    { codigo: "ERG_POR_GRAMO_DE_SEGUNDO", nombre: "ERG_POR_GRAMO_DE_SEGUNDO" },
    { codigo: "ERG_POR_SEGUNDO", nombre: "ERG_POR_SEGUNDO" },
    { codigo: "ERG_POR_SEGUNDO_CENTIMETRO_CUADRADO", nombre: "ERG_POR_SEGUNDO_CENTIMETRO_CUADRADO" },
    { codigo: "ERG_POR_CENTIMETRO_CUADRADO_SEGUNDO", nombre: "ERG_POR_CENTIMETRO_CUADRADO_SEGUNDO" },
    { codigo: "ERG_CENTIMETRO_CUADRADO", nombre: "ERG_CENTIMETRO_CUADRADO" },
    { codigo: "ERGIMETRO_CUADRADO_POR_GRAMO", nombre: "ERGIMETRO_CUADRADO_POR_GRAMO" },
    { codigo: "EXAJULIO", nombre: "EXAJULIO" },
    { codigo: "FARADIO_POR_METRO", nombre: "FARADIO_POR_METRO" },
    { codigo: "AMPERIO_POR_MILIMETRO_CUADRADO", nombre: "AMPERIO_POR_MILIMETRO_CUADRADO" },
    { codigo: "FEMTOJOULE", nombre: "FEMTOJOULE" },
    { codigo: "FEMTOMETRO", nombre: "FEMTOMETRO" },
    { codigo: "PIE_POR_SEGUNDO_AL_CUADRADO", nombre: "PIE_POR_SEGUNDO_AL_CUADRADO" },
    { codigo: "PIE_FUERZA_DE_LA_LIBRA_POR_SEGUNDO", nombre: "PIE_FUERZA_DE_LA_LIBRA_POR_SEGUNDO" },
    { codigo: "TONELADA_DE_CARGA", nombre: "TONELADA_DE_CARGA" },
    { codigo: "GALON", nombre: "GALON" },
    { codigo: "UNIDAD_DE_DESPLAZAMIENTO_CGS_GAUSSIANA", nombre: "UNIDAD_DE_DESPLAZAMIENTO_CGS_GAUSSIANA" },
    { codigo: "UNIDAD_GAUSSIANA_CGS_DE_CORRIENTE_ELECTRICA", nombre: "UNIDAD_GAUSSIANA_CGS_DE_CORRIENTE_ELECTRICA" },
    { codigo: "UNIDAD_GAUSSIAN_CGS_DE_CARGA_ELECTRICA", nombre: "UNIDAD_GAUSSIAN_CGS_DE_CARGA_ELECTRICA" },
    { codigo: "AMPERIO_SEGUNDO", nombre: "AMPERIO_SEGUNDO" },
    { codigo: "UNIDAD_GAUSSIAN_CGS_DE_INTENSIDAD_DE_CAMPO_ELECTRICO", nombre: "UNIDAD_GAUSSIAN_CGS_DE_INTENSIDAD_DE_CAMPO_ELECTRICO" },
    { codigo: "UNIDAD_GAUSSIAN_CGS_DE_POLARIZACION_ELECTRICA", nombre: "UNIDAD_GAUSSIAN_CGS_DE_POLARIZACION_ELECTRICA" },
    { codigo: "UNIDAD_GAUSSIAN_CGS_DE_POTENCIAL_ELECTRICO", nombre: "UNIDAD_GAUSSIAN_CGS_DE_POTENCIAL_ELECTRICO" },
    { codigo: "UNIDAD_GAUSSIANA_CGS_DE_MAGNETIZACION", nombre: "UNIDAD_GAUSSIANA_CGS_DE_MAGNETIZACION" },
    { codigo: "GIGACOULOMB_POR_METRO_CUBICO", nombre: "GIGACOULOMB_POR_METRO_CUBICO" },
    { codigo: "GIGAELECTRONVOLT", nombre: "GIGAELECTRONVOLT" },
    { codigo: "GIGAHERCIOS", nombre: "GIGAHERCIOS" },
    { codigo: "GIGAOHM", nombre: "GIGAOHM" },
    { codigo: "MEDIDOR_DE_GIGAOHM", nombre: "MEDIDOR_DE_GIGAOHM" },
    { codigo: "GIGAPASCAL", nombre: "GIGAPASCAL" },
    { codigo: "TARIFA", nombre: "TARIFA" },
    { codigo: "GIGAVATIOS", nombre: "GIGAVATIOS" },
    { codigo: "GON", nombre: "GON" },
    { codigo: "GRAMO_POR_METRO_CUBICO", nombre: "GRAMO_POR_METRO_CUBICO" },
    { codigo: "GRAMO_POR_MOL", nombre: "GRAMO_POR_MOL" },
    { codigo: "GRIS", nombre: "GRIS" },
    { codigo: "GRIS_POR_SEGUNDO", nombre: "GRIS_POR_SEGUNDO" },
    { codigo: "HECTOPASCAL", nombre: "HECTOPASCAL" },
    { codigo: "HENRY_POR_METRO", nombre: "HENRY_POR_METRO" },
    { codigo: "BOLA", nombre: "BOLA" },
    { codigo: "PAQUETE_A_GRANEL", nombre: "PAQUETE_A_GRANEL" },
    { codigo: "ACRE", nombre: "ACRE" },
    { codigo: "BYTE", nombre: "BYTE" },
    { codigo: "AMPERIO_POR_METRO", nombre: "AMPERIO_POR_METRO" },
    { codigo: "MINUTO_ADICIONAL", nombre: "MINUTO_ADICIONAL" },
    { codigo: "MINUTO_PROMEDIO_POR_LLAMADA", nombre: "MINUTO_PROMEDIO_POR_LLAMADA" },
    { codigo: "POLICIA", nombre: "POLICIA" },
    { codigo: "BRAZA", nombre: "BRAZA" },
    { codigo: "LINEA_DE_ACCESO", nombre: "LINEA_DE_ACCESO" },
    { codigo: "AMPOLLA", nombre: "AMPOLLA" },
    { codigo: "HORA_AMPERIO", nombre: "HORA_AMPERIO" },
    { codigo: "AMPERIO", nombre: "AMPERIO" },
    { codigo: "ANNO", nombre: "ANNO" },
    { codigo: "SOLO_LIBRA_DE_ALUMINIO", nombre: "SOLO_LIBRA_DE_ALUMINIO" },
    { codigo: "ONZA_TROY_O_ONZA_DE_BOTICARIOS", nombre: "ONZA_TROY_O_ONZA_DE_BOTICARIOS" },
    { codigo: "UNIDAD_DE_FACTOR_ANTIHEMOFILICO", nombre: "UNIDAD_DE_FACTOR_ANTIHEMOFILICO" },
    { codigo: "SUPOSITORIO", nombre: "SUPOSITORIO" },
    { codigo: "CIEN_METROS_CUADRADOS", nombre: "CIEN_METROS_CUADRADOS" },
    { codigo: "SURTIDO", nombre: "SURTIDO" },
    { codigo: "FUERZA_ALCOHOLICA_EN_MASA", nombre: "FUERZA_ALCOHOLICA_EN_MASA" },
    { codigo: "FUERZA_ALCOHOLICA_POR_VOLUMEN", nombre: "FUERZA_ALCOHOLICA_POR_VOLUMEN" },
    { codigo: "AMBIENTE_ESTANDAR", nombre: "AMBIENTE_ESTANDAR" },
    { codigo: "AMBIENTE_TECNICO", nombre: "AMBIENTE_TECNICO" },
    { codigo: "CAPSULA", nombre: "CAPSULA" },
    { codigo: "VIAL_LLENO_DE_POLVO", nombre: "VIAL_LLENO_DE_POLVO" },
    { codigo: "MONTAJE", nombre: "MONTAJE" },
    { codigo: "UNIDAD_TERMICA_BRITANICA_POR_LIBRA", nombre: "UNIDAD_TERMICA_BRITANICA_POR_LIBRA" },
    { codigo: "BTU_POR_PIE_CUBICO", nombre: "BTU_POR_PIE_CUBICO" },
    { codigo: "BARRIL_POR_DIA", nombre: "BARRIL_POR_DIA" },
    { codigo: "JULIOS_POR_KILOGRAMO_KELVIN", nombre: "JULIOS_POR_KILOGRAMO_KELVIN" },
    { codigo: "JULIOS_POR_METRO", nombre: "JULIOS_POR_METRO" },
    { codigo: "JULIOS_POR_METRO_CUADRADO", nombre: "JULIOS_POR_METRO_CUADRADO" },
    { codigo: "JULIOS_POR_METRO_A_LA_CUARTA_POTENCIA", nombre: "JULIOS_POR_METRO_A_LA_CUARTA_POTENCIA" },
    { codigo: "JULIOS_POR_MOL", nombre: "JULIOS_POR_MOL" },
    { codigo: "JULIOS_POR_MOL_KELVIN", nombre: "JULIOS_POR_MOL_KELVIN" },
    { codigo: "JOULE_SEGUNDO", nombre: "JOULE_SEGUNDO" },
    { codigo: "LITERA", nombre: "LITERA" },
    { codigo: "JOULE_METRO_CUADRADO_POR_KILOGRAMO", nombre: "JOULE_METRO_CUADRADO_POR_KILOGRAMO" },
    { codigo: "KELVIN_POR_VATIO", nombre: "KELVIN_POR_VATIO" },
    { codigo: "KILOAMPERE", nombre: "KILOAMPERE" },
    { codigo: "KILOAMPERE_POR_METRO_CUADRADO", nombre: "KILOAMPERE_POR_METRO_CUADRADO" },
    { codigo: "KILOAMPERE_POR_METRO", nombre: "KILOAMPERE_POR_METRO" },
    { codigo: "KILOBECQUEREL_POR_KILOGRAMO", nombre: "KILOBECQUEREL_POR_KILOGRAMO" },
    { codigo: "KILOCOULOMB", nombre: "KILOCOULOMB" },
    { codigo: "KILOCOULOMB_POR_METRO_CUBICO", nombre: "KILOCOULOMB_POR_METRO_CUBICO" },
    { codigo: "KILOCOULOMB_POR_METRO_CUADRADO", nombre: "KILOCOULOMB_POR_METRO_CUADRADO" },
    { codigo: "KILOELECTRONVOLT", nombre: "KILOELECTRONVOLT" },
    { codigo: "LIBRA_DE_BATEO", nombre: "LIBRA_DE_BATEO" },
    { codigo: "KILOGRAMO_METRO_POR_SEGUNDO", nombre: "KILOGRAMO_METRO_POR_SEGUNDO" },
    { codigo: "KILOGRAMO_METRO_CUADRADO", nombre: "KILOGRAMO_METRO_CUADRADO" },
    { codigo: "KILOGRAMO_METRO_CUADRADO_POR_SEGUNDO", nombre: "KILOGRAMO_METRO_CUADRADO_POR_SEGUNDO" },
    { codigo: "KILOGRAMO_POR_DECIMETRO_CUBICO", nombre: "KILOGRAMO_POR_DECIMETRO_CUBICO" },
    { codigo: "KILOGRAMO_POR_LITRO", nombre: "KILOGRAMO_POR_LITRO" },
    { codigo: "CALORIA_TERMOQUIMICA_POR_GRAMO", nombre: "CALORIA_TERMOQUIMICA_POR_GRAMO" },
    { codigo: "KILOGRAMO_DE_FUERZA", nombre: "KILOGRAMO_DE_FUERZA" },
    { codigo: "METRO_DE_FUERZA_DE_KILOGRAMO", nombre: "METRO_DE_FUERZA_DE_KILOGRAMO" },
    { codigo: "METRO_DE_FUERZA_DE_KILOGRAMO_POR_SEGUNDO", nombre: "METRO_DE_FUERZA_DE_KILOGRAMO_POR_SEGUNDO" },
    { codigo: "BARRIL_IMPERIAL", nombre: "BARRIL_IMPERIAL" },
    { codigo: "KILOGRAMO_DE_FUERZA_POR_METRO_CUADRADO", nombre: "KILOGRAMO_DE_FUERZA_POR_METRO_CUADRADO" },
    { codigo: "KILOJOULE_PER_KELVIN", nombre: "KILOJOULE_PER_KELVIN" },
    { codigo: "KILOJOULE_POR_KILOGRAMO", nombre: "KILOJOULE_POR_KILOGRAMO" },
    { codigo: "KILOJOULE_POR_KILOGRAMO_KELVIN", nombre: "KILOJOULE_POR_KILOGRAMO_KELVIN" },
    { codigo: "KILOJOULE_POR_MOL", nombre: "KILOJOULE_POR_MOL" },
    { codigo: "KILOMOL", nombre: "KILOMOL" },
    { codigo: "KILOMOL_POR_METRO_CUBICO", nombre: "KILOMOL_POR_METRO_CUBICO" },
    { codigo: "KILONEWTON", nombre: "KILONEWTON" },
    { codigo: "MEDIDOR_DE_KILONEWTON", nombre: "MEDIDOR_DE_KILONEWTON" },
    { codigo: "KILOOHM", nombre: "KILOOHM" },
    { codigo: "PALANQUILLA", nombre: "PALANQUILLA" },
    { codigo: "MEDIDOR_DE_KILOOHM", nombre: "MEDIDOR_DE_KILOOHM" },
    { codigo: "KILOPOND", nombre: "KILOPOND" },
    { codigo: "KILOSEGUNDO", nombre: "KILOSEGUNDO" },
    { codigo: "KILOSIEMENS", nombre: "KILOSIEMENS" },
    { codigo: "KILOSIEMENS_POR_METRO", nombre: "KILOSIEMENS_POR_METRO" },
    { codigo: "KILOVOLTIOS_POR_METRO", nombre: "KILOVOLTIOS_POR_METRO" },
    { codigo: "KILOVEBER_POR_METRO", nombre: "KILOVEBER_POR_METRO" },
    { codigo: "ANNO_LUZ", nombre: "ANNO_LUZ" },
    { codigo: "LITRO_POR_MOL", nombre: "LITRO_POR_MOL" },
    { codigo: "HORA_LUMEN", nombre: "HORA_LUMEN" },
    { codigo: "BOLLO", nombre: "BOLLO" },
    { codigo: "LUMEN_POR_METRO_CUADRADO", nombre: "LUMEN_POR_METRO_CUADRADO" },
    { codigo: "LUMEN_POR_VATIO", nombre: "LUMEN_POR_VATIO" },
    { codigo: "LUMEN_SEGUNDO", nombre: "LUMEN_SEGUNDO" },
    { codigo: "HORA_DE_LUX", nombre: "HORA_DE_LUX" },
    { codigo: "LUX_SEGUNDO", nombre: "LUX_SEGUNDO" },
    { codigo: "MAXWELL", nombre: "MAXWELL" },
    { codigo: "MEGAAMPERIOS_POR_METRO_CUADRADO", nombre: "MEGAAMPERIOS_POR_METRO_CUADRADO" },
    { codigo: "MEGABECQUEREL_POR_KILOGRAMO", nombre: "MEGABECQUEREL_POR_KILOGRAMO" },
    { codigo: "MEGACOULOMB_POR_METRO_CUBICO", nombre: "MEGACOULOMB_POR_METRO_CUBICO" },
    { codigo: "CICLO", nombre: "CICLO" },
    { codigo: "MEGACOULOMB_POR_METRO_CUADRADO", nombre: "MEGACOULOMB_POR_METRO_CUADRADO" },
    { codigo: "MEGAELECTRONVOLT", nombre: "MEGAELECTRONVOLT" },
    { codigo: "MEGAGRAMO_POR_METRO_CUBICO", nombre: "MEGAGRAMO_POR_METRO_CUBICO" },
    { codigo: "MEGANEWTON", nombre: "MEGANEWTON" },
    { codigo: "MEDIDOR_DE_MEGANEWTON", nombre: "MEDIDOR_DE_MEGANEWTON" },
    { codigo: "MEGAOHM", nombre: "MEGAOHM" },
    { codigo: "METRO_MEGAOHM", nombre: "METRO_MEGAOHM" },
    { codigo: "MEGASIEMENS_POR_METRO", nombre: "MEGASIEMENS_POR_METRO" },
    { codigo: "MEGAVOLTIO", nombre: "MEGAVOLTIO" },
    { codigo: "MEGAVOLT_POR_METRO", nombre: "MEGAVOLT_POR_METRO" },
    { codigo: "JULIOS_POR_METRO_CUBICO", nombre: "JULIOS_POR_METRO_CUBICO" },
    { codigo: "METRO_RECIPROCO_CUADRADO_RECIPROCO_SEGUNDO", nombre: "METRO_RECIPROCO_CUADRADO_RECIPROCO_SEGUNDO" },
    { codigo: "METRO_A_LA_CUARTA_POTENCIA", nombre: "METRO_A_LA_CUARTA_POTENCIA" },
    { codigo: "MICROAMPERIOS", nombre: "MICROAMPERIOS" },
    { codigo: "MICROBAR", nombre: "MICROBAR" },
    { codigo: "MICROCOULOMB", nombre: "MICROCOULOMB" },
    { codigo: "MICROCOULOMB_POR_METRO_CUBICO", nombre: "MICROCOULOMB_POR_METRO_CUBICO" },
    { codigo: "MICROCOULOMB_POR_METRO_CUADRADO", nombre: "MICROCOULOMB_POR_METRO_CUADRADO" },
    { codigo: "MICROFARADA_POR_METRO", nombre: "MICROFARADA_POR_METRO" },
    { codigo: "BATT", nombre: "BATT" },
    { codigo: "MICROHENRY", nombre: "MICROHENRY" },
    { codigo: "MICROHENRY_POR_METRO", nombre: "MICROHENRY_POR_METRO" },
    { codigo: "MICRONEWTON", nombre: "MICRONEWTON" },
    { codigo: "MEDIDOR_DE_MICRONEWTON", nombre: "MEDIDOR_DE_MICRONEWTON" },
    { codigo: "MICROOHM", nombre: "MICROOHM" },
    { codigo: "MEDIDOR_DE_MICROOHMIOS", nombre: "MEDIDOR_DE_MICROOHMIOS" },
    { codigo: "MICROPASCAL", nombre: "MICROPASCAL" },
    { codigo: "MICRORADIANO", nombre: "MICRORADIANO" },
    { codigo: "MICROSEGUNDO", nombre: "MICROSEGUNDO" },
    { codigo: "MICROSIEMENS", nombre: "MICROSIEMENS" },
    { codigo: "BARRA", nombre: "BARRA" },
    { codigo: "CAJA_BASE", nombre: "CAJA_BASE" },
    { codigo: "TABLERO", nombre: "TABLERO" },
    { codigo: "MANOJO", nombre: "MANOJO" },
    { codigo: "PIE_DE_TABLA", nombre: "PIE_DE_TABLA" },
    { codigo: "BOLSO", nombre: "BOLSO" },
    { codigo: "CEPILLO", nombre: "CEPILLO" },
    { codigo: "POTENCIA_AL_FRENO", nombre: "POTENCIA_AL_FRENO" },
    { codigo: "TRILLON_DE_DOLARES", nombre: "TRILLON_DE_DOLARES" },
    { codigo: "CANGILON", nombre: "CANGILON" },
    { codigo: "CESTA", nombre: "CESTA" },
    { codigo: "BALA", nombre: "BALA" },
    { codigo: "BARRIL_SECO", nombre: "BARRIL_SECO" },
    { codigo: "BARRIL_PETROLEO_ETC", nombre: "BARRIL_PETROLEO_ETC" },
    { codigo: "BOTELLA", nombre: "BOTELLA" },
    { codigo: "CIEN_PIES_DE_TABLA", nombre: "CIEN_PIES_DE_TABLA" },
    { codigo: "BECQUEREL", nombre: "BECQUEREL" },
    { codigo: "BAR", nombre: "BAR" },
    { codigo: "TORNILLO", nombre: "TORNILLO" },
    { codigo: "UNIDAD_TERMICA_BRITANICA", nombre: "UNIDAD_TERMICA_BRITANICA" },
    { codigo: "BUSHEL_EE_UU", nombre: "BUSHEL_EE_UU" },
    { codigo: "BUSHEL_REINO_UNIDO", nombre: "BUSHEL_REINO_UNIDO" },
    { codigo: "PESO_BASE", nombre: "PESO_BASE" },
    { codigo: "CAJA", nombre: "CAJA" },
    { codigo: "MILLONES_DE_BTUS", nombre: "MILLONES_DE_BTUS" },
    { codigo: "LLAMADA", nombre: "LLAMADA" },
    { codigo: "PRODUCTO_COMPUESTO_LIBRA_PESO_TOTAL", nombre: "PRODUCTO_COMPUESTO_LIBRA_PESO_TOTAL" },
    { codigo: "MILLIFARAD", nombre: "MILLIFARAD" },
    { codigo: "MILIGAL", nombre: "MILIGAL" },
    { codigo: "MILIGRAMO_POR_METRO", nombre: "MILIGRAMO_POR_METRO" },
    { codigo: "MILIGRAY", nombre: "MILIGRAY" },
    { codigo: "MILIHENRY", nombre: "MILIHENRY" },
    { codigo: "MILIJOULE", nombre: "MILIJOULE" },
    { codigo: "MILIMETRO_POR_SEGUNDO", nombre: "MILIMETRO_POR_SEGUNDO" },
    { codigo: "MILIMETRO_CUADRADO_POR_SEGUNDO", nombre: "MILIMETRO_CUADRADO_POR_SEGUNDO" },
    { codigo: "MILIMOL", nombre: "MILIMOL" },
    { codigo: "MOL_POR_KILOGRAMO", nombre: "MOL_POR_KILOGRAMO" },
    { codigo: "CARSET", nombre: "CARSET" },
    { codigo: "MILLINEWTON", nombre: "MILLINEWTON" },
    { codigo: "MILLINEWTON_POR_METRO", nombre: "MILLINEWTON_POR_METRO" },
    { codigo: "MEDIDOR_DE_MILIOHM", nombre: "MEDIDOR_DE_MILIOHM" },
    { codigo: "SEGUNDO_MILIPASCAL", nombre: "SEGUNDO_MILIPASCAL" },
    { codigo: "MILIRADIAN", nombre: "MILIRADIAN" },
    { codigo: "MILISEGUNDO", nombre: "MILISEGUNDO" },
    { codigo: "MILISIEMENS", nombre: "MILISIEMENS" },
    { codigo: "MILISIEVERT", nombre: "MILISIEVERT" },
    { codigo: "MILLITESLA", nombre: "MILLITESLA" },
    { codigo: "MICROVOLTIOS_POR_METRO", nombre: "MICROVOLTIOS_POR_METRO" },
    { codigo: "MILIVOLTIOS_POR_METRO", nombre: "MILIVOLTIOS_POR_METRO" },
    { codigo: "MILIVATIOS", nombre: "MILIVATIOS" },
    { codigo: "MILIVATIOS_POR_METRO_CUADRADO", nombre: "MILIVATIOS_POR_METRO_CUADRADO" },
    { codigo: "MILLIWEBER", nombre: "MILLIWEBER" },
    { codigo: "TOPO", nombre: "TOPO" },
    { codigo: "MOL_POR_DECIMETRO_CUBICO", nombre: "MOL_POR_DECIMETRO_CUBICO" },
    { codigo: "MOL_POR_METRO_CUBICO", nombre: "MOL_POR_METRO_CUBICO" },
    { codigo: "MOL_POR_LITRO", nombre: "MOL_POR_LITRO" },
    { codigo: "NANOAMPERE", nombre: "NANOAMPERE" },
    { codigo: "PARTIDO_DE_CARGA", nombre: "PARTIDO_DE_CARGA" },
    { codigo: "NANOCOULOMB", nombre: "NANOCOULOMB" },
    { codigo: "NANOFARAD", nombre: "NANOFARAD" },
    { codigo: "NANOFARAD_POR_METRO", nombre: "NANOFARAD_POR_METRO" },
    { codigo: "NANOHENRY", nombre: "NANOHENRY" },
    { codigo: "NANOHENRY_POR_METRO", nombre: "NANOHENRY_POR_METRO" },
    { codigo: "NANOMETRO", nombre: "NANOMETRO" },
    { codigo: "MEDIDOR_DE_NANOOHM", nombre: "MEDIDOR_DE_NANOOHM" },
    { codigo: "NANOSEGUNDO", nombre: "NANOSEGUNDO" },
    { codigo: "NANOTESLA", nombre: "NANOTESLA" },
    { codigo: "NANOVATIO", nombre: "NANOVATIO" },
    { codigo: "COSTO", nombre: "COSTO" },
    { codigo: "NEPER", nombre: "NEPER" },
    { codigo: "NEPER_POR_SEGUNDO", nombre: "NEPER_POR_SEGUNDO" },
    { codigo: "PICOMETRO", nombre: "PICOMETRO" },
    { codigo: "METRO_DE_NEWTON_SEGUNDO", nombre: "METRO_DE_NEWTON_SEGUNDO" },
    { codigo: "NEWTON_METRO_CUADRADO_KILOGRAMO_CUADRADO", nombre: "NEWTON_METRO_CUADRADO_KILOGRAMO_CUADRADO" },
    { codigo: "NEWTON_POR_METRO_CUADRADO", nombre: "NEWTON_POR_METRO_CUADRADO" },
    { codigo: "NEWTON_POR_MILIMETRO_CUADRADO", nombre: "NEWTON_POR_MILIMETRO_CUADRADO" },
    { codigo: "NEWTON_SEGUNDO", nombre: "NEWTON_SEGUNDO" },
    { codigo: "NEWTON_SEGUNDO_POR_METRO", nombre: "NEWTON_SEGUNDO_POR_METRO" },
    { codigo: "OCTAVA", nombre: "OCTAVA" },
    { codigo: "CELULA", nombre: "CELULA" },
    { codigo: "OHM_CENTIMETRO", nombre: "OHM_CENTIMETRO" },
    { codigo: "OHM_METRO", nombre: "OHM_METRO" },
    { codigo: "UNO", nombre: "UNO" },
    { codigo: "PARSEC", nombre: "PARSEC" },
    { codigo: "PASCAL_POR_KELVIN", nombre: "PASCAL_POR_KELVIN" },
    { codigo: "SEGUNDO_PASCAL", nombre: "SEGUNDO_PASCAL" },
    { codigo: "SEGUNDO_PASCAL_POR_METRO_CUBICO", nombre: "SEGUNDO_PASCAL_POR_METRO_CUBICO" },
    { codigo: "SEGUNDO_PASCAL_POR_METRO", nombre: "SEGUNDO_PASCAL_POR_METRO" },
    { codigo: "PETAJOULE", nombre: "PETAJOULE" },
    { codigo: "TELEFONO", nombre: "TELEFONO" },
    { codigo: "CENTIPOISE", nombre: "CENTIPOISE" },
    { codigo: "PICOAMPERE", nombre: "PICOAMPERE" },
    { codigo: "PICOCOULOMB", nombre: "PICOCOULOMB" },
    { codigo: "PICOFARAD_POR_METRO", nombre: "PICOFARAD_POR_METRO" },
    { codigo: "PICOHENRY", nombre: "PICOHENRY" },
    { codigo: "PICOWATT", nombre: "PICOWATT" },
    { codigo: "PICOWATT_POR_METRO_CUADRADO", nombre: "PICOWATT_POR_METRO_CUADRADO" },
    { codigo: "MEDIDOR_DE_LIBRAS", nombre: "MEDIDOR_DE_LIBRAS" },
    { codigo: "FUERZA_DE_LIBRA", nombre: "FUERZA_DE_LIBRA" },
    { codigo: "MILLICOULOMB_POR_KILOGRAMO", nombre: "MILLICOULOMB_POR_KILOGRAMO" },
    { codigo: "RAD", nombre: "RAD" },
    { codigo: "RADIAN", nombre: "RADIAN" },
    { codigo: "MEDIDOR_DE_RADIANES_AL_CUADRADO_POR_MOL", nombre: "MEDIDOR_DE_RADIANES_AL_CUADRADO_POR_MOL" },
    { codigo: "MEDIDOR_DE_RADIANES_AL_CUADRADO_POR_KILOGRAMO", nombre: "MEDIDOR_DE_RADIANES_AL_CUADRADO_POR_KILOGRAMO" },
    { codigo: "RADIAN_POR_METRO", nombre: "RADIAN_POR_METRO" },
    { codigo: "ANGSTROM_RECIPROCO", nombre: "ANGSTROM_RECIPROCO" },
    { codigo: "METRO_CUBICO_RECIPROCO", nombre: "METRO_CUBICO_RECIPROCO" },
    { codigo: "METRO_CUBICO_RECIPROCO_POR_SEGUNDO", nombre: "METRO_CUBICO_RECIPROCO_POR_SEGUNDO" },
    { codigo: "VOLTIOS_DE_ELECTRONES_RECIPROCOS_POR_METRO_CUBICO", nombre: "VOLTIOS_DE_ELECTRONES_RECIPROCOS_POR_METRO_CUBICO" },
    { codigo: "HENRY_RECIPROCO", nombre: "HENRY_RECIPROCO" },
    { codigo: "GRUPO_DE_BOBINA", nombre: "GRUPO_DE_BOBINA" },
    { codigo: "JOULE_RECIPROCO_POR_METRO_CUBICO", nombre: "JOULE_RECIPROCO_POR_METRO_CUBICO" },
    { codigo: "KELVIN_RECIPROCO_O_KELVIN_AL_PODER_MENOS_UNO", nombre: "KELVIN_RECIPROCO_O_KELVIN_AL_PODER_MENOS_UNO" },
    { codigo: "MEDIDOR_RECIPROCO", nombre: "MEDIDOR_RECIPROCO" },
    { codigo: "METRO_CUADRADO_RECIPROCO", nombre: "METRO_CUADRADO_RECIPROCO" },
    { codigo: "MINUTO_RECIPROCO", nombre: "MINUTO_RECIPROCO" },
    { codigo: "MOLE_RECIPROCO", nombre: "MOLE_RECIPROCO" },
    { codigo: "PASCAL_RECIPROCO_O_PASCAL_A_LA_POTENCIA_MENOS_UNO", nombre: "PASCAL_RECIPROCO_O_PASCAL_A_LA_POTENCIA_MENOS_UNO" },
    { codigo: "SEGUNDO_RECIPROCO", nombre: "SEGUNDO_RECIPROCO" },
    { codigo: "SEGUNDO_RECIPROCO_POR_METRO_CUBICO", nombre: "SEGUNDO_RECIPROCO_POR_METRO_CUBICO" },
    { codigo: "SEGUNDO_RECIPROCO_POR_METRO_CUADRADO", nombre: "SEGUNDO_RECIPROCO_POR_METRO_CUADRADO" },
    { codigo: "LATA", nombre: "LATA" },
    { codigo: "CAPACIDAD_DE_CARGA_EN_TONELADAS_METRICAS", nombre: "CAPACIDAD_DE_CARGA_EN_TONELADAS_METRICAS" },
    { codigo: "CANDELA", nombre: "CANDELA" },
    { codigo: "GRADO_CELSIUS", nombre: "GRADO_CELSIUS" },
    { codigo: "CIEN", nombre: "CIEN" },
    { codigo: "TARJETA", nombre: "TARJETA" },
    { codigo: "CENTIGRAMO", nombre: "CENTIGRAMO" },
    { codigo: "ENVASE", nombre: "ENVASE" },
    { codigo: "CONO", nombre: "CONO" },
    { codigo: "CONECTOR", nombre: "CONECTOR" },
    { codigo: "COULOMB_POR_KILOGRAMO", nombre: "COULOMB_POR_KILOGRAMO" },
    { codigo: "BOBINA", nombre: "BOBINA" },
    { codigo: "CIENTOS_DE_LICENCIA", nombre: "CIENTOS_DE_LICENCIA" },
    { codigo: "CENTILITRO", nombre: "CENTILITRO" },
    { codigo: "CENTIMETRO_CUADRADO", nombre: "CENTIMETRO_CUADRADO" },
    { codigo: "CENTIMETRO_CUBICO", nombre: "CENTIMETRO_CUBICO" },
    { codigo: "CENTIMETRO", nombre: "CENTIMETRO" },
    { codigo: "PAQUETE_DE_CIEN", nombre: "PAQUETE_DE_CIEN" },
    { codigo: "CENTAL_REINO_UNIDO", nombre: "CENTAL_REINO_UNIDO" },
    { codigo: "GARRAFON", nombre: "GARRAFON" },
    { codigo: "CULOMBIO", nombre: "CULOMBIO" },
    { codigo: "CARTUCHO", nombre: "CARTUCHO" },
    { codigo: "CAJON_DE_EMBALAJE", nombre: "CAJON_DE_EMBALAJE" },
    { codigo: "CASO", nombre: "CASO" },
    { codigo: "CAJA_DE_CARTON", nombre: "CAJA_DE_CARTON" },
    { codigo: "QUILATE_METRICO", nombre: "QUILATE_METRICO" },
    { codigo: "VASO", nombre: "VASO" },
    { codigo: "CURIE", nombre: "CURIE" },
    { codigo: "CUBRIR", nombre: "CUBRIR" },
    { codigo: "CIEN_LIBRAS_QUINTALES_DIVIDIDO_CIEN_PESOS_US", nombre: "CIEN_LIBRAS_QUINTALES_DIVIDIDO_CIEN_PESOS_US" },
    { codigo: "CIEN_PESOS_REINO_UNIDO", nombre: "CIEN_PESOS_REINO_UNIDO" },
    { codigo: "CILINDRO", nombre: "CILINDRO" },
    { codigo: "COMBO", nombre: "COMBO" },
    { codigo: "SEGUNDO_RECIPROCO_POR_ESTERADIANO", nombre: "SEGUNDO_RECIPROCO_POR_ESTERADIANO" },
    { codigo: "SIEMENS_POR_METRO", nombre: "SIEMENS_POR_METRO" },
    { codigo: "SIEMENS_METRO_CUADRADO_POR_MOL", nombre: "SIEMENS_METRO_CUADRADO_POR_MOL" },
    { codigo: "SIEVERT", nombre: "SIEVERT" },
    { codigo: "MIL_YARDAS_LINEALES", nombre: "MIL_YARDAS_LINEALES" },
    { codigo: "SONE", nombre: "SONE" },
    { codigo: "CENTIMETRO_CUADRADO_POR_ERGIO", nombre: "CENTIMETRO_CUADRADO_POR_ERGIO" },
    { codigo: "CENTIMETRO_CUADRADO_POR_ERG_ESTERLINA", nombre: "CENTIMETRO_CUADRADO_POR_ERG_ESTERLINA" },
    { codigo: "METRO_KELVIN", nombre: "METRO_KELVIN" },
    { codigo: "METRO_KELVIN", nombre: "METRO_KELVIN" },
    { codigo: "SEGUNDO_RECIPROCO_POR_METROS_CUADRADOS_ESTERADIANOS", nombre: "SEGUNDO_RECIPROCO_POR_METROS_CUADRADOS_ESTERADIANOS" },
    { codigo: "METRO_CUADRADO_POR_JULIO", nombre: "METRO_CUADRADO_POR_JULIO" },
    { codigo: "METRO_CUADRADO_POR_KILOGRAMO", nombre: "METRO_CUADRADO_POR_KILOGRAMO" },
    { codigo: "METRO_CUADRADO_POR_MOL", nombre: "METRO_CUADRADO_POR_MOL" },
    { codigo: "PLUMA_GRAMO_PROTEINA", nombre: "PLUMA_GRAMO_PROTEINA" },
    { codigo: "METRO_CUADRADO_POR_ESTERILIZADOR", nombre: "METRO_CUADRADO_POR_ESTERILIZADOR" },
    { codigo: "METRO_CUADRADO_POR_JULIOS_ESTERADIANOS", nombre: "METRO_CUADRADO_POR_JULIOS_ESTERADIANOS" },
    { codigo: "METRO_CUADRADO_POR_VOLTIO_SEGUNDO", nombre: "METRO_CUADRADO_POR_VOLTIO_SEGUNDO" },
    { codigo: "ESTERADIANO", nombre: "ESTERADIANO" },
    { codigo: "SIFON", nombre: "SIFON" },
    { codigo: "TERAHERCIOS", nombre: "TERAHERCIOS" },
    { codigo: "TERAJULIO", nombre: "TERAJULIO" },
    { codigo: "TERAVATIO", nombre: "TERAVATIO" },
    { codigo: "HORA_DE_TERAVATIO", nombre: "HORA_DE_TERAVATIO" },
    { codigo: "TESLA", nombre: "TESLA" },
    { codigo: "TEXAS", nombre: "TEXAS" },
    { codigo: "CALORIA_TERMOQUIMICA", nombre: "CALORIA_TERMOQUIMICA" },
    { codigo: "CALORIA_TERMOQUIMICA_POR_GRAMO_KELVIN", nombre: "CALORIA_TERMOQUIMICA_POR_GRAMO_KELVIN" },
    { codigo: "CALORIAS_TERMOQUIMICAS_POR_SEGUNDO_CENTIMETRO_KELVIN", nombre: "CALORIAS_TERMOQUIMICAS_POR_SEGUNDO_CENTIMETRO_KELVIN" },
    { codigo: "CALORIAS_TERMOQUIMICAS_POR_SEGUNDO_CENTIMETRO_CUADRADO_KELVIN", nombre: "CALORIAS_TERMOQUIMICAS_POR_SEGUNDO_CENTIMETRO_CUADRADO_KELVIN" },
    { codigo: "MIL_LITROS", nombre: "MIL_LITROS" },
    { codigo: "TONELADA_POR_METRO_CUBICO", nombre: "TONELADA_POR_METRO_CUBICO" },
    { codigo: "ANNO_TROPICAL", nombre: "ANNO_TROPICAL" },
    { codigo: "UNIDAD_DE_MASA_ATOMICA_UNIFICADA", nombre: "UNIDAD_DE_MASA_ATOMICA_UNIFICADA" },
    { codigo: "VAR", nombre: "VAR" },
    { codigo: "VOLTIOS_AL_CUADRADO_POR_KELVIN_AL_CUADRADO", nombre: "VOLTIOS_AL_CUADRADO_POR_KELVIN_AL_CUADRADO" },
    { codigo: "VOLTIO_AMPERIO", nombre: "VOLTIO_AMPERIO" },
    { codigo: "VOLTIO_POR_CENTIMETRO", nombre: "VOLTIO_POR_CENTIMETRO" },
    { codigo: "VOLTIO_POR_KELVIN", nombre: "VOLTIO_POR_KELVIN" },
    { codigo: "MILIVOLTIOS_POR_KELVIN", nombre: "MILIVOLTIOS_POR_KELVIN" },
    { codigo: "KILOGRAMO_POR_CENTIMETRO_CUADRADO", nombre: "KILOGRAMO_POR_CENTIMETRO_CUADRADO" },
    { codigo: "VOLTIOS_POR_METRO", nombre: "VOLTIOS_POR_METRO" },
    { codigo: "VOLTIOS_POR_MILIMETRO", nombre: "VOLTIOS_POR_MILIMETRO" },
    { codigo: "VATIOS_POR_KELVIN", nombre: "VATIOS_POR_KELVIN" },
    { codigo: "VATIOS_POR_METRO_KELVIN", nombre: "VATIOS_POR_METRO_KELVIN" },
    { codigo: "VATIOS_POR_METRO_CUADRADO", nombre: "VATIOS_POR_METRO_CUADRADO" },
    { codigo: "VATIOS_POR_METRO_CUADRADO_KELVIN", nombre: "VATIOS_POR_METRO_CUADRADO_KELVIN" },
    { codigo: "VATIOS_POR_METRO_CUADRADO_DE_KELVIN_A_LA_CUARTA_POTENCIA", nombre: "VATIOS_POR_METRO_CUADRADO_DE_KELVIN_A_LA_CUARTA_POTENCIA" },
    { codigo: "VATIOS_POR_STERADIAN", nombre: "VATIOS_POR_STERADIAN" },
    { codigo: "VATIOS_POR_METRO_CUADRADO_ESTERLINO", nombre: "VATIOS_POR_METRO_CUADRADO_ESTERLINO" },
    { codigo: "WEBER_POR_METRO", nombre: "WEBER_POR_METRO" },
    { codigo: "RONTGEN_POR_SEGUNDO", nombre: "RONTGEN_POR_SEGUNDO" },
    { codigo: "WEBER_POR_MILIMETRO", nombre: "WEBER_POR_MILIMETRO" },
    { codigo: "MINUTOS", nombre: "MINUTOS" },
    { codigo: "SEGUNDOS", nombre: "SEGUNDOS" },
    { codigo: "LIBRO", nombre: "LIBRO" },
    { codigo: "BLOQUEAR", nombre: "BLOQUEAR" },
    { codigo: "REDONDO", nombre: "REDONDO" },
    { codigo: "CASETE", nombre: "CASETE" },
    { codigo: "DOLAR_POR_HORA", nombre: "DOLAR_POR_HORA" },
    { codigo: "PULGADA_A_LA_CUARTA_POTENCIA", nombre: "PULGADA_A_LA_CUARTA_POTENCIA" },
    { codigo: "SANDWICH", nombre: "SANDWICH" },
    { codigo: "TABLA_INTERNACIONAL_CALORIA", nombre: "TABLA_INTERNACIONAL_CALORIA" },
    { codigo: "TABLA_INTERNACIONAL_CALORIAS_POR_SEGUNDO_CENTIMETRO_KELVIN", nombre: "TABLA_INTERNACIONAL_CALORIAS_POR_SEGUNDO_CENTIMETRO_KELVIN" },
    { codigo: "TABLA_INTERNACIONAL_CALORIAS_POR_SEGUNDO_CENTIMETRO_CUADRADO_KELVIN", nombre: "TABLA_INTERNACIONAL_CALORIAS_POR_SEGUNDO_CENTIMETRO_CUADRADO_KELVIN" },
    { codigo: "JOULE_METRO_CUADRADO", nombre: "JOULE_METRO_CUADRADO" },
    { codigo: "KILOGRAMO_POR_MOL", nombre: "KILOGRAMO_POR_MOL" },
    { codigo: "TABLA_INTERNACIONAL_CALORIAS_POR_GRAMO", nombre: "TABLA_INTERNACIONAL_CALORIAS_POR_GRAMO" },
    { codigo: "TABLA_INTERNACIONAL_CALORIAS_POR_GRAMO_KELVIN", nombre: "TABLA_INTERNACIONAL_CALORIAS_POR_GRAMO_KELVIN" },
    { codigo: "MEGACOULOMB", nombre: "MEGACOULOMB" },
    { codigo: "HAZ", nombre: "HAZ" },
    { codigo: "PUNTAJE_DE_DRENAJE", nombre: "PUNTAJE_DE_DRENAJE" },
    { codigo: "MICROWATT", nombre: "MICROWATT" },
    { codigo: "MICROTESLA", nombre: "MICROTESLA" },
    { codigo: "MICROVOLTIO", nombre: "MICROVOLTIO" },
    { codigo: "MEDIDOR_DE_MILLINEWTON", nombre: "MEDIDOR_DE_MILLINEWTON" },
    { codigo: "MICROWATT_POR_METRO_CUADRADO", nombre: "MICROWATT_POR_METRO_CUADRADO" },
    { codigo: "MILLICOULOMB", nombre: "MILLICOULOMB" },
    { codigo: "MILIMOL_POR_KILOGRAMO", nombre: "MILIMOL_POR_KILOGRAMO" },
    { codigo: "MILLICOULOMB_POR_METRO_CUBICO", nombre: "MILLICOULOMB_POR_METRO_CUBICO" },
    { codigo: "MILLICOULOMB_POR_METRO_CUADRADO", nombre: "MILLICOULOMB_POR_METRO_CUADRADO" },
    { codigo: "DINA_POR_CENTIMETRO_CUADRADO", nombre: "DINA_POR_CENTIMETRO_CUADRADO" },
    { codigo: "METRO_CUBICO_NETO", nombre: "METRO_CUBICO_NETO" },
    { codigo: "MOVIMIENTO_RAPIDO_DEL_OJO", nombre: "MOVIMIENTO_RAPIDO_DEL_OJO" },
    { codigo: "BANDA", nombre: "BANDA" },
    { codigo: "SEGUNDO_POR_METRO_CUBICO", nombre: "SEGUNDO_POR_METRO_CUBICO" },
    { codigo: "SEGUNDO_POR_METRO_CUBICO_RADIANES", nombre: "SEGUNDO_POR_METRO_CUBICO_RADIANES" },
    { codigo: "JULIOS_POR_GRAMO", nombre: "JULIOS_POR_GRAMO" },
    { codigo: "LIBRA_BRUTA", nombre: "LIBRA_BRUTA" },
    { codigo: "CARGA_DE_PALET_UNIDAD", nombre: "CARGA_DE_PALET_UNIDAD" },
    { codigo: "LIBRA_DE_MASA", nombre: "LIBRA_DE_MASA" },
    { codigo: "MANGA", nombre: "MANGA" },
    { codigo: "DESPRECIAR", nombre: "DESPRECIAR" },
    { codigo: "DIEZ_DIAS", nombre: "DIEZ_DIAS" },
    { codigo: "DIA", nombre: "DIA" },
    { codigo: "LIBRA_SECA", nombre: "LIBRA_SECA" },
    { codigo: "DISCO", nombre: "DISCO" },
    { codigo: "LA_LICENCIATURA", nombre: "LA_LICENCIATURA" },
    { codigo: "ACUERDO", nombre: "ACUERDO" },
    { codigo: "DECADA", nombre: "DECADA" },
    { codigo: "DECIGRAMO", nombre: "DECIGRAMO" },
    { codigo: "DISPENSADOR", nombre: "DISPENSADOR" },
    { codigo: "DECAGRAMO", nombre: "DECAGRAMO" },
    { codigo: "DECILITRO", nombre: "DECILITRO" },
    { codigo: "DECIMETRO_CUADRADO", nombre: "DECIMETRO_CUADRADO" },
    { codigo: "DECIMETRO_CUBICO", nombre: "DECIMETRO_CUBICO" },
    { codigo: "DECIMETRO", nombre: "DECIMETRO" },
    { codigo: "MEDIDOR_DE_DECINEWTON", nombre: "MEDIDOR_DE_DECINEWTON" },
    { codigo: "DOCENA_PIEZA", nombre: "DOCENA_PIEZA" },
    { codigo: "DOCENA_PAR", nombre: "DOCENA_PAR" },
    { codigo: "TONELAJE_DE_DESPLAZAMIENTO", nombre: "TONELAJE_DE_DESPLAZAMIENTO" },
    { codigo: "REGISTRO_DE_DATOS", nombre: "REGISTRO_DE_DATOS" },
    { codigo: "TAMBOR", nombre: "TAMBOR" },
    { codigo: "DRAM_US", nombre: "DRAM_US" },
    { codigo: "DRAM_REINO_UNIDO", nombre: "DRAM_REINO_UNIDO" },
    { codigo: "DOCENA_ROLLO", nombre: "DOCENA_ROLLO" },
    { codigo: "DRACMA_REINO_UNIDO", nombre: "DRACMA_REINO_UNIDO" },
    { codigo: "MONITOR", nombre: "MONITOR" },
    { codigo: "TONELADA_SECA", nombre: "TONELADA_SECA" },
    { codigo: "DECITONNE", nombre: "DECITONNE" },
    { codigo: "DINA", nombre: "DINA" },
    { codigo: "PENNYWEIGHT", nombre: "PENNYWEIGHT" },
    { codigo: "DINA_POR_CENTIMETRO", nombre: "DINA_POR_CENTIMETRO" },
    { codigo: "LIBRO_DE_DIRECTORIO", nombre: "LIBRO_DE_DIRECTORIO" },
    { codigo: "DOCENA", nombre: "DOCENA" },
    { codigo: "PAQUETE_DE_DOCE", nombre: "PAQUETE_DE_DOCE" },
    { codigo: "CINTURON", nombre: "CINTURON" },
    { codigo: "REMOLQUE", nombre: "REMOLQUE" },
    { codigo: "KILOGRAMO_BRUTO", nombre: "KILOGRAMO_BRUTO" },
    { codigo: "TONELADA_METRICA_LARGA", nombre: "TONELADA_METRICA_LARGA" },
    { codigo: "CADA", nombre: "CADA" },
    { codigo: "CASILLA_DE_CORREO_ELECTRONICO", nombre: "CASILLA_DE_CORREO_ELECTRONICO" },
    { codigo: "CADA_UNO_POR_MES", nombre: "CADA_UNO_POR_MES" },
    { codigo: "PAQUETE_DE_ONCE", nombre: "PAQUETE_DE_ONCE" },
    { codigo: "GALON_EQUIVALENTE", nombre: "GALON_EQUIVALENTE" },
    { codigo: "SOBRE", nombre: "SOBRE" },
    { codigo: "MIL_PIES_CUBICOS_POR_DIA", nombre: "MIL_PIES_CUBICOS_POR_DIA" },
    { codigo: "FIBRA_POR_CENTIMETRO_CUBICO_DE_AIRE", nombre: "FIBRA_POR_CENTIMETRO_CUBICO_DE_AIRE" },
    { codigo: "GRADO_FAHRENHEIT", nombre: "GRADO_FAHRENHEIT" },
    { codigo: "FARADIO", nombre: "FARADIO" },
    { codigo: "CAMPO", nombre: "CAMPO" },
    { codigo: "MIL_PIES_CUBICOS", nombre: "MIL_PIES_CUBICOS" },
    { codigo: "MILLON_DE_PARTICULAS_POR_PIE_CUBICO", nombre: "MILLON_DE_PARTICULAS_POR_PIE_CUBICO" },
    { codigo: "PIE_DE_PISTA", nombre: "PIE_DE_PISTA" },
    { codigo: "CIEN_METROS_CUBICOS", nombre: "CIEN_METROS_CUBICOS" },
    { codigo: "PARCHE_TRANSDERMICO", nombre: "PARCHE_TRANSDERMICO" },
    { codigo: "MICROMOL", nombre: "MICROMOL" },
    { codigo: "TONELADA_EN_ESCAMAS", nombre: "TONELADA_EN_ESCAMAS" },
    { codigo: "MILLONES_DE_PIES_CUBICOS", nombre: "MILLONES_DE_PIES_CUBICOS" },
    { codigo: "PIE", nombre: "PIE" },
    { codigo: "LIBRA_POR_PIE_CUADRADO", nombre: "LIBRA_POR_PIE_CUADRADO" },
    { codigo: "PIE_POR_MINUTO", nombre: "PIE_POR_MINUTO" },
    { codigo: "PIE_POR_SEGUNDO", nombre: "PIE_POR_SEGUNDO" },
    { codigo: "PIE_CUADRADO", nombre: "PIE_CUADRADO" },
    { codigo: "PIE_CUBICO", nombre: "PIE_CUBICO" },
    { codigo: "US_GALONES_POR_MINUTO", nombre: "US_GALONES_POR_MINUTO" },
    { codigo: "GALON_IMPERIAL_POR_MINUTO", nombre: "GALON_IMPERIAL_POR_MINUTO" },
    { codigo: "HOJA_DE_MICROFICHA", nombre: "HOJA_DE_MICROFICHA" },
    { codigo: "GALON_US_POR_DIA", nombre: "GALON_US_POR_DIA" },
    { codigo: "GIGABECQUEREL", nombre: "GIGABECQUEREL" },
    { codigo: "GRAMO_POR_100_GRAMO", nombre: "GRAMO_POR_100_GRAMO" },
    { codigo: "BARRIL_BRUTO", nombre: "BARRIL_BRUTO" },
    { codigo: "LIBRA_POR_GALON_US", nombre: "LIBRA_POR_GALON_US" },
    { codigo: "GRAMO_POR_METRO_GRAMO_POR_100_CENTIMETROS", nombre: "GRAMO_POR_METRO_GRAMO_POR_100_CENTIMETROS" },
    { codigo: "GRAMO_DE_ISOTOPO_FISIONABLE", nombre: "GRAMO_DE_ISOTOPO_FISIONABLE" },
    { codigo: "DOCENA_GRUESA", nombre: "DOCENA_GRUESA" },
    { codigo: "MEDIO_GALON_EE_UU", nombre: "MEDIO_GALON_EE_UU" },
    { codigo: "BRANQUIAS", nombre: "BRANQUIAS" },
    { codigo: "GILL_REINO_UNIDO", nombre: "GILL_REINO_UNIDO" },
    { codigo: "GRAMO_POR_MILILITRO", nombre: "GRAMO_POR_MILILITRO" },
    { codigo: "GRAMO_POR_KILOGRAMO", nombre: "GRAMO_POR_KILOGRAMO" },
    { codigo: "GRAMO_POR_LITRO", nombre: "GRAMO_POR_LITRO" },
    { codigo: "GALON_SECO_EE_UU", nombre: "GALON_SECO_EE_UU" },
    { codigo: "GALON_REINO_UNIDO", nombre: "GALON_REINO_UNIDO" },
    { codigo: "GALON_US", nombre: "GALON_US" },
    { codigo: "GRAMO_POR_METRO_CUADRADO", nombre: "GRAMO_POR_METRO_CUADRADO" },
    { codigo: "GALON_BRUTO", nombre: "GALON_BRUTO" },
    { codigo: "MILIGRAMOS_POR_METRO_CUADRADO", nombre: "MILIGRAMOS_POR_METRO_CUADRADO" },
    { codigo: "MILIGRAMO_POR_METRO_CUBICO", nombre: "MILIGRAMO_POR_METRO_CUBICO" },
    { codigo: "MICROGRAMOS_POR_METRO_CUBICO", nombre: "MICROGRAMOS_POR_METRO_CUBICO" },
    { codigo: "GRAMO", nombre: "GRAMO" },
    { codigo: "GRANO", nombre: "GRANO" },
    { codigo: "BRUTO", nombre: "BRUTO" },
    { codigo: "TONELADA_DE_REGISTRO_BRUTO", nombre: "TONELADA_DE_REGISTRO_BRUTO" },
    { codigo: "TONELADA_BRUTA", nombre: "TONELADA_BRUTA" },
    { codigo: "GIGAJOULE", nombre: "GIGAJOULE" },
    { codigo: "GALON_POR_MIL_PIES_CUBICOS", nombre: "GALON_POR_MIL_PIES_CUBICOS" },
    { codigo: "HORA_DE_GIGAVATIOS", nombre: "HORA_DE_GIGAVATIOS" },
    { codigo: "PATIO_BRUTO", nombre: "PATIO_BRUTO" },
    { codigo: "SISTEMA_DE_MEDICION", nombre: "SISTEMA_DE_MEDICION" },
    { codigo: "MEDIA_PAGINA_ELECTRONICA", nombre: "MEDIA_PAGINA_ELECTRONICA" },
    { codigo: "MEDIO_LITRO", nombre: "MEDIO_LITRO" },
    { codigo: "MADEJO", nombre: "MADEJO" },
    { codigo: "HECTAREA", nombre: "HECTAREA" },
    { codigo: "HECTOBAR", nombre: "HECTOBAR" },
    { codigo: "CIEN_CAJAS", nombre: "CIEN_CAJAS" },
    { codigo: "CIEN_CUENTAS", nombre: "CIEN_CUENTAS" },
    { codigo: "MEDIA_DOCENA", nombre: "MEDIA_DOCENA" },
    { codigo: "CENTESIMA_DE_QUILATE", nombre: "CENTESIMA_DE_QUILATE" },
    { codigo: "CIEN_PIES", nombre: "CIEN_PIES" },
    { codigo: "HECTOGRAMO", nombre: "HECTOGRAMO" },
    { codigo: "CIEN_PIES_CUBICOS", nombre: "CIEN_PIES_CUBICOS" },
    { codigo: "CIEN_HOJAS", nombre: "CIEN_HOJAS" },
    { codigo: "CIEN_UNIDADES_INTERNACIONALES", nombre: "CIEN_UNIDADES_INTERNACIONALES" },
    { codigo: "CABALLO_METRICO", nombre: "CABALLO_METRICO" },
    { codigo: "CIEN_KILOGRAMOS", nombre: "CIEN_KILOGRAMOS" },
    { codigo: "CIEN_PIES_LINEALES", nombre: "CIEN_PIES_LINEALES" },
    { codigo: "HECTOLITRO", nombre: "HECTOLITRO" },
    { codigo: "MILLA_POR_HORA", nombre: "MILLA_POR_HORA" },
    { codigo: "MILLONES_DE_METROS_CUBICOS", nombre: "MILLONES_DE_METROS_CUBICOS" },
    { codigo: "HECTOMETRO", nombre: "HECTOMETRO" },
    { codigo: "MILIMETRO_CONVENCIONAL_DE_MERCURIO", nombre: "MILIMETRO_CONVENCIONAL_DE_MERCURIO" },
    { codigo: "CIEN_ONZAS_TROY", nombre: "CIEN_ONZAS_TROY" },
    { codigo: "MILIMETRO_CONVENCIONAL_DE_AGUA", nombre: "MILIMETRO_CONVENCIONAL_DE_AGUA" },
    { codigo: "HECTOLITRO_DE_ALCOHOL_PURO", nombre: "HECTOLITRO_DE_ALCOHOL_PURO" },
    { codigo: "CIEN_PIES_CUADRADOS", nombre: "CIEN_PIES_CUADRADOS" },
    { codigo: "MEDIA_HORA", nombre: "MEDIA_HORA" },
    { codigo: "HERTZ", nombre: "HERTZ" },
    { codigo: "HORA", nombre: "HORA" },
    { codigo: "CIEN_YARDAS", nombre: "CIEN_YARDAS" },
    { codigo: "PULGADA_LIBRA", nombre: "PULGADA_LIBRA" },
    { codigo: "CONTAR_POR_PULGADA", nombre: "CONTAR_POR_PULGADA" },
    { codigo: "PERSONA", nombre: "PERSONA" },
    { codigo: "PULGADAS_DE_AGUA", nombre: "PULGADAS_DE_AGUA" },
    { codigo: "COLUMNA_PULGADA", nombre: "COLUMNA_PULGADA" },
    { codigo: "PULGADA_POR_MINUTO", nombre: "PULGADA_POR_MINUTO" },
    { codigo: "IMPRESION", nombre: "IMPRESION" },
    { codigo: "PULGADA", nombre: "PULGADA" },
    { codigo: "PULGADA_CUADRADA", nombre: "PULGADA_CUADRADA" },
    { codigo: "PULGADA_EN_CUBOS", nombre: "PULGADA_EN_CUBOS" },
    { codigo: "POLIZA_DE_SEGUROS", nombre: "POLIZA_DE_SEGUROS" },
    { codigo: "CONTEO_POR_CENTIMETRO", nombre: "CONTEO_POR_CENTIMETRO" },
    { codigo: "PULGADA_POR_SEGUNDO_VELOCIDAD_LINEAL", nombre: "PULGADA_POR_SEGUNDO_VELOCIDAD_LINEAL" },
    { codigo: "PULGADA_POR_SEGUNDO_AL_CUADRADO_ACELERACION", nombre: "PULGADA_POR_SEGUNDO_AL_CUADRADO_ACELERACION" },
    { codigo: "JULIOS_POR_KILOGRAMO", nombre: "JULIOS_POR_KILOGRAMO" },
    { codigo: "JUMBO", nombre: "JUMBO" },
    { codigo: "JOULE_POR_KELVIN", nombre: "JOULE_POR_KELVIN" },
    { codigo: "JARRA", nombre: "JARRA" },
    { codigo: "MEGAJULIO_POR_KILOGRAMO", nombre: "MEGAJULIO_POR_KILOGRAMO" },
    { codigo: "MEGAJULIO_POR_METRO_CUBICO", nombre: "MEGAJULIO_POR_METRO_CUBICO" },
    { codigo: "ARTICULACION", nombre: "ARTICULACION" },
    { codigo: "JOULE", nombre: "JOULE" },
    { codigo: "TARRO", nombre: "TARRO" },
    { codigo: "DEMANDA_DE_KILOVATIOS", nombre: "DEMANDA_DE_KILOVATIOS" },
    { codigo: "KILOVOLTIOS_AMPERIOS_REACTIVOS_DE_DEMANDA", nombre: "KILOVOLTIOS_AMPERIOS_REACTIVOS_DE_DEMANDA" },
    { codigo: "KILOVOLTIO_AMPERIO_HORA_REACTIVA", nombre: "KILOVOLTIO_AMPERIO_HORA_REACTIVA" },
    { codigo: "AMPERIOS_KILOVOLTIOS_REACTIVOS", nombre: "AMPERIOS_KILOVOLTIOS_REACTIVOS" },
    { codigo: "KILOLITRO", nombre: "KILOLITRO" },
    { codigo: "PASTEL", nombre: "PASTEL" },
    { codigo: "KILOCHARACTER", nombre: "KILOCHARACTER" },
    { codigo: "KILOBAR", nombre: "KILOBAR" },
    { codigo: "KILOGRAMO_DECIMAL", nombre: "KILOGRAMO_DECIMAL" },
    { codigo: "KELVIN", nombre: "KELVIN" },
    { codigo: "KILOPACKET", nombre: "KILOPACKET" },
    { codigo: "BARRILETE", nombre: "BARRILETE" },
    { codigo: "KILOGRAMO", nombre: "KILOGRAMO" },
    { codigo: "KILOGRAMO_POR_SEGUNDO", nombre: "KILOGRAMO_POR_SEGUNDO" },
    { codigo: "KILOHERCIO", nombre: "KILOHERCIO" },
    { codigo: "KILOGRAMO_POR_MILIMETRO_DE_ANCHO", nombre: "KILOGRAMO_POR_MILIMETRO_DE_ANCHO" },
    { codigo: "KILOSEGMENTO", nombre: "KILOSEGMENTO" },
    { codigo: "KILOJOULE", nombre: "KILOJOULE" },
    { codigo: "KILOGRAMO_POR_METRO", nombre: "KILOGRAMO_POR_METRO" },
    { codigo: "KILOMETRO_POR_HORA", nombre: "KILOMETRO_POR_HORA" },
    { codigo: "KILOMETRO_CUADRADO", nombre: "KILOMETRO_CUADRADO" },
    { codigo: "KILOGRAMO_POR_METRO_CUBICO", nombre: "KILOGRAMO_POR_METRO_CUBICO" },
    { codigo: "KILOGRAMO_DE_NITROGENO", nombre: "KILOGRAMO_DE_NITROGENO" },
    { codigo: "KILOGRAMO_DE_SUSTANCIA_NOMBRADA", nombre: "KILOGRAMO_DE_SUSTANCIA_NOMBRADA" },
    { codigo: "NUDO", nombre: "NUDO" },
    { codigo: "MILLIEQUIVALENCIA_DE_POTASA_CAUSTICA_POR_GRAMO_DE_PRODUCTO", nombre: "MILLIEQUIVALENCIA_DE_POTASA_CAUSTICA_POR_GRAMO_DE_PRODUCTO" },
    { codigo: "KILOPASCAL", nombre: "KILOPASCAL" },
    { codigo: "KILOGRAMO_DE_HIDROXIDO_DE_POTASIO_POTASA_CAUSTICA", nombre: "KILOGRAMO_DE_HIDROXIDO_DE_POTASIO_POTASA_CAUSTICA" },
    { codigo: "KILOGRAMO_DE_OXIDO_DE_POTASIO", nombre: "KILOGRAMO_DE_OXIDO_DE_POTASIO" },
    { codigo: "KILOGRAMO_DE_PENTOXIDO_DE_FOSFORO_ANHIDRIDO_FOSFORICO", nombre: "KILOGRAMO_DE_PENTOXIDO_DE_FOSFORO_ANHIDRIDO_FOSFORICO" },
    { codigo: "KILORONTGEN", nombre: "KILORONTGEN" },
    { codigo: "MIL_LIBRAS_POR_PULGADA_CUADRADA", nombre: "MIL_LIBRAS_POR_PULGADA_CUADRADA" },
    { codigo: "KILOGRAMO_DE_SUSTANCIA_NOVENTA_PORCIENTO_SECA", nombre: "KILOGRAMO_DE_SUSTANCIA_NOVENTA_PORCIENTO_SECA" },
    { codigo: "KILOGRAMO_DE_HIDROXIDO_DE_SODIO_SODA_CAUSTICA", nombre: "KILOGRAMO_DE_HIDROXIDO_DE_SODIO_SODA_CAUSTICA" },
    { codigo: "EQUIPO", nombre: "EQUIPO" },
    { codigo: "KILOMETRO", nombre: "KILOMETRO" },
    { codigo: "KILOTONNE", nombre: "KILOTONNE" },
    { codigo: "KILOGRAMO_DE_URANIO", nombre: "KILOGRAMO_DE_URANIO" },
    { codigo: "KILOVOLTIO_AMPERE", nombre: "KILOVOLTIO_AMPERE" },
    { codigo: "KILOVAR", nombre: "KILOVAR" },
    { codigo: "KILOVOLTIO", nombre: "KILOVOLTIO" },
    { codigo: "KILOGRAMOS_POR_MILIMETRO", nombre: "KILOGRAMOS_POR_MILIMETRO" },
    { codigo: "KILOVATIOS_HORA", nombre: "KILOVATIOS_HORA" },
    { codigo: "KILOVATIO", nombre: "KILOVATIO" },
    { codigo: "MILILITRO_POR_KILOGRAMO", nombre: "MILILITRO_POR_KILOGRAMO" },
    { codigo: "LITRO_POR_MINUTO", nombre: "LITRO_POR_MINUTO" },
    { codigo: "LIBRA_POR_PULGADA_CUBICA", nombre: "LIBRA_POR_PULGADA_CUBICA" },
    { codigo: "LIBRA", nombre: "LIBRA" },
    { codigo: "LIBRA_TROY", nombre: "LIBRA_TROY" },
    { codigo: "CENTIMETRO_LINEAL", nombre: "CENTIMETRO_LINEAL" },
    { codigo: "LITRO_POR_DIA", nombre: "LITRO_POR_DIA" },
    { codigo: "LITE", nombre: "LITE" },
    { codigo: "HOJA", nombre: "HOJA" },
    { codigo: "PIE_LINEAL", nombre: "PIE_LINEAL" },
    { codigo: "HORA_DE_TRABAJO", nombre: "HORA_DE_TRABAJO" },
    { codigo: "PULGADA_LINEAL", nombre: "PULGADA_LINEAL" },
    { codigo: "SPRAY_GRANDE", nombre: "SPRAY_GRANDE" },
    { codigo: "ENLAZAR", nombre: "ENLAZAR" },
    { codigo: "METRO_LINEAL", nombre: "METRO_LINEAL" },
    { codigo: "LONGITUD", nombre: "LONGITUD" },
    { codigo: "MUCHO", nombre: "MUCHO" },
    { codigo: "LIBRA_LIQUIDA", nombre: "LIBRA_LIQUIDA" },
    { codigo: "LITRO_DE_ALCOHOL_PURO", nombre: "LITRO_DE_ALCOHOL_PURO" },
    { codigo: "CAPA", nombre: "CAPA" },
    { codigo: "SUMA_GLOBAL", nombre: "SUMA_GLOBAL" },
    { codigo: "TON_REINO_UNIDO_O_LONGTON_EEUU", nombre: "TON_REINO_UNIDO_O_LONGTON_EEUU" },
    { codigo: "LITRO", nombre: "LITRO" },
    { codigo: "LUMEN", nombre: "LUMEN" },
    { codigo: "LUX", nombre: "LUX" },
    { codigo: "YARDA_LINEAL_POR_LIBRA", nombre: "YARDA_LINEAL_POR_LIBRA" },
    { codigo: "YARDA_LINEAL", nombre: "YARDA_LINEAL" },
    { codigo: "CINTA_MAGNETICA", nombre: "CINTA_MAGNETICA" },
    { codigo: "MILIGRAMOS_POR_LITRO", nombre: "MILIGRAMOS_POR_LITRO" },
    { codigo: "VALOR_MONETARIO", nombre: "VALOR_MONETARIO" },
    { codigo: "MICROCURIE", nombre: "MICROCURIE" },
    { codigo: "MICROPULGADA", nombre: "MICROPULGADA" },
    { codigo: "MILLONES_DE_BTU_POR_1000_PIES_CUBICOS", nombre: "MILLONES_DE_BTU_POR_1000_PIES_CUBICOS" },
    { codigo: "MAQUINA_POR_UNIDAD", nombre: "MAQUINA_POR_UNIDAD" },
    { codigo: "MEGA_LITRO", nombre: "MEGA_LITRO" },
    { codigo: "MEGAMETRO", nombre: "MEGAMETRO" },
    { codigo: "MEGAVATIO", nombre: "MEGAVATIO" },
    { codigo: "MIL_EQUIVALENTES_DE_LADRILLO_ESTANDAR", nombre: "MIL_EQUIVALENTES_DE_LADRILLO_ESTANDAR" },
    { codigo: "MIL_PIES_DE_TABLA", nombre: "MIL_PIES_DE_TABLA" },
    { codigo: "MILIBAR", nombre: "MILIBAR" },
    { codigo: "MICROGRAMO", nombre: "MICROGRAMO" },
    { codigo: "MILICURIE", nombre: "MILICURIE" },
    { codigo: "AIRE_SECO_TONELADA_METRICA", nombre: "AIRE_SECO_TONELADA_METRICA" },
    { codigo: "MILIGRAMO_POR_PIE_CUADRADO_POR_LADO", nombre: "MILIGRAMO_POR_PIE_CUADRADO_POR_LADO" },
    { codigo: "MILIGRAMO", nombre: "MILIGRAMO" },
    { codigo: "MEGAHERCIO", nombre: "MEGAHERCIO" },
    { codigo: "MILLA_CUADRADA", nombre: "MILLA_CUADRADA" },
    { codigo: "MIL", nombre: "MIL" },
    { codigo: "MINUTO", nombre: "MINUTO" },
    { codigo: "MILLON", nombre: "MILLON" },
    { codigo: "MILLONES_DE_UNIDADES_INTERNACIONALES", nombre: "MILLONES_DE_UNIDADES_INTERNACIONALES" },
    { codigo: "MILIGRAMO_POR_PULGADA_CUADRADA", nombre: "MILIGRAMO_POR_PULGADA_CUADRADA" },
    { codigo: "MIL_MILLONES", nombre: "MIL_MILLONES" },
    { codigo: "MILILITRO", nombre: "MILILITRO" },
    { codigo: "MILIMETRO_CUADRADO", nombre: "MILIMETRO_CUADRADO" },
    { codigo: "MILIMETRO_CUBICO", nombre: "MILIMETRO_CUBICO" },
    { codigo: "MILIMETRO", nombre: "MILIMETRO" },
    { codigo: "MES", nombre: "MES" },
    { codigo: "MEGAPASCAL", nombre: "MEGAPASCAL" },
    { codigo: "MIL_METROS", nombre: "MIL_METROS" },
    { codigo: "METRO_CUBICO_POR_HORA", nombre: "METRO_CUBICO_POR_HORA" },
    { codigo: "METRO_CUBICO_POR_SEGUNDO", nombre: "METRO_CUBICO_POR_SEGUNDO" },
    { codigo: "METRO_POR_SEGUNDO_AL_CUADRADO", nombre: "METRO_POR_SEGUNDO_AL_CUADRADO" },
    { codigo: "ESTERA", nombre: "ESTERA" },
    { codigo: "METRO_CUADRADO", nombre: "METRO_CUADRADO" },
    { codigo: "METRO_CUBICO", nombre: "METRO_CUBICO" },
    { codigo: "METRO", nombre: "METRO" },
    { codigo: "METRO_POR_SEGUNDO", nombre: "METRO_POR_SEGUNDO" },
    { codigo: "NUMERO_DE_MULTS", nombre: "NUMERO_DE_MULTS" },
    { codigo: "MEGAVOLT_AMPERE", nombre: "MEGAVOLT_AMPERE" },
    { codigo: "MEGAVATIOS_HORA", nombre: "MEGAVATIOS_HORA" },
    { codigo: "CALORIAS_DE_LA_PLUMA", nombre: "CALORIAS_DE_LA_PLUMA" },
    { codigo: "NUMERO_DE_LINEAS", nombre: "NUMERO_DE_LINEAS" },
    { codigo: "PUNTO_DE_IMPRESION", nombre: "PUNTO_DE_IMPRESION" },
    { codigo: "MILIGRAMO_POR_KILOGRAMO", nombre: "MILIGRAMO_POR_KILOGRAMO" },
    { codigo: "NUMERO_DE_ARTICULOS", nombre: "NUMERO_DE_ARTICULOS" },
    { codigo: "BARCAZA", nombre: "BARCAZA" },
    { codigo: "NUMERO_DE_BOBINAS", nombre: "NUMERO_DE_BOBINAS" },
    { codigo: "COCHE", nombre: "COCHE" },
    { codigo: "NUMERO_DE_CELDAS", nombre: "NUMERO_DE_CELDAS" },
    { codigo: "BARRIL_NETO", nombre: "BARRIL_NETO" },
    { codigo: "LITRO_NETO", nombre: "LITRO_NETO" },
    { codigo: "NEWTON", nombre: "NEWTON" },
    { codigo: "MENSAJE", nombre: "MENSAJE" },
    { codigo: "GALON_NETO_US", nombre: "GALON_NETO_US" },
    { codigo: "HORA_DEL_MENSAJE", nombre: "HORA_DEL_MENSAJE" },
    { codigo: "GALON_IMPERIAL_NETO", nombre: "GALON_IMPERIAL_NETO" },
    { codigo: "NUMERO_DE_UNIDADES_INTERNACIONALES", nombre: "NUMERO_DE_UNIDADES_INTERNACIONALES" },
    { codigo: "NUMERO_DE_PANTALLAS", nombre: "NUMERO_DE_PANTALLAS" },
    { codigo: "CARGA", nombre: "CARGA" },
    { codigo: "MILLA_NAUTICA", nombre: "MILLA_NAUTICA" },
    { codigo: "NUMERO_DE_PAQUETES", nombre: "NUMERO_DE_PAQUETES" },
    { codigo: "ENTRENAR", nombre: "ENTRENAR" },
    { codigo: "NUMERO_DE_PARCELAS", nombre: "NUMERO_DE_PARCELAS" },
    { codigo: "NUMERO_DE_PARES", nombre: "NUMERO_DE_PARES" },
    { codigo: "NUMERO_DE_PARTES", nombre: "NUMERO_DE_PARTES" },
    { codigo: "MHO", nombre: "MHO" },
    { codigo: "MICROMHO", nombre: "MICROMHO" },
    { codigo: "NUMERO_DE_ROLLOS", nombre: "NUMERO_DE_ROLLOS" },
    { codigo: "TONELADA_NETA", nombre: "TONELADA_NETA" },
    { codigo: "REGISTRO_NETO_DE_TONELADAS", nombre: "REGISTRO_NETO_DE_TONELADAS" },
    { codigo: "MEDIDOR_DE_NEWTON", nombre: "MEDIDOR_DE_NEWTON" },
    { codigo: "VEHICULO", nombre: "VEHICULO" },
    { codigo: "PARTE_POR_MIL", nombre: "PARTE_POR_MIL" },
    { codigo: "LIBRA_POR_AIRE_SECO_TONELADA_METRICA", nombre: "LIBRA_POR_AIRE_SECO_TONELADA_METRICA" },
    { codigo: "PANEL", nombre: "PANEL" },
    { codigo: "OHM", nombre: "OHM" },
    { codigo: "ONZA_POR_YARDA_CUADRADA", nombre: "ONZA_POR_YARDA_CUADRADA" },
    { codigo: "ONZA", nombre: "ONZA" },
    { codigo: "DOS_PAQUETES", nombre: "DOS_PAQUETES" },
    { codigo: "HORA_EXTRA", nombre: "HORA_EXTRA" },
    { codigo: "ONZA_AV", nombre: "ONZA_AV" },
    { codigo: "ONZA_LIQUIDA_US", nombre: "ONZA_LIQUIDA_US" },
    { codigo: "ONZA_LIQUIDA_REINO_UNIDO", nombre: "ONZA_LIQUIDA_REINO_UNIDO" },
    { codigo: "PAGINA_ELECTRONICA", nombre: "PAGINA_ELECTRONICA" },
    { codigo: "POR_CIENTO", nombre: "POR_CIENTO" },
    { codigo: "LIBRA_POR_PIE", nombre: "LIBRA_POR_PIE" },
    { codigo: "PAQUETE_DE_TRES", nombre: "PAQUETE_DE_TRES" },
    { codigo: "PAQUETE_DE_CUATRO", nombre: "PAQUETE_DE_CUATRO" },
    { codigo: "PAQUETE_DE_CINCO", nombre: "PAQUETE_DE_CINCO" },
    { codigo: "PAQUETE_DE_SEIS", nombre: "PAQUETE_DE_SEIS" },
    { codigo: "PAQUETE_DE_SIETE", nombre: "PAQUETE_DE_SIETE" },
    { codigo: "PAQUETE_DE_OCHO", nombre: "PAQUETE_DE_OCHO" },
    { codigo: "PAQUETE_DE_NUEVE", nombre: "PAQUETE_DE_NUEVE" },
    { codigo: "EMPAQUETADO", nombre: "EMPAQUETADO" },
    { codigo: "PASCAL", nombre: "PASCAL" },
    { codigo: "PAR_DE_PULGADAS", nombre: "PAR_DE_PULGADAS" },
    { codigo: "ALMOHADILLA", nombre: "ALMOHADILLA" },
    { codigo: "EQUIVALENTE_EN_LIBRAS", nombre: "EQUIVALENTE_EN_LIBRAS" },
    { codigo: "PALET_ASCENSOR", nombre: "PALET_ASCENSOR" },
    { codigo: "PLATO", nombre: "PLATO" },
    { codigo: "GALON_DE_PRUEBA", nombre: "GALON_DE_PRUEBA" },
    { codigo: "TONO", nombre: "TONO" },
    { codigo: "PAQUETE", nombre: "PAQUETE" },
    { codigo: "CUBO", nombre: "CUBO" },
    { codigo: "PORCENTAJE_DE_LIBRA", nombre: "PORCENTAJE_DE_LIBRA" },
    { codigo: "LIBRA_NETA", nombre: "LIBRA_NETA" },
    { codigo: "LIBRA_POR_PULGADA_DE_LONGITUD", nombre: "LIBRA_POR_PULGADA_DE_LONGITUD" },
    { codigo: "PAGINA_POR_PULGADA", nombre: "PAGINA_POR_PULGADA" },
    { codigo: "PAR", nombre: "PAR" },
    { codigo: "FUERZA_DE_LIBRA_POR_PULGADA_CUADRADA", nombre: "FUERZA_DE_LIBRA_POR_PULGADA_CUADRADA" },
    { codigo: "PINTA", nombre: "PINTA" },
    { codigo: "PINTA_SECA", nombre: "PINTA_SECA" },
    { codigo: "PINTA_REINO_UNIDO", nombre: "PINTA_REINO_UNIDO" },
    { codigo: "PINTA_LIQUIDA_US", nombre: "PINTA_LIQUIDA_US" },
    { codigo: "BANDEJA_PAQUETE_DE_BANDEJA", nombre: "BANDEJA_PAQUETE_DE_BANDEJA" },
    { codigo: "MEDIA_PINTA_US", nombre: "MEDIA_PINTA_US" },
    { codigo: "LIBRA_POR_PULGADA_DE_ANCHO", nombre: "LIBRA_POR_PULGADA_DE_ANCHO" },
    { codigo: "PECK_DRY_US", nombre: "PECK_DRY_US" },
    { codigo: "PECK_DRY_REINO_UNIDO", nombre: "PECK_DRY_REINO_UNIDO" },
    { codigo: "COMIDA", nombre: "COMIDA" },
    { codigo: "PAGINA_FACSIMIL", nombre: "PAGINA_FACSIMIL" },
    { codigo: "CUARTO_DE_UN_ANNO", nombre: "CUARTO_DE_UN_ANNO" },
    { codigo: "PAGINA_COPIA_IMPRESA", nombre: "PAGINA_COPIA_IMPRESA" },
    { codigo: "CUARTO_DE_DOCENA", nombre: "CUARTO_DE_DOCENA" },
    { codigo: "UN_CUARTO_DE_HORA", nombre: "UN_CUARTO_DE_HORA" },
    { codigo: "CUARTO_DE_KILOGRAMO", nombre: "CUARTO_DE_KILOGRAMO" },
    { codigo: "MANO_DE_PAPEL", nombre: "MANO_DE_PAPEL" },
    { codigo: "CUARTO_DE_GALON_US", nombre: "CUARTO_DE_GALON_US" },
    { codigo: "CUARTO_SECO_EE_UU", nombre: "CUARTO_SECO_EE_UU" },
    { codigo: "CUARTO_DE_GALON_REINO_UNIDO", nombre: "CUARTO_DE_GALON_REINO_UNIDO" },
    { codigo: "CUARTO_LIQUIDO_US", nombre: "CUARTO_LIQUIDO_US" },
    { codigo: "CUARTO_UK", nombre: "CUARTO_UK" },
    { codigo: "PICA", nombre: "PICA" },
    { codigo: "CALORIA", nombre: "CALORIA" },
    { codigo: "MIL_METROS_CUBICOS", nombre: "MIL_METROS_CUBICOS" },
    { codigo: "ESTANTE", nombre: "ESTANTE" },
    { codigo: "VARA", nombre: "VARA" },
    { codigo: "ANILLO", nombre: "ANILLO" },
    { codigo: "HORA_DE_FUNCIONAMIENTO_O_DE_FUNCIONAMIENTO", nombre: "HORA_DE_FUNCIONAMIENTO_O_DE_FUNCIONAMIENTO" },
    { codigo: "MEDIDA_METRICA_ROLLO", nombre: "MEDIDA_METRICA_ROLLO" },
    { codigo: "CARRETEL", nombre: "CARRETEL" },
    { codigo: "RESMA", nombre: "RESMA" },
    { codigo: "MEDIDA_METRICA_DE_RESMA", nombre: "MEDIDA_METRICA_DE_RESMA" },
    { codigo: "RODAR", nombre: "RODAR" },
    { codigo: "LIBRA_POR_RESMA", nombre: "LIBRA_POR_RESMA" },
    { codigo: "REVOLUCIONES_POR_MINUTO", nombre: "REVOLUCIONES_POR_MINUTO" },
    { codigo: "REVOLUCIONES_POR_SEGUNDO", nombre: "REVOLUCIONES_POR_SEGUNDO" },
    { codigo: "REINICIAR", nombre: "REINICIAR" },
    { codigo: "INGRESO_TONELADA_MILLA", nombre: "INGRESO_TONELADA_MILLA" },
    { codigo: "CORRER", nombre: "CORRER" },
    { codigo: "PIE_CUADRADO_POR_SEGUNDO", nombre: "PIE_CUADRADO_POR_SEGUNDO" },
    { codigo: "METRO_CUADRADO_POR_SEGUNDO", nombre: "METRO_CUADRADO_POR_SEGUNDO" },
    { codigo: "SESENTA_CUARTOS_DE_PULGADA", nombre: "SESENTA_CUARTOS_DE_PULGADA" },
    { codigo: "SESION", nombre: "SESION" },
    { codigo: "UNIDAD_DE_ALMACENAMIENTO", nombre: "UNIDAD_DE_ALMACENAMIENTO" },
    { codigo: "UNIDAD_DE_PUBLICIDAD_ESTANDAR", nombre: "UNIDAD_DE_PUBLICIDAD_ESTANDAR" },
    { codigo: "SACO", nombre: "SACO" },
    { codigo: "MEDIO_ANNO", nombre: "MEDIO_ANNO" },
    { codigo: "PUNTUACION", nombre: "PUNTUACION" },
    { codigo: "ESCRUPULO", nombre: "ESCRUPULO" },
    { codigo: "LIBRA_SOLIDA", nombre: "LIBRA_SOLIDA" },
    { codigo: "SECCION", nombre: "SECCION" },
    { codigo: "SEGUNDO", nombre: "SEGUNDO" },
    { codigo: "CONJUNTO", nombre: "CONJUNTO" },
    { codigo: "SEGMENTO", nombre: "SEGMENTO" },
    { codigo: "TONELADA_DE_ENVIO", nombre: "TONELADA_DE_ENVIO" },
    { codigo: "SIEMENS", nombre: "SIEMENS" },
    { codigo: "CAMION_CISTERNA_DIVIDIDO", nombre: "CAMION_CISTERNA_DIVIDIDO" },
    { codigo: "HOJA_DE_DESLIZAMIENTO", nombre: "HOJA_DE_DESLIZAMIENTO" },
    { codigo: "MILLA", nombre: "MILLA" },
    { codigo: "VARILLA_CUADRADA", nombre: "VARILLA_CUADRADA" },
    { codigo: "CARRETE", nombre: "CARRETE" },
    { codigo: "PAQUETE_DE_ESTANTE", nombre: "PAQUETE_DE_ESTANTE" },
    { codigo: "CUADRADO", nombre: "CUADRADO" },
    { codigo: "TIRA", nombre: "TIRA" },
    { codigo: "HOJA_METRICA_MEDIDA", nombre: "HOJA_METRICA_MEDIDA" },
    { codigo: "CORTO_ESTANDAR_7200_PARTIDOS", nombre: "CORTO_ESTANDAR_7200_PARTIDOS" },
    { codigo: "HOJAS", nombre: "HOJAS" },
    { codigo: "PIEDRA_REINO_UNIDO", nombre: "PIEDRA_REINO_UNIDO" },
    { codigo: "TONELADA_US_O_TONELADA_CORTA_UK_US", nombre: "TONELADA_US_O_TONELADA_CORTA_UK_US" },
    { codigo: "PATINAR", nombre: "PATINAR" },
    { codigo: "MADEJA", nombre: "MADEJA" },
    { codigo: "ENVIO", nombre: "ENVIO" },
    { codigo: "LINEA_DE_TELECOMUNICACIONES_EN_SERVICIO", nombre: "LINEA_DE_TELECOMUNICACIONES_EN_SERVICIO" },
    { codigo: "MIL_LIBRAS_BRUTAS", nombre: "MIL_LIBRAS_BRUTAS" },
    { codigo: "MIL_PIEZAS", nombre: "MIL_PIEZAS" },
    { codigo: "BOLSA_DE_MIL", nombre: "BOLSA_DE_MIL" },
    { codigo: "CAJA_DE_MIL", nombre: "CAJA_DE_MIL" },
    { codigo: "MIL_GALONES_US", nombre: "MIL_GALONES_US" },
    { codigo: "MIL_IMPRESIONES", nombre: "MIL_IMPRESIONES" },
    { codigo: "MIL_PULGADAS_LINEALES", nombre: "MIL_PULGADAS_LINEALES" },
    { codigo: "DECIMO_PIE_CUBICO", nombre: "DECIMO_PIE_CUBICO" },
    { codigo: "KILOAMPERE_HORA_O_MIL_AMPERIOS_HORA", nombre: "KILOAMPERE_HORA_O_MIL_AMPERIOS_HORA" },
    { codigo: "CAMION", nombre: "CAMION" },
    { codigo: "TERMIA", nombre: "TERMIA" },
    { codigo: "TOTALIZADOR", nombre: "TOTALIZADOR" },
    { codigo: "DIEZ_METROS_CUADRADOS", nombre: "DIEZ_METROS_CUADRADOS" },
    { codigo: "MIL_PULGADAS_CUADRADAS", nombre: "MIL_PULGADAS_CUADRADAS" },
    { codigo: "MIL_CENTIMETROS_CUADRADOS", nombre: "MIL_CENTIMETROS_CUADRADOS" },
    { codigo: "MIL_CENTIMETROS_CUADRADOS", nombre: "MIL_CENTIMETROS_CUADRADOS" },
    { codigo: "MIL_PIES_LINEALES", nombre: "MIL_PIES_LINEALES" },
    { codigo: "ESTANO", nombre: "ESTANO" },
    { codigo: "TONELADA_TONELADA_METRICA", nombre: "TONELADA_TONELADA_METRICA" },
    { codigo: "PAQUETE_DE_DIEZ", nombre: "PAQUETE_DE_DIEZ" },
    { codigo: "DIEZ_PARES", nombre: "DIEZ_PARES" },
    { codigo: "MIL_PIES", nombre: "MIL_PIES" },
    { codigo: "MIL_METROS_CUBICOS_POR_DIA", nombre: "MIL_METROS_CUBICOS_POR_DIA" },
    { codigo: "DIEZ_PIES_CUADRADOS", nombre: "DIEZ_PIES_CUADRADOS" },
    { codigo: "TRILLON_EUR", nombre: "TRILLON_EUR" },
    { codigo: "MIL_PIES_CUADRADOS", nombre: "MIL_PIES_CUADRADOS" },
    { codigo: "TONELADA_DE_SUSTANCIA_90_SECA", nombre: "TONELADA_DE_SUSTANCIA_90_SECA" },
    { codigo: "TONELADA_DE_VAPOR_POR_HORA", nombre: "TONELADA_DE_VAPOR_POR_HORA" },
    { codigo: "MIL_METROS_LINEALES", nombre: "MIL_METROS_LINEALES" },
    { codigo: "TUBO", nombre: "TUBO" },
    { codigo: "MIL_KILOGRAMOS", nombre: "MIL_KILOGRAMOS" },
    { codigo: "MIL_HOJAS", nombre: "MIL_HOJAS" },
    { codigo: "TANQUE_CILINDRICO", nombre: "TANQUE_CILINDRICO" },
    { codigo: "TRATAMIENTO", nombre: "TRATAMIENTO" },
    { codigo: "TABLETA", nombre: "TABLETA" },
    { codigo: "TORR", nombre: "TORR" },
    { codigo: "LINEA_DE_TELECOMUNICACIONES_EN_SERVICIO_PROMEDIO", nombre: "LINEA_DE_TELECOMUNICACIONES_EN_SERVICIO_PROMEDIO" },
    { codigo: "PUERTO_DE_TELECOMUNICACIONES", nombre: "PUERTO_DE_TELECOMUNICACIONES" },
    { codigo: "DECIMO_MINUTO", nombre: "DECIMO_MINUTO" },
    { codigo: "DECIMA_HORA", nombre: "DECIMA_HORA" },
    { codigo: "USO_POR_LINEA_DE_TELECOMUNICACION_PROMEDIO", nombre: "USO_POR_LINEA_DE_TELECOMUNICACION_PROMEDIO" },
    { codigo: "DIEZ_MIL_YARDAS", nombre: "DIEZ_MIL_YARDAS" },
    { codigo: "MILLONES_DE_UNIDADES", nombre: "MILLONES_DE_UNIDADES" },
    { codigo: "VOLTIO_AMPERIO_POR_KILOGRAMO", nombre: "VOLTIO_AMPERIO_POR_KILOGRAMO" },
    { codigo: "FRASCO", nombre: "FRASCO" },
    { codigo: "VOLTIO", nombre: "VOLTIO" },
    { codigo: "ABULTAR", nombre: "ABULTAR" },
    { codigo: "VISITAR", nombre: "VISITAR" },
    { codigo: "KILO_MOJADO", nombre: "KILO_MOJADO" },
    { codigo: "DOS_SEMANAS", nombre: "DOS_SEMANAS" },
    { codigo: "VATIO_POR_KILOGRAMO", nombre: "VATIO_POR_KILOGRAMO" },
    { codigo: "LIBRA_MOJADA", nombre: "LIBRA_MOJADA" },
    { codigo: "CABLE", nombre: "CABLE" },
    { codigo: "TONELADA_MOJADA", nombre: "TONELADA_MOJADA" },
    { codigo: "WEBER", nombre: "WEBER" },
    { codigo: "SEMANA", nombre: "SEMANA" },
    { codigo: "GALON_DE_VINO", nombre: "GALON_DE_VINO" },
    { codigo: "RUEDA", nombre: "RUEDA" },
    { codigo: "VATIOS_HORA", nombre: "VATIOS_HORA" },
    { codigo: "PESO_POR_PULGADA_CUADRADA", nombre: "PESO_POR_PULGADA_CUADRADA" },
    { codigo: "MES_DE_TRABAJO", nombre: "MES_DE_TRABAJO" },
    { codigo: "ENVOLVER", nombre: "ENVOLVER" },
    { codigo: "ESTANDAR", nombre: "ESTANDAR" },
    { codigo: "VATIO", nombre: "VATIO" },
    { codigo: "MILILITRO_DE_AGUA", nombre: "MILILITRO_DE_AGUA" },
    { codigo: "CADENA", nombre: "CADENA" },
    { codigo: "YARDA_CUADRADA", nombre: "YARDA_CUADRADA" },
    { codigo: "YARDA_CUBICA", nombre: "YARDA_CUBICA" },
    { codigo: "CIEN_YARDAS_LINEALES", nombre: "CIEN_YARDAS_LINEALES" },
    { codigo: "YARDA", nombre: "YARDA" },
    { codigo: "DIEZ_YARDAS", nombre: "DIEZ_YARDAS" },
    { codigo: "VAN_DE_ELEVACION", nombre: "VAN_DE_ELEVACION" },
    { codigo: "PECHO", nombre: "PECHO" },
    { codigo: "BARRIL", nombre: "BARRIL" },
    { codigo: "PIPA", nombre: "PIPA" },
    { codigo: "LENGUETA", nombre: "LENGUETA" },
    { codigo: "PUNTO_DE_CONFERENCIA", nombre: "PUNTO_DE_CONFERENCIA" },
    { codigo: "LINEA_DE_NOTICIAS_DE_AGATA", nombre: "LINEA_DE_NOTICIAS_DE_AGATA" },
    { codigo: "PAGINA", nombre: "PAGINA" },
    { codigo: "MUTUAMENTE_DEFINIDO", nombre: "MUTUAMENTE_DEFINIDO" },
]

export default tiposUnidadMedida;