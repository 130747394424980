export const styles = (theme) => ({
  root: {
    flexGrow: 2,
    maxWidth: "100%",
  },
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: "center",
    color: theme.palette.text.primary,
    boxShadow: "0px 0px 9px -6px rgba(0,0,0,0.75)",
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
      padding: 0,
    },
  },
  containerLogos: {
    flex: 1,
    display: "inline-flex",
    [theme.breakpoints.down("md")]: {
      maxHeight: "3em",
    },
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    maxHeight: "6em",
    margin: 0,
    padding: 0,
  },
  rootLogo: {
    display: "inline",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "inherit",
  },
  logo: {
    maxHeight: "inherit",
  },
  logoPortal: {
    maxWidth: "35%",
    marginTop: "-15px",
  },
  fontFamily: {
    fontFamily: "Quicksand",
  },
  show: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  showContainer: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
});
