import { ConsultasFacturacion } from "./SubMenus/Consultas";
import { EmisionFacturacion, emitirFactura, emitirFacturaExoprtacion, emitirNotaCredito, emitirNotaDebito } from "./SubMenus/Emision";
import { DocumentosEmitidosFacturacion } from "./SubMenus/Consultas";
import { productos, adquirentes } from "./SubMenus/Maestros";
export const Facturacion = [
  {
    isNoView: "true",
    pathname: "/Home",
    principal: "true",
  },
  adquirentes,
  productos,
  emitirFactura,
  emitirFacturaExoprtacion,
  emitirNotaCredito,
  emitirNotaDebito,
  DocumentosEmitidosFacturacion,
  EmisionFacturacion,
  ConsultasFacturacion,
];