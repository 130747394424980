import { Consultas } from "./SubMenus/Consultas";
import { productos, adquirentes } from "./SubMenus/Maestros";
import { gestionResoluciones } from "./SubMenus/Configuracion";

export const Contable = [
  {
    isNoView: "true",
    pathname: "/Home",
    principal: "true",
  },
  gestionResoluciones,
  adquirentes,
  productos,
  Consultas,
];
