/* eslint-disable */
function formatearDecimales(num) {
  if (num == '') {
    num = 0;
  }
  num = parseFloat(num);
  let value = `${num.toFixed(4)}`;
  return value;
}

function formatearDecimalesPorcentajeICA(num) {
  if (num == '') {
    num = 0;
  }
  num = parseFloat(num);
  let value = `${num.toFixed(3)}`;
  return value;
}

export function impuestosAdicionalesEncabezado(items, tributo) {
  if (items.length > 0) {
    let valorTotal = 0;
    let subtotales = [];
    switch (tributo) {
      case 'IVA':
        items.map((item) => {
          item.impuestosRetenciones.map((item) => {
            item.subtotales.map((item) => {
              if (item.tributo == 'IVA') {
                valorTotal += parseFloat(item.valorImpuestoRetencion);
                subtotales.push(item);
              }
            });
          });
        });
        if (subtotales.length == 0) return null;
        return { valorTotal: formatearDecimales(valorTotal), subtotales: subtotales };
      case 'INC':
        items.map((item) => {
          item.impuestosRetenciones.map((item) => {
            item.subtotales.map((item) => {
              if (item.tributo == 'INC') {
                valorTotal += parseFloat(item.valorImpuestoRetencion);
                subtotales.push(item);
              }
            });
          });
        });
        if (subtotales.length == 0) return null;
        return { valorTotal: formatearDecimales(valorTotal), subtotales: subtotales };
      case 'ICUI':
        return calcularTributosEncabezado(items, 'ICUI');
      default:
        return null;
    }
  }
  return null;
}

const calcularTributosEncabezado = (items, tributo) => {
  let valorTotal = 0;
  let subtotales = [];
  items.map((item) => {
    item.impuestosRetenciones.map((item) => {
      item.subtotales.map((item) => {
        if (item.tributo == tributo) {
          valorTotal += parseFloat(item.valorImpuestoRetencion);
          subtotales.push(item);
        }
      });
    });
  });
  if (subtotales.length == 0) return null;
  return { valorTotal: formatearDecimales(valorTotal), subtotales };
};

export function impuestosAdicionalesRetefuente(valorBase, valorRetefuente, porcentaje) {
  return {
    valorTotal: formatearDecimales(valorRetefuente),
    subtotales: [
      {
        valorBase: formatearDecimales(valorBase),
        valorImpuestoRetencion: formatearDecimales(valorRetefuente),
        porcentaje: formatearDecimales(porcentaje),
        tributo: 'RETE_FUENTE',
      },
    ],
  };
}

export function impuestosAdicionalesICA(valorBase, porcentaje) {
  const valorIca = parseFloat((porcentaje * valorBase) / 100);
  return {
    valorTotal: formatearDecimales(valorIca),
    subtotales: [
      {
        valorBase: formatearDecimales(valorBase),
        valorImpuestoRetencion: formatearDecimales(valorIca),
        porcentaje: formatearDecimalesPorcentajeICA(porcentaje),
        tributo: 'RETE_ICA',
      },
    ],
  };
}

export function impuestosAdicionalesReteIva(valorBase, valorReteIva, porcentajeReteIva) {
  return {
    valorTotal: formatearDecimales(valorReteIva),
    subtotales: [
      {
        valorBase: formatearDecimales(valorBase),
        valorImpuestoRetencion: formatearDecimales(valorReteIva),
        porcentaje: formatearDecimales(porcentajeReteIva),
        tributo: 'RETE_IVA',
      },
    ],
  };
}

export function impuestosAdicionalesBolsa() {
  return {
    valorTotal: '20.00',
    subtotales: [
      {
        valorBase: '500.00',
        valorImpuestoRetencion: '20.00',
        unidadMedida: 'UNIDAD',
        valorUnitario: '20',
        porcentaje: '0',
        tributo: 'BOLSAS',
      },
    ],
  };
}
