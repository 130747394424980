import * as React from 'react';
import Input from 'components/Inputs/Input';
import { TIPOS_DATOS, validarInput } from 'utils/ValidarDatos';

import Select from 'components/Inputs/Select';
import { modalidadesContratacionSalud } from 'static/enums/ModalidadContratacionSalud';
import { coverturasSalud } from 'static/enums/CoberturaSalud';

import Checkbox from 'components/molecules/Checkbox';
import { now } from 'utils/DateUtils';

function DatosSalud({
  setMsUsuarios,
  onChangeDocumentoSalud,
  isDocumentoSalud,
  infoUsuarioAdquirente = { noInfo: true },
  removeUsuarioSalud,
}) {
  const [msUsuario, setMsUsuario] = React.useState({});

  React.useEffect(() => {
    const {
      msTipoDocID,
      dsIdentificacion,
      dsNombre,
      dsSegundoNombre,
      dsPrimerApellido,
      dsSegundoApellido,
      msTipoUsuario,
      msModalidadContratacion,
      msCoberturaPlanBeneficios,
      msCodigoPrestadorSalud,
    } = infoUsuarioAdquirente;
    setUsuario({
      msTipoDocID,
      msDocID: dsIdentificacion,
      msTipoUsuario,
      msModalidadContratacion,
      msPrimerNombre: dsNombre,
      msSegundoNombre: dsSegundoNombre,
      msPrimerApellido: dsPrimerApellido || dsNombre,
      msSegundoApellido: dsSegundoApellido,
      msCoberturaPlanBeneficios,
      msCodigoPrestadorSalud,
      msNumeroMIPRES: '',
      msNumeroIDMIPRES: '',
      msNumeroContrato: '',
      msNumeroPoliza: '',
      msCopago: '',
      msCuotaModeradora: '0',
      msCuotaRecuperacion: '0',
      msPagosCompartidos: '0',
      msAnticipo: '0',
      msFechaInicio: now().format('YYYY-MM-DD'),
      msFechaFin: now().format('YYYY-MM-DD'),
    });
  }, [isDocumentoSalud, infoUsuarioAdquirente.noInfo]);

  React.useEffect(() => {
    if (isDocumentoSalud) setMsUsuarios(msUsuario);
    else removeUsuarioSalud();
  }, [isDocumentoSalud, msUsuario]);

  const handleChange = (event) => {
    const label = event.target.name;
    const value = event.target.value;
    setUsuario({ [label]: value });
  };

  const setUsuario = (d) => setMsUsuario((s) => ({ ...s, ...d }));

  return (
    <>
      <Checkbox
        label='Seleccione si pertenece al sector salud'
        checked={isDocumentoSalud}
        onChange={({ target }) => onChangeDocumentoSalud(target.checked)}
      />
      {isDocumentoSalud && (
        <>
          <div className='form-row col-xl-12'>
            <div className='form-group col-md-12 col-xl-2'>
              <Input
                label='Fecha inicio servicio'
                name='msFechaInicio'
                onChange={handleChange}
                type='date'
                value={msUsuario.msFechaInicio}
              />
            </div>
            <div className='form-group col-md-12 col-xl-2'>
              <Input
                label='Fecha fin servicio'
                name='msFechaFin'
                onChange={handleChange}
                type='date'
                value={msUsuario.msFechaFin}
              />
            </div>
            <div className='form-group col-xl-4'>
              <Select
                label='Modalidad contratación'
                name='msModalidadContratacion'
                options={modalidadesContratacionSalud}
                value={modalidadesContratacionSalud.find(
                  ({ value }) => value === msUsuario.msModalidadContratacion,
                )}
                onChange={({ value: msModalidadContratacion }) =>
                  setUsuario({ msModalidadContratacion })
                }
              />
            </div>
            <div className='col-md-4'>
              <Select
                label='Covertura plan'
                name='msCoberturaPlanBeneficios'
                options={coverturasSalud}
                value={coverturasSalud.find(
                  ({ value }) => value === msUsuario.msCoberturaPlanBeneficios,
                )}
                onChange={({ value: msCoberturaPlanBeneficios }) =>
                  setUsuario({ msCoberturaPlanBeneficios })
                }
              />
            </div>
          </div>
          <div className='form-row col-md-12'>
            <div className='form-group col-md-12 col-xl-3'>
              <Input
                label='Número Autorización'
                name='msNumeroAutorizacion'
                onChange={handleChange}
                value={msUsuario.msNumeroAutorizacion}
              />
            </div>
            <div className='form-group col-md-12 col-xl-3'>
              <Input
                label='Número MIPRES'
                name='msNumeroMIPRES'
                onChange={handleChange}
                value={msUsuario.msNumeroMIPRES}
              />
            </div>
            <div className='form-group col-md-12 col-xl-2'>
              <Input
                label='Número IDMIPRES'
                name='msNumeroIDMIPRES'
                onChange={handleChange}
                value={msUsuario.msNumeroIDMIPRES}
              />
            </div>
            <div className='form-group col-md-12 col-xl-2'>
              <Input
                label='Número Contrato'
                name='msNumeroContrato'
                onChange={handleChange}
                value={msUsuario.msNumeroContrato}
              />
            </div>
            <div className='form-group col-md-12 col-xl-2'>
              <Input
                label='Número Póliza'
                name='msNumeroPoliza'
                onChange={handleChange}
                value={msUsuario.msNumeroPoliza}
              />
            </div>
          </div>
          <div className='form-row col-md-12'>
            <div className='form-group col-md-12 col-xl-2'>
              <Input
                label='Cuota recuperación'
                name='msCuotaRecuperacion'
                onChange={handleChange}
                onKeyPress={(event) => validarInput(event, TIPOS_DATOS.decimales)}
                value={msUsuario.msCuotaRecuperacion}
              />
            </div>
            <div className='form-group col-md-12 col-xl-2'>
              <Input
                label='Pagos voluntarios salud'
                name='msPagosVoluntariosSalud'
                onChange={handleChange}
                onKeyPress={(event) => validarInput(event, TIPOS_DATOS.decimales)}
                value={msUsuario.msPagosVoluntariosSalud}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DatosSalud;
