export const tiposUsuarioSalud = [
  { value: "CONTRIBUTIVO_COTIZANTE", label: "Contributivo cotizante" },
  { value: "CONTRIBUTIVO_BENEFICIARIO", label: "Contributivo beneficiario" },
  { value: "CONTRIBUTIVO_ADICIONAL", label: "Contributivo adicional" },
  { value: "SUBSIDIADO", label: "Subsidiado" },
  { value: "SIN_REGIMEN", label: "Sin régimen" },
  {
    value: "ESPECIALES_O_DE_EXCEPCION_COTIZANTE",
    label: "Especiales o de Excepción cotizante",
  },
  {
    value: "ESPECIALES_O_DE_EXCEPCION_BENEFICIARIO",
    label: "Especiales o de Excepción beneficiario",
  },
  { value: "PARTICULAR", label: "Particular" },
  { value: "TOMADOR_AMPARADO_ARL", label: "Tomador/Amparado ARL" },
  { value: "TOMADOR_AMPARADO_SOAT", label: "Tomador/Amparado SOAT" },
  {
    value: "TOMADOR_AMPARADO_PLANES_VOLUNTARIOS_DE_SALUD",
    label: "Tomador/Amparado Planes voluntarios de salud",
  },
];
