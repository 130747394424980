import React from 'react';
import { Radio as ARadio } from 'antd';

function Radio({ options = [], ...rest }) {
  return (
    <ARadio.Group {...rest}>
      {options.map(({ label, value }, key) => (
        <ARadio key={key} value={value}>
          {label}
        </ARadio>
      ))}
    </ARadio.Group>
  );
}

export default Radio;
