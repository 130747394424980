import React, { useState } from "react";
import Input from "../../../Inputs/Input";
import Button from 'components/atoms/Button';
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function libranza({ baseBody, guardarDeduccion, xlCol = "2" }) {
  const [dsDescripcion, setDsDescripcion] = useState();
  const [nmDeduccion, setNmDeduccion] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `LIBRANZA`,
    dsDescripcion,
    nmDeduccion,
  };

  const guardar = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDeduccion(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setDsDescripcion();
    setNmDeduccion();
  };

  return (
    <form onSubmit={guardar}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Descripcion de la Libranza *"
            value={dsDescripcion || ""}
            onChange={(e) => setDsDescripcion(e.target.value)}
            tooltip="Nombre de la Libranza que corresponda a las cuotas que el empleado deba pagar a una entidad financiera, para la amortización de un crédito que le haya sido otorgado por libranza"
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor pagado *"
            value={nmDeduccion || ""}
            onChange={(e) => setNmDeduccion(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Las cuotas que el empleado deba pagar a una entidad financiera, para la amortización de un crédito que le haya sido otorgado por libranza"
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.dsDescripcion) errores.push("La descripcion esta vacia");
  if (!body.nmDeduccion) errores.push("El valor pagado esta vacio");
  return errores;
}
