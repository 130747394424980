const tiposPrecioReferencia = [
    {
        codigo:"VALOR_COMERCIAL",
        nombre:"Valor comercial"
    },
    // {
    //     codigo:"VALOR_INVENTARIOS",
    //     nombre:"Valor en inventarios"
    // },
    // {
    //     codigo:"OTRO_VALOR",
    //     nombre:"Otro valor"
    // }
]

export default tiposPrecioReferencia;