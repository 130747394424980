import * as React from 'react';
const { useState, useCallback } = React;

const useForm = (defaultValues = {}) => {
  const [state, setDataState] = useState(defaultValues);

  const setState = useCallback(
    function (data) {
      setDataState((prevState) => ({ ...prevState, ...data }));
    },
    [setDataState],
  );

  const handleChange = useCallback(
    function (event) {
      const { name, value } = event.target;
      setState({ [name]: value });
    },
    [setState],
  );

  const handleChangeFiles = useCallback(
    function (event) {
      const { name, files } = event.target;
      setState({ [`${name}Files`]: files });
      handleChange(event);
    },
    [setState],
  );

  return { state, handleChange, setState, handleChangeFiles };
};

export default useForm;
