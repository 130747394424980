/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SetDatosAdicionalesDocumento } from 'reducers/actions/facturaActions';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  ordenarArray,
  handleKeyPressTextoNumeros,
  handleKeyPressDecimal,
} from 'utils/funcionesUtiles';
import moment from 'moment';
import { SetConfirmacionModalState } from 'reducers/actions/facturaActions';
import { consultarFirmasVigentes } from 'network/Api/CertFirma';
import { getDateFromArray } from 'utils/DateUtils';
import DatosTipoOperacion from './DatosTipoOperacion';
import CambioMoneda from 'components/templates/CambioMoneda';
import { SetDetalleFactura } from 'reducers/actions/facturaActions';
import Typography from 'components/atoms/Typography';

const styles = (theme) => ({
  show: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

function getTime() {
  let date = new Date();
  let seconds = date.getSeconds();
  let minutes = date.getMinutes();
  let hour = date.getHours();
  if (seconds.toString().length == 1) seconds = `0${seconds}`;
  if (minutes.toString().length == 1) minutes = `0${minutes}`;
  if (hour.toString().length == 1) hour = `0${hour}`;
  return ` ${hour}:${minutes}:${seconds}`;
}

class DatosAdicionalesDocumento extends Component {
  state = {
    dsObservacion: '',
    fechaEmisionFull: '',
    fechaVencimiento: '',
    // moneda: "COP",
    numeroCaracteresObservacion: 0,
    formaPago: 'CONTADO',
    medioPago: 'EFECTIVO',
    codigoIncoterm: '',
    condicionesEntrega: '',
    valorTipoCambio: '',
    fechaTipoCambio: '',
    ordenCompraPrefijo: '',
    tipoOperacion: 'ESTANDAR',
    numeroRemision: null,
    numeroOrdenTransporte: null,
    numeroHojaEntrada: null,
  };

  componentDidUpdate() {
    const { datosDocumento } = this.props;
    if (!this.state.moneda && datosDocumento.tipo) {
      const moneda = 'COP';
      this.setState({ moneda, isFacturaExportacion: datosDocumento.tipo === 'EXPORTACION' }, () =>
        this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state })),
      );
    }
  }

  componentDidMount() {
    this.setState(
      {
        fechaEmisionFull: this.obtenerDiaActual(),
        fechaEmision: this.obtenerDiaActual() + getTime(),
        fechaVencimiento: this.obtenerDiaActual(),
        fechaTipoCambio: this.obtenerDiaActual(),
      },
      () => {
        this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state }));
      },
    );
  }

  handleChangeTipoMoneda = (selectedOption) => {
    const moneda = selectedOption.value;
    this.setState({ moneda, isFacturaExportacion: moneda !== 'COP' }, () => {
      this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state }));
    });
  };

  formatearDecimales = (num) => {
    if (num === '') {
      num = 0;
    }
    num = parseFloat(num);
    let value = `${num.toFixed(2)}`;
    return value;
  };

  handleChange = (event) => {
    let id = event.target.id;
    let value = event.target.value;
    this.setState({ [event.target.id]: value }, () => {
      this.setState(
        {
          numeroCaracteresObservacion: this.state.dsObservacion.length,
          dsObservacion: JSON.parse(
            JSON.stringify(this.state.dsObservacion)
              .replaceAll('\\n', ' ')
              .replaceAll('<', '')
              .replaceAll('>', '')
              .replaceAll('\t', ' '),
          ),
        },
        () => {
          if (id == 'fechaEmisionFull') {
            this.setState({ fechaEmision: this.state.fechaEmisionFull + getTime() }, () => {
              this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state }));
            });
          } else {
            this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state }));
          }
        },
      );
    });
  };

  handleKeyPressTextoNumeros = (event) => {
    if (!handleKeyPressTextoNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressDecimal = (event) => {
    if (!handleKeyPressDecimal(event)) {
      event.preventDefault();
    }
  };

  obtenerDiaActual = () => {
    var date = new Date();
    var today = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      .toISOString()
      .split('T')[0];
    return today;
  };

  handleChangeFormaPago = (selectedOption) => {
    let codigo = selectedOption.value;
    this.setState({ formaPago: codigo }, () => {
      this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state }));
    });
  };

  handleChangeMedioPago = (selectedOption) => {
    let codigo = selectedOption.value;
    this.setState({ medioPago: codigo }, () => {
      this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state }));
    });
  };

  handleChangeTipoEntrega = (selectedOption) => {
    let codigo = selectedOption.value;
    this.setState({ codigoIncoterm: codigo }, () => {
      this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state }));
    });
  };

  validarFechaEmisionOnBlur = () => {
    if (this.state.fechaEmisionFull < moment().subtract(10, 'days').format('YYYY-MM-DD')) {
      this.setState({
        fechaEmisionFull: moment().subtract(10, 'days').format('YYYY-MM-DD'),
      });
    } else {
      if (this.state.fechaEmisionFull > moment().add(10, 'days').format('YYYY-MM-DD')) {
        this.setState({
          fechaEmisionFull: moment().add(10, 'days').format('YYYY-MM-DD'),
        });
      }
    }
  };

  validarRangoFechaEmisionOnBlur = () => {
    const { fechaEmisionFull } = this.state;
    const emisorId = this.props.userAttributes.codigoEmisor;
    if (emisorId) {
      consultarFirmasVigentes({ emisorId }).then((res) => {
        if (res && res.data) {
          if (
            moment(fechaEmisionFull) < getDateFromArray(res.data.feInicioFirma) ||
            moment(fechaEmisionFull) > getDateFromArray(res.data.feFinFirma).add(1, 'DAYS')
          ) {
            let modal;
            modal = {
              open: true,
              text: `La fecha de la factura está por fuera del rango asignado en la firma.`,
              onClick: () => {
                this.setState({ fechaEmisionFull: '' }, () => {
                  this.props.dispatch(SetConfirmacionModalState({ open: false }));
                });
              },
            };
            this.props.dispatch(SetConfirmacionModalState(modal));
          }
        }
      });
    }
  };

  handleChangeState = (data) => {
    this.setState({ ...data }, () =>
      this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state })),
    );
  };

  render() {
    const { tiposMonedas, tiposPago, tiposMediosPago, tiposCondicionesEntrega, datosDocumento } =
      this.props;

    const {
      dsObservacion,
      fechaEmisionFull,
      fechaVencimiento,
      numeroCaracteresObservacion,
      condicionesEntrega,
      valorTipoCambio,
      fechaTipoCambio,
      isFacturaExportacion,
      ordenCompraPrefijo,
      moneda,
      numeroRemision,
      numeroOrdenTransporte,
      numeroHojaEntrada,
    } = this.state;

    let tipoMonedaSelect = tiposMonedas.map((item) => ({
      value: item.moneda,
      label: item.nombre,
      default: item.default,
      defaultExportacion: item.defaultExportacion,
    }));

    let tiposPagoSelect = [];
    tiposPago.map((item) => {
      tiposPagoSelect.push({ value: item.codigo, label: item.nombre });
    });

    let tiposMediosPagoSelect = [];
    tiposMediosPago
      .filter((mp) => mp.documento)
      .map((item) => {
        tiposMediosPagoSelect.push({ value: item.codigo, label: item.nombre });
      });
    tiposMediosPagoSelect = ordenarArray(tiposMediosPagoSelect);

    const tiposCondicionesEntregaSelect = ordenarArray(
      tiposCondicionesEntrega.map((item) => ({
        value: item.codigo,
        label: item.nombre,
      })),
    );

    return (
      <div className='form-row'>
        <div className='form-group col-md-12 mt-0 mb-0'>
          <Typography variant='labelEncabezado'>Información documento</Typography>
          <h4 className='titulo_principal'></h4>
        </div>
        <div
          className='form-row col-md-12 d-flex justify-content-between'
          style={{ marginTop: '30px' }}
        >
          <div className='form-group col-xl-4 col-sm-12'>
            <Typography variant='labelInput'>Forma de pago *</Typography>
            <Select
              onChange={this.handleChangeFormaPago.bind(this)}
              defaultValue={tiposPagoSelect[0]}
              options={tiposPagoSelect}
            />
          </div>
          <div className='form-group col-xl-4 col-sm-12'>
            <Typography variant='labelInput'>Moneda *</Typography>
            <Select
              onChange={this.handleChangeTipoMoneda.bind(this)}
              value={tipoMonedaSelect.find((m) => m.value === moneda)}
              options={tipoMonedaSelect}
              isDisabled={true}
            />
          </div>
          <div className='form-group col-xl-4 col-sm-12'>
            <Typography variant='labelInput'>Medio de pago *</Typography>
            <Select
              onChange={this.handleChangeMedioPago.bind(this)}
              value={tiposMediosPagoSelect.find(({ value }) => value === this.state.medioPago)}
              options={tiposMediosPagoSelect}
            />
          </div>
        </div>

        <div className='form-row col-md-12 d-flex justify-content-between'>
          <div className='form-group col-xl-4'>
            <Typography variant='labelInput'>Fecha documento</Typography>
            <input
              type='date'
              id='fechaEmisionFull'
              min={moment().format('YYYY-MM-DD')}
              max={moment().format('YYYY-MM-DD')}
              value={fechaEmisionFull}
              onChange={this.handleChange}
              onBlur={this.validarFechaEmisionOnBlur && this.validarRangoFechaEmisionOnBlur}
              className='form-control'
              required
            />
          </div>
          <div className='form-group col-xl-4'>
            <Typography variant='labelInput'>Fecha de vencimiento</Typography>
            <input
              type='date'
              id='fechaVencimiento'
              min={moment().format('YYYY-MM-DD')}
              max='3000-12-31'
              value={fechaVencimiento}
              onChange={this.handleChange}
              className='form-control'
              required
            />
          </div>
          <div className='form-group col-xl-4'>
            <Typography variant='labelInput'>Orden de referencia</Typography>
            <input
              type='text'
              id='ordenCompraPrefijo'
              value={ordenCompraPrefijo}
              onChange={this.handleChange}
              className='form-control'
            />
          </div>
          <div className='form-group col-xl-4'>
            <Typography variant='labelInput'>Número Remision</Typography>
            <input
              type='text'
              id='numeroRemision'
              value={numeroRemision}
              onChange={this.handleChange}
              className='form-control'
            />
          </div>
          <div className='form-group col-xl-4'>
            <Typography variant='labelInput'>Número Orden de transporte</Typography>
            <input
              type='text'
              id='numeroOrdenTransporte'
              value={numeroOrdenTransporte}
              onChange={this.handleChange}
              className='form-control'
            />
          </div>
          <div className='form-group col-xl-4'>
            <Typography variant='labelInput'>Número Hoja de entrada</Typography>
            <input
              type='text'
              id='numeroHojaEntrada'
              value={numeroHojaEntrada}
              onChange={this.handleChange}
              className='form-control'
            />
          </div>
          <DatosTipoOperacion
            state={this.state}
            handleChange={(event) => this.handleChange(event)}
            setState={(newState) =>
              this.setState({ ...newState }, () =>
                this.props.dispatch(SetDatosAdicionalesDocumento({ ...this.state })),
              )
            }
          />
        </div>
        <div className='form-row col-md-12'>
          <div className='form-group col-md-12 '>
            <Typography variant='label'>Observación</Typography>
            <div className='form-label-group '>
              <textarea
                style={{ height: '10.55rem' }}
                maxLength='300'
                rows='3'
                autoComplete='off'
                type='text'
                id='dsObservacion'
                className='form-control form-control-sm'
                value={dsObservacion}
                onChange={this.handleChange}
              />
              <div style={{ alignItems: 'center', textAlign: 'center' }}>
                <small className='text-muted'>{numeroCaracteresObservacion}/300 Caracteres</small>
              </div>
            </div>
          </div>
        </div>
        {isFacturaExportacion && (
          <div className='form-row col-md-6'>
            <div className='form-row col-md-12 d-flex justify-content-between'>
              <div className='form-group col-xl-6 col-sm-12'>
                <Typography variant='label'>Tipo entrega *</Typography>
                <Select
                  onChange={this.handleChangeTipoEntrega}
                  options={tiposCondicionesEntregaSelect}
                />
              </div>
              <div className='form-group col-xl-6 col-sm-12'>
                <Typography variant='label'>Condiciones entrega *</Typography>
                <div className='form-group'>
                  <input
                    maxLength='100'
                    autoComplete='off'
                    type='text'
                    id='condicionesEntrega'
                    className='form-control form-control-md'
                    value={condicionesEntrega}
                    onKeyPress={this.handleKeyPressTextoNumeros}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
            </div>
            <CambioMoneda
              setState={this.handleChangeState}
              state={this.state}
              totalesFactura={this.props.totalesFactura}
              valorBruto={this.props.totalesFactura.valorBruto}
              detalles={this.props.detalles}
              setDetalles={(detalles) => this.props.dispatch(SetDetalleFactura(detalles))}
            />
            {moneda !== 'COP' && (
              <div className='form-row col-md-12 d-flex justify-content-between'>
                <div className='form-group col-xl-6 col-sm-12'>
                  <Typography variant='label'>Valor moneda acordado *</Typography>
                  <div className='form-group'>
                    <input
                      maxLength='100'
                      autoComplete='off'
                      type='text'
                      id='valorTipoCambio'
                      className='form-control form-control-md'
                      value={valorTipoCambio}
                      onKeyPress={this.handleKeyPressDecimal}
                      onChange={this.handleChange}
                      onBlur={() =>
                        this.setState({
                          valorTipoCambio: this.formatearDecimales(valorTipoCambio),
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <div className='form-group col-xl-6 col-sm-12 mt-2'>
                  <small>Fecha acuerdo</small>
                  <input
                    type='date'
                    id='fechaTipoCambio'
                    min='1000-01-01'
                    max={this.obtenerDiaActual()}
                    value={fechaTipoCambio}
                    onChange={this.handleChange}
                    className='form-control'
                    required
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

DatosAdicionalesDocumento.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    tiposMonedas: state.factura.tiposMonedas,
    tiposPago: state.factura.tiposPago,
    tiposMediosPago: state.factura.tiposMediosPago,
    isFacturaExportacion: state.factura.isFacturaExportacion,
    tiposCondicionesEntrega: state.factura.tiposCondicionesEntrega,
    datosDocumento: state.factura.datosDocumento,
    datosAdicionalesDocumento: state.factura.datosAdicionalesDocumento,
    userAttributes: state.factura.userAttributes,
    totalesFactura: state.factura.totalesFactura,
    detalles: state.factura.listaDetalleFactura,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(DatosAdicionalesDocumento));
