import React, { useState } from 'react';
import Select from 'components/molecules/Select';
import { consultarProductosActivosPorEmisorYDescripcion as consultarProductos } from 'network/Api/Producto';

function SelectProductos({ idEmisor = 0, className, onChange, ...rest }) {
  const [productos, setProductos] = useState([]);
  const [options, setOptions] = useState([]);

  const loadOptions = (input = '', callback) => {
    cargarProductos(input).then((res) => callback(res));
  };

  const cargarProductos = async (filtro = '') => {
    const { data = [] } = await consultarProductos({ idEmisor, filtro });
    setProductos(data);
    const options = data.map((producto) => ({
      value: producto.nmId,
      label: `${producto.dsCodigo} - ${producto.dsDescripcion}`,
      categoria: producto.categoria,
      json: JSON.stringify(producto),
    }));
    setOptions(options);
    return options;
  };

  const onChangeProducto = (event) => {
    const producto = productos.find((p) => p.nmId === event.value);
    onChange(event, producto);
  };

  return (
    <div className={className}>
      <Select
        async
        defaultOptions
        options={options}
        loadOptions={loadOptions}
        onInputChange={(inp) => cargarProductos(inp)}
        onChange={onChangeProducto}
        {...rest}
      />
    </div>
  );
}

export default SelectProductos;
