const initialState = {
    //Adquirente
    openModalAddAdquirente: false,
    openModalUpdateAdquirente: false,
    adquirentes:[],
    adquirente:{},
    //Producto
    openModalAddProducto: false,
    openModalUpdateProducto: false,
    productos:[],
    producto:{},
    emisor:{},
}

function maestros(state = initialState, action) {
    switch (action.type) {
        //ADQUIRENTE
        case 'SET_OPEN_MODAL_ADD_ADQUIRENTE': {
            return {
                ...state,
                openModalAddAdquirente: action.payload.item
            }
        }
        case 'SET_OPEN_MODAL_UPDATE_ADQUIRENTE': {
            return {
                ...state,
                openModalUpdateAdquirente: action.payload.item
            }
        }
        case 'SET_ADQUIRENTES': {
            return {
                ...state,
                adquirentes: action.payload.item
            }
        }
        case 'SET_ADQUIRENTE': {
            return {
                ...state,
                adquirente: action.payload.item
            }
        }

        //PRODUCTO
        case 'SET_OPEN_MODAL_ADD_PRODUCTO': {
            return {
                ...state,
                openModalAddProducto: action.payload.item
            }
        }
        case 'SET_OPEN_MODAL_UPDATE_PRODUCTO': {
            return {
                ...state,
                openModalUpdateProducto: action.payload.item
            }
        }
        case 'SET_PRODUCTOS': {
            return {
                ...state,
                productos: action.payload.item
            }
        }
        case 'SET_PRODUCTO': {
            return {
                ...state,
                producto: action.payload.item
            }
        }
        case 'SET_EMISOR': {
            return {
                ...state,
                emisor: action.payload.item
            }
        }
        
        default:
            return state
    }
}

export default maestros;