import * as React from "react";
import Productos from "@material-ui/icons/Assignment";

export const Distribuidores = [
  {
    title: "Estados Pin",
    icon: <Productos />,
    pathname: "/EstadosPin",
  },
  // {
  //   title: "Crear Pin",
  //   icon: <Productos />,
  //   pathname: "/GenerarPin",
  // },
];
