import React, { useState } from "react";
import Input from "../../../Inputs/Input";
import Button from 'components/atoms/Button';
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function prima({ baseBody, guardarDevengado, xlCol = "2" }) {
  const [nmCantidad, setNmCantidad] = useState();
  const [nmPago, setNmPago] = useState();
  const [nmPagoNS, setNmPagoNS] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: "PRIMA",
    nmCantidad,
    nmPago,
    nmPagoNS,
  };

  const guardarPrima = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmCantidad();
    setNmPago();
    setNmPagoNS();
  };

  return (
    <form onSubmit={guardarPrima}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Cantidad de dias trabajados *"
            id="cantidad"
            value={nmCantidad || ""}
            onChange={(e) => setNmCantidad(parseInt(e.target.value))}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Cantidad de dias trabajados para calculo de Pago de Corte de Prima"
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor de la prima *"
            id="pago"
            value={nmPago || ""}
            onChange={(e) => setNmPago(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Pagos por el reconocimiento del logro o cumplimiento por parte del trabajador en el desarrollo de sus labores, de condiciones definidas expresamente entre las partes."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor de la prima no salarial"
            id="pagoNS"
            value={nmPagoNS || ""}
            onChange={(e) => setNmPagoNS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Son valores pagados al trabajador de forma ocasional y por mera liberalidad o los pactados entre las partes de forma expresa como pago no salarial."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmCantidad) errores.push("La cantidad es obligatoria");
  if (!body.nmPago) {
    errores.push("El valor del pago es obligatorio");
  }
  return errores;
}
