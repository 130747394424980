/* eslint-disable */
import React, { Component } from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TablePagination,
} from '@material-ui/core';

class TablaGenerica extends Component {
  state = {
    order: 'desc',
    page: 0,
    rowsPerPage: 5,
  };

  componentDidMount = () => {
    const { rowsPerPage } = this.props;
    if (rowsPerPage) {
      this.setState({ rowsPerPage });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { detalles, orderBy, headers, keys } = this.props;
    const { order, rowsPerPage, page } = this.state;
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(detalles, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((detalle, index) => {
                return (
                  <TableRow key={index}>
                    {keys.map((key) => {
                      return <TableCell key={key}>{eval('detalle.' + key)}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={detalles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Página siguiente',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default TablaGenerica;
