import React, { Component } from "react";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import SubMenu from "./SubMenu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import * as MenuOptions from "./MenuOptions";

class Menu extends Component {
  state = {
    menuComplete: [],
  };

  componentDidMount() {
    const { menuOptions } = this.props;
    let finalMenu = [];
    menuOptions.forEach((menu) => {
      if (menu) {
        MenuOptions[menu].forEach((itemMenu) => {
          let a = finalMenu.filter(
            (itemMenuFinal) =>
              itemMenuFinal.pathname === itemMenu.pathname && !itemMenu.subMenu
          )[0];
          if (!a) finalMenu.push(itemMenu);
        });
      }
    });
    this.setState({ menuComplete: finalMenu });
  }

  render() {
    const { menuComplete } = this.state;
    return (
      <List>
        {menuComplete.map((item, key) =>
          item.subMenu ? (
            <SubMenu
              key={key}
              title={item.title}
              items={item.items}
              icon={item.icon}
            />
          ) : (
            !item.isNoView && (
              <Link
                style={{ textDecoration: "none" }}
                key={item.title}
                to={item.pathname}
              >
                <ListItem button key={key}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            )
          )
        )}
      </List>
    );
  }
}

export default Menu;
