import CryptoJS from 'crypto-js';

export function InformacionPasarela(userAttributes, state, ipAddress, fecha, dateISO) {
 
    const dateExpirationISO = new Date(fecha.getTime() + 10 * 60000 + 1).toISOString(); // Se le suma el tiempo en min a la fecha
    const rawNonce = Math.floor(Math.random() * 1000000);
    const reference = rawNonce + '_' + userAttributes['custom:custom:userid'] + '_' + fecha.getTime();
    const nonce = Buffer.from(rawNonce.toString()).toString('base64');
    const tranKey = CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(rawNonce + dateISO + process.env.REACT_APP_PASARELA_GOU_KEY));

    return ({
        auth: {
            login: process.env.REACT_APP_PASARELA_GOU_LOGIN,
            tranKey: tranKey,
            nonce: nonce,
            seed: dateISO,
        },
        payment: {
            reference: reference,
            description: 'Pago por plataforma GOU, Banco de Bogotá',
            amount: {
                currency: 'COP',
                total: state.recargaValor,
            },
        },
        buyer: {
            document: userAttributes['custom:custom:userid'],
            documentType: userAttributes['tipoIdentificacion'],
            name: userAttributes['fullName'],
            email: userAttributes['email'],
            mobile: userAttributes['phone_number'],
            address: userAttributes['direccion'],
        },
        expiration: dateExpirationISO,
        returnUrl: process.env.REACT_APP_PUBLIC_URL + 'Home',
        cancelUrl: process.env.REACT_APP_PUBLIC_URL + 'Home',
        ipAddress: ipAddress,
        userAgent: 'PlacetoPay Sandbox'
     })
}

export default InformacionPasarela;
