import * as React from "react";
import Select from "components/Inputs/Select";
import TiposIdentificacion from "static/enums/tiposIdentificacion";

const SelectTipoIdentificacion = ({ value, tipoOperacion, isDocumentoSoporte, ...rest }) => {
  const options = (isDocumentoSoporte ? TiposIdentificacion.filter((ti) => (tipoOperacion === "RESIDENTE" ? ti.tipoIdentificacionAdquiriente === "NIT" : ti.isSoporte)) : TiposIdentificacion).map(
    ({ nombre, tipoIdentificacionAdquiriente }) => ({
      label: nombre,
      value: tipoIdentificacionAdquiriente,
    })
  );

  return <Select value={options.find((to) => to.value === value)} options={options} {...rest} />;
};

export default SelectTipoIdentificacion;
