import { serviceRoutes, get, post } from "./index";

export const consultarEstadoPines = async ({
  aliado,
  inicio,
  fin,
  estado = "",
}) =>
  await get(
    serviceRoutes.consultarEstadoPinesTransacciones,
    `?aliado=${aliado}&inicio=${inicio}&fin=${fin}&estado=${estado}`
  );

export const generarPinTransaccion = async (body) =>
  await post(serviceRoutes.generarPinTransaccion, body);

export const validarPinTransaccion = async ({ dsPin }) =>
  await get(serviceRoutes.validarPinTransaccion, `?pin=${dsPin}`);

export const registrarPersonaPin = async (body) =>
  await post(serviceRoutes.registrarPersonaPin, body);
