import React, { useEffect, useState } from "react";
import Input from "components/Inputs/Input";
import LoaderText from "components/Loaders/LoaderText";
import { consultarAyudasActivas } from "network/Api/Ayuda";
import PreviewAyuda from "components/Ayudas/Preview";

function Ayudas() {
  const [ayudasConsultadas, setAyudasConsultadas] = useState();
  const [ayudasFilter, setAyudasFilter] = useState();

  useEffect(() => {
    consultarAyudasActivas().then((response) => {
      if (response.status === 200) setAyudasConsultadas(response.data);
    });
  }, []);

  useEffect(() => filtro(""), [ayudasConsultadas]);

  function filtro(palabra) {
    let arrayFiltrado = [];
    ayudasConsultadas &&
      ayudasConsultadas
        .map((item) => ({
          nmId: item.nmId,
          dsAyuda: item.dsAyuda,
          dsCuerpoAyuda: item.dsCuerpoAyuda,
        }))
        .forEach((item) => {
          Object.keys(item).some((key) => {
            if (
              item[key]
                .toString()
                .toLowerCase()
                .includes(palabra)
            ) {
              arrayFiltrado.push(item);
              return true;
            } else {
              return false;
            }
          });
        });
    setAyudasFilter(arrayFiltrado);
  }

  return (
    <div>
      <div
        style={{
          margin: "10px 0px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Input
          label="Buscar"
          onChange={(event) => filtro(event.target.value)}
        />
      </div>
      {Array.isArray(ayudasFilter) ? (
        ayudasFilter.map((ayuda) => (
          <PreviewAyuda
            key={ayuda.nmId}
            title={ayuda.dsAyuda}
            body={ayuda.dsCuerpoAyuda}
          />
        ))
      ) : (
        <LoaderText text="Cargando Ayudas..." />
      )}
    </div>
  );
}

export default Ayudas;
