import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import { consultarUrlPorPin } from 'network/Api/DocumentoPos';
import Modal from 'components/molecules/Modal';
import useModal from 'hooks/useModal';

function ConsultaPosPDF() {
  const [pin, setPin] = useState('');
  const [props, open] = useModal();

  const consultar = async () => {
    const { data = {} } = await consultarUrlPorPin({ pin });
    if (data) {
      const { DOMINA_URL, PORTAL_PDF } = data;
      if (DOMINA_URL) {
        openPDF(DOMINA_URL);
        return;
      }
      if (PORTAL_PDF) {
        const byteCharacters = atob(PORTAL_PDF);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++)
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        openPDF(URL.createObjectURL(blob));
        return;
      }
    } else open({ content: 'El pin ingresado es incorrecto' });
  };

  const openPDF = (url) => {
    const isOpen = window.open(url);
    if (!isOpen) open({ content: 'Las ventanas emergentes estan deshabilitadas' });
  };

  return (
    <Grid container xl={12} style={{ display: 'felx', alignItems: 'flex-end' }}>
      <Grid item xl={8}>
        <Input
          label='Codigo del documento'
          name='pin'
          value={pin}
          onChange={({ target }) => setPin(target.value)}
        />
      </Grid>
      <Grid item xl={4}>
        <Button style={{ margin: '0px 20px' }} onClick={consultar}>
          Consultar
        </Button>
      </Grid>
      <Modal {...props} />
    </Grid>
  );
}

export default ConsultaPosPDF;
