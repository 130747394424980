import * as React from "react";
import Input from "components/Inputs/Input";
import Button from 'components/atoms/Button';
import SelectTipoPersona from "components/Documento/DatosCliente/SelectTipoPersona";
import SelectTipoIdentificacion from "components/Documento/DatosCliente/SelectTipoIdentificacion";
import { calcularDigitoVerificacion } from "utils/funcionesUtiles";
import SelectRegimen from "components/Documento/DatosCliente/SelectRegimen";
import SelectCiudades from "components/Inputs/SelectCiudades";
import SelectRespFiscales from "components/Documento/DatosCliente/SelectRespFiscales";
import { registrarEmisorExpress } from "network/Api/Emisor";
import Checkbox from 'components/molecules/Checkbox';
import Modal from "components/modal/ModalConfirmacion";
import { TIPOS_DATOS, validarString } from "utils/ValidarDatos";

const CrearEmisorExpress = () => {
  const [emisor, setData] = React.useState({ persona: {}, cdRetenedorImpuestos: "N" });
  const [state, setDataState] = React.useState({});

  const setEmisor = (data) => setData((d) => ({ ...d, ...data }));

  const setPersona = (data) => setEmisor({ persona: { ...emisor.persona, ...data } });

  const setState = (data) => setDataState((d) => ({ ...d, ...data }));

  const calcularDigito = () => {
    const { dsIdentificacion } = emisor.persona;
    setPersona({ dsDigitoVerificacion: calcularDigitoVerificacion(dsIdentificacion) });
  };

  const registrarEmisor = async () => {
    const errores = validarEmisor();
    if (errores.length === 0) {
      const { persona } = emisor
      const { data, status } = await registrarEmisorExpress({
        ...emisor,
        dsRespFiscales: emisor.responsabilidadesFiscales,
        persona: {
          ...persona,
          dsIdentificacion: emisor.persona.dsIdentificacion + "-" + emisor.persona.dsDigitoVerificacion,
        },
      });
      if (status === 200 && data) alert("Emisor creado correctamente");
    } else openModal({ content: errores.join(" - ") });
  };

  const validarEmisor = () => {
    const errores = [];
    const addError = (error) => errores.push(error);
    const { persona, cdTipoPersona, cdTipoRegimen, dsCodigoCiiu, responsabilidadesFiscales } = emisor;
    const { cdTipoIdentificacion, dsIdentificacion, dsCelular, dsNombre, dsPrimerApellido, dsEmail, dsDireccion, ciudad } = persona;
    if (!cdTipoPersona) addError("El tipo persona es obligatoria");
    if (!cdTipoIdentificacion) addError("El tipo de identificacion es obligatoria");
    if (!dsIdentificacion) addError("La identifiacion es obligatoria");
    if (!dsCelular) addError("El celular es obligatorio");
    if (!dsNombre) addError("El nombre o razon social es obligatorio");
    if (cdTipoPersona === "NATURAL" && !dsPrimerApellido) addError("El primer apellio es obligatorio si el tipo persona es natural");
    if (!dsEmail) addError("El email es obligatorio");
    else if (!validarString(dsEmail, TIPOS_DATOS.email)) addError("Email invalido");
    if (!cdTipoRegimen) addError("El regimen es obligatorio");
    if (!dsCodigoCiiu) addError("Los codigos CIIU son obligatorios");
    if (!responsabilidadesFiscales) addError("Las responsabilidades fiscales son obligatorias");
    if (!dsDireccion) addError("La direccion es obligatoria");
    if (!ciudad || !ciudad.nmId) addError("La ciudad es obligatoria");
    return errores;
  };

  const openModal = ({ content, onCancel = () => setState({ openModal: false }) }) => setState({ openModal: true, content, onCancel });

  return (
    <form style={{ width: "80%", margin: "0px auto" }}>
      <div className="form-row">
        <div className="form-group col-xl-3">
          <SelectTipoPersona
            label="Tipo persona *"
            name="cdTipoPersona"
            value={emisor.cdTipoPersona}
            onChange={({ value }) => setEmisor({ cdTipoPersona: value })}
          />
        </div>
        <div className="form-group col-xl-2">
          <SelectTipoIdentificacion
            label="Tipo Identificacion *"
            name="persona.cdTipoIdentificacion"
            value={emisor.persona.cdTipoPersona}
            onChange={({ value }) => setPersona({ cdTipoIdentificacion: value })}
          />
        </div>
        <div className="form-group col-xl-2">
          <Input
            label="Identificacion *"
            name="persona.dsIdentificacion"
            value={emisor.persona.dsIdentificacion}
            onChange={({ target }) => setPersona({ dsIdentificacion: target.value })}
            onBlur={calcularDigito}
          />
        </div>
        <div className="form-group col-xl-2">
          <Input label="Digito Verificacion" name="persona.dsDigitoVerificacion" value={emisor.persona.dsDigitoVerificacion} disabled={true} />
        </div>
        <div className="form-group col-xl-2">
          <Input
            label="Celular *"
            name="persona.dsCelular"
            value={emisor.persona.dsCelular}
            onChange={({ target }) => setPersona({ dsCelular: target.value })}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-xl-2">
          <Input
            label="Nombre o razon social *"
            name="persona.dsNombre"
            value={emisor.persona.dsNombre}
            onChange={({ target }) => setPersona({ dsNombre: target.value })}
          />
        </div>
        <div className="form-group col-xl-2">
          <Input
            label="Segundo nombre"
            name="persona.dsSegundoNombre"
            value={emisor.persona.dsSegundoNombre}
            onChange={({ target }) => setPersona({ dsSegundoNombre: target.value })}
            disabled={emisor.cdTipoPersona !== "NATURAL"}
          />
        </div>
        <div className="form-group col-xl-2">
          <Input
            label={`Primer apellido ${emisor.cdTipoPersona === "NATURAL" ? "*" : ""}`}
            name="persona.dsPrimerApellido"
            value={emisor.persona.dsPrimerApellido}
            onChange={({ target }) => setPersona({ dsPrimerApellido: target.value })}
            disabled={emisor.cdTipoPersona !== "NATURAL"}
          />
        </div>
        <div className="form-group col-xl-2">
          <Input
            label="Segundo apellido"
            name="persona.dsSegundoApellido"
            value={emisor.persona.dsSegundoApellido}
            onChange={({ target }) => setPersona({ dsSegundoApellido: target.value })}
            disabled={emisor.cdTipoPersona !== "NATURAL"}
          />
        </div>
        <div className="form-group col-xl-3">
          <Input
            label="Email *"
            name="persona.dsEmail"
            value={emisor.persona.dsEmail}
            onChange={({ target }) => setPersona({ dsEmail: target.value })}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-xl-2" style={{ paddingTop: "20px" }}>
          <Checkbox
            label="Retenedor de Impuestos"
            name="cdRetenedorImpuestos"
            checked={emisor.cdRetenedorImpuestos === "S"}
            onChange={({ target }) => setEmisor({ cdRetenedorImpuestos: target.checked ? "S" : "N" })}
          />
        </div>
        <div className="form-group col-xl-3">
          <SelectRegimen
            label="Regimen *"
            name="cdTipoRegimen"
            value={emisor.cdTipoRegimen}
            onChange={({ value }) => setEmisor({ cdTipoRegimen: value })}
          />
        </div>
        <div className="form-group col-xl-2">
          <Input
            label="Codigos CIIU *"
            name="dsCodigoCiiu"
            value={emisor.dsCodigoCiiu}
            onChange={({ target }) => setEmisor({ dsCodigoCiiu: target.value })}
          />
        </div>
        <div className="form-group col-xl-2">
          <SelectRespFiscales label="Responsabilidades fiscales *" setDocumento={setEmisor} responsabilidades={emisor.responsabilidadesFiscales} />
        </div>
        <div className="form-group col-xl-2">
          <Input label="Responsabilidades fiscales" value={emisor.responsabilidadesFiscales} disabled={true} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-xl-2">
          <Input
            label="Direccion *"
            name="dsDireccion"
            value={emisor.persona.dsDireccion}
            onChange={({ target }) => setPersona({ dsDireccion: target.value })}
          />
        </div>
        <div className="form-group col-xl-2">
          <SelectCiudades label="Ciudad *" name="persona.ciudad" onChange={({ value }) => setPersona({ ciudad: { nmId: value } })} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-xl-2">
          <Button onClick={registrarEmisor}>Guardar</Button>
        </div>
      </div>
      <Modal open={state.openModal} content={state.content} onCancel={state.onCancel} />
    </form>
  );
};

export default CrearEmisorExpress;
