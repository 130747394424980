import React from 'react';
import { connect } from 'react-redux';
import TablaTransacciones from 'components/organisms/TablaTransacciones';
import FiltroEntreFechas from 'components/organisms/FiltroEntreFechas';
import useFiltroFechas from 'hooks/useFiltroFechas';
import { now, DATE_FORMAT } from 'utils/DateUtils';

const fechaMinima = now().subtract('months', 6).format(DATE_FORMAT);

function HistorialTransacciones({ userAttributes }) {
  const { updateFechas, feInicio, feFin } = useFiltroFechas();

  return (
    <div>
      <FiltroEntreFechas
        updateFechas={updateFechas}
        fechaMinima={fechaMinima}
        defaultInicio={feInicio}
        buttonTexto='Consultar'
      />
      <TablaTransacciones
        emisor={userAttributes['custom:custom:userid']}
        inicio={feInicio}
        fin={feFin}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HistorialTransacciones);
