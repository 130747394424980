import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { moneyFormat } from "../../utils/TextFormattingUtils";
import { Button } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(133, 198, 56, 1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledHeaderTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(133, 198, 56, 1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function tablaDevengados({ detalles, eliminarItem }) {
  const [pago, setPago] = useState(false);
  const [porcentaje, setPorcentaje] = useState(false);
  const [cantidad, setCiudad] = useState(false);
  const [auxilioNS, setAuxilioNS] = useState(false);
  const [auxilioS, setAuxilioS] = useState(false);
  const [bonificacionS, setBonificacionS] = useState(false);
  const [bonificacionNS, setBonificacionNS] = useState(false);
  const [pagoNS, setPagoNS] = useState(false);
  const [nmPagoAlimentacionS, setNmPagoAlimentacionS] = useState(false);
  const [nmPagoAlimentacionNS, setNmPagoAlimentacionNS] = useState(false);
  const [nmPagoIntereses, setNmPagoIntereses] = useState(false);
  const [nmCompensacionO, setNmCompensacionO] = useState(false);
  const [nmCompensacionE, setNmCompensacionE] = useState(false);
  const [dsDescripcionConcepto, setDsDescripcionConcepto] = useState(false);
  const [nmConceptoS, setNmConceptoS] = useState(false);
  const [nmConceptoNS, setNmConceptoNS] = useState(false);
  const [nmAuxilioTransporte, setNmAuxilioTransporte] = useState(false);
  const [nmViaticoManuAlojS, setNmViaticoManuAlojS] = useState(false);
  const [nmViaticoManuAlojNS, setNmViaticoManuAlojNS] = useState(false);

  useEffect(() => {
    setPago(detalles.filter((d) => d.nmPago).length > 0);
    setPorcentaje(detalles.filter((d) => d.nmPorcentaje).length > 0);
    setCiudad(detalles.filter((d) => d.nmCantidad).length > 0);
    setAuxilioNS(detalles.filter((d) => d.nmAuxilioNS).length > 0);
    setAuxilioS(detalles.filter((d) => d.nmAuxilioS).length > 0);
    setBonificacionS(detalles.filter((d) => d.nmBonificacionS).length > 0);
    setBonificacionNS(detalles.filter((d) => d.nmBonificacionNS).length > 0);
    setPagoNS(detalles.filter((d) => d.nmPagoNS).length > 0);
    setNmPagoAlimentacionS(detalles.filter((d) => d.nmPagoAlimentacionS).length > 0);
    setNmPagoAlimentacionNS(detalles.filter((d) => d.nmPagoAlimentacionNS).length > 0);
    setNmPagoIntereses(detalles.filter((d) => d.nmPagoIntereses).length > 0);
    setNmCompensacionO(detalles.filter((d) => d.nmCompensacionO).length > 0);
    setNmCompensacionE(detalles.filter((d) => d.nmCompensacionE).length > 0);
    setDsDescripcionConcepto(detalles.filter((d) => d.dsDescripcionConcepto).length > 0);
    setNmConceptoS(detalles.filter((d) => d.nmConceptoS).length > 0);
    setNmConceptoNS(detalles.filter((d) => d.nmConceptoNS).length > 0);
    setNmAuxilioTransporte(detalles.filter((d) => d.nmAuxilioTransporte).length > 0);
    setNmViaticoManuAlojS(detalles.filter((d) => d.nmViaticoManuAlojS).length > 0);
    setNmViaticoManuAlojNS(detalles.filter((d) => d.nmViaticoManuAlojNS).length > 0);
  }, [detalles]);

  return (
    detalles.length > 0 && (
      <div style={{ overflowX: "auto" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledHeaderTableCell />
              {pago && <StyledHeaderTableCell>Pago</StyledHeaderTableCell>}
              {porcentaje && <StyledHeaderTableCell>Porcentaje</StyledHeaderTableCell>}
              {cantidad && <StyledHeaderTableCell>Cantidad</StyledHeaderTableCell>}
              {auxilioNS && <StyledHeaderTableCell>Auxilio No Salarial</StyledHeaderTableCell>}
              {auxilioS && <StyledHeaderTableCell>Auxilio Salarial</StyledHeaderTableCell>}
              {bonificacionNS && <StyledHeaderTableCell>Bonificacion No Salarial</StyledHeaderTableCell>}
              {bonificacionS && <StyledHeaderTableCell>Bonificacion Salarial</StyledHeaderTableCell>}
              {pagoNS && <StyledHeaderTableCell>Pago No Salarial</StyledHeaderTableCell>}
              {nmPagoAlimentacionS && <StyledHeaderTableCell>Pago Alimentacion Salarial</StyledHeaderTableCell>}
              {nmPagoAlimentacionNS && <StyledHeaderTableCell>Pago Alimentacion No Salarial</StyledHeaderTableCell>}
              {nmPagoIntereses && <StyledHeaderTableCell>Pago Intereses</StyledHeaderTableCell>}
              {nmCompensacionO && <StyledHeaderTableCell>Compensacion Ordinarias</StyledHeaderTableCell>}
              {nmCompensacionE && <StyledHeaderTableCell>Compensacion Extraordinarias</StyledHeaderTableCell>}
              {dsDescripcionConcepto && <StyledHeaderTableCell>Descripcion</StyledHeaderTableCell>}
              {nmConceptoS && <StyledHeaderTableCell>Concepto Salarial</StyledHeaderTableCell>}
              {nmConceptoNS && <StyledHeaderTableCell>Concepto No Salarial</StyledHeaderTableCell>}
              {nmAuxilioTransporte && <StyledHeaderTableCell>Auxilio Transporte</StyledHeaderTableCell>}
              {nmViaticoManuAlojS && <StyledHeaderTableCell>Viatico Manual Salarial</StyledHeaderTableCell>}
              {nmViaticoManuAlojNS && <StyledHeaderTableCell>Viatico Manual No Salarial</StyledHeaderTableCell>}
              <StyledHeaderTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {detalles.map((d, key) => (
              <StyledTableRow key={key}>
                <StyledTableCell style={{ fontSize: "16px", fontWeight: "bold" }}>{getName(d.dsTipo)}</StyledTableCell>
                {pago && <StyledTableCell>{moneyFormat(d.nmPago)}</StyledTableCell>}
                {porcentaje && <StyledTableCell>{d.nmPorcentaje && `${d.nmPorcentaje} %`}</StyledTableCell>}
                {cantidad && <StyledTableCell>{d.nmCantidad}</StyledTableCell>}
                {auxilioNS && <StyledTableCell>{moneyFormat(d.nmAuxilioNS)}</StyledTableCell>}
                {auxilioS && <StyledTableCell>{moneyFormat(d.nmAuxilioS)}</StyledTableCell>}
                {bonificacionNS && <StyledTableCell>{moneyFormat(d.nmBonificacionNS)}</StyledTableCell>}
                {bonificacionS && <StyledTableCell>{moneyFormat(d.nmBonificacionS)}</StyledTableCell>}
                {pagoNS && <StyledTableCell>{moneyFormat(d.nmPagoNS)}</StyledTableCell>}
                {nmPagoAlimentacionS && <StyledTableCell>{moneyFormat(d.nmPagoAlimentacionS)}</StyledTableCell>}
                {nmPagoAlimentacionNS && <StyledTableCell>{moneyFormat(d.nmPagoAlimentacionNS)}</StyledTableCell>}
                {nmPagoIntereses && <StyledTableCell>{moneyFormat(d.nmPagoIntereses)}</StyledTableCell>}
                {nmCompensacionO && <StyledTableCell>{moneyFormat(d.nmCompensacionO)}</StyledTableCell>}
                {nmCompensacionE && <StyledTableCell>{moneyFormat(d.nmCompensacionE)}</StyledTableCell>}
                {dsDescripcionConcepto && <StyledTableCell>{d.dsDescripcionConcepto}</StyledTableCell>}
                {nmConceptoS && <StyledTableCell>{moneyFormat(d.nmConceptoS)}</StyledTableCell>}
                {nmConceptoNS && <StyledTableCell>{moneyFormat(d.nmConceptoNS)}</StyledTableCell>}
                {nmAuxilioTransporte && <StyledTableCell>{moneyFormat(d.nmAuxilioTransporte)}</StyledTableCell>}
                {nmViaticoManuAlojS && <StyledTableCell>{moneyFormat(d.nmViaticoManuAlojS)}</StyledTableCell>}
                {nmViaticoManuAlojNS && <StyledTableCell>{moneyFormat(d.nmViaticoManuAlojNS)}</StyledTableCell>}
                <StyledTableCell>
                  <Button onClick={() => eliminarItem(d)}>
                    <DeleteRoundedIcon/>
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  );
}

function getName(code) {
  switch (code) {
    case "ANTICIPO":
      return "Anticipo";
    case "CESANTIA":
      return "Cesantia";
    case "AUXILIO":
      return "Auxilio";
    case "BONIFICACION":
      return "Bonificacion";
    case "BONO_EPCTV":
      return "Bono EPCTV";
    case "COMISION":
      return "Comision";
    case "COMPENSACION":
      return "Compensacion";
    case "HED":
      return "Horas Extra Diarias";
    case "HEN":
      return "Horas Extra Nocturnas";
    case "HRN":
      return "Horas Recargo Nocturnas";
    case "HEDDF":
      return "Horas Extra Diarias Dominicales";
    case "HRDDF":
      return "Horas Recarga Diarias Dominicales y Festivos";
    case "HENDF":
      return "Horas Extra Diarias Dominicales y Festivos";
    case "HRNDF":
      return "Horas Recargo Nocturno Dominicales";
    case "HUELGA_LEGAL":
      return "Huelga Legal";
    case "INCAPACIODAD_COMUN":
      return "Incapacidad Comun";
    case "INCAPACIODAD_PROFESIONAL":
      return "Incapacidad Profesional";
    case "INCAPACIODAD_LABORAL":
      return "Incapacidad Laboral";
    case "LICENCIA_MP":
      return "Licencia Maternidad o Paternidad";
    case "LICENCIA_R":
      return "Licencia Remunerada del Documento";
    case "LICENCIA_NR":
      return "Licencia No Remunerada";
    case "OTRO_CONCEPTO":
      return "Otro Concepto";
    case "PAGO_TERCERO":
      return "Pago Tercero";
    case "PRIMA":
      return "Prima";
    case "TRANSPORTE":
      return "Transporte";
    case "VACACIONES_COMUN":
      return "Vacaciones Comunes";
    case "VACACIONES_COMPENSADAS":
      return "Vacaciones Compensadas";
    case "DOTACION":
      return "Dotacion";
    case "APOYO_SOST":
      return "Apoyo Sostenible";
    case "TELETRABAJO":
      return "Teletrabajo";
    case "BONIFI_RETIRO":
      return "Bonificacion Retiro";
    case "INDEMNIZACION":
      return "Indemnizacion";
    case "REINTEGRO":
      return "Reintegro";
    case "BASICO":
      return "Salario";
    default:
      return code;
  }
}
