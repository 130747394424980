import * as React from "react";
import { connect } from "react-redux";
import { SetSpinnerModal } from "reducers/actions/facturaActions";
import ResolucionTable from "components/GestionResoluciones/ResolucionTable";
import { consultarResolucionesPosEmisor } from "network/Api/Resolucion";

const GestionarResolucionesEmisor = (props) => {
  const [state, setData] = React.useState({});

  React.useEffect(() => {
    if (props.userAttributes && props.userAttributes.codigoEmisor) {
      setState({
        emisorId: props.userAttributes.codigoEmisor,
        emisorIdentificacion: props.userAttributes.identificacionEmisor,
      });
      consultarResoluciones(props.userAttributes.codigoEmisor);
    }
  }, [props.userAttributes]);

  const setState = (data) => setData((state) => ({ ...state, ...data }));

  const consultarResoluciones = (nmId) => {
    const { setSpinnerModal } = props;
    setSpinnerModal(true);
    consultarResolucionesPosEmisor({ emisor: nmId }).then((res) => {
      setState({ resoluciones: res.data });
      setSpinnerModal(false);
    });
  };

  return (
    <div>
      <ResolucionTable
        emisor={state.emisorId}
        identificacionEmisor={state.emisorIdentificacion}
        resoluciones={state.resoluciones}
        handleConsultarResoluciones={consultarResoluciones}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userAttributes: state.factura.userAttributes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionarResolucionesEmisor);
