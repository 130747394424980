import React from 'react';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

export const Medico = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
  {
    title: 'Facturación electrónica sector salud',
    icon: <LocalHospitalIcon />,
    pathname: '/salud',
  },
];
