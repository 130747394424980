import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import * as DateUtils from "utils/DateUtils";
import Loader from "components/Loaders/Loader";
import { consultarDocumentosEstadistica } from "network/Api/DetalleDocumento";
import * as TextFormattingUtils from "utils/TextFormattingUtils";
import TablaGenerica from "components/Tablas/TablaGenerica";
import { withStyles } from "@material-ui/core/styles";
import Modal from "components/modal/ModalConfirmacion";

const styles = () => ({
  table: { maxWidth: "20%", border: "1px solid #E1E0E0" },
  titleHeader: {
    fontWeight: "bold",
    color: "#000",
    fontSize: "18px",
    backgroundColor: "#afacac",
    borderRight: "1px solid #E1E0E0",
    textAlign: "center",
  },
  titleBody: {
    fontWeight: "bold",
    color: "#000",
    fontSize: ".9rem",
    backgroundColor: "#E1E0E0",
  },
  content: { border: "1px solid #E1E0E0", textAlign: "center" },
});

function ChartDocumento({ periodo, emisor, intervalo, classes, maxIntervalo }) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [tableValues, setTableValues] = useState([]);
  const [modal, setModal] = useState({ open: false });
  const [isAsync, setAsync] = useState(false);

  function getTableValues(estadistica, array) {
    array.push({
      fecha: getFormatoFecha(estadistica.feEstadistica),
      total: TextFormattingUtils.moneyFormat(parseFloat(estadistica.nmTotal).toFixed(0)),
    });
  }

  function getFormatoFecha(fechaString) {
    if (fechaString) {
      let fecha = fechaString.split("-");
      switch (fecha.length) {
        case 2:
          if (periodo === "MONTHLY") {
            return DateUtils.dateString(fechaString + "-01").format("MMMM YYYY");
          } else if (periodo === "WEEKLY") {
            return `Semana ${fecha[1]} / ${fecha[0]}`;
          }
          break;
        case 3:
          return DateUtils.dateString(fechaString).format("MMMM DD YYYY");
        default:
          return fechaString;
      }
      return fechaString;
    } else return "";
  }

  useEffect(() => {
    setLoading(true);
    if (emisor && intervalo && periodo && (!isAsync || intervalo <= maxIntervalo)) {
      consultarDocumentosEstadistica({
        emisor,
        intervalo: periodo,
        periodo: intervalo,
      }).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data === "ASYNC") {
            openModal(
              "Al consultar una cantidad de periodos superior a 1 año, en unos minutos se enviará un informe formato Excel al correo registrado en PortalFactura"
            );
            setAsync(true);
            setBlanco();
          } else {
            let labelsTemp = [];
            let arrayNeto = [];
            let values = [];
            res.data.forEach((estadistica) => {
              labelsTemp.push(getFormatoFecha(estadistica.feEstadistica));
              arrayNeto.push(parseFloat(estadistica.nmTotal).toFixed(0));
              getTableValues(estadistica, values);
            });
            setLabels(labelsTemp);
            setData([
              {
                label: "Total Facturado",
                data: arrayNeto.reverse(),
                backgroundColor: "#D6EAFF",
              },
            ]);
            setTableValues(values);
          }
        } else {
          alert("Error consultando documentos");
        }
      });
    } else if (isAsync) {
      setBlanco();
      openModal("Actualmente, hay un informe en progreso, una vez termine podrá generar un nuevo informe");
    }
  }, [emisor, intervalo, periodo]);

  const openModal = (msg) => setModal({ open: true, msg });

  const setBlanco = () => {
    setLoading(false);
    setLabels([]);
    setData([]);
    setTableValues([]);
  };

  return (
    <>
      <Modal open={modal.open} onAccept={() => setModal((m) => ({ ...m, open: false }))} content={modal.msg} />
      <div className="chart">
        {!isLoading ? (
          <Bar
            height={600}
            data={{
              labels: labels.reverse(),
              datasets: data,
            }}
            options={{
              maintainAspectRatio: false,
              title: {
                display: true,
                text: "Total Ventas",
                fontSize: 25,
              },
              legend: {
                display: true,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      callback: (label, position, array) => {
                        return TextFormattingUtils.moneyFormat(label);
                      },
                    },
                  },
                ],
              },
            }}
          />
        ) : (
          <Loader />
        )}
      </div>
      <TablaGenerica
        headers={["Fecha", "Total"]}
        keys={["fecha", "total"]}
        detalles={tableValues}
        withPagination={false}
        rowsPerPage={1000}
        classNames={classes}
        loading={isLoading}
        tableStyle={{ marginLeft: "60px", marginTop: "60px" }}
      />
    </>
  );
}

export default withStyles(styles)(ChartDocumento);
