import * as React from "react";
import Icon from "components/menu/Icon";
import EmisionIcon from "static/images/emision.png";
import Factura from "static/images/factura.png";
import Exportacion from "static/images/exportacion.png";
import Credito from "static/images/notacredito.png";
import Debito from "static/images/debito.png";
import SoporteNomina from "static/images/account_balance.svg";
import DocumentoExcel from "static/images/factexcel.png";
import Cotizacion from "static/images/cotizacion.png";
import DocSoporte from "static/images/docSoporte.png";
import NotAjsDocSop from "static/images/NotAjsDocSop.png";

export const emitirFactura = {
  title: "Emitir factura nacional",
  icon: <Icon src={Factura} alt="Factura de venta" />,
  pathname: "/emision-documento?tipo=VENTA",
};

export const emitirFacturaExoprtacion = {
  title: "Emitir factura de exportación",
  icon: <Icon src={Exportacion} alt="Factura de exportacion" />,
  pathname: "/emision-documento?tipo=EXPORTACION",
};

export const emitirDocumentoSoporte = {
  title: "Emitir documento soporte",
  icon: <Icon src={DocSoporte} alt="Documento Soporte" />,
  pathname: "/documentosoporte?tipo=SOPORTE_ADQUISICION",
};

export const emitirNotaCredito = {
  title: "Emitir notas crédito para facturas",
  icon: <Icon src={Credito} alt="Nota Crédito" />,
  pathname: "/facturaV2?tipo=NOTA_CREDITO",
};

export const emitirNotaDebito = {
  title: "Emitir nota débito para facturas",
  icon: <Icon src={Debito} alt="Nota Débito" />,
  pathname: "/facturaV2?tipo=NOTA_DEBITO",
};

export const emitirNotaCreditoDocumentoSoporte = {
  title: "Emitir nota crédito para documento soporte",
  icon: <Icon src={NotAjsDocSop} alt="Documento Soporte" />,
  pathname: "/documentosoporte?tipo=NOTA_CREDITO_SOPORTE_ADQUISICION",
};

const getMenu = (items) => ({
  subMenu: true,
  title: "Emisión",
  icon: <Icon src={EmisionIcon} alt="Emisión" />,
  items,
});

const PantallaSoporteNomina = {
  title: "Soporte nómina",
  icon: <Icon src={SoporteNomina} alt="Soporte nómina" />,
  pathname: "/SoporteNomina",
};

const Facturacion = [
  {
    isNoView: "true",
    pathname: "/facturaV2",
  },
  {
    isNoView: "true",
    pathname: "/emision-documento",
  },
];

const PantallaDocumentoExcel = {
  title: "Factura vía excel",
  icon: <Icon src={DocumentoExcel} alt="Factura vía Excel" />,
  pathname: "/documentoexcel",
};

const PamtallaCotizacion = {
  title: "Cotización documento",
  icon: <Icon src={Cotizacion} alt="Cotizar documento" />,
  pathname: "/cotizacion",
};

const SoporteFacturacion = [
  {
    isNoView: "true",
    pathname: "/documentosoporte",
  },
];

export const Emision = getMenu([...Facturacion, PantallaDocumentoExcel, PamtallaCotizacion, ...SoporteFacturacion]);

export const EmisionNomina = getMenu([PantallaSoporteNomina]);

export const EmisionFacturacion = getMenu([...Facturacion, PantallaDocumentoExcel, PamtallaCotizacion]);
