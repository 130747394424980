import React from 'react';
import BaseSelect from 'react-select';
import Async from 'react-select/async';
import Typography from 'components/atoms/Typography';

export const AsyncSelect = ({
  label,
  margin,
  width = '100%',
  labelStyle,
  error,
  classLabel,
  ...rest
}) => {
  return (
    <div style={{ margin, width }}>
      <div>
        {label && (
          <label style={labelStyle} className={classLabel}>
            {label}
          </label>
        )}
        <Async {...rest} />
      </div>
      {error && <small style={{ color: 'red' }}>{error}</small>}
    </div>
  );
};

const Select = ({ label, margin, width = '100%', labelStyle, error, classLabel, ...rest }) => {
  return (
    <div style={{ margin, width }}>
      <div>
        {label && <Typography variant='labelInput'>{label}</Typography>}
        <BaseSelect {...rest} />
      </div>
      {error && <small style={{ color: 'red' }}>{error}</small>}
    </div>
  );
};

export default Select;
