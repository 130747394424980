import { ConfiguracionSinFirma } from "./SubMenus/Configuracion";
import { UtilidadesSinFirma } from "./SubMenus/Utilidades";

export const EmisorSinFirma = [
  {
    isNoView: "true",
    pathname: "/Home",
    principal: "true",
  },
  ConfiguracionSinFirma,
  UtilidadesSinFirma,
];
