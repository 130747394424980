import React, { useState } from "react";
import Input from "../../../Inputs/Input";
import Button from 'components/atoms/Button';
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function sancion({ baseBody, guardarDeduccion, xlCol = "2" }) {
  const [nmSancionPublic, setNmSancionPublic] = useState();
  const [nmSancionPriv, setNmSancionPriv] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `SANCION`,
    nmSancionPublic,
    nmSancionPriv,
  };

  const guardar = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDeduccion(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmSancionPublic();
    setNmSancionPriv();
  };

  return (
    <form onSubmit={guardar}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago de la Sanción Pública *"
            value={nmSancionPublic || ""}
            onChange={(e) => setNmSancionPublic(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor por el del incumplimiento de una regla o norma de conducta obligatoria (Publica)"
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago de la Sanción Privada *"
            value={nmSancionPriv || ""}
            onChange={(e) => setNmSancionPriv(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor por el del incumplimiento de una regla o norma de conducta obligatoria (Privada o Ordinaria)"
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmSancionPublic || !body.nmSancionPriv)
    errores.push("La sancion publica y la privada son obligatorias");
  return errores;
}
