/* eslint-disable */
import { withStyles } from '@material-ui/core/styles';
import Help from '@material-ui/icons/HelpOutline';
import { subirArchivo } from 'utils/UploadS3';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  SetConfirmacionModalState,
  SetLoadingValue,
  SetSpinnerModal,
} from 'reducers/actions/facturaActions';
import {
  handleKeyPressTexto,
  handleKeyPressTextoEmail,
  handleKeyPressTextoNumeroGuion,
  handleKeyPressNumeros,
} from 'utils/funcionesUtiles';
import LabelTitulos from 'components/layout/labelTitulos';
import ConfirmacionModal from 'components/modals/confirmacionModal';
import ContratoModal from 'components/landingPage/contratoModal';
import 'components/landingPage/css/creative.css';
import { GetUserData, RetrieveCurrentSessionRefreshToken } from 'screens/login/auth/amplifyAuth';
import { Redirect } from 'react-router';
import moment from 'moment';
import Checkbox from 'components/molecules/Checkbox';
import { finalizarRegistro } from 'network/Api/Emisor';
import { consultarPersonaPorIdentificacion } from 'network/Api/Persona';

let regEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const urlBucket = 'https://s3.us-east-2.amazonaws.com/portalfactura.com/public/';
const styles = (theme) => ({
  containerLogos: {
    maxHeight: 'inherit',
    flex: 1,
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    maxHeight: '3em',
    maxWidth: '100%',
    margin: 0,
    padding: 0,
  },
  rootLogo: {
    display: 'inline',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    maxHeight: 'inherit',
    maxWidth: '100%',
  },
  logo: { maxHeight: 'inherit', maxWidth: '100%' },
  titulo: { fontFamily: 'Aclonica', fontSize: '1.6rem' },
  root: { flexGrow: 2, maxWidth: '100%' },
  fontFamily: { whiteSpace: 'nowrap', textAlign: 'left' },
  show: {
    display: 'block',
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  logo: { width: '200px', height: '200px', backgroundSize: 'cover' },
});

const customStyles = {
  input: (styles) => {
    return { ...styles, height: '2.25rem' };
  },
};

class TerminarRegistro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DsEmailRemitente: 'portal.factura@tecnofactor.com',
      CdTipoPersona: '',
      CdTipoRegimen: '',
      CdRetenedorImpuestos: '',
      CdRespIva: '',
      multiValue: [],
      DsRespFiscales: '',
      DsCodigoCiiu: '',
      DsPiePagina: '',
      FePlanFactura: '',
      fechaMinima: '',
      urlLogo: '',
      textoInputLogo: 'Subir logo...',
      urlPdfRut: '',
      textoInputPdfRut: 'Subir RUT...',
      DsContrasenaRut: '',
      file: '',
      filePdfRut: '',
      DsUrlLogo: '',
      DsUrlRut: '',
      subiendoLogo: false,
      subiendoPdf: false,
      openModal: false,
      identificacionEmisor: '',
      redirectHome: false,
      numeroCaracteresPiePagina: 0,
      /*nuevos historia 38*/
      datosPersona: null,
      DsTestid: null,
      imagenHeaderIzquierda: null,
      DsTokenSeguridad: null,

      textoInputCarta: '',
      cartaNotariaCamaraComercio: null,

      fotocopiaCedula: null,
      fotocopiaCedulaSubiendo: false,

      urlFotocopiaCedula: null,
      urlModeloConfianza: null,
      urlCartaNotariadaDocCamComercio: null,

      textoInputModeloConfianza: 'Subir Modelo de Confianza...',
      modeloConfianza: null,

      cartaNotariadaCamaraComercioTexto: 'Subir documento...',
      fotocopiaCedulaTexto: 'Subir cedula...',
    };
  }

  componentDidMount() {
    GetUserData().then((result) => {
      let identificacionEmisor = result.attributes['custom:custom:userid'];
      this.obtenerDatosPersona(identificacionEmisor);
    });
    this.obtenerFechaMinima();
  }

  obtenerDatosPersona = (dsIdentificacion) => {
    consultarPersonaPorIdentificacion({ dsIdentificacion }).then((result) => {
      if (result.status == 200) {
        this.setState({
          datosPersona: result.data,
        });
      } else {
        this.mensajes(
          false,
          `No se pudo consultar la información de la persona para enviar a Domina`,
          this.mensajes,
          true,
        );
      }
    });
  };

  obtenerFechaMinima = () => {
    var fechaActual = new Date();
    fechaActual.setDate(fechaActual.getDate() + 10);
    var month = fechaActual.getMonth() + 1;
    var day = fechaActual.getDate();
    var year = fechaActual.getFullYear();
    if (month.toString().length < 2) month = '0' + month;
    if (day.toString().length < 2) day = '0' + day;
    fechaActual = `${year}-${month}-${day}`;
    this.setState({ fechaMinima: fechaActual });
  };

  handleChange = (event) => {
    let value = event.target.value;
    if (event.target.id == 'DsPiePagina') {
      if (value.length > 300) {
        value = value.substring(0, 299);
      }
      this.setState({
        [event.target.id]: value,
        numeroCaracteresPiePagina: value.length,
      });
    } else if (event.target.id === 'DsTestid') {
      const patron = /^[A-Za-z0-9\- ]*$/;
      if (value.length > 0) {
        if (patron.test(value) === false) {
          this.setState({ DsTestid: '' });
          return;
        }
        if (value.length > 255) {
          this.setState({ DsTestid: '' });
          return;
        }
        this.setState({ DsTestid: value.replace(/ /g, '') });
      }
    } else {
      this.setState({ [event.target.id]: value });
    }
  };

  handleChangeFechaMinima = (event) => {
    let value = event.target.value;
    const { fechaMinima } = this.state;
    let fecha = fechaMinima;
    let fechaObtenida = value;
    fecha = fecha.replace(/-/gi, '');
    fechaObtenida = fechaObtenida.replace(/-/gi, '');
    if (parseFloat(fechaObtenida) < parseFloat(fecha)) return;
    this.setState({ FePlanFactura: value });
  };

  getSnRespIva = (regimen) => {
    switch (regimen) {
      case 'RESPONSABLE_IVA':
        return 'S';
      case 'NO_RESPONSABLE_IVA':
        return 'N';
      default:
        return null;
    }
  };

  handleCloseModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handleChangeCdTipoPersona = (value) => {
    this.setState({ CdTipoPersona: value.value });
  };

  handleChangeRegimenAdquirente = (value) =>
    this.setState({
      CdTipoRegimen: value.value,
      CdRespIva: this.getSnRespIva(value.value),
    });

  handleChangeCdRetenedorImpuestos = (value) => {
    this.setState({ CdRetenedorImpuestos: value.value });
  };

  handleChangeCdRespIva = (value) => {
    this.setState({ CdRespIva: value.value.substring(0, 1) });
  };

  handleEnviar = (event) => {
    event.preventDefault();
    let sw = true;
    const {
      CdTipoPersona,
      CdTipoRegimen,
      CdRetenedorImpuestos,
      CdRespIva,
      DsRespFiscales,
      datosPersona,
      imagenHeaderIzquierda,
    } = this.state;
    if (
      CdTipoPersona == '' ||
      CdTipoRegimen == '' ||
      CdRetenedorImpuestos == '' ||
      CdRespIva == '' ||
      DsRespFiscales == ''
    ) {
      sw = false;
    }

    if (sw == false) {
      this.mensajes(
        false,
        `Faltan campos por llenar. Por favor tenga en cuenta que los campos marcados con (*) son obligatorios`,
        this.mensajes,
        true,
      );
      return;
    }

    if (datosPersona === null) {
      this.mensajes(
        false,
        `No se puede continuar porque no se tienen los datos de la persona`,
        this.mensajes,
        true,
      );
      return;
    }

    if (imagenHeaderIzquierda === null) {
      this.mensajes(
        false,
        `No se puede continuar porque no se tiene la imagen en formato base 64`,
        this.mensajes,
        true,
      );
      return;
    }

    if (!regEmail.test(this.state.DsEmailRemitente)) {
      this.mensajes(false, `El campo 'E-Mail' tiene un formato inválido`, this.mensajes, true);
      return;
    }

    this.mensajes(false, `¿Seguro que desea guardar la información?`, () => {
      this.mensajes(true);
      this.ConsultarIdentificacionEmisor();
    });
  };

  mensajes = (close, texto, onClick, parametro) => {
    let modal;
    if (close) {
      modal = { open: false };
      this.props.setConfirmacionModalState(modal);
      return;
    }

    if (parametro != null) {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(parametro),
        cancelarVisible: true,
      };
    } else {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(),
        cancelarVisible: true,
      };
    }
    this.props.setConfirmacionModalState(modal);
  };

  EnviarEmisor = () => {
    this.props.setLoadingValue(true);
    finalizarRegistro(this.getBody()).then((result) => {
      this.props.setLoadingValue(false);
      if (result.status === 200) {
        this.setState({ redirectHome: true });
      } else {
        this.mensajes(
          false,
          `Ocurrió un error al guardar la información del emisor.`,
          this.mensajes,
          true,
        );
      }
    });
  };

  getBody = () => {
    const {
      identificacionEmisor,
      CdTipoPersona,
      CdTipoRegimen,
      DsTokenSeguridad,
      DsPiePagina,
      DsEmailRemitente,
      CdRetenedorImpuestos,
      imagenHeaderIzquierda,
      DsUrlRut,
      DsUrlLogo,
      DsContrasenaRut,
      DsCodigoCiiu,
      DsRespFiscales,
      CdRespIva,
      DsTestid,
      fotocopiaCedulaUrl,
      cartaNotariadaCamaraComercioUrl,
      modeloConfianzaUrl,
      sinLogo,
    } = this.state;
    return {
      persona: { dsIdentificacion: identificacionEmisor },
      cdTipoPersona: CdTipoPersona,
      cdTipoRegimen: CdTipoRegimen,
      dsTokenSeguridad: DsTokenSeguridad,
      dsEmailRemitente: DsEmailRemitente,
      cdRetenedorImpuestos: CdRetenedorImpuestos,
      dsLogo: sinLogo ? '' : 'data:image/' + imagenHeaderIzquierda.replace(',', ';base64,'),
      dsUrlLogo: sinLogo ? '' : DsUrlLogo,
      dsUrlRut: DsUrlRut,
      dsContrasenaRut: DsContrasenaRut,
      dsCodigoCiiu: DsCodigoCiiu,
      cdRespIva: CdRespIva,
      dsTestid: DsTestid,
      dsRespFiscales: DsRespFiscales,
      dsPiePagina: DsPiePagina,
      snPdfNomina: true,
      certFirma: {
        dsFotocopiaCedula: fotocopiaCedulaUrl,
        dsUrlModeloConfianza: modeloConfianzaUrl,
        dsUrlCartaNotariadaCamComercio: cartaNotariadaCamaraComercioUrl,
      },
    };
  };

  ConsultarIdentificacionEmisor = () => {
    RetrieveCurrentSessionRefreshToken().then(() => {
      GetUserData().then((result) => {
        let identificacionEmisor = result.attributes['custom:custom:userid'];
        this.setState({ identificacionEmisor: identificacionEmisor }, () => {
          this.EnviarEmisor();
        });
      });
    });
  };

  handleKeyPressTexto = (event) => {
    if (!handleKeyPressTexto(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressNumeros = (event) => {
    if (!handleKeyPressNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoNumeroGuion = (event) => {
    if (!handleKeyPressTextoNumeroGuion(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoEmail = (event) => {
    if (!handleKeyPressTextoEmail(event)) {
      event.preventDefault();
    }
  };

  ordenarArray = (array) => {
    array.sort(function (a, b) {
      let nombre = a.label.toString().toLowerCase();
      let nombreComparar = b.label.toString().toLowerCase();
      if (nombre > nombreComparar) {
        return 1;
      }
      if (nombre < nombreComparar) {
        return -1;
      }
      return 0;
    });
    return array;
  };

  handleMultiChange = (option) => {
    let multiValue = this.state.multiValue;
    let isExistente = multiValue.filter((m) => m.value === option.value);
    let nuevasResponsabilidades =
      isExistente.length > 0
        ? multiValue.filter((m) => m.value !== option.value)
        : [...multiValue, option];
    this.setState({
      multiValue: nuevasResponsabilidades,
      DsRespFiscales: nuevasResponsabilidades.map((nr) => nr.value).join(';'),
    });
  };

  handleChangeImage = (event) => {
    const file = event.target.files[0];
    if (file == undefined) return;
    if (file.size > 1000000) {
      this.setState({
        urlLogo: '',
        file: '',
        textoInputLogo: 'Archivo no permitido',
      });
      return;
    }
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
      let fileUrl = URL.createObjectURL(file);
      let nombreLogo = `${file.name.replace(/ /g, '')}`;
      let idCardBase64 = '';
      this.getBase64(file, (result) => {
        idCardBase64 = result;
        if (idCardBase64 !== null) {
          const splitBase64 = idCardBase64.split(',');
          const splitTypeFile = file.type.split('/');
          this.setState({
            imagenHeaderIzquierda: `${splitTypeFile[1]},${splitBase64[1]}`,
          });
        }
      });
      this.setState(
        {
          urlLogo: fileUrl,
          file,
          textoInputLogo: nombreLogo,
          subiendoLogo: true,
        },
        () => this.saveFileImage(),
      );
    } else {
      this.setState({
        urlLogo: '',
        file: '',
        textoInputLogo: 'Archivo no permitido',
      });
      return;
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function () {
      this.mensajes(false, `No se pudo convertir la imagen a Base64`, this.mensajes, true);
      cb(null);
    };
  }

  handleChangeRut = (event) => {
    const file = event.target.files[0];
    if (file == undefined) return;
    if (file.type == 'application/pdf') {
      let fileUrl = URL.createObjectURL(file);
      let nombrePdf = `${moment().format('YYYYMMDDHHmmss')}.pdf`;
      this.setState(
        {
          urlPdfRut: fileUrl,
          filePdfRut: file,
          textoInputPdfRut: nombrePdf,
          subiendoPdf: true,
        },
        () => this.saveFilePdf(),
      );
    } else {
      this.setState({
        urlPdfRut: '',
        filePdfRut: '',
        textoInputPdfRut: 'Archivo no permitido',
      });
      return;
    }
  };

  saveFileImage = () => {
    const { emisorId } = this.props;
    subirArchivo(this.state.file, emisorId).then((resultadoLoad) =>
      this.setState({ DsUrlLogo: resultadoLoad.urlComplete, subiendoLogo: false }),
    );
  };

  saveFilePdf = () => {
    const { datosPersona } = this.state;
    let fecha = Math.floor(Date.now() / 1000);
    Storage.put(
      `${datosPersona.dsIdentificacion}/${fecha}${this.state.textoInputPdfRut}`,
      this.state.filePdfRut,
    )
      .then((result) => {
        this.setState({
          DsUrlRut: `${urlBucket}${result.key}`,
          subiendoPdf: false,
        });
      })
      .catch((err) => {
        this.mensajes(
          false,
          `Ocurrió un error al subir el PDF al servidor: ${err}`,
          this.mensajes,
          true,
        );
        this.setState({ subiendoPdf: false });
      });
  };

  handleUploadFile = (event) => {
    const file = event.target.files[0];
    const baseName = event.target.id;
    this.setState({ [baseName]: file, [`${baseName}Texto`]: file.name });
    this.saveFile(file.name, file, `${baseName}Url`, `${baseName}Subiendo`);
  };

  saveFile = async (nombre, file, nombreUrl, nombreSubiendo) => {
    const { datosPersona } = this.state;
    this.setState({ [nombreSubiendo]: true });
    let fecha = Math.floor(Date.now() / 1000);
    await Storage.put(`${datosPersona.dsIdentificacion}/${fecha}${nombre.replace(/ /g, '')}`, file)
      .then((result) => this.setState({ [nombreUrl]: `${urlBucket}${result.key}` }))
      .catch((err) =>
        this.mensajes(
          false,
          `Ocurrio un error subiendo ${nombre} al servidor ${err}`,
          this.mensajes,
          true,
        ),
      );
    this.setState({ [nombreSubiendo]: false });
  };

  handleChangeSinLogo = (e) => {
    this.setState({
      sinLogo: e.target.checked,
      textoInputLogo: 'Subir logo...',
      DsUrlLogo: '',
      imagenHeaderIzquierda: '',
    });
  };

  render() {
    const {
      classes,
      loading,
      responsableIvaList,
      retenedorImpuestosList,
      tiposPersona,
      regimenAdquirente,
      tiposDsRespFiscales,
    } = this.props;

    const {
      openModal,
      multiValue,
      DsCodigoCiiu,
      DsPiePagina,
      textoInputLogo,
      textoInputPdfRut,
      DsContrasenaRut,
      DsUrlLogo,
      subiendoLogo,
      subiendoPdf,
      redirectHome,
      numeroCaracteresPiePagina,
      DsTestid,
      CdTipoPersona,
      cartaNotariadaCamaraComercioTexto,
      fotocopiaCedulaTexto,
      sinLogo,
    } = this.state;

    if (redirectHome) return <Redirect to={{ pathname: '/Home' }} />;

    let responsableIvaSelect = [];
    responsableIvaList.map((item) => {
      responsableIvaSelect.push({ value: item.codigo, label: item.nombre });
    });

    let retenedorImpuestosSelect = [];
    retenedorImpuestosList.map((item) => {
      retenedorImpuestosSelect.push({ value: item.codigo, label: item.nombre });
    });

    let tiposPersonaSelect = [];
    tiposPersona.map((item) => {
      tiposPersonaSelect.push({
        value: item.tipoPersonaAdquiriente,
        label: item.nombre,
      });
    });

    let regimenAdquirenteSelect = [];
    regimenAdquirente.map((item) => {
      regimenAdquirenteSelect.push({ value: item.codigo, label: item.nombre });
    });

    let tiposDsRespFiscalesSelect = [];
    tiposDsRespFiscales.map((item) => {
      tiposDsRespFiscalesSelect.push({
        value: item.codigo,
        label: item.nombre,
      });
    });
    tiposDsRespFiscalesSelect = this.ordenarArray(tiposDsRespFiscalesSelect);

    return (
      <form onSubmit={this.handleEnviar}>
        <header className='masthead-nuevo-emisor-terminar-registro mt-0'>
          <div className='container h-100 mt-0'>
            <a className={classes.titulo}>
              <LabelTitulos texto='Terminar registro' />
            </a>
            <p style={{ textAlign: 'center' }} className='text-muted mb-2'>
              Información de la empresa o persona natural que realizará facturación electrónica.
            </p>
            <div className='form-row col-md-12 d-flex justify-content-between'>
              <div className='form-group col-md-12 col-xl-8 mb-0 mt-2 text-center'>
                <label className='text-muted'>RUT*</label>
                <div class='custom-file'>
                  <input
                    type='file'
                    class='custom-file-input'
                    id='urlPdfRut'
                    accept='application/pdf'
                    onChange={this.handleChangeRut}
                    required
                  />
                  <label class='custom-file-label' for='urlPdfRut'>
                    {textoInputPdfRut}
                  </label>
                </div>
              </div>
              <div className='form-group col-md-12 col-xl-1 mt-5'>
                <a
                  data-toggle='tooltip'
                  title='En caso del que el archivo PDF del RUT tenga contraseña para abrirlo, por favor ingrese la contraseña.'
                >
                  {' '}
                  <Help />
                </a>
              </div>
              {subiendoPdf && <div class='spinner-border text-primary mt-5' />}
              {!subiendoPdf && (
                <div className='form-group col-md-12 col-xl-2 mb-0 text-center'>
                  <label className='text-muted'>Contraseña del RUT</label>
                  <div className='form-label-group'>
                    <input
                      maxLength='100'
                      autoComplete='off'
                      type='text'
                      id='DsContrasenaRut'
                      className='form-control form-control-sm'
                      value={DsContrasenaRut}
                      placeholder='Contraseña pdf'
                      onChange={this.handleChange}
                    />
                    <label className={classes.fontFamily} htmlFor='DsContrasenaRut'>
                      Ingrese...
                    </label>
                  </div>
                </div>
              )}
              <div className='form-group col-md-12 col-xl-1 mt-5'>
                <a
                  data-toggle='tooltip'
                  title='En caso del que el archivo PDF del RUT tenga contraseña para abrirlo, por favor ingrese la contraseña.'
                >
                  {' '}
                  <Help />
                </a>
              </div>
            </div>
            <div className='form-row col-md-12 d-flex justify-content-between '>
              <div className='form-group col-md-12 col-xl-8 mb-0 mt-2 text-center'>
                <label className='text-muted'>Logo*</label>
                <div class='custom-file'>
                  <input
                    type='file'
                    class='custom-file-input'
                    id='urlLogo'
                    accept='image/jpg,image/png,image/jpeg'
                    onChange={this.handleChangeImage}
                    disabled={sinLogo}
                  />
                  <label class='custom-file-label' for='urlLogo'>
                    {textoInputLogo}
                  </label>
                </div>
              </div>
              <div className='form-group col-md-12 col-xl-1 mt-5'>
                <a
                  data-toggle='tooltip'
                  title='Se permite archivos JPG y PNG máximo con 1 mb de tamaño.'
                >
                  {' '}
                  <Help />
                </a>
              </div>
              <div className='form-group col-md-12 col-xl-3 mt-5'>
                {subiendoLogo && <div class='spinner-border text-primary' />}
                {DsUrlLogo.length > 0 && (
                  <div
                    style={{
                      width: '100%',
                      height: '80px',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url(${DsUrlLogo})`,
                    }}
                  />
                )}
              </div>
            </div>
            <div className='form-row col-md-12 d-flex justify-content-between'>
              <Checkbox label='Sin logo' onChange={this.handleChangeSinLogo} />
            </div>
            <div className='form-row col-md-12 d-flex justify-content-between'>
              <div className='form-group col-md-12 col-xl-3 mb-3 text-center'>
                <label className='text-muted'>Tipo persona*</label>
                <Select
                  onChange={this.handleChangeCdTipoPersona.bind(this)}
                  options={tiposPersonaSelect}
                  placeholder='Tipo persona*'
                  styles={customStyles}
                />
              </div>
              <div className='form-group col-md-12 col-xl-3 mb-3 text-center'>
                <label className='text-muted'>Tipo regimen*</label>
                <Select
                  onChange={this.handleChangeRegimenAdquirente.bind(this)}
                  options={regimenAdquirenteSelect}
                  placeholder='Regimen adquirente*'
                  styles={customStyles}
                />
              </div>
              <div className='form-group col-md-12 col-xl-3 mb-3 text-center'>
                <label className='text-muted'>Retenedor de impuestos*</label>
                <Select
                  onChange={this.handleChangeCdRetenedorImpuestos.bind(this)}
                  options={retenedorImpuestosSelect}
                  placeholder='Es retenedor de impuestos*'
                  styles={customStyles}
                />
              </div>
            </div>
            <div className='form-row col-md-12 d-flex justify-content-between'>
              <div className='form-group col-md-12 col-xl-6 mb-0 text-center'>
                <label className='text-muted'>Test ID</label>
                <div className='form-label-group'>
                  <input
                    maxLength='300'
                    autoComplete='off'
                    type='text'
                    onKeyPress={this.handleKeyPressTextoNumeroGuion}
                    id='DsTestid'
                    className='form-control form-control-sm'
                    value={DsTestid}
                    placeholder='Test ID'
                    onChange={this.handleChange}
                  />
                  <label className={classes.fontFamily} htmlFor='DsTestid'>
                    Ingrese Test ID
                  </label>
                </div>
              </div>
              <div className='form-group col-md-12 col-xl-3 mb-0 text-center'>
                <label className='text-muted'>Códigos CIIU *</label>
                <div className='form-label-group'>
                  <input
                    maxLength='100'
                    autoComplete='off'
                    type='text'
                    onKeyPress={this.handleKeyPressNumeros}
                    id='DsCodigoCiiu'
                    className='form-control form-control-sm'
                    value={DsCodigoCiiu}
                    placeholder='Codigos'
                    onChange={this.handleChange}
                    required
                  />
                  <label className={classes.fontFamily} htmlFor='DsCodigoCiiu'>
                    Ingrese códigos CIIU
                  </label>
                </div>
              </div>
              <div className='form-group col-md-12 col-xl-3 mb-0 text-center'>
                <label className='text-muted'>Responsabilidades fiscales *</label>
                <Select
                  name='DsRespFiscales'
                  placeholder='Responsabilidades fiscales'
                  value={multiValue}
                  options={tiposDsRespFiscalesSelect}
                  onChange={this.handleMultiChange.bind(this)}
                  styles={customStyles}
                  multi
                />
              </div>
              <div
                className='form-row col-md-9'
                style={{
                  boxShadow: '0px 0px 9px -6px rgba(0,0,0,0.75)',
                  border: '.5px solid gray',
                  borderRadius: '5px',
                }}
              >
                <LabelTitulos texto='Certificado de firma digital' />
                <div className='form-row col-md-12 d-flex justify-content-between '>
                  <div className='form-group col-md-12 col-xl-8 mb-0 mt-2 text-center'>
                    <label className='text-muted'>
                      {CdTipoPersona === 'NATURAL'
                        ? 'Carta Notariada'
                        : 'Documento de Cámara y Comercio'}
                    </label>
                    <div class='custom-file'>
                      <input
                        type='file'
                        class='custom-file-input'
                        id='cartaNotariadaCamaraComercio'
                        accept='application/pdf'
                        onChange={this.handleUploadFile}
                        disabled={!CdTipoPersona}
                      />
                      <label class='custom-file-label' for='cartaNotariadaCamaraComercio'>
                        {cartaNotariadaCamaraComercioTexto}
                      </label>
                    </div>
                  </div>
                  <div className='form-group col-md-12 col-xl-1 mt-5'>
                    <a
                      data-toggle='tooltip'
                      title='Permite archivos tipo PDF, subir la carta notariada si es persona natural o el documento de cámara y comercio si es persona jurídica.'
                    >
                      {' '}
                      <Help />
                    </a>
                  </div>
                </div>
                <div className='form-row col-md-12 d-flex justify-content-between '>
                  <div className='form-group col-md-12 col-xl-8 mb-0 mt-2 text-center'>
                    <label className='text-muted'>Fotocopia de la cedula</label>
                    <div class='custom-file'>
                      <input
                        type='file'
                        class='custom-file-input'
                        id='fotocopiaCedula'
                        accept='image/jpg,image/png,image/jpeg,application/pdf'
                        onChange={this.handleUploadFile}
                      />
                      <label class='custom-file-label' for='fotocopiaCedula'>
                        {fotocopiaCedulaTexto}
                      </label>
                    </div>
                  </div>
                  <div className='form-group col-md-12 col-xl-1 mt-5'>
                    <a data-toggle='tooltip' title='Se permite archivos JPG, PNG, y PDF.'>
                      {' '}
                      <Help />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='form-row col-md-12 d-flex'>
              <div className='form-group col-md-12 col-xl-8 mb-0 text-center'>
                <label className='text-muted'>Pie de página en factura</label>
                <textarea
                  maxLength='300'
                  autoComplete='off'
                  type='text'
                  id='DsPiePagina'
                  className='form-control form-control-sm'
                  value={DsPiePagina}
                  onChange={this.handleChange}
                  style={{ height: '4.92rem' }}
                />
                <div style={{ alignItems: 'center', textAlign: 'center' }}>
                  <small className='text-muted'>{numeroCaracteresPiePagina}/300 Caracteres</small>
                </div>
              </div>
              <div className='form-group col-md-12 col-xl-4  mt-4'>
                <button
                  className='btn btn-lg btn-primary btn-block text-uppercase mt-2'
                  type='submit'
                  disabled={loading}
                >
                  {loading && (
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                  GUARDAR Y CONTINUAR
                </button>
              </div>
            </div>
          </div>
        </header>
        <ContratoModal openAccion={openModal} handleCloseModal={this.handleCloseModal.bind(this)} />
        <ConfirmacionModal />
      </form>
    );
  }
}

TerminarRegistro.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    retenedorImpuestosList: state.factura.retenedorImpuestos,
    responsableIvaList: state.factura.responsableIva,
    tiposPersona: state.factura.tiposPersona,
    regimenAdquirente: state.factura.regimenAdquirente,
    tiposDsRespFiscales: state.factura.tiposResponsabilidadesFiscales,
    loading: state.factura.loading,
    userAttributes: state.factura.userAttributes,
    ciudadesArray: state.factura.ciudadesArray,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setLoadingValue: (item) => dispatch(SetLoadingValue(item)),
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TerminarRegistro));
