import * as React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import moment from 'moment';
import { subirArchivo } from 'utils/UploadS3';
import { SetSpinnerModal } from 'reducers/actions/facturaActions';
import { registrarCargaMasiva, procesarDocumentosCargaMasiva } from 'network/Api/CargaMasiva';
import Radio from 'components/molecules/Radio';
import './style.css';

const { Component } = React;

const TIPO_CARGAS = [
  { label: 'Documento electronico', value: 'DOCUMENTO_ELECTRONICO' },
  { label: 'Documento soporte', value: 'DOCUMENTO_SOPORTE' },
];

class CargaMasiva extends Component {
  state = {
    msgModal: '',
    modalOpen: false,
    rutaJson: [],
    nombreJson: 'Selecciona un documento json...',
    subiendoArchivo: false,
    cdTipo: 'DOCUMENTO_ELECTRONICO',
  };

  handleChange = (event) => {
    let label = event.target.name;
    let value = event.target.value;
    switch (label) {
      case 'documentos':
        this.handleUploadJson(event.target.files[0]);
        break;
      default:
        this.setState({ [label]: value });
        break;
    }
  };

  handleGetBytes = (file) => {
    if (file.name.endsWith('.json')) {
      this.setState({ nombreJson: file.name, subiendoArchivo: true });
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          var arrayBuffer = new Uint8Array(evt.target.result);
          this.setState({ rutaJson: arrayBuffer, subiendoArchivo: false });
        }
      };
    } else {
      this.setState({
        msgModal: 'Es obligatorio que sea un documento json',
        modalOpen: true,
      });
    }
  };

  handleGetJson = (file) => {
    if (file.name.endsWith('.json')) {
      this.setState({ nombreJson: file.name, subiendoArchivo: true });
      let fr = new FileReader();
      fr.onload = (e) => {
        this.setState({
          rutaJson: e.target.result,
          subiendoArchivo: false,
        });
      };
      fr.readAsText(file);
    } else {
      this.setState({
        msgModal: 'Es obligatorio que sea un documento json',
        modalOpen: true,
      });
    }
  };

  handleUploadJson = (file) => {
    const { userAttributes } = this.props;
    if (file.name.endsWith('.json') && file.size < 5000000) {
      let name = `${moment().format('YYYYMMDDHHmmss')}.json`;
      this.setState({ nombreJson: name, subiendoArchivo: true });
      subirArchivo(file, userAttributes.codigoEmisor).then((res) =>
        this.setState({ rutaJson: res.key, subiendoArchivo: false }),
      );
    } else {
      this.setState({
        msgModal: 'El archivo no es un documento JSON o pesa mas de 5MB',
        modalOpen: true,
      });
    }
  };

  handleGuardarRegistro = () => {
    this.setState({ disableAccept: true }, () => {
      const { rutaJson, cdTipo } = this.state;
      const { userAttributes } = this.props;
      let errores = this.validarFormulario();
      if (errores.length < 1) {
        this.setState({ subiendoArchivo: true });
        registrarCargaMasiva({
          emisorDto: { nmId: userAttributes.codigoEmisor },
          dsUrlJson: rutaJson,
          cdTipo,
        }).then((res) => {
          if (res.status !== 400)
            this.setState(
              {
                rutaJson: '',
                nombreJson: 'Selecciona un documento json...',
                subiendoArchivo: false,
                modalOpen: true,
                msgModal: `Documentos subidos correctamente`,
                onAccept: undefined,
                disableAccept: false,
              },
              () => procesarDocumentosCargaMasiva(),
            );
          else
            this.setState({
              modalOpen: true,
              msgModal: `Error subiendo documentos`,
              subiendoArchivo: false,
              onAccept: undefined,
              disableAccept: false,
            });
        });
      } else {
        this.setState({
          modalOpen: true,
          msgModal: `${errores.join('\n')}`,
          onAccept: undefined,
          disableAccept: false,
        });
      }
    });
  };

  validarFormulario = () => {
    const { rutaJson } = this.state;
    let errores = [];
    if (rutaJson.length < 1) errores.push('Es necesario subir el documento json');
    return errores;
  };

  openModalEnvio = () => {
    const { cdTipo } = this.state;
    const { label } = TIPO_CARGAS.find((tipoCarga) => cdTipo === tipoCarga.value);
    this.setState({
      msgModal: `Esta seguro que desea enviar el tipo de carga ${label} `,
      modalOpen: true,
      onAccept: this.handleGuardarRegistro,
    });
  };

  render() {
    const { msgModal, modalOpen, nombreJson, subiendoArchivo, cdTipo, onAccept, disableAccept } =
      this.state;
    return (
      <div className='formContainer'>
        <form className='form-signin containerfields' autoComplete='off'>
          <Radio
            name='cdTipo'
            value={cdTipo}
            label='Tipo de carga masiva'
            options={TIPO_CARGAS}
            onChange={this.handleChange}
          />
          <div className='custom-file' style={{ margin: '15px 0px' }}>
            <input
              className='custom-file-input'
              type='file'
              name='documentos'
              onChange={this.handleChange}
            />
            <label className='custom-file-label'>{nombreJson}</label>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={this.openModalEnvio}
              disabled={subiendoArchivo}
              fullWidth
            >
              Cargar Documentos
            </Button>
          </div>
          <small>
            Descargue
            <a href='https://s3.us-east-2.amazonaws.com/portalfactura.com/public/Documentos/ImplementacionWebServiceCargaMasivav1-1.pdf'>
              {' '}
              AQUÍ{' '}
            </a>
            la documentacion
          </small>
        </form>
        <Dialog open={modalOpen}>
          <DialogTitle>Portalfactura</DialogTitle>
          <DialogContent>{msgModal}</DialogContent>
          <DialogActions>
            {onAccept && (
              <Button disabled={disableAccept} onClick={onAccept}>
                Aceptar
              </Button>
            )}
            <Button onClick={() => this.setState({ modalOpen: false, onAccept: undefined })}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state, _) {
  return { userAttributes: state.factura.userAttributes };
}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CargaMasiva);
