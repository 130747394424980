import { subirArchivoEmisor } from 'network/Api/Emisor';

export const subirArchivo = async (file, id) => {
  const result = await subirArchivoEmisor({ id, file });
  return result.data;
};

export const extenciones = {
  'image/png': '.png',
  'image/jpeg': '.jpeg',
  'image/jpg': '.jpg',
};
