export function convertirALetras(numero, codigoMoneda) {
  let letras = milmillon(parseInt(numero));
  let centavos = numero.toString().includes('.')
    ? milmillon(parseFloat(numero).toFixed(2).toString().split('.')[1])
    : 'CERO';
  let moneda = getTextoMoneda(codigoMoneda);
  return `${letras} ${moneda} CON ${centavos} CENTAVOS`;
}

function getTextoMoneda(codigoMoneda) {
  switch (codigoMoneda) {
    case 'USD':
      return 'DOLARES';
    case 'EUR':
      return 'EUROS';
    default:
      return 'PESOS';
  }
}

function unidad(numero) {
  switch (numero) {
    case 1:
      return 'UNO';
    case 2:
      return 'DOS';
    case 3:
      return 'TRES';
    case 4:
      return 'CUATRO';
    case 5:
      return 'CINCO';
    case 6:
      return 'SEIS';
    case 7:
      return 'SIETE';
    case 8:
      return 'OCHO';
    case 9:
      return 'NUEVE';
    default:
      return 'CERO';
  }
}

function decena(numero) {
  if (numero >= 90 && numero <= 99) return getDecena(numero, 90, 'NOVENTA ');
  if (numero >= 80 && numero <= 89) return getDecena(numero, 80, 'OCHENTA ');
  if (numero >= 70 && numero <= 79) return getDecena(numero, 70, 'SETENTA ');
  if (numero >= 60 && numero <= 69) return getDecena(numero, 60, 'SESENTA ');
  if (numero >= 50 && numero <= 59) return getDecena(numero, 50, 'CINCUENTA ');
  if (numero >= 40 && numero <= 49) return getDecena(numero, 40, 'CUARENTA ');
  if (numero >= 30 && numero <= 39) return getDecena(numero, 30, 'TREINTA ');
  if (numero >= 20 && numero <= 29) return getDecenaVeinte(numero, 20);
  if (numero >= 10 && numero <= 19) {
    switch (numero) {
      case 10:
        return 'DIEZ';
      case 11:
        return 'ONCE';
      case 12:
        return 'DOCE';
      case 13:
        return 'TRECE';
      case 14:
        return 'CATORCE';
      case 15:
        return 'QUINCE';
      case 16:
        return 'DIECISEIS';
      case 17:
        return 'DIECISIETE';
      case 18:
        return 'DIECIOCHO';
      case 19:
        return 'DIECINUEVE';
      default:
        return '';
    }
  }
  if (numero < 10) return unidad(numero);
}

function getDecenaVeinte(numero, decena) {
  if (numero === 20) return 'VEINTE';
  else return `VEINTI ${unidad(numero - decena)}`;
}

function getDecena(numero, decena, nombre) {
  let numLet = nombre;
  if (numero > decena) numLet += `Y ${unidad(numero - decena)}`;
  return numLet;
}

function centena(numero) {
  if (numero >= 900 && numero <= 999) return getCentena(numero, 900, 'NOVECIENTOS ');
  if (numero >= 800 && numero <= 899) return getCentena(numero, 800, 'OCHOCIENTOS ');
  if (numero >= 700 && numero <= 799) return getCentena(numero, 700, 'SETECIENTOS ');
  if (numero >= 600 && numero <= 699) return getCentena(numero, 600, 'SEISCIENTOS ');
  if (numero >= 500 && numero <= 599) return getCentena(numero, 500, 'QUINIENTOS ');
  if (numero >= 400 && numero <= 499) return getCentena(numero, 400, 'CUATROCIENTOS ');
  if (numero >= 300 && numero <= 399) return getCentena(numero, 300, 'TRESCIENTOS ');
  if (numero >= 200 && numero <= 299) return getCentena(numero, 200, 'DOSCIENTOS ');
  if (numero >= 100 && numero <= 199) {
    if (numero === 100) return 'CIEN';
    else return getCentena(numero, 100, 'CIENTO ');
  }
  if (numero < 100) return decena(numero);
}

function getCentena(numero, centena, nombre) {
  let numLet = nombre;
  if (numero > centena) numLet += `${decena(numero - centena)}`;
  return numLet;
}

function miles(numero) {
  if (numero === 1000) return 'MIL ';
  if (numero >= 1000 && numero <= 1999) return `MIL ${centena(numero % 1000)}`;
  if (numero >= 2000 && numero <= 10000)
    return `${unidad(Math.floor(numero / 1000))} MIL ${centena(numero % 1000)}`;
  if (numero < 1000) return centena(numero);
}

function decMiles(numero) {
  if (numero === 10000) return 'DIEZ MIL';
  if (numero > 10000 && numero < 20000)
    return `${decena(Math.floor(numero / 1000))} MIL ${centena(numero % 1000)}`;
  if (numero >= 20000 && numero <= 100000)
    return `${decena(Math.floor(numero / 1000))} MIL ${miles(numero % 1000)}`;
  if (numero < 10000) return miles(numero);
}

function cienmiles(numero) {
  if (numero === 100000) return 'CIEN MIL';
  if (numero >= 100000 && numero < 1000000)
    return `${centena(Math.floor(numero / 1000))} MIL ${centena(numero % 1000)} `;
  if (numero < 100000) return decMiles(numero);
}

function millon(numero) {
  if (numero === 1000000) return 'UN MILLON ';
  if (numero >= 1000000 && numero < 2000000) return `UN MILLON ${cienmiles(numero % 1000000)}`;
  if (numero >= 2000000 && numero < 10000000)
    return `${unidad(Math.floor(numero / 1000000))} MILLONES ${cienmiles(numero % 1000000)}`;
  if (numero < 1000000) return cienmiles(numero);
}

function decmillon(numero) {
  if (numero === 10000000) return 'DIEZ MILLONES';
  if (numero > 10000000 && numero < 20000000)
    return `${decena(Math.floor(numero / 1000000))} MILLONES ${cienmiles(numero % 1000000)}`;
  if (numero >= 20000000 && numero < 100000000)
    return `${decena(Math.floor(numero / 1000000))} MILLONES ${millon(numero % 1000000)}`;
  if (numero < 10000000) return millon(numero);
}

function cienmillon(numero) {
  if (numero === 100000000) return 'CIEN MILLONES';
  if (numero >= 100000000 && numero < 1000000000)
    return `${centena(Math.floor(numero / 1000000))} MILLONES ${millon(numero % 1000000)}`;
  if (numero < 100000000) return decmillon(numero);
}

function milmillon(numero) {
  if (numero === 1000000000) return 'MIL MILLONES ';
  if (numero >= 1000000000 && numero < 2000000000) return `MIL ${cienmillon(numero % 1000000000)}`;
  if (numero >= 2000000000 && numero < 10000000000)
    return `${unidad(Math.floor(numero / 1000000000))} MIL ${cienmillon(numero % 1000000000)}`;
  if (numero < 1000000000) return cienmillon(numero);
}
