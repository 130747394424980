import { serviceRoutes, get, post } from "./index";

export const consultarNotificacionesFechas = async ({ feInicio, feFin }) =>
  await get(
    serviceRoutes.consultarNotificacionesEntreFechas,
    `?inicio=${feInicio}&fin=${feFin}`
  );

export const guardarNotificacion = async (body) =>
  await post(serviceRoutes.guardarNotificacion, body);
