import * as React from 'react';
import Async from 'react-select/async';
import { consultarAdquirentesPorEmisorYNombre } from 'network/Api/Adquirente';
import { consultarVendedores } from 'network/Api/Vendedor';
import Loader from 'components/Loaders/Loader';
import Typography from 'components/atoms/Typography';

const SelectAdquirente = ({ emisor, label, isVendedor, ...rest }) => {
  const [defaultOptions, setDefault] = React.useState([]);

  React.useEffect(() => {
    if (emisor) filtro().then((res) => setDefault(res));
  }, [emisor]);

  const filtro = async (filtro) =>
    !isVendedor
      ? consultarAdquirentesPorEmisorYNombre({
          idEmisor: emisor,
          filtro,
        }).then((adquirentes) =>
          adquirentes.data.map((adquirente) => ({
            value: adquirente.nmId,
            label: `${adquirente.cdTipoIdentificacion}: ${adquirente.dsIdentificacion}-${adquirente.dsDigitoVerif} ${
              adquirente.dsNombre
            } ${adquirente.dsSegundoNombre || ''} ${adquirente.dsPrimerApellido || ''} ${adquirente.dsSegundoApellido || ''}`,
          })),
        )
      : consultarVendedores({
          idEmisor: emisor,
          filtro,
        }).then(({ data }) =>
          data.map((vendedor) => ({
            value: vendedor.nmId,
            label: `${vendedor.cdTipoIdentificacion}: ${vendedor.dsIdentificacion}-${vendedor.dsDigitoVerif} ${
              vendedor.dsNombre
            } ${vendedor.dsSegundoNombre || ''} ${vendedor.dsPrimerApellido || ''} ${vendedor.dsSegundoApellido || ''}`,
          })),
        );

  const loadOptions = (inputValue, callback) => filtro(inputValue).then((res) => callback(res));

  return emisor ? (
    <div>
      {label && <Typography variant='labelInput'>{label}</Typography>}
      <Async defaultOptions={defaultOptions} loadOptions={loadOptions} {...rest} />
    </div>
  ) : (
    <Loader />
  );
};

export default SelectAdquirente;
