//ADQUIRENTE
export function SetOpenModalAddAdquirente(item) {
    return {
        type: 'SET_OPEN_MODAL_ADD_ADQUIRENTE',
        payload: {
            item
        }
    }
}

export function SetOpenModalUpdateAdquirente(item) {
    return {
        type: 'SET_OPEN_MODAL_UPDATE_ADQUIRENTE',
        payload: {
            item
        }
    }
}

export function SetAdquirentes(item) {
    return {
        type: 'SET_ADQUIRENTES',
        payload: {
            item
        }
    }
}

export function SetAdquirente(item) {
    return {
        type: 'SET_ADQUIRENTE',
        payload: {
            item
        }
    }
}


//PRODUCTO
export function SetOpenModalAddProducto(item) {
    return {
        type: 'SET_OPEN_MODAL_ADD_PRODUCTO',
        payload: {
            item
        }
    }
}

export function SetOpenModalUpdateProducto(item) {
    return {
        type: 'SET_OPEN_MODAL_UPDATE_PRODUCTO',
        payload: {
            item
        }
    }
}

export function SetProductos(item) {
    return {
        type: 'SET_PRODUCTOS',
        payload: {
            item
        }
    }
}

export function SetProducto(item) {
    return {
        type: 'SET_PRODUCTO',
        payload: {
            item
        }
    }
}
export function SetEmisor(item) {
    return {
        type: 'SET_EMISOR',
        payload: {
            item
        }
    }
}
