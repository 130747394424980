import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import Typography from 'components/atoms/Typography';
import { LabelTitle, InfoList, InfoLabel, SeccionInfo } from './styles';

function InformacionDocumento({ emisor, documento }) {
  const ciudad = useMemo(
    () => `${emisor.ciudadEmisor.dsNombre} - ${emisor.ciudadEmisor.departamento.dsNombre}`,
    [emisor],
  );

  const nombreAdquirente = useMemo(
    () =>
      `${documento.nombresAdquiriente || ''} ${documento.segundoNombre || ''} ${
        documento.primerApellido || ''
      } ${documento.segundoApellido || ''}`,
    [documento],
  );

  const identificacion = useMemo(
    () => documento.tipoIdentificacionAdquiriente || 'Identificacion',
    [documento],
  );

  return (
    <Row>
      <Col span={12}>
        <SeccionInfo>
          <LabelTitle type='Text'>Información Emisor</LabelTitle>
          <InfoList>
            <InfoLabel>
              <Typography type='Text'>Emisor: </Typography>
              <Typography type='Text' strong>
                {emisor.fullName}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Nit: </Typography>
              <Typography type='Text' strong>
                {emisor.identificacionEmisor}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Direccion: </Typography>
              <Typography type='Text' strong>
                {emisor.direccion}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Teléfono: </Typography>
              <Typography type='Text' strong>
                {emisor.telefono}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Ciudad: </Typography>
              <Typography type='Text' strong>
                {ciudad}
              </Typography>
            </InfoLabel>
          </InfoList>
        </SeccionInfo>
        <SeccionInfo style={{ marginTop: '20px' }}>
          <LabelTitle type='Text'>Información Cliente</LabelTitle>
          <InfoList>
            <InfoLabel>
              <Typography type='Text'>Cliente: </Typography>
              <Typography type='Text' strong>
                {nombreAdquirente}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>{identificacion}: </Typography>
              <Typography type='Text' strong>
                {documento.identificacionAdquiriente}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Dirección: </Typography>
              <Typography type='Text' strong>
                {documento.direccionAdquiriente}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Teléfono: </Typography>
              <Typography type='Text' strong>
                {documento.telefonoAdquiriente}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Email: </Typography>
              <Typography type='Text' strong>
                {documento.emailAdquiriente}
              </Typography>
            </InfoLabel>
          </InfoList>
        </SeccionInfo>
      </Col>
      <Col span={12}>
        <SeccionInfo>
          <InfoList>
            <InfoLabel>
              <Typography type='Text'>Fecha Emisión: </Typography>
              <Typography type='Text' strong>
                {documento.fechaEmision}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Fecha Vencimiento: </Typography>
              <Typography type='Text' strong>
                {documento.fechaVencimiento}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Moneda: </Typography>
              <Typography type='Text' strong>
                {documento.moneda}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Forma de Pago: </Typography>
              <Typography type='Text' strong>
                {documento.pago.formaPago}
              </Typography>
            </InfoLabel>
            <InfoLabel>
              <Typography type='Text'>Medio de Pago: </Typography>
              <Typography type='Text' strong>
                {documento.pago.medioPago}
              </Typography>
            </InfoLabel>
          </InfoList>
        </SeccionInfo>
      </Col>
    </Row>
  );
}

export default InformacionDocumento;
