import { serviceRoutes, postFormData, get, put } from "./index";

export const guardarSolicitud = async (body) => await postFormData(serviceRoutes.guardarSolicitud, body);

export const consultarSolicitudesPorEmisor = async ({ codigoEmisor }) =>
  await get(serviceRoutes.consultarSolicitudesPorEmisor, `?id=${codigoEmisor}`);

export const actualizarSolicitud = async (body) => await put(serviceRoutes.actualizarSolicitud, body);

export const consultarSolicitudInformante = async ({ dsInformante }) =>
  await get(serviceRoutes.consultarSolicitudInformante, `?informante=${dsInformante}`);
