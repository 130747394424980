import React, { useCallback, useState } from 'react';
import { Divider } from 'antd';
import Input from 'components/molecules/Input';
import Radio from 'components/molecules/Radio';
import TerminosCondiciones from 'components/molecules/TerminosCondiciones';
import CheckBox from 'components/molecules/Checkbox';
import ButtonComprarServicio from 'components/organisms/ButtonComprarServicio';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
import Button from 'components/atoms/Button';
import { moneyFormat } from 'utils/TextFormattingUtils';

const PREFERENCIAS = [
  { label: 'Llamada', value: 'LLAMADA' },
  { label: 'Whatsapp', value: 'WHATSAPP' },
  { label: 'Email', value: 'EMAIL' },
];

function FormularioCompra({ emisor, condiciones, servicioId, closeModal, valorServicio }) {
  const [form, setDataForm] = useState({
    dsNombre: emisor['custom:custom:fullname'],
    dsIdentificacion: emisor.identificacionEmisor,
    dsEmail: emisor.email,
    dsCelular: emisor.phone_number.replace('+57', ''),
    dsDireccion: emisor.direccion,
    dsPreferenciaContacto: 'LLAMADA',
    comprador: { nmId: emisor.codigoEmisor },
    servicio: { nmId: servicioId.nmId },
    cdTipoIdentificacion: emisor.tipoIdentificacion,
  });
  const [errores, setErrores] = useState({});

  const setForm = (data) => setDataForm((f) => ({ ...f, ...data }));

  const handleChange = useCallback(({ target }) => {
    const { name, value } = target;
    setForm({ [name]: value });
  }, []);

  const handleChangeSelect = useCallback(({ value }, label) => {
    setForm({ [label]: value });
  }, []);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Input
        label='Nombre del cliente'
        name='dsNombre'
        value={form.dsNombre}
        error={errores.dsNombre}
        onChange={handleChange}
      />
      <SelectTipoIdentificacion
        label='Tipo Identificación'
        name='cdTipoIdentificacion'
        value={form.cdTipoIdentificacion}
        error={errores.cdTipoIdentificacion}
        onChange={(e) => handleChangeSelect(e, 'cdTipoIdentificacion')}
      />
      <Input
        label='Número de documento del cliente'
        name='dsIdentificacion'
        value={form.dsIdentificacion}
        error={errores.dsIdentificacion}
        onChange={handleChange}
      />
      <Input
        label='Email del cliente'
        name='dsEmail'
        value={form.dsEmail}
        error={errores.dsEmail}
        onChange={handleChange}
      />
      <Input
        label='Celular del cliente'
        name='dsCelular'
        value={form.dsCelular}
        error={errores.dsCelular}
        onChange={handleChange}
      />
      <Input
        label='Dirección del cliente'
        name='dsDireccion'
        value={form.dsDireccion}
        error={errores.dsDireccion}
        onChange={handleChange}
      />
      <Radio
        label='Preferencia de contacto'
        name='dsPreferenciaContacto'
        options={PREFERENCIAS}
        value={form.dsPreferenciaContacto}
        error={errores.dsPreferenciaContacto}
        onChange={handleChange}
      />
      {condiciones && (
        <>
          <Divider />
          <TerminosCondiciones texto={condiciones} />
          <CheckBox
            label='Acepto terminos y condiciones'
            onChange={({ target }) => setForm({ terminos: target.checked })}
            checked={form.terminos}
            error={errores.terminos}
          />
        </>
      )}
      <Divider />
      <TerminosCondiciones
        titulo='Autorización de Descuento y Divulgación de Información'
        texto={`Al aceptar estos términos y condiciones, usted autoriza a PortalFactura a descontar de su saldo la cantidad de ${moneyFormat(valorServicio)} (correspondiente al valor del servicio), ingresada en nuestra base de datos según la promoción vigente. Asimismo, usted consiente que PortalFactura divulgue su información de contacto a nuestros aliados para la prestación del servicio.`}
      />
      <CheckBox
        label='Autorización de Descuento y Divulgación de Información'
        onChange={({ target }) => setForm({ autorizacion: target.checked })}
        checked={form.autorizacion}
        error={errores.autorizacion}
      />
      <div style={{ display: 'flex', justifyContent: 'space-around', margin: '20px 0px' }}>
        <ButtonComprarServicio
          formulario={form}
          setErrores={setErrores}
          closeModal={closeModal}
          condiciones={condiciones}
        />
        <Button variant='outlined' onClick={closeModal}>
          Atras
        </Button>
      </div>
    </form>
  );
}

export default FormularioCompra;
