import factura from './factura';
import maestros from './maestros';
import utils from './Utils';
//import drawer from './drawer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    factura,
    maestros,
    utils
    //drawer,
})

export default rootReducer;