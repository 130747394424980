import * as React from 'react';
import Help from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';

const Tooltip = ({ text }) =>
  text ? (
    <span data-toggle='tooltip' title={text}>
      <Help />
    </span>
  ) : (
    <></>
  );

Tooltip.propTypes = {
  text: PropTypes.string,
};

export default Tooltip;
