/* eslint-disable */
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import LogoPortal from "../../static/images/logo_portal_factura.png";
import LabelTitulos from "../layout/labelTitulos";
import "../landingPage/css/creative.css";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import {
  SetSpinnerModal,
  SetConfirmacionModalState,
} from "../../reducers/actions/facturaActions";
import SpinnerModal from "../modals/spinnerModal";
import { numberFormat } from "../../utils/funcionesUtiles";
import ConfirmacionModal from "../modals/confirmacionModal";
import { consultarPersonaPorIdentificacion } from "network/Api/Persona";

function ccyFormatTotales(num) {
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return numberFormat(value);
}

const styles = (theme) => ({
  containerLogos: {
    maxHeight: "inherit",
    flex: 1,
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    maxHeight: "3em",
    maxWidth: "100%",
    margin: 0,
    padding: 0,
  },
  rootLogo: {
    display: "inline",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "inherit",
    maxWidth: "100%",
  },
  logo: {
    maxHeight: "inherit",
    maxWidth: "100%",
  },
  titulo: {
    fontFamily: "Aclonica",
    fontSize: "1.6rem",
  },
  root: {
    flexGrow: 2,
    maxWidth: "100%",
  },
  fontFamily: {
    whiteSpace: "nowrap",
    textAlign: "left",
  },
  show: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
});

let modal = {};
class ResultadoRecarga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idSolicitud: "",
      redirect: false,
      noDocumento: "",
      nombres: "",
      valor: 0,
      estadoTransaccion: "",
    };
  }

  componentDidMount() {
    this.actualizarRecarga();
  }

  async actualizarRecarga() {
    const url = new URLSearchParams(window.location.search);
    const idSolicitud = url.get("idSolicitud");
    if (idSolicitud) {
      const { data, status } = {};
      if (status === 200 && data.estado === "APROBADA") {
        this.setState({
          estadoTransaccion: data.estado,
          noDocumento: data.idUsuario,
          valor: data.valor,
        });
        const resultadoPersona = await consultarPersonaPorIdentificacion({
          dsIdentifiacion: data.idUsuario,
        });
        if (resultadoPersona.status === 200)
          this.setState({ nombres: resultadoPersona.data.fullName });
        else
          this.openModal(
            `Error: Hemos recibido su pago pero no fue posible modificar el estado de su pago. Por favor, contáctese con soporte de portal factura.`
          );
      } else
        this.openModal(
          `El estado de la recarga es: ${
            result.data.estado
          }. Por favor contáctese con soporte.`
        );
    } else
      this.openModal(
        "Ocurrió un error consultando el estado de la recarga. Por favor recargue esta página, si el error persiste, contáctese con soporte."
      );
  }

  openModal(text) {
    const { setConfirmacionModalState } = this.props;
    setConfirmacionModalState({
      open: true,
      text,
      onClick: () => {
        setConfirmacionModalState({ open: false });
        this.setState({ redirect: true });
      },
      cancelarVisible: true,
    });
  }

  render() {
    const {
      redirect,
      estadoTransaccion,
      noDocumento,
      nombres,
      valor,
    } = this.state;
    if (redirect) return <Redirect to={{ pathname: "/" }} />;
    const { classes } = this.props;
    return (
      <Fragment>
        <form>
          <nav
            className="navbar navbar-expand-lg navbar-light fixed-top py-0 mb-0"
            id="mainNav"
          >
            <div className="container">
              <Link
                className={{ textDecoration: "none" }}
                to={{
                  pathname: "/",
                }}
              >
                <a className="navbar-brand " href="#">
                  <div className={classes.containerLogos}>
                    <div className={classes.rootLogo}>
                      <img className={classes.logo} src={LogoPortal} />
                    </div>
                  </div>
                  <LabelTitulos texto={"PortalFactura"} />
                </a>
              </Link>
              <ul className="navbar-nav ml-auto my-2 my-lg-0">
                <li className="nav-item">
                  <Link
                    className={{ textDecoration: "none" }}
                    to={{
                      pathname: "/login",
                    }}
                  >
                    <div className={classes.loginBottom}>
                      <LabelTitulos texto="Login" />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <header className="masthead-nuevo-emisor mt-0">
            <div className="container h-100 mt-0">
              <a className={classes.titulo}>
                <LabelTitulos texto={estadoTransaccion} />
              </a>
              <p style={{ textAlign: "center" }} className="text-muted mb-3">
                Señor (a) {nombres.toUpperCase()} el estado de su recarga es:{" "}
                {estadoTransaccion.toUpperCase()}{" "}
                {estadoTransaccion == "APROBADA"
                  ? " por favor comuníquese con su asesor."
                  : ""}
              </p>
              <div className="form-row col-md-12 d-flex justify-content-between">
                <div className="form-group col-md-12 col-xl-4 mb-3">
                  <div className="form-label-group">
                    <input
                      maxLength="200"
                      autoComplete="off"
                      type="text"
                      id="noDocumento"
                      className="form-control form-control-sm"
                      value={noDocumento}
                      placeholder="No. documento"
                      onChange={this.handleChange}
                      disabled
                    />
                    <label className={classes.fontFamily} htmlFor="noDocumento">
                      No. documento
                    </label>
                  </div>
                </div>

                <div className="form-group col-md-12 col-xl-4 mb-0">
                  <div className="form-label-group">
                    <input
                      maxLength="200"
                      autoComplete="off"
                      type="text"
                      id="nombres"
                      className="form-control form-control-sm"
                      value={nombres}
                      placeholder="Nombre"
                      onChange={this.handleChange}
                      disabled
                    />
                    <label className={classes.fontFamily} htmlFor="nombres">
                      Nombre
                    </label>
                  </div>
                </div>

                <div className="form-group col-md-12 col-xl-4 mb-0">
                  <div className="form-label-group">
                    <input
                      maxLength="200"
                      autoComplete="off"
                      type="text"
                      id="valor"
                      className="form-control form-control-sm"
                      value={`$ ${ccyFormatTotales(valor)}`}
                      placeholder="Valor recarga"
                      onChange={this.handleChange}
                      disabled
                    />
                    <label className={classes.fontFamily} htmlFor="valor">
                      Valor recarga
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </form>
        <SpinnerModal />
        <ConfirmacionModal />
      </Fragment>
    );
  }
}

ResultadoRecarga.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {};
}

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  setConfirmacionModalState: (item) =>
    dispatch(SetConfirmacionModalState(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResultadoRecarga));
