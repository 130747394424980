import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import { now, DATE_FORMAT } from 'utils/DateUtils';

const fechaActual = now().format(DATE_FORMAT);

function FiltroEntreFechas({
  updateFechas,
  buttonTexto = 'Actualizar Fechas',
  defaultInicio = fechaActual,
  fechaMinima,
}) {
  const [state, setState] = useState({ feFin: fechaActual, feInicio: defaultInicio });

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const { feInicio, feFin } = state;
      updateFechas(feInicio, feFin);
    },
    [state],
  );

  const handleChange = useCallback(({ target }) => {
    const { name, value } = target;
    setState((s) => ({ ...s, [name]: value }));
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Grid container xl={12} spacing={8} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Grid item xl={1}>
          <Input
            label='Fecha Inicio'
            type='date'
            name='feInicio'
            value={state.feInicio}
            onChange={handleChange}
            max={fechaActual}
            min={fechaMinima}
          />
        </Grid>
        <Grid item xl={1}>
          <Input
            label='Fecha Fin'
            type='date'
            name='feFin'
            value={state.feFin}
            onChange={handleChange}
            max={fechaActual}
            min={state.feInicio}
          />
        </Grid>
        <Grid item xl={1}>
          <Button type='submit'>{buttonTexto}</Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default FiltroEntreFechas;
