import * as React from "react";
import Modal from "components/modal/ModalConfirmacion";
import Input from "components/Inputs/Input";
import { now } from "utils/DateUtils";
import Button from 'components/atoms/Button';
import { generarCierreCajaPos } from "network/Api/DocumentoPos";
import { consultarPuntoDeVentaPos } from "network/Api/PuntoVentaPos";
import { fileDownload } from "utils/BlobUtils";
import CurrencyInput from 'react-currency-input-field'

const FormularioCierre = ({ userAttributes, open, handleClose }) => {
  const [state, setData] = React.useState({});

  React.useEffect(() => {
    if (userAttributes.codigoEmisor)
      consultarPuntoDeVentaPos().then(({ data }) =>
        setState({
          direccion: data.dsDireccion,
          puntoVenta: data.nmId,
          emisor: { nmId: userAttributes.codigoEmisor },
          fechaCierre: now().format("YYYY-MM-DD"),
        })
      );
  }, [userAttributes]);

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  const handleChange = ({ target }) => {
    setState({ [target.name]: target.value });
  }


  const handleChangeNumericValues = (value, name) => {
    const parseValue = value ? parseInt(value) : null;
    setState({ [name]: parseValue });
  }

  const clearState = () =>
    setData({
      fechaCierre: now().format("YYYY-MM-DD"),
      emisor: { nmId: userAttributes.codigoEmisor },
      direccion: state.direccion,
      puntoVenta: state.puntoVenta,
    });

  const handleCierre = (event) => {
    event.preventDefault();
    const { nombre, efectivo } = state;
    if (nombre && efectivo) {
      generarCierreCajaPos(state).then((blob) => {
        fileDownload({ blob, name: `Cierre-del-dia` });
        clearState();
        handleClose();
      });
    }
  };

  return (
    <Modal
      open={open}
      fullWidth={true}
      title="Cierre de Caja"
      content={
        <form onSubmit={handleCierre}>
          <Input label="Nombre"
            name="nombre"
            value={state.nombre}
            onChange={handleChange}

          />
          <label>Total egreso</label>
          <CurrencyInput
            label="Total egreso"
            className="input-money"
            name="egreso"
            value={state.egreso}
            decimalsLimit={2}
            onValueChange={(e) => handleChangeNumericValues(e, "egreso")}
            decimalSeparator=","
            groupSeparator="."
            prefix='$'
          />
          <label>Total efectivo</label>
          <CurrencyInput
            label="Total efectivo"
            className="input-money"
            name="efectivo"
            value={state.efectivo} 
            decimalsLimit={2}
            onValueChange={(e) => handleChangeNumericValues(e, "efectivo")}
            decimalSeparator=","
            groupSeparator="."
            prefix='$'
          />          
          <Input label="Direccion" name="direccion" value={state.direccion} onChange={handleChange} readOnly />
          <Input label="Fecha de cierre" name="fechaCierre" value={state.fechaCierre} onChange={handleChange} readOnly type="date" />
          <Button type="submit" style={{ margin: "5px 0px" }}>
            Consultar
          </Button>
          <Button
            style={{ margin: "5px 5px" }}
            onClick={() => {
              clearState();
              handleClose();
            }}
          >
            Cerrar
          </Button>
        </form>
      }
    />
  );
};

export default FormularioCierre;
