import React, { useEffect, useState } from 'react';
import ContratoModal from 'components/landingPage/contratoModal';
import { calcularDigitoVerificacion } from 'utils/funcionesUtiles';
import { Modal } from 'antd';
import { codigosPais } from 'static/enums/CodigosPais';
import { consultarCiudades } from 'network/Api/Ciudad';
import LogoPortal from 'static/images/logoNoBackground.png';
import { registrarNuevoSocio } from 'network/Api/Emisor';
import { Link } from 'react-router-dom';
import { consultarEmailExistente } from 'network/Api/Persona';
import { TIPOS_DATOS } from 'utils/ValidarDatos';
import FormularioPersonaRegistroEmisor from 'components/templates/FormularioPersonaRegistroEmisor';
import FormularioContactoRegistroEmisor from 'components/templates/FormularioContactoRegistroEmisor';
import Button from 'components/atoms/Button';
import '../NuevoEmisor/global.css';
import 'components/landingPage/css/creative.css';

const steps = {
  personal: { name: 'personal', nextStep: 'contacto', validar: true },
  contacto: { name: 'contacto', previousStep: 'personal', nextStep: 'finish', validar: true },
};

const NuevoSocio = () => {
  const [currentStep, setCurrentStep] = useState(steps.personal);
  const [errores, setErrores] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState({});
  const [isModalOpenError, setIsModalOpenError] = useState({
    status: false,
    message: '',
  });
  const [ciudades, setCiudades] = useState([]);
  const [data, setData] = useState({
    modalProps: {
      open: false,
      content: '',
      onAccept: () => {},
      cdTipoIdentificacion: '',
      disabledName: false,
    },
    aliado: new URLSearchParams(window.location.search).get('fromRedirect'),
    tipoCompra: new URLSearchParams(window.location.search).get('tipo'),
    cdCodigoPais: codigosPais.find((pais) => pais.label === 'Colombia'),
    emailValido: false,
  });

  const changeStep = async (esSiguiente) => {
    const { nextStep, previousStep, name, validar } = currentStep;
    const stepChanged = esSiguiente ? nextStep : previousStep;

    if (esSiguiente && validar) {
      let errores = validarFormulario(name);
      if (Object.keys(errores).length > 0) return;
    }
    if (stepChanged !== 'finish') {
      setCurrentStep(steps[stepChanged]);
    } else {
      setState({ openContrato: true });
    }
  };

  const validarFormulario = (name) => {
    if (name === 'personal') return validarPersona();
    if (name === 'contacto') return validarContacto();
  };

  const validarPersona = () => {
    let errores = {};
    const {
      dsIdentificacion = '',
      dsNombre = '',
      cdTipoIdentificacion,
      dsPrimerApellido,
      dsCelular = '',
      cdCodigoPais,
    } = data;
    setErrores({});
    if (!dsIdentificacion && dsIdentificacion.length < 8) errores.dsIdentificacion = true;
    if (!dsNombre && dsNombre < 3) errores.dsNombre = true;
    if (cdTipoIdentificacion !== 'NIT' && !dsPrimerApellido) errores.dsPrimerApellido = true;
    if (!dsCelular && dsCelular.length < 10) errores.dsCelular = true;
    if (!cdCodigoPais) errores.cdCodigoPais = true;
    if (!cdTipoIdentificacion) errores.cdTipoIdentificacion = true;
    setErrores(errores);
    return errores;
  };

  const validarContacto = () => {
    let errores = {};
    const { dsNombreContacto = '', dsEmail = '', dsDireccion = '', nmIdCiudad } = data;
    setErrores({});
    if (!dsNombreContacto && dsNombreContacto.length < 3) errores.dsNombreContacto = true;
    if (!dsEmail && !TIPOS_DATOS.email.regExp.test(dsEmail)) errores.dsEmail = true;
    if (!dsDireccion && dsDireccion.length < 5) errores.dsDireccion = true;
    if (!nmIdCiudad) errores.nmIdCiudad = true;
    setErrores(errores);
    return errores;
  };

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  useEffect(() => {
    const onCancel = () => setStateModal((m) => ({ ...m, open: false }));
    setStateModal({
      open: true,
      onOk: onCancel,
      onCancel: onCancel,
      content: (
        <p style={{ textAlign: 'justify' }}>
          ¡Estimado usuario! <br /> <br /> Una vez que te registres en nuestra página web, recibirás
          un correo electrónico con tu usuario y contraseña para ingresar a nuestra plataforma y ver
          todos tus movimientos de saldo.
        </p>
      ),
    });
    consultarCiudades().then((a) => {
      const ciudades = a.data.map((c) => {
        return {
          label: `${c.dsNombre} - ${c.departamento.dsNombre} - ${c.departamento.pais.dsNombre}`,
          value: c.nmId,
        };
      });
      setCiudades(ciudades);
    });
  }, []);

  const handleEnvio = () => {
    setState({ loadingRegistro: true });
    registrarEmisor();
    closeModalProps();
  };

  const registrarEmisor = async () => {
    setData((d) => ({ ...d, disableButton: true }));
    const result = await registrarNuevoSocio(getBodyRegistroPersona());
    setData((d) => ({ ...d, disableButton: false }));
    setStateModal((m) => ({
      ...m,
      open: true,
      content: result.data ? 'Registro con éxito' : 'Error con el registro',
      onOk: () => (window.location.href = '/login'),
      onCancel: () => (window.location.href = '/'),
    }));
  };

  const getBodyRegistroPersona = () => {
    const {
      cdTipoIdentificacion,
      dsIdentificacion,
      dsNombre,
      dsPrimerApellido,
      dsDireccion,
      dsCelular,
      dsEmail,
      dsNombreContacto,
      aliado,
      nmIdCiudad,
      captcha,
    } = data;
    return {
      json: JSON.stringify({
        captcha,
        persona: {
          cdTipoIdentificacion,
          dsIdentificacion: `${dsIdentificacion}-${calcularDigitoVerificacion(dsIdentificacion)}`,
          dsNombre,
          dsPrimerApellido,
          dsDireccion,
          ciudad: { nmId: nmIdCiudad },
          dsCelular,
          dsEmail,
          dsNombreContacto,
          dsTelefonoContacto: dsCelular,
          dsAliado: aliado,
          dsDigitoVerificacion: calcularDigitoVerificacion(data.dsIdentificacion),
        },
      }),
    };
  };

  const closeModalProps = () =>
    setState({
      modalProps: { ...data.modalProps, open: false },
      loadingRegistro: false,
    });

  const setAceptarChange = async () => {
    handleEnvio();
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cierraModal = () => setStateModal((m) => ({ ...m, open: false }));

  const validarEmail = async (event) => {
    setState({ emailValido: false });
    const dsEmail = event.target.value;

    if (!TIPOS_DATOS.email.regExp.test(dsEmail)) {
      setState({ emailValido: false });
      setStateModal({
        open: true,
        onOk: cierraModal,
        onCancel: cierraModal,
        content: (
          <p style={{ textAlign: 'justify' }}>
            ¡Estimado usuario! <br /> <br /> El correo {dsEmail} no es valido.
          </p>
        ),
      });
    }
    const { data } = await consultarEmailExistente({ dsEmail });
    if (data) {
      setStateModal({
        open: true,
        onOk: cierraModal,
        onCancel: cierraModal,
        content: (
          <p style={{ textAlign: 'justify' }}>
            ¡Estimado usuario! <br /> <br /> El correo ya se encuentra registrado, ingrese otro
            diferente.
          </p>
        ),
      });
    } else {
      setState({ emailValido: true });
    }
  };

  const validarLongitudMinima = async (e, minimo) => {
    if (e.target.value) {
      const contador = e.target.value.length;
      if (minimo > contador) {
        setStateModal({
          open: true,
          onOk: cierraModal,
          onCancel: cierraModal,
          content: (
            <p style={{ textAlign: 'justify', marginTop: '15px' }}>
              El campo {e.target.placeholder}, no cumple con la cantidad mínima ({minimo}) de
              caracteres permitidos.
            </p>
          ),
        });
      }
    }
  };

  return (
    <main className='nd-main'>
      <header className='nd-header'>
        <div className='nd-container'>
          <div className='nd-header-top'>
            <div>
              <img src={LogoPortal} className='nd-body-brand' alt='' />
            </div>
            <div className='nd-header-information'>Regístrate</div>
            <Link className='link-login' to={{ pathname: '/login' }}>
              <button class='nd-header-button'>
                <strong>Iniciar sesión</strong>
              </button>
            </Link>
          </div>
          <div className='nd-header-bottom'>
            <button className={currentStep.name === 'personal' ? 'nd-button-act' : 'nd-button'}>
              Personal
            </button>
            <button className={currentStep.name === 'contacto' ? 'nd-button-act' : 'nd-button'}>
              Contacto
            </button>
          </div>
        </div>
      </header>
      <div className='nd-content'>
        <div className='nd-container'>
          {currentStep.name === 'personal' && (
            <FormularioPersonaRegistroEmisor
              validarLongitudMinima={validarLongitudMinima}
              setData={setData}
              errores={errores}
              defaultPersona={data}
            />
          )}
          {currentStep.name === 'contacto' && (
            <FormularioContactoRegistroEmisor
              setData={setData}
              validarLongitudMinima={validarLongitudMinima}
              validarEmail={validarEmail}
              ciudades={ciudades}
              errores={errores}
              defaultContacto={data}
            />
          )}
          <div className='nd-footer'>
            <Button onClick={() => changeStep(true)}>
              {currentStep.nextStep !== 'finish' ? 'Continuar' : 'Registrarse'}
            </Button>
            {currentStep.previousStep && (
              <Button onClick={() => changeStep(false)}>Anterior</Button>
            )}
          </div>
        </div>
      </div>
      <ContratoModal
        openAccion={data.openContrato || false}
        handleCloseModal={() => setState({ openContrato: false })}
        setAceptarChange={setAceptarChange}
      />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button onChange={handleOk} className='nd-button-act' onClick={handleOk}>
            Aceptar
          </button>,
        ]}
      >
        <p className='nd-title-modal'>Confirmación!</p>
        <p className='nd-text-body-modal'>
          En unos minutos recibirás en tu correo electrónico el usuario y la contraseña para acceder
          a la plataforma de Portalfactura
        </p>
      </Modal>
      <Modal
        open={stateModal.open}
        onOk={stateModal.onOk}
        onCancel={stateModal.onCancel}
        footer={[
          <button onChange={stateModal.onOk} className='nd-button-act' onClick={stateModal.onOk}>
            Aceptar
          </button>,
        ]}
      >
        <p className='nd-text-body-modal'>{stateModal.content}</p>
      </Modal>
      <Modal
        title='Error'
        open={isModalOpenError.status}
        okButtonProps={{
          style: { backgroundColor: '#673D80', color: 'white' },
        }}
        onOk={() => {
          setIsModalOpenError({ ...isModalOpenError, ...{ status: false } });
        }}
        onCancel={() => {
          setIsModalOpenError({ ...isModalOpenError, ...{ status: false } });
        }}
      >
        <p>{isModalOpenError.message}</p>
      </Modal>
    </main>
  );
};

export default NuevoSocio;
