import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import {
  SetModalFacturasReferencia,
  SetFacturasReferencia,
} from '../../../reducers/actions/facturaActions';
import LabelTitulos from '../../layout/labelTitulos';
import { handleKeyPressTextoNumeros, handleKeyPressNumeros } from '../../../utils/funcionesUtiles';
import Select from 'react-select';
import FacturaReferenciaList from '../Components/FacturaReferenciaList';
import Input from 'components/Inputs/Input';

const styles = {
  buttonAdd: {
    fontWeight: 'bold',
    backgroundColor: '#128BCC',
    color: 'white',
  },
};

const customStyles = {
  input: (styles) => {
    return {
      ...styles,
      height: '2.25rem',
    };
  },
};

class FacturasReferenciaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prefijo: '',
      numero: '',
      conceptoNotaDebito: 'OTROS',
      conceptoNotaCredito: 'OTROS',
      descripcion: '',
      camposObligatoriosPrefijo: false,
      camposObligatoriosNumero: false,
      tipo: '',
      id: '',
      fechaInicioPeriodo: '',
      fechaFinPeriodo: '',
    };
    this.handleChangeTipoConceptoDebito = this.handleChangeTipoConceptoDebito.bind(this);
    this.handleChangeTipoConceptoCredito = this.handleChangeTipoConceptoCredito.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickAgregar = this.handleClickAgregar.bind(this);
  }

  componentDidMount() {
    const { open } = this.props;
    if (open.tipoDocumento === 'NOTA_DEBITO') {
      this.setState({ tipo: open.tipoDocumento, conceptoNotaCredito: '' });
    } else {
      this.setState({ tipo: open.tipoDocumento, conceptoNotaDebito: '' });
    }
  }

  handleChangeTipoConceptoDebito = (value) => {
    let codigo = value.value;
    this.setState({ conceptoNotaDebito: codigo }, () => {});
  };

  handleChangeTipoConceptoCredito = (value) => {
    let codigo = value.value;
    this.setState({ conceptoNotaCredito: codigo }, () => {});
  };

  handleKeyPressTextoNumeros = (event) => {
    if (!handleKeyPressTextoNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressNumeros = (event) => {
    if (!handleKeyPressNumeros(event)) {
      event.preventDefault();
    }
  };

  handleClose = () => {
    if (this.props.listaFacturasReferencia.length > 0) {
      this.props.setModalFacturasReferencia(false);
    } else {
      window.location.href = '/Home';
    }
  };

  handleChange = (event) => {
    let value = event.target.value.toUpperCase();
    this.setState({ [event.target.id]: value }, () => {});
  };

  handleClickAgregar = () => {
    if (!this.validarCamposObligatorios()) {
      return;
    }
    this.agregarId().then(() => {
      let data = [
        ...this.props.listaFacturasReferencia,
        {
          ...this.state,
        },
      ];
      this.props.setFacturasReferencia(data);
      this.setState({
        prefijo: '',
        numero: '',
        descripcion: '',
        id: '',
        fechaInicioPeriodo: '',
        fechaFinPeriodo: '',
        camposObligatoriosNumero: false,
        fechasObligatorias: false,
      });
    });
  };

  agregarId = async () => {
    const { listaFacturasReferencia } = this.props;
    let id = 0;
    let tamanoArray = await listaFacturasReferencia.length;
    if (tamanoArray > 0) {
      id = (await listaFacturasReferencia[tamanoArray - 1].id) + 1;
    }
    this.setState({ id: id });
  };

  validarCamposObligatorios = () => {
    let sw = 0;
    const { numero, fechaInicioPeriodo, fechaFinPeriodo } = this.state;
    this.setState({
      camposObligatoriosNumero: numero.length === 0,
      fechasObligatorias: !fechaInicioPeriodo || !fechaFinPeriodo,
    });
    if (!numero && (!fechaInicioPeriodo || !fechaFinPeriodo)) {
      sw += 1;
    }
    return !sw > 0;
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  render() {
    const { open, tiposConceptoDebito, tiposConceptoCredito, listaFacturasReferencia } = this.props;
    const {
      prefijo,
      numero,
      descripcion,
      camposObligatoriosPrefijo,
      camposObligatoriosNumero,
      fechaInicioPeriodo,
      fechaFinPeriodo,
      fechasObligatorias,
    } = this.state;

    const tiposConceptoDebitoSelect = tiposConceptoDebito.map((item) => ({
      value: item.codigo,
      label: item.nombre,
    }));

    const tiposConceptoCreditoSelect = tiposConceptoCredito.map((item) => ({
      value: item.codigo,
      label: item.nombre,
    }));

    return (
      <div>
        <Dialog
          open={open.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          scroll='paper'
          fullScreen={isWidthUp('md', this.props.width) ? false : true}
          fullWidth={isWidthUp('xl', this.props.width) ? false : true}
          maxWidth='md'
        >
          <DialogTitle id='form-dialog-title'>
            <LabelTitulos texto={`Documento referencia ${open.tipoDocumento}`} />
          </DialogTitle>
          <DialogContent>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className='form-row col-md-12'>
                <div className='form-group col-md-6'>
                  <TextField
                    autoFocus
                    margin='dense'
                    id='prefijo'
                    label='Prefijo *'
                    onKeyPress={this.handleKeyPressTextoNumeros}
                    fullWidth
                    value={prefijo}
                    onChange={this.handleChange}
                    autoComplete='off'
                    error={camposObligatoriosPrefijo}
                  />
                </div>
                <div className='form-group col-md-6'>
                  <TextField
                    margin='dense'
                    id='numero'
                    label='# Documento *'
                    value={numero}
                    onKeyPress={this.handleKeyPressNumeros}
                    fullWidth
                    onChange={this.handleChange}
                    autoComplete='off'
                    error={camposObligatoriosNumero}
                  />
                </div>
              </div>
              {open.tipoDocumento === 'NOTA_DEBITO' && (
                <div className='form-row col-md-12 d-flex justify-content-between'>
                  <div className='form-group col-md-6'>
                    <small className='text-muted'>Concepto nota débito: </small>
                  </div>
                  <div className='form-group col-md-6'>
                    <Select
                      defaultValue={tiposConceptoDebitoSelect[3]}
                      onChange={this.handleChangeTipoConceptoDebito}
                      options={tiposConceptoDebitoSelect}
                      placeholder='Concepto nota débito *'
                      styles={customStyles}
                    />
                  </div>
                </div>
              )}
              {open.tipoDocumento === 'NOTA_CREDITO' && (
                <div className='form-row col-md-12 d-flex justify-content-between'>
                  <div className='form-group col-md-6'>
                    <small className='text-muted'>Concepto nota crédito: </small>
                  </div>
                  <div className='form-group col-md-6'>
                    <Select
                      onChange={this.handleChangeTipoConceptoCredito}
                      defaultValue={tiposConceptoCreditoSelect[4]}
                      options={tiposConceptoCreditoSelect}
                      placeholder='Concepto crédito *'
                      styles={customStyles}
                    />
                  </div>
                </div>
              )}
              <div className='form-row col-md-12'>
                <div className='form-group col-md-12'>
                  <textarea
                    style={{ height: '3.46rem' }}
                    maxLength='100'
                    rows='2'
                    autoComplete='off'
                    type='text'
                    id='descripcion'
                    className='form-control form-control-sm'
                    value={descripcion}
                    placeholder='Descripción'
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className='form-row col-md-12'>
                <div className='form-group col-md-6'>
                  <Input
                    label='Fecha inicio periodo'
                    type='date'
                    name='fechaInicioPeriodo'
                    value={fechaInicioPeriodo}
                    onChange={this.onChange}
                    error={fechasObligatorias ? 'Obligatorio' : ''}
                  />
                </div>
                <div className='form-group col-md-6'>
                  <Input
                    label='Fecha fin periodo'
                    type='date'
                    name='fechaFinPeriodo'
                    value={fechaFinPeriodo}
                    onChange={this.onChange}
                    error={fechasObligatorias ? 'Obligatorio' : ''}
                  />
                </div>
              </div>
              <DialogActions>
                <Button
                  style={styles.buttonAdd}
                  onClick={this.handleClickAgregar}
                  name='txtAgregar'
                  color='primary'
                  type='submit'
                >
                  Agregar
                </Button>
                <Button
                  style={styles.buttonAdd}
                  onClick={this.handleClose}
                  name='Cerrar'
                  color='primary'
                >
                  Cerrar
                </Button>
              </DialogActions>
            </form>
            {listaFacturasReferencia.length > 0 && <FacturaReferenciaList />}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setModalFacturasReferencia: (item) => dispatch(SetModalFacturasReferencia(item)),
  setFacturasReferencia: (item) => dispatch(SetFacturasReferencia(item)),
});

const mapStateToProps = (state, props) => ({
  open: state.factura.openModalFacturasReferencia,
  listaFacturasReferencia: state.factura.listaFacturasReferencia,
  tiposConceptoDebito: state.factura.tiposConceptoDebito,
  tiposConceptoCredito: state.factura.tiposConceptoCredito,
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(FacturasReferenciaModal));
