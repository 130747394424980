import React, { Component } from "react";
import moment from "moment";
import TablaGenerica from "components/Tablas/TablaGenerica";
import { getUserData } from "network/Amplify";
import { consultarEstadoPines } from "network/Api/Transaccion";
import Select from "react-select";

const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DATE_FORMAT = "YYYY-MM-DD";

export default class EstadosPin extends Component {
  state = {
    pines: [],
    dsAliado: undefined,
    cdEstado: null,
    feInicio: moment()
      .subtract(1, "month")
      .format(DATE_FORMAT),
    feFin: moment().format(DATE_FORMAT),
    loading: true,
  };

  componentDidMount() {
    getUserData().then((res) => {
      let dsAliado = res.attributes["custom:custom:userid"];
      this.setState({ dsAliado }, () => this.consultarPines());
    });
  }

  consultarPines = () => {
    const { dsAliado, feInicio, feFin, cdEstado } = this.state;
    this.setState({ loading: true });
    consultarEstadoPines({
      aliado: dsAliado,
      inicio: feInicio,
      fin: feFin,
      estado: cdEstado ? cdEstado : "",
    }).then((res) => {
      let pines = res.data.map((pin) => ({
        ...pin,
        cdEstado: this.converterEstado(pin.cdEstado),
        snPinVigente: this.converterSnVigente(pin.snPinVigente),
        feCompra: moment(pin.feCompra).format(DATETIME_FORMAT),
      }));
      this.setState({ pines, loading: false });
    });
  };

  converterEstado = (estado) => {
    switch (estado) {
      case "P":
        return "Pendiente";
      case "A":
        return "Aprobado";
      default:
        return "Estado desconocido";
    }
  };

  converterSnVigente = (sn) => {
    switch (sn) {
      case "S":
        return "Vigente";
      case "N":
        return "No Vigente";
      default:
        return "Vigencia desconocida";
    }
  };

  handleChange = (event) => {
    let label = event.target.id;
    let value = event.target.value;
    switch (label) {
      default:
        this.setState({ [label]: value });
        break;
    }
  };

  handleChangeSelect = (event, lable) => {
    this.setState({ [lable]: event.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.consultarPines();
  };

  render() {
    const { pines, feInicio, feFin, loading } = this.state;
    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <div style={{ margin: "0px 5px" }}>
            <label htmlFor="feInicio">Fecha Inicio</label>
            <input
              id="feInicio"
              className="form-control"
              type="date"
              value={feInicio}
              max={moment().format(DATE_FORMAT)}
              onChange={this.handleChange}
            />
          </div>
          <div style={{ margin: "0px 5px" }}>
            <label htmlFor="feFin">Fecha Fin</label>
            <input
              id="feFin"
              className="form-control"
              type="date"
              value={feFin}
              max={moment().format(DATE_FORMAT)}
              onChange={this.handleChange}
            />
          </div>
          <div style={{ margin: "0px 5px", width: "150px" }}>
            <label htmlFor="cdEstado">Estado</label>
            <Select
              id="cdEstado"
              defaultValue={[{ label: "Cualquiera", value: null }]}
              options={[
                { label: "Cualquiera", value: null },
                { label: "Pendiente", value: "P" },
                { label: "Aprobado", value: "A" },
              ]}
              onChange={(event) => this.handleChangeSelect(event, "cdEstado")}
            />
          </div>
          <div style={{ margin: "0px 5px" }}>
            <button className="btn btn-primary" type="submit">
              Consultar
            </button>
          </div>
        </form>
        <TablaGenerica
          headers={["PIN", "Plan", "Estado", "Vigencia", "Fecha Generacion"]}
          keys={[
            "dsPin",
            "plan.dsDescripcion",
            "cdEstado",
            "snPinVigente",
            "feCompra",
          ]}
          rowsPerPage={10}
          orderBy={"feCompra"}
          detalles={pines}
          loading={loading}
        />
      </div>
    );
  }
}
