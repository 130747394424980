import * as React from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import Button from 'components/atoms/Button';
import { SetSpinnerModal } from "reducers/actions/facturaActions";
import { iniciarSiguienteTurno, finalizarTruno } from "network/Api/Turno";
import Modal from "components/modal/ModalConfirmacion";


const Asesor = ({ userAttributes }) => {
  const [state, setS] = React.useState({
    atendiendoTurno: false,
    turnoActual: {},
    openModal: false,
  });

  const setState = (d) => setS((s) => ({ ...s, ...d }));

  const siguienteTurno = () => {
    const { codigoEmisor } = userAttributes;
    iniciarSiguienteTurno({
      emisor: { nmId: codigoEmisor },
      dsAsesor: userAttributes["custom:custom:username"],
    }).then(({ data }) => {
      if (data) setState({ turnoActual: data, atendiendoTurno: true });
      else if (data === null)
        openModal("Por ahora no se encuentran mas turnos pendientes");
    });
  };

  const finalizarAtencion = (atencionRealizada) => {
    const { turnoActual } = state;
    finalizarTruno({
      nmId: turnoActual.nmId,
      cdEstado: atencionRealizada ? "FINALIZADO" : "NO_ATENDIDO",
    }).then(({ data }) => {
      if (data)
        setState({ atendiendoTurno: false, turnoActual: {}, loading: false });
    });
  };

  const openModal = (contentModal) =>
    setState({ openModal: true, contentModal });

  return (
    <div>
      {!state.atendiendoTurno ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={siguienteTurno} 
          style = {{  backgroundColor: "#EC7404", color:"#ffff",  }}>Siguiente Turno</Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h1">
            {state.turnoActual.dsNombre} {state.turnoActual.dsApellido}
          </Typography>
          <div style={{ margin: "10px" }}>
            <Button
              onClick={() => finalizarAtencion(true)} 
              style={{ margin: "5px" , backgroundColor: "#EC7404", color:"#ffff",   }}
            >
              Finalizar Atencion
            </Button>
            <Button
              onClick={() => finalizarAtencion(false)}
              style={{ margin: "5px" ,   backgroundColor: "#EC7404", color:"#ffff",  }}
            >
              Atencion no realizada
            </Button>
          </div>
        </div>
      )}
      <Modal
        open={state.openModal}
        content={state.contentModal}
        onAccept={state.onAcceptModal}
        onCancel={() => setState({ openModal: false })}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Asesor);
