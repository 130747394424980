import { DocumentoPos } from './SubMenus/DocumentoPos';
import { ConsultasPos } from './SubMenus/Consultas';

export const EmisorPos = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
  DocumentoPos,
  ConsultasPos,
];
