import * as React from "react";
import Async from 'react-select/async';
import { consultarProductosPorEmisorYDescripcion, consultarProductosActivosPorEmisorYDescripcion } from "network/Api/Producto";
import Loader from "components/Loaders/Loader";

const SelectProductos = ({ emisor, label, isExportacion, soloActivos, ...rest }) => {
  const [defaultOptions, setDefault] = React.useState([]);

  React.useEffect(() => {
    if (emisor) filtro().then((res) => setDefault(res));
  }, [emisor]);

  const filtro = async (filtro) =>
    (soloActivos
      ? consultarProductosActivosPorEmisorYDescripcion({
          idEmisor: emisor,
          isFacturaExportacion: isExportacion,
          filtro,
        })
      : consultarProductosPorEmisorYDescripcion({
          idEmisor: emisor,
          isFacturaExportacion: isExportacion,
          filtro,
        })
    ).then(({ data }) =>
      data.map((producto) => ({
        value: producto.nmId,
        label: `${producto.dsCodigo} - ${producto.dsDescripcion}`,
      }))
    );

  const loadOptions = (inputValue, callback) => filtro(inputValue).then((res) => callback(res));

  return emisor ? (
    <div>
      {label && <label>{label}</label>}
      <Async defaultOptions={defaultOptions} loadOptions={loadOptions} {...rest} />
    </div>
  ) : (
    <Loader />
  );
};

export default SelectProductos;
