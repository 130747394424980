import * as React from "react";
import tiposPrecioReferencia from "static/enums/tiposPrecioReferencia";
import Select from "components/Inputs/Select";
import Input from "components/Inputs/Input";

const Obsequio = ({ setDetalle, esObsequio, precioReferenciaDefault, errores }) => {
  const [obsequio, setDataObsequio] = React.useState({ precioReferencia: precioReferenciaDefault });

  React.useEffect(() => {
    if (esObsequio === true) {
      setDetalle({ obsequio: [obsequio] });
    } else if (esObsequio === false) {
      setDetalle({ obsequio: null });
    }
  }, [obsequio, esObsequio]);

  const setObsequio = (data) => setDataObsequio((s) => ({ ...s, ...data }));

  const getOptions = () =>
    tiposPrecioReferencia.map((pr) => ({
      label: pr.nombre,
      value: pr.codigo,
    }));

  const getValue = (value) => getOptions().filter((pr) => pr.value === obsequio.tipoPrecioReferencia);

  const getError = (tag) => (errores.find((error) => error.tag === tag) || {}).err;

  return (
    <>
      <div className="form-group col-md-4 col-xl-4">
        <Select
          placeholder="Tipo precio referencia"
          label="Tipo precio referencia *"
          options={getOptions()}
          value={getValue(obsequio.tipoPrecioReferencia)}
          onChange={({ value }) => setObsequio({ tipoPrecioReferencia: value })}
          isDisabled={!esObsequio}
          error={getError("tipoPrecioReferencia")}
        />
      </div>
      <div className="form-group col-md-4 col-xl-4">
        <Input
          label="Precio referencia *"
          name="precioReferencia"
          onChange={({ target }) => setObsequio({ precioReferencia: target.value })}
          disabled={!esObsequio}
          value={obsequio.precioReferencia}
          error={getError("precioReferencia")}
        />
      </div>
    </>
  );
};

export default Obsequio;
