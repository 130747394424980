import React from 'react';
import AtomCheckbox from 'components/atoms/Checkbox';
import Tooltip from 'components/atoms/Tooltip';

const style = {
  display: 'flex',
  alignItems: 'center',
};

const Checkbox = ({ size, label, color = 'primary', tooltip, error, className, ...rest }) => {
  return (
    <div className={className}>
      <div style={style}>
        <AtomCheckbox color={color} {...rest}>
          {label}
        </AtomCheckbox>
        <Tooltip text={tooltip} />
      </div>
      {error && <small style={{ color: 'red' }}>{error}</small>}
    </div>
  );
};

export default Checkbox;
