import React, { useState } from 'react';
import Typography from 'components/atoms/Typography';

function TerminosCondiciones({ titulo = 'Terminos y Condiciones', texto, className }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={className}>
      <Typography type='Text' strong>
        {titulo}
      </Typography>
      <Typography
        type='Paragraph'
        ellipsis={{
          rows: 5,
          expanded,
          onExpand: (_, info) => setExpanded(info.expanded),
          expandable: 'collapsible',
          symbol: <span style={{ color: 'blue' }}>{expanded ? 'Ver menos' : 'Ver mas'}</span>,
        }}
        style={{ textAlign: 'justify' }}
      >
        {texto}
      </Typography>
    </div>
  );
}

export default TerminosCondiciones;
