import React from 'react';
import Button from 'components/atoms/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { SetConfirmacionModalState } from 'reducers/actions/facturaActions';
import LabelTitulos from 'components/layout/labelTitulos';
import FormularioAdquirente from './FormularioAdquirente';
import { actualizarAdquirente } from 'network/Api/Adquirente';

const ACTUALIZADO_EXITOSO = 'El cliente se actualizo exitosamente!';

const ModalActualizarAdquirente = ({
  adquirenteUpdate,
  open,
  onClose,
  setConfirmacionModalState,
  onConsulta,
}) => {
  const updateAdquirente = async (adquirente) => {
    const { status } = await actualizarAdquirente(adquirente);
    if (status === 200) {
      openModalExitoso({ text: ACTUALIZADO_EXITOSO, onClick: () => onConsulta('') });
    } else
      openModal(
        'Ocurrió un error guardando el adquirente, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte',
      );
  };

  const openModal = (text = '') =>
    setConfirmacionModalState({
      open: true,
      text,
      onClick: () => setConfirmacionModalState({ open: false }),
    });

  const openModalExitoso = ({ text = '', onClick }) =>
    setConfirmacionModalState({
      open: true,
      text,
      onClick,
    });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='form-dialog-title'
        scroll='paper'
        fullWidth={true}
        maxWidth='xl'
      >
        <DialogTitle id='form-dialog-title'>
          <LabelTitulos texto='Actualizar cliente' />
        </DialogTitle>
        <DialogContent>
          <FormularioAdquirente
            updateAdquirente={adquirenteUpdate}
            onRegistro={updateAdquirente}
            openModal={openModal}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} name='Cerrar'>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalActualizarAdquirente);
