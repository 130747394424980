import { serviceRoutes, get, post, put } from "./index";

export async function consultarPorEmisor({ id }) {
  return await get(serviceRoutes.consultarProductosPorEmisor, `?id=${id}`);
}

export async function consultarProductosPorEmisorYDescripcion({ idEmisor, filtro = "", isFacturaExportacion = false }) {
  const encodedFiltro = filtro
    .split("")
    .map((c) => encodeURIComponent(c))
    .join("");
  return await get(
    serviceRoutes.consultarProductosPorEmisorYDescripcion,
    `?id=${idEmisor}&descripcion=${encodedFiltro}&exportacion=${isFacturaExportacion}`
  );
}

export const registrarProducto = async (body) => await post(serviceRoutes.registrarProducto, body);

export const actualizarProducto = async (body) => await put(serviceRoutes.actualizarProducto, body);

export const actualizarEstadoProducto = async (body) => await put(serviceRoutes.actualizarEstadoProducto, body);

export const consultarProductoPorEmisorYCodigo = async ({ codigo, emisor }) =>
  await get(serviceRoutes.consultarProductoPorEmisorYCodigo, `?codigo=${codigo}&emisor=${emisor}`);

export const consultarProductoPorId = async ({ id }) => await get(serviceRoutes.consultarProductoPorId, `?id=${id}`);

export const crearActualizarProducto = async (body) => await post(serviceRoutes.crearActualizarProducto, body);

export async function consultarProductosActivosPorEmisorYDescripcion({ idEmisor, filtro = "", isFacturaExportacion = false }) {
  const encodedFiltro = filtro
    .split("")
    .map((c) => encodeURIComponent(c))
    .join("");
  return await get(
    serviceRoutes.consultarProductosActivosPorEmisorYDescripcion,
    `?id=${idEmisor}&descripcion=${encodedFiltro}&exportacion=${isFacturaExportacion}`
  );
}

export const consultarCategoriasProductosPorEmisor = async (emisor) =>
  await get(serviceRoutes.consultarCategoriasProductosPorEmisor, `?emisor=${emisor}`);
