const Ciudades = [
    {
        "nmId": 291,
        "dsNombre": "El Encanto",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "263",
    },
    {
        "nmId": 435,
        "dsNombre": "La Chorrera",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "405",
    },
    {
        "nmId": 450,
        "dsNombre": "La Pedrera",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "407",
    },
    {
        "nmId": 475,
        "dsNombre": "Leticia",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "001",
    },
    {
        "nmId": 527,
        "dsNombre": "Miriti Parana",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "460",
    },
    {
        "nmId": 670,
        "dsNombre": "Puerto Nariño",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "540",
    },
    {
        "nmId": 912,
        "dsNombre": "Tarapaca",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "798",
    },
    {
        "nmId": 1159,
        "dsNombre": "LA VICTORIA",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "430",
    },
    {
        "nmId": 1160,
        "dsNombre": "PUERTO ALEGRÍA",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "530",
    },
    {
        "nmId": 1161,
        "dsNombre": "PUERTO ARICA",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "536",
    },
    {
        "nmId": 1162,
        "dsNombre": "PUERTO SANTANDER",
        "departamento": {
            "cdIso": "CO-AMA",
            "dsNombre": "Amazonas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "91",
        },
        "cdDane": "669",
    },
    {
        "nmId": 1,
        "dsNombre": "Abejorral",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "002",
    },
    {
        "nmId": 3,
        "dsNombre": "Abriaqui",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "004",
    },
    {
        "nmId": 21,
        "dsNombre": "Alejandria",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "021",
    },
    {
        "nmId": 29,
        "dsNombre": "Amaga",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "030",
    },
    {
        "nmId": 30,
        "dsNombre": "Amalfi",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "031",
    },
    {
        "nmId": 36,
        "dsNombre": "Andes",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "034",
    },
    {
        "nmId": 37,
        "dsNombre": "Angelopolis",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "036",
    },
    {
        "nmId": 38,
        "dsNombre": "Angostura",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "038",
    },
    {
        "nmId": 40,
        "dsNombre": "Anori",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "040",
    },
    {
        "nmId": 44,
        "dsNombre": "Anza",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "044",
    },
    {
        "nmId": 46,
        "dsNombre": "Apartado",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "045",
    },
    {
        "nmId": 59,
        "dsNombre": "Arboletes",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "051",
    },
    {
        "nmId": 61,
        "dsNombre": "Argelia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "055",
    },
    {
        "nmId": 66,
        "dsNombre": "Armenia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "059",
    },
    {
        "nmId": 80,
        "dsNombre": "Barbosa",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "079",
    },
    {
        "nmId": 92,
        "dsNombre": "Bello",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "088",
    },
    {
        "nmId": 93,
        "dsNombre": "Belmira",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "086",
    },
    {
        "nmId": 96,
        "dsNombre": "Betania",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "091",
    },
    {
        "nmId": 98,
        "dsNombre": "Betulia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "093",
    },
    {
        "nmId": 126,
        "dsNombre": "Buritica",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "113",
    },
    {
        "nmId": 128,
        "dsNombre": "Cañasgordas",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "138",
    },
    {
        "nmId": 132,
        "dsNombre": "Caceres",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "120",
    },
    {
        "nmId": 137,
        "dsNombre": "Caicedo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "125",
    },
    {
        "nmId": 145,
        "dsNombre": "Caldas",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "129",
    },
    {
        "nmId": 152,
        "dsNombre": "Campamento",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "134",
    },
    {
        "nmId": 162,
        "dsNombre": "Caracoli",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "142",
    },
    {
        "nmId": 163,
        "dsNombre": "Caramanta",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "145",
    },
    {
        "nmId": 165,
        "dsNombre": "Carepa",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "147",
    },
    {
        "nmId": 168,
        "dsNombre": "El Carmen De Viboral",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "148",
    },
    {
        "nmId": 169,
        "dsNombre": "Carolina",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "150",
    },
    {
        "nmId": 177,
        "dsNombre": "Caucasia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "154",
    },
    {
        "nmId": 192,
        "dsNombre": "Chigorodo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "172",
    },
    {
        "nmId": 217,
        "dsNombre": "Cisneros",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "190",
    },
    {
        "nmId": 218,
        "dsNombre": "Ciudad Bolivar",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "101",
    },
    {
        "nmId": 219,
        "dsNombre": "Cocorna",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "197",
    },
    {
        "nmId": 228,
        "dsNombre": "Concepcion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "206",
    },
    {
        "nmId": 229,
        "dsNombre": "Concordia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "209",
    },
    {
        "nmId": 236,
        "dsNombre": "Copacabana",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "212",
    },
    {
        "nmId": 266,
        "dsNombre": "Dabeiba",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "234",
    },
    {
        "nmId": 269,
        "dsNombre": "Don Matias",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "237",
    },
    {
        "nmId": 274,
        "dsNombre": "Ebejico",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "240",
    },
    {
        "nmId": 276,
        "dsNombre": "El Bagre",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "250",
    },
    {
        "nmId": 312,
        "dsNombre": "Entrerrios",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "264",
    },
    {
        "nmId": 313,
        "dsNombre": "Envigado",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "266",
    },
    {
        "nmId": 330,
        "dsNombre": "Fredonia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "282",
    },
    {
        "nmId": 332,
        "dsNombre": "Frontino",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "284",
    },
    {
        "nmId": 356,
        "dsNombre": "Giraldo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "306",
    },
    {
        "nmId": 358,
        "dsNombre": "Girardota",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "308",
    },
    {
        "nmId": 360,
        "dsNombre": "Gomez Plata",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "310",
    },
    {
        "nmId": 363,
        "dsNombre": "Granada",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "313",
    },
    {
        "nmId": 371,
        "dsNombre": "Guadalupe",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "315",
    },
    {
        "nmId": 382,
        "dsNombre": "Guarne",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "318",
    },
    {
        "nmId": 384,
        "dsNombre": "Guatape",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "321",
    },
    {
        "nmId": 399,
        "dsNombre": "Heliconia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "347",
    },
    {
        "nmId": 402,
        "dsNombre": "Hispania",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "353",
    },
    {
        "nmId": 415,
        "dsNombre": "Itagui",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "360",
    },
    {
        "nmId": 416,
        "dsNombre": "Ituango",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "361",
    },
    {
        "nmId": 420,
        "dsNombre": "Jardin",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "364",
    },
    {
        "nmId": 422,
        "dsNombre": "Jerico",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "368",
    },
    {
        "nmId": 433,
        "dsNombre": "La Ceja",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "376",
    },
    {
        "nmId": 439,
        "dsNombre": "La Estrella",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "380",
    },
    {
        "nmId": 451,
        "dsNombre": "La Pintada",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "390",
    },
    {
        "nmId": 461,
        "dsNombre": "La Union",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "400",
    },
    {
        "nmId": 477,
        "dsNombre": "Liborina",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "411",
    },
    {
        "nmId": 491,
        "dsNombre": "Maceo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "425",
    },
    {
        "nmId": 510,
        "dsNombre": "Marinilla",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "440",
    },
    {
        "nmId": 519,
        "dsNombre": "Medellin",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 538,
        "dsNombre": "Montebello",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "467",
    },
    {
        "nmId": 551,
        "dsNombre": "Murindo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "475",
    },
    {
        "nmId": 552,
        "dsNombre": "Mutata",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "480",
    },
    {
        "nmId": 555,
        "dsNombre": "Nariño",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "483",
    },
    {
        "nmId": 559,
        "dsNombre": "Nechi",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "495",
    },
    {
        "nmId": 560,
        "dsNombre": "Necocli",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "490",
    },
    {
        "nmId": 577,
        "dsNombre": "Olaya",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "501",
    },
    {
        "nmId": 623,
        "dsNombre": "Peque",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "543",
    },
    {
        "nmId": 651,
        "dsNombre": "Pueblorrico",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "576",
    },
    {
        "nmId": 656,
        "dsNombre": "Puerto Berrio",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "579",
    },
    {
        "nmId": 669,
        "dsNombre": "Puerto Nare",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "585",
    },
    {
        "nmId": 677,
        "dsNombre": "Puerto Triunfo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "591",
    },
    {
        "nmId": 696,
        "dsNombre": "Remedios",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "604",
    },
    {
        "nmId": 701,
        "dsNombre": "Retiro",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "607",
    },
    {
        "nmId": 708,
        "dsNombre": "Rionegro",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "615",
    },
    {
        "nmId": 727,
        "dsNombre": "Sabanalarga",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "628",
    },
    {
        "nmId": 729,
        "dsNombre": "Sabaneta",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "631",
    },
    {
        "nmId": 740,
        "dsNombre": "Salgar",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "642",
    },
    {
        "nmId": 749,
        "dsNombre": "San Andres De Cuerquia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "647",
    },
    {
        "nmId": 760,
        "dsNombre": "San Carlos",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "649",
    },
    {
        "nmId": 775,
        "dsNombre": "San Jeronimo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "656",
    },
    {
        "nmId": 780,
        "dsNombre": "San Jose De La Montaña",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "658",
    },
    {
        "nmId": 789,
        "dsNombre": "San Juan De Uraba",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "659",
    },
    {
        "nmId": 792,
        "dsNombre": "San Luis",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "660",
    },
    {
        "nmId": 807,
        "dsNombre": "San Pedro",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "664",
    },
    {
        "nmId": 808,
        "dsNombre": "San Pedro De Uraba",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "665",
    },
    {
        "nmId": 810,
        "dsNombre": "San Rafael",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "667",
    },
    {
        "nmId": 811,
        "dsNombre": "San Roque",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "670",
    },
    {
        "nmId": 814,
        "dsNombre": "San Vicente",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "674",
    },
    {
        "nmId": 821,
        "dsNombre": "Santa Barbara",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "679",
    },
    {
        "nmId": 824,
        "dsNombre": "Santa Fe De Antioquia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "042",
    },
    {
        "nmId": 833,
        "dsNombre": "Santa Rosa De Osos",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "686",
    },
    {
        "nmId": 843,
        "dsNombre": "Santo Domingo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "690",
    },
    {
        "nmId": 845,
        "dsNombre": "Santuario",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "697",
    },
    {
        "nmId": 853,
        "dsNombre": "Segovia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "736",
    },
    {
        "nmId": 879,
        "dsNombre": "Sonson",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "756",
    },
    {
        "nmId": 880,
        "dsNombre": "Sopetran",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "761",
    },
    {
        "nmId": 909,
        "dsNombre": "Tamesis",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "789",
    },
    {
        "nmId": 913,
        "dsNombre": "Taraza",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "790",
    },
    {
        "nmId": 915,
        "dsNombre": "Tarso",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "792",
    },
    {
        "nmId": 938,
        "dsNombre": "Titiribi",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "809",
    },
    {
        "nmId": 944,
        "dsNombre": "Toledo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "819",
    },
    {
        "nmId": 964,
        "dsNombre": "Turbo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "837",
    },
    {
        "nmId": 977,
        "dsNombre": "Urrao",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "847",
    },
    {
        "nmId": 982,
        "dsNombre": "Valdivia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "854",
    },
    {
        "nmId": 988,
        "dsNombre": "Valparaiso",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "856",
    },
    {
        "nmId": 990,
        "dsNombre": "Vegachi",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "858",
    },
    {
        "nmId": 993,
        "dsNombre": "Venecia",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "861",
    },
    {
        "nmId": 1025,
        "dsNombre": "Yali",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "885",
    },
    {
        "nmId": 1026,
        "dsNombre": "Yarumal",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "887",
    },
    {
        "nmId": 1028,
        "dsNombre": "Yolombo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "890",
    },
    {
        "nmId": 1029,
        "dsNombre": "Yondo",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "893",
    },
    {
        "nmId": 1035,
        "dsNombre": "Zaragoza",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "895",
    },
    {
        "nmId": 1041,
        "dsNombre": "BRICEÑO",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "107",
    },
    {
        "nmId": 1042,
        "dsNombre": "PEÑOL",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "541",
    },
    {
        "nmId": 1043,
        "dsNombre": "SAN FRANCISCO",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "652",
    },
    {
        "nmId": 1044,
        "dsNombre": "URAMITA",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "842",
    },
    {
        "nmId": 1045,
        "dsNombre": "VIGÍA DEL FUERTE",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "Antioquia",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "873",
    },
    {
        "nmId": 54,
        "dsNombre": "Arauca",
        "departamento": {
            "cdIso": "CO-ARA",
            "dsNombre": "Arauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "81",
        },
        "cdDane": "001",
    },
    {
        "nmId": 55,
        "dsNombre": "Arauquita",
        "departamento": {
            "cdIso": "CO-ARA",
            "dsNombre": "Arauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "81",
        },
        "cdDane": "065",
    },
    {
        "nmId": 250,
        "dsNombre": "Cravo Norte",
        "departamento": {
            "cdIso": "CO-ARA",
            "dsNombre": "Arauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "81",
        },
        "cdDane": "220",
    },
    {
        "nmId": 673,
        "dsNombre": "Puerto Rondon",
        "departamento": {
            "cdIso": "CO-ARA",
            "dsNombre": "Arauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "81",
        },
        "cdDane": "591",
    },
    {
        "nmId": 848,
        "dsNombre": "Saravena",
        "departamento": {
            "cdIso": "CO-ARA",
            "dsNombre": "Arauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "81",
        },
        "cdDane": "736",
    },
    {
        "nmId": 908,
        "dsNombre": "Tame",
        "departamento": {
            "cdIso": "CO-ARA",
            "dsNombre": "Arauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "81",
        },
        "cdDane": "794",
    },
    {
        "nmId": 1156,
        "dsNombre": "FORTUL",
        "departamento": {
            "cdIso": "CO-ARA",
            "dsNombre": "Arauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "81",
        },
        "cdDane": "300",
    },
    {
        "nmId": 77,
        "dsNombre": "Baranoa",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "078",
    },
    {
        "nmId": 86,
        "dsNombre": "Barranquilla",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "001",
    },
    {
        "nmId": 153,
        "dsNombre": "Campo De La Cruz",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "137",
    },
    {
        "nmId": 156,
        "dsNombre": "Candelaria",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "141",
    },
    {
        "nmId": 344,
        "dsNombre": "Galapa",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "296",
    },
    {
        "nmId": 427,
        "dsNombre": "Juan De Acosta",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "372",
    },
    {
        "nmId": 488,
        "dsNombre": "Luruaco",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "421",
    },
    {
        "nmId": 500,
        "dsNombre": "Malambo",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "433",
    },
    {
        "nmId": 502,
        "dsNombre": "Manati",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "436",
    },
    {
        "nmId": 602,
        "dsNombre": "Palmar De Varela",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "520",
    },
    {
        "nmId": 632,
        "dsNombre": "Piojo",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "549",
    },
    {
        "nmId": 641,
        "dsNombre": "Polonuevo",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "558",
    },
    {
        "nmId": 642,
        "dsNombre": "Ponedera",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "560",
    },
    {
        "nmId": 660,
        "dsNombre": "Puerto Colombia",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "573",
    },
    {
        "nmId": 698,
        "dsNombre": "Repelon",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "606",
    },
    {
        "nmId": 725,
        "dsNombre": "Sabanagrande",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "634",
    },
    {
        "nmId": 726,
        "dsNombre": "Sabanalarga",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "638",
    },
    {
        "nmId": 826,
        "dsNombre": "Santa Lucia",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "675",
    },
    {
        "nmId": 844,
        "dsNombre": "Santo Tomas",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "685",
    },
    {
        "nmId": 876,
        "dsNombre": "Soledad",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "758",
    },
    {
        "nmId": 887,
        "dsNombre": "Suan",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "770",
    },
    {
        "nmId": 956,
        "dsNombre": "Tubara",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "832",
    },
    {
        "nmId": 979,
        "dsNombre": "Usiacuri",
        "departamento": {
            "cdIso": "CO-ATL",
            "dsNombre": "Atlántico",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "08",
        },
        "cdDane": "849",
    },
    {
        "nmId": 65,
        "dsNombre": "Arjona",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "052",
    },
    {
        "nmId": 85,
        "dsNombre": "Barranco D Loba",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "074",
    },
    {
        "nmId": 143,
        "dsNombre": "Calamar",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "140",
    },
    {
        "nmId": 158,
        "dsNombre": "Cantagallo",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "160",
    },
    {
        "nmId": 170,
        "dsNombre": "Cartagena",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "001",
    },
    {
        "nmId": 239,
        "dsNombre": "Cordoba",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "212",
    },
    {
        "nmId": 283,
        "dsNombre": "El Carmen De Bolivar",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "244",
    },
    {
        "nmId": 294,
        "dsNombre": "El Guamo",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "248",
    },
    {
        "nmId": 494,
        "dsNombre": "Magangue",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "430",
    },
    {
        "nmId": 496,
        "dsNombre": "Mahates",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "433",
    },
    {
        "nmId": 508,
        "dsNombre": "Margarita",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "440",
    },
    {
        "nmId": 509,
        "dsNombre": "Maria La Baja",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "442",
    },
    {
        "nmId": 534,
        "dsNombre": "Mompos",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "468",
    },
    {
        "nmId": 543,
        "dsNombre": "Morales",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "473",
    },
    {
        "nmId": 631,
        "dsNombre": "Pinillos",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "549",
    },
    {
        "nmId": 712,
        "dsNombre": "Rioviejo",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "600",
    },
    {
        "nmId": 767,
        "dsNombre": "San Estanislao",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "647",
    },
    {
        "nmId": 770,
        "dsNombre": "San Fernando",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "650",
    },
    {
        "nmId": 774,
        "dsNombre": "San Jacinto",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "654",
    },
    {
        "nmId": 790,
        "dsNombre": "San Juan Nepomuceno",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "657",
    },
    {
        "nmId": 797,
        "dsNombre": "San Martin De Loba",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "667",
    },
    {
        "nmId": 802,
        "dsNombre": "San Pablo",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "670",
    },
    {
        "nmId": 823,
        "dsNombre": "Santa Catalina",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "673",
    },
    {
        "nmId": 831,
        "dsNombre": "Santa Rosa",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "683",
    },
    {
        "nmId": 864,
        "dsNombre": "Simiti",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "744",
    },
    {
        "nmId": 881,
        "dsNombre": "Soplaviento",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "760",
    },
    {
        "nmId": 962,
        "dsNombre": "Turbaco",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "836",
    },
    {
        "nmId": 963,
        "dsNombre": "Turbana",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "838",
    },
    {
        "nmId": 1012,
        "dsNombre": "Villanueva",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "873",
    },
    {
        "nmId": 1033,
        "dsNombre": "Zambrano",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "894",
    },
    {
        "nmId": 1046,
        "dsNombre": "ACHÍ",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "6",
    },
    {
        "nmId": 1047,
        "dsNombre": "ALTOS DEL ROSARIO",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "30",
    },
    {
        "nmId": 1048,
        "dsNombre": "ARENAL",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "42",
    },
    {
        "nmId": 1049,
        "dsNombre": "ARROYOHONDO",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "62",
    },
    {
        "nmId": 1050,
        "dsNombre": "CICUCO",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "188",
    },
    {
        "nmId": 1051,
        "dsNombre": "CLEMENCIA",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "222",
    },
    {
        "nmId": 1052,
        "dsNombre": "EL PEÑÓN",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "268",
    },
    {
        "nmId": 1053,
        "dsNombre": "HATILLO DE LOBA",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "300",
    },
    {
        "nmId": 1054,
        "dsNombre": "MONTECRISTO",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "458",
    },
    {
        "nmId": 1055,
        "dsNombre": "REGIDOR",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "580",
    },
    {
        "nmId": 1056,
        "dsNombre": "SAN CRISTÓBAL",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "620",
    },
    {
        "nmId": 1057,
        "dsNombre": "SAN JACINTO DEL CAUCA",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "655",
    },
    {
        "nmId": 1058,
        "dsNombre": "SANTA ROSA DEL SUR",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "688",
    },
    {
        "nmId": 1059,
        "dsNombre": "TALAIGUA NUEVO",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "780",
    },
    {
        "nmId": 1060,
        "dsNombre": "TIQUISIO",
        "departamento": {
            "cdIso": "CO-BOL",
            "dsNombre": "Bolívar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "13",
        },
        "cdDane": "810",
    },
    {
        "nmId": 24,
        "dsNombre": "Almeida",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "022",
    },
    {
        "nmId": 49,
        "dsNombre": "Aquitania",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "047",
    },
    {
        "nmId": 60,
        "dsNombre": "Arcabuco",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "051",
    },
    {
        "nmId": 88,
        "dsNombre": "Belen",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "087",
    },
    {
        "nmId": 95,
        "dsNombre": "Berbeo",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "090",
    },
    {
        "nmId": 97,
        "dsNombre": "Beteitiva",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "092",
    },
    {
        "nmId": 101,
        "dsNombre": "Boavita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "097",
    },
    {
        "nmId": 112,
        "dsNombre": "Boyaca",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "104",
    },
    {
        "nmId": 113,
        "dsNombre": "Briceño",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "106",
    },
    {
        "nmId": 121,
        "dsNombre": "Buenavista",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "109",
    },
    {
        "nmId": 127,
        "dsNombre": "Busbanza",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "114",
    },
    {
        "nmId": 146,
        "dsNombre": "Caldas",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "131",
    },
    {
        "nmId": 155,
        "dsNombre": "Campohermoso",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "135",
    },
    {
        "nmId": 180,
        "dsNombre": "Cerinza",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "162",
    },
    {
        "nmId": 197,
        "dsNombre": "Chinavita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "172",
    },
    {
        "nmId": 202,
        "dsNombre": "Chiquinquira",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "176",
    },
    {
        "nmId": 203,
        "dsNombre": "Chiquiza",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "232",
    },
    {
        "nmId": 205,
        "dsNombre": "Chiscas",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "180",
    },
    {
        "nmId": 206,
        "dsNombre": "Chita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "183",
    },
    {
        "nmId": 208,
        "dsNombre": "Chitaraque",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "185",
    },
    {
        "nmId": 214,
        "dsNombre": "Cienega",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "189",
    },
    {
        "nmId": 226,
        "dsNombre": "Combita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "204",
    },
    {
        "nmId": 237,
        "dsNombre": "Coper",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "212",
    },
    {
        "nmId": 244,
        "dsNombre": "Corrales",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "215",
    },
    {
        "nmId": 247,
        "dsNombre": "Covarachia",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "218",
    },
    {
        "nmId": 252,
        "dsNombre": "Cubara",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "223",
    },
    {
        "nmId": 254,
        "dsNombre": "Cucaita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "224",
    },
    {
        "nmId": 258,
        "dsNombre": "Cuitiva",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "226",
    },
    {
        "nmId": 272,
        "dsNombre": "Duitama",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "238",
    },
    {
        "nmId": 286,
        "dsNombre": "El Cocuy",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "244",
    },
    {
        "nmId": 292,
        "dsNombre": "El Espino",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "248",
    },
    {
        "nmId": 319,
        "dsNombre": "Firavitoba",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "272",
    },
    {
        "nmId": 323,
        "dsNombre": "Floresta",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "276",
    },
    {
        "nmId": 341,
        "dsNombre": "Gachantiva",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "293",
    },
    {
        "nmId": 349,
        "dsNombre": "Gameza",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "296",
    },
    {
        "nmId": 350,
        "dsNombre": "Garagoa",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "299",
    },
    {
        "nmId": 367,
        "dsNombre": "Guacamayas",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "317",
    },
    {
        "nmId": 387,
        "dsNombre": "Guateque",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "322",
    },
    {
        "nmId": 392,
        "dsNombre": "Guayata",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "325",
    },
    {
        "nmId": 394,
        "dsNombre": "Guican",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
    },
    {
        "nmId": 417,
        "dsNombre": "Iza",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "362",
    },
    {
        "nmId": 421,
        "dsNombre": "Jenesano",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "367",
    },
    {
        "nmId": 423,
        "dsNombre": "Jerico",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "368",
    },
    {
        "nmId": 432,
        "dsNombre": "La Capilla",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "380",
    },
    {
        "nmId": 462,
        "dsNombre": "La Uvita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "403",
    },
    {
        "nmId": 465,
        "dsNombre": "La Victoria",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "401",
    },
    {
        "nmId": 469,
        "dsNombre": "Labranzagrande",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "377",
    },
    {
        "nmId": 489,
        "dsNombre": "Macanal",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "425",
    },
    {
        "nmId": 511,
        "dsNombre": "Maripi",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "442",
    },
    {
        "nmId": 525,
        "dsNombre": "Miraflores",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "455",
    },
    {
        "nmId": 535,
        "dsNombre": "Mongua",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "464",
    },
    {
        "nmId": 536,
        "dsNombre": "Mongui",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "466",
    },
    {
        "nmId": 537,
        "dsNombre": "Moniquira",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "469",
    },
    {
        "nmId": 550,
        "dsNombre": "Motavita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "476",
    },
    {
        "nmId": 554,
        "dsNombre": "Muzo",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "480",
    },
    {
        "nmId": 566,
        "dsNombre": "Nobsa",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "491",
    },
    {
        "nmId": 570,
        "dsNombre": "Nuevo Colon",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "494",
    },
    {
        "nmId": 585,
        "dsNombre": "Otanche",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "507",
    },
    {
        "nmId": 588,
        "dsNombre": "Pachavita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "511",
    },
    {
        "nmId": 592,
        "dsNombre": "Paez",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "514",
    },
    {
        "nmId": 597,
        "dsNombre": "Paipa",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "516",
    },
    {
        "nmId": 598,
        "dsNombre": "Pajarito",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "518",
    },
    {
        "nmId": 610,
        "dsNombre": "Panqueba",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "522",
    },
    {
        "nmId": 615,
        "dsNombre": "Pauna",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "531",
    },
    {
        "nmId": 616,
        "dsNombre": "Paya",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "533",
    },
    {
        "nmId": 618,
        "dsNombre": "Paz De Rio",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "537",
    },
    {
        "nmId": 625,
        "dsNombre": "Pesca",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "542",
    },
    {
        "nmId": 633,
        "dsNombre": "Pisva",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "550",
    },
    {
        "nmId": 657,
        "dsNombre": "Puerto Boyaca",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "572",
    },
    {
        "nmId": 693,
        "dsNombre": "Ramiriqui",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "599",
    },
    {
        "nmId": 694,
        "dsNombre": "Raquira",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "600",
    },
    {
        "nmId": 719,
        "dsNombre": "Rondon",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "621",
    },
    {
        "nmId": 730,
        "dsNombre": "Saboya",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "632",
    },
    {
        "nmId": 732,
        "dsNombre": "Sachica",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "638",
    },
    {
        "nmId": 741,
        "dsNombre": "Samaca",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "646",
    },
    {
        "nmId": 766,
        "dsNombre": "San Eduardo",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "660",
    },
    {
        "nmId": 779,
        "dsNombre": "San Jose De Pare",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "664",
    },
    {
        "nmId": 794,
        "dsNombre": "San Luis De Gaceno",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "667",
    },
    {
        "nmId": 798,
        "dsNombre": "San Mateo",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "673",
    },
    {
        "nmId": 800,
        "dsNombre": "San Miguel Sema",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "676",
    },
    {
        "nmId": 804,
        "dsNombre": "San Pablo Borbur",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "681",
    },
    {
        "nmId": 827,
        "dsNombre": "Santa Maria",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "690",
    },
    {
        "nmId": 834,
        "dsNombre": "Santa Rosa De Viterbo",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "693",
    },
    {
        "nmId": 835,
        "dsNombre": "Santa Sofia",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "696",
    },
    {
        "nmId": 838,
        "dsNombre": "Santana",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "686",
    },
    {
        "nmId": 851,
        "dsNombre": "Sativanorte",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "720",
    },
    {
        "nmId": 852,
        "dsNombre": "Sativasur",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "723",
    },
    {
        "nmId": 856,
        "dsNombre": "Siachoque",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "740",
    },
    {
        "nmId": 870,
        "dsNombre": "Soata",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "753",
    },
    {
        "nmId": 871,
        "dsNombre": "Socha",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "757",
    },
    {
        "nmId": 873,
        "dsNombre": "Socota",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "755",
    },
    {
        "nmId": 874,
        "dsNombre": "Sogamoso",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "759",
    },
    {
        "nmId": 878,
        "dsNombre": "Somondoco",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "761",
    },
    {
        "nmId": 883,
        "dsNombre": "Soraca",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "764",
    },
    {
        "nmId": 884,
        "dsNombre": "Sotaquira",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "763",
    },
    {
        "nmId": 900,
        "dsNombre": "Susacon",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "774",
    },
    {
        "nmId": 901,
        "dsNombre": "Sutamarchan",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "776",
    },
    {
        "nmId": 903,
        "dsNombre": "Sutatenza",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "778",
    },
    {
        "nmId": 916,
        "dsNombre": "Tasco",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "790",
    },
    {
        "nmId": 923,
        "dsNombre": "Tenza",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "798",
    },
    {
        "nmId": 928,
        "dsNombre": "Tibana",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "804",
    },
    {
        "nmId": 929,
        "dsNombre": "Tibasosa",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "806",
    },
    {
        "nmId": 936,
        "dsNombre": "Tinjaca",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "808",
    },
    {
        "nmId": 937,
        "dsNombre": "Tipacoque",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "810",
    },
    {
        "nmId": 939,
        "dsNombre": "Toca",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "814",
    },
    {
        "nmId": 942,
        "dsNombre": "Togui",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "816",
    },
    {
        "nmId": 948,
        "dsNombre": "Topaga",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "820",
    },
    {
        "nmId": 952,
        "dsNombre": "Tota",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "822",
    },
    {
        "nmId": 959,
        "dsNombre": "Tunja",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "001",
    },
    {
        "nmId": 960,
        "dsNombre": "Tunungua",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "832",
    },
    {
        "nmId": 965,
        "dsNombre": "Turmeque",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "835",
    },
    {
        "nmId": 966,
        "dsNombre": "Tuta",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "837",
    },
    {
        "nmId": 967,
        "dsNombre": "Tutasa",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "839",
    },
    {
        "nmId": 972,
        "dsNombre": "Umbita",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "842",
    },
    {
        "nmId": 995,
        "dsNombre": "Ventaquemada",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "861",
    },
    {
        "nmId": 1003,
        "dsNombre": "Villa De Leyva",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "407",
    },
    {
        "nmId": 1019,
        "dsNombre": "Viracacha",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "879",
    },
    {
        "nmId": 1037,
        "dsNombre": "Zetaquira",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "897",
    },
    {
        "nmId": 1061,
        "dsNombre": "CHIVATÁ",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "187",
    },
    {
        "nmId": 1062,
        "dsNombre": "CHIVOR",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "236",
    },
    {
        "nmId": 1063,
        "dsNombre": "GÜICÁN",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "332",
    },
    {
        "nmId": 1064,
        "dsNombre": "OICATÁ",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "500",
    },
    {
        "nmId": 1065,
        "dsNombre": "QUÍPAMA",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "580",
    },
    {
        "nmId": 1066,
        "dsNombre": "SORA",
        "departamento": {
            "cdIso": "CO-BOY",
            "dsNombre": "Boyacá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "15",
        },
        "cdDane": "762",
    },
    {
        "nmId": 11,
        "dsNombre": "Aguadas",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "013",
    },
    {
        "nmId": 41,
        "dsNombre": "Anserma",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "042",
    },
    {
        "nmId": 51,
        "dsNombre": "Aranzazu",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "050",
    },
    {
        "nmId": 87,
        "dsNombre": "Belalcazar",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "088",
    },
    {
        "nmId": 198,
        "dsNombre": "Chinchina",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "174",
    },
    {
        "nmId": 317,
        "dsNombre": "Filadelfia",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "272",
    },
    {
        "nmId": 438,
        "dsNombre": "La Dorada",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "380",
    },
    {
        "nmId": 444,
        "dsNombre": "La Merced",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "388",
    },
    {
        "nmId": 505,
        "dsNombre": "Manizales",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "001",
    },
    {
        "nmId": 507,
        "dsNombre": "Manzanares",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "433",
    },
    {
        "nmId": 513,
        "dsNombre": "Marmato",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "442",
    },
    {
        "nmId": 514,
        "dsNombre": "Marquetalia",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "444",
    },
    {
        "nmId": 516,
        "dsNombre": "Marulanda",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "446",
    },
    {
        "nmId": 561,
        "dsNombre": "Neira",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "486",
    },
    {
        "nmId": 590,
        "dsNombre": "Pacora",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "513",
    },
    {
        "nmId": 600,
        "dsNombre": "Palestina",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "524",
    },
    {
        "nmId": 621,
        "dsNombre": "Pensilvania",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "541",
    },
    {
        "nmId": 710,
        "dsNombre": "Riosucio",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "614",
    },
    {
        "nmId": 713,
        "dsNombre": "Risaralda",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "616",
    },
    {
        "nmId": 735,
        "dsNombre": "Salamina",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "653",
    },
    {
        "nmId": 742,
        "dsNombre": "Samana",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "662",
    },
    {
        "nmId": 897,
        "dsNombre": "Supia",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "777",
    },
    {
        "nmId": 999,
        "dsNombre": "Victoria",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "867",
    },
    {
        "nmId": 1008,
        "dsNombre": "Villamaria",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "873",
    },
    {
        "nmId": 1021,
        "dsNombre": "Viterbo",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "877",
    },
    {
        "nmId": 1067,
        "dsNombre": "NORCASIA",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "495",
    },
    {
        "nmId": 1068,
        "dsNombre": "SAN JOSÉ",
        "departamento": {
            "cdIso": "CO-CAL",
            "dsNombre": "Caldas",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "17",
        },
        "cdDane": "665",
    },
    {
        "nmId": 18,
        "dsNombre": "Albania",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "029",
    },
    {
        "nmId": 89,
        "dsNombre": "Belen Andaquies",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "094",
    },
    {
        "nmId": 136,
        "dsNombre": "San Vicente Del Caguan",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "753",
    },
    {
        "nmId": 171,
        "dsNombre": "Cartagena Del Chaira",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "150",
    },
    {
        "nmId": 263,
        "dsNombre": "Curillo",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "205",
    },
    {
        "nmId": 289,
        "dsNombre": "El Doncello",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "247",
    },
    {
        "nmId": 296,
        "dsNombre": "El Paujil",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "256",
    },
    {
        "nmId": 321,
        "dsNombre": "Florencia",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "001",
    },
    {
        "nmId": 446,
        "dsNombre": "La Montañita",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "410",
    },
    {
        "nmId": 523,
        "dsNombre": "Milan",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "460",
    },
    {
        "nmId": 545,
        "dsNombre": "Morelia",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "479",
    },
    {
        "nmId": 672,
        "dsNombre": "Puerto Rico",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "592",
    },
    {
        "nmId": 782,
        "dsNombre": "San Jose Del Fragua",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "610",
    },
    {
        "nmId": 875,
        "dsNombre": "Solano",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "756",
    },
    {
        "nmId": 877,
        "dsNombre": "Solita",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "785",
    },
    {
        "nmId": 989,
        "dsNombre": "Valparaiso",
        "departamento": {
            "cdIso": "CO-CAQ",
            "dsNombre": "Caquetá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "18",
        },
        "cdDane": "860",
    },
    {
        "nmId": 12,
        "dsNombre": "Aguazul",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "010",
    },
    {
        "nmId": 186,
        "dsNombre": "Chameza",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "015",
    },
    {
        "nmId": 398,
        "dsNombre": "Hato Corozal",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "125",
    },
    {
        "nmId": 455,
        "dsNombre": "La Salina",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "136",
    },
    {
        "nmId": 504,
        "dsNombre": "Mani",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "139",
    },
    {
        "nmId": 542,
        "dsNombre": "Monterrey",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "162",
    },
    {
        "nmId": 571,
        "dsNombre": "Nunchia",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "225",
    },
    {
        "nmId": 581,
        "dsNombre": "Orocue",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "230",
    },
    {
        "nmId": 617,
        "dsNombre": "Paz De Ariporo",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "250",
    },
    {
        "nmId": 644,
        "dsNombre": "Pore",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "263",
    },
    {
        "nmId": 695,
        "dsNombre": "Recetor",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "279",
    },
    {
        "nmId": 723,
        "dsNombre": "San Luis De Palenque",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "325",
    },
    {
        "nmId": 728,
        "dsNombre": "Sabanalarga",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "300",
    },
    {
        "nmId": 731,
        "dsNombre": "Sacama",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "315",
    },
    {
        "nmId": 907,
        "dsNombre": "Tamara",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "400",
    },
    {
        "nmId": 917,
        "dsNombre": "Tauramena",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "410",
    },
    {
        "nmId": 954,
        "dsNombre": "Trinidad",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "430",
    },
    {
        "nmId": 1009,
        "dsNombre": "Villanueva",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "440",
    },
    {
        "nmId": 1030,
        "dsNombre": "Yopal",
        "departamento": {
            "cdIso": "CO-CAS",
            "dsNombre": "Casanare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "85",
        },
        "cdDane": "001",
    },
    {
        "nmId": 23,
        "dsNombre": "Almaguer",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "022",
    },
    {
        "nmId": 62,
        "dsNombre": "Argelia",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "050",
    },
    {
        "nmId": 75,
        "dsNombre": "Balboa",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "075",
    },
    {
        "nmId": 108,
        "dsNombre": "Bolivar",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "100",
    },
    {
        "nmId": 122,
        "dsNombre": "Buenos Aires",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "110",
    },
    {
        "nmId": 141,
        "dsNombre": "Cajibio",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "130",
    },
    {
        "nmId": 147,
        "dsNombre": "Caldono",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "137",
    },
    {
        "nmId": 151,
        "dsNombre": "Caloto",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "142",
    },
    {
        "nmId": 241,
        "dsNombre": "Corinto",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "212",
    },
    {
        "nmId": 305,
        "dsNombre": "El Tambo",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "256",
    },
    {
        "nmId": 322,
        "dsNombre": "Florencia",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "290",
    },
    {
        "nmId": 380,
        "dsNombre": "Guapi",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "318",
    },
    {
        "nmId": 410,
        "dsNombre": "Inza",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "355",
    },
    {
        "nmId": 418,
        "dsNombre": "Jambalo",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "364",
    },
    {
        "nmId": 456,
        "dsNombre": "La Sierra",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "392",
    },
    {
        "nmId": 463,
        "dsNombre": "La Vega",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "397",
    },
    {
        "nmId": 480,
        "dsNombre": "Lopez",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "418",
    },
    {
        "nmId": 522,
        "dsNombre": "Mercaderes",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "450",
    },
    {
        "nmId": 526,
        "dsNombre": "Miranda",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "455",
    },
    {
        "nmId": 544,
        "dsNombre": "Morales",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "473",
    },
    {
        "nmId": 591,
        "dsNombre": "Padilla",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "513",
    },
    {
        "nmId": 593,
        "dsNombre": "Paez",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "517",
    },
    {
        "nmId": 614,
        "dsNombre": "Patia",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "532",
    },
    {
        "nmId": 628,
        "dsNombre": "Piendamo",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "548",
    },
    {
        "nmId": 643,
        "dsNombre": "Popayan",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "001",
    },
    {
        "nmId": 676,
        "dsNombre": "Puerto Tejada",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "573",
    },
    {
        "nmId": 681,
        "dsNombre": "Purace",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "585",
    },
    {
        "nmId": 682,
        "dsNombre": "Purace",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "585",
    },
    {
        "nmId": 720,
        "dsNombre": "Rosas",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "622",
    },
    {
        "nmId": 812,
        "dsNombre": "San Sebastian",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "693",
    },
    {
        "nmId": 830,
        "dsNombre": "Santa Rosa",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "701",
    },
    {
        "nmId": 839,
        "dsNombre": "Santander De Quilichao",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "698",
    },
    {
        "nmId": 861,
        "dsNombre": "Silvia",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "743",
    },
    {
        "nmId": 885,
        "dsNombre": "Sotara",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "760",
    },
    {
        "nmId": 934,
        "dsNombre": "Timbio",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "807",
    },
    {
        "nmId": 935,
        "dsNombre": "Timbiqui",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "809",
    },
    {
        "nmId": 950,
        "dsNombre": "Toribio",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "821",
    },
    {
        "nmId": 953,
        "dsNombre": "Totoro",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "824",
    },
    {
        "nmId": 1069,
        "dsNombre": "GUACHENÉ",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "300",
    },
    {
        "nmId": 1070,
        "dsNombre": "PIAMONTE",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "533",
    },
    {
        "nmId": 1071,
        "dsNombre": "SUÁREZ",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "780",
    },
    {
        "nmId": 1072,
        "dsNombre": "SUCRE",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "785",
    },
    {
        "nmId": 1073,
        "dsNombre": "VILLA RICA",
        "departamento": {
            "cdIso": "CO-CAU",
            "dsNombre": "Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "19",
        },
        "cdDane": "845",
    },
    {
        "nmId": 9,
        "dsNombre": "Aguachica",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "011",
    },
    {
        "nmId": 13,
        "dsNombre": "Agustin Codazzi",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "013",
    },
    {
        "nmId": 69,
        "dsNombre": "Astrea",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "032",
    },
    {
        "nmId": 111,
        "dsNombre": "Bosconia",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "060",
    },
    {
        "nmId": 195,
        "dsNombre": "Chimichagua",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "175",
    },
    {
        "nmId": 204,
        "dsNombre": "Chiriguana",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "178",
    },
    {
        "nmId": 265,
        "dsNombre": "Curumani",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "228",
    },
    {
        "nmId": 288,
        "dsNombre": "El Copey",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "238",
    },
    {
        "nmId": 295,
        "dsNombre": "El Paso",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "250",
    },
    {
        "nmId": 347,
        "dsNombre": "Gamarra",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "295",
    },
    {
        "nmId": 361,
        "dsNombre": "Gonzalez",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "310",
    },
    {
        "nmId": 441,
        "dsNombre": "La Gloria",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "383",
    },
    {
        "nmId": 442,
        "dsNombre": "La Jagua De Ibirico",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "400",
    },
    {
        "nmId": 595,
        "dsNombre": "Pailitas",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "517",
    },
    {
        "nmId": 620,
        "dsNombre": "Pelaya",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "550",
    },
    {
        "nmId": 704,
        "dsNombre": "Rio De Oro",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "614",
    },
    {
        "nmId": 746,
        "dsNombre": "San Alberto",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "710",
    },
    {
        "nmId": 765,
        "dsNombre": "San Diego",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "750",
    },
    {
        "nmId": 906,
        "dsNombre": "Tamalameque",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "787",
    },
    {
        "nmId": 987,
        "dsNombre": "Valledupar",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "001",
    },
    {
        "nmId": 1074,
        "dsNombre": "BECERRIL",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "45",
    },
    {
        "nmId": 1075,
        "dsNombre": "MANAURE",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "443",
    },
    {
        "nmId": 1076,
        "dsNombre": "PUEBLO BELLO",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "570",
    },
    {
        "nmId": 1077,
        "dsNombre": "LA PAZ",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "621",
    },
    {
        "nmId": 1078,
        "dsNombre": "SAN MARTÍN",
        "departamento": {
            "cdIso": "CO-CES",
            "dsNombre": "Cesar",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "20",
        },
        "cdDane": "770",
    },
    {
        "nmId": 5,
        "dsNombre": "Acandi",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "006",
    },
    {
        "nmId": 27,
        "dsNombre": "Alto Baudo",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "025",
    },
    {
        "nmId": 72,
        "dsNombre": "Bagado",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "073",
    },
    {
        "nmId": 73,
        "dsNombre": "Bahia Solano",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "075",
    },
    {
        "nmId": 74,
        "dsNombre": "Bajo Baudo",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "077",
    },
    {
        "nmId": 105,
        "dsNombre": "Bojaya",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "099",
    },
    {
        "nmId": 230,
        "dsNombre": "Condoto",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "205",
    },
    {
        "nmId": 282,
        "dsNombre": "El Carmen De Atrato",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "245",
    },
    {
        "nmId": 414,
        "dsNombre": "Istmina",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "361",
    },
    {
        "nmId": 429,
        "dsNombre": "Jurado",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "372",
    },
    {
        "nmId": 479,
        "dsNombre": "Lloro",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "413",
    },
    {
        "nmId": 568,
        "dsNombre": "Novita",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "491",
    },
    {
        "nmId": 572,
        "dsNombre": "Nuqui",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "495",
    },
    {
        "nmId": 687,
        "dsNombre": "Quibdo",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "001",
    },
    {
        "nmId": 711,
        "dsNombre": "Riosucio",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "615",
    },
    {
        "nmId": 778,
        "dsNombre": "San Jose Del Palmar",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "660",
    },
    {
        "nmId": 867,
        "dsNombre": "Sipi",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "745",
    },
    {
        "nmId": 905,
        "dsNombre": "Tado",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "787",
    },
    {
        "nmId": 1085,
        "dsNombre": "ATRATO",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "50",
    },
    {
        "nmId": 1086,
        "dsNombre": "BELÉN DE BAJIRÁ",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "086",
    },
    {
        "nmId": 1087,
        "dsNombre": "EL CANTÓN DEL SAN PABLO",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "135",
    },
    {
        "nmId": 1088,
        "dsNombre": "CARMEN DEL DARIEN",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "150",
    },
    {
        "nmId": 1089,
        "dsNombre": "CÉRTEGUI",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "160",
    },
    {
        "nmId": 1090,
        "dsNombre": "EL LITORAL DEL SAN JUAN",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "250",
    },
    {
        "nmId": 1091,
        "dsNombre": "MEDIO ATRATO",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "425",
    },
    {
        "nmId": 1092,
        "dsNombre": "MEDIO BAUDÓ",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "430",
    },
    {
        "nmId": 1093,
        "dsNombre": "MEDIO SAN JUAN",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "450",
    },
    {
        "nmId": 1094,
        "dsNombre": "RÍO IRO",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "580",
    },
    {
        "nmId": 1095,
        "dsNombre": "RÍO QUITO",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "600",
    },
    {
        "nmId": 1096,
        "dsNombre": "UNGUÍA",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "800",
    },
    {
        "nmId": 1097,
        "dsNombre": "UNIÓN PANAMERICANA",
        "departamento": {
            "cdIso": "CO-CHO",
            "dsNombre": "Chocó",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "27",
        },
        "cdDane": "810",
    },
    {
        "nmId": 71,
        "dsNombre": "Ayapel",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "068",
    },
    {
        "nmId": 120,
        "dsNombre": "Buenavista",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "079",
    },
    {
        "nmId": 179,
        "dsNombre": "Cerete",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "162",
    },
    {
        "nmId": 193,
        "dsNombre": "Chima",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "168",
    },
    {
        "nmId": 199,
        "dsNombre": "Chinu",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "182",
    },
    {
        "nmId": 213,
        "dsNombre": "Cienaga De Oro",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "189",
    },
    {
        "nmId": 246,
        "dsNombre": "Cotorra",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "300",
    },
    {
        "nmId": 481,
        "dsNombre": "Lorica",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "417",
    },
    {
        "nmId": 483,
        "dsNombre": "Los Cordobas",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "419",
    },
    {
        "nmId": 533,
        "dsNombre": "Momil",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "464",
    },
    {
        "nmId": 539,
        "dsNombre": "Montelibano",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "466",
    },
    {
        "nmId": 541,
        "dsNombre": "Monteria",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "001",
    },
    {
        "nmId": 638,
        "dsNombre": "Planeta Rica",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "555",
    },
    {
        "nmId": 649,
        "dsNombre": "Pueblo Nuevo",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "570",
    },
    {
        "nmId": 661,
        "dsNombre": "Puerto Escondido",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "574",
    },
    {
        "nmId": 665,
        "dsNombre": "Puerto Libertador",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "580",
    },
    {
        "nmId": 684,
        "dsNombre": "Purisima",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "586",
    },
    {
        "nmId": 733,
        "dsNombre": "Sahagun",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "660",
    },
    {
        "nmId": 750,
        "dsNombre": "San Andres Sotavento",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "670",
    },
    {
        "nmId": 752,
        "dsNombre": "San Antero",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "672",
    },
    {
        "nmId": 758,
        "dsNombre": "San Bernardo Del Viento",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "675",
    },
    {
        "nmId": 761,
        "dsNombre": "San Carlos",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "678",
    },
    {
        "nmId": 809,
        "dsNombre": "San Pelayo",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "686",
    },
    {
        "nmId": 932,
        "dsNombre": "Tierralta",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "807",
    },
    {
        "nmId": 983,
        "dsNombre": "Valencia",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "855",
    },
    {
        "nmId": 1079,
        "dsNombre": "CANALETE",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "90",
    },
    {
        "nmId": 1080,
        "dsNombre": "LA APARTADA",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "350",
    },
    {
        "nmId": 1081,
        "dsNombre": "MOÑITOS",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "500",
    },
    {
        "nmId": 1178,
        "dsNombre": "San Jose de Ure",
        "departamento": {
            "cdIso": "CO-COR",
            "dsNombre": "Córdoba",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "23",
        },
        "cdDane": "682",
    },
    {
        "nmId": 8,
        "dsNombre": "Agua De Dios",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "001",
    },
    {
        "nmId": 15,
        "dsNombre": "Alban",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "019",
    },
    {
        "nmId": 33,
        "dsNombre": "Anapoima",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "035",
    },
    {
        "nmId": 39,
        "dsNombre": "Anolaima",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "040",
    },
    {
        "nmId": 56,
        "dsNombre": "Arbelaez",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "053",
    },
    {
        "nmId": 94,
        "dsNombre": "Beltran",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "086",
    },
    {
        "nmId": 100,
        "dsNombre": "Bituima",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "095",
    },
    {
        "nmId": 104,
        "dsNombre": "Bojaca",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "099",
    },
    {
        "nmId": 129,
        "dsNombre": "Cabrera",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "120",
    },
    {
        "nmId": 133,
        "dsNombre": "Cachipay",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "123",
    },
    {
        "nmId": 142,
        "dsNombre": "Cajica",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "126",
    },
    {
        "nmId": 159,
        "dsNombre": "Caparrapi",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "148",
    },
    {
        "nmId": 161,
        "dsNombre": "Caqueza",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "151",
    },
    {
        "nmId": 167,
        "dsNombre": "Carmen De Carupa",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "154",
    },
    {
        "nmId": 184,
        "dsNombre": "Chaguani",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "168",
    },
    {
        "nmId": 191,
        "dsNombre": "Chia",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "175",
    },
    {
        "nmId": 200,
        "dsNombre": "Chipaque",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "178",
    },
    {
        "nmId": 209,
        "dsNombre": "Choachi",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "181",
    },
    {
        "nmId": 211,
        "dsNombre": "Choconta",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "183",
    },
    {
        "nmId": 221,
        "dsNombre": "Cogua",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "200",
    },
    {
        "nmId": 245,
        "dsNombre": "Cota",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "214",
    },
    {
        "nmId": 255,
        "dsNombre": "Cucunuba",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "224",
    },
    {
        "nmId": 287,
        "dsNombre": "El Colegio",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "245",
    },
    {
        "nmId": 298,
        "dsNombre": "El Peñon",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "258",
    },
    {
        "nmId": 302,
        "dsNombre": "El Rosal",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "260",
    },
    {
        "nmId": 315,
        "dsNombre": "Facatativa",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "269",
    },
    {
        "nmId": 326,
        "dsNombre": "Fomeque",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "279",
    },
    {
        "nmId": 329,
        "dsNombre": "Fosca",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "281",
    },
    {
        "nmId": 336,
        "dsNombre": "Funza",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "286",
    },
    {
        "nmId": 337,
        "dsNombre": "Fuquene",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "288",
    },
    {
        "nmId": 338,
        "dsNombre": "Fusagasuga",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "290",
    },
    {
        "nmId": 339,
        "dsNombre": "Gachala",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "293",
    },
    {
        "nmId": 340,
        "dsNombre": "Gachancipa",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "295",
    },
    {
        "nmId": 342,
        "dsNombre": "Gacheta",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "297",
    },
    {
        "nmId": 346,
        "dsNombre": "Gama",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "299",
    },
    {
        "nmId": 357,
        "dsNombre": "Girardot",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "307",
    },
    {
        "nmId": 365,
        "dsNombre": "Granada",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "312",
    },
    {
        "nmId": 369,
        "dsNombre": "Guacheta",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "317",
    },
    {
        "nmId": 374,
        "dsNombre": "Guaduas",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "320",
    },
    {
        "nmId": 383,
        "dsNombre": "Guasca",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "322",
    },
    {
        "nmId": 385,
        "dsNombre": "Guataqui",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "324",
    },
    {
        "nmId": 386,
        "dsNombre": "Guatavita",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "326",
    },
    {
        "nmId": 391,
        "dsNombre": "Guayabal Siquim",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "328",
    },
    {
        "nmId": 395,
        "dsNombre": "Gutierrez",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "339",
    },
    {
        "nmId": 424,
        "dsNombre": "Jerusalen",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "368",
    },
    {
        "nmId": 428,
        "dsNombre": "Junin",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "372",
    },
    {
        "nmId": 431,
        "dsNombre": "La Calera",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "377",
    },
    {
        "nmId": 445,
        "dsNombre": "La Mesa",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "386",
    },
    {
        "nmId": 447,
        "dsNombre": "La Palma",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "394",
    },
    {
        "nmId": 449,
        "dsNombre": "La Peña",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "398",
    },
    {
        "nmId": 464,
        "dsNombre": "La Vega",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "402",
    },
    {
        "nmId": 473,
        "dsNombre": "Lenguazaque",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "407",
    },
    {
        "nmId": 492,
        "dsNombre": "Macheta",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "426",
    },
    {
        "nmId": 493,
        "dsNombre": "Madrid",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "430",
    },
    {
        "nmId": 506,
        "dsNombre": "Manta",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "436",
    },
    {
        "nmId": 520,
        "dsNombre": "Medina",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "438",
    },
    {
        "nmId": 548,
        "dsNombre": "Mosquera",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "473",
    },
    {
        "nmId": 556,
        "dsNombre": "Nariño",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "483",
    },
    {
        "nmId": 563,
        "dsNombre": "Nemocon",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "486",
    },
    {
        "nmId": 564,
        "dsNombre": "Nilo",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "488",
    },
    {
        "nmId": 565,
        "dsNombre": "Nimaima",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "489",
    },
    {
        "nmId": 567,
        "dsNombre": "Nocaima",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "491",
    },
    {
        "nmId": 589,
        "dsNombre": "Pacho",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "513",
    },
    {
        "nmId": 596,
        "dsNombre": "Paime",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "518",
    },
    {
        "nmId": 609,
        "dsNombre": "Pandi",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "524",
    },
    {
        "nmId": 612,
        "dsNombre": "Pasca",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "535",
    },
    {
        "nmId": 674,
        "dsNombre": "Puerto Salgar",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "572",
    },
    {
        "nmId": 679,
        "dsNombre": "Puli",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "580",
    },
    {
        "nmId": 685,
        "dsNombre": "Quebradanegra",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "592",
    },
    {
        "nmId": 686,
        "dsNombre": "Quetame",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "594",
    },
    {
        "nmId": 690,
        "dsNombre": "Quipile",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "596",
    },
    {
        "nmId": 702,
        "dsNombre": "Ricaurte",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "612",
    },
    {
        "nmId": 751,
        "dsNombre": "San Antonio De Tequendama",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "645",
    },
    {
        "nmId": 757,
        "dsNombre": "San Bernardo",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "649",
    },
    {
        "nmId": 763,
        "dsNombre": "San Cayetano",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "653",
    },
    {
        "nmId": 771,
        "dsNombre": "San Francisco",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "658",
    },
    {
        "nmId": 787,
        "dsNombre": "San Juan De Rio Seco",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "662",
    },
    {
        "nmId": 850,
        "dsNombre": "Sasaima",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "718",
    },
    {
        "nmId": 854,
        "dsNombre": "Sesquile",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "736",
    },
    {
        "nmId": 857,
        "dsNombre": "Sibate",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "740",
    },
    {
        "nmId": 860,
        "dsNombre": "Silvania",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "743",
    },
    {
        "nmId": 863,
        "dsNombre": "Simijaca",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "745",
    },
    {
        "nmId": 869,
        "dsNombre": "Soacha",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "754",
    },
    {
        "nmId": 882,
        "dsNombre": "Sopo",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "758",
    },
    {
        "nmId": 891,
        "dsNombre": "Subachoque",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "769",
    },
    {
        "nmId": 895,
        "dsNombre": "Suesca",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "772",
    },
    {
        "nmId": 896,
        "dsNombre": "Supata",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "777",
    },
    {
        "nmId": 899,
        "dsNombre": "Susa",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "779",
    },
    {
        "nmId": 902,
        "dsNombre": "Sutatausa",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "781",
    },
    {
        "nmId": 904,
        "dsNombre": "Tabio",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "785",
    },
    {
        "nmId": 918,
        "dsNombre": "Tausa",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "793",
    },
    {
        "nmId": 920,
        "dsNombre": "Tena",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "797",
    },
    {
        "nmId": 922,
        "dsNombre": "Tenjo",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "799",
    },
    {
        "nmId": 927,
        "dsNombre": "Tibacuy",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "805",
    },
    {
        "nmId": 930,
        "dsNombre": "Tibirita",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "807",
    },
    {
        "nmId": 940,
        "dsNombre": "Tocaima",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "815",
    },
    {
        "nmId": 941,
        "dsNombre": "Tocancipa",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "817",
    },
    {
        "nmId": 949,
        "dsNombre": "Topaipi",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "823",
    },
    {
        "nmId": 968,
        "dsNombre": "Ubala",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "839",
    },
    {
        "nmId": 969,
        "dsNombre": "Ubaque",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "841",
    },
    {
        "nmId": 970,
        "dsNombre": "Villa De San Diego De Ubate",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "843",
    },
    {
        "nmId": 974,
        "dsNombre": "Une",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "845",
    },
    {
        "nmId": 981,
        "dsNombre": "Utica",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "851",
    },
    {
        "nmId": 994,
        "dsNombre": "Venecia",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "506",
    },
    {
        "nmId": 996,
        "dsNombre": "Vergara",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "862",
    },
    {
        "nmId": 998,
        "dsNombre": "Viani",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "867",
    },
    {
        "nmId": 1006,
        "dsNombre": "Villagomez",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "871",
    },
    {
        "nmId": 1013,
        "dsNombre": "Villapinzon",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "873",
    },
    {
        "nmId": 1017,
        "dsNombre": "Villeta",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "875",
    },
    {
        "nmId": 1018,
        "dsNombre": "Viota",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "878",
    },
    {
        "nmId": 1022,
        "dsNombre": "Yacopi",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "885",
    },
    {
        "nmId": 1038,
        "dsNombre": "Zipacon",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "898",
    },
    {
        "nmId": 1039,
        "dsNombre": "Zipaquira",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "899",
    },
    {
        "nmId": 1082,
        "dsNombre": "GUAYABETAL",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "335",
    },
    {
        "nmId": 1083,
        "dsNombre": "PARATEBUENO",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "530",
    },
    {
        "nmId": 1084,
        "dsNombre": "APULO",
        "departamento": {
            "cdIso": "CO-CUN",
            "dsNombre": "Cundinamarca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "25",
        },
        "cdDane": "599",
    },
    {
        "nmId": 103,
        "dsNombre": "Bogota D.C.",
        "departamento": {
            "cdIso": "CO-DC",
            "dsNombre": "Distrito Capital de Bogotá",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "11",
        },
        "cdDane": "001",
    },
    {
        "nmId": 409,
        "dsNombre": "Inirida",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "001",
    },
    {
        "nmId": 768,
        "dsNombre": "San Felipe",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "883",
    },
    {
        "nmId": 769,
        "dsNombre": "San Felipe",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "883",
    },
    {
        "nmId": 1163,
        "dsNombre": "BARRANCO MINAS",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "343",
    },
    {
        "nmId": 1164,
        "dsNombre": "MAPIRIPANA",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "663",
    },
    {
        "nmId": 1165,
        "dsNombre": "PUERTO COLOMBIA",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "884",
    },
    {
        "nmId": 1166,
        "dsNombre": "LA GUADALUPE",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "885",
    },
    {
        "nmId": 1167,
        "dsNombre": "CACAHUAL",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "886",
    },
    {
        "nmId": 1168,
        "dsNombre": "PANA PANA",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "887",
    },
    {
        "nmId": 1169,
        "dsNombre": "MORICHAL",
        "departamento": {
            "cdIso": "CO-GUA",
            "dsNombre": "Guainía",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "94",
        },
        "cdDane": "888",
    },
    {
        "nmId": 783,
        "dsNombre": "San Jose Del Guaviare",
        "departamento": {
            "cdIso": "CO-GUV",
            "dsNombre": "Guaviare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "95",
        },
        "cdDane": "001",
    },
    {
        "nmId": 1170,
        "dsNombre": "CALAMAR",
        "departamento": {
            "cdIso": "CO-GUV",
            "dsNombre": "Guaviare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "95",
        },
        "cdDane": "15",
    },
    {
        "nmId": 1171,
        "dsNombre": "EL RETORNO",
        "departamento": {
            "cdIso": "CO-GUV",
            "dsNombre": "Guaviare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "95",
        },
        "cdDane": "25",
    },
    {
        "nmId": 1172,
        "dsNombre": "MIRAFLORES",
        "departamento": {
            "cdIso": "CO-GUV",
            "dsNombre": "Guaviare",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "95",
        },
        "cdDane": "200",
    },
    {
        "nmId": 6,
        "dsNombre": "Acevedo",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "006",
    },
    {
        "nmId": 7,
        "dsNombre": "Agrado",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "013",
    },
    {
        "nmId": 14,
        "dsNombre": "Aipe",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "016",
    },
    {
        "nmId": 22,
        "dsNombre": "Algeciras",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "020",
    },
    {
        "nmId": 26,
        "dsNombre": "Altamira",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "026",
    },
    {
        "nmId": 78,
        "dsNombre": "Baraya",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "078",
    },
    {
        "nmId": 154,
        "dsNombre": "Campoalegre",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "132",
    },
    {
        "nmId": 222,
        "dsNombre": "Colombia",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "206",
    },
    {
        "nmId": 308,
        "dsNombre": "Elias",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "244",
    },
    {
        "nmId": 351,
        "dsNombre": "Garzon",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "298",
    },
    {
        "nmId": 354,
        "dsNombre": "Gigante",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "306",
    },
    {
        "nmId": 372,
        "dsNombre": "Guadalupe",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "319",
    },
    {
        "nmId": 403,
        "dsNombre": "Hobo",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "349",
    },
    {
        "nmId": 412,
        "dsNombre": "Iquira",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "357",
    },
    {
        "nmId": 413,
        "dsNombre": "Isnos",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "359",
    },
    {
        "nmId": 430,
        "dsNombre": "La Argentina",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "378",
    },
    {
        "nmId": 452,
        "dsNombre": "La Plata",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "396",
    },
    {
        "nmId": 557,
        "dsNombre": "Nataga",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "483",
    },
    {
        "nmId": 562,
        "dsNombre": "Neiva",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "001",
    },
    {
        "nmId": 579,
        "dsNombre": "Oporapa",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "503",
    },
    {
        "nmId": 594,
        "dsNombre": "Paicol",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "518",
    },
    {
        "nmId": 599,
        "dsNombre": "Palermo",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "524",
    },
    {
        "nmId": 634,
        "dsNombre": "Pital",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "548",
    },
    {
        "nmId": 635,
        "dsNombre": "Pitalito",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "551",
    },
    {
        "nmId": 714,
        "dsNombre": "Rivera",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "615",
    },
    {
        "nmId": 734,
        "dsNombre": "Saladoblanco",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "660",
    },
    {
        "nmId": 745,
        "dsNombre": "San Agustin",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "668",
    },
    {
        "nmId": 828,
        "dsNombre": "Santa Maria",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "676",
    },
    {
        "nmId": 889,
        "dsNombre": "Suaza",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "770",
    },
    {
        "nmId": 914,
        "dsNombre": "Tarqui",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "791",
    },
    {
        "nmId": 919,
        "dsNombre": "Tello",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "799",
    },
    {
        "nmId": 925,
        "dsNombre": "Teruel",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "801",
    },
    {
        "nmId": 926,
        "dsNombre": "Tesalia",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "797",
    },
    {
        "nmId": 933,
        "dsNombre": "Timana",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "807",
    },
    {
        "nmId": 1016,
        "dsNombre": "Villavieja",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "872",
    },
    {
        "nmId": 1024,
        "dsNombre": "Yaguara",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "885",
    },
    {
        "nmId": 1098,
        "dsNombre": "PALESTINA",
        "departamento": {
            "cdIso": "CO-HUI",
            "dsNombre": "Huila",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "41",
        },
        "cdDane": "530",
    },
    {
        "nmId": 84,
        "dsNombre": "Barrancas",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "078",
    },
    {
        "nmId": 327,
        "dsNombre": "Fonseca",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "279",
    },
    {
        "nmId": 497,
        "dsNombre": "Maicao",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "430",
    },
    {
        "nmId": 503,
        "dsNombre": "Manaure",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "560",
    },
    {
        "nmId": 707,
        "dsNombre": "Riohacha",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "001",
    },
    {
        "nmId": 786,
        "dsNombre": "San Juan Del Cesar",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "650",
    },
    {
        "nmId": 976,
        "dsNombre": "Uribia",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "847",
    },
    {
        "nmId": 1010,
        "dsNombre": "Villanueva",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "874",
    },
    {
        "nmId": 1099,
        "dsNombre": "ALBANIA",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "35",
    },
    {
        "nmId": 1100,
        "dsNombre": "DIBULLA",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "90",
    },
    {
        "nmId": 1101,
        "dsNombre": "DISTRACCIÓN",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "98",
    },
    {
        "nmId": 1102,
        "dsNombre": "EL MOLINO",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "110",
    },
    {
        "nmId": 1103,
        "dsNombre": "HATONUEVO",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "378",
    },
    {
        "nmId": 1104,
        "dsNombre": "LA JAGUA DEL PILAR",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "420",
    },
    {
        "nmId": 1105,
        "dsNombre": "URUMITA",
        "departamento": {
            "cdIso": "CO-LAG",
            "dsNombre": "La Guajira",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "44",
        },
        "cdDane": "855",
    },
    {
        "nmId": 50,
        "dsNombre": "Aracataca",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "053",
    },
    {
        "nmId": 64,
        "dsNombre": "Ariguani",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "058",
    },
    {
        "nmId": 182,
        "dsNombre": "Cerro San Antonio",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "161",
    },
    {
        "nmId": 212,
        "dsNombre": "Cienaga",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "189",
    },
    {
        "nmId": 277,
        "dsNombre": "El Banco",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "245",
    },
    {
        "nmId": 299,
        "dsNombre": "El Piñon",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "258",
    },
    {
        "nmId": 334,
        "dsNombre": "Fundacion",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "288",
    },
    {
        "nmId": 378,
        "dsNombre": "Guamal",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "318",
    },
    {
        "nmId": 619,
        "dsNombre": "Pedraza",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "541",
    },
    {
        "nmId": 636,
        "dsNombre": "Pivijay",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "551",
    },
    {
        "nmId": 639,
        "dsNombre": "Plato",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "555",
    },
    {
        "nmId": 652,
        "dsNombre": "Puebloviejo",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "570",
    },
    {
        "nmId": 697,
        "dsNombre": "Remolino",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "605",
    },
    {
        "nmId": 736,
        "dsNombre": "Salamina",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "675",
    },
    {
        "nmId": 813,
        "dsNombre": "San Sebastian De Buenavista",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "692",
    },
    {
        "nmId": 817,
        "dsNombre": "San Zenon",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "703",
    },
    {
        "nmId": 820,
        "dsNombre": "Santa Ana",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "707",
    },
    {
        "nmId": 829,
        "dsNombre": "Santa Marta",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "001",
    },
    {
        "nmId": 868,
        "dsNombre": "Sitionuevo",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "745",
    },
    {
        "nmId": 921,
        "dsNombre": "Tenerife",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "798",
    },
    {
        "nmId": 1106,
        "dsNombre": "ALGARROBO",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "30",
    },
    {
        "nmId": 1107,
        "dsNombre": "CHIBOLO",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "170",
    },
    {
        "nmId": 1108,
        "dsNombre": "CONCORDIA",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "205",
    },
    {
        "nmId": 1109,
        "dsNombre": "EL RETÉN",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "268",
    },
    {
        "nmId": 1110,
        "dsNombre": "NUEVA GRANADA",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "460",
    },
    {
        "nmId": 1111,
        "dsNombre": "PIJIÑO DEL CARMEN",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "545",
    },
    {
        "nmId": 1112,
        "dsNombre": "SABANAS DE SAN ANGEL",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "660",
    },
    {
        "nmId": 1113,
        "dsNombre": "SANTA BÁRBARA DE PINTO",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "720",
    },
    {
        "nmId": 1114,
        "dsNombre": "ZAPAYÁN",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "960",
    },
    {
        "nmId": 1115,
        "dsNombre": "ZONA BANANERA",
        "departamento": {
            "cdIso": "CO-MAG",
            "dsNombre": "Magdalena",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "47",
        },
        "cdDane": "980",
    },
    {
        "nmId": 4,
        "dsNombre": "Acacias",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "006",
    },
    {
        "nmId": 131,
        "dsNombre": "Cabuyaro",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "124",
    },
    {
        "nmId": 175,
        "dsNombre": "Castilla La Nueva",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "150",
    },
    {
        "nmId": 253,
        "dsNombre": "Cubarral",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "223",
    },
    {
        "nmId": 259,
        "dsNombre": "Cumaral",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "226",
    },
    {
        "nmId": 279,
        "dsNombre": "El Calvario",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "245",
    },
    {
        "nmId": 333,
        "dsNombre": "Fuente De Oro",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "287",
    },
    {
        "nmId": 364,
        "dsNombre": "Granada",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "313",
    },
    {
        "nmId": 377,
        "dsNombre": "Guamal",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "318",
    },
    {
        "nmId": 662,
        "dsNombre": "Puerto Gaitan",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "568",
    },
    {
        "nmId": 666,
        "dsNombre": "Puerto Lleras",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "577",
    },
    {
        "nmId": 667,
        "dsNombre": "Puerto Lopez",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "573",
    },
    {
        "nmId": 699,
        "dsNombre": "Restrepo",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "606",
    },
    {
        "nmId": 762,
        "dsNombre": "San Carlos De Guaroa",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "680",
    },
    {
        "nmId": 788,
        "dsNombre": "San Juan De Arama",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "683",
    },
    {
        "nmId": 796,
        "dsNombre": "San Martin",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "689",
    },
    {
        "nmId": 1015,
        "dsNombre": "Villavicencio",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "001",
    },
    {
        "nmId": 1020,
        "dsNombre": "Vistahermosa",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "711",
    },
    {
        "nmId": 1116,
        "dsNombre": "BARRANCA DE UPÍA",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "110",
    },
    {
        "nmId": 1117,
        "dsNombre": "EL CASTILLO",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "251",
    },
    {
        "nmId": 1118,
        "dsNombre": "EL DORADO",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "270",
    },
    {
        "nmId": 1119,
        "dsNombre": "MAPIRIPÁN",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "325",
    },
    {
        "nmId": 1120,
        "dsNombre": "MESETAS",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "330",
    },
    {
        "nmId": 1121,
        "dsNombre": "LA MACARENA",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "350",
    },
    {
        "nmId": 1122,
        "dsNombre": "URIBE",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "370",
    },
    {
        "nmId": 1123,
        "dsNombre": "LEJANÍAS",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "400",
    },
    {
        "nmId": 1124,
        "dsNombre": "PUERTO CONCORDIA",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "450",
    },
    {
        "nmId": 1125,
        "dsNombre": "PUERTO RICO",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "590",
    },
    {
        "nmId": 1126,
        "dsNombre": "SAN JUANITO",
        "departamento": {
            "cdIso": "CO-MET",
            "dsNombre": "Meta",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "50",
        },
        "cdDane": "686",
    },
    {
        "nmId": 16,
        "dsNombre": "Alban",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "019",
    },
    {
        "nmId": 20,
        "dsNombre": "Aldana",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "022",
    },
    {
        "nmId": 34,
        "dsNombre": "Ancuya",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "036",
    },
    {
        "nmId": 57,
        "dsNombre": "Arboleda",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "051",
    },
    {
        "nmId": 79,
        "dsNombre": "Barbacoas",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "079",
    },
    {
        "nmId": 123,
        "dsNombre": "Buesaco",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "110",
    },
    {
        "nmId": 183,
        "dsNombre": "Chachagui",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "240",
    },
    {
        "nmId": 224,
        "dsNombre": "Colon",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "203",
    },
    {
        "nmId": 232,
        "dsNombre": "Consaca",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "207",
    },
    {
        "nmId": 233,
        "dsNombre": "Contadero",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "210",
    },
    {
        "nmId": 240,
        "dsNombre": "Cordoba",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "215",
    },
    {
        "nmId": 251,
        "dsNombre": "Cuaspud",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "224",
    },
    {
        "nmId": 260,
        "dsNombre": "Cumbal",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "227",
    },
    {
        "nmId": 261,
        "dsNombre": "Cumbitara",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "233",
    },
    {
        "nmId": 285,
        "dsNombre": "El Charco",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "250",
    },
    {
        "nmId": 303,
        "dsNombre": "El Rosario",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "256",
    },
    {
        "nmId": 304,
        "dsNombre": "El Tablon De Gomez",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "258",
    },
    {
        "nmId": 306,
        "dsNombre": "El Tambo",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "260",
    },
    {
        "nmId": 335,
        "dsNombre": "Funes",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "287",
    },
    {
        "nmId": 370,
        "dsNombre": "Guachucal",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "317",
    },
    {
        "nmId": 375,
        "dsNombre": "Guaitarilla",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "320",
    },
    {
        "nmId": 376,
        "dsNombre": "Gualmatan",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "323",
    },
    {
        "nmId": 407,
        "dsNombre": "Iles",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "352",
    },
    {
        "nmId": 408,
        "dsNombre": "Imues",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "354",
    },
    {
        "nmId": 411,
        "dsNombre": "Ipiales",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "356",
    },
    {
        "nmId": 436,
        "dsNombre": "La Cruz",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "378",
    },
    {
        "nmId": 440,
        "dsNombre": "La Florida",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "381",
    },
    {
        "nmId": 460,
        "dsNombre": "La Union",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "399",
    },
    {
        "nmId": 478,
        "dsNombre": "Linares",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "411",
    },
    {
        "nmId": 482,
        "dsNombre": "Los Andes",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "418",
    },
    {
        "nmId": 495,
        "dsNombre": "Magui",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "427",
    },
    {
        "nmId": 501,
        "dsNombre": "Mallama",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "435",
    },
    {
        "nmId": 549,
        "dsNombre": "Mosquera",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "473",
    },
    {
        "nmId": 583,
        "dsNombre": "Ospina",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "506",
    },
    {
        "nmId": 613,
        "dsNombre": "Pasto",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "001",
    },
    {
        "nmId": 640,
        "dsNombre": "Policarpa",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "540",
    },
    {
        "nmId": 645,
        "dsNombre": "Potosi",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "560",
    },
    {
        "nmId": 654,
        "dsNombre": "Puerres",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "573",
    },
    {
        "nmId": 680,
        "dsNombre": "Pupiales",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "585",
    },
    {
        "nmId": 703,
        "dsNombre": "Ricaurte",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "612",
    },
    {
        "nmId": 715,
        "dsNombre": "Roberto Payan",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "621",
    },
    {
        "nmId": 743,
        "dsNombre": "Samaniego",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "678",
    },
    {
        "nmId": 791,
        "dsNombre": "San Lorenzo",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "687",
    },
    {
        "nmId": 803,
        "dsNombre": "San Pablo",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "693",
    },
    {
        "nmId": 819,
        "dsNombre": "Sandona",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "683",
    },
    {
        "nmId": 822,
        "dsNombre": "Santa Barbara",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "696",
    },
    {
        "nmId": 837,
        "dsNombre": "Santacruz",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "699",
    },
    {
        "nmId": 847,
        "dsNombre": "Sapuyes",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "720",
    },
    {
        "nmId": 910,
        "dsNombre": "Taminango",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "786",
    },
    {
        "nmId": 911,
        "dsNombre": "Tangua",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "788",
    },
    {
        "nmId": 961,
        "dsNombre": "Tuquerres",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "838",
    },
    {
        "nmId": 1023,
        "dsNombre": "Yacuanquer",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "885",
    },
    {
        "nmId": 1127,
        "dsNombre": "BELÉN",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "83",
    },
    {
        "nmId": 1128,
        "dsNombre": "EL PEÑOL",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "254",
    },
    {
        "nmId": 1129,
        "dsNombre": "LA LLANADA",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "385",
    },
    {
        "nmId": 1130,
        "dsNombre": "LA TOLA",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "390",
    },
    {
        "nmId": 1131,
        "dsNombre": "LEIVA",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "405",
    },
    {
        "nmId": 1132,
        "dsNombre": "NARIÑO",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "480",
    },
    {
        "nmId": 1133,
        "dsNombre": "OLAYA HERRERA",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "490",
    },
    {
        "nmId": 1134,
        "dsNombre": "FRANCISCO PIZARRO",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "520",
    },
    {
        "nmId": 1135,
        "dsNombre": "PROVIDENCIA",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "565",
    },
    {
        "nmId": 1136,
        "dsNombre": "SAN BERNARDO",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "685",
    },
    {
        "nmId": 1137,
        "dsNombre": "SAN PEDRO DE CARTAGO",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "694",
    },
    {
        "nmId": 1138,
        "dsNombre": "SAN ANDRES DE TUMACO",
        "departamento": {
            "cdIso": "CO-NAR",
            "dsNombre": "Nariño",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "52",
        },
        "cdDane": "835",
    },
    {
        "nmId": 1040,
        "dsNombre": "No Registra",
        "departamento": {
            "cdIso": "CO-NN",
            "dsNombre": "No Registra",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
        },
    },
    {
        "nmId": 2,
        "dsNombre": "Abrego",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "003",
    },
    {
        "nmId": 58,
        "dsNombre": "Arboledas",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "051",
    },
    {
        "nmId": 102,
        "dsNombre": "Bochalema",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "099",
    },
    {
        "nmId": 116,
        "dsNombre": "Bucarasica",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "109",
    },
    {
        "nmId": 134,
        "dsNombre": "Cachira",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "128",
    },
    {
        "nmId": 135,
        "dsNombre": "Cacota",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "125",
    },
    {
        "nmId": 196,
        "dsNombre": "Chinacota",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "172",
    },
    {
        "nmId": 207,
        "dsNombre": "Chitaga",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "174",
    },
    {
        "nmId": 235,
        "dsNombre": "Convencion",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "206",
    },
    {
        "nmId": 256,
        "dsNombre": "Cucuta",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "001",
    },
    {
        "nmId": 257,
        "dsNombre": "Cucutilla",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "223",
    },
    {
        "nmId": 273,
        "dsNombre": "Durania",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "239",
    },
    {
        "nmId": 281,
        "dsNombre": "El Carmen",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "245",
    },
    {
        "nmId": 307,
        "dsNombre": "El Zulia",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "261",
    },
    {
        "nmId": 362,
        "dsNombre": "Gramalote",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "313",
    },
    {
        "nmId": 396,
        "dsNombre": "Hacari",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "344",
    },
    {
        "nmId": 400,
        "dsNombre": "Herran",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "347",
    },
    {
        "nmId": 453,
        "dsNombre": "La Playa",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "398",
    },
    {
        "nmId": 468,
        "dsNombre": "Labateca",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "377",
    },
    {
        "nmId": 485,
        "dsNombre": "Los Patios",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "405",
    },
    {
        "nmId": 487,
        "dsNombre": "Lourdes",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "418",
    },
    {
        "nmId": 553,
        "dsNombre": "Mutiscua",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "480",
    },
    {
        "nmId": 575,
        "dsNombre": "Ocaña",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "498",
    },
    {
        "nmId": 607,
        "dsNombre": "Pamplona",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "518",
    },
    {
        "nmId": 608,
        "dsNombre": "Pamplonita",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "520",
    },
    {
        "nmId": 675,
        "dsNombre": "Puerto Santander",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "553",
    },
    {
        "nmId": 692,
        "dsNombre": "Ragonvalia",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "599",
    },
    {
        "nmId": 737,
        "dsNombre": "Salazar",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "660",
    },
    {
        "nmId": 759,
        "dsNombre": "San Calixto",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "670",
    },
    {
        "nmId": 764,
        "dsNombre": "San Cayetano",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "673",
    },
    {
        "nmId": 841,
        "dsNombre": "Santiago",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "680",
    },
    {
        "nmId": 849,
        "dsNombre": "Sardinata",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "720",
    },
    {
        "nmId": 859,
        "dsNombre": "Silos",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "743",
    },
    {
        "nmId": 924,
        "dsNombre": "Teorama",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "800",
    },
    {
        "nmId": 943,
        "dsNombre": "Toledo",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "820",
    },
    {
        "nmId": 1002,
        "dsNombre": "Villa Caro",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "871",
    },
    {
        "nmId": 1004,
        "dsNombre": "Villa Del Rosario",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "874",
    },
    {
        "nmId": 1139,
        "dsNombre": "EL TARRA",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "250",
    },
    {
        "nmId": 1140,
        "dsNombre": "LA ESPERANZA",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "385",
    },
    {
        "nmId": 1141,
        "dsNombre": "TIBÚ",
        "departamento": {
            "cdIso": "CO-NSA",
            "dsNombre": "Norte de Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "54",
        },
        "cdDane": "810",
    },
    {
        "nmId": 223,
        "dsNombre": "Colon",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "219",
    },
    {
        "nmId": 530,
        "dsNombre": "Mocoa",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "001",
    },
    {
        "nmId": 580,
        "dsNombre": "Orito",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "320",
    },
    {
        "nmId": 655,
        "dsNombre": "Puerto Asis",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "568",
    },
    {
        "nmId": 658,
        "dsNombre": "Puerto Caicedo",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "569",
    },
    {
        "nmId": 663,
        "dsNombre": "Puerto Guzman",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "571",
    },
    {
        "nmId": 772,
        "dsNombre": "San Francisco",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "755",
    },
    {
        "nmId": 842,
        "dsNombre": "Santiago",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "760",
    },
    {
        "nmId": 858,
        "dsNombre": "Sibundoy",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "749",
    },
    {
        "nmId": 985,
        "dsNombre": "Valle Del Guamuez",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "865",
    },
    {
        "nmId": 1005,
        "dsNombre": "Villagarzon",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "885",
    },
    {
        "nmId": 1157,
        "dsNombre": "LEGUÍZAMO",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "573",
    },
    {
        "nmId": 1158,
        "dsNombre": "SAN MIGUEL",
        "departamento": {
            "cdIso": "CO-PUT",
            "dsNombre": "Putumayo",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "86",
        },
        "cdDane": "757",
    },
    {
        "nmId": 67,
        "dsNombre": "Armenia",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "001",
    },
    {
        "nmId": 118,
        "dsNombre": "Buenavista",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "111",
    },
    {
        "nmId": 144,
        "dsNombre": "Calarca",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "130",
    },
    {
        "nmId": 216,
        "dsNombre": "Circasia",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "190",
    },
    {
        "nmId": 238,
        "dsNombre": "Cordoba",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "212",
    },
    {
        "nmId": 318,
        "dsNombre": "Filandia",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "272",
    },
    {
        "nmId": 352,
        "dsNombre": "Genova",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "302",
    },
    {
        "nmId": 457,
        "dsNombre": "La Tebaida",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "401",
    },
    {
        "nmId": 540,
        "dsNombre": "Montenegro",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "470",
    },
    {
        "nmId": 629,
        "dsNombre": "Pijao",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "548",
    },
    {
        "nmId": 688,
        "dsNombre": "Quimbaya",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "594",
    },
    {
        "nmId": 739,
        "dsNombre": "Salento",
        "departamento": {
            "cdIso": "CO-QUI",
            "dsNombre": "Quindío",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "63",
        },
        "cdDane": "690",
    },
    {
        "nmId": 47,
        "dsNombre": "Apia",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "045",
    },
    {
        "nmId": 76,
        "dsNombre": "Balboa",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "075",
    },
    {
        "nmId": 90,
        "dsNombre": "Belen De Umbria",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "088",
    },
    {
        "nmId": 91,
        "dsNombre": "Belen De Umbria",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "088",
    },
    {
        "nmId": 271,
        "dsNombre": "Dosquebradas",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "170",
    },
    {
        "nmId": 388,
        "dsNombre": "Guatica",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "318",
    },
    {
        "nmId": 434,
        "dsNombre": "La Celia",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "383",
    },
    {
        "nmId": 467,
        "dsNombre": "La Virginia",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "400",
    },
    {
        "nmId": 515,
        "dsNombre": "Marsella",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "440",
    },
    {
        "nmId": 528,
        "dsNombre": "Mistrato",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "456",
    },
    {
        "nmId": 624,
        "dsNombre": "Pereira",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "001",
    },
    {
        "nmId": 650,
        "dsNombre": "Pueblo Rico",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "572",
    },
    {
        "nmId": 689,
        "dsNombre": "Quinchia",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "594",
    },
    {
        "nmId": 832,
        "dsNombre": "Santa Rosa De Cabal",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "682",
    },
    {
        "nmId": 846,
        "dsNombre": "Santuario",
        "departamento": {
            "cdIso": "CO-RIS",
            "dsNombre": "Risaralda",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "66",
        },
        "cdDane": "687",
    },
    {
        "nmId": 10,
        "dsNombre": "Aguada",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "013",
    },
    {
        "nmId": 17,
        "dsNombre": "Albania",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "020",
    },
    {
        "nmId": 53,
        "dsNombre": "Aratoca",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "051",
    },
    {
        "nmId": 81,
        "dsNombre": "Barbosa",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "077",
    },
    {
        "nmId": 82,
        "dsNombre": "Barichara",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "079",
    },
    {
        "nmId": 83,
        "dsNombre": "Barrancabermeja",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "081",
    },
    {
        "nmId": 99,
        "dsNombre": "Betulia",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "092",
    },
    {
        "nmId": 107,
        "dsNombre": "Bolivar",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "101",
    },
    {
        "nmId": 115,
        "dsNombre": "Bucaramanga",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "001",
    },
    {
        "nmId": 130,
        "dsNombre": "Cabrera",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "121",
    },
    {
        "nmId": 149,
        "dsNombre": "California",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "132",
    },
    {
        "nmId": 160,
        "dsNombre": "Capitanejo",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "147",
    },
    {
        "nmId": 164,
        "dsNombre": "Carcasi",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "152",
    },
    {
        "nmId": 178,
        "dsNombre": "Cepita",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "160",
    },
    {
        "nmId": 181,
        "dsNombre": "Cerrito",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "162",
    },
    {
        "nmId": 189,
        "dsNombre": "Charala",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "167",
    },
    {
        "nmId": 190,
        "dsNombre": "Charta",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "169",
    },
    {
        "nmId": 194,
        "dsNombre": "Chima",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "176",
    },
    {
        "nmId": 201,
        "dsNombre": "Chipata",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "179",
    },
    {
        "nmId": 215,
        "dsNombre": "Cimitarra",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "190",
    },
    {
        "nmId": 227,
        "dsNombre": "Concepcion",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "207",
    },
    {
        "nmId": 231,
        "dsNombre": "Confines",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "209",
    },
    {
        "nmId": 234,
        "dsNombre": "Contratacion",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "211",
    },
    {
        "nmId": 242,
        "dsNombre": "Coromoro",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "217",
    },
    {
        "nmId": 264,
        "dsNombre": "Curiti",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "229",
    },
    {
        "nmId": 293,
        "dsNombre": "El Guacamayo",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "245",
    },
    {
        "nmId": 300,
        "dsNombre": "El Playon",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "255",
    },
    {
        "nmId": 309,
        "dsNombre": "Encino",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "264",
    },
    {
        "nmId": 310,
        "dsNombre": "Enciso",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "266",
    },
    {
        "nmId": 325,
        "dsNombre": "Floridablanca",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "276",
    },
    {
        "nmId": 343,
        "dsNombre": "Galan",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "296",
    },
    {
        "nmId": 348,
        "dsNombre": "Gambita",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "298",
    },
    {
        "nmId": 359,
        "dsNombre": "Giron",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "307",
    },
    {
        "nmId": 366,
        "dsNombre": "Guaca",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "318",
    },
    {
        "nmId": 373,
        "dsNombre": "Guadalupe",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "320",
    },
    {
        "nmId": 381,
        "dsNombre": "Guapota",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "322",
    },
    {
        "nmId": 389,
        "dsNombre": "Guavata",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "324",
    },
    {
        "nmId": 397,
        "dsNombre": "Hato",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "344",
    },
    {
        "nmId": 425,
        "dsNombre": "Jesus Maria",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "368",
    },
    {
        "nmId": 426,
        "dsNombre": "Jordan",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "370",
    },
    {
        "nmId": 448,
        "dsNombre": "La Paz",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "397",
    },
    {
        "nmId": 470,
        "dsNombre": "Landazuri",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "385",
    },
    {
        "nmId": 471,
        "dsNombre": "Lebrija",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "406",
    },
    {
        "nmId": 486,
        "dsNombre": "Los Santos",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "418",
    },
    {
        "nmId": 490,
        "dsNombre": "Macaravita",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "425",
    },
    {
        "nmId": 499,
        "dsNombre": "Malaga",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "432",
    },
    {
        "nmId": 517,
        "dsNombre": "Matanza",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "444",
    },
    {
        "nmId": 531,
        "dsNombre": "Mogotes",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "464",
    },
    {
        "nmId": 532,
        "dsNombre": "Molagavita",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "468",
    },
    {
        "nmId": 574,
        "dsNombre": "Ocamonte",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "498",
    },
    {
        "nmId": 576,
        "dsNombre": "Oiba",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "500",
    },
    {
        "nmId": 578,
        "dsNombre": "Onzaga",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "502",
    },
    {
        "nmId": 601,
        "dsNombre": "Palmar",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "522",
    },
    {
        "nmId": 603,
        "dsNombre": "Palmas Del Socorro",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "524",
    },
    {
        "nmId": 611,
        "dsNombre": "Paramo",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "533",
    },
    {
        "nmId": 626,
        "dsNombre": "Piedecuesta",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "547",
    },
    {
        "nmId": 630,
        "dsNombre": "Pinchote",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "549",
    },
    {
        "nmId": 653,
        "dsNombre": "Puente Nacional",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "572",
    },
    {
        "nmId": 678,
        "dsNombre": "Puerto Wilches",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "575",
    },
    {
        "nmId": 709,
        "dsNombre": "Rionegro",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "615",
    },
    {
        "nmId": 724,
        "dsNombre": "Sabana De Torres",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "655",
    },
    {
        "nmId": 748,
        "dsNombre": "San Andres",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "669",
    },
    {
        "nmId": 754,
        "dsNombre": "San Benito",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "673",
    },
    {
        "nmId": 755,
        "dsNombre": "San Benito",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "673",
    },
    {
        "nmId": 773,
        "dsNombre": "San Gil",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "679",
    },
    {
        "nmId": 776,
        "dsNombre": "San Joaquin",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "682",
    },
    {
        "nmId": 781,
        "dsNombre": "San Jose De Miranda",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "684",
    },
    {
        "nmId": 799,
        "dsNombre": "San Miguel",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "686",
    },
    {
        "nmId": 816,
        "dsNombre": "San Vicente Chucuri",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "689",
    },
    {
        "nmId": 862,
        "dsNombre": "Simacota",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "745",
    },
    {
        "nmId": 872,
        "dsNombre": "Socorro",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "755",
    },
    {
        "nmId": 886,
        "dsNombre": "Suaita",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "770",
    },
    {
        "nmId": 893,
        "dsNombre": "Sucre",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "773",
    },
    {
        "nmId": 898,
        "dsNombre": "Surata",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "780",
    },
    {
        "nmId": 947,
        "dsNombre": "Tona",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "820",
    },
    {
        "nmId": 986,
        "dsNombre": "Valle De San Jose",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "855",
    },
    {
        "nmId": 991,
        "dsNombre": "Velez",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "861",
    },
    {
        "nmId": 1011,
        "dsNombre": "Villanueva",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "872",
    },
    {
        "nmId": 1034,
        "dsNombre": "Zapatoca",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "895",
    },
    {
        "nmId": 1142,
        "dsNombre": "EL CARMEN DE CHUCURÍ",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "235",
    },
    {
        "nmId": 1143,
        "dsNombre": "EL PEÑÓN",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "250",
    },
    {
        "nmId": 1144,
        "dsNombre": "FLORIÁN",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "271",
    },
    {
        "nmId": 1145,
        "dsNombre": "GÜEPSA",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "327",
    },
    {
        "nmId": 1146,
        "dsNombre": "LA BELLEZA",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "377",
    },
    {
        "nmId": 1147,
        "dsNombre": "PUERTO PARRA",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "573",
    },
    {
        "nmId": 1148,
        "dsNombre": "SANTA BÁRBARA",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "705",
    },
    {
        "nmId": 1149,
        "dsNombre": "SANTA HELENA DEL OPÓN",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "720",
    },
    {
        "nmId": 1150,
        "dsNombre": "VETAS",
        "departamento": {
            "cdIso": "CO-SAN",
            "dsNombre": "Santander",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "68",
        },
        "cdDane": "867",
    },
    {
        "nmId": 648,
        "dsNombre": "Providencia",
        "departamento": {
            "cdIso": "CO-SAP",
            "dsNombre": "San Andrés, Providencia y Santa Catalina",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "88",
        },
        "cdDane": "564",
    },
    {
        "nmId": 747,
        "dsNombre": "San Andres",
        "departamento": {
            "cdIso": "CO-SAP",
            "dsNombre": "San Andrés, Providencia y Santa Catalina",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "88",
        },
        "cdDane": "001",
    },
    {
        "nmId": 119,
        "dsNombre": "Buenavista",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "110",
    },
    {
        "nmId": 139,
        "dsNombre": "Caimito",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "124",
    },
    {
        "nmId": 185,
        "dsNombre": "Chalan",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "230",
    },
    {
        "nmId": 225,
        "dsNombre": "Coloso",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "204",
    },
    {
        "nmId": 243,
        "dsNombre": "Corozal",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "215",
    },
    {
        "nmId": 248,
        "dsNombre": "Coveñas",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "221",
    },
    {
        "nmId": 345,
        "dsNombre": "Galeras",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "235",
    },
    {
        "nmId": 458,
        "dsNombre": "La Union",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "400",
    },
    {
        "nmId": 484,
        "dsNombre": "Los Palmitos",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "418",
    },
    {
        "nmId": 498,
        "dsNombre": "Majagual",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "429",
    },
    {
        "nmId": 547,
        "dsNombre": "Morroa",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "473",
    },
    {
        "nmId": 586,
        "dsNombre": "Ovejas",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "508",
    },
    {
        "nmId": 605,
        "dsNombre": "Palmito",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "523",
    },
    {
        "nmId": 744,
        "dsNombre": "Sampues",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "670",
    },
    {
        "nmId": 756,
        "dsNombre": "San Benito Abad",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "678",
    },
    {
        "nmId": 785,
        "dsNombre": "San Juan De Betulia",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "702",
    },
    {
        "nmId": 795,
        "dsNombre": "San Marcos",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "708",
    },
    {
        "nmId": 801,
        "dsNombre": "San Onofre",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "713",
    },
    {
        "nmId": 805,
        "dsNombre": "San Pedro",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "717",
    },
    {
        "nmId": 865,
        "dsNombre": "San Luis De Since",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "742",
    },
    {
        "nmId": 866,
        "dsNombre": "Sincelejo",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "001",
    },
    {
        "nmId": 894,
        "dsNombre": "Sucre",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "771",
    },
    {
        "nmId": 945,
        "dsNombre": "Santiago De Tolu",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "820",
    },
    {
        "nmId": 946,
        "dsNombre": "Toluviejo",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "823",
    },
    {
        "nmId": 1151,
        "dsNombre": "EL ROBLE",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "233",
    },
    {
        "nmId": 1152,
        "dsNombre": "GUARANDA",
        "departamento": {
            "cdIso": "CO-SUC",
            "dsNombre": "Sucre",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "70",
        },
        "cdDane": "265",
    },
    {
        "nmId": 25,
        "dsNombre": "Alpujarra",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "024",
    },
    {
        "nmId": 28,
        "dsNombre": "Alvarado",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "026",
    },
    {
        "nmId": 32,
        "dsNombre": "Ambalema",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "030",
    },
    {
        "nmId": 45,
        "dsNombre": "Anzoategui",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "043",
    },
    {
        "nmId": 68,
        "dsNombre": "Armero",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "055",
    },
    {
        "nmId": 70,
        "dsNombre": "Ataco",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "067",
    },
    {
        "nmId": 140,
        "dsNombre": "Cajamarca",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "124",
    },
    {
        "nmId": 166,
        "dsNombre": "Carmen De Apicala",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "148",
    },
    {
        "nmId": 174,
        "dsNombre": "Casabianca",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "152",
    },
    {
        "nmId": 187,
        "dsNombre": "Chaparral",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "168",
    },
    {
        "nmId": 220,
        "dsNombre": "Coello",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "200",
    },
    {
        "nmId": 249,
        "dsNombre": "Coyaima",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "217",
    },
    {
        "nmId": 262,
        "dsNombre": "Cunday",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "226",
    },
    {
        "nmId": 268,
        "dsNombre": "Dolores",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "236",
    },
    {
        "nmId": 314,
        "dsNombre": "Espinal",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "268",
    },
    {
        "nmId": 316,
        "dsNombre": "Falan",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "270",
    },
    {
        "nmId": 320,
        "dsNombre": "Flandes",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "275",
    },
    {
        "nmId": 331,
        "dsNombre": "Fresno",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "283",
    },
    {
        "nmId": 379,
        "dsNombre": "Guamo",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "319",
    },
    {
        "nmId": 401,
        "dsNombre": "Herveo",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "347",
    },
    {
        "nmId": 404,
        "dsNombre": "Honda",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "349",
    },
    {
        "nmId": 405,
        "dsNombre": "Ibague",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "001",
    },
    {
        "nmId": 406,
        "dsNombre": "Icononzo",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "352",
    },
    {
        "nmId": 474,
        "dsNombre": "Lerida",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "408",
    },
    {
        "nmId": 476,
        "dsNombre": "Libano",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "411",
    },
    {
        "nmId": 512,
        "dsNombre": "Mariquita",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "443",
    },
    {
        "nmId": 521,
        "dsNombre": "Melgar",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "449",
    },
    {
        "nmId": 558,
        "dsNombre": "Natagaima",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "483",
    },
    {
        "nmId": 582,
        "dsNombre": "Ortega",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "504",
    },
    {
        "nmId": 627,
        "dsNombre": "Piedras",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "547",
    },
    {
        "nmId": 637,
        "dsNombre": "Planadas",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "555",
    },
    {
        "nmId": 647,
        "dsNombre": "Prado",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "563",
    },
    {
        "nmId": 683,
        "dsNombre": "Purificacion",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "585",
    },
    {
        "nmId": 705,
        "dsNombre": "Rioblanco",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "616",
    },
    {
        "nmId": 718,
        "dsNombre": "Roncesvalles",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "622",
    },
    {
        "nmId": 721,
        "dsNombre": "Rovira",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "624",
    },
    {
        "nmId": 738,
        "dsNombre": "Saldaña",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "671",
    },
    {
        "nmId": 753,
        "dsNombre": "San Antonio",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "675",
    },
    {
        "nmId": 793,
        "dsNombre": "San Luis",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "678",
    },
    {
        "nmId": 825,
        "dsNombre": "Santa Isabel",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "686",
    },
    {
        "nmId": 888,
        "dsNombre": "Suarez",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "770",
    },
    {
        "nmId": 984,
        "dsNombre": "Valle De San Juan",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "854",
    },
    {
        "nmId": 992,
        "dsNombre": "Venadillo",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "861",
    },
    {
        "nmId": 1007,
        "dsNombre": "Villahermosa",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "870",
    },
    {
        "nmId": 1014,
        "dsNombre": "Villarrica",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "873",
    },
    {
        "nmId": 1153,
        "dsNombre": "MURILLO",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "461",
    },
    {
        "nmId": 1154,
        "dsNombre": "PALOCABILDO",
        "departamento": {
            "cdIso": "CO-TOL",
            "dsNombre": "Tolima",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "73",
        },
        "cdDane": "520",
    },
    {
        "nmId": 19,
        "dsNombre": "Alcala",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "020",
    },
    {
        "nmId": 35,
        "dsNombre": "Andalucia",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "036",
    },
    {
        "nmId": 42,
        "dsNombre": "Ansermanuevo",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "041",
    },
    {
        "nmId": 63,
        "dsNombre": "Argelia",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "054",
    },
    {
        "nmId": 106,
        "dsNombre": "Bolivar",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "100",
    },
    {
        "nmId": 117,
        "dsNombre": "Buenaventura",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "109",
    },
    {
        "nmId": 125,
        "dsNombre": "Bugalagrande",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "113",
    },
    {
        "nmId": 138,
        "dsNombre": "Caicedonia",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "122",
    },
    {
        "nmId": 148,
        "dsNombre": "Cali",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "001",
    },
    {
        "nmId": 150,
        "dsNombre": "Calima",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "126",
    },
    {
        "nmId": 157,
        "dsNombre": "Candelaria",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "130",
    },
    {
        "nmId": 172,
        "dsNombre": "Cartago",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "147",
    },
    {
        "nmId": 267,
        "dsNombre": "Dagua",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "233",
    },
    {
        "nmId": 275,
        "dsNombre": "El Aguila",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "243",
    },
    {
        "nmId": 278,
        "dsNombre": "El Cairo",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "246",
    },
    {
        "nmId": 284,
        "dsNombre": "El Cerrito",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "248",
    },
    {
        "nmId": 290,
        "dsNombre": "El Dovio",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "250",
    },
    {
        "nmId": 324,
        "dsNombre": "Florida",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "275",
    },
    {
        "nmId": 355,
        "dsNombre": "Ginebra",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "306",
    },
    {
        "nmId": 368,
        "dsNombre": "Guacari",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "318",
    },
    {
        "nmId": 419,
        "dsNombre": "Jamundi",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "364",
    },
    {
        "nmId": 437,
        "dsNombre": "La Cumbre",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "377",
    },
    {
        "nmId": 459,
        "dsNombre": "La Union",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "400",
    },
    {
        "nmId": 466,
        "dsNombre": "La Victoria",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "403",
    },
    {
        "nmId": 573,
        "dsNombre": "Obando",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "497",
    },
    {
        "nmId": 604,
        "dsNombre": "Palmira",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "520",
    },
    {
        "nmId": 646,
        "dsNombre": "Pradera",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "563",
    },
    {
        "nmId": 700,
        "dsNombre": "Restrepo",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "606",
    },
    {
        "nmId": 706,
        "dsNombre": "Riofrio",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "616",
    },
    {
        "nmId": 717,
        "dsNombre": "Roldanillo",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "622",
    },
    {
        "nmId": 806,
        "dsNombre": "San Pedro",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "670",
    },
    {
        "nmId": 855,
        "dsNombre": "Sevilla",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "736",
    },
    {
        "nmId": 951,
        "dsNombre": "Toro",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "823",
    },
    {
        "nmId": 955,
        "dsNombre": "Trujillo",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "828",
    },
    {
        "nmId": 957,
        "dsNombre": "Tulua",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "834",
    },
    {
        "nmId": 971,
        "dsNombre": "Ulloa",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "845",
    },
    {
        "nmId": 997,
        "dsNombre": "Versalles",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "863",
    },
    {
        "nmId": 1000,
        "dsNombre": "Vijes",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "869",
    },
    {
        "nmId": 1031,
        "dsNombre": "Yotoco",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "890",
    },
    {
        "nmId": 1032,
        "dsNombre": "Yumbo",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "892",
    },
    {
        "nmId": 1036,
        "dsNombre": "Zarzal",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "895",
    },
    {
        "nmId": 1155,
        "dsNombre": "GUADALAJARA DE BUGA",
        "departamento": {
            "cdIso": "CO-VAC",
            "dsNombre": "Valle del Cauca",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "76",
        },
        "cdDane": "111",
    },
    {
        "nmId": 173,
        "dsNombre": "Caruru",
        "departamento": {
            "cdIso": "CO-VAU",
            "dsNombre": "Vaupés",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "97",
        },
        "cdDane": "161",
    },
    {
        "nmId": 529,
        "dsNombre": "Mitu",
        "departamento": {
            "cdIso": "CO-VAU",
            "dsNombre": "Vaupés",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "97",
        },
        "cdDane": "001",
    },
    {
        "nmId": 1027,
        "dsNombre": "Yavarate",
        "departamento": {
            "cdIso": "CO-VAU",
            "dsNombre": "Vaupés",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "97",
        },
        "cdDane": "889",
    },
    {
        "nmId": 1173,
        "dsNombre": "PACOA",
        "departamento": {
            "cdIso": "CO-VAU",
            "dsNombre": "Vaupés",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "97",
        },
        "cdDane": "511",
    },
    {
        "nmId": 1174,
        "dsNombre": "TARAIRA",
        "departamento": {
            "cdIso": "CO-VAU",
            "dsNombre": "Vaupés",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "97",
        },
        "cdDane": "666",
    },
    {
        "nmId": 1175,
        "dsNombre": "PAPUNAUA",
        "departamento": {
            "cdIso": "CO-VAU",
            "dsNombre": "Vaupés",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "97",
        },
        "cdDane": "777",
    },
    {
        "nmId": 454,
        "dsNombre": "La Primavera",
        "departamento": {
            "cdIso": "CO-VID",
            "dsNombre": "Vichada",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "99",
        },
        "cdDane": "524",
    },
    {
        "nmId": 659,
        "dsNombre": "Puerto Carreño",
        "departamento": {
            "cdIso": "CO-VID",
            "dsNombre": "Vichada",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "99",
        },
        "cdDane": "001",
    },
    {
        "nmId": 1176,
        "dsNombre": "SANTA ROSALÍA",
        "departamento": {
            "cdIso": "CO-VID",
            "dsNombre": "Vichada",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "99",
        },
        "cdDane": "624",
    },
    {
        "nmId": 1177,
        "dsNombre": "CUMARIBO",
        "departamento": {
            "cdIso": "CO-VID",
            "dsNombre": "Vichada",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "99",
        },
        "cdDane": "773",
    },
    {
        "nmId": 2000,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AF",
                "dsNombre": "Afganistan",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2001,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AL",
                "dsNombre": "Albania",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2002,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "DE",
                "dsNombre": "Alemania",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2003,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AD",
                "dsNombre": "Andorra",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2004,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AO",
                "dsNombre": "Angola",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2005,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AI",
                "dsNombre": "Anguila",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2006,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AQ",
                "dsNombre": "Antártida",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2007,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AG",
                "dsNombre": "Antigua y Barbuda",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2008,
        "dsNombre": "Antillas Neerlandesas",
        "departamento": {
            "cdIso": "AN",
            "dsNombre": "",
            "pais": {
                "cdIso": "AN",
                "dsNombre": "Antillas Neerlandesas",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2009,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SA",
                "dsNombre": "Arabia Saudita",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2010,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "DZ",
                "dsNombre": "Argelia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2011,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AR",
                "dsNombre": "Argentina",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2012,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AM",
                "dsNombre": "Armenia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2013,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AW",
                "dsNombre": "Aruba",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2014,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MK",
                "dsNombre": "ARY Macedonia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2015,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AU",
                "dsNombre": "Australia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2016,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AT",
                "dsNombre": "Austria",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2017,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AZ",
                "dsNombre": "Azerbaiyán",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2018,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BS",
                "dsNombre": "Bahamas",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2019,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BD",
                "dsNombre": "Bangladesh",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2020,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BB",
                "dsNombre": "Barbados",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2021,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BE",
                "dsNombre": "Bélgica",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2022,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BZ",
                "dsNombre": "Belice",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2023,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BM",
                "dsNombre": "Bermudas",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2024,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BY",
                "dsNombre": "Bielorrusia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2025,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BO",
                "dsNombre": "Bolivia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2026,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BQ",
                "dsNombre": "Bonaire",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2027,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BA",
                "dsNombre": "Bosnia y Herzegovina",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2028,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BW",
                "dsNombre": "Botsuana",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2029,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BR",
                "dsNombre": "Brasil",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2030,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BG",
                "dsNombre": "Bulgaria",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2031,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BF",
                "dsNombre": "Burkina Faso",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2032,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BI",
                "dsNombre": "Burundi",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2033,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CV",
                "dsNombre": "Cabo Verde",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2034,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KH",
                "dsNombre": "Camboya",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2035,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CM",
                "dsNombre": "Camerún",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2036,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CA",
                "dsNombre": "Canadá",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2037,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TD",
                "dsNombre": "Chad",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2038,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CL",
                "dsNombre": "Chile",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2039,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CN",
                "dsNombre": "China",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2040,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CY",
                "dsNombre": "Chipre",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2041,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "VA",
                "dsNombre": "Ciudad del Vaticano",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2042,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CO",
                "dsNombre": "Colombia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2043,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KM",
                "dsNombre": "Comoras",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2044,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KP",
                "dsNombre": "Corea del Norte",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2045,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KR",
                "dsNombre": "Corea del Sur",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2046,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CI",
                "dsNombre": "Costa de Marfil",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2047,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CR",
                "dsNombre": "Costa Rica",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2048,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "HR",
                "dsNombre": "Croacia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2049,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CU",
                "dsNombre": "Cuba",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2050,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "DK",
                "dsNombre": "Dinamarca",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2051,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "DM",
                "dsNombre": "Dominica",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2052,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "EC",
                "dsNombre": "Ecuador",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2053,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "EG",
                "dsNombre": "Egipto",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2054,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SV",
                "dsNombre": "El Salvador",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2055,
        "dsNombre": "Abu Dhabi",
        "departamento": {
            "cdIso": "AE-AZ",
            "dsNombre": "Abu Dhabi",
            "pais": {
                "cdIso": "AE",
                "dsNombre": "Emiratos Árabes Unidos",
            },
            "cdDane": "AE-AZ",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2056,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ER",
                "dsNombre": "Eritrea",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2057,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SK",
                "dsNombre": "Eslovaquia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2058,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SI",
                "dsNombre": "Eslovenia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2059,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ES",
                "dsNombre": "España",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2060,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "EA",
                "dsNombre": "España (Ceuta y Melilla)",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2061,
        "dsNombre": "Washington",
        "departamento": {
            "cdIso": "US-WA",
            "dsNombre": "Washington",
            "pais": {
                "cdIso": "US",
                "dsNombre": "Estados Unidos",
            },
            "cdDane": "US-WA",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2180,
        "dsNombre": "Boston",
        "departamento": {
            "cdIso": "US-MA",
            "dsNombre": "Massachusetts",
            "pais": {
                "cdIso": "US",
                "dsNombre": "Estados Unidos",
            },
            "cdDane": "US-MA",
        },
        "cdDane": "001",
    },
    {
        "nmId": 1179,
        "dsNombre": "Miami",
        "departamento": {
            "cdIso": "US-FL",
            "dsNombre": "Florida",
            "pais": {
                "cdIso": "US",
                "dsNombre": "Estados Unidos",
            },
            "cdDane": "US-WA",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2062,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "EE",
                "dsNombre": "Estonia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2063,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ET",
                "dsNombre": "Etiopía",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2064,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PH",
                "dsNombre": "Filipinas",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2065,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "FI",
                "dsNombre": "Finlandia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2066,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "FJ",
                "dsNombre": "Fiyi",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2067,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "FR",
                "dsNombre": "Francia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2068,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GM",
                "dsNombre": "Gambia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2069,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GE",
                "dsNombre": "Georgia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2070,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GH",
                "dsNombre": "Ghana",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2071,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GI",
                "dsNombre": "Gibraltar",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2072,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GD",
                "dsNombre": "Granada",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2073,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GR",
                "dsNombre": "Grecia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2074,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GL",
                "dsNombre": "Groenlandia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2075,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GP",
                "dsNombre": "Guadalupe",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2076,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GU",
                "dsNombre": "Guam",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2077,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GT",
                "dsNombre": "Guatemala",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2078,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GF",
                "dsNombre": "Guayana Francesa",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2079,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GG",
                "dsNombre": "Guernsey",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2080,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GN",
                "dsNombre": "Guinea",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2081,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GQ",
                "dsNombre": "Guinea Ecuatorial",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2082,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GW",
                "dsNombre": "Guinea-Bissau",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2083,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GY",
                "dsNombre": "Guyana",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2084,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "HT",
                "dsNombre": "Haití",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2085,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "HN",
                "dsNombre": "Honduras",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2086,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "HK",
                "dsNombre": "Hong Kong",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2087,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "HU",
                "dsNombre": "Hungría",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2088,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IN",
                "dsNombre": "India",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2089,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ID",
                "dsNombre": "Indonesia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2090,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IR",
                "dsNombre": "Irán",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2091,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IQ",
                "dsNombre": "Iraq",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2092,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IE",
                "dsNombre": "Irlanda",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2093,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "BV",
                "dsNombre": "Isla Bouvet",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2094,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IM",
                "dsNombre": "Isla de Man",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2095,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CX",
                "dsNombre": "Isla de Navidad",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2096,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IS",
                "dsNombre": "Islandia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2097,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KY",
                "dsNombre": "Islas Caiman",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2098,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CC",
                "dsNombre": "Islas Cocos",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2099,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CK",
                "dsNombre": "Islas Cook",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2100,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "FO",
                "dsNombre": "Islas Feroe",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2101,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GS",
                "dsNombre": "Islas Georgias del Sur y Sandwich del Sur",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2102,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "HM",
                "dsNombre": "Islas Heard y McDonald",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2103,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "FK",
                "dsNombre": "Islas Malvinas",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2104,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MP",
                "dsNombre": "Islas Marianas del Norte",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2105,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MH",
                "dsNombre": "Islas Marshall",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2106,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PN",
                "dsNombre": "Islas Pitcairn",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2107,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SB",
                "dsNombre": "Islas Salomón",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2108,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TC",
                "dsNombre": "Islas Turcas y Caicos",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2109,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "UM",
                "dsNombre": "Islas ultramarinas de Estados Unidos",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2110,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "VG",
                "dsNombre": "Islas Virgenes Britá¡nicas",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2111,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "VI",
                "dsNombre": "Islas Virgenes Estadounidenses",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2112,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IL",
                "dsNombre": "Israel",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2113,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IT",
                "dsNombre": "Italia J",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2114,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "JM",
                "dsNombre": "Jamaica",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2115,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "JP",
                "dsNombre": "Japón",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2116,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "JE",
                "dsNombre": "Jersey",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2117,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "JO",
                "dsNombre": "Jordania K",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2118,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KZ",
                "dsNombre": "Kazajistán",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2119,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KE",
                "dsNombre": "Kenia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2120,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KG",
                "dsNombre": "Kirguistan",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2121,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KI",
                "dsNombre": "Kiribati",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2122,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KW",
                "dsNombre": "Kuwait L",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2123,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LA",
                "dsNombre": "Laos",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2124,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LS",
                "dsNombre": "Lesoto",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2125,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LV",
                "dsNombre": "Letonia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2126,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LB",
                "dsNombre": "Libano",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2127,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LR",
                "dsNombre": "Liberia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2128,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LY",
                "dsNombre": "Libia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2129,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LI",
                "dsNombre": "Liechtenstein",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2130,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LT",
                "dsNombre": "Lituania",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2131,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LU",
                "dsNombre": "Luxemburgo M",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2132,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MO",
                "dsNombre": "Macao",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2133,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MG",
                "dsNombre": "Madagascar",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2134,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MY",
                "dsNombre": "Malasia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2135,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MW",
                "dsNombre": "Malawi",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2136,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MV",
                "dsNombre": "Maldivas",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2137,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MT",
                "dsNombre": "Malta",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2138,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MA",
                "dsNombre": "Marruecos",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2139,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MQ",
                "dsNombre": "Martinica",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2140,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MU",
                "dsNombre": "Mauricio",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2141,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MR",
                "dsNombre": "Mauritania",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2142,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "YT",
                "dsNombre": "Mayotte",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2143,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MX",
                "dsNombre": "México",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2144,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "FM",
                "dsNombre": "Micronesia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2145,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MD",
                "dsNombre": "Moldavia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2146,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MC",
                "dsNombre": "Mónaco",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2147,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MN",
                "dsNombre": "Mongolia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2148,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ME",
                "dsNombre": "Montenegro",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2149,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MS",
                "dsNombre": "Montserrat",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2150,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MZ",
                "dsNombre": "Mozambique",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2151,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "MM",
                "dsNombre": "Myanmar",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2152,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NA",
                "dsNombre": "Namibia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2153,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NR",
                "dsNombre": "Nauru",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2154,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NP",
                "dsNombre": "Nepal",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2155,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NI",
                "dsNombre": "Nicaragua",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2156,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NG",
                "dsNombre": "Nigeria",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2157,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NU",
                "dsNombre": "Niue",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2158,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NF",
                "dsNombre": "Norfolk",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2159,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NO",
                "dsNombre": "Noruega",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2160,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NC",
                "dsNombre": "Nueva Caledonia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2161,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NZ",
                "dsNombre": "Nueva Zelanda",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2162,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "NL",
                "dsNombre": "paises Bajos",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2163,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PK",
                "dsNombre": "Pakistan",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2164,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PW",
                "dsNombre": "Palaos",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2165,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PS",
                "dsNombre": "Palestina (ANP)",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2166,
        "dsNombre": "Ciudad de Panamá",
        "departamento": {
            "cdIso": "PA-8",
            "dsNombre": "",
            "pais": {
                "cdIso": "PA",
                "dsNombre": "Panamá",
            },
            "cdDane": "PA-8",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2167,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PY",
                "dsNombre": "Paraguay",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2168,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PE",
                "dsNombre": "Perú",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2169,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PF",
                "dsNombre": "Polinesia Francesa",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2170,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PL",
                "dsNombre": "Polonia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2171,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PT",
                "dsNombre": "Portugal",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2172,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "PR",
                "dsNombre": "Puerto Rico",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2173,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "QA",
                "dsNombre": "Qatar",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2174,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "GB",
                "dsNombre": "Reino Unido",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2175,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CF",
                "dsNombre": "Republica Centroafricana",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2176,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CZ",
                "dsNombre": "Republica Checa",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2177,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CG",
                "dsNombre": "Republica del Congo",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2178,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CD",
                "dsNombre": "Republica Democratica del Congo",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2179,
        "dsNombre": "Distrito Nacional (Santo Domingo)",
        "departamento": {
            "cdIso": "DO-01",
            "dsNombre": "Distrito Nacional (Santo Domingo)",
            "pais": {
                "cdIso": "DO",
                "dsNombre": "Republica Dominicana",
            },
            "cdDane": "DO-01",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2180,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "RW",
                "dsNombre": "Ruanda",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2181,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "RO",
                "dsNombre": "Rumania",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2182,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "RU",
                "dsNombre": "Rusia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2183,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "EH",
                "dsNombre": "Sahara Occidental",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2184,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "WS",
                "dsNombre": "Samoa",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2185,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "AS",
                "dsNombre": "Samoa Americana",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2186,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "KN",
                "dsNombre": "San Cristobal y Nieves",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2187,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SM",
                "dsNombre": "San Marino",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2188,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "VC",
                "dsNombre": "San Vicente y las Granadinas",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2189,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SH",
                "dsNombre": "Santa Helena",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2190,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LC",
                "dsNombre": "Santa Lucía",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2191,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ST",
                "dsNombre": "Santo Tomás y Príncipe",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2192,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SN",
                "dsNombre": "Senegal",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2193,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "RS",
                "dsNombre": "Serbia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2194,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SC",
                "dsNombre": "Seychelles",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2195,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SL",
                "dsNombre": "Sierra Leona",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2196,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SG",
                "dsNombre": "Singapur",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2197,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SY",
                "dsNombre": "Siria",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2198,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SO",
                "dsNombre": "Somalia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2199,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "LK",
                "dsNombre": "Sri Lanka",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2200,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SZ",
                "dsNombre": "Suazilandia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2201,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ZA",
                "dsNombre": "Sudafrica",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2202,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SD",
                "dsNombre": "Sudan",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2203,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SS",
                "dsNombre": "Sudan del Sur",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2204,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SE",
                "dsNombre": "Suecia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2205,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "CH",
                "dsNombre": "Suiza",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2206,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SR",
                "dsNombre": "Surinam",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2207,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "SJ",
                "dsNombre": "Svalbard y Jan Mayen",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2208,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TH",
                "dsNombre": "Tailandia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2209,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TW",
                "dsNombre": "Taiwan",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2210,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TZ",
                "dsNombre": "Tanzania",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2211,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TJ",
                "dsNombre": "Tayikista¡n",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2212,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "IO",
                "dsNombre": "Territorio Britanico del Oceano indico",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2213,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TF",
                "dsNombre": "Territorios Australes Franceses",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2214,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TL",
                "dsNombre": "Timor Oriental",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2215,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TG",
                "dsNombre": "Togo",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2216,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TK",
                "dsNombre": "Tokelau",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2217,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TO",
                "dsNombre": "Tonga",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2218,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TT",
                "dsNombre": "Trinidad y Tobago",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2219,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TN",
                "dsNombre": "Tunez",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2220,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TM",
                "dsNombre": "Turkmenistan",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2221,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TR",
                "dsNombre": "Turquia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2222,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "TV",
                "dsNombre": "Tuvalu",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2223,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "UA",
                "dsNombre": "Ucrania",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2224,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "UG",
                "dsNombre": "Uganda",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2225,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "UY",
                "dsNombre": "Uruguay",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2226,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "UZ",
                "dsNombre": "Uzbekistan",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2227,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "VU",
                "dsNombre": "Vanuatu",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2228,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "VE",
                "dsNombre": "Venezuela",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2229,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "VN",
                "dsNombre": "Vietnam",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2230,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "WF",
                "dsNombre": "Wallis y Futuna",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2231,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "YE",
                "dsNombre": "Yemen",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2232,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "DJ",
                "dsNombre": "Yibuti",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2233,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ZM",
                "dsNombre": "Zambia",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2234,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "ZW",
                "dsNombre": "Zimbabue",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 2235,
        "dsNombre": "Ciudad exportacion",
        "departamento": {
            "cdIso": "CO-ANT",
            "dsNombre": "",
            "pais": {
                "cdIso": "US",
                "dsNombre": "Estados Unidos",
            },
            "cdDane": "05",
        },
        "cdDane": "001",
    },
    {
        "nmId": 1180,
        "dsNombre": "Deerfield Beach",
        "departamento": {
            "cdIso": "US-FL",
            "dsNombre": "Florida",
            "pais": {
                "cdIso": "US",
                "dsNombre": "Estados Unidos",
            },
            "cdDane": "US-WA",
        },
        "cdDane": "001",
    }
];

export default Ciudades;
