export const Socio = [
  {
    isNoView: 'true',
    pathname: '/Home',
    principal: 'true',
  },
  {
    pathname: '/recargar_saldo',
    isNoView: 'true',
  },
  {
    pathname: '/historial-transacciones',
    isNoView: 'true',
  },
  {
    pathname: '/historialcaja',
    isNoView: 'true',
  },
  {
    pathname: '/aliados',
    isNoView: 'true',
  },
];
