import React from 'react';
import { connect } from 'react-redux';
import TablaAliados from 'components/organisms/TablaAliados';
import DetalleAliado from 'components/templates/DetalleAliado';
import useModal from 'hooks/useModal';

function Aliados({ userAttributes }) {
  const [modalProps, openModal, closeModal] = useModal();
  return (
    <>
      <DetalleAliado {...modalProps} closeModal={closeModal} emisor={userAttributes} />
      <TablaAliados openDetalles={openModal} />
    </>
  );
}

const mapStateToProps = (state) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Aliados);
