import { serviceRoutes, post, getHeader, get } from "./index";

export const guardarCotizacion = async (body) =>
  await post(serviceRoutes.guardarCotizacion, body);

export const consultarCotizaciones = async ({
  emisor,
  estado,
  adquirente,
  identificacion,
  fechacreacion,
  fefin,
}) =>
  await get(
    serviceRoutes.consultarCotizaciones,
    `?emisor=${emisor}&estado=${estado}&adquirente=${adquirente}&identificacion=${identificacion}&fechacreacion=${fechacreacion}&fefin=${fefin}`
  );

export const facturarCotizacion = async (body) =>
  await post(serviceRoutes.facturarCotizacion, body);

export const generarPdfCotizacion = async ({ cotizacionid }) =>
  await fetch(
    `${serviceRoutes.generarPdfCotizacion}/?cotizacionid=${cotizacionid}`,
    {
      method: "GET",
      headers: await getHeader(),
    }
  )
    .then((res) => res.blob())
    .catch((err) => err);
