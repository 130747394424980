import React, { useMemo, useState } from 'react';
import { Paper } from './styles';
import Typography from 'components/atoms/Typography';
import Radio from 'components/molecules/Radio';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import TablaCargosDescuentos from './TablaCargosDescuentos';
import useCargoDescuento from 'hooks/useCargosDescuentos';

function validarCargoDescuento(cargosDescuentos = [], cargoDescuento) {
  const { valorBase, valor } = cargoDescuento;
  const descuentoTotalAnterior = cargosDescuentos.reduce((suma, { valor }) => valor + suma, 0);
  const descuentoTotal = parseFloat(valor) + descuentoTotalAnterior;
  return descuentoTotal <= valorBase;
}

function FormularioDescuento({
  className,
  valorBase,
  addCargoDescuento,
  cargosDescuentos,
  removeCargoDescuento,
}) {
  const [tipoDescuento, setTipoDescuento] = useState('valor');
  const { cargoDescuento, handleChange, limpiar } = useCargoDescuento(false, valorBase);

  const tipoDescuentoOpuesto = useMemo(() => {
    return tipoDescuento === 'valor' ? 'porcentaje' : 'valor';
  }, [tipoDescuento]);

  const handleChangeTipo = () => {
    setTipoDescuento(tipoDescuentoOpuesto);
    limpiar();
  };

  const agregarDescuento = () => {
    const isValido = validarCargoDescuento(cargosDescuentos, cargoDescuento);
    if (isValido) {
      addCargoDescuento(cargoDescuento);
      limpiar();
    }
  };

  return (
    <Paper className={className}>
      <div className='form-row col-12'>
        <div className='form-group col-12'>
          <Typography>Agregar descuentos</Typography>
        </div>
      </div>
      <div className='form-row col-12'>
        <div className='form-group col-12 d-flex justify-content-center'>
          <Radio
            name='codigoDescuento'
            onChange={handleChange}
            value={cargoDescuento.codigoDescuento}
            options={[
              { label: 'Descuento NO Condicionado', value: 'DESCUENTO_NO_CONDICIONADO' },
              { label: 'Descuento Condicionado', value: 'DESCUENTO_CONDICIONADO' },
            ]}
          />
        </div>
      </div>
      <div className='form-row col-12'>
        <div className='form-group col-4'>
          <Typography>Descuento por {tipoDescuento}</Typography>
        </div>
        <div className='form-group col-4'>
          <Input
            name={tipoDescuento}
            onChange={handleChange}
            validacion='only-num-decimals'
            value={cargoDescuento[tipoDescuento]}
            label={`Ingrese el ${tipoDescuento} de descuento`}
          />
        </div>
        <div className='form-group col-4'>
          <Button variant='text' onClick={handleChangeTipo}>
            Cambiar a descuento por {tipoDescuentoOpuesto}
          </Button>
        </div>
      </div>
      <div className='form-row col-12'>
        <div className='form-group col-12 d-flex justify-content-center'>
          <Button disabled={!Boolean(valorBase)} onClick={agregarDescuento}>
            Agregar descuento
          </Button>
        </div>
      </div>
      <TablaCargosDescuentos
        className='form-row col-12'
        cargosDescuentos={cargosDescuentos}
        deleteItem={removeCargoDescuento}
      />
    </Paper>
  );
}

export default FormularioDescuento;
