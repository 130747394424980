import React, { useCallback, useEffect, useState } from 'react';
import Table from 'components/molecules/Table';
import { consultarHistoricoTransaccionRecarga as consultarTransacciones } from 'network/Api/TransaccionRecarga';
import { moneyFormat } from 'utils/TextFormattingUtils';
import { dateString, DATETIME_FORMAT } from 'utils/DateUtils';

function traducirEstado(estado) {
  switch (estado) {
    case 'APPROVED':
      return 'APROBADO';
    case 'REJECTED':
      return 'RECHAZADO';
    default:
      return 'PENDIENTE';
  }
}

function TablaTransacciones({ inicio, fin, emisor }) {
  const [detalles, setDetalles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (emisor) consultar();
  }, [emisor, inicio, fin]);

  const consultar = useCallback(async () => {
    setLoading(true);
    const { data } = await consultarTransacciones({
      identificacion: emisor,
      inicio: inicio + ' 00:00:00',
      fin: fin + ' 23:59:59',
    });
    setDetalles(
      data.map(({ nmValor, date, status, ...rest }) => ({
        ...rest,
        nmValor: moneyFormat(nmValor),
        date: dateString(date).format(DATETIME_FORMAT),
        status: traducirEstado(status),
      })),
    );
    setLoading(false);
  }, [inicio, fin, emisor]);

  return (
    <Table
      withPagination
      headers={[
        { label: 'Referencia' },
        { label: 'Fecha' },
        { label: 'Valor' },
        { label: 'Estado' },
        { label: 'Mensaje' },
      ]}
      keys={['reference', 'date', 'nmValor', 'status', 'message']}
      detalles={detalles}
      loading={loading}
    />
  );
}

export default TablaTransacciones;
