import * as React from "react";
import Icon from "components/menu/Icon";
import RecargaSaldoIcon from "@material-ui/icons/AddShoppingCart";
import AyudasIcon from "@material-ui/icons/HelpOutlineRounded";
import UtilidadesIcon from "static/images/utilidades.png";

const getMenu = (items) => ({
  subMenu: true,
  title: "Utilidades",
  icon: <Icon src={UtilidadesIcon} alt="Utilidades" />,
  items,
});

const Recargas = {
  title: "Recargas",
  icon: <RecargaSaldoIcon />,
  pathname: "/recargar_saldo",
  isNotView: "true",
};

export const Utilidades = getMenu([
  {
    title: "Ayudas",
    icon: <AyudasIcon />,
    pathname: "/ayudas",
    isNotView: "true",
  },
  Recargas,
]);

export const UtilidadesSinFirma = getMenu([Recargas]);
