import React, { useEffect, useMemo } from 'react';
import TipoIdentificacion from 'static/enums/tiposIdentificacion';
import Select from 'components/molecules/Select';
import Input from 'components/Inputs/Input';
import { codigosPais } from 'static/enums/CodigosPais';
import useFormularioPersonaEmisor from 'hooks/useFormularioPersonaEmisor';

function FormularioPersonaRegistroEmisor({
  defaultPersona,
  validarLongitudMinima,
  setData,
  errores,
}) {
  const { persona, handleChange, disableApellidos, setPersona } =
    useFormularioPersonaEmisor(defaultPersona);

  useEffect(() => {
    setData((d) => ({ ...d, ...persona }));
  }, [persona]);

  const tipoIdentificacionOptions = useMemo(
    () =>
      TipoIdentificacion.map((ti) => ({
        label: ti.nombre,
        value: ti.tipoIdentificacionAdquiriente,
      })),
    [],
  );

  return (
    <div className='nd-body'>
      <div className='nd-body-item'>
        <div className='nd-body-item-heading'>
          <label className='nd-body-item-label'>Identificación</label>
          <div className='nd-body-item-description'></div>
        </div>
        <div className='nd-body-item-content'>
          <div>
            <label>Tipo de identificación</label>
            <div>
              <Select
                antd
                placeholder='Tipo de identificación'
                value={persona.cdTipoIdentificacion}
                onChange={(e) => setPersona({ cdTipoIdentificacion: e })}
                options={tipoIdentificacionOptions}
              />
            </div>
            <label className='nd-label-error'>
              {errores.cdTipoIdentificacion && <span>Este campo es requerido</span>}
            </label>
          </div>
          <div>
            <label># de Identificación</label>
            <Input
              className='nd-body-item-content-input'
              placeholder='# Identificación'
              value={persona.dsIdentificacion}
              maxLength='35'
              name='dsIdentificacion'
              onChange={handleChange}
              disabled={false}
              data-validation='only-num-text'
              onBlur={(e) => validarLongitudMinima(e, 6)}
            />
            <label className='nd-label-error'>
              {errores.dsIdentificacion && <span>Este campo es requerido</span>}
            </label>
          </div>
          <div>
            <label>Dígito verificación</label>
            <input
              className='nd-body-item-content-input'
              name='dsDigitoVerificacion'
              placeholder='Dígito verificación'
              value={persona.dsDigitoVerificacion || ''}
              maxLength='1'
              onChange={handleChange}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className='nd-body-item'>
        <div className='nd-body-item-heading'>
          <label className='nd-body-item-label'>Datos personales</label>
          <div className='nd-body-item-description'></div>
        </div>

        <div className='nd-body-item-content'>
          <div>
            <label>Nombre o razón social</label>
            <input
              className='nd-body-item-content-input'
              name='dsNombre'
              id='dsNombre'
              placeholder='Nombre o razón social'
              label='Nombre o razón social'
              value={persona.dsNombre || ''}
              maxLength='200'
              onChange={handleChange}
              onBlur={(e) => validarLongitudMinima(e, 3)}
              autoComplete='off'
            />
            <label className='nd-label-error'>
              {errores.dsNombre && <span>Este campo es requerido</span>}
            </label>
          </div>
          <div>
            <label>Apellidos</label>
            <input
              className='nd-body-item-content-input'
              name='dsPrimerApellido'
              id='dsPrimerApellido'
              label='Apellidos'
              placeholder='Apellidos'
              value={persona.dsPrimerApellido || ''}
              maxLength='200'
              onChange={handleChange}
              disabled={disableApellidos}
              onBlur={(e) => validarLongitudMinima(e, 3)}
              autoComplete='off'
            />
            <label className='nd-label-error'>
              {errores.dsPrimerApellido && <span>Este campo es requerido</span>}
            </label>
          </div>
          <div>
            <label>Código país</label>
            <div>
              <Select
                antd
                placeholder='Codigo pais'
                value={persona.cdCodigoPais}
                options={codigosPais}
                name='cdCodigoPais'
                onChange={(value) => setPersona({ cdCodigoPais: value })}
              />
            </div>
            <label className='nd-label-error'>
              {errores.cdCodigoPais && <span>Este campo es requerido</span>}
            </label>
          </div>
          <div>
            <label>Celular</label>
            <input
              className='nd-body-item-content-input'
              name='dsCelular'
              id='dsCelular'
              label='Celular'
              placeholder='Celular'
              value={persona.dsCelular || ''}
              maxLength='12'
              onBlur={(e) => validarLongitudMinima(e, 10)}
              onChange={handleChange}
            />
            <label className='nd-label-error'>
              {errores.dsCelular && <span>Este campo es requerido</span>}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormularioPersonaRegistroEmisor;
