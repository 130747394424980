import { serviceRoutes, post, get } from "./index";

export function enviarSoportes(body) {
  return post(serviceRoutes.enviarSoporteNomina, body);
}

export function consultarSoportesEnviados({
  emisor,
  feInicio,
  feFin,
  empleadoId,
}) {
  return get(
    serviceRoutes.consultarSoportesEnviados,
    `?emisor=${emisor}&feInicio=${feInicio}&feFin=${feFin}&empleado=${empleadoId}`
  );
}

export function reenviarEmail({ documentoId, rutaPdf, email }) {
  return get(
    serviceRoutes.reenviarEmail,
    `?documentoId=${documentoId}&rutaPdf=${rutaPdf}&email=${email}`
  );
}

export async function generarSoporteNomina(idEmisor, fecha) {
  return await get(
    serviceRoutes.generarSoporteNomina,
    `${idEmisor}?fecha=${fecha}`
  );
}

export function remplazarSoporteNomina(body) {
  return post(serviceRoutes.remplazarSoporteNomina, body);
}

export function consultarDocumentoNominaPorId({ idDocumento }) {
  return get(serviceRoutes.consultarDocumentoNominaPorId, `?id=${idDocumento}`);
}

export function regenerarDocumentoNomina(body) {
  return post(serviceRoutes.regenerarDocumentoNomina, body);
}
