import { serviceRoutes, post, get } from './index';

export const transaccionRecarga = async (body) =>
  await post(serviceRoutes.transaccionRecarga, body);

export const consultarTransaccionRecarga = async ({ identificacion }) =>
  await get(serviceRoutes.consultarTransaccionRecarga, `?identificacion=${identificacion}`);

export const consultarHistoricoTransaccionRecarga = async ({ identificacion, inicio, fin }) =>
  await get(
    serviceRoutes.consultarHistoricoTransaccionRecarga,
    `?identificacion=${identificacion}&inicio=${inicio}&fin=${fin}`,
  );
