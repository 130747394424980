import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function compensacion({
  baseBody,
  guardarDevengado,
  xlCol = "2",
}) {
  const [nmCompensacionO, setNmCompensacion] = useState();
  const [nmCompensacionE, setNmCompensacionE] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `COMPENSACION`,
    nmCompensacionO,
    nmCompensacionE,
  };

  const guardarCompensacion = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmCompensacion();
    setNmCompensacionE();
  };

  return (
    <form onSubmit={guardarCompensacion}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago por Compensaciones Ordinarias *"
            value={nmCompensacionO || ""}
            onChange={(e) => setNmCompensacion(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Suma de dinero definido en el régimen de compensaciones como retribución mensual recibido por el asociado por la ejecución de su actividad material o inmaterial, la cual se fija teniendo en cuenta el tipo de labor desempeñada, el rendimiento o la productividad y la cantidad de trabajo aportado. El monto de la compensación ordinaria podrá ser una suma básica igual para todos los asociados (Ordinaria)."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago por Compensaciones Extraordinarias *"
            value={nmCompensacionE || ""}
            onChange={(e) => setNmCompensacionE(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Los demás pagos adicionales a la Compensación Ordinaria que recibe el asociado como retribución por su trabajo, definidos en el régimen de compensaciones (Extraordinaria)."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmCompensacionO)
    errores.push("El pago por compensaciones ordinarias es obligatorio");
  if (!body.nmCompensacionE)
    errores.push("El pago por compensaciones extraordinarias es obligatorio");
  return errores;
}
