import { serviceRoutes, post, get } from "./index";

export const registrarEntradaParqueadero = async (body) =>
  await post(serviceRoutes.registrarEntradaParqueadero, body);

export const reenviarNotificacionParqueadero = async (body) =>
  await post(serviceRoutes.reenviarNotificacionParqueadero, body);

export const consultarReporteParqueadero = async ({
  feInicio,
  feFin,
  nmIdEmisor,
  feConsulta,
}) =>
  await get(
    serviceRoutes.consultarReporteParqueadero,
    `?feInio=${feInicio}&feFin=${feFin}&dsFechaConsulta=${feConsulta}&nmIdEmisor=${nmIdEmisor}`
  );

export const generarPdfParqueadero = async ({ nmId }) =>
  await get(serviceRoutes.generarPdfParqueadero, `?nmId=${nmId}`);

export const registrarSalidaParqueadero = async (body) =>
  await post(serviceRoutes.registrarSalidaParqueadero, body);
