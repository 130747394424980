/* eslint-disable */
import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "components/Inputs/Select";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { SetModalAddDetalleFactura, SetDetalleFactura, SetCargosDescuentos, SetSnackBarState } from "../../reducers/actions/facturaActions";
import LabelTitulos from "../layout/labelTitulos";
import { handleKeyPressDecimal, numberFormat, handleKeyPressTextoNumeros } from "../../utils/funcionesUtiles";
import CargosDescuentosList from "./Components/CargosDescuentosList";
import CopagoCuotaModeradoraList from "./Components/CopagosCuotaModeradoraList";
import { codigosCopagoModeradora } from "./../../static/enums/CopagoCuotaModeradora";
import { consultarProductosActivosPorEmisorYDescripcion } from "network/Api/Producto";
import Async from 'react-select/async';
import Input from "components/Inputs/Input"

const styles = {
  buttonAdd: { fontWeight: "bold", backgroundColor: "#128BCC", color: "white" },
  buttonAddDescuentos: {
    fontWeight: "bold",
    backgroundColor: "#92C63E",
    color: "white",
  },
  paper: {
    padding: "2px",
    textAlign: "center",
    borderRadius: "5px",
    backgroundcolor: "#FFFFFF",
    boxShadow: "0px 0px 23px -12px rgba(127,143,166,0.87)",
    border: "1px solid #eadadf",
    marginBottom: "15px",
  },
  containerDialog: { width: "800px" },
  textDecoration: { textDecoration: "underline", cursor: "pointer " },
};

const customStyles = {
  input: (styles) => {
    return { ...styles, height: "2.25rem" };
  },
};

function formatearDecimales(num) {
  if (num == "") {
    num = 0;
  }
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return value;
}

function ccyFormat(num) {
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return numberFormat(value);
}

class DetalleFacturaAgregar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      codigoArticulo: "",
      descripcionArticulo: "",
      cantidad: "",
      precioUnitario: 0,
      porcentajeIva: 19,
      porcentajeIca: 0,
      porcentajeConsumo: 0,
      porcentajeDescuento: 0,
      impuestoIca: 0,
      valorIva: 0,
      impuestoConsumo: 0,
      valorTotalDescuento: 0,
      codigoDescuento: "DESCUENTO_NO_CONDICIONADO",
      unidadMedida: "",
      valorBruto: 0,
      valorTotalArticulo: 0,
      valorTotalArticuloDetalle: 0,
      nombreMarca: "",
      nombreModelo: "",
      estandarProducto: "",
      camposObligatoriosCodigo: false,
      camposObligatoriosDescripcion: false,
      camposObligatoriosCantidad: false,
      camposObligatoriosvalorUnitario: false,
      camposObligatoriosNombreMarca: false,
      camposObligatoriosNombreModelo: false,
      tipoDescuento: "porcentaje",
      esObsequio: false,
      precioReferencia: "",
      tipoPrecioReferencia: "",
      defaultValueTipoPrecioReferencia: -1,
      esSinIva: false,
      codigoModeradoraCopago: "",
      valorCuotaModeradaCopago: 0,
    };
  }

  filterProducto = (inputValue) => {
    const { isFacturaExportacion } = this.props;
    return consultarProductosActivosPorEmisorYDescripcion({
      idEmisor: this.props.userAttributes.codigoEmisor,
      filtro: inputValue,
      isFacturaExportacion,
    }).then((res) => {
      this.setState({ productos: res.data });
      return res.data.map((i) => ({
        value: i.nmId,
        label: `${i.dsCodigo} - ${i.dsDescripcion}`,
      }));
    });
  };

  loadOptions = (inputValue, callback) => {
    if (sessionStorage.getItem("dataEmisor")) {
      this.filterProducto(inputValue).then((res) => callback(res));
    } else window.location.reload();
  };

  handleConsultaProducto = (newValue) => {
    const { isFacturaExportacion } = this.props;
    return consultarProductosActivosPorEmisorYDescripcion({
      idEmisor: this.props.userAttributes.codigoEmisor,
      filtro: newValue,
      isFacturaExportacion,
    }).then((res) => {
      this.setState({ productos: res.data });
      return newValue;
    });
  };

  handleCloseAgregar = async (event) => {
    event.preventDefault();
    if (!this.validarCamposObligatorios()) {
      return;
    }
    await this.calcularValores();
    let data = await this.crearObjeto();
    this.props.setDetalleFactura(data);
    this.limpiarEstado();
  };

  handleClose = () => {
    this.limpiarEstado();
  };

  handleChange = (event) => {
    let cantidad = 0;
    let valorUnitario = 0;
    let precioReferencia = 0;
    switch (event.target.id) {
      case "cantidad":
        if (event.target.value < 0) event.target.value = 0;
        if (event.target.value === "") {
          this.setState({ cantidad: 0 }, () => {
            this.props.setCargosDescuentos([]);
            this.calcularValores();
          });
          return;
        }
        cantidad = event.target.value;
        this.setState({ cantidad: cantidad }, () => {
          this.props.setCargosDescuentos([]);
          this.calcularValores();
        });
        break;
      case "precioUnitario":
        if (this.state.esObsequio) return;
        if (event.target.value < 0) event.target.value = 0;
        if (event.target.value === "") {
          this.setState({ precioUnitario: 0 }, () => {
            this.props.setCargosDescuentos([]);
            this.calcularValores();
          });
          return;
        }
        valorUnitario = event.target.value;
        this.setState({ precioUnitario: valorUnitario }, () => {
          this.props.setCargosDescuentos([]);
          this.calcularValores();
        });
        this.setState({
          [event.target.id]: event.target.value.replace(/[\.]+[\.]/g, ".").replace(/[\,]/g, ""),
        });
        break;
      case "txtDescuento":
        if (event.target.value === "") {
          this.setState({ porcentajeDescuento: 0 }, () => {});
          return;
        }
        if (this.state.tipoDescuento == "porcentaje") {
          if (event.target.value < 0) event.target.value = 0;
          if (event.target.value > 100) event.target.value = 100;
        }

        this.setState({ porcentajeDescuento: event.target.value }, () => {});
        break;
      case "txtDescripcion":
        this.setState({
          descripcionArticulo: event.target.value.substring(0, 298).replaceAll("\t", ""),
          camposObligatoriosDescripcion: false,
        });
        break;
      case "precioReferencia":
        if (event.target.value === "") {
          this.setState({ precioReferencia: 0, precioUnitario: 0 }, () => {
            this.props.setCargosDescuentos([]);
            this.calcularValores();
          });
          return;
        }
        precioReferencia = event.target.value;
        this.setState({ precioReferencia: precioReferencia, precioUnitario: 0 }, () => {
          this.props.setCargosDescuentos([]);
          this.calcularValores();
        });
        break;
      case "valorCuotaModeradaCopago":
        this.setState({ [event.target.id]: event.target.value });
        break;
      default:
        return;
    }
  };

  limpiarEstado = () => {
    this.setState(
      {
        codigoArticulo: "",
        descripcionArticulo: "",
        cantidad: "",
        precioUnitario: 0,
        porcentajeIva: 19,
        porcentajeConsumo: 0,
        porcentajeDescuento: 0,
        porcentajeIca: 0,
        impuestoIca: 0,
        codigoDescuento: "DESCUENTO_NO_CONDICIONADO",
        valorIva: 0,
        impuestoConsumo: 0,
        valorTotalDescuento: 0,
        valorBruto: 0,
        valorTotalArticulo: 0,
        unidadMedida: "",
        tipoDescuento: "porcentaje",
        nombreMarca: "",
        nombreModelo: "",
        estandarProducto: "",
        camposObligatoriosvalorUnitario: false,
        esObsequio: false,
        precioReferencia: "",
        tipoPrecioReferencia: "",
        defaultValueTipoPrecioReferencia: -1,
        esSinIva: false,
      },
      () => {
        this.props.setCargosDescuentos([]);
        this.props.setModalAddDetalleFactura(false);
      }
    );
  };

  crearObjeto = async () => {
    const { cargosDescuentos } = this.props;
    let id = 0;
    let tamanoArray = await this.props.listaDetalleFactura.length;

    if (tamanoArray > 0) {
      id = this.props.listaDetalleFactura[tamanoArray - 1].id + 1;
    }

    let descuento = await parseFloat(this.state.porcentajeDescuento);
    let ico = await parseFloat(this.state.porcentajeConsumo);
    let iva = await parseFloat(this.state.porcentajeIva);
    let impuestosRetenciones = await this.objetoImpuestosRetenciones();
    let valorTotalImpuestosRetenciones = await this.valorTotalImpuestosRetenciones(impuestosRetenciones);
    let obsequio = new Object({
      precioReferencia: formatearDecimales(this.state.precioReferencia),
      tipoPrecioReferencia: this.state.tipoPrecioReferencia,
    });
    let cargosDesc = cargosDescuentos;
    let data;
    if (this.state.esObsequio) {
      cargosDesc = [];
      impuestosRetenciones = [];
      valorTotalImpuestosRetenciones = 0;
      iva = 0;
      data = await [
        ...this.props.listaDetalleFactura,
        {
          id: id,
          codigoArticulo: this.state.codigoArticulo,
          descripcionArticulo: this.state.descripcionArticulo,
          cantidad: formatearDecimales(this.state.cantidad),
          precioUnitario: formatearDecimales(this.state.precioUnitario),
          porcentajeIva: formatearDecimales(iva),
          porcentajeConsumo: formatearDecimales(ico), //porcentajeIca: formatearDecimales(this.state.porcentajeIca),impuestoIca: this.state.impuestoIca
          descuento: formatearDecimales(descuento),
          valorTotalDescuento: this.state.valorTotalDescuento,
          valorBrutoItem: this.state.valorBruto,
          valorTotalBruto: this.state.valorBruto,
          valorIva: this.state.valorIva,
          impuestoConsumo: this.state.impuestoConsumo,
          valorTotalArticulo: this.state.valorTotalArticulo,
          valorTotalArticuloDetalle: this.state.valorTotalArticuloDetalle,
          impuestosRetenciones: impuestosRetenciones,
          cargosDescuentos: cargosDesc,
          cargoTotal: 0,
          descuentoTotal: this.state.valorTotalDescuento,
          valorTotalImpuestosRetenciones: formatearDecimales(valorTotalImpuestosRetenciones),
          unidadMedida: this.state.unidadMedida,
          nombreMarca: this.state.nombreMarca,
          nombreModelo: this.state.nombreModelo,
          estandarProducto: this.state.estandarProducto,
          obsequio,
        },
      ];
    } else {
      if (this.state.esSinIva) iva = 0;
      data = await [
        ...this.props.listaDetalleFactura,
        {
          id: id,
          codigoArticulo: this.state.codigoArticulo,
          descripcionArticulo: this.state.descripcionArticulo,
          cantidad: formatearDecimales(this.state.cantidad),
          precioUnitario: formatearDecimales(this.state.precioUnitario),
          porcentajeIva: formatearDecimales(iva),
          porcentajeConsumo: formatearDecimales(ico),
          //porcentajeIca: formatearDecimales(this.state.porcentajeIca),
          // impuestoIca: this.state.impuestoIca,
          descuento: formatearDecimales(descuento),
          valorTotalDescuento: this.state.valorTotalDescuento,
          valorBrutoItem: this.state.valorBruto,
          valorTotalBruto: this.state.valorBruto,
          valorIva: this.state.valorIva,
          impuestoConsumo: this.state.impuestoConsumo,
          valorTotalArticulo: this.state.valorTotalArticulo,
          valorTotalArticuloDetalle: this.state.valorTotalArticuloDetalle,
          impuestosRetenciones: impuestosRetenciones,
          cargosDescuentos: cargosDesc,
          cargoTotal: 0,
          descuentoTotal: this.state.valorTotalDescuento,
          valorTotalImpuestosRetenciones: formatearDecimales(valorTotalImpuestosRetenciones),
          unidadMedida: this.state.unidadMedida,
          nombreMarca: this.state.nombreMarca,
          nombreModelo: this.state.nombreModelo,
          estandarProducto: this.state.estandarProducto,
          esSinIva: this.state.esSinIva,
          Dia_Sin_IVA: this.state.esSinIva ? "Bienes Cubiertos" : "",
        },
      ];
    }

    return data;
  };

  valorTotalImpuestosRetenciones = async (impuestosRetenciones) => {
    let valorTotal = 0;
    impuestosRetenciones.map((item) => {
      valorTotal += parseFloat(item.valorTotal);
    });
    return valorTotal;
  };

  objetoImpuestosRetenciones = async () => {
    const {
      porcentajeIva,
      porcentajeConsumo,
      porcentajeIca,
      valorBruto,
      valorTotalDescuento,
      valorIva,
      impuestoConsumo,
      impuestoIca,
      valorUnitarioPL,
      volumen,
      impuestoPL,
    } = this.state;
    let impuestosRetenciones = [];
    let valorAplicaImpuesto = valorBruto - valorTotalDescuento;
    if (porcentajeIva > 0) {
      let objetoIva = {
        valorTotal: formatearDecimales(valorIva),
        subtotales: [
          {
            valorBase: formatearDecimales(valorAplicaImpuesto),
            valorImpuestoRetencion: formatearDecimales(valorIva),
            porcentaje: formatearDecimales(porcentajeIva),
            tributo: "IVA",
          },
        ],
      };
      impuestosRetenciones.push(objetoIva);
    }

    if (porcentajeConsumo > 0) {
      let objetoICO = {
        valorTotal: formatearDecimales(impuestoConsumo),
        subtotales: [
          {
            valorBase: formatearDecimales(valorAplicaImpuesto),
            valorImpuestoRetencion: formatearDecimales(impuestoConsumo),
            porcentaje: formatearDecimales(porcentajeConsumo),
            tributo: "INC",
          },
        ],
      };
      impuestosRetenciones.push(objetoICO);
    }

    if (porcentajeIca > 0) {
      let objetoICA = {
        valorTotal: formatearDecimales(impuestoIca),
        subtotales: [
          {
            valorBase: formatearDecimales(valorAplicaImpuesto),
            valorImpuestoRetencion: formatearDecimales(impuestoIca),
            porcentaje: formatearDecimales(porcentajeIca),
            tributo: "ICA",
          },
        ],
      };
      impuestosRetenciones.push(objetoICA);
    }

    if (impuestoPL) {
      const totalImpuesto = valorUnitarioPL * volumen;
      impuestosRetenciones.push({
        valorTotal: formatearDecimales(totalImpuesto),
        subtotales: [
          {
            valorImpuestoRetencion: formatearDecimales(totalImpuesto),
            valorUnitario: valorUnitarioPL,
            tributo: impuestoPL,
            volumen,
          },
        ],
      });
    }

    return impuestosRetenciones;
  };

  calcularValores = () => {
    const { cantidad, precioUnitario } = this.state;
    const { cargosDescuentos } = this.props;

    let dvalorBruto = cantidad * precioUnitario;
    let dvalorTotalDescuento = 0;
    let valoresTotales = 0;
    let porcentajeDescuento = 0;
    if (cargosDescuentos.length > 0) {
      cargosDescuentos.map((item) => {
        porcentajeDescuento += parseFloat(item.porcentaje);
        valoresTotales += parseFloat(item.valor);
      });
      if (porcentajeDescuento > 100) {
        porcentajeDescuento = 100;
      }
      dvalorTotalDescuento = valoresTotales;
    }

    const valorBase = dvalorBruto - dvalorTotalDescuento;
    let dvalorTotalIva = valorBase * (this.state.porcentajeIva / 100);
    let dvalorTotalIco = valorBase * (this.state.porcentajeConsumo / 100);
    let dvalorTotalIca = valorBase * (this.state.porcentajeIca / 100);

    let dvalorTotal = dvalorBruto - dvalorTotalDescuento + dvalorTotalIva + dvalorTotalIco + dvalorTotalIca;
    this.setState({
      valorBruto: formatearDecimales(dvalorBruto),
      valorTotalArticulo: formatearDecimales(dvalorBruto),
      valorTotalArticuloDetalle: formatearDecimales(dvalorTotal),
      valorIva: formatearDecimales(dvalorTotalIva),
      impuestoConsumo: formatearDecimales(dvalorTotalIco),
      impuestoIca: formatearDecimales(dvalorTotalIca),
      valorTotalDescuento: formatearDecimales(dvalorTotalDescuento),
    });
  };

  validarCamposObligatorios = () => {
    const { cargosDescuentos, isFacturaExportacion } = this.props;
    let sw = 0;

    if (this.state.esObsequio) {
      if (this.state.precioReferencia === "" || this.state.precioReferencia == "0") {
        sw += 1;
        this.props.setSnackBarState({
          open: true,
          message: "El precio referencia no puede ser cero o vacío",
        });
      }

      if (this.state.tipoPrecioReferencia == "" || this.state.tipoPrecioReferencia == null) {
        sw += 1;
        this.props.setSnackBarState({
          open: true,
          message: "Seleccione un tipo precio referencia",
        });
      }

      if (this.state.precioUnitario > 0) {
        sw += 1;
        this.props.setSnackBarState({
          open: true,
          message: "El valor unitario debe ser igual a cero",
        });
      }
    } else {
      if (this.state.precioUnitario == "0" || this.state.precioUnitario == "0.00") {
        sw += 1;
        this.props.setSnackBarState({
          open: true,
          message: "El precio unitario debe ser mayor a cero",
        });
      }
    }

    if (this.state.codigoArticulo.length === 0) {
      sw += 1;
      this.setState({ camposObligatoriosCodigo: true });
    }

    if (this.state.descripcionArticulo.length < 5 || this.state.descripcionArticulo.length > 200) {
      sw += 1;
      this.setState({ camposObligatoriosDescripcion: true });
      this.props.setSnackBarState({
        open: true,
        message: "La descripción no puede estar vacía y debe tener entre 5 y 200 caracterés",
      });
    }

    if (this.state.cantidad <= 0) {
      sw += 1;
      this.setState({ camposObligatoriosCantidad: true });
    }

    if (this.state.unidadMedida.length === 0) {
      sw += 1;
      this.props.setSnackBarState({
        open: true,
        message: "La unidad de medida es obligatoria",
      });
    }

    let totalPorcentajeDescuento = 0;
    if (cargosDescuentos.length > 0) {
      cargosDescuentos.map((item) => {
        totalPorcentajeDescuento += parseFloat(item.porcentaje);
      });
    }

    if (totalPorcentajeDescuento > 100) {
      sw += 1;
      this.props.setSnackBarState({
        open: true,
        message: "Los descuentos no pueden superar el 100% del valor bruto",
      });
    }

    if (isFacturaExportacion) {
      if (this.state.nombreMarca.length === 0) {
        sw += 1;
        this.setState({ camposObligatoriosNombreMarca: true });
        this.props.setSnackBarState({
          open: true,
          message: "La marca del producto es obligatoria",
        });
      }

      if (this.state.nombreModelo.length === 0) {
        sw += 1;
        this.setState({ camposObligatoriosNombreModelo: true });
        this.props.setSnackBarState({
          open: true,
          message: "El módelo del producto es obligatorio",
        });
      }

      if (this.state.estandarProducto.length === 0) {
        sw += 1;
        this.props.setSnackBarState({
          open: true,
          message: "El estandar del producto es obligatorio",
        });
      }
    }

    if (sw > 0) {
      return false;
    }
    return true;
  };

  handleChangeCodigoDescuento = (selectedOption) => {
    let codigo = selectedOption.value;
    this.setState({ codigoDescuento: codigo });
  };

  handleChangeTipoEstandarProducto = (selectedOption) => {
    let codigo = selectedOption.value;
    this.setState({ estandarProducto: codigo });
  };

  handleAgregarDescuento = () => {
    const { cargosDescuentos } = this.props;
    const { codigoDescuento, porcentajeDescuento, valorBruto, cantidad, precioUnitario, tipoDescuento } = this.state;
    if (cantidad == 0 || precioUnitario == 0 || porcentajeDescuento == 0) {
      this.props.setSnackBarState({
        open: true,
        message: "Ingrese correctamente la cantidad, el precio unitario y un descuento mayor a 0",
      });
      return;
    }

    let id = 0;
    let tamanoArray = cargosDescuentos.length;
    if (tamanoArray > 0) {
      id = cargosDescuentos[tamanoArray - 1].id + 1;
    }

    if (tipoDescuento == "porcentaje") {
      let valor = valorBruto * (porcentajeDescuento / 100);
      let objetoCargosDescuentos = {
        id: id,
        tipo: false,
        codigoDescuento: codigoDescuento,
        porcentaje: formatearDecimales(porcentajeDescuento),
        valor: formatearDecimales(valor),
        valorBase: formatearDecimales(valorBruto),
      };
      this.props.setCargosDescuentos([...cargosDescuentos, objetoCargosDescuentos]);
    } else {
      if (parseFloat(valorBruto) < parseFloat(porcentajeDescuento)) {
        this.props.setSnackBarState({
          open: true,
          message: "El valor del descuento no puede ser mayor al valor bruto",
        });
        return;
      }

      let valorPorcentaje = (parseFloat(porcentajeDescuento) * 100) / parseFloat(valorBruto);
      let valor = porcentajeDescuento;
      let objetoCargosDescuentos = {
        id: id,
        tipo: false,
        codigoDescuento: codigoDescuento,
        porcentaje: formatearDecimales(valorPorcentaje),
        valor: formatearDecimales(valor),
        valorBase: formatearDecimales(valorBruto),
      };
      this.props.setCargosDescuentos([...cargosDescuentos, objetoCargosDescuentos]);
    }
  };

  handleChangeCodigoModeradoraCopago = (select) => this.setState({ codigoModeradoraCopago: select.value });

  handleAgregarModeradoraCopago = () => {
    const { setCargosDescuentos, cargosDescuentos } = this.props;
    const { valorBruto, codigoModeradoraCopago, valorCuotaModeradaCopago } = this.state;
    if (parseInt(valorCuotaModeradaCopago) <= parseInt(valorBruto) && codigoModeradoraCopago) {
      let id = 0,
        tamanoArray = cargosDescuentos.length;
      if (tamanoArray > 0) {
        id = cargosDescuentos[tamanoArray - 1].id + 1;
      }
      let finalObject = {
        id: id,
        tipo: false,
        codigoDescuento: "DESCUENTO_NO_CONDICIONADO",
        razon: codigoModeradoraCopago,
        porcentaje: formatearDecimales((valorCuotaModeradaCopago * 100) / valorBruto),
        valor: formatearDecimales(valorCuotaModeradaCopago),
        valorBase: formatearDecimales(valorBruto),
      };
      setCargosDescuentos([...cargosDescuentos, finalObject]);
    } else {
      this.props.setSnackBarState({
        open: true,
        message: "El valor es mayor al valor bruto o no selecciono si es cuota moderadora o copago",
      });
    }
  };

  handleKeyPressTextoNumeros = (event) => {
    if (!handleKeyPressTextoNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressDecimal = (event) => {
    if (!handleKeyPressDecimal(event)) {
      event.preventDefault();
    }
  };

  handleClickCambiarTipoDescuento = () => {
    let tipoDescuento = "valor";
    if (this.state.tipoDescuento == tipoDescuento) {
      tipoDescuento = "porcentaje";
    }
    this.setState({
      tipoDescuento: tipoDescuento,
    });
  };

  handleChangeCodigo = (selectedOption) => {
    let codigo = selectedOption.value;
    const { isFacturaExportacion, tiposPrecioReferencia } = this.props;
    const { productos } = this.state;
    let productoSeleccionado = productos.find((item) => item.nmId === codigo);
    let nombreMarca = "",
      nombreModelo = "";
    if (isFacturaExportacion) {
      nombreMarca = productoSeleccionado.nmMarca;
      nombreModelo = productoSeleccionado.nmModelo;
    }
    let conValorUnitario = false;
    if (productoSeleccionado.nmValorUnitario > 0) {
      conValorUnitario = true;
    }
    let defaultValueTipoPrecioReferencia = tiposPrecioReferencia.findIndex((item) => item.codigo == productoSeleccionado.dsTipoPrecioref);
    this.setState({ defaultValueTipoPrecioReferencia: -1 }, () => {
      const { snIvaIncluido, nmValorUnitario, nmPorcentajeIva } = productoSeleccionado;
      const precioUnitario = snIvaIncluido ? formatearDecimales(nmValorUnitario / (1 + nmPorcentajeIva / 100)) : nmValorUnitario;
      this.setState({
        valorDividirUnitario: 1 + nmPorcentajeIva / 100,
        codigoArticulo: productoSeleccionado.dsCodigo,
        descripcionArticulo: productoSeleccionado.dsDescripcion.substring(0, 198),
        precioUnitario,
        porcentajeIva: nmPorcentajeIva,
        porcentajeConsumo: productoSeleccionado.nmPorcentajeInc,
        unidadMedida: productoSeleccionado.nmUnidadMedida,
        nombreMarca: nombreMarca,
        nombreModelo: nombreModelo,
        camposObligatoriosvalorUnitario: conValorUnitario,
        defaultValueTipoPrecioReferencia: defaultValueTipoPrecioReferencia,
        precioReferencia: productoSeleccionado.dsPrecioReferencia,
        tipoPrecioReferencia: productoSeleccionado.dsTipoPrecioref,
        esObsequio: false,
        snIvaIncluido,
        totalBase: nmValorUnitario,
      });
    });
  };

  handleBlur = (event) => {
    if (event.target.value != "") {
      this.setState({
        precioUnitario: ccyFormat(parseFloat(event.target.value)),
      });
    }
  };

  handleChangeEsObsequio = (event) => {
    this.setState(
      {
        precioUnitario: 0,
        esObsequio: event.target.checked,
        defaultValueTipoPrecioReferencia: null,
      },
      () => {
        this.props.setCargosDescuentos([]);
        this.calcularValores();
      }
    );
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value })
  }

  handleChangeTipoPrecioReferencia = (selectedOption) => {
    let codigo = selectedOption.value;
    this.setState({ tipoPrecioReferencia: codigo });
  };

  render() {
    const { open, cargosDescuentos, codigosDescuentos, isFacturaExportacion, tiposEstandarProducto, productos, tiposPrecioReferencia } = this.props;

    const {
      tipoDescuento,
      cantidad,
      precioUnitario,
      descripcionArticulo,
      unidadMedida,
      porcentajeIva,
      porcentajeConsumo,
      nombreMarca,
      nombreModelo,
      esObsequio,
      precioReferencia,
      defaultValueTipoPrecioReferencia,
      fristIva,
      snIvaIncluido,
      valorUnitarioPL,
      volumen,
    } = this.state;

    let codigosDescuentosSelect = [];
    codigosDescuentos.map((item) => {
      codigosDescuentosSelect.push({ value: item.codigo, label: item.nombre });
    });

    let tiposEstandarProductoSelect = [];
    tiposEstandarProducto.map((item) => {
      tiposEstandarProductoSelect.push({
        value: item.codigo,
        label: item.nombre,
      });
    });

    let productosSelect = [];
    productos.map((item) => {
      if (item.cdEstado == "A") {
        productosSelect.push({
          value: item.nmId,
          label: `${item.dsCodigo} - ${item.dsDescripcion}`,
        });
      }
    });

    let tiposPrecioReferenciaSelect = [];
    tiposPrecioReferencia.map((item) => {
      tiposPrecioReferenciaSelect.push({
        value: item.codigo,
        label: item.nombre,
      });
    });

    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          scroll="paper"
          fullScreen={isWidthUp("md", this.props.width) ? false : true}
          fullWidth={isWidthUp("xl", this.props.width) ? false : true}
          maxWidth="md"
        >
          <form onSubmit={this.handleCloseAgregar.bind(this)}>
            <DialogTitle id="form-dialog-title">
              <LabelTitulos texto="Agregar nuevo item a la factura" />
            </DialogTitle>
            <DialogContent>
              <div className="form-row col-md-12">
                <div className="form-group col-md-6">
                  <label className="text-muted">Producto *</label>
                  <Async
                    cacheOptions
                    loadOptions={this.loadOptions}
                    defaultOptions
                    onInputChange={this.handleConsultaProducto}
                    onChange={this.handleChangeCodigo}
                  />
                </div>

                <div className="form-group col-md-2 col-xl-2">
                  <label className="text-muted">Cantidad *</label>
                  <div className="form-group">
                    <input
                      maxLength="20"
                      autoComplete="off"
                      type="text"
                      id="cantidad"
                      className="form-control form-control-md"
                      value={cantidad}
                      onKeyPress={this.handleKeyPressDecimal}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group col-md-2 col-xl-2">
                  <label className="text-muted">Valor unitario *</label>
                  <div className="form-group">
                    <input
                      maxLength="30"
                      autoComplete="off"
                      type="text"
                      id="precioUnitario"
                      className="form-control form-control-md"
                      value={precioUnitario}
                      onKeyPress={this.handleKeyPressDecimal}
                      onChange={this.handleChange}
                      onBlur={({ target }) => {
                        const { snIvaIncluido, valorDividirUnitario } = this.state;
                        if (snIvaIncluido)
                          this.setState({
                            precioUnitario: formatearDecimales(target.value / valorDividirUnitario),
                          });
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="custom-control custom-checkbox text-left col-md-2" style={{ marginTop: "30px" }}>
                  <input type="checkbox" disabled={true} checked={snIvaIncluido} id="snIvaIncluido" style={{ margin: "5px" }} />
                  <label htmlFor="snIvaIncluido">Iva Incluido</label>
                </div>
              </div>
              <div className="form-group col-md-12">
                <label className="text-muted">Descripción</label>
                <div className="form-group">
                  <input
                    maxLength="300"
                    autoComplete="off"
                    type="text"
                    id="txtDescripcion"
                    className="form-control form-control-md"
                    onKeyPress={this.handleKeyPressTextoNumeros}
                    onChange={this.handleChange}
                    value={descripcionArticulo}
                    required
                  />
                </div>
              </div>
              <div className="form-row col-md-12">
                <div className="form-group col-md-4 col-xl-4">
                  <div>
                    <label className="text-muted">Unidad de medida</label>
                    <input
                      maxLength="100"
                      autoComplete="off"
                      type="text"
                      className="form-control form-control-md"
                      value={unidadMedida}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group col-md-4 col-xl-4">
                  <div>
                    <label className="text-muted">Porcentaje IVA</label>
                    <input
                      maxLength="100"
                      autoComplete="off"
                      type="text"
                      className="form-control form-control-md"
                      value={porcentajeIva}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group col-md-4 col-xl-4">
                  <div>
                    <label className="text-muted">Porcentaje INC</label>
                    <input
                      maxLength="100"
                      autoComplete="off"
                      type="text"
                      className="form-control form-control-md"
                      value={porcentajeConsumo}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row col-md-12">
                <div className="form-group col-md-4 col-xl-4">
                  <Select 
                    label="Impuesto" 
                    classLabel="text-muted" 
                    defaultValue={null}
                    options={[
                      { label: "Selecciona un tipo de impuesto", value: null },
                      { label: "Licores", value: "ICL" },
                      { label: "Plasticos", value: "INPP" },
                    ]}
                    onChange={({ value }) => this.setState({ impuestoPL: value })}
                  />
                </div>
                <div className="form-group col-md-4 col-xl-4">
                  <Input 
                    label="Valor unitario"
                    name="valorUnitarioPL"
                    value={valorUnitarioPL}
                    onChange={this.onChange}
                    data-validation="only-num-decimals"
                  />
                </div>
                <div className="form-group col-md-4 col-xl-4">
                  <Input 
                    label="Volumen"
                    name="volumen"
                    value={volumen}
                    onChange={this.onChange}
                    data-validation="only-num-decimals"
                  />
                </div>
              </div>

              <div className="form-row col-md-12" style={{ padding: "10px" }}>
                <div className="form-group col-md-4 col-xl-4">
                  <div>
                    <label className="text-muted">Tipo precio referencia *</label>
                    {defaultValueTipoPrecioReferencia >= 0 && (
                      <Select
                        defaultValue={tiposPrecioReferenciaSelect[defaultValueTipoPrecioReferencia]}
                        onChange={this.handleChangeTipoPrecioReferencia.bind(this)}
                        options={tiposPrecioReferenciaSelect}
                        placeholder="Tipo precio referencia"
                        isDisabled={esObsequio ? false : true}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group col-md-4 col-xl-4">
                  <label className="text-muted">Precio referencia *</label>
                  <div className="form-group">
                    <input
                      maxLength="15"
                      autoComplete="off"
                      type="text"
                      id="precioReferencia"
                      className="form-control form-control-md"
                      value={precioReferencia}
                      onKeyPress={this.handleKeyPressDecimal}
                      onChange={this.handleChange}
                      required={esObsequio ? true : false}
                      disabled={esObsequio ? false : true}
                    />
                  </div>
                </div>
                <div className="form-group col-md-4 col-xl-4">
                  <label className="text-muted">Seleccione si es: </label>
                  <div class="custom-control custom-checkbox text-left" style={{ paddingLeft: "50%" }}>
                    <input checked={esObsequio} onChange={this.handleChangeEsObsequio} type="checkbox" class="custom-control-input" id="esObsequio" />
                    <label class="custom-control-label " for="esObsequio">
                      Obsequio
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox text-left" style={{ paddingLeft: "50%" }}>
                    <input
                      onChange={(e) => {
                        this.setState({ esSinIva: e.target.checked });
                        this.setState({ fristIva: porcentajeIva });
                        if (e.target.checked) {
                          this.setState({ porcentajeIva: 0 });
                        } else {
                          this.setState({ porcentajeIva: fristIva });
                        }
                      }}
                      type="checkbox"
                      class="custom-control-input"
                      id="esSinIva"
                    />
                    <label class="custom-control-label " for="esSinIva">
                      Día sin IVA
                    </label>
                  </div>
                </div>
              </div>
              {isFacturaExportacion && (
                <div style={styles.paper}>
                  <div className="form-row col-md-12 d-flex justify-content-center">
                    <div className="form-group col-md-12 col-xl-12">
                      <div>
                        <label className="text-muted">Información para exportación</label>
                        <div className="form-row col-md-12">
                          <div className="col-md-4">
                            <label className="text-muted">Marca del producto</label>
                            <input
                              maxLength="100"
                              autoComplete="off"
                              type="text"
                              className="form-control form-control-md"
                              value={nombreMarca}
                              disabled={true}
                            />
                          </div>
                          <div className="col-md-4 col-xl-4">
                            <label className="text-muted">Modelo del producto</label>
                            <input
                              maxLength="100"
                              autoComplete="off"
                              type="text"
                              className="form-control form-control-md"
                              value={nombreModelo}
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4 col-xl-4">
                            <label className="text-muted">Estandar del producto</label>
                            <Select
                              onChange={this.handleChangeTipoEstandarProducto}
                              options={tiposEstandarProductoSelect}
                              placeholder="Estandar del producto..."
                              styles={customStyles}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div style={styles.paper}>
                <div className="form-row col-md-12 d-flex justify-content-center">
                  <div className="form-group col-md-12 col-xl-12">
                    <div>
                      <label className="text-muted">Agregar descuentos</label>
                      <Select
                        onChange={this.handleChangeCodigoDescuento.bind(this)}
                        options={codigosDescuentosSelect}
                        placeholder="Seleccione un código..."
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row col-md-12 d-flex justify-content-center">
                  <div className="form-group col-md-4 col-xl-4 mt-4">
                    <div>
                      <label className="text-muted">Descuento por {tipoDescuento}</label>
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xl-4">
                    <div>
                      {tipoDescuento == "porcentaje" ? (
                        <TextField
                          id="txtDescuento"
                          label={`Ingrese el % de descuento`}
                          type="text"
                          InputProps={{ inputProps: { min: 0, max: 100 } }}
                          fullWidth
                          onKeyPress={this.handleKeyPressDecimal}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      ) : (
                        <TextField
                          id="txtDescuento"
                          label={`Ingrese el valor de descuento`}
                          type="text"
                          fullWidth
                          onKeyPress={this.handleKeyPressDecimal}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xl-4 mt-4">
                    <div>
                      <label style={styles.textDecoration} onClick={this.handleClickCambiarTipoDescuento}>
                        Cambiar a descuento por {tipoDescuento == "porcentaje" ? "valor" : "porcentaje"}
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-xl-12">
                    <div className="mt-3">
                      <Button style={styles.buttonAddDescuentos} onClick={this.handleAgregarDescuento} name="Cerrar" color="primary">
                        Agregar descuento
                      </Button>
                    </div>
                  </div>
                </div>

                {cargosDescuentos.length > 0 && <CargosDescuentosList />}
              </div>

              <div style={styles.paper}>
                <label className="text-muted">Agregar Copago o Cuota Moderadora</label>
                <div className="form-row col-md-12 d-flex justify-content-center">
                  <div className="form-group col-md-4 col-xl-4">
                    <div>
                      <Select
                        onChange={this.handleChangeCodigoModeradoraCopago.bind(this)}
                        options={codigosCopagoModeradora.map((item) => ({
                          value: item.nombre,
                          label: item.descriptcion,
                        }))}
                        placeholder="Selecciona Copago o Cuota"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xl-4">
                    <div>
                      <TextField
                        id="valorCuotaModeradaCopago"
                        label={`Ingrese el valor`}
                        type="text"
                        fullWidth
                        onKeyPress={this.handleKeyPressDecimal}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xl-4 mt-2">
                    <Button style={styles.buttonAddDescuentos} onClick={this.handleAgregarModeradoraCopago} name="Cerrar" color="primary">
                      Agregar valor
                    </Button>
                  </div>
                </div>

                {cargosDescuentos.length > 0 && <CopagoCuotaModeradoraList />}
              </div>
            </DialogContent>
            <DialogActions>
              <Button style={styles.buttonAdd} type="submit" name="txtAgregar" color="primary">
                Agregar
              </Button>
              <Button style={styles.buttonAdd} onClick={this.handleClose} name="Cerrar" color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCargosDescuentos: (item) => dispatch(SetCargosDescuentos(item)),
  setDetalleFactura: (item) => dispatch(SetDetalleFactura(item)),
  setModalAddDetalleFactura: (item) => dispatch(SetModalAddDetalleFactura(item)),
  setSnackBarState: (item) => dispatch(SetSnackBarState(item)),
});

function mapStateToProps(state, props) {
  return {
    tipoFactura: state.factura.datosDocumento.tipoDocumentoElectronico,
    open: state.factura.openModalDetalleFactura,
    listaDetalleFactura: state.factura.listaDetalleFactura,
    tiposPorcentajesIVA: state.factura.tiposPorcentajesIVA,
    tiposPorcentajesICO: state.factura.tiposPorcentajesICO,
    cargosDescuentos: state.factura.cargosDescuentos,
    codigosDescuentos: state.factura.codigosDescuentos,
    tiposUnidadMedida: state.factura.tiposUnidadMedida,
    isFacturaExportacion: state.factura.isFacturaExportacion,
    tiposEstandarProducto: state.factura.tiposEstandarProducto,
    productos: state.maestros.productos,
    tiposPrecioReferencia: state.factura.tiposPrecioReferencia,
    userAttributes: state.factura.userAttributes,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(DetalleFacturaAgregar));
