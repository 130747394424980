import React, { Component } from "react";
import Select from "components/Inputs/Select";
import {
  consultarPlanesPorDistribuidor,
  consultarPlanPorDistribuidorYTipo,
} from "network/Api/Plan";

export const Types = {
  consultarDistribuidor: "consultarPlanesDistribuidor",
  consultarPorDistribuidorYTipo: "consultarPorDistribuidorYTipo",
};

export default class InputPlanes extends Component {
  state = {
    planesSelect: [],
  };

  componentDidMount() {
    const { consulta } = this.props;
    switch (consulta) {
      case "consultarPlanesDistribuidor":
        consultarPlanesPorDistribuidor(
          this.validarBodyPlanesDistribuidor()
        ).then((res) => this.getPlanesResponse(res));
        break;
      case "consultarPorDistribuidorYTipo":
        const body = this.validarBodyPlanesDistribuidorYTipo(consulta);
        consultarPlanPorDistribuidorYTipo(body.distribuidor, body.tipo).then(
          (res) => this.getPlanesResponse(res)
        );
        break;
      default:
        throw new Error("Tipo de consulta desconocido");
    }
  }

  getPlanesResponse = (res) => {
    if (res.status)
      this.setState({
        planesSelect: res.data.map((plan) => ({
          label: `${this.tipoPlanConverter(plan.cdTipo)} - ${
            plan.dsDescripcion
          }`,
          value: plan.nmId,
          tipo: plan.cdTipo,
        })),
      });
    else this.setState({ planesSelect: res });
  };

  validarBodyPlanesDistribuidor = () => {
    const { distribuidor } = this.props;
    if (!distribuidor)
      throw new Error("Distribuidor obligatorio para este tipo");
    return { distribuidor };
  };

  validarBodyPlanesDistribuidorYTipo = () => {
    const { distribuidor, tipo } = this.props;
    if (!distribuidor)
      throw new Error("Distribuidor obligatorio para este tipo");
    if (!tipo)
      throw new Error("Tipo de plan obligatorio para este tipo de consulta");
    return { distribuidor, tipo };
  };

  tipoPlanConverter = (tipo) => {
    switch (tipo) {
      case "1":
        return "Activacion";
      case "2":
        return "Recarga";
      case "3":
        return "Acceso";
      case "4":
        return "Anualidad";
      case "5":
        return "Recarga Caja Menor";
      case "6":
        return "Recarga Pos";
      case "7":
        return "Recarga Tiquetera";
      default:
        return "Tipo de plan desconocido";
    }
  };

  render() {
    const { planesSelect } = this.state;
    const { handleChange, style, className } = this.props;
    return (
      <Select
        fullWidth
        options={planesSelect}
        styles={style}
        onChange={(e) => handleChange(e)}
        className={className}
        {...this.props}
      />
    );
  }
}
