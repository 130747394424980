import styled from 'styled-components';
import Typography from 'components/atoms/Typography';
import { Row } from 'antd';

export const Container = styled.div`
  padding: 50px;
`;

export const SeccionInfo = styled.div`
  padding-top: '30px';
`;

export const LabelTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.3rem;
  padding: 0px;
`;

export const InfoList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoLabel = styled.div`
  color: #000;
  font-size: 1.2rem;
  padding: 0px;
`;

export const TotalTitle = styled(Typography)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  display: block;
  text-align: left;
  margin: 0px 10px;
`;

export const StrongTotal = styled.strong`
  color: #000;
`;

export const Observaciones = styled(Row)`
  margin-top: '100px';
`;

export const GridTotales = styled.div`
  display: grid;
  grid-template:
    'total total'
    'total total'
    'total total'
    'total total'
    'total total'
    'letras letras';
  max-width: 600px;
`;
