import * as React from "react";
import Icon from "components/menu/Icon";
import EmisionIcon from "static/images/emision.png";

const getMenu = (items) => ({
  subMenu: true,
  title: "Documentos express",
  icon: <Icon src={EmisionIcon} alt="Documentos Express" />,
  items,
});

export const DocumentoExpress = getMenu([
  {
    isNoView: true,
    pathname: "/documento-express",
  },
  {
    title: "Factura express",
    icon: <Icon src={EmisionIcon} alt="Documento express" />,
    pathname: "/documento-express?tipo=VENTA",
  },
  {
    title: "Nota Credito express",
    icon: <Icon src={EmisionIcon} alt="Documento Express" />,
    pathname: "/documento-express?tipo=NOTA_CREDITO",
  },
  {
    title: "Asociar tiquetera",
    icon: <Icon src={EmisionIcon} alt="Asociar Tiquetera" />,
    pathname: "/asociar-tiquetera",
  },
  {
    title: "Crear emisor",
    icon: <Icon src={EmisionIcon} alt="Crear Emisor Express" />,
    pathname: "/crear-emisor-express",
  },
]);
