import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function bonoEPCTV({ baseBody, guardarDevengado, xlCol = "2" }) {
  const [nmPago, setNmPago] = useState();
  const [nmPagoNS, setNmPagoNS] = useState();
  const [nmPagoAlimentacionS, setNmPagoAlimentacionS] = useState();
  const [nmPagoAlimentacionNS, setNmPagoAlimentacionNS] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `BONO_EPCTV`,
    nmPago,
    nmPagoNS,
    nmPagoAlimentacionS,
    nmPagoAlimentacionNS,
  };

  const guardarBonosEPCTV = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmPago();
    setNmPagoNS();
    setNmPagoAlimentacionS();
    setNmPagoAlimentacionNS();
  };

  return (
    <form onSubmit={guardarBonosEPCTV}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Concepto Salarial"
            value={nmPago || ""}
            onChange={(e) => setNmPago(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor que el trabajador recibe como contraprestación por el trabajo realizado, por medio de bonos electrónicos, recargas, cheques, vales. es decir, todo pago realizado en un medio diferente a dinero en efectivo o consignación de cuenta bancaria (Salarial)."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Concepto No Salarial"
            value={nmPagoNS || ""}
            onChange={(e) => setNmPagoNS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor que el trabajador recibe como concepto no salarial, por medio de bonos electrónicos, recargas, cheques, vales. es decir, todo pago realizado en un medio diferente a dinero en efectivo o consignación de cuenta bancaria (No Salarial)."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Concepto Alimentacion Salarial"
            value={nmPagoAlimentacionS || ""}
            onChange={(e) => setNmPagoAlimentacionS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor que el trabajador recibe como concepto no salarial, por medio de bonos electrónicos, recargas, cheques, vales. es decir, todo pago realizado en un medio diferente a dinero en efectivo o consignación de cuenta bancaria (Para Alimentación Salarial)."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Concepto Alimentacion No Salarial"
            value={nmPagoAlimentacionNS || ""}
            onChange={(e) => setNmPagoAlimentacionNS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor que el trabajador recibe como concepto no salarial, por medio de bonos electrónicos, recargas, cheques, vales. es decir, todo pago realizado en un medio diferente a dinero en efectivo o consignación de cuenta bancaria (Para Alimentación No Salarial)."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (
    !body.nmPago &&
    !body.nmPagoNS &&
    !body.nmPagoAlimentacionS &&
    !body.nmPagoAlimentacionNS
  )
    errores.push(
      "No se esta enviando ningun campo o se esta enviando en negativo o cero"
    );
  return errores;
}
