import React from 'react';
import { Card, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import LabelTitulos from 'components/layout/labelTitulos';
import LogoGou from 'static/images/logo_gou_2.svg';

export default function modalConfirmarTransaccion({
  open = false,
  content = <div />,
  onAccept,
  onCancel,
  title = 'PortalFactura',
  fullWidth,
  maxWidth = 'sm',
  extraButtons = [],
  fullScreen,
}) {
  return (
    <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} fullScreen={fullScreen}>
      <Card>
        <img src={LogoGou} alt='Logo GOU' className='logo-gou' />
      </Card>
      <DialogTitle style={{ textAlign: 'center' }}>
        <LabelTitulos tipo='encabezado' texto={title} />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogContent>
        <small>
          Descargue
          <a href='https://s3.us-east-2.amazonaws.com/portalfactura.com/public/Documentos/terminos_y_condiciones_portalfactura.pdf' target="_blank" rel="noopener noreferrer">
            {' '}
            AQUÍ{' '}
          </a>
          los terminos y condiciones
        </small>
      </DialogContent>
      <DialogActions>
        {onAccept && <Button onClick={onAccept}>Aceptar</Button>}
        {extraButtons.map((buttonOptions) => (
          <Button onClick={buttonOptions.onClick}>{buttonOptions.name}</Button>
        ))}
        {onCancel && <Button onClick={onCancel}>Cerrar</Button>}
      </DialogActions>
    </Dialog>
  );
}
