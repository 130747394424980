import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Quicksand',
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#92C63E',
      main: '#3C3588',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#F8F8FE',
      main: '#F8F8FE',
      dark: '#ba000d',
      contrastText: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  backgroundColor: {
    default: '#F8F8FE',
  },
});

export default theme;
