import * as React from "react";
import Select from "./Select";
import Unidades from "static/enums/tiposUnidadMedida";

const SelectUnidad = ({ value, ...rest }) => {
  const options = Unidades.map((unidad) => ({
    label: unidad.nombre,
    value: unidad.codigo,
  }));

  return (
    <Select
      options={options}
      value={options.find((o) => o.value === value)}
      {...rest}
    />
  );
};

export default SelectUnidad;
