import * as React from "react";
import { connect } from "react-redux";
import { now } from "utils/DateUtils";
import Select from "components/Inputs/Select";
import { consultarPuntosVentaPos } from "network/Api/PuntoVentaPos";
import { generarConsolidadoVentasPos, generarDetalleCierreCajaPos } from "network/Api/DocumentoPos";
import Input from "components/Inputs/Input";
import { fileDownload } from "utils/BlobUtils";
import Button from 'components/atoms/Button';

const InformeVentasPos = ({ userAttributes }) => {
  const [consulta, setConsulta] = React.useState({ feInicio: now().format("YYYY-MM-01"), feFin: now().format("YYYY-MM-DD"), puntosSelected: [] });
  const [puntos, setPuntos] = React.useState([]);

  React.useEffect(() => {
    consultarPuntosEmisor();
  }, []);

  const updateConsulta = (data) => setConsulta((c) => ({ ...c, ...data }));

  const consultarPuntosEmisor = async () => {
    const { data } = await consultarPuntosVentaPos();
    setPuntos(data.map(({ nmId, dsNombre }) => ({ value: nmId.toString(), label: dsNombre })));
  };

  const handleChangePuntos = ({ value }) => {
    const { puntosSelected } = consulta;
    const prevPunto = puntosSelected.find((ps) => ps === value);
    const newPuntos = prevPunto ? puntosSelected.filter((ps) => ps !== value) : [...puntosSelected, value];
    updateConsulta({ puntosSelected: newPuntos });
  };

  const getValue = () => consulta.puntosSelected.map((ps) => puntos.find((p) => p.value === ps));

  const handleChange = ({ target }) => {
    const { name, value } = target;
    updateConsulta({ [name]: value });
  };

  const validarFormulario = () => {
    const { feInicio, feFin, puntosSelected } = consulta;
    const { codigoEmisor } = userAttributes;
    if (puntosSelected.length < 1) return;
    return { feInicio, feFin, puntosSelected, emisor: codigoEmisor };
  };

  const generarConsolidadoVenta = async () => {
    const body = validarFormulario();
    if (body) {
      const blob = await generarConsolidadoVentasPos(body);
      fileDownload({ blob, name: `consolidado` });
    }
  };

  const generarCierreDetallado = async () => {
    const body = validarFormulario();
    if (body) {
      const blob = await generarDetalleCierreCajaPos(body);
      fileDownload({ blob, name: `cierre` });
    }
  };

  return (
    <div style={{ width: "80%", margin: "0px auto" }}>
      <Select label="Puntos de Venta" options={puntos} onChange={handleChangePuntos} value={getValue()} multi />
      <Input label="Fecha Inicio" type="date" name="feInicio" value={consulta.feInicio} onChange={handleChange} />
      <Input label="Fecha Fin" type="date" name="feFin" value={consulta.feFin} onChange={handleChange} />
      <Button onClick={generarConsolidadoVenta}>Generar Consolidado</Button>
      <Button onClick={generarCierreDetallado}>Generar Cierre</Button>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InformeVentasPos);
