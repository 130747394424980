import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { connect } from "react-redux";
import {
  SetConfirmacionModalState,
  SetOpenModalFiltrosVentas,
  SetSpinnerModal,
} from "reducers/actions/facturaActions";
import { numberFormat } from "utils/funcionesUtiles";
import { GetUserData } from "screens/login/auth/amplifyAuth";
import Encabezado from "./TablaEncabezado";
import TablaToolbar from "./TablaToolBar";
import {
  consultarVentas,
  actualizaTransaccionPersona,
  consultarVentasPorAliado,
} from "network/Api/Persona";
import Button from 'components/atoms/Button';
import Modal from "components/modal/ModalConfirmacion";
import Input from "components/Inputs/Input";
import { consultarPlanPorDistribuidorYTipo } from "network/Api/Plan";
import Select from "components/Inputs/Select";

function ccyFormat(num) {
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return numberFormat(value);
}

class VentasDetalle extends React.Component {
  state = {
    order: "desc",
    orderBy: "feProceso",
    page: 0,
    rowsPerPage: 5,
    selected: [],
    ventas: [],
    fechaInicial: "",
    fechaFinal: "",
    planesSelect: [],
    openModal: false,
  };

  componentDidMount = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), "01")
      .toISOString()
      .split("T")[0];
    var lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      .toISOString()
      .split("T")[0];
    GetUserData().then((result) => {
      const userId = result.attributes["custom:custom:userid"] || null;
      this.setState(
        { fechaInicial: firstDay, fechaFinal: lastDay, userId },
        () => {
          this.consultar();
        }
      );
      consultarPlanPorDistribuidorYTipo("PORTALFACTURA", 1).then((r) => {
        if (r.status === 200) {
          this.setState({
            planesSelect: r.data.map((p) => ({
              value: p.nmId,
              label: `${p.dsDescripcion} - $${ccyFormat(p.nmValor)}`,
            })),
          });
        }
      });
    });
  };

  consultar = async () => {
    const { setSpinnerModal } = this.props;
    const { fechaInicial, fechaFinal, userId } = this.state;
    setSpinnerModal(true);
    const response =
      userId === "SERVICIOCLIENTE"
        ? await consultarVentas({ inicio: fechaInicial, fin: fechaFinal })
        : await consultarVentasPorAliado({
            inicio: fechaInicial,
            fin: fechaFinal,
            aliado: userId,
          });
    setSpinnerModal(false);
    if (response.status === 200) {
      this.setState({ ventas: response.data });
    } else {
      this.mensajes(false, response.data, this.mensajes, true);
    }
  };

  mensajes = (close, texto, onClick, parametro) => {
    let modal;
    if (close) {
      modal = { open: false };
      this.props.setConfirmacionModalState(modal);
      return;
    }

    if (parametro != null) {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(parametro),
        cancelarVisible: true,
      };
    } else {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(),
        cancelarVisible: true,
      };
    }
    this.props.setConfirmacionModalState(modal);
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    let orderFuture = "desc";
    if (orderBy === property && order === "desc") {
      orderFuture = "asc";
    }
    this.setState({ order: orderFuture, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  obtenerEstado = (estado) => {
    switch (estado) {
      case "P":
        return "PENDIENTE";
      case "A":
        return "APROBADO";
      case "F":
        return "FINALIZADO";
      default:
        return "RECHAZADO";
    }
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleClick = (fechaInicial, fechaFinal) => {
    this.setState(
      { fechaInicial: fechaInicial, fechaFinal: fechaFinal },
      () => {
        this.consultar();
        this.props.setOpenModalFiltrosVentas(false);
      }
    );
  };

  actualizarEstado() {
    const { dsIdentificacion, idPlan, idSolicitud } = this.state;
    const { setSpinnerModal } = this.props;
    if (idPlan) {
      this.setState({ openModal: false });
      setSpinnerModal(true);
      actualizaTransaccionPersona({
        dsIdentificacion,
        dsTokenPrimrecar: JSON.stringify({ idPlan, idSolicitud }),
        cdEstadoTransaccion: "A",
      }).then((res) => {
        if (res.status === 200) {
          setSpinnerModal(false);
          this.consultar();
        }
      });
    } else {
      this.mensajes(false, "Por favor selecciona un plan", this.mensajes, true);
    }
  }

  render() {
    const {
      order,
      orderBy,
      rowsPerPage,
      page,
      selected,
      planesSelect,
      ventas,
      openModal,
      userId,
    } = this.state;

    return (
      <Paper>
        <TablaToolbar
          onClick={this.handleClick}
          numSelected={selected.length}
        />
        <div>
          <Table>
            <Encabezado
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={ventas.length}
              userId={userId}
            />
            <TableBody>
              {stableSort(ventas, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((documento) => {
                  return (
                    <TableRow key={documento.nmId} align="center">
                      <TableCell>{documento.dsIdentificacion}</TableCell>
                      <TableCell>{`${
                        documento.dsNombre
                      } ${documento.dsSegundoNombre ||
                        ""} ${documento.dsPrimerApellido ||
                        ""} ${documento.dsSegundoApellido || ""}`}</TableCell>
                      <TableCell>{documento.dsCelular}</TableCell>
                      <TableCell>
                        {documento.feProceso &&
                          documento.feProceso.substring(0, 10)}
                      </TableCell>
                      <TableCell align="center">
                        {documento.dsTokenPrimrecar}
                      </TableCell>
                      <TableCell>
                        {documento.dsValorTransacion == null
                          ? ""
                          : `$${ccyFormat(documento.dsValorTransacion)}`}
                      </TableCell>
                      <TableCell>{documento.dsAliado}</TableCell>
                      <TableCell align="center">
                        {this.obtenerEstado(documento.cdEstadoTransaccion)}
                      </TableCell>
                      {userId === "SERVICIOCLIENTE" && (
                        <TableCell align="center">
                          {
                            <Button
                              onClick={() =>
                                this.setState({
                                  openModal: true,
                                  dsIdentificacion: documento.dsIdentificacion,
                                })
                              }
                              disabled={documento.cdEstadoTransaccion !== "P"}
                            >
                              Actualizar
                            </Button>
                          }
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={ventas.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Página anterior",
              }}
              nextIconButtonProps={{
                "aria-label": "Página siguiente",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        </div>
        <Modal
          content={
            <div style={{ padding: "15px 0px" }}>
              <Select
                label="Seleccione plan comprado *"
                options={planesSelect}
                onChange={(e) => this.setState({ idPlan: e.value })}
              />
              <Input
                label="Token de transaccion"
                onChange={(e) => this.setState({ idSolicitud: e.target.value })}
              />
            </div>
          }
          onAccept={() => this.actualizarEstado()}
          onCancel={() => this.setState({ openModal: false })}
          open={openModal}
          title="Selecciona el plan comprado"
        />
      </Paper>
    );
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function mapStateToProps(state, props) {
  return {
    loading: state.factura.loading,
    userAttributes: state.factura.userAttributes,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  setConfirmacionModalState: (item) =>
    dispatch(SetConfirmacionModalState(item)),
  setOpenModalFiltrosVentas: (item) =>
    dispatch(SetOpenModalFiltrosVentas(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VentasDetalle);
