import React from "react";
import Select from "./Select";
import { getEmpleados } from "network/Api/Empleado";

const OPTIONS_DEFAULT = [
  { label: "Cargando...", value: null },
  { label: "Ninguno", value: null },
];

function getVacio(str) {
  return str ? str : "";
}

export async function getEmpleadosInput(emisor) {
  return await getEmpleados(emisor).then((result) => [
    OPTIONS_DEFAULT[1],
    ...result.data
      .map((empleado) => ({
        label: `${getVacio(empleado.dsNombre)} ${getVacio(
          empleado.dsSegundoNombre
        )} ${getVacio(empleado.dsPrimerApellido)} ${getVacio(
          empleado.dsSegundoApellido
        )} - ${getVacio(empleado.dsIdentificacion)}`,
        value: empleado.nmId,
      }))
      .sort(function(a, b) {
        if (a.label < b.label) return -1;
        else if (a.label > b.label) return 1;
        else return 0;
      }),
  ]);
}

export default function selectEmpleados({ empleados, ...rest }) {
  return <Select options={empleados || OPTIONS_DEFAULT[0]} {...rest} />;
}
