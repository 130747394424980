const tiposReferenciaDocumentos = [
    { codigo: 'BIENES_PROPIOS', nombre: 'BIENES PROPIOS' },
    { codigo: 'MERCANCIAS_ADA', nombre: 'MERCANCIAS ADA' },
    { codigo: 'BIENES_DACION_PAGO', nombre: 'BIENES DACION PAGO' },
    { codigo: 'SELECCION_ABREVIADA_OFERTA', nombre: 'SELECCION ABREVIADA OFERTA' },
    { codigo: 'SELECCION_ABREVIADA_SUBASTA', nombre: 'SELECCION ABREVIADA SUBASTA' },
    { codigo: 'VENTA_INTERMEDIARIO', nombre: 'VENTA INTERMEDIARIO' },
    { codigo: 'VENTA_PROMOTORES', nombre: 'VENTA PROMOTORES' },
    { codigo: 'VENTA_BANCA_INVERSION', nombre: 'VENTA BANCA INVERSION' },
    { codigo: 'VENTA_MARTILLO', nombre: 'VENTA MARTILLO' },
    { codigo: 'VENTA_COMISIONISTA_BOLSA', nombre: 'VENTA COMISIONISTA BOLSA' },
    { codigo: 'VENTA_PRECIOS_FIJOS', nombre: 'VENTA PRECIOS FIJOS' },
    { codigo: 'VENTAS_LEY_80', nombre: 'VENTAS LEY 80' },
    { codigo: 'DONACION_BIENES', nombre: 'DONACION BIENES' },
    { codigo: 'ORDEN_PEDIDO', nombre: 'ORDEN PEDIDO' },
    { codigo: 'SOPORTE_PAGO', nombre: 'SOPORTE PAGO' },
    { codigo: 'CODIGO_ACTIVIDAD_ECONOMICA', nombre: 'CODIGO ACTIVIDAD ECONOMICA' },
    { codigo: 'CARTA_CREDITO', nombre: 'CARTA CREDITO' },
    { codigo: 'ORDEN_ENTREGA', nombre: 'ORDEN ENTREGA' },
    { codigo: 'REFERENCIA_BENEFICIARIO', nombre: 'REFERENCIA BENEFICIARIO' },
    { codigo: 'NUMERO_PLAN', nombre: 'NUMERO PLAN' },
    { codigo: 'INFORME_CONSUMO', nombre: 'INFORME CONSUMO' },
    { codigo: 'REFERENCIA_RECAUDO', nombre: 'REFERENCIA RECAUDO' },
    { codigo: 'REFERENCIA_TRANSACCION', nombre: 'REFERENCIA TRANSACCION' },
    { codigo: 'REFERENCIA_TRANSACCION_INDIVIDUAL', nombre: 'REFERENCIA TRANSACCION INDIVIDUAL' },
    { codigo: 'NUMERO_CONVENIO', nombre: 'NUMERO CONVENIO' },
    { codigo: 'NUMERO_PEDIDO', nombre: 'NUMERO PEDIDO' },
    { codigo: 'NUMERO_DEVOLUCION', nombre: 'NUMERO DEVOLUCION' },
    { codigo: 'ORDEN_COMPRA', nombre: 'ORDEN COMPRA' },
    { codigo: 'VENTA_ENAJENACION', nombre: 'VENTA ENAJENACION' },
    { codigo: 'FACTURA_TALONARIO_CONTINGENCIA', nombre: 'FACTURA TALONARIO CONTINGENCIA' },
    { codigo: 'FACTURA_TALONARIO_PAPEL', nombre: 'FACTURA TALONARIO PAPEL' },
    { codigo: 'FACTURA_TALONARIO_COMPUTADOR', nombre: 'FACTURA TALONARIO COMPUTADOR' },

]

export default tiposReferenciaDocumentos;