/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { SetConfirmacionModalState } from '../../reducers/actions/facturaActions';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/SearchRounded';
import InputAdornment from '@material-ui/core/InputAdornment';

const tituloModal = 'Seleccione un emisor';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: '360px',
        backgroundColor: theme.palette.background.paper,

    },
    head: {
        backgroundColor: "#128BCC",
        textColor: '#fff',
        color: '#fff',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    modal: {
        
        minWidth: '360px',
    }
});

class ResponsiveDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arrayEmisores: [],
            arrayEmisoresNoChange: []
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillMount() {
        const { emisores } = this.props;
        
        this.setState({ arrayEmisores: emisores, arrayEmisoresNoChange: emisores })
    }

    handleClose = () => {
        this.props.dispatch(SetConfirmacionModalState({ open: false }));
    };

    handleChange = (event) => {
        let value = event.target.value.toLowerCase();
        let newArrayEmisores = [];
        const { arrayEmisoresNoChange } = this.state;
        newArrayEmisores = arrayEmisoresNoChange;
        newArrayEmisores = newArrayEmisores.filter(item => {
            if (item.nombresEmisor.toLowerCase().indexOf(value) > -1 || item.identificacionEmisor.toLowerCase().indexOf(value) > -1) {
                return item
            }
        }
        );
        this.setState({ arrayEmisores: newArrayEmisores });
    }

    render() {

        const { arrayEmisores } = this.state;
        
        const { open, classes, onClick, emisores } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={open}
                    aria-labelledby="responsive-dialog-title"
                    className={classes.modal}
                >
                    <DialogTitle className={classes.head} id="responsive-dialog-title"><div className={classes.head}>{tituloModal}</div></DialogTitle>
                    <TextField
                        className={classes.margin}
                        id="txtBusqueda"
                        label="Filtro por nombre o documento"
                        onChange={this.handleChange}
                        autoComplete="off"
                        error={null}
                        autoFocusaa={true}

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Divider />
                    <DialogContent className={classes.modal}>
                        <DialogContentText >
                            <List component="nav" className={classes.root}>
                                {arrayEmisores.length > 0 &&
                                    arrayEmisores.map(item => {
                                        return (
                                            <Fragment>
                                                <ListItem key={item.codigoEmisor} id={item.identificacionEmisor} button onClick={() => onClick(item)}>
                                                    <ListItemText primary={item.nombresEmisor} secondary={item.identificacionEmisor} />
                                                </ListItem>
                                                <Divider />
                                            </Fragment>
                                        )
                                    })
                                }
                            </List>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};


export default withStyles(styles)(withMobileDialog()(ResponsiveDialog));
