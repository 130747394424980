import * as React from "react";
import Select from "./Select";
import { tiposDocumentos } from "static/enums/tiposDocumentos";

const SelectTipoDocumento = ({
  name,
  value,
  onChange,
  isCotizacion = false,
  isExcel = false,
  isDocumentoSoporte = false,
  ...rest
}) => {
  const getTipo = () => {
    if (isCotizacion)
      return tiposDocumentos.filter((tipo) => tipo.isCotizacion);
    if (isExcel) return tiposDocumentos.filter((tipo) => tipo.isExcel);
    if (isDocumentoSoporte)
      return tiposDocumentos.filter((tipo) => tipo.isDocumentoSoporte);
    return tiposDocumentos;
  };

  const mapTipo = () =>
    getTipo().map((tipo) => ({
      label: isCotizacion ? tipo.nombreCotizacion : tipo.nombre,
      value: tipo.idTipo,
    }));

  return (
    <Select
      name={name}
      options={mapTipo()}
      onChange={(event) => onChange({ name, ...event })}
      value={mapTipo().filter((tipo) => tipo.value === value)}
      {...rest}
    />
  );
};

export default SelectTipoDocumento;
