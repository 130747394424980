export const styles = () => ({
  typography: {
    left: 0,
    top: 0,
    bottom: 0,
    margin: 0,
    padding: 0,
    fontFamily: 'Aclonica',
    textAlign: 'center',
    color: '#3C3588',
  },
  labelEncabezado: {
    width: '100%',
    fontWeight: '600',
    fontSize: '1.5rem',
  },
  label: {
    width: '100%',
    color: '#128BCC',
    border: 0,
    fontSize: '16px',
  },
  labelBlanco: {
    fontSize: '1.5rem',
    color: '#FFF',
  },
  labelInput: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#3C3588 !important',
    minWidth: '100px',
    display: 'unset',
  },
});
