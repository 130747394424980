import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = () => ({
  root: {
    width: '324px',
    borderRadius: '10px',
    padding: '8px',
    display: 'flex',
    color: '#333',
    alignItems: 'center',
  },
  img: {
    maxHeight: '50px',
    maxWidth: '50px',
  },
  contentBody: {
    marginLeft: '10px',
  },
  content: {
    margin: '0px',
  },
  link: {
    margin: '10px',
  },
});

const Card = ({
  title,
  content,
  iconImg,
  classes,
  rootColor = '#e8e3f3',
  small = '',
  fullHeigth,
  componentIco,
}) => {
  return (
    <div
      className={classes.root}
      style={{ backgroundColor: rootColor, height: fullHeigth ? '90px' : '' }}
    >
      {iconImg ? (
        <div className='logo'>
          <img src={iconImg} alt={title} className={classes.img} />
        </div>
      ) : componentIco ? (
        componentIco
      ) : (
        <div className={classes.img} />
      )}
      <div className={classes.contentBody}>
        <h6 className='title'>
          <b>{title}</b>
        </h6>
        <h5 className={classes.content} style={{ fontWeight: 600 }}>
          {content}
        </h5>
        <small style={{ textAlign: 'justify', display: 'block' }}>{small}</small>
      </div>
    </div>
  );
};

function HomeCard({ redirect, classes, onClick, ...rest }) {
  return (
    <>
      {redirect ? (
        <Link
          className={classes.link}
          style={{
            textDecoration: 'none',
          }}
          to={redirect}
        >
          <Card classes={classes} {...rest} />
        </Link>
      ) : (
        <div
          className={classes.link}
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onClick={onClick}
        >
          <Card classes={classes} {...rest} />
        </div>
      )}
    </>
  );
}

export default withStyles(useStyles)(HomeCard);
