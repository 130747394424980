import * as React from 'react';
import Select from 'components/molecules/Select';
import { selectOptions as responsabilidadesOptions } from 'static/enums/tiposResponsabilidadesFiscales';
const { useCallback } = React;

function SelectResponsabilidaesFiscales({ value, onChange, ...rest }) {
  const handleChange = useCallback(
    function (event) {
      if (onChange) {
        const { value: newValue } = event;
        const splitedValues = value ? value.split(';') : [];
        const valueExists = splitedValues.some((val) => val === newValue);
        const newValueJoined = (
          valueExists
            ? splitedValues.filter((current) => current !== newValue)
            : [...splitedValues, newValue]
        ).join(';');
        onChange(event, newValueJoined);
      }
    },
    [onChange],
  );

  return (
    <Select
      label='Responsabilidades fiscales *'
      options={responsabilidadesOptions}
      value={value}
      onChange={handleChange}
      multi
      {...rest}
    />
  );
}

export default SelectResponsabilidaesFiscales;
