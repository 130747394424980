const regimenAdquirente = [ 
    {
        codigo: "RESPONSABLE_IVA",
        nombre: "Impuesto sobre las ventas - IVA"
    },
    {
        codigo: "NO_RESPONSABLE_IVA",
        nombre: "No responsable de IVA"
    }
]

export default regimenAdquirente;