import * as React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CardSolicitud from "./CardSolicitud";
import { consultarSolicitudesPorEmisor, actualizarSolicitud, consultarSolicitudInformante } from "network/Api/Solicitud";
import Modal from "components/modal/ModalConfirmacion";
import Comentarios from "./Comentarios";
import getEnvironment from "environments";
import Select from "components/Inputs/Select";
import Input from "components/Inputs/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { styles } from "./styles";
import { getUserData } from "network/Amplify";

const ESTADOS = [
  { label: "Creado", value: "CREADO", defaultCheck: true, order: 1 },
  { label: "En progreso", value: "PROGRESO", defaultCheck: true, order: 2 },
  { label: "En espera", value: "ESPERA", defaultCheck: true, order: 3 },
  { label: "Resuelto", value: "RESUELTO", defaultCheck: false, order: 4 },
  { label: "Cerrado", value: "CERRADO", defaultCheck: false, order: 5 },
];

const RESPONSALBES = [{ label: "Nivel 1", value: "N1" }, { label: "Nivel 2", value: "N2" }];

const GestionarSolicitudes = ({ userAttributes, isMias = false, classes }) => {
  const [state, setData] = React.useState({});
  const { solicitudes = [], open, solicitud = {}, listEstadosVisibles = [] } = state;
  const urlS3 = getEnvironment().urlS3;

  const setState = (s) => setData((d) => ({ ...d, ...s }));

  React.useEffect(() => chargeFilters(), []);

  React.useEffect(() => consultarSolucitudes(), [userAttributes]);

  const chargeFilters = () => setState({ listEstadosVisibles: ESTADOS.filter(({ defaultCheck }) => defaultCheck).map(({ value }) => value) });

  const consultarSolucitudes = () => {
    getUserData().then((user) => {
      const { username } = user;
      const { codigoEmisor } = userAttributes;
      if (codigoEmisor || username === "SERVICIOCLIENTE") {
        const dsInformante = userAttributes["custom:custom:userid"];
        const cod = username === "SERVICIOCLIENTE" ? 1 : codigoEmisor;
        (isMias ? consultarSolicitudInformante({ dsInformante }) : consultarSolicitudesPorEmisor({ codigoEmisor: cod })).then((res) => {
          const { data } = res;
          setState({ solicitudes: data, allSolicitudes: data });
          const { nmId } = solicitud;
          if (nmId) {
            const nueva = data.find((d) => d.nmId === nmId);
            const adNueva = nueva.dsUrlAdjuntos;
            setState({ solicitud: nueva, adjuntosList: adNueva ? adNueva.split(";;;") : null });
          }
        });
      }
    });
  };

  const openModal = (solicitud) => {
    const { dsUrlAdjuntos } = solicitud;
    setState({ open: true, solicitud, adjuntosList: dsUrlAdjuntos ? dsUrlAdjuntos.split(";;;") : null });
  };

  const actualizarEstado = ({ value: cdEstado }) => {
    const { nmId } = solicitud;
    actualizarSolicitud({ nmId, cdEstado }).then(() => {
      consultarSolucitudes();
      setState({ openMessage: true, open: false });
    });
  };

  const actualizarResponsable = ({ value: dsEncargado }) => {
    const { nmId } = solicitud;
    actualizarSolicitud({ nmId, dsEncargado }).then(() => {
      consultarSolucitudes();
      setState({ openMessage: true, open: false });
    });
  };

  const deleteAdjunto = (adjunto) => {
    const { adjuntosList } = state;
    const { nmId } = solicitud;
    const lista = adjuntosList.filter((ad) => adjunto !== ad);
    const dsUrlAdjuntos = lista.join(";;;");
    setState({ adjuntosList: lista });
    actualizarSolicitud({ nmId, dsUrlAdjuntos }).then(() => consultarSolucitudes());
  };

  React.useEffect(() => {
    const { allSolicitudes = [], listEstadosVisibles } = state;
    const solicitudes = allSolicitudes.filter(({ cdEstado }) => listEstadosVisibles.find((ev) => ev === cdEstado));
    setState({ solicitudes });
  }, [state.listEstadosVisibles, state.allSolicitudes]);

  const descargarAdjunto = (url) => window.open(url, "_blank");

  const onCancel = () => setState({ open: false });

  return (
    <>
      <div className={classes.filter}>
        {ESTADOS.map((e, key) => (
          <div key={key} className={classes.filterItem}>
            <input
              checked={listEstadosVisibles.find((s) => s === e.value)}
              id={"check" + key}
              type="checkbox"
              onChange={() => {
                const { listEstadosVisibles } = state;
                const estado = listEstadosVisibles.find((ev) => ev === e.value);
                if (estado) setState({ listEstadosVisibles: listEstadosVisibles.filter((ev) => ev !== estado) });
                else setState({ listEstadosVisibles: [...listEstadosVisibles, e.value] });
              }}
            />
            <label htmlFor={"check" + key}>{e.label}</label>
          </div>
        ))}
      </div>
      <div
        className={classes.gridContainer}
        style={{ gridTemplateColumns: `${100 / listEstadosVisibles.length - 0.5}% `.repeat(listEstadosVisibles.length) }}
      >
        {listEstadosVisibles
          .map((e, key) => {
            const { label, value, order } = ESTADOS.find(({ value }) => value === e);
            return (
              <div key={key} className={"col-grid"} order={order}>
                <b>{label}</b>
                {solicitudes
                  .filter(({ cdEstado }) => value === cdEstado)
                  .map((solicitud, key) => (
                    <CardSolicitud key={key} solicitud={solicitud} responsables={RESPONSALBES} onClick={() => openModal(solicitud)} />
                  ))}
              </div>
            );
          })
          .sort((e1, e2) => e1.props.order > e2.props.order)}
      </div>
      <Modal open={state.openMessage} content={"PQR actualizada"} onCancel={() => setState({ openMessage: false })} />
      <Modal
        fullWidth={true}
        maxWidth="md"
        open={open}
        title={`${solicitud.nmTicket} - ${solicitud.dsAsunto}`}
        content={
          <div className={classes.contenedorSolicitud}>
            <div className={classes.infoSolicitud}>
              <b>Descripción</b>
              <p>{solicitud.dsMensaje}</p>
              <div className={classes.contenedorAdjuntos}>
                <b>Adjuntos</b>
                <div className={classes.allAdjuntos}>
                  {state.adjuntosList &&
                    state.adjuntosList.map((adjunto, key) => (
                      <div key={key} style={{ display: "inline-block" }}>
                        <div className={classes.adjunto}>
                          <div className={classes.buttonsFiles}>
                            {!isMias && (
                              <button onClick={() => deleteAdjunto(adjunto)} className={classes.buttonAdjunto}>
                                <DeleteIcon />
                              </button>
                            )}
                            <button onClick={() => descargarAdjunto(`${urlS3}/${adjunto}`)} className={classes.buttonAdjunto}>
                              <DownloadIcon />
                            </button>
                          </div>
                          <div className={classes.fileNameContainer}>
                            <small>{adjunto.split("|")[1]}</small>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <Comentarios solicitud={solicitud} userAttributes={userAttributes} consultarSolucitudes={consultarSolucitudes} onCancel={onCancel} />
            </div>
            <div style={{ border: ".5px solid #000", margin: "0px 20px" }} />
            <div style={{ width: "20%" }}>
              <Input label="Informante" value={solicitud.dsInformante} disabled />
              <Select
                label="Responsable"
                options={RESPONSALBES}
                onChange={actualizarResponsable}
                defaultValue={RESPONSALBES.find((e) => e.value === solicitud.dsEncargado)}
                isDisabled={isMias}
              />
              <Select
                label="Estado"
                options={ESTADOS}
                onChange={actualizarEstado}
                defaultValue={ESTADOS.find((e) => e.value === solicitud.cdEstado)}
                isDisabled={isMias}
              />
            </div>
          </div>
        }
        onCancel={onCancel}
      />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GestionarSolicitudes));
