import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function deuda({ baseBody, guardarDeduccion, xlCol = "2" }) {
  const [nmDeduccion, setNmDeduccion] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `DEUDA`,
    nmDeduccion,
  };

  const guardar = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDeduccion(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmDeduccion();
  };

  return (
    <form onSubmit={guardar}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago de la Deuda con la Empresa"
            id="deuda"
            value={nmDeduccion || ""}
            onChange={(e) => setNmDeduccion(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Deduccion por Anticipos de Nómina."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmDeduccion || body.nmDeduccion < 1)
    errores.push(
      "El valor de la deduccion no se esta enviando, es cero o negativo"
    );
  return errores;
}
