import React, { Component } from "react";
import packageJson from "../../../package.json";
class FooterComponet extends Component {
  render() {
    let year = new Date().getFullYear();
    return (
      <footer style={{
        textAlign: 'center', bottom: '0',
        width: '100%' }}> © {year} by TECNOFACTOR Todos los derechos reservados - Version:{" "}
        {packageJson.version}
      </footer>  
    );
  }
}

export default FooterComponet;
