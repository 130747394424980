import * as React from 'react';
import Typography from 'components/atoms/Typography';

const customStyle = {
  inputError: {
    border: '2px solid red',
  },
};

const TextArea = ({
  label,
  textMuted,
  type = 'text',
  autoComplete = 'off',
  className = 'form-control',
  sinSalto,
  onChange,
  error,
  style,
  classLabel,
  ...rest
}) => {
  const handleChange = ({ target }) => {
    const { name, value } = target;
    if (sinSalto)
      onChange({
        target: {
          name,
          value: JSON.parse(JSON.stringify(value).replaceAll('\\n', ' ')),
        },
      });
    else onChange({ target });
  };

  return (
    <div>
      {label && (
        <Typography variant='labelInput' className={classLabel}>
          {label}
        </Typography>
      )}
      <div className='form-label-group'>
        <textarea
          style={error ? { ...style, ...customStyle.inputError } : style}
          autoComplete={autoComplete}
          type={type}
          className={className}
          onChange={handleChange}
          {...rest}
        />
        {textMuted && (
          <div style={{ alignItems: 'center', textAlign: 'center' }}>
            <small className='text-muted'>{textMuted}</small>
          </div>
        )}
        {error && <small style={{ color: 'red' }}>{error}</small>}
      </div>
    </div>
  );
};

export default TextArea;
