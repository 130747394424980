import * as React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Select from "components/Inputs/Select";
import Input from "components/Inputs/Input";
import TextArea from "components/Inputs/TextArea";
import InputFile from "components/Inputs/File";
import Button from 'components/atoms/Button';
import { styles } from "./styles";
import { guardarSolicitud } from "network/Api/Solicitud";
import Modal from "components/modal/ModalMensaje";

const tiposPeticion = [
  { label: "Reportar un Problema", value: "REPORTAR" },
  { label: "Solicitud Informacion", value: "SOLICITUD" },
  { label: "Otro", value: "OTRO" },
];

const Solicitud = ({ userAttributes, classes }) => {
  const [state, setData] = React.useState({});

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  React.useEffect(
    () =>
      setState({
        cdTipo: "REPORTAR",
        dsInformante: userAttributes["custom:custom:userid"],
        dsUsuarioInformante: userAttributes["custom:custom:username"],
      }),
    [userAttributes]
  );

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  const handleChangeFiles = ({ target }) => {
    const { name, files } = target;
    setState({
      [name]: files,
      labelFiles: Array.from(files)
        .map((file) => file.name)
        .join(" - "),
    });
  };

  const enviar = () => {
    const { dsAdjuntos, cdTipo, dsAsunto, dsMensaje, dsInformante, dsUsuarioInformante } = state;
    guardarSolicitud({
      files: dsAdjuntos,
      jsonSolicitud: JSON.stringify({
        cdTipo,
        dsAsunto,
        dsMensaje,
        dsInformante,
        dsUsuarioInformante,
        emisor: { nmId: 1 },
        dsEncargado: "N1",
      }),
    }).then(() => {
      setState({
        open: true,
        cdTipo: "REPORTAR",
        dsAsunto: "",
        labelFiles: "",
        dsMensaje: "",
        dsAdjuntos: null,
      });
    });
  };

  const handleClose = () => {
    window.location.href = "/mis-solicitudes";
  };

  return (
    <div className={classes.formSolicitud}>
      <Modal open={state.open} content="PQR enviada" handleClose={handleClose} />
      <div className={classes.columnaData}>
        <Select
          label="Tipo petición"
          name="cdTipo"
          options={tiposPeticion}
          value={tiposPeticion.find((tp) => tp.value === state.cdTipo)}
          onChange={({ value }) => setState({ cdTipo: value })}
        />
        <Input label="Asunto" name="dsAsunto" onChange={handleChange} value={state.dsAsunto} />
        <TextArea label="Mensaje" name="dsMensaje" onChange={handleChange} value={state.dsMensaje} className={`form-control ${classes.textArea}`} />
        <InputFile label="Anexos" name="dsAdjuntos" onChange={handleChangeFiles} inputText={state.labelFiles} multiple />
        <div className={classes.buttonContainer}>
          <Button onClick={enviar}>Enviar</Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Solicitud));
