import ReactDomServer from 'react-dom/server';

export const fileDownload = ({ type = 'application/pdf', blob, name }) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const file = new Blob([blob], { type });
  const url = URL.createObjectURL(file);
  a.href = url;
  a.id = name;
  a.download = name;
  a.click();
  document.body.removeChild(a);
};

export const printHTML = (html) => {
  const popupWin = window.open();
  popupWin.document.open();
  popupWin.document.write(html);
  popupWin.document.close();
  popupWin.onload = () => {
    popupWin.print();
  };
};

export const printReactComponent = function (ComponenteReact) {
  const htmlText = ReactDomServer.renderToStaticMarkup(ComponenteReact);
  printHTML(htmlText);
};

export const downloadImage = ({ href, name = 'imagen.png' }) => {
  const link = document.createElement('a');
  link.href = href;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
