import * as React from "react";
import { getTime } from "utils/DateUtils";
import Button from 'components/atoms/Button';
import EnviarFactura from "@material-ui/icons/AssignmentTurnedIn";
import { guardarCotizacion } from "network/Api/CotizarDocumento";
import { generarTokenRegistroDoc as generarToken } from "network/Api/Generic";
import Loader from "components/Loaders/Loader";

const ButtonCotizar = ({
  openModal,
  limpiarFormulario,
  userAttributes,
  documento,
}) => {
  const [loading, setLoading] = React.useState(false);

  const emviarDocumento = async () => {
    setLoading(true);
    const { codigoEmisor, identificacionEmisor } = userAttributes;
    const body = await completarDocumento(codigoEmisor, identificacionEmisor);
    guardarCotizacion({
      emisor: { nmId: codigoEmisor },
      dsNombre: body.nombresAdquiriente,
      dsIdentificacion: body.identificacionAdquiriente,
      nmTotal: getTotal(body),
      dsJsonDocumento: JSON.stringify(body),
    });
    openModal({
      content: "Documento Guardado",
      onAccept: limpiarFormulario,
      onCancel: limpiarFormulario,
    });
    setLoading(false);
  };

  const validarCotizacion = () => {
    setLoading(true);
    const errores = validarDocumento();
    if (errores.length === 0) {
      setLoading(false);
      openModal({ content: "¿Guardar Cotización?", onAccept: emviarDocumento });
    } else {
      setLoading(false);
      openModal({
        content: `Cotización con errores: ${errores.join(" - ")}`,
      });
    }
  };

  const getTotal = (body) =>
    body.detalles.reduce((total, detalle) => {
      const {
        precioUnitario,
        cantidad,
        valorTotalDescuento,
        porcentajeIva,
        porcentajeConsumo,
      } = detalle;
      const valorBruto = precioUnitario * cantidad;
      const valorBase = valorBruto - valorTotalDescuento;
      const valorIva = (parseFloat(porcentajeIva) * valorBase) / 100;
      const valorConsumo = (parseFloat(porcentajeConsumo) * valorBase) / 100;
      return total + (valorBase + valorIva + valorConsumo);
    }, 0);

  const validarDocumento = () => {
    const errores = [];
    const addError = (error) => errores.push(error);
    if (!documento.detalles || !documento.detalles.length)
      addError("No se a cargado ningun detalle");
    if (!documento.pago || !documento.pago.formaPago)
      addError("No se a seleccionado una forma de pago");
    if (!documento.pago || !documento.pago.medioPago)
      addError("No se a seleccionado un medio de pago");
    if (!documento.emailAdquiriente)
      addError("El email del adquirente es obligatorio");
    return errores;
  };

  const completarDocumento = async (id, identificacion) => {
    const { data } = await generarToken({ id, identificacion });
    const {
      dsPrefijo,
      fechaEmision,
      fechaVencimiento,
      ciudadAdquiriente,
      pago,
      snConsecutivoAutomatico,
    } = documento;
    return {
      ...documento,
      dsPrefijo: dsPrefijo !== "SIN_PREFIJO" ? dsPrefijo : "",
      emisorId: data,
      fechaEmision: null,
      fechaVencimiento: null,
      fechaCotizacion: `${fechaEmision} ${getTime()}`,
      pago: {
        ...pago,
        fechaVencimientoPago: `${fechaVencimiento} ${getTime()}`,
      },
      ciudadAdquiriente: {
        cdDane:
          ciudadAdquiriente.departamento.cdDane + ciudadAdquiriente.cdDane,
      },
      snConsecutivoAutomatico:
        snConsecutivoAutomatico === "S" ? "true" : "false",
    };
  };

  return (
    <div className="form-label-group">
      <Button
        style={{ backgroundColor: "#92C63E" }}
        onClick={validarCotizacion}
        disabled={loading}
      >
        {loading && <Loader type="spinner-border-sm" />}
        <EnviarFactura /> COTIZAR
      </Button>
    </div>
  );
};

export default ButtonCotizar;
