import { serviceRoutes, post } from './index';

export const habilitarEmisorNomina = async ({ nmId }) =>
  await post(serviceRoutes.habilitarEmisorNomina, {
    nmId: parseInt(nmId),
  });

export const habilitarEmisorFacturacion = async ({ nmId }) =>
  await post(serviceRoutes.habilitarEmisorFacturacion, {
    nmId: parseInt(nmId),
  });

export const habilitarEmisorPos = async ({ nmId }) =>
  await post(serviceRoutes.habilitarEmisorPos, {
    nmId: parseInt(nmId),
  });
