import * as React from 'react';
import Modal from 'components/molecules/Modal';
import Button from 'components/atoms/Button';
import EnviarFactura from '@material-ui/icons/AssignmentTurnedIn';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { consultarAdquirentePorIdentificacion } from 'network/Api/Adquirente';
import { consultarParametroPorNombre } from 'network/Api/Parametro';
import { consultarSaldoEmisor } from 'network/Api/SaldoPos';
import { registrarRecarga } from 'network/Api/Recarga';
import { enviar } from 'network/Api/DocumentoPos';
import CurrencyInput from 'react-currency-input-field';
import { handleKeyPressDecimal } from 'utils/funcionesUtiles';
import { connect } from 'react-redux';
import { printHTML } from 'utils/BlobUtils';
import { now } from 'utils/DateUtils';
import Detalles from './Detalles';
import Botonera from './Botonera';
import BeneficiosComprador from 'components/templates/BeneficiosComprador';
import InformacionCaja from 'components/templates/InformacionCajaVenta';
import base from 'static/baseJson/documento-pos.json';
import SelectProductos from 'components/organisms/SelectProductos';
import Input from 'components/molecules/Input';
import FormularioOtroImpuesto from 'components/templates/FormularioOtroImpuesto';
import Typography from 'components/atoms/Typography';
import { styles } from './styles';
import SelectMedioPago from 'components/organisms/SelectMedioPago';
import SelectFormaPago from 'components/organisms/SelectFormaPago';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
const { useState, useEffect } = React;

function DocumentoPos({ classes, userAttributes }) {
  const [basePosDocument, setBasePosDocument] = useState(base);

  const [documento, setDocumento] = useState({ netoMostrar: 0, totalPagado: 0 });
  const [producto, setProducto] = useState({});
  const [modal, setModal] = useState({ open: false });
  const [tabs, setDataTab] = useState({ selected: 0, allTabs: [] });
  const [valorICA, setValorICA] = useState();

  useEffect(() => {
    const { codigoEmisor } = userAttributes;
    if (codigoEmisor) {
      handleBlurIdentificacion();
      setDocumento((d) => ({ ...d, codigoEmisor }));
    }
  }, [userAttributes]);

  useEffect(() => {
    const { totalPagado, netoMostrar = 0 } = documento;
    setProducto({});
    let totalBruto = 0,
      totalDescuento = 0,
      totalIva = 0,
      valorNeto = 0,
      impuestosRetenciones = [];
    (documento.detalles || []).forEach((detalle) => {
      totalBruto += detalle.valorBruto;
      totalDescuento += detalle.valorTotalDescuento;
      totalIva += detalle.valorTotalImpuestosRetenciones;
      valorNeto = totalBruto + totalIva - totalDescuento;
    });
    if (!isNaN(valorICA)) {
      impuestosRetenciones = impuestosRetencionPosICA(totalBruto - totalDescuento, valorICA);
    }
    setDocumento((d) => ({
      ...d,
      totalBruto,
      totalDescuento,
      totalIva,
      valorNeto,
      netoMostrar: valorNeto,
      totalCambio: (totalPagado - netoMostrar).toFixed(0),
      impuestosRetenciones,
    }));
  }, [documento.detalles]);

  useEffect(() => {
    const { selected } = tabs;
    const oldTabs = JSON.parse(localStorage.getItem('tabs'));
    if (oldTabs) {
      const tab = oldTabs.allTabs.at(selected);
      setDocumento(tab ? tab.doc : basePosDocument);
      setDataTab(oldTabs);
    } else {
      setDocumento(basePosDocument);
    }
  }, []);

  const handleChangeProducto = (_, producto) => {
    const { snIvaIncluido } = producto;
    setProducto({
      nmId: producto.nmId,
      codigoArticulo: producto.dsCodigo,
      precioUnitario: producto.nmValorUnitario,
      cantidad: 1,
      porcentajeIva: producto.nmPorcentajeIva,
      porcentajeConsumo: producto.nmPorcentajeInc,
      valorTotalDescuento: 0,
      descripcionArticulo: producto.dsDescripcion,
      snIvaIncluido,
    });
  };

  const handleChange = (event) => {
    const label = event.target.name;
    const value = event.target.value;
    setDocumento((d) => ({ ...d, [label]: value }));
  };

  const handleChangeNumber = (event) => {
    const value = event.target.value;
    if (!/\D/.test(value)) handleChange(event);
  };

  const handleChangeTotalPagado = (name, value) => {
    const { detalles } = documento;
    if (detalles.length) {
      setDocumento((d) => ({ ...d, [name]: value }));
    }
  };

  const handleChangeDetalle = (event) => {
    const label = event.target.name;
    const value = event.target.value;
    setProducto((d) => ({ ...d, [label]: value }));
  };

  const addDetalle = () => {
    if (producto.cantidad) {
      const {
        precioUnitario: precioUnitarioIva,
        cantidad,
        porcentajeIva,
        valorTotalDescuento,
        porcentajeConsumo,
        otroImpuestoValor,
        otroImpuestoVolumen,
        otroImpuestoPorcentaje,
        otroImpuesto,
        snIvaIncluido,
      } = producto;
      if (otroImpuesto) {
        if (!otroImpuestoPorcentaje) {
          let errores = validarImpuestos(otroImpuestoVolumen, otroImpuestoValor);
          if (errores.length > 0) {
            openModal({
              title: 'Fallo en validaciones',
              content: errores.join(' - '),
              onAccept: closeModal,
            });
            return;
          }
        }
      }
      const precioUnitario = snIvaIncluido
        ? precioUnitarioIva / ((porcentajeIva + 100) / 100)
        : precioUnitarioIva;
      const valorBruto = precioUnitario * cantidad;
      const valorBase = valorBruto - valorTotalDescuento;
      const valorTotalIva = (porcentajeIva * valorBase) / 100;
      const valorTotalInc = (porcentajeConsumo * valorBase) / 100;
      const otroImpuestoTotal = otroImpuestoPorcentaje
        ? (valorBase * otroImpuestoPorcentaje) / 100
        : otroImpuesto === 'IBUA'
          ? (otroImpuestoValor * otroImpuestoVolumen) / 100
          : otroImpuestoValor * otroImpuestoVolumen;
      let reteICA = 0;
      if (!isNaN(valorICA)) {
        reteICA = impuestosRetencionPosICA(valorBase, valorICA)[0].valorTotal;
      }
      setDocumento((p) => ({
        ...p,
        detalles: [
          ...p.detalles.map((producto, key) => ({
            ...producto,
            id: key,
          })),
          {
            ...producto,
            id: p.detalles.length + 1,
            valorBruto,
            valorTotalImpuestosRetenciones:
              parseFloat(valorTotalIva) +
              parseFloat(valorTotalInc) +
              parseFloat(otroImpuestoTotal || 0) -
              parseFloat(reteICA),
            valorBase,
            otroImpuestoTotal,
            precioUnitario,
          },
        ],
      }));
    }
  };

  const impuestosRetencionPosICA = (valorBase, porcentaje) => {
    const valorIca = parseFloat((porcentaje * valorBase) / 100);
    return [
      {
        valorTotal: formatearDecimales(valorIca / 10, 4),
        subtotales: [
          {
            valorBase: formatearDecimales(valorBase, 4),
            valorImpuestoRetencion: formatearDecimales(valorIca / 10, 4),
            porcentaje: formatearDecimales(porcentaje, 3),
            tributo: 'RETE_ICA',
          },
        ],
      },
    ];
  };

  const formatearDecimales = (num, decimales) => {
    if (num === '') {
      num = 0;
    }
    num = parseFloat(num);
    let value = `${num.toFixed(decimales)}`;
    return value;
  };

  const validarSaldoEmisor = async () => {
    const { codigoEmisor } = userAttributes;
    const documentosFaltantes = await consultarParametroPorNombre({
      parametro: 'DOCUMENTOS_FALTANTES_RECARGA',
    });
    const { data } = await consultarSaldoEmisor({ emisor: codigoEmisor });
    return data <= documentosFaltantes.data;
  };

  const comprarDocumento = async () => {
    const plan = await consultarParametroPorNombre({ parametro: 'PLAN_RECARGA_UNO' });
    const { codigoEmisor } = userAttributes;
    const { data, status } = await registrarRecarga({
      emisor: { nmId: codigoEmisor },
      plan: { nmId: plan.data },
      cdCanal: 'PARALELA',
    });
    if (status === 200) {
      openModal(
        data
          ? {
              content: 'Compra realizada correctamente',
              onAccept: enviarDocumento,
              onCancel: enviarDocumento,
            }
          : {
              content: 'Saldo insuficiente',
              onCancel: closeModal,
            },
      );
    }
  };

  const validarEnvioDocumento = async () => {
    const errores = validarDocumento();
    if (errores.length === 0) {
      const isEnvioValido = await validarSaldoEmisor();
      openModal({
        content: !isEnvioValido ? '¿Enviar documento?' : '¿Comprar 1 documento?',
        onAccept: !isEnvioValido ? enviarDocumento : comprarDocumento,
        onCancel: closeModal,
      });
    } else {
      openModal({
        title: 'Error en validaciones',
        content: errores.join(' - '),
        onAccept: closeModal,
      });
    }
  };

  const validarImpuestos = (otroImpuestoVolumen, otroImpuestoValor) => {
    let errores = [];
    if (!otroImpuestoValor) errores.push('Es obligatorio diligenciar el campo Valor unitario');
    if (!otroImpuestoVolumen) errores.push('Es obligatorio diligenciar el campo Volumen');
    return errores;
  };

  const validarDocumento = () => {
    let errores = [];
    if (documento.detalles.length === 0)
      errores.push('Es obligatorio enviar detalles en el documento');
    if (!documento.nombresAdquiriente) errores.push('El nombre del adquirente es obligatorio');
    if (!documento.identificacionAdquiriente)
      errores.push('La identificacion del adquirente es obligatoria');
    if (!documento.beneficiosComprador.codigo)
      errores.push('El código del comprador es obligatorio');
    if (!documento.beneficiosComprador.puntos)
      errores.push('Los puntos del comprador son obligatorios');
    if (!documento.informacionCajaVenta.placaCaja)
      errores.push('La placa de la caja es obligatoria');
    if (!documento.informacionCajaVenta.ubicacionCaja)
      errores.push('La ubicación de la caja es obligatoria');
    if (!documento.informacionCajaVenta.cajero)
      errores.push('El código del empleado es obligatorio');
    if (!documento.informacionCajaVenta.tipoCaja) errores.push('El tipo de caja es obligatorio');
    if (!documento.informacionCajaVenta.codigoVenta)
      errores.push('El código de la venta es obligatorio');
    return errores;
  };

  const enviarDocumento = async () => {
    setModal((m) => ({ ...m, disableButtons: true }));
    const fechaEmision = now().format('YYYY-MM-DD HH:MM:ss');
    const { status, data } = await enviar({ ...documento, fechaEmision });
    if (status === 200) {
      printHTML(data);
      openModal({
        content: 'Documento enviado correctamente',
        onAccept: () => {
          if (tabs.allTabs.length > 0) {
            deleteTab();
            closeModal();
          } else window.location.reload();
        },
        disableButtons: false,
      });
    } else {
      openModal({
        content: 'Error enviando documento',
        onAccept: closeModal(),
        onCancel: closeModal(),
      });
    }
  };

  const openModal = ({ title, content, onAccept, onCancel, fullScreen = false }) =>
    setModal({ open: true, title, content, onAccept, onCancel, fullScreen });

  const closeModal = () => setModal((m) => ({ ...m, open: false, disableButtons: false }));

  const setTabs = (dt) =>
    setDataTab((t) => {
      const final = { ...t, ...dt };
      localStorage.setItem('tabs', JSON.stringify(final));
      return final;
    });

  const addTab = () => {
    setDataTab((t) => {
      const { selected, allTabs, newName } = t;
      let final = { selected, allTabs };
      if (newName)
        final = {
          selected,
          allTabs: [...allTabs, { name: newName, doc: basePosDocument }],
        };
      return final;
    });
  };

  const deleteTab = () => {
    setDataTab((t) => {
      const { selected, allTabs } = t;
      const newTabs = allTabs.map((at, i) => i !== selected && at).filter((at) => at);
      setDocumento(newTabs.length > 0 ? newTabs.at(0).doc : basePosDocument);
      const final = { selected: 0, allTabs: newTabs };
      return final;
    });
  };

  const changeTab = (_, tab) => {
    const { selected } = tabs;
    const { allTabs } = JSON.parse(localStorage.getItem('tabs'));
    const prevTab = { ...allTabs.at(selected), doc: documento };
    const thisTab = allTabs.at(tab);
    setDocumento(thisTab.doc);
    const final = {
      selected: tab,
      allTabs: allTabs.map((at, index) => (index === selected ? prevTab : at)),
    };
    setTabs(final);
  };

  useEffect(() => {
    const { selected, allTabs } = tabs;
    if (selected !== undefined && allTabs && allTabs.length > 0) {
      const thisTab = { ...allTabs.at(selected), doc: documento };
      const newAllTabs = allTabs.map((tab, index) => (index === selected ? thisTab : tab));
      const newTabs = { selected, allTabs: newAllTabs };
      setTabs(newTabs);
    }
  }, [documento]);

  useEffect(() => {
    localStorage.setItem('tabs', JSON.stringify(tabs));
  }, [tabs]);

  const handleBlurIdentificacion = async () => {
    const { identificacionAdquiriente } = documento;
    const data = await consultarAdquirente(identificacionAdquiriente);
    if (data) {
      setDocumento((d) => ({ ...d, ...data }));
      setBasePosDocument((d) => ({ ...d, ...data }));
    }
  };

  const consultarAdquirente = async (identificacion) => {
    const { codigoEmisor: id } = userAttributes;
    const { data } = await consultarAdquirentePorIdentificacion({ id, identificacion });
    if (data) {
      const { dsNombre, dsIdentificacion, cdTipoIdentificacion, dsEmail, dsDireccion, dsTelefono } =
        data;
      return {
        nombresAdquiriente: dsNombre,
        identificacionAdquiriente: dsIdentificacion,
        telefonoAdquiriente: dsTelefono,
        emailAdquiriente: dsEmail,
        direccionAdquiriente: dsDireccion,
        tipoIdentificacionAdquiriente: cdTipoIdentificacion,
      };
    }
  };

  const handleChangeDescuentos = (name, value) => {
    const { detalles } = documento;
    if (detalles.length > 0) {
      const valueNumeric = !value ? 0 : parseInt(value);
      setDocumento((d) => ({ ...d, [name]: value }));
      if (name === 'descuentoTotal') {
        if (valueNumeric >= parseInt(documento.totalBruto)) {
          openModal({
            content: (
              <p>
                El valor del descuento no puede ser superior al valor bruto del documento ($
                {documento.totalBruto})
              </p>
            ),
            onAccept: closeModal,
          });
          setDocumento((d) => ({ ...d, [name]: 1 }));
        }
        if (valueNumeric < documento.totalBruto) {
          setDocumento((d) => ({
            ...d,
            porcentajeDescuento: null,
            netoMostrar: d.valorNeto - valueNumeric,
          }));
        }
      }
      if (name === 'porcentajeDescuento') {
        if (valueNumeric > 30) setDocumento((d) => ({ ...d, [name]: 30 }));
        if (valueNumeric <= 30) {
          setDocumento((d) => ({
            ...d,
            descuentoTotal: null,
            netoMostrar: d.valorNeto - (d.valorNeto * value) / 100,
          }));
        }
      }
    }
  };

  const handleChangeValorICA = (event) => {
    const { value } = event.target;
    setValorICA(value);
  };

  const handleBlurValorICA = () => {
    let newValue = parseFloat(valorICA);
    if (isNaN(newValue)) newValue = 0;
    if (newValue < 0) newValue = 0;
    if (newValue > 100) newValue = 100;
    setValorICA(Math.trunc(newValue * 1000) / 1000);
  };

  useEffect(() => {
    setDocumento((d) => ({ ...d, totalCambio: d.totalPagado - d.netoMostrar }));
  }, [documento.netoMostrar, documento.totalPagado]);

  const handleChangePago = (event) => {
    const { value, name } = event;
    setDocumento((d) => {
      const { pago = {} } = d;
      return { ...d, pago: { ...pago, [name]: value } };
    });
  };

  const closeBotonera = (detalles) => {
    setDocumento((d) => ({ ...d, detalles }));
    closeModal();
  };

  return (
    <div className='space-header'>
      <div className='form-row' style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Input
          label='Nombre de la cuenta'
          className='form-group col-md-3'
          value={tabs.newName}
          onChange={({ target }) => setTabs({ newName: target.value })}
        />
        <div className='form-group col-md-auto'>
          <Button onClick={addTab}>Agregar Cuenta</Button>
        </div>
        <div className='form-group col-md-auto'>
          <Button onClick={deleteTab}>Eliminar Cuenta</Button>
        </div>
      </div>
      {tabs.allTabs.length > 0 && (
        <AppBar position='static'>
          <Tabs
            value={tabs.selected}
            onChange={changeTab}
            variant='scrollable'
            scrollButtons='auto'
          >
            {tabs.allTabs.map(({ name }, index) => (
              <Tab label={name} id={`Tab${index}`} key={index} />
            ))}
          </Tabs>
        </AppBar>
      )}
      <div className='from-row style-movil'>
        <div className='col-lg-8'>
          <div className={`form-row ${classes.borderedContainer}`}>
            <SelectFormaPago
              label='Forma de pago'
              value={(documento.pago || {}).formaPago}
              onChange={handleChangePago}
              name='formaPago'
              className='form-group col-lg-6'
            />
            <SelectMedioPago
              label='Medio de pago'
              value={(documento.pago || {}).medioPago}
              onChange={handleChangePago}
              type='pos'
              name='medioPago'
              className='form-group col-lg-6'
            />
            <Input
              label='Nombre'
              onChange={handleChange}
              name='nombresAdquiriente'
              value={documento.nombresAdquiriente}
              className='form-group col-lg-4'
              readOnly
              require
            />
            <SelectTipoIdentificacion
              label='Tipo Identificación'
              onChange={(e) => setDocumento((d) => ({ ...d, [e.name]: e.value }))}
              name='tipoIdentificacionAdquiriente'
              value={documento.tipoIdentificacionAdquiriente}
              className='form-group col-lg-4'
            />
            <Input
              label='Identificación'
              onBlur={handleBlurIdentificacion}
              onChange={handleChangeNumber}
              name='identificacionAdquiriente'
              value={documento.identificacionAdquiriente}
              className='form-group col-lg-4'
              require
            />
            <Input
              label='Teléfono'
              onChange={handleChange}
              name='telefonoAdquiriente'
              value={documento.telefonoAdquiriente}
              className='form-group col-lg-6'
              require
              readOnly
            />
            <Input
              label='Email'
              onChange={handleChange}
              name='emailAdquiriente'
              value={documento.emailAdquiriente}
              className='form-group col-lg-6'
              require
            />
            <div className='form-group col-12'>
              <Typography variant='label'>Observacion*</Typography>
              <textarea
                className='form-control'
                onChange={(e) => {
                  if (e.target.value.length <= 200) handleChange(e);
                }}
                name='dsObservacion'
                value={documento.dsObservacion}
              />
            </div>
          </div>

          <div className={`form-row justify-content-center ${classes.borderedContainer}`}>
            <SelectProductos
              className='form-group col-lg-7'
              label='Código - Producto'
              idEmisor={userAttributes.codigoEmisor}
              onChange={handleChangeProducto}
              value={producto.nmId}
            />
            <Input
              label='Cantidad'
              className='form-group col-lg-2'
              value={producto.cantidad}
              name='cantidad'
              onChange={handleChangeDetalle}
              validacion='only-num-decimals'
            />
            <Input
              label='Precio Unitario'
              className='form-group col-lg-3'
              value={producto.precioUnitario}
              name='precioUnitario'
              onChange={handleChangeDetalle}
              validacion='only-num-decimals'
              currency
            />
            <FormularioOtroImpuesto
              className='form-row col-lg-10'
              otroImpuesto={producto}
              handleChangeInput={handleChangeDetalle}
              handleChangeSelect={({ value }) =>
                setProducto((d) => ({ ...d, otroImpuesto: value }))
              }
            />
            <Input
              label='Porcentaje ReteICA'
              className='form-row col-lg-2'
              value={valorICA}
              min='0'
              max='100'
              placeholder='%'
              validacion='only-num-decimals'
              onKeyPress={handleKeyPressDecimal}
              onChange={handleChangeValorICA}
              onBlur={handleBlurValorICA}
              id='valorICA'
            />

            <div className='form-group col-md-auto'>
              <Button
                onClick={() =>
                  openModal({
                    content: (
                      <Botonera
                        classes={classes}
                        closeModal={closeBotonera}
                        codigoEmisor={userAttributes.codigoEmisor}
                        oldDetalles={documento.detalles}
                      />
                    ),
                    fullScreen: true,
                    title: 'Productos',
                  })
                }
              >
                <b>VER PRODUCTOS</b>
              </Button>
            </div>
            <div className='form-group col-md-auto'>
              <Button onClick={addDetalle}>Agregar</Button>
            </div>
          </div>
        </div>
        <div className='col-lg-4 align-items-center justify-content-center'>
          <BeneficiosComprador
            lg={12}
            className={classes.borderedContainer}
            documento={documento}
            setDocumento={setDocumento}
          />
          <InformacionCaja
            lg={12}
            className={classes.borderedContainer}
            documento={documento}
            setDocumento={setDocumento}
          />
          <Detalles classes={classes} documento={documento} setDocumento={setDocumento} />
          <div className='form-row justify-content-center'>
            <div className='form-group col-lg-5'>
              <Typography variant='labelInput'>Descuento en pesos</Typography>
              <CurrencyInput
                className='input-money'
                name='descuentoTotal'
                value={documento.descuentoTotal || ''}
                decimalsLimit={2}
                onValueChange={(value, name) => handleChangeDescuentos(name, value)}
                decimalSeparator=','
                groupSeparator='.'
                prefix='$'
              />
            </div>
            <div className='form-group col-lg-5'>
              <Input
                label='Descuento en %'
                value={documento.porcentajeDescuento || ''}
                name='porcentajeDescuento'
                onChange={(e) => handleChangeDescuentos(e.target.name, e.target.value)}
              />
            </div>
            <div className='form-group col-lg-4'>
              <Typography variant='labelInput'>Total Pagado</Typography>
              <CurrencyInput
                className='input-money'
                name='totalPagado'
                value={documento.totalPagado || ''}
                decimalsLimit={2}
                onValueChange={(value, name) => handleChangeTotalPagado(name, value)}
                decimalSeparator=','
                groupSeparator='.'
                prefix='$'
              />
            </div>
            <div className='form-group col-lg-4'>
              <Typography variant='labelInput'>Total Cambio</Typography>
              <CurrencyInput
                className='input-money'
                name='totalPagado'
                readOnly
                value={documento.totalCambio || 0}
                decimalsLimit={2}
                decimalSeparator=','
                groupSeparator='.'
                prefix='$'
              />
            </div>
          </div>
          <div
            className='form-row col-12 align-items-center justify-content-center'
            style={{ marginTop: '30px' }}
          >
            <Button className={classes.buttonSended} onClick={validarEnvioDocumento}>
              <EnviarFactura /> Imprimir Cuenta
            </Button>
          </div>
        </div>
      </div>
      <Modal {...modal} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentoPos));
