import { serviceRoutes, get } from "./index";

export const generarTokenRegistroDoc = async ({ id, identificacion }) =>
  await get(
    serviceRoutes.generarTokenRegistroDoc,
    `?id=${id}&identificacion=${identificacion}`
  );

export const getEnum = async (name) =>
  await get(serviceRoutes.getEnum, `?name=${name}`);
