import React from 'react';

const descuentoDefault = { codigoDescuento: 'DESCUENTO_NO_CONDICIONADO', tipo: false };
const cargoDefault = { tipo: true };

const useCargoDescuento = (isCargo, valorBase) => {
  const defaultValues = React.useMemo(() => (isCargo ? cargoDefault : descuentoDefault), [isCargo]);
  const [cargoDescuento, setData] = React.useState(defaultValues);

  const setCargoDescuento = (data) => setData((cd) => ({ ...cd, ...data }));

  const handleChange = ({ target }) => {
    const { value, name } = target;
    switch (name) {
      case 'porcentaje':
        setCargoDescuento({
          valorBase,
          porcentaje: value,
          valor: valorBase * (value / 100),
        });
        break;
      case 'valor':
        setCargoDescuento({
          valorBase,
          valor: value,
          porcentaje: (value * 100) / valorBase,
        });
        break;
      default:
        setCargoDescuento({ [name]: value });
        break;
    }
  };

  const limpiar = () => setData(defaultValues);

  return { cargoDescuento, handleChange, limpiar };
};

export default useCargoDescuento;
