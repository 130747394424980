import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SetOpenModalFiltrosVentas } from 'reducers/actions/facturaActions';
import CamposFiltros from './CamposFiltros';

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

class TablaToolbar extends Component {
    handleClickFiltro = ()=>{
        this.props.setOpenModalFiltrosVentas(true);
    }

    
    render() {
        const { numSelected, classes, onClick } = this.props;
        return (
            <Fragment>
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                <Tooltip onClick={this.handleClickFiltro} title="Filtro de lista">
                        <IconButton aria-label="Filtro de lista">
                            Filtrar por fecha<FilterListIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                </div>
            </Toolbar>
            <CamposFiltros onClick={onClick} />
            </Fragment>
        )
    }
}

TablaToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

function mapStateToProps(state, props) {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
    setOpenModalFiltrosVentas: (item) => dispatch(SetOpenModalFiltrosVentas(item)),
})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(toolbarStyles)(TablaToolbar));