import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import LabelTitulos from 'components/layout/labelTitulos';
import { registrarProducto } from 'network/Api/Producto';
import Form from './Formulario';
const { useCallback } = React;

const GUARDADO_EXITOSO = '¡El producto se guardo exitosamente!';

function ModalAddProducto({
  onConsulta,
  onClose,
  userAttributes,
  onOpenModal,
  setSpinnerModal,
  filter,
  open = false,
  ...rest
}) {
  const guardarProducto = useCallback(
    async (producto) => {
      setSpinnerModal(true);
      const { codigoEmisor: nmId } = userAttributes;
      const { status, message = '' } = await registrarProducto({ ...producto, emisor: { nmId } });
      setSpinnerModal(false);
      if (status === 200) {
        onOpenModal({
          open: true,
          text: GUARDADO_EXITOSO,
          onClick: () => onOpenModal({ open: false }),
        });
        onConsulta(filter);
        onClose();
      } else {
        onOpenModal({
          open: true,
          text: message.includes('Duplicate')
            ? `Codigo del producto registrado anteriormente`
            : `Ocurrió un error guardando el producto, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte.`,
          onClick: () => onOpenModal({ open: false }),
        });
      }
    },
    [userAttributes],
  );

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      scroll='paper'
      fullWidth={true}
      maxWidth='xl'
      open={open}
      {...rest}
    >
      <DialogTitle id='form-dialog-title'>
        <LabelTitulos texto='Crear nuevo producto' />
      </DialogTitle>
      <DialogContent className='mb-5'>
        <Form
          handleClose={onClose}
          handleSave={guardarProducto}
          openModal={onOpenModal}
          nmIdEmisor={userAttributes.codigoEmisor}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ModalAddProducto;
