import { serviceRoutes, get, post, put } from "./index";

export const consultarResolucionAcumulada = ({ emisor }) =>
  get(serviceRoutes.resolucionesSoporteVigentes, `?emisor=${emisor}`);

export const guardarNuevaResolucionSoporte = (body) =>
  post(serviceRoutes.guardarNuevaResolucionSoporte, body);

export const actualizarListaResolucionesSoporte = (body) =>
  put(serviceRoutes.actualizarListaResolucionesSoporte, body);
