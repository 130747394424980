import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import VentasDetalle from './VentasDetalle';
import 'components/Factura/styles.css';
import { connect } from 'react-redux'
import { SetConfirmacionModalState, SetLoadingValue, Logout, SetSpinnerModal } from 'reducers/actions/facturaActions';
import ConfirmacionModal from 'components/modals/confirmacionModal';

const styles = theme => ({
    root: {
        flexGrow: 2,
        maxWidth: '100%',
    },
    fontFamily: {
        fontFamily: 'Quicksand'
    },
});

class Ventas extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <form className={classes.fontFamily}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <VentasDetalle />
                        </Grid>
                    </Grid>
                </form>
                <ConfirmacionModal />
            </div>
        )
    }
}

Ventas.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
    return {
        loading: state.factura.loading,
        userAttributes: state.factura.userAttributes,
    }
}

const mapDispatchToProps = (dispatch) => ({
    setLoadingValue: (item) => dispatch(SetLoadingValue(item)),
    setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
    setInitialState: () => dispatch(Logout()),
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ventas));