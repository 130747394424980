import * as React from 'react';
import Button from 'components/atoms/Button';
import AcuseCarga from 'static/images/acuseCarga.png';
import Aceptado from 'static/images/aceptado.png';
import Recibido from 'static/images/recibido.png';
import Rechazado from 'static/images/rechazado.png';
import Icon from 'components/menu/Icon';
import Modal from 'components/modal/ModalConfirmacion';
import Select from 'components/Inputs/Select';
import { codigosRechazo } from 'static/enums/CodigoRechazoGasto';
import { actualizarEventoGasto } from 'network/Api/Gasto';

const defaultState = {
  open: false,
};

const colors = {
  completado: '#27AE60',
  invalido: '#95A5A6',
  completadoR: '#E74C3C',
};

const completado = { disabled: true, color: colors.completado };
const completadoR = { disabled: true, color: colors.completadoR };
const invalido = { disabled: true, color: colors.invalido };
const seleccionable = { disabled: false };

const ActualizarEstadoEvento = ({ gasto, consultarGastos, loading, openModal }) => {
  const [state, setState] = React.useState(defaultState);

  const setModal = (m) => setState((s) => ({ ...s, ...m }));

  const actualizarEstado = ({ gasto, estadoEnvio }) => {
    const { codigoRechazo } = state;
    if (estadoEnvio === 'RECLAMO' && !codigoRechazo) return;
    loading(true);
    actualizarEventoGasto({
      id: gasto.id,
      codigorechazo: codigoRechazo,
      cdEstado: estadoEnvio,
    }).then(({ data }) => {
      openModal(data === 'EXITOSO' ? 'Actualiza estado XML exitosamente' : 'Error enviando XML');
      consultarGastos();
      loading(false);
      setModal({ open: false, codigoRechazo: null });
    });
  };

  const getAcuseCarga = () => {
    const { cdEstado, cdEstadoRespuesta } = gasto;
    if (cdEstado === 'ACUSE_DOCUMENTO')
      return cdEstadoRespuesta === 'EXITOSO' ? completado : seleccionable;
    return completado;
  };

  const getRecibido = () => {
    const { cdEstado, cdEstadoRespuesta } = gasto;
    if (cdEstado === 'ACUSE_DOCUMENTO')
      return cdEstadoRespuesta === 'EXITOSO' ? seleccionable : invalido;
    if (cdEstado === 'RECIBO_SERVICIO' && cdEstadoRespuesta !== 'EXITOSO') return seleccionable;
    return completado;
  };

  const getAceptacion = () => {
    const { cdEstado, cdEstadoRespuesta } = gasto;
    if (cdEstado === 'RECIBO_SERVICIO')
      return cdEstadoRespuesta === 'EXITOSO' ? seleccionable : invalido;
    if (cdEstado === 'ACEPTACION_EXPRESA')
      return cdEstadoRespuesta === 'EXITOSO' ? completado : seleccionable;
    return invalido;
  };

  const getReclamo = () => {
    const { cdEstado, cdEstadoRespuesta } = gasto;
    if (cdEstado === 'RECIBO_SERVICIO')
      return cdEstadoRespuesta === 'EXITOSO' ? seleccionable : invalido;
    if (cdEstado === 'RECLAMO')
      return cdEstadoRespuesta === 'EXITOSO' ? completadoR : seleccionable;
    return invalido;
  };

  return (
    <div>
      <Button
        disabled={getAcuseCarga().disabled}
        style={{ backgroundColor: getAcuseCarga().color, margin: '3px' }}
        onClick={() => actualizarEstado({ gasto, estadoEnvio: 'ACUSE_DOCUMENTO' })}
      >
        <Icon src={AcuseCarga} />
      </Button>
      <Button
        disabled={getRecibido().disabled}
        style={{ backgroundColor: getRecibido().color, margin: '3px' }}
        onClick={() => actualizarEstado({ gasto, estadoEnvio: 'RECIBO_SERVICIO' })}
        title='Notificar Recibido'
      >
        <Icon src={Recibido} />
      </Button>
      <Button
        disabled={getAceptacion().disabled}
        style={{ backgroundColor: getAceptacion().color, margin: '3px' }}
        onClick={() => actualizarEstado({ gasto, estadoEnvio: 'ACEPTACION_EXPRESA' })}
        title='Aceptar Documento'
      >
        <Icon src={Aceptado} />
      </Button>
      <Button
        disabled={getReclamo().disabled}
        style={{ backgroundColor: getReclamo().color, margin: '3px' }}
        onClick={() => setModal({ open: true })}
        title='Rechazar Documento'
      >
        <Icon src={Rechazado} />
      </Button>
      <Modal
        open={state.open}
        title='Selecciona motivo del rechazo'
        content={
          <div style={{ height: '230px' }}>
            <Select
              label='Causa Rechazo'
              options={codigosRechazo}
              onChange={({ value }) => setModal({ codigoRechazo: value })}
            />
          </div>
        }
        onAccept={() => actualizarEstado({ gasto, estadoEnvio: 'RECLAMO' })}
        onCancel={() => setModal({ open: false })}
      />
    </div>
  );
};

export default ActualizarEstadoEvento;
