import React, { Component } from 'react';

const style = {
  labelEncabezado: {
    left: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    color: '#3C3588',
    textAlign: 'center',
    fontFamily: 'Aclonica',
    margin: 0,
    padding: 0,
    fontSize: '1.5rem',
  },
  label: {
    left: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    color: '#128BCC',
    textAlign: 'center',
    fontFamily: 'Aclonica',
    margin: 0,
    padding: 0,
    border: 0,
  },
  labelBlanco: {
    left: 0,
    top: 0,
    bottom: 0,
    fontSize: '1.5rem',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Aclonica',
    margin: 0,
    padding: 0,
  },
};

class Label extends Component {
  handleStyleTipo = (tipo) => {
    switch (tipo) {
      case 'encabezado':
        return style.labelEncabezado;
      case 'enabezadoBlanco':
        return style.labelBlanco;
      default:
        return style.label;
    }
  };

  render() {
    const { texto, tipo, ...rest } = this.props;
    return (
      <label style={this.handleStyleTipo(tipo)} {...rest}>
        {texto}
      </label>
    );
  }
}

export default Label;
