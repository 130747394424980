import * as React from "react";
import Button from 'components/atoms/Button';
import EnviarFactura from "@material-ui/icons/AssignmentTurnedIn";
import { generarTokenRegistroDoc as generarToken } from "network/Api/Generic";
import Loader from "components/Loaders/Loader";
import { registrarDocumentoExpress } from "network/Api/DocumentoExpress";
import { registrarActualizarAdquirente } from "network/Api/Adquirente";
import { getTime } from "utils/DateUtils";
import { TIPOS_DATOS, validarString } from "utils/ValidarDatos";

const ButtonEnviar = ({ openModal, limpiarFormulario, emisor, documento, identificacionEmisor }) => {
  const [loading, setLoading] = React.useState(false);

  const enviarDocumento = async () => {
    setLoading(true);
    const documento = await completarDocumento(emisor, identificacionEmisor);
    registrarDocumentoExpress({ documento, serial: documento.serial });
    openModal({
      content: "Enviando documento, podra ver el resultado en la pantalla de documentos enviados",
      onAccept: limpiarFormulario,
      onCancel: limpiarFormulario,
    });
    setLoading(false);
  };

  const validarEnvio = () => {
    setLoading(true);
    const errores = validarDocumento();
    if (errores.length === 0) {
      setLoading(false);
      openModal({ content: "¿Enviar Documento?", onAccept: enviarDocumento });
    } else {
      setLoading(false);
      openModal({ content: `Documento con errores: ${errores.join(" - ")}` });
    }
  };

  const registrarActualizarVendedor = () => {
    openModal({
      content: "¿Desea actualizar o registrar cliente?",
      onAccept: () => {
        registrarActualizarAdquirente(documento);
        validarEnvio();
      },
      onCancel: validarEnvio,
    });
  };

  const validarDocumento = () => {
    const { tipoDocumentoElectronico, dsNumeroFactura, dsPrefijo } = documento;
    const errores = [];
    const addError = (error) => errores.push(error);
    validarAdquirente(addError);
    if (!documento.detalles || !documento.detalles.length) addError("No se a cargado ningun detalle");
    if (!documento.pago || !documento.pago.formaPago) addError("No se a seleccionado una forma de pago");
    if (!documento.pago || !documento.pago.medioPago) addError("No se a seleccionado un medio de pago");
    if (!documento.dsResolucionDian) addError("La Resolucion es obligatoria");
    if (!documento.tipoOperacion) addError("El tipo operacion es obligatorio");
    if (tipoDocumentoElectronico === "NOTA_CREDITO") {
      if (!dsNumeroFactura) addError("El consecutivo del documento es obligatorio");
      if (!dsPrefijo || !validarString(dsPrefijo, TIPOS_DATOS.texto)) addError("El prefijo es obligatorio y no puede tener caracteres numericos");
    }
    return errores;
  };

  const validarAdquirente = (addError) => {
    if (!documento.ciudadAdquiriente) addError("La ciudad del cliente es obligatoria");
    if (!documento.emailAdquiriente) addError("El email del cliente es obligatorio");
    if (!documento.nombresAdquiriente) addError("El nombre del cliente es obligatorio");
    if (documento.tipoPersonaAdquiriente === "NATURAL" && !documento.primerApellido)
      addError("El primer apellido del cliente es obligatorio cuando no es persona natural");
    if (!documento.tipoOperacion) addError("El tipo de operacion es obligatoria");
    if (!documento.responsabilidadesFiscales) addError("Las responsabilidades fiscales son obligatorias");
  };

  const completarDocumento = async (id, identificacion) => {
    const { data } = await generarToken({ id, identificacion });
    const { dsPrefijo, fechaVencimiento, ciudadAdquiriente, pago, snConsecutivoAutomatico, fechaEmision, dsNumeroFactura } = documento;
    const time = getTime();
    return {
      ...documento,
      dsPrefijo: dsPrefijo !== "SIN_PREFIJO" ? dsPrefijo : "",
      dsNumeroFactura: dsNumeroFactura ? dsNumeroFactura : null,
      emisorId: data,
      fechaEmision: fechaEmision + " " + time,
      fechaVencimiento: fechaVencimiento,
      pago: { ...pago, fechaVencimientoPago: `${fechaVencimiento + " " + time}` },
      ciudadAdquiriente: { cdDane: ciudadAdquiriente.departamento.cdDane + ciudadAdquiriente.cdDane },
      snConsecutivoAutomatico: snConsecutivoAutomatico === "S" ? "true" : "false",
    };
  };

  return (
    <div className="form-label-group">
      <Button style={{ backgroundColor: "#92C63E" }} onClick={registrarActualizarVendedor} disabled={loading}>
        {loading && <Loader type="spinner-border-sm" />}
        <EnviarFactura /> Enviar Documento
      </Button>
    </div>
  );
};

export default ButtonEnviar;
