import * as React from "react";
import { Paper } from "@material-ui/core";
import Input from "components/Inputs/Input";
import Select from "components/Inputs/Select";
import EstandarProducto from "static/enums/tiposEstandarProducto";

const InfoExportacion = ({ detalle, setDetalle }) => {
  const getOptions = () =>
    EstandarProducto.map((ep) => ({
      label: ep.nombre,
      value: ep.codigo,
    }));

  const getValue = (value) => getOptions().filter((ep) => ep.value === value);

  return (
    <Paper>
      <div className="form-row col-md-12 d-flex justify-content-center">
        <label className="text-muted">Información para exportación</label>
      </div>
      <div className="form-row col-md-12 d-flex justify-content-center">
        <div className="form-group col-md-4">
          <Input
            label="Marca del producto"
            name="nombreMarca"
            value={detalle.nombreMarca}
            disabled={true}
          />
        </div>
        <div className="form-group col-md-4">
          <Input
            label="Modelo del producto"
            value={detalle.nombreModelo}
            name="nombreModelo"
            disabled={true}
          />
        </div>
        <div className="form-group col-md-4">
          <Select
            label="Estandar del producto"
            onChange={({ value }) => setDetalle({ estandarProducto: value })}
            options={getOptions()}
            placeholder="Estandar del producto..."
            value={getValue(detalle.estandarProducto)}
          />
        </div>
      </div>
    </Paper>
  );
};

export default InfoExportacion;
