import * as React from "react";
import Button from 'components/atoms/Button';
import DeleteIcon from "@material-ui/icons/Delete";

const ButtonLimpiar = ({ openModal, limpiarFormulario }) => {
  return (
    <div className="form-label-group">
      <Button
        style={{ backgroundColor: "#92C63E" }}
        onClick={() =>
          openModal({
            content: "¿Limpiar todos los campos?",
            onAccept: limpiarFormulario,
          })
        }
      >
        <DeleteIcon />
        LIMPIAR
      </Button>
    </div>
  );
};

export default ButtonLimpiar;
