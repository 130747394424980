import * as React from 'react';
import Select from 'components/molecules/Select';
import Input from 'components/molecules/Input';
import SelectCiudades from 'components/organisms/SelectCiudades';
import { selectOptionsNatural as tipoIdentificacionNatural } from 'static/enums/tiposIdentificacion';
import { sexoOptions } from 'static/enums/sexoOptions';
import { tiposUsuarioSalud } from 'static/enums/TipoUsuarioSalud';
import Modal from 'components/molecules/Modal';
import useModal from 'hooks/useModal';
const { useState, useEffect, useCallback } = React;

const DEFAULT = {};

const formulariodatosUsuarios = () => {
  const [datosUsuarios, setDatDatosUsuarios] = useState(DEFAULT);
  const [modalProps, openModal, closeModal] = useModal();

  useEffect(() => {
    callOpenmodal();
    setdatosUsuarios({});
  }, []);

  const setdatosUsuarios = (data) => setDatDatosUsuarios((a) => ({ ...a, ...data }));

  const handleChange = useCallback(
    ({ target }) => {
      callOpenmodal();
      const { name, value } = target;
      setdatosUsuarios({ [name]: value });
    },
    [setdatosUsuarios],
  );

  function callOpenmodal() {
    openModal({
      textAlignContent: 'justify',
      content:
        'Según el Ministerio de Salud y Protección Social, se modifican las Resoluciones 2275 de 2023 y 558 de 2024 en relación con la transición prevista para la implementación del RIPS como soporte de la FEV en salud, y la plataforma del mecanismo único de validación estará en funcionamiento a partir del 1 de febrero de 2025.',
      onCancel: null,
    });
  }

  return (
    <form>
      <div className='form-row'>
        <div className='col-md-3'>
          <Select
            label='Tipo identificación *'
            placeholder='Seleccione un tipo'
            classLabel='labelInput'
            name='cdTipoIdentificacion'
            onChange={({ value }) => setdatosUsuarios({ cdTipoIdentificacion: value })}
            options={tipoIdentificacionNatural}
            value={datosUsuarios.cdTipoIdentificacion}
          />
        </div>
        <div className='col-md-3'>
          <Input
            label='Nro. Documento *'
            onChange={handleChange}
            value={datosUsuarios.numDocumentoIdentificacion}
            name='numDocumentoIdentificacion'
            maxLength='35'
            validacion='only-num-text'
            required
          />
        </div>
        <div className='col-md-3'>
          <Select
            label='Tipo persona'
            placeholder='Seleccione un tipo'
            name='tipoUsuario'
            options={tiposUsuarioSalud}
            onChange={({ value }) => setdatosUsuarios({ tipoUsuario: value })}
            value={datosUsuarios.tipoUsuario}
          />
        </div>
        <div className='col-md-3'>
          <Input
            label='Fecha de nacimiento del usuario'
            name='fechaNacimiento'
            onChange={handleChange}
            type='date'
            value={datosUsuarios.fechaNacimiento}
          />
        </div>
        <div className='col-md-3'>
          <Select
            label='Sexo'
            placeholder='Seleccione un tipo'
            name='codSexo'
            options={sexoOptions}
            onChange={({ value }) => setdatosUsuarios({ codSexo: value })}
            value={datosUsuarios.codSexo}
          />
        </div>
        <div className='col-md-3'>
          <SelectCiudades
            label='Ciudad *'
            placeholder='Seleccióne una ciudad'
            name={`ciudad`}
            value={datosUsuarios.ciudad ? datosUsuarios.ciudad.nmId : undefined}
            onChange={(_, ciudad) => setdatosUsuarios({ ciudad })}
          />
        </div>
      </div>
      <Modal
        title='Funcionalidad en Progreso - En funcionamiento el 1 de febrero de 2025'
        onAccept={closeModal}
        {...modalProps}
      />
    </form>
  );
};

export default formulariodatosUsuarios;
