import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export default function otroConcepto({
  baseBody,
  guardarDevengado,
  xlCol = "2",
}) {
  const [dsDescripcionConcepto, setDsDescripcionConcepto] = useState();
  const [nmConceptoS, setNmConceptoS] = useState();
  const [nmConceptoNS, setConceptoNS] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `OTRO_CONCEPTO`,
    dsDescripcionConcepto,
    nmConceptoS,
    nmConceptoNS,
  };

  const guardarOtroConcepto = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setDsDescripcionConcepto();
    setNmConceptoS();
    setConceptoNS();
  };

  return (
    <form onSubmit={guardarOtroConcepto}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Descripcion del concepto *"
            value={dsDescripcionConcepto || ""}
            onChange={(e) => setDsDescripcionConcepto(e.target.value)}
            tooltip="Nombre del Concepto que corresponde a los demás pagos fijos o variables realizados al trabajador que remuneren en dinero o en especie como contraprestación directa del servicio, sea cualquiera la forma o denominación que se adopte."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago por Conceptos Salariales*"
            value={nmConceptoS || ""}
            onChange={(e) => setNmConceptoS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor de los demás pagos fijos o variables realizados al trabajador que remuneren en dinero o en especie como contraprestación directa del servicio, sea cualquiera la forma o denominación que se adopte (Salarial)."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Pago por Conceptos No Salariales*"
            value={nmConceptoNS || ""}
            onChange={(e) => setConceptoNS(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Valor de los demás pagos que ocasionalmente y por mera liberalidad recibe el trabajador del empleador, en dinero o en especie no para su beneficio, ni para enriquecer su patrimonio, sino para desempeñar a cabalidad sus funciones (No Salarial)."
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.dsDescripcionConcepto)
    errores.push("La descripcion es obligatoria");
  if (
    (!body.nmConceptoS || body.nmConceptoS < 0) &&
    (!body.nmConceptoNS || body.nmConceptoNS < 0)
  )
    errores.push(
      "El concepto salarial o no salarial es obligatorio y debe ser cero o mayor"
    );
  return errores;
}
