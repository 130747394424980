import React from 'react';
import Input from 'react-currency-input-field';

function InputCurrency({
  prefix = '$',
  groupSeparator = '.',
  decimalSeparator = ',',
  decimalsLimit = 2,
  onChange,
  className = 'form-control',
  ...rest
}) {
  return (
    <Input
      decimalsLimit={decimalsLimit}
      onValueChange={onChange}
      decimalSeparator={decimalSeparator}
      groupSeparator={groupSeparator}
      prefix={prefix}
      className={className}
      {...rest}
    />
  );
}

export default InputCurrency;
