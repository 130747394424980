import React, { useCallback } from 'react';
import { descargarDocumento } from 'network/Api/BucketS3Aws';
import { fileDownload } from 'utils/BlobUtils';
import Button from 'components/atoms/Button';

const URL_PRIVATE = process.env.REACT_APP_PUBLIC_URL;

const ButtonDescargaArchivos = ({
  text,
  fileName,
  icon,
  onFailDownload,
  onFailDownloadMessage = 'Ocurrió un error descargando el documento, por favor contacte con soporte',
}) => {
  const handleDownload = useCallback(async () => {
    if (fileName) {
      const url = new URL(fileName, URL_PRIVATE);
      const pathName = url.pathname.replace('/', '');
      const spliterd = pathName.split('/');
      const response = await descargarDocumento({ fileName: pathName });
      if (response.size > 0) {
        fileDownload({ blob: response, name: spliterd[spliterd.length - 1], type: null });
      } else {
        alert(onFailDownloadMessage);
        if (onFailDownload) onFailDownload();
      }
    }
  }, [fileName]);

  return (
    <Button onClick={handleDownload} disabled={!fileName} variant='text'>
      {text}
      <span style={{ marginLeft: '3px' }}>{icon}</span>
    </Button>
  );
};

export default ButtonDescargaArchivos;
