import * as React from "react";
import Tabs from 'components/molecules/Tabs';
import UnicoEnvio from "./UnicoEnvio";
import EnvioMultiple from "./EnvioMultiple";

const DocumentoExcel = () => {
  return (
    <Tabs
      tabNames={["Unico Envio", "Envio Multiple"]}
      tabComponents={[<UnicoEnvio />, <EnvioMultiple />]}
      defaultTab={0}
    />
  );
};

export default DocumentoExcel;
