export const codigosPais = [
    {id:"1", label: "Colombia", value: "57" },
    { id: "2", label: "Argentina", value: "54" },
    { id: "3", label: "Bolivia", value: "591" },
    { id: "4", label: "Brasil", value: "	55" },
    { id: "5", label: "Ecuador", value: "593" },
    { id: "6", label: "Paraguay", value: "595" },
    { id: "7", label: "Perú", value: "51" },
    { id: "8", label: "Uruguay", value: "598" },
    { id: "9", label: "Venezuela", value: "58" },
    { id: "10", label: "Canada", value: "1" },
    { id: "111", label: "Estados Unidos", value: "+1" },
    { id: "12", label: "Mexico", value: "52" },
    { id: "13", label: "Costa Rica", value: "506" },
    { id: "14", label: "Cuba", value: "53" },
    { id: "15", label: "Guatemala", value: "502" },
    { id: "16", label: "Honduras", value: "504" },
    { id: "17", label: "Jamaica", value: "876" },
    { id: "18", label: "Nicaragua", value: "505" },
    { id: "19", label: "Panamá", value: "507" },
    { id: "20", label: "Puerto Rico", value: "1787" },
    { id: "21", label: "República Dominicana", value: "809" }]