import React from 'react';

const useFormularioContactoEmisor = (defaultContacto = {}) => {
  const [contacto, setDataContacto] = React.useState(defaultContacto);

  const setContacto = (dc) => setDataContacto((c) => ({ ...c, ...dc }));

  const handleChange = ({ target: { name, value } }) => {
    setContacto({ [name]: value });
  };

  return {
    contacto,
    setContacto,
    handleChange,
  };
};

export default useFormularioContactoEmisor;
