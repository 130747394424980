import React, { Component } from "react";
import moment from "moment";
import { Drawer, Button } from "@material-ui/core";
import LabelTitulos from "./labelTitulos";
import { actualizarMensajesALeidos } from "network/Api/Mensaje";

const style = {
  notificacion: {
    boxShadow: "0px 0px 15px -5px rgba(0,0,0,0.75)",
    margin: "10px 0px",
    padding: "5px",
    borderRadius: "5px",
    maxWidth: "300px",
  },
};

export default class DrawerMensajes extends Component {
  state = {
    mensajesActualizar: [],
  };

  handleActualizarMensaje = () => {
    const { handleConsulta, handleClose } = this.props;
    const { mensajesActualizar } = this.state;
    actualizarMensajesALeidos(mensajesActualizar).then((_) => handleConsulta());
    handleClose();
  };

  handlePushMensaje = (mensaje) => {
    const { mensajesActualizar } = this.state;
    this.setState({
      mensajesActualizar: [...mensajesActualizar, { ...mensaje, snLeido: "S" }],
    });
    document.getElementById(`notify-${mensaje.nmId}`).style.backgroundColor =
      "#eceff1";
    document.getElementById(`button-${mensaje.nmId}`).style.display = "none";
  };

  render() {
    const { isOpen, mensajes } = this.props;
    return (
      <Drawer open={isOpen} anchor={"right"}>
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={this.handleActualizarMensaje}>Cerrar</Button>
          </div>
          <LabelTitulos texto="Notificaciones" tipo="encabezado" />
          <ul>
            {Array.isArray(mensajes) &&
              mensajes.map((mensaje) => (
                <li key={mensaje.nmId}>
                  <div
                    id={`notify-${mensaje.nmId}`}
                    style={
                      mensaje.snLeido === "S"
                        ? { ...style.notificacion, backgroundColor: "#eceff1" }
                        : { ...style.notificacion }
                    }
                  >
                    <LabelTitulos texto={mensaje.dsTitulo} />
                    <p>{mensaje.dsMensaje}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      <h6 style={{ fontSize: ".7rem" }}>
                        Fecha notificacion:{" "}
                        {moment(mensaje.feCreacion).format("DD/MM/YYYY")}
                      </h6>
                      {mensaje.snLeido === "N" && (
                        <button
                          id={`button-${mensaje.nmId}`}
                          style={{
                            backgroundColor: "#92C63E",
                            color: "white",
                            boder: "none",
                            fontSize: ".8rem",
                            padding: "2px",
                          }}
                          className="btn btn-md"
                          onClick={() => this.handlePushMensaje(mensaje)}
                        >
                          marcar como leido
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </Drawer>
    );
  }
}
