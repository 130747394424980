import * as React from 'react';
import InputFile from 'components/Inputs/File';
import Input from './Input';
import Loader from 'components/Loaders/LoadingScreen';
import { TIPOS_DATOS, validarString } from 'utils/ValidarDatos';
import { calcularDetalleExcel } from 'network/Api/Documento';
import Button from 'components/atoms/Button';
import Typography from 'components/atoms/Typography';

const relacionNumColumna = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const defaultMultiple = {
  codigoColum: 'K',
  cantidadColum: 'O',
  precioColum: 'P',
  descuentoColum: 'U',
  adquirenteColum: 'D',
  numFacColum: 'C',
};

const defaultSimple = {
  codigoColum: 'A',
  cantidadColum: 'C',
  precioColum: 'D',
  descuentoColum: 'E',
};

const AgregarDetalles = (props) => {
  const { setDocumento, emisor, openModal, isCargaMasiva = false } = props;
  const [state, setData] = React.useState({
    initialRow: 7,
    finalRow: 8,
    loading: false,
    tecnologicoExcentoIva: null,
    ...(isCargaMasiva ? defaultMultiple : defaultSimple),
  });

  const setState = (data) => setData((d) => ({ ...d, ...data }));

  const agregarDetalles = async () => {
    setState({ loading: true });
    const file = state.file;
    const errores = validarCarga({ file });
    if (errores.length === 0) {
      calcularDetalleExcel({
        file,
        emisor,
        config: JSON.stringify(getConfig()),
        isMultiple: isCargaMasiva,
      }).then(({ data, status }) => {
        if (status === 200) {
          const { detalles, codigosNoEncontrados, documentos } = data;
          setDocumento({ detalles, multiplesDocumentos: documentos });
          if (codigosNoEncontrados.length)
            openModal({
              content: `Los productos con los códigos ${codigosNoEncontrados.join(
                ', ',
              )} no fueron encontrados`,
            });
        } else {
          openModal({
            content: `Error generando los detalles, si el error persiste por favor contáctese con soporte`,
          });
        }
        setState({ loading: false });
      });
    } else {
      openModal({
        content: `Error al cargar el documento: ${errores.join(', ')}`,
      });
      setState({ loading: false });
    }
  };

  const getConfig = () => ({
    initialRow: state.initialRow - 1,
    finalRow: state.finalRow - 1,
    codigoColum: relacionNumColumna.indexOf(state.codigoColum),
    cantidadColum: relacionNumColumna.indexOf(state.cantidadColum),
    precioColum: relacionNumColumna.indexOf(state.precioColum),
    descuentoColum: relacionNumColumna.indexOf(state.descuentoColum),
    adquirenteColum: relacionNumColumna.indexOf(state.adquirenteColum),
    numFacColum: relacionNumColumna.indexOf(state.numFacColum),
  });

  const validarCarga = ({ file }) => {
    const correctFileType = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    const isIncomplete =
      !state.initialRow ||
      !state.finalRow ||
      !state.codigoColum ||
      !state.cantidadColum ||
      !state.precioColum ||
      !state.descuentoColum;
    const errores = [];
    const addError = (error) => errores.push(error);
    if (!correctFileType.filter((f) => f === file.type)[0])
      addError('El archivo debe tener la extension .xlsx');
    if (isIncomplete) addError('Faltan campos necesarios para la lectura del archivo');
    else {
      if (!parseInt(state.initialRow) > 0)
        addError('La posición de inicio no puede ser inferior a 1');
      if (!findLeter(state.codigoColum))
        addError('La columna del código se encuentra fuera del rango A-Z');
      if (!findLeter(state.cantidadColum))
        addError('La columna de la cantidad se encuentra fuera del rango A-Z');
      if (!findLeter(state.precioColum))
        addError('La columna del precio se encuentra fuera del rango A-Z');
      if (!findLeter(state.descuentoColum))
        addError('La columna del descuento se encuentra fuera del rango A-Z');
      if (parseInt(state.finalRow) - parseInt(state.initialRow) > 100)
        addError('Excede el límite de 100 registros.');
    }
    return errores;
  };

  const findLeter = (l) => relacionNumColumna.filter((letter) => letter === l)[0];

  const handleChangeRow = ({ target }) => {
    const { name, value } = target;
    if (validarString(value, TIPOS_DATOS.numeros) || value === '')
      if (parseInt(value) > 0 || value === '') setState({ [name]: value });
  };

  const handleChangeCol = ({ target }) => {
    const { name, value } = target;
    if (findLeter(value.toUpperCase()) || value === '') setState({ [name]: value.toUpperCase() });
  };

  const handleChangeFile = ({ target }) => {
    const file = target.files[0];
    setState({ file, fileName: file.name });
  };

  return (
    <div className='form-row'>
      <div className='form-group col-md-12 mt-0 mb-0'>
        <Typography>Estructura del Excel</Typography>
      </div>
      <div className='form-row col-md-12'>
        <Input
          label='Fila de inicio *'
          value={state.initialRow}
          name='initialRow'
          onChange={handleChangeRow}
          tooltip=''
        />
        <Input
          label='Fila de finalización *'
          value={state.finalRow}
          name='finalRow'
          onChange={handleChangeRow}
          tooltip=''
        />
        {isCargaMasiva && (
          <>
            <Input
              label='Num Factura *'
              value={state.numFacColum}
              name='numFacColum'
              onChange={handleChangeCol}
              tooltip=''
            />
            <Input
              label='Identificacion Adquirente *'
              value={state.adquirenteColum}
              name='adquirenteColum'
              onChange={handleChangeCol}
              tooltip=''
            />
          </>
        )}
        <Input
          label='Código del producto *'
          value={state.codigoColum}
          onChange={handleChangeCol}
          name='codigoColum'
          tooltip=''
        />
        <Input
          label='Precio Unitario *'
          value={state.precioColum}
          onChange={handleChangeCol}
          name='precioColum'
          tooltip=''
        />
        <Input
          label='Cantidad *'
          value={state.cantidadColum}
          onChange={handleChangeCol}
          name='cantidadColum'
          tooltip=''
        />
        <Input
          label='Valor del Descuento *'
          value={state.descuentoColum}
          onChange={handleChangeCol}
          name='descuentoColum'
          tooltip=''
        />
        <div className='form-row col-xl-12 col-sm-12'>
          <div className='form-group col-xl-10 col-sm-10'>
            <InputFile
              label='Selecciona el archivo *'
              inputText={state.fileName}
              onChange={handleChangeFile}
            />
          </div>
          <div
            className='form-group col-xl-2 col-sm-2'
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Button disabled={!state.file} onClick={agregarDetalles}>
              Generar Detalles
            </Button>
          </div>
        </div>
      </div>
      <Loader open={state.loading} />
    </div>
  );
};

export default AgregarDetalles;
