export const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 1,
    overflowX: 'scroll',
  },
  table: {
    minWidth: 600,
  },
  head: {
    backgroundColor: '#3C3588',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  headText: {
    color: theme.palette.common.white,
  },
  bodyTotales: {
    fontWeight: 'bold',
    backgroundColor: '#3C3588',
    color: theme.palette.common.white,
  },
  bodyDetalles: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  buttonAdd: {
    backgroundColor: '#92C63E',
    color: theme.palette.common.white,
  },
});
