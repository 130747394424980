const tiposDocumentosValidacionPrevia = [
    {
        idTipo:"VENTA",
        nombre:"Factura de venta"
    },
    {
        idTipo:"NOTA_DEBITO",
        nombre:"Nota débito"
    },
    {
        idTipo:"NOTA_CREDITO",
        nombre:"Nota crédito"
    },
    {
        idTipo:"EXPORTACION",
        nombre:"Factura exportación"
    },
    // {
    //     idTipo:"EXPORTACION",
    //     nombre:"Exportación"
    // },
    // {
    //     idTipo:"CONTINGENCIA_FACTURADOR",
    //     nombre:"Contingencia facturador"
    // },
    // {
    //     idTipo:"CONTINGENCIA_DIAN",
    //     nombre:"Contingencia dian"
    // }
]

export default tiposDocumentosValidacionPrevia;