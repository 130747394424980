import React, { useEffect, useState } from 'react';
import { consultarCiudades } from 'network/Api/Ciudad';
import Select from './Select';

export async function getById(id) {
  return await consultarCiudades().then((res) => {
    return res.data.filter((c) => {
      return c.nmId === parseInt(id);
    })[0];
  });
}

export default function selectCiudades({ ...rest }) {
  const [ciudades, setCiudades] = useState([]);

  useEffect(() => {
    consultarCiudades().then((a) => setCiudades(a.data));
  }, []);

  return ciudades ? (
    <Select
      options={ciudades.map((c) => ({
        label: `${c.dsNombre} - ${c.departamento.dsNombre} - ${c.departamento.pais.dsNombre}`,
        value: c.nmId,
      }))}
      {...rest}
    />
  ) : (
    <div />
  );
}
