import React from 'react';
import Typography from 'components/atoms/Typography';
import AtomTextArea from 'components/atoms/TextArea';

function TextArea({ className, classNameLabel, error, label, require = false, ...rest }) {
  return (
    <div className={className}>
      <Typography className={classNameLabel}>{`${label}${require ? ' *' : ''}`}</Typography>
      <AtomTextArea require={require.toString()} {...rest} />
      {error && <small style={{ color: 'red' }}>{error}</small>}
    </div>
  );
}

export default TextArea;
