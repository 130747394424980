/* eslint-disable */
import React from "react";

import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import StopIcon from "@material-ui/icons/Stop";

import "./css/creative.css";
import LabelTitulos from "../layout/labelTitulos";
import LogoPortal from "../../static/images/logo_portal_factura.png";
import preguntasFrecuentes from "../../static/enums/preguntasFrecuentes";

const styles = (theme) => ({
  containerLogos: {
    maxHeight: "inherit",
    flex: 1,
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    maxHeight: "3em",
    maxWidth: "100%",
    margin: 0,
    padding: 0,
  },
  rootLogo: {
    display: "inline",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "inherit",
    maxWidth: "100%",
  },
  logo: {
    maxHeight: "inherit",
    maxWidth: "100%",
  },
  titulo: {
    fontFamily: "Aclonica",
    fontSize: "4rem",
  },
  root: {
    flexGrow: 2,
    maxWidth: "100%",
  },
  fontFamily: {
    whiteSpace: "nowrap",
    textAlign: "left",
  },
});

function PreguntasFrecuentes() {
  const classes = styles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top py-0 mb-0"
        id="mainNav"
      >
        <div className="container">
          <Link
            className={{ textDecoration: "none" }}
            to={{
              pathname: "/",
            }}
          >
            <a className="navbar-brand " href="#">
              <div className={classes.containerLogos}>
                <div className={classes.rootLogo}>
                  <img
                    className={classes.logo}
                    style={{ width: "15%" }}
                    src={LogoPortal}
                  />
                </div>
              </div>
            </a>
          </Link>
          <ul className="navbar-nav ml-auto my-2 my-lg-0">
            <li className="nav-item">
              <Link
                className={{ textDecoration: "none" }}
                to={{
                  pathname: "/login",
                }}
              >
                <div className={classes.loginBottom}>
                  <LabelTitulos texto="Login" />
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <header className="masthead-nuevo-emisor mt-0">
        <div className="container h-100 mt-0">
          <LabelTitulos texto="Preguntas Frecuentes" />
        </div>
        <div
          style={{
            width: "50%",
            marginTop: "2%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {preguntasFrecuentes &&
            preguntasFrecuentes.map((item) => {
              return (
                <ExpansionPanel
                  expanded={expanded === `${item.id}`}
                  onChange={handleChange(`${item.id}`)}
                  key={item.id}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography>
                      <b>{item.pregunta}</b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    {item.id == 4 && 8 ? (
                      " "
                    ) : (
                      <Typography>
                        {item.respuesta && item.respuesta}
                      </Typography>
                    )}
                  </ExpansionPanelDetails>
                  {item.id == 4 || 8
                    ? item.lista &&
                      item.lista.map((item) => (
                        <List
                          component="div"
                          key={item.id}
                          style={{ marginTop: "-30px" }}
                        >
                          <ListItem>
                            <ListItemIcon>
                              <StopIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                          </ListItem>
                        </List>
                      ))
                    : ""}
                </ExpansionPanel>
              );
            })}
        </div>
        <div className="container" style={{ marginTop: "5%" }}>
          <LabelTitulos texto="También tenemos algunos videos explicativos para ti" />
        </div>
        <div
          className="container-videos"
          style={{
            width: "98%",
            margin: "20px auto",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <iframe
            width="380"
            height="240"
            style={{ borderRadius: 10 }}
            src="https://www.youtube.com/embed/xzo6cbGUBQQ"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <iframe
            width="380"
            height="240"
            style={{ borderRadius: 10 }}
            src="https://www.youtube.com/embed/rtIgVCegyQY"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <iframe
            width="380"
            height="240"
            style={{ borderRadius: 10 }}
            src="https://www.youtube.com/embed/aoLSBWJ9xV4"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </header>
    </div>
  );
}

export default withStyles(styles)(PreguntasFrecuentes);
