import { serviceRoutes, post, get, getBlob } from "./index";

export const enviarDocumentoSoporte = async ({ body }) => await post(serviceRoutes.enviarDocumentoSoporte, body);

export const consultarDocumentosSoporte = async ({ feInicio, feFin, emisor, adquirente, prefijo = "", numero = "" }) =>
  await get(
    serviceRoutes.consultarDocumentosSoporte,
    `?feInicio=${feInicio}&feFin=${feFin}&emisor=${emisor}&adquirente=${adquirente}&prefijo=${prefijo}&numero=${numero}`
  );

export const consultarDocumentoPrefijoNumero = async ({ emisor, prefijo = "", numero = "" }) =>
  await get(serviceRoutes.consultarDocumentoPrefijoNumero, `?emisor=${emisor}&prefijo=${prefijo}&numero=${numero}`);

export const reenviarDocumentoSoporte = async (nmId) => await post(serviceRoutes.reenviarDocumentoSoporte, { nmId });

export const generarExcelDocumentosSoporte = async ({ feInicio, feFin, emisor, adquirente, prefijo = "", numero = "" }) =>
  await getBlob(
    serviceRoutes.generarExcelDocumentosSoporte,
    `?feInicio=${feInicio}&feFin=${feFin}&emisor=${emisor}&adquirente=${adquirente}&prefijo=${prefijo}&numero=${numero}`
  );

export const consultarDocumentosSoporteErroneos = async ({ emisor = 0, prefijo = "", numero = "", inicio, fin }) =>
  await get(serviceRoutes.consultarDocumentosSoporteErroneos, `?emisor=${emisor}&prefijo=${prefijo}&numero=${numero}&inicio=${inicio}&fin=${fin}`);

export const reenviarDocumentosErroneosSoporte = async () =>
  await post(serviceRoutes.reenviarDocumentosErroneosSoporte, null);

export const generarNotaCreditoSoporte = async (body) =>
  await post(`${serviceRoutes.generarNotaCreditoSoporte}`, body);
