import * as React from 'react';
import Checkbox from 'components/molecules/Checkbox';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import { tiposUsuarioSalud } from 'static/enums/TipoUsuarioSalud';
import { tiposIdentificacionSalud } from 'static/enums/TipoIdentificacionSalud';
import { modalidadesContratacionSalud } from 'static/enums/ModalidadContratacionSalud';
import { coverturasSalud } from 'static/enums/CoberturaSalud';

const FormularioSalud = ({ adquirente, setAdquirente, handleChange }) => {
  const handleChecked = ({ target }) => {
    const { name, checked } = target;
    setAdquirente({ [name]: checked });
  };

  return (
    <>
      <div className='form-row'>
        <div className='col-md-12'>
          <Checkbox
            label='Seleccione si es del sector salud'
            name='isSalud'
            checked={adquirente.isSalud}
            onChange={handleChecked}
          />
        </div>
      </div>
      {adquirente.isSalud && (
        <>
          <div className='form-row'>
            <div className='col-md-3'>
              <Input
                label='Segundo Nombre'
                name='dsSegundoNombre'
                onChange={handleChange}
                value={adquirente.dsSegundoNombre}
              />
            </div>
            <div className='col-md-3'>
              <Input
                label='Segundo Apellido'
                name='dsSegundoApellido'
                onChange={handleChange}
                value={adquirente.dsSegundoApellido}
              />
            </div>
            <div className='col-md-3'>
              <Select
                label='Tipo usuario'
                name='msTipoUsuario'
                options={tiposUsuarioSalud}
                value={tiposUsuarioSalud.find(({ value }) => value === adquirente.msTipoUsuario)}
                onChange={({ value: msTipoUsuario }) => setAdquirente({ msTipoUsuario })}
              />
            </div>
            <div className='col-md-3'>
              <Select
                label='Tipo de documento salud'
                name='msTipoDocID'
                options={tiposIdentificacionSalud}
                value={tiposIdentificacionSalud.find(
                  ({ value }) => value === adquirente.msTipoDocID,
                )}
                onChange={({ value: msTipoDocID }) => setAdquirente({ msTipoDocID })}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='col-md-4'>
              <Input
                label='Código prestador de Salud'
                name='msCodigoPrestadorSalud'
                onChange={handleChange}
                value={adquirente.msCodigoPrestadorSalud}
              />
            </div>
            <div className='col-md-4'>
              <Select
                label='Modalidad contratación'
                name='msModalidadContratacion'
                options={modalidadesContratacionSalud}
                value={modalidadesContratacionSalud.find(
                  ({ value }) => value === adquirente.msModalidadContratacion,
                )}
                onChange={({ value: msModalidadContratacion }) =>
                  setAdquirente({ msModalidadContratacion })
                }
              />
            </div>
            <div className='col-md-4'>
              <Select
                label="Cobertura plan"
                name="msCoberturaPlanBeneficios"
                options={coverturasSalud}
                value={coverturasSalud.find(
                  ({ value }) => value === adquirente.msCoberturaPlanBeneficios,
                )}
                onChange={({ value: msCoberturaPlanBeneficios }) =>
                  setAdquirente({ msCoberturaPlanBeneficios })
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormularioSalud;
