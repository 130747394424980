/* eslint-disable */
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { connect } from "react-redux";
import React from "react";
import { SetSpinnerModal } from "reducers/actions/facturaActions";
import TablaGenerica from "components/Tablas/TablaGenerica";
import moment from "moment";
import "moment/locale/es";
import { reenviarDocumento as reenviarDocumentoErroneo, reenviarDocumentosErroneos } from "network/Api/Documento";
import { consultarDocumentosErroneos } from "network/Api/Documento";
import { consultarDocumentosSoporteErroneos, reenviarDocumentosErroneosSoporte, reenviarDocumentoSoporte } from "network/Api/DocumentoSoporte";
import { recalcularJson } from "utils/RecalcularJson";
import Input from "components/Inputs/Input";
import Select from "components/Inputs/Select";
import SelectEmisor from "components/Inputs/SelectConsultaEmisores";

let headersTable = [
  "Nombre del Emisor",
  "Tipo Documento Electronico",
  "Prefijo",
  "Numero",
  "Identificacion del Adquirente",
  "Fecha proceso del documento",
  "Respuesta de Domina",
  "Json del Documento",
  "Reenviar Documento",
  "Regenerar Documento",
];
let keysTable = [
  "nombreEmisor",
  "tipoDocumentoElectronico",
  "prefijoDocumento",
  "numeroDocumento",
  "identificacionAdquirente",
  "feProceso",
  "jsonResponseDomina",
  "jsonDocumento",
  "buttonEnviarDocumento",
  "btnRegenerarDocumento",
];
const style = {
  copy: {
    resize: "none",
    position: "absolute",
    top: "0px",
    letft: "0px",
    width: "0px",
    height: "0px",
  },
  modal: {
    width: "600px",
  },
  modalTitle: {
    textAlign: "center",
    color: "#128BCC",
    fontFamily: "Aclonica",
    padding: "20px",
  },
};

const OPCIONES_DOCUMENTO = [
  { label: "Documento Electronico", value: "DOCUMENTO_ELECTRONICO" },
  { label: "Documento Soporte", value: "DOCUMENTO_SOPORTE" },
];

class ReenvioFacturas extends React.Component {
  state = {
    documentos: [],
    openModal: false,
    openModalResponse: false,
    identificacionEmisor: "",
    reenvioResponse: false,
    mensajeResponse: "",
    isLoading: true,
    reenvioModal: {
      prefijoNumeroDocumento: "",
      jsonDefault: "",
    },
    emisor: 0,
    prefijo: "",
    numero: "",
    inicio: moment().format("YYYY-MM-01"),
    fin: moment().format("YYYY-MM-DD"),
    tipoDocumento: "DOCUMENTO_ELECTRONICO",
  };

  getRespuestaDomina = (item) => {
    try {
      let respuestaDomina = JSON.parse(item.jsonResponseDomina);
      return (
        <p>
          {respuestaDomina[0].estado.descripcion} <br /> {respuestaDomina[0].detalle}
        </p>
      );
    } catch (err) {
      return <p>{item.jsonResponseDomina ? item.jsonResponseDomina : ""}</p>;
    }
  };

  copyJson = (id) => {
    let textArea = document.getElementById(id);
    textArea.select();
    document.execCommand("copy");
  };

  createJsonButton = (id, json) => {
    return (
      <div>
        <div>
          <Button variant="contained" color="primary" onClick={this.copyJson.bind(this, id)}>
            Copiar Json
          </Button>
          <textarea style={style.copy} id={id} defaultValue={JSON.stringify(json)} readOnly={true} />
        </div>
      </div>
    );
  };

  handleOpenModal = (jsonText) => {
    let json = JSON.parse(jsonText);
    this.setState({
      identificacionEmisor: json.identificacionEmisor,
      reenvioModal: {
        prefijoNumeroDocumento: `${json.dsPrefijo}-${json.dsNumeroFactura}`,
        jsonDefault: jsonText,
      },
      openModal: true,
    });
  };

  createReenvioButton = (json) => {
    return (
      <div>
        <Button variant="contained" color="primary" onClick={() => this.handleOpenModal(json)}>
          Reenviar Documento
        </Button>
      </div>
    );
  };

  consultar = () => {
    const { setSpinnerModal } = this.props;
    const { emisor, prefijo, numero, inicio, fin, tipoDocumento } = this.state;
    this.setState({ isLoading: true });
    setSpinnerModal(true);
    const body = { emisor, prefijo, numero, inicio, fin };
    (tipoDocumento === "DOCUMENTO_ELECTRONICO" ? consultarDocumentosErroneos(body) : consultarDocumentosSoporteErroneos(body)).then(({ data }) => {
      setSpinnerModal(false);
      const documentos = Array.isArray(data)
        ? data.map((item, key) => ({
            nombreEmisor: item.nombreEmisor,
            tipoDocumentoElectronico: item.tipoDocumentoElectronico,
            prefijoDocumento: item.prefijoDocumento,
            numeroDocumento: item.numeroDocumento,
            identificacionAdquirente: item.identificacionAdquirente,
            feProceso: moment(item.feProceso).format("LLL"),
            jsonResponseDomina: this.getRespuestaDomina(item),
            jsonDocumento: this.createJsonButton(`json${key}`, {
              ...JSON.parse(item.jsonDocumento),
              nmId: item.nmId,
            }),
            buttonEnviarDocumento: this.createReenvioButton(item.jsonDocumento),
            btnRegenerarDocumento: tipoDocumento === "DOCUMENTO_ELECTRONICO" && this.crearBotonReenvio(item.jsonDocumento),
          }))
        : [];
      this.setState({ documentos, isLoading: false });
    });
  };

  crearBotonReenvio(json) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          const { identificacionEmisor } = this.state;
          const jsonR = recalcularJson(JSON.parse(json));
          this.setState({
            openModal: false,
            reenvioResponse: true,
            mensajeResponse: `Se esta procesando el documento ${jsonR.dsPrefijo}-${jsonR.dsNumeroFactura} en un momento podrá consultar su estado`,
          });
          reenviarDocumentoErroneo({ ...jsonR, identificacionEmisor });
        }}
      >
        Regenerar Documento
      </Button>
    );
  }

  componentDidMount() {
    this.consultar();
  }

  reenviarDocumento() {
    const { identificacionEmisor, jsonReenvio, reenvioModal, tipoDocumento } = this.state;
    let jsonFinal = JSON.parse(jsonReenvio ? jsonReenvio : reenvioModal.jsonDefault);
    jsonFinal.identificacionEmisor = identificacionEmisor;
    this.props.setSpinnerModal(true);
    const response = tipoDocumento === "DOCUMENTO_ELECTRONICO" ? reenviarDocumentoErroneo(jsonFinal) : reenviarDocumentoSoporte(jsonFinal);
    if (response) {
      this.props.setSpinnerModal(false);
      this.setState({
        openModal: false,
        reenvioResponse: true,
        mensajeResponse: `Se esta procesando el documento ${reenvioModal.prefijoNumeroDocumento} en un momento podrá consultar su estado`,
      });
      this.consultar();
    } else {
      this.setState({
        openModal: false,
        reenvioResponse: true,
        mensajeResponse: `No se puede reprocesar el documento`,
      });
      this.consultar();
    }
  }

  render() {
    const {
      documentos,
      reenvioModal,
      openModal,
      reenvioResponse,
      mensajeResponse,
      isLoading,
      prefijo,
      numero,
      inicio,
      fin,
      tipoDocumento,
    } = this.state;

    return (
      <>
        <div style={{ padding: "10px" }}>
          <div className="form-row">
            <div className="form-group col-xl-12">
              <SelectEmisor label="Emisor" handleConsultarEmisor={({ id }) => this.setState({ emisor: id })} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-xl-2">
              <Select
                label="Tipo documento"
                options={OPCIONES_DOCUMENTO}
                value={OPCIONES_DOCUMENTO.find((od) => od.value === tipoDocumento)}
                onChange={({ value }) => this.setState({ tipoDocumento: value }, () => this.consultar())}
              />
            </div>
            <div className="form-group col-xl-2">
              <Input label="Prefijo" value={prefijo} onChange={({ target }) => this.setState({ prefijo: target.value })} />
            </div>
            <div className="form-group col-xl-2">
              <Input label="Numero" value={numero} onChange={({ target }) => this.setState({ numero: target.value })} />
            </div>
            <div className="form-group col-xl-2">
              <Input label="Fecha Inicio" value={inicio} type="date" onChange={({ target }) => this.setState({ inicio: target.value })} />
            </div>
            <div className="form-group col-xl-2">
              <Input label="Fecha Fin" value={fin} type="date" onChange={({ target }) => this.setState({ fin: target.value })} />
            </div>
            <div className="form-group col-xl-2" style={{ display: "flex", alignItems: "flex-end" }}>
              <Button variant="contained" color="primary" onClick={() => this.consultar()} style={{ margin: "0px 5px" }}>
                Consultar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={tipoDocumento === "DOCUMENTO_ELECTRONICO" ? reenviarDocumentosErroneos : reenviarDocumentosErroneosSoporte}
                style={{ margin: "0px 5px" }}
              >
                Reenviar Docs
              </Button>
            </div>
          </div>
        </div>
        <Paper>
          <div>
            <TablaGenerica
              detalles={documentos}
              headers={headersTable}
              orderBy={headersTable[5]}
              keys={keysTable}
              rowsPerPage={10}
              loading={isLoading}
            />
            <Dialog open={openModal}>
              <h4 style={style.modalTitle}>Reenviar el documento {reenvioModal.prefijoNumeroDocumento}</h4>
              <DialogContent style={style.modal}>
                <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                  <textarea
                    style={{ height: "300px" }}
                    id="jsonReenvio"
                    defaultValue={reenvioModal.jsonDefault}
                    onChange={(e) => this.setState({ jsonReenvio: e.target.value })}
                  />
                  <label>Reenviar el Json:</label>
                </div>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="primary" onClick={() => this.reenviarDocumento()}>
                  Reenviar Documento
                </Button>
                <Button onClick={() => this.setState({ openModal: false })}>Cerrar</Button>
              </DialogActions>
            </Dialog>
            <Dialog open={reenvioResponse}>
              <DialogContentText>
                <p style={{ padding: "20px" }}>{mensajeResponse}</p>
              </DialogContentText>
              <DialogActions>
                <Button onClick={() => this.setState({ reenvioResponse: false })}>Cerrar</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Paper>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    loading: state.factura.loading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReenvioFacturas);
