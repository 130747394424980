import * as React from "react";
import Select from "./Select";
import PorcentajeIva from "static/enums/tiposPorcentajesIVA";
import PorcentajeInc from "static/enums/tiposPorcentajesICO";

const tipoTributo = {
  IVA: PorcentajeIva,
  INC: PorcentajeInc,
};

const SelectPorcentajes = ({ tributo, value, ...rest }) => {
  const options = tipoTributo[tributo].map((porcentaje) => ({
    label: porcentaje.nombre,
    value: porcentaje.codigo,
  }));

  return (
    <Select
      value={options.find((p) => p.value === value)}
      options={options}
      {...rest}
    />
  );
};

export default SelectPorcentajes;
