import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { GetUserData } from "screens/login/auth/amplifyAuth";
import {
  consultarReporteParqueadero,
  generarPdfParqueadero,
} from "network/Api/DocumentoParqueadero";
import { SetSpinnerModal } from "reducers/actions/facturaActions";
import { exportTableToExcel } from "utils/ExportarExcelUtil";
import pdf from "static/images/pdf.svg";

const DATE_FORMAT = "YYYY-MM-DD";
const style = {
  filter: {
    margin: "20px",
    display: "flex",
    alignItems: "flex-end",
  },
  inputFilter: {
    margin: "0px 10px",
  },
};

const nf = Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
});

class ReporteParqueadero extends Component {
  state = {
    feInicio: moment()
      .subtract(31, "days")
      .format(DATE_FORMAT),
    feFin: moment().format(DATE_FORMAT),
    feConsulta: "FE_SALIDA",
    mensajeError: "",
    reporteLista: null,
  };

  componentDidMount() {
    GetUserData().then((res) =>
      this.setState(
        {
          nmIdEmisor: JSON.parse(res.storage.dataEmisor).listaEmisores[0]
            .codigoEmisor,
        },
        () => this.handleConsultarReporte()
      )
    );
  }

  handleConsultarReporte = () => {
    const { feInicio, feFin, feConsulta, nmIdEmisor } = this.state;
    const { setSpinnerModal } = this.props;
    setSpinnerModal(true);
    consultarReporteParqueadero({
      feInicio: `${feInicio}`,
      feFin: `${feFin}`,
      nmIdEmisor,
      feConsulta,
    }).then((res) => {
      let data =
        res.status === 200
          ? { reporteLista: res.data }
          : { mensajeError: "Error consultando reporte" };
      this.setState(data);
      setSpinnerModal(false);
    });
  };

  handleChange = (event) => {
    let label = event.target.id ? event.target.id : event.target.name;
    this.setState({ [label]: event.target.value });
  };

  handleGenerarPdf = (reporte) => {
    const { setSpinnerModal } = this.props;
    setSpinnerModal(true);
    generarPdfParqueadero({ nmId: reporte.nmId }).then((res) => {
      fetch(`data:application/pdf;base64,${res.data}`)
        .then((result) => result.blob())
        .then((blob) => {
          const file = new Blob([blob], { type: "application/pdf" });
          const filename = "pdf.pdf";
          const link = document.createElement("a");
          if (link.download !== undefined) {
            let objectURL = URL.createObjectURL(file);
            link.setAttribute("href", objectURL);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          setSpinnerModal(false);
        });
    });
  };

  render() {
    const {
      feInicio,
      feFin,
      reporteLista,
      mensajeError,
      feConsulta,
    } = this.state;
    return (
      <div>
        <div style={style.filter}>
          <FormControl variant="outlined">
            <InputLabel id="fechaLabel">Fecha de consulta</InputLabel>
            <Select
              labelId="fechaLabel"
              id="feConsulta"
              value={feConsulta}
              onChange={this.handleChange}
              name="feConsulta"
              style={{ width: "200px" }}
            >
              <MenuItem value="FE_INGRESO">Fecha Entrada</MenuItem>
              <MenuItem value="FE_SALIDA">Fecha Salida</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: "flex" }}>
            <div style={style.inputFilter}>
              <small>Fecha Inicio</small>
              <input
                type="date"
                id="feInicio"
                value={feInicio}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div style={style.inputFilter}>
              <small>Fecha Fin</small>
              <input
                type="date"
                id="feFin"
                value={feFin}
                max={moment().format(DATE_FORMAT)}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <Button
            variant="contained"
            style={style.inputFilter}
            color="primary"
            onClick={this.handleConsultarReporte}
          >
            Consultar
          </Button>
          <Button
            style={style.inputFilter}
            variant="contained"
            color="primary"
            onClick={() =>
              exportTableToExcel("tableReport", `${feInicio}_${feFin}`)
            }
          >
            Exportar excel
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Número</TableCell>
              <TableCell>Placa</TableCell>
              <TableCell>Tipo Vehiculo</TableCell>
              <TableCell>Fecha Ingreso</TableCell>
              <TableCell>Fecha Salida</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>PDF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reporteLista ? (
              reporteLista.map((reporte, key) => (
                <TableRow key={key}>
                  <TableCell>{reporte.nmId}</TableCell>
                  <TableCell>{reporte.dsPlaca}</TableCell>
                  <TableCell>{reporte.cdTipoVehiculo}</TableCell>
                  <TableCell>
                    {reporte.feIngreso && reporte.feIngreso.replace("T", " ")}
                  </TableCell>
                  <TableCell>
                    {reporte.feSalida && reporte.feSalida.replace("T", " ")}
                  </TableCell>
                  <TableCell>{nf.format(reporte.nmValor)}</TableCell>
                  <TableCell>{reporte.dsUsuarioSalida}</TableCell>
                  <TableCell>
                    <Button onClick={() => this.handleGenerarPdf(reporte)}>
                      <img
                        src={pdf}
                        alt="Generar PDF"
                        style={{ height: "35px", width: "35px" }}
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: "center" }}>
                  <div class="spinner-border text-primary" />
                  <p style={{ textAlign: "center" }} className="text-muted ">
                    Cargando...
                  </p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Table id="tableReport" style={{ display: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell>Número</TableCell>
              <TableCell>Placa</TableCell>
              <TableCell>Tipo Vehiculo</TableCell>
              <TableCell>Fecha Ingreso</TableCell>
              <TableCell>Fecha Salida</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Usuario</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reporteLista &&
              reporteLista.map((reporte, key) => (
                <TableRow key={key}>
                  <TableCell>{reporte.nmId}</TableCell>
                  <TableCell>{reporte.dsPlaca}</TableCell>
                  <TableCell>{reporte.cdTipoVehiculo}</TableCell>
                  <TableCell>
                    {reporte.feIngreso && reporte.feIngreso.replace("T", " ")}
                  </TableCell>
                  <TableCell>
                    {reporte.feSalida && reporte.feSalida.replace("T", " ")}
                  </TableCell>
                  <TableCell>{nf.format(reporte.nmValor)}</TableCell>
                  <TableCell>{reporte.usuarioRegistro}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Dialog open={mensajeError}>
          <DialogTitle>PortalFactura</DialogTitle>
          <DialogContent dangerouslySetInnerHTML={{ __html: mensajeError }} />
          <DialogActions>
            <Button onClick={() => this.setState({ mensajeError: "" })}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, _) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReporteParqueadero);
