import React, { useState } from "react";
import Input from "../../../../components/Inputs/Input";
import Button from 'components/atoms/Button';
import SelectTipoIdentificacion from "../../../../components/Inputs/SelectTipoIdentificacion";
import SelectCiudades from "../../../../components/Inputs/SelectCiudades";
import {
  TIPOS_DATOS,
  validarInput,
  validarString,
} from "../../../../utils/ValidarDatos";
import { calcularDigitoVerificacion } from "../../../../utils/funcionesUtiles";
import { guardarEmpleado } from "network/Api/Empleado";
import * as DateUtils from "../../../../utils/DateUtils";
import Checkbox from 'components/molecules/Checkbox';
import Select from "components/Inputs/Select";
import FormasPago from "static/enums/tiposPago";
import MediosPago from "static/enums/tiposMediosPago";
import TipoTrabajador from "static/enums/TipoTrabajador";
import SubTipoTrabajador from "static/enums/SubTipoTrabajador";

const DEFAULT_EMPLEADO = { cdFormaPago: "CONTADO", snAltoRiesgoPension: false };

export default function nuevoEmpleado({ emisor, openModal, resetEmpleado }) {
  const [empleado, setEmpleado] = useState(DEFAULT_EMPLEADO);

  const body = {
    ...empleado,
    emisor: { nmId: emisor },
    cdTipoIdentificacion:
      empleado.cdTipoIdentificacion && empleado.cdTipoIdentificacion.value,
    dsDigitoVerificacion: calcularDigitoVerificacion(
      empleado.dsIdentificacion ? empleado.dsIdentificacion.toString() : ""
    ),
    cdEstadoEmpleado: "C",
    ciudad: {
      nmId: empleado.ciudad && empleado.ciudad.value,
    },
    feNacimiento: DateUtils.formatDateTime(empleado.feNacimiento),
    cdTipoPersona: "NATURAL",
  };

  const guardar = () => {
    let errores = validarFormulario(body);
    if (errores.length === 0) {
      guardarEmpleado({
        ...body,
        feIngreso: body.feIngreso + "T00:00:00",
      }).then((res) => {
        if (res.status === 200) {
          clearForm();
          resetEmpleado();
          openModal(res.data);
        } else openModal("Error guardando el empleado");
      });
    } else openModal(errores.join(", "));
  };

  const setData = (data) => {
    setEmpleado({ ...empleado, ...data });
  };

  const clearForm = () => {
    setEmpleado({ cdTipoIdentificacion: {}, ciudad: {} });
  };

  return (
    <div style={{ padding: "10px" }}>
      <div className="form-row col-md-12">
        <div className="form-group col-md-12 col-xl-3">
          <SelectTipoIdentificacion
            label="Tipo de identificación *"
            onChange={(e) => setData({ cdTipoIdentificacion: e })}
            value={empleado.cdTipoIdentificacion}
          />
        </div>
        <div className="form-group col-xl-2">
          <Input
            label="Identificación *"
            value={empleado.dsIdentificacion || ""}
            onChange={(e) =>
              setData({
                dsIdentificacion: parseInt(e.target.value),
              })
            }
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.textoNumeros)}
          />
        </div>
        <div className="form-group col-xl-3">
          <Input
            label="Email *"
            value={empleado.dsEmail || ""}
            onChange={(e) => setData({ dsEmail: e.target.value })}
          />
        </div>
        <div className="form-group col-xl-2">
          <Select
            label="Tipo trabajador *"
            options={TipoTrabajador}
            value={TipoTrabajador.filter(
              (tt) => tt.value === empleado.cdTipoTrabajador
            )}
            onChange={(e) => setData({ cdTipoTrabajador: e.value })}
          />
        </div>
        <div className="form-group col-xl-2">
          <Select
            label="Sub tipo trabajador *"
            options={SubTipoTrabajador}
            value={SubTipoTrabajador.filter(
              (stt) => stt.value === empleado.cdSubTipoTrabajador
            )}
            onChange={(e) => setData({ cdSubTipoTrabajador: e.value })}
          />
        </div>
        <div className="form-row col-md-12">
          <div className="form-group col-md-3 mb-0">
            <Input
              label="Primer nombre *"
              value={empleado.dsNombre || ""}
              onChange={(e) => setData({ dsNombre: e.target.value })}
            />
          </div>
          <div className="form-group col-md-3 mb-0">
            <Input
              label="Segundo nombre"
              value={empleado.dsSegundoNombre || ""}
              onChange={(e) => setData({ dsSegundoNombre: e.target.value })}
            />
          </div>
          <div className="form-group col-md-3 mb-0">
            <Input
              label="Primer apellido *"
              value={empleado.dsPrimerApellido || ""}
              onChange={(e) => setData({ dsPrimerApellido: e.target.value })}
            />
          </div>
          <div className="form-group col-md-3 mb-0">
            <Input
              label="Segundo apellido *"
              value={empleado.dsSegundoApellido || ""}
              onChange={(e) => setData({ dsSegundoApellido: e.target.value })}
            />
          </div>
        </div>
        <div className="form-row col-md-12">
          <div className="form-group col-xl-2">
            <Input
              label="Dirección *"
              value={empleado.dsDireccion || ""}
              onChange={(e) => setData({ dsDireccion: e.target.value })}
            />
          </div>
          <div className="form-group col-xl-2">
            <SelectCiudades
              label="Ciudad *"
              onChange={(e) => setData({ ciudad: e })}
              value={empleado.ciudad}
            />
          </div>
          <div className="form-group col-xl-2">
            <Select
              label="Medio de pago *"
              options={MediosPago.filter((mp) => mp.nomina).map((f) => ({
                label: f.nombre,
                value: f.codigo,
              }))}
              value={MediosPago.filter((mp) => mp.nomina)
                .map((f) => ({
                  label: f.nombre,
                  value: f.codigo,
                }))
                .filter((f) => f.value === empleado.cdMedioPago)}
              onChange={(e) => setData({ cdMedioPago: e.value })}
            />
          </div>
          <div className="form-group col-xl-2">
            <Select
              label="Forma de pago"
              options={FormasPago.map((f) => ({
                label: f.nombre,
                value: f.codigo,
              }))}
              value={FormasPago.map((f) => ({
                label: f.nombre,
                value: f.codigo,
              })).filter((f) => f.value === empleado.cdFormaPago)}
              isDisabled={true}
            />
          </div>
          <div className="form-group col-xl-2">
            <Input
              label="Fecha ingreso *"
              type="date"
              value={empleado.feIngreso || ""}
              onChange={(e) => setData({ feIngreso: e.target.value })}
            />
          </div>
          <div className="form-group col-xl-2" style={{ marginTop: "20px" }}>
            <Checkbox
              label="Alto riesgo de pension"
              id="salario"
              tooltip="Si el trabajador desarrollo durante el presente periodo alguna de las actividades descritas en el Decreto 2090 de 2003, o la norma que lo modifique, adicione o sustituya"
              onChange={(e) =>
                setData({ snAltoRiesgoPension: e.target.checked })
              }
              checked={empleado.snAltoRiesgoPension || false}
            />
          </div>
        </div>
        <div className="form-row col-md-12">
          <div className="form-group col-xl-2">
            <Button onClick={guardar}>Guardar</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.cdTipoIdentificacion)
    errores.push("El Tipo de Identificación debe ser diligenciada");
  if (!body.dsIdentificacion)
    errores.push("La Identificación debe ser diligenciada");
  if (!body.dsNombre) errores.push("El Nombre debe ser diligenciado");
  if (!body.dsPrimerApellido) errores.push("El Primer Apellido es obligatorio");
  if (!body.dsDireccion) errores.push("La Dirección debe ser diligenciada");
  if (body.dsDireccion && body.dsDireccion.length > 60)
    errores.push("La Dirección no puede superar los 60 caracteres");
  if (body.ciudad && !body.ciudad.nmId)
    errores.push("La Ciudad debe ser diligenciada");
  if (!body.feIngreso) errores.push("La fecha de ingreso es obligatoria");
  if (!validarString(body.dsEmail, TIPOS_DATOS.email))
    errores.push("Email con formato inválido");
  return errores;
}
