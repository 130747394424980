import { serviceRoutes, get, put, post } from './index';

export const consultarPersonaPorIdentificacion = async ({ dsIdentificacion }) =>
  await get(serviceRoutes.consultarPersonaPorIdentificacion, `?identificacion=${dsIdentificacion}`);

export const consultarVentasPorAliado = async ({ aliado, inicio, fin }) =>
  await get(
    serviceRoutes.consultarVentasPorAliado,
    `?aliado=${aliado}&inicio=${inicio}&fin=${fin}`,
  );

export const actualizaTransaccionPersona = async (body) =>
  await put(serviceRoutes.actualizaTransaccionPersona, body);

export const consultarPersonas = async () => await get(serviceRoutes.consultarPersonas, '');

export const registrarPersona = async (body) => await post(serviceRoutes.registrarPersona, body);

export const consultarVentas = async ({ inicio, fin }) =>
  await get(serviceRoutes.consultarAllVentas, `?inicio=${inicio}&fin=${fin}`);

export const consultarEmailExistente = async ({ dsEmail }) =>
  await get(serviceRoutes.consultarEmailExistente, `?email=${dsEmail}`);
