export function SetDetalleFactura(item) {
  return {
    type: 'SET_DETALLE_FACTURA',
    payload: {
      item,
    },
  };
}

export function SetTotalesFactura(item) {
  return {
    type: 'SET_TOTALES_FACTURA',
    payload: {
      item,
    },
  };
}

export function SetTiposIdentificacion(item) {
  return {
    type: 'SET_TIPOS_IDENTIFICACION',
    payload: {
      item,
    },
  };
}

export function SetConfirmacionModalState(item) {
  return {
    type: 'SET_MODAL_CONFIRMACION_STATE',
    payload: {
      item,
    },
  };
}

export function SetSpinnerModal(item) {
  return {
    type: 'SET_SPINNER_MODAL',
    payload: {
      item,
    },
  };
}

export function SetModalAddDetalleFactura(item) {
  return {
    type: 'SET_MODAL_ADD_DETALLE_FACTURA',
    payload: {
      item,
    },
  };
}

export function SetDatosDocumento(item) {
  return {
    type: 'SET_DATOS_DOCUMENTO',
    payload: {
      item,
    },
  };
}

export function SetDatosCliente(item) {
  return {
    type: 'SET_DATOS_CLIENTE',
    payload: {
      item,
    },
  };
}

export function SetDatosAdicionalesDocumento(item) {
  return {
    type: 'SET_DATOS_ADICIONALES_DOCUMENTO',
    payload: {
      item,
    },
  };
}

export function SetModalCodigoConfirmacion(item) {
  return {
    type: 'SET_MODAL_CODIGO_VERIFICACION',
    payload: {
      item,
    },
  };
}

export function SetSnackBarState(item) {
  return {
    type: 'SET_SNACKBAR_STATE',
    payload: {
      item,
    },
  };
}

export function SetLoadingValue(item) {
  return {
    type: 'SET_LOADING_VALUE',
    payload: {
      item,
    },
  };
}

export function SetUserAttributes(item) {
  return {
    type: 'SET_USER_ATTRIBUTES',
    payload: {
      item,
    },
  };
}

export function SetUserGroup(item) {
  return {
    type: 'SET_USER_GROUP',
    payload: {
      item,
    },
  };
}

export function Logout() {
  return {
    type: 'LOGOUT',
  };
}

export function SetOpenModalFiltroFactura(item) {
  return {
    type: 'SET_OPEN_MODAL_FILTRO_FACTURA',
    payload: {
      item,
    },
  };
}

export function SetFacturasEnviadas(item) {
  return {
    type: 'SET_FACTURAS_ENVIADAS',
    payload: {
      item,
    },
  };
}

export function SetPlanes(item) {
  return {
    type: 'SET_PLANES',
    payload: {
      item,
    },
  };
}

export function SetAttributesSuperEmisor(item) {
  return {
    type: 'SET_ATTRIBUTES_SUPER_EMISOR',
    payload: {
      item,
    },
  };
}

export function SetModalFacturasReferencia(item) {
  return {
    type: 'SET_MODAL_FACTURA_REFERENCIA',
    payload: {
      item,
    },
  };
}

export function SetFacturasReferencia(item) {
  return {
    type: 'SET_FACTURAS_REFERENCIA',
    payload: {
      item,
    },
  };
}

export function SetModalDocumentosAdicionales(item) {
  return {
    type: 'SET_MODAL_DOCUMENTOS_ADICIONALES',
    payload: {
      item,
    },
  };
}

export function SetDocumentosAdicionales(item) {
  return {
    type: 'SET_DOCUMENTOS_ADICIONALES',
    payload: {
      item,
    },
  };
}

export function SetCargosDescuentos(item) {
  return {
    type: 'SET_CARGOS_DESCUENTOS',
    payload: {
      item,
    },
  };
}

export function SetIsFacturaExportacion(item) {
  return {
    type: 'SET_IS_FACTURA_EXPORTACION',
    payload: {
      item,
    },
  };
}

export function SetPreDocumentoSelected(item) {
  return {
    type: 'SET_PRE_DOCUMENTO_SELECCIONADO',
    payload: {
      item,
    },
  };
}

export function SetPreDocumentos(item) {
  return {
    type: 'SET_PRE_DOCUMENTOS',
    payload: {
      item,
    },
  };
}

export function SetDetallesPreDocumento(item) {
  return {
    type: 'SET_DETALLES_PREDOCUMENTO',
    payload: {
      item,
    },
  };
}

export function SetOpenModalPreDocumento(item) {
  return {
    type: 'SET_OPEN_MODAL_PREDOCUMENTO',
    payload: {
      item,
    },
  };
}

export function SetOpenModalFiltrosVentas(item) {
  return {
    type: 'SET_OPEN_MODAL_FILTROS_VENTAS',
    payload: {
      item,
    },
  };
}

export function SetEmisorFacturasEnviadas(item) {
  return {
    type: 'SET_EMISOR_FACTURAS_ENVIADAS',
    payload: {
      item,
    },
  };
}
