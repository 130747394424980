export const styles = (theme) => ({
  formSolicitud: {
    padding: '20px',
    width: '50%',
    margin: '0px auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0px',
  },
  columnaInformacion: {},
  separador: {
    border: '.5px solid grey',
    margin: '20px',
  },
  columnaData: {},
  textArea: { resize: 'none', height: '200px !important' },
});
