import * as React from 'react';
import Input from 'components/molecules/Input';
import useForm from 'hooks/useForm';
import SelectCiudades from 'components/organisms/SelectCiudades';
import SelectResponsabilidaes from 'components/organisms/SelectResponsabilidadesFiscales';
import SelectTipoPersona from 'components/organisms/SelectTipoPersona';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
import Button from 'components/atoms/Button';
import { Paper } from '@material-ui/core';
import Layout from 'components/layout/Layout';
import { consultarAdquirente, actualizarAdquirente } from 'network/Api/Documento';
const { useCallback, useMemo } = React;

const PersonalizarAdquirente = () => {
  const { state, handleChange, setState } = useForm();

  const consultar = useCallback(async () => {
    const { codigo: cufe } = state;
    const { data } = await consultarAdquirente({ cufe });
    setState(data);
  }, [state.codigo]);

  const actualizarDocumento = useCallback(async () => {
    const { data } = await actualizarAdquirente(state);
    console.log(data);
  }, [state]);

  return (
    <Layout>
      <Paper className='form-row d-flex justify-content-center'>
        <Input
          label='Código de la factura'
          onChange={handleChange}
          value={state.codigo}
          name='codigo'
          className='form-group col-md-6'
          required
        />
        <div className='form-row col-md-2 d-flex align-items-center'>
          <Button onClick={consultar}>Consultar</Button>
        </div>
      </Paper>
      <div className='form-row'>
        <div className='form-row col-md-12'>
          <SelectTipoPersona
            value={state.tipoPersonaAdquiriente}
            name='tipoPersonaAdquiriente'
            onChange={useCallback(({ value }) => setState({ tipoPersonaAdquiriente: value }), [])}
            className='form-group col-xl-3'
          />
          <SelectTipoIdentificacion
            value={state.tipoIdentificacionAdquiriente}
            name='tipoIdentificacionAdquiriente'
            onChange={useCallback(
              ({ value }) => setState({ tipoIdentificacionAdquiriente: value }),
              [],
            )}
            className='form-group col-xl-3'
            tipoPersona={state.tipoPersonaAdquiriente}
          />
          <Input
            value={state.identificacionAdquiriente}
            name='identificacionAdquiriente'
            onChange={handleChange}
            className='form-group col-xl-3'
            label='Nro. Documento'
            maxLength='35'
            required
          />
          <Input
            value={state.digitoVerificacionAdquirente}
            name='digitoVerificacionAdquirente'
            onChange={handleChange}
            className='form-group col-xl-3'
            label='Dígito Verif.'
            required
            disabled
          />
        </div>
        <div className='form-row col-md-12'>
          <Input
            value={state.nombresAdquiriente}
            name='nombresAdquiriente'
            onChange={handleChange}
            className='form-group col-md-3 mb-0'
            label='Nombre o Razón Social'
            maxLength='200'
            required
          />
          <Input
            value={state.segundoNombre}
            name='segundoNombre'
            onChange={handleChange}
            className='form-group col-md-3 mb-0'
            label='Segundo Nombre'
            maxLength='200'
            required
          />
          <Input
            value={state.primerApellido}
            name='primerApellido'
            onChange={handleChange}
            className='form-group col-md-3 mb-0'
            label='Primer Apellido'
            maxLength='200'
          />
          <Input
            value={state.segundoApellido}
            name='segundoApellido'
            onChange={handleChange}
            className='form-group col-md-3 mb-0'
            label='Segundo Apellido'
            maxLength='200'
          />
        </div>
        <div className='form-row col-md-12'>
          <SelectCiudades
            placeholder='Seleccióne una ciudad'
            className='form-group col-xl-3'
            onChange={useCallback((_, ciudadAdquiriente) => setState({ ciudadAdquiriente }), [])}
            value={useMemo(
              () => (state.ciudadAdquiriente ? state.ciudadAdquiriente.nmId : undefined),
              [state.ciudadAdquiriente],
            )}
          />
          <Input
            label='Email'
            maxLength='100'
            name='emailAdquiriente'
            className='form-group col-xl-3'
            value={state.emailAdquiriente}
            onChange={handleChange}
            required
          />
          <SelectResponsabilidaes
            className='form-group col-md-12 col-xl-3'
            onChange={useCallback(
              (_, r) => setState({ responsabilidadesFiscales: r }),
              [state.responsabilidadesFiscales],
            )}
            value={state.responsabilidadesFiscales}
          />
          <Input
            value={state.responsabilidadesFiscales}
            className='form-group col-md-12 col-xl-3'
            label='Responsabilidades seleccionadas'
            disabled
          />
        </div>
        <div className='form-row col-md-12'>
          <div className='form-group col-md-12 col-xl-5'>
            <Button onClick={actualizarDocumento}>Actualizar Adquirente</Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PersonalizarAdquirente;
