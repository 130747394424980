import * as React from "react";
import Icon from "components/menu/Icon";
import NominaIcon from "static/images/paid.svg";
import EmpleadoIcon from "static/images/contact_page.svg";
import SoporteNomina from "static/images/account_balance.svg";
import DocNominaIcon from "static/images/documentosNomina.png";

export const Nomina = {
  subMenu: true,
  title: "Emitir nómina",
  icon: <Icon src={NominaIcon} alt="Nómina" />,
  items: [
    {
      title: "Crear empleado",
      icon: <Icon src={EmpleadoIcon} alt="Informacion empleado" />,
      pathname: "/Empleado",
    },
    {
      title: "Enviar soporte nómina DIAN",
      icon: <Icon src={SoporteNomina} alt="Soporte nómina" />,
      pathname: "/SoporteNomina",
    },
    {
      title: "Consulta soportes nómina emitidos",
      icon: <Icon src={DocNominaIcon} alt="Documentos nomina emitidas" />,
      pathname: "/informenomina",
    },
  ],
};
