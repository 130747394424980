import { serviceRoutes, get } from './index';

export const consultarParametros = async () => await get(serviceRoutes.consultarParametros, '');

export const consultarParametroPorNombre = async ({ parametro }) =>
  await get(serviceRoutes.consultarParametroPorNombre, `?parametro=${parametro}`);

export const consultarParametroEmisor = async ({ parametro, emisor }) =>
  await get(serviceRoutes.consultarParametroEmisor, `${parametro}/${emisor}`);

export const consultarParametrosInicio = async ({ logged }) =>
  await get(serviceRoutes.consultarParametrosInicio, `?logged=${logged}`);
