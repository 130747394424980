import * as React from "react";
import { Typography } from "@material-ui/core";

const SolicitudCancelada = () => (
  <div style={{ textAlign: "center", margin: "50px auto" }}>
    <Typography variant="h3">
      Gracias por visitarnos, vuelve pronto!!!
    </Typography>
  </div>
);

export default SolicitudCancelada;
