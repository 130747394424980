const TipoIdentificacion = [
  {
    idTipoIdentificacion: "13",
    nombre: "Cédula de ciudadanía",
    tipoIdentificacionAdquiriente: "CEDULA_CIUDADANIA",
    forNatural: true,
  },
  {
    idTipoIdentificacion: "11",
    nombre: "Registro civil",
    tipoIdentificacionAdquiriente: "REGISTRO_CIVIL",
    forNatural: true,
  },
  {
    idTipoIdentificacion: "12",
    nombre: "Tarjeta de identidad",
    tipoIdentificacionAdquiriente: "TARJETA_IDENTIDAD",
    forNatural: true,
  },
  {
    idTipoIdentificacion: "21",
    nombre: "Tarjeta de extranjería",
    tipoIdentificacionAdquiriente: "TARJETA_EXTRANJERIA",
    isSoporte: true,
    forNatural: true,
  },
  {
    idTipoIdentificacion: "22",
    nombre: "Cédula de extranjería",
    tipoIdentificacionAdquiriente: "CEDULA_EXTRANJERIA",
    isSoporte: true,
    forNatural: true,
  },
  {
    idTipoIdentificacion: "31",
    nombre: "NIT",
    tipoIdentificacionAdquiriente: "NIT",
    isSoporte: true,
    forJuridica: true,
  },
  {
    idTipoIdentificacion: "41",
    nombre: "Pasaporte",
    tipoIdentificacionAdquiriente: "PASAPORTE",
    isSoporte: true,
    forNatural: true,
  },
  {
    idTipoIdentificacion: "42",
    nombre: "Documento de identificación extranjero",
    tipoIdentificacionAdquiriente: "DOC_ID_EXTRANJERO",
    isSoporte: true,
    forNatural: true,
    forJuridica: true,
  },
  {
    idTipoIdentificacion: "50",
    nombre: "Nit de Otro Pais",
    tipoIdentificacionAdquiriente: "NIT_OTRO_PAIS",
    isSoporte: true,
    forNatural: true,
    forJuridica: true,
  },
  {
    idTipoIdentificacion: "47",
    nombre: "PEP",
    tipoIdentificacionAdquiriente: "PEP",
    isSoporte: true,
    forNatural: true,
  },
  {
    idTipoIdentificacion: "48",
    nombre: "Permiso de proteccion temporal",
    tipoIdentificacionAdquiriente: "PPT",
  },
];

export const selectOptions = TipoIdentificacion.map(
  ({ tipoIdentificacionAdquiriente, nombre }) => ({
    label: nombre,
    value: tipoIdentificacionAdquiriente,
  })
);

export const selectOptionsNatural = TipoIdentificacion.filter(
  ({ forNatural }) => forNatural
).map(({ tipoIdentificacionAdquiriente, nombre }) => ({
  label: nombre,
  value: tipoIdentificacionAdquiriente,
}));

export const selectOptionsJuridica = TipoIdentificacion.filter(
  ({ forJuridica }) => forJuridica
).map(({ tipoIdentificacionAdquiriente, nombre }) => ({
  label: nombre,
  value: tipoIdentificacionAdquiriente,
}));

export default TipoIdentificacion;
