import * as React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

function TablaAyudas({ title, body }) {
  return (
    <ExpansionPanel style={{ width: "100%" }}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Typography variant="h4" color="primary">
          <b>{title}</b>
        </Typography>
      </ExpansionPanelSummary>
      <div
        style={{ padding: "0px 24px 0px 24px" }}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </ExpansionPanel>
  );
}

export default TablaAyudas;
