import React, { useState } from "react";
import Button from 'components/atoms/Button';
import Input from "../../../Inputs/Input";
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";
import Select from "../../../Inputs/Select";

const TIPO_LISENCIA = [
  { label: "Licencia de Materinidad o Paternidad", value: "LICENCIA_MP" },
  { label: "Licencia Remunerada del Documento", value: "LICENCIA_R" },
  { label: "Licencia No Remunerada", value: "LICENCIA_NR" },
];

export default function lisencia({ baseBody, guardarDevengado, xlCol = "2" }) {
  const [tipo, setTipo] = useState(TIPO_LISENCIA[0]);
  const [nmCantidad, setNmCantidad] = useState();
  const [nmPago, setNmPago] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `${tipo.value}`,
    nmPago,
    nmCantidad,
  };

  const guardarLisencia = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmCantidad();
    setNmPago();
  };

  return (
    <form onSubmit={guardarLisencia}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Select
            label="Seleccione el tipo licencia"
            options={TIPO_LISENCIA}
            onChange={setTipo}
            value={tipo}
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Cantidad *"
            value={nmCantidad || ""}
            onChange={(e) => setNmCantidad(parseInt(e.target.value))}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip=""
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          {tipo.value !== "LICENCIA_NR" && (
            <Input
              label="Valor del pago *"
              value={nmPago || ""}
              onChange={(e) => setNmPago(e.target.value)}
              onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
              tooltip=""
            />
          )}
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmCantidad) errores.push("La cantidad es obligatoria");
  if (!body.nmPago && body.dsTipo !== "LICENCIA_NR")
    errores.push("El valor del pago es obligatorio");
  return errores;
}
