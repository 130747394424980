import React, { useState } from "react";
import Input from "../../../Inputs/Input";
import Select from "../../../Inputs/Select";
import Button from 'components/atoms/Button';
import { TIPOS_DATOS, validarInput } from "../../../../utils/ValidarDatos";
import ModalMensaje from "components/modal/ModalMensaje";

export const TIPO_VACACIONES = [
  { label: "Vacaciones Comunes", value: "COMUN" },
  { label: "Vacaciones Compensadas", value: "COMPENSADAS" },
];

export default function vacaciones({
  baseBody,
  guardarDevengado,
  xlCol = "2",
}) {
  const [tipo, setTipo] = useState(TIPO_VACACIONES[0]);
  const [nmCantidad, setNmCantidad] = useState();
  const [nmPago, setNmPago] = useState();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const body = {
    ...baseBody,
    dsTipo: `VACACIONES_${tipo.value}`,
    nmCantidad,
    nmPago,
  };

  const guardarVacaciones = (e) => {
    e.preventDefault();
    let errores = validarFormulario(body);
    if (!errores.length) guardarDevengado(body, resetForm);
    else {
      setOpen(true);
      setModalContent(errores.join(", "));
    }
  };

  const resetForm = () => {
    setNmCantidad();
    setNmPago();
  };

  return (
    <form onSubmit={guardarVacaciones}>
      <div className="form-row col-md-12">
        <div className={`form-group col-xl-${xlCol}`}>
          <Select
            label="Seleccione el tipo de vacaciones"
            options={TIPO_VACACIONES}
            value={tipo}
            onChange={(e) => setTipo(e)}
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Cantidad *"
            id="cantidad"
            value={nmCantidad || ""}
            onChange={(e) => setNmCantidad(parseInt(e.target.value))}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Número de días que el trabajador estuvo inactivo durante el mes por vacaciones."
          />
        </div>
        <div className={`form-group col-xl-${xlCol}`}>
          <Input
            label="Valor del pago *"
            id="pago"
            value={nmPago || ""}
            onChange={(e) => setNmPago(e.target.value)}
            onKeyPress={(e) => validarInput(e, TIPOS_DATOS.decimales)}
            tooltip="Corresponde al valor pagado al trabajador, por el descanso remunerado que tiene derecho por haber trabajado un determinado tiempo. (Vacaciones SI disfrutadas)"
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-xl-2">
          <Button type="submit" disabled={!baseBody.empleado}>
            Agregar
          </Button>
        </div>
      </div>
      <ModalMensaje
        content={modalContent}
        handleClose={() => setOpen(false)}
        open={isOpen}
      />
    </form>
  );
}

function validarFormulario(body) {
  let errores = [];
  if (!body.nmCantidad) errores.push("La cantidad es obligatoria");
  if (!body.nmPago) errores.push("El valor del pago es obligatorio");
  return errores;
}
