const preguntasFrecuentes = [
  {
    id: 1,
    pregunta:
      "¿Qué responsabilidades deben tenerse en el RUT para facturar electrónicamente o como proveedor tecnológico?",
    respuesta:
      "Debe tener la responsabilidad 52, esta actualización la hace de manera automática la DIAN una vez se hayan superado las pruebas, es importante tener en cuenta que, si por algún motivo la DIAN no actualiza al RUT con dicha responsabilidad, esto no será un impedimento para empezar a facturar electrónicamente.",
  },
  {
    id: 2,
    pregunta:
      "¿Un obligado a facturar electrónicamente puede expedir documentos equivalentes a la factura? ",
    respuesta:
      "Un obligado a facturar, que lo haga electrónicamente, podrá utilizar los documentos equivalentes a la factura que la normativa le autoriza para sus ventas, siempre y cuando tenga la respectiva autorización.",
  },
  {
    id: 3,
    pregunta: "¿Cómo anulo una factura de venta?",
    respuesta:
      "La única manera de anular una factura que ya fue validada por la DIAN es generando una nota crédito",
  },
  {
    id: 4,
    pregunta:
      "¿En qué casos debo utilizar notas débito y/o crédito electrónicas?",
    respuesta:
      " Las notas electrónicas se pueden utilizar en los siguientes casos",
    lista: [
      {
        id: 1,
        text:
          "NOTAS CRÉDITO: Devolución parcial de los bienes y/o no aceptación parcial del servicio, Anulación de factura electrónica, Rebaja o descuento parcial o total, Ajuste de precio, Entre otros.",
      },
      {
        id: 2,
        text:
          "NOTAS DÉBITO: Intereses, Gastos por cobrar, Cambio del valor, Entre otros",
      },
    ],
  },
  {
    id: 5,
    pregunta:
      "¿Puedo aplicar una nota a una factura que fue rechazada por la DIAN?",
    respuesta:
      "No se puede aplicar, ya que la factura no fue correctamente validada, por lo tanto, no tiene validez fiscal.",
  },
  {
    id: 6,
    pregunta: "¿Qué es el Código Único de Facturación Electrónica (CUFE)?",
    respuesta:
      "Es un mecanismo técnico de control que se debe incorporar a la factura electrónica, que lo constituye en un valor alfanumérico obtenido al utilizar los datos de la factura.",
  },
  {
    id: 7,
    pregunta: "¿Qué es el acuse de recibo de la Factura electrónica?",
    respuesta:
      "Es el mensaje de datos a través del cual un adquirente a quien le fue facturado de forma electrónica manifiesta que la factura fue recibida por sus sistemas de información. El adquirente puede hacerlo a través de sus propios sistemas de información o a través de los medios que disponga para ello el facturador electrónico.",
  },
  {
    id: 8,
    pregunta:
      "¿Cómo podrán facturar los obligados a facturar que tengan inconvenientes técnicos para hacerlo?",
    respuesta: "",
    lista: [
      {
        id: 1,
        text:
          "Generar la facturación con numeración de contingencia en papel o por computador.",
      },
      {
        id: 2,
        text:
          "El facturador tendrá 30 días a partir de la fecha en que supera la contingencia para enviar las facturas electrónicas en formato XML a la DIAN.",
      },
      {
        id: 3,
        text:
          "Para los documentos de notas crédito y notas débito, el facturador NO debe usar la numeración de contingencia, sino el consecutivo propio normal.Las notas enviadas a la DIAN se validarán de manera normal en la DIAN. Por lo anterior, no existe el concepto de nota en contingencia, es decir las validaciones de las notas son las establecidas en el Anexo técnico vigente y se recomienda generarlas en un ambiente de operación normal.",
      },
      {
        id: 4,
        text:
          "Una vez el facturador electrónico salga de contingencia deberá proceder al proceso de generación normal.",
      },
    ],
  },
  {
    id: 9,
    pregunta:
      "¿La numeración autorizada por la DIAN para las facturas electrónicas tiene algún periodo de validez?",
    respuesta:
      "Los rangos de numeración que autoriza la DIAN tienen diferentes periodos de vigencia que van desde 6 meses hasta 2 años de acuerdo con lo establecido en la Resolución 55 de 2016.",
  },
];

export default preguntasFrecuentes;
