import * as React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import FormularioConsulta from './FormularioConsulta';
import { SetConfirmacionModalState, SetSpinnerModal } from 'reducers/actions/facturaActions';
import { consultarProductosPorEmisorYDescripcion as consultar } from 'network/Api/Producto';
import useModal from 'hooks/useModal';
import TablaProductos from './TablaProductos';
import ModalAddProducto from './ModalAddProducto';
import ModalUpdateProducto from './ModalUpdateProducto';
import ConfirmacionModal from 'components/modals/confirmacionModal';
const { useState, useCallback, useEffect } = React;

function Productos({ userAttributes, setSpinnerModal, setConfirmacionModalState }) {
  const [productos, setProductos] = useState([]);
  const [filter, setFilter] = useState('');
  const [modalNuevo, openNuevo, closeModalNuevo] = useModal();
  const [modalAcualizar, openAcualizar, closeModalAcualizar] = useModal();
  const [producto, setProducto] = useState({});

  const consultarProductos = useCallback(
    async (filtro) => {
      const { codigoEmisor: idEmisor } = userAttributes;
      if (idEmisor) {
        setSpinnerModal(true);
        const { data } = await consultar({ idEmisor, filtro });
        setProductos(data);
        setSpinnerModal(false);
      }
    },
    [userAttributes],
  );

  useEffect(() => {
    consultarProductos('');
  }, [userAttributes]);

  const openEditar = useCallback((producto) => {
    setProducto(producto);
    openAcualizar({});
  }, []);

  const openNuevoProducto = useCallback(() => openNuevo({}), []);

  return (
    <>
      <Grid container spacing={8}>
        <FormularioConsulta
          filter={filter}
          setFilter={setFilter}
          onOpenAgregar={openNuevoProducto}
          onConsulta={consultarProductos}
        />
        <Grid item xs={12}>
          <TablaProductos
            productos={productos}
            filter={filter}
            onConsulta={consultarProductos}
            onOpenUpdate={openEditar}
          />
        </Grid>
        <ModalAddProducto
          onConsulta={consultarProductos}
          onClose={closeModalNuevo}
          userAttributes={userAttributes}
          setSpinnerModal={setSpinnerModal}
          onOpenModal={setConfirmacionModalState}
          filter={filter}
          {...modalNuevo}
        />
        <ModalUpdateProducto
          onConsulta={consultarProductos}
          onClose={closeModalAcualizar}
          productoUpdate={producto}
          userAttributes={userAttributes}
          setSpinnerModal={setSpinnerModal}
          onOpenModal={setConfirmacionModalState}
          filter={filter}
          {...modalAcualizar}
        />
      </Grid>
      <ConfirmacionModal />
    </>
  );
}
const mapStateToProps = (state, props) => ({
  loading: state.factura.loading,
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Productos);
