import * as React from "react";
import { connect } from "react-redux";
import FirmasTable from "components/ServicioCliente/GestionFirmaDigital/FirmasTable";
import { SetSpinnerModal } from "reducers/actions/facturaActions";
import { consultarPorEmisor } from "network/Api/CertFirma";

function GestionarFirmaDigitalEmisor({ userAttributes, setSpinnerModal }) {
  const [listFirmas, setListFirmas] = React.useState(null);

  React.useEffect(() => {
    if (userAttributes.codigoEmisor) handleConsultarFirmas();
  }, [userAttributes]);

  const handleConsultarFirmas = () => {
    setSpinnerModal(true);
    consultarPorEmisor({ nmIdEmisor: userAttributes.codigoEmisor }).then(
      (result) => {
        if (result.data) {
          setListFirmas(
            result.data.map((r) => ({
              ...r,
              urlFotocopiaCedula: r.dsFotocopiaCedula,
              urlModeloConfianza: r.dsUrlModeloConfianza,
              urlCartaNotariadaDocCamComercio: r.dsUrlCartaNotariadaCamComercio,
            }))
          );
        }
        setSpinnerModal(false);
      }
    );
  };

  return (
    <div>
      {userAttributes && (
        <FirmasTable
          firmas={listFirmas}
          isEmisor={true}
          handleConsultarFirmas={handleConsultarFirmas}
          nitEmisor={userAttributes.identificacionEmisor}
          emisorId={userAttributes.codigoEmisor}
          urlRutEmisor={userAttributes.urlRut}
        />
      )}
    </div>
  );
}

function mapStateToProps(state, props) {
  return { userAttributes: state.factura.userAttributes };
}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionarFirmaDigitalEmisor);
