export default [
  {
    label: "Dependiente",
    value: "DEPENDIENTE",
    codigoDian: "01",
  },
  {
    label: "Servicio Domestico",
    value: "SERVICIO_DOMESTICO",
    codigoDian: "02",
  },
  {
    label: "Madre Comunitaria",
    value: "MADRE_COMUNITARIA",
    codigoDian: "04",
  },
  {
    label: "Aprendiz del SENA etapa lectiva",
    value: "APRENDIZ_SENA_LECTIVA",
    codigoDian: "12",
  },
  {
    label: "Funcionario público sin tope máximo de ibc",
    value: "FUNCIONARIO_PUBLICO",
    codigoDian: "18",
  },
  {
    label: "Aprendiz del SENA en etapa productiva",
    value: "APRENDIZ_SENA_PRODUCTIVA",
    codigoDian: "19",
  },
  {
    label: "Estudiantes de postgrado en salud",
    value: "ESTUDIANTE_SALUD",
    codigoDian: "21",
  },
  {
    label: "Profesor de establecimiento particular",
    value: "PROFESOR_PARTICULAR",
    codigoDian: "22",
  },
  {
    label: "Estudiante aportes solo riesgos laborales",
    value: "ESTUDIANTE",
    codigoDian: "23",
  },
  {
    label:
      "Dependiente entidades o universidades públicas con régimen especial en salud",
    value: "DEPENDIENTE_REGIMEN_SALUD",
    codigoDian: "30",
  },
  {
    label: "Cooperados o pre cooperativas de trabajo asociado",
    value: "COOPERADOS_PRE_COOPERATIVAS",
    codigoDian: "31",
  },
  {
    label:
      "Trabajador dependiente de entidad beneficiaria del sistema general de participaciones ‐ aportes patronales",
    value: "APORTES_PATRONALES",
    codigoDian: "47",
  },
  {
    label: "Trabajador de tiempo parcial",
    value: "TIEMPO_PARCIAL",
    codigoDian: "51",
  },
  {
    label: "Pre pensionado de entidad en liquidación",
    value: "PRE_PENSIONADO_LIQUIDACION",
    codigoDian: "54",
  },
  {
    label: "Pre pensionado con aporte voluntario a salud",
    value: "PRE_PENSIONADO_VOLUNTARIO_SALUD",
    codigoDian: "56",
  },
  {
    label: "Estudiante de prácticas laborales en el sector público",
    value: "ESTUDIANTE_PRACTICAS_PUBLICO",
    codigoDian: "58",
  },
];
