import { serviceRoutes, post } from "./index";

export const iniciarSiguienteTurno = async (body) =>
  await post(serviceRoutes.iniciarSiguienteTurno, body);

export const finalizarTruno = async (body) =>
  await post(serviceRoutes.finalizarTruno, body);

export const solicitarTurno = async (body) =>
  await post(serviceRoutes.solicitarTurno, body);
