export const codigosRechazo = [
  {
    label: "Documento con inconsistencias",
    value: "DOCUMENTO_CON_INCONSISTENCIAS",
  },
  {
    label: "Mecancia no entregada totalmente",
    value: "MERCANCIA_NO_ENTREGADA_TOTALMENTE",
  },
  {
    label: "Mercancia no entregada parcialmente",
    value: "MERCANCIA_NO_ENTREGADA_PARCIALMENTE",
  },
  {
    label: "Servicio no prestado",
    value: "SERVICIO_NO_PRESTADO",
  },
];
