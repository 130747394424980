import * as React from 'react';
import Select from 'react-select';
import Input from 'components/Inputs/Input';
import { calcularDigitoVerificacion } from 'utils/funcionesUtiles';

const TIPOS_OPERACION = [
  { label: 'Estandar', value: 'ESTANDAR' },
  { label: 'Mandato', value: 'MANDATOS' },
  { label: 'Servicios AIU', value: 'AIU' },
];

const TIPOS_INGRESOS = [
  { label: 'Ingreso propio', value: '0' },
  { label: 'Ingresos recibidos para terceros', value: '1' },
];

const TIPOS_IDENTIDFICACION = [
  { label: 'Cedula Ciudadania', value: '13' },
  { label: 'NIT', value: '31' },
];

const DatosTipoOperacion = ({ state, setState, handleChange }) => {
  return (
    <>
      <div className='form-group col-xl-4'>
        <label className='titulos_label'>Tipo Operación</label>
        <Select
          options={TIPOS_OPERACION}
          value={TIPOS_OPERACION.find(({ value }) => value === state.tipoOperacion)}
          onChange={({ value }) => {
            const isMandato = value === 'MANDATOS';
            const isAIU = value === 'AIU';
            setState({
              tipoOperacion: value,
              tipoIngreso: isMandato ? '0' : null,
              mandanteTipoIngreso: isMandato ? state.mandanteTipoIngreso || '0' : null,
              mandanteIdentificacion: isMandato ? state.mandanteIdentificacion || '' : null,
              mandanteDigitoVerificacion: isMandato ? state.mandanteDigitoVerificacion || '' : null,
              mandanteNombre: isMandato ? state.mandanteNombre || '' : null,
              mandanteApellido: isMandato ? state.mandanteApellido || '' : null,
              mandanteTipoIdentifacion: isMandato ? state.mandanteTipoIdentifacion || '' : null,
              nota: isAIU ? state.nota : null,
            });
          }}
        />
      </div>
      {state.tipoOperacion === 'MANDATOS' && (
        <>
          <div className='form-group col-xl-3'>
            <label className='titulos_label'>Tipo Ingreso</label>
            <Select
              options={TIPOS_INGRESOS}
              value={TIPOS_INGRESOS.find(({ value }) => value === state.mandanteTipoIngreso)}
              onChange={({ value }) => setState({ mandanteTipoIngreso: value, tipoIngreso: value })}
            />
          </div>
          <div className='form-group col-xl-3'>
            <Input
              label='Identificación mandante'
              classLabel='titulos_label'
              id='mandanteIdentificacion'
              onChange={(e) => {
                handleChange(e);
                setState({
                  mandanteDigitoVerificacion: calcularDigitoVerificacion(e.target.value),
                });
              }}
              value={state.mandanteIdentificacion}
            />
          </div>
          <div className='form-group col-xl-2'>
            <Input
              label='Digito verif mandante'
              classLabel='titulos_label'
              id='mandanteDigitoVerificacion'
              value={state.mandanteDigitoVerificacion}
              disabled
            />
          </div>
          <div className='form-group col-xl-4'>
            <Input
              label='Nombre mandante'
              classLabel='titulos_label'
              id='mandanteNombre'
              onChange={handleChange}
              value={state.mandanteNombre}
            />
          </div>
          <div className='form-group col-xl-4'>
            <Input
              label='Apellido mandante'
              classLabel='titulos_label'
              id='mandanteApellido'
              onChange={handleChange}
              value={state.mandanteApellido}
            />
          </div>
          <div className='form-group col-xl-4'>
            <label className='titulos_label'>Tipo identificación mandante</label>
            <Select
              options={TIPOS_IDENTIDFICACION}
              value={TIPOS_IDENTIDFICACION.find(
                ({ value }) => value === state.mandanteTipoIdentifacion,
              )}
              onChange={({ value }) => setState({ mandanteTipoIdentifacion: value })}
            />
          </div>
        </>
      )}
      {state.tipoOperacion === 'AIU' && (
        <>
          <div className='form-group col-xl-8'>
            <Input
              label='Contrato de servicios AIU por concepto de'
              id='nota'
              classLabel='titulos_label'
              onChange={handleChange}
              value={state.nota}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DatosTipoOperacion;
