import * as React from "react";
import Select from "components/Inputs/Select";

const SelectResolucion = ({
  resoluciones = [],
  onChange,
  name,
  value,
  setDocumento,
  ...rest
}) => {
  const filterResoluciones = () =>
    resoluciones.map(({ dsResolucionDian }) => ({
      value: dsResolucionDian,
      label: dsResolucionDian,
    }));

  return (
    <Select
      options={filterResoluciones()}
      onChange={(event) => {
        setDocumento({ dsPrefijo: "" });
        onChange({ name, ...event });
      }}
      value={filterResoluciones().filter((r) => (r.value === value))}
      name={name}
      {...rest}
    />
  );
};

export default SelectResolucion;
