import React from 'react';

const rowsPerPageOptions = [5, 10, 25, 50, 100];

const useTable = ({ detalles, withPagination }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const onChangeRowsPerPage = ({ target }) => setRowsPerPage(target.value);

  const onChangePage = (_, page) => setPage(page);

  const getDetalles = () => {
    if (withPagination) {
      const inicio = page * rowsPerPage;
      return detalles.slice(inicio, inicio + rowsPerPage);
    }
    return detalles;
  };

  return {
    count: detalles.length,
    onChangeRowsPerPage,
    rowsPerPageOptions,
    onChangePage,
    rowsPerPage,
    getDetalles,
    page,
  };
};

export default useTable;
