import React, { useEffect, useState } from "react";
import Input from "components/Inputs/Input";
import SelectTipoIdentificacion from "components/Inputs/SelectTipoIdentificacion";
import { TIPOS_DATOS, validarInput } from "utils/ValidarDatos";

function DatosSalud({ msUsuarioProps = {}, setMsUsuarios }) {
  const [msUsuario, setMsUsuario] = useState(msUsuarioProps);

  useEffect(() => setMsUsuarios(msUsuario), [msUsuario]);

  const handleChange = (event) => {
    const label = event.target.name;
    const value = event.target.value;
    setMsUsuario((s) => ({ ...s, [label]: value }));
  };

  const handleChangeTipoIdentificacion = (event) =>
    setMsUsuario((s) => ({ ...s, msTipoDocID: event.value }));

  return (
    <>
      <div className="form-row col-md-12">
        <div className="form-group col-md-12 col-xl-2">
          <SelectTipoIdentificacion
            label="Tipo de Identificacion"
            name="msTipoDocID"
            onChange={handleChangeTipoIdentificacion}
            isDisabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Identificacion"
            name="msDocID"
            onChange={handleChange}
            value={msUsuario.msDocID || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Primer Nombre"
            name="msPrimerNombre"
            onChange={handleChange}
            value={msUsuario.msPrimerNombre || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Segundo Nombre"
            name="msSegundoNombre"
            onChange={handleChange}
            value={msUsuario.msSegundoNombre || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Primer apellido"
            name="msPrimerApellido"
            onChange={handleChange}
            value={msUsuario.msPrimerApellido || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Segundo apellido"
            name="msSegundoApellido"
            onChange={handleChange}
            value={msUsuario.msSegundoApellido || ""}
            disabled={!setMsUsuarios}
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-md-12 col-xl-3">
          <Input
            label="Numero Autorizacion"
            name="msNumeroAutorizacion"
            onChange={handleChange}
            value={msUsuario.msNumeroAutorizacion || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-3">
          <Input
            label="Numero MIPRES"
            name="msNumeroMIPRES"
            onChange={handleChange}
            value={msUsuario.msNumeroMIPRES || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Numero IDMIPRES"
            name="msNumeroIDMIPRES"
            onChange={handleChange}
            value={msUsuario.msNumeroIDMIPRES || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Numero Contrato"
            name="msNumeroContrato"
            onChange={handleChange}
            value={msUsuario.msNumeroContrato || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Numero Polisa"
            name="msNumeroPoliza"
            onChange={handleChange}
            value={msUsuario.msNumeroPoliza || ""}
            disabled={!setMsUsuarios}
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Copago"
            name="msCopago"
            onChange={handleChange}
            onKeyPress={(event) => validarInput(event, TIPOS_DATOS.decimales)}
            value={msUsuario.msCopago || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Cuota moderadora"
            name="msCuotaModeradora"
            onChange={handleChange}
            onKeyPress={(event) => validarInput(event, TIPOS_DATOS.decimales)}
            value={msUsuario.msCuotaModeradora || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Cuota recuperacion"
            name="msCuotaRecuperacion"
            onChange={handleChange}
            onKeyPress={(event) => validarInput(event, TIPOS_DATOS.decimales)}
            value={msUsuario.msCuotaRecuperacion || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Pagos voluntarios salud"
            name="msPagosVoluntariosSalud"
            onChange={handleChange}
            onKeyPress={(event) => validarInput(event, TIPOS_DATOS.decimales)}
            value={msUsuario.msPagosVoluntariosSalud || ""}
            disabled={!setMsUsuarios}
          />
        </div>

        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Fecha inicio servicio"
            name="msFechaInicio"
            onChange={handleChange}
            type="date"
            value={msUsuario.msFechaInicio || ""}
            disabled={!setMsUsuarios}
          />
        </div>
        <div className="form-group col-md-12 col-xl-2">
          <Input
            label="Fecha fin servicio"
            name="msFechaFin"
            onChange={handleChange}
            type="date"
            value={msUsuario.msFechaFin || ""}
            disabled={!setMsUsuarios}
          />
        </div>
      </div>
    </>
  );
}

export default DatosSalud;
