import * as React from "react";
import ProductosIcon from "@material-ui/icons/Assignment";
import AdquirentesIcon from "@material-ui/icons/PersonAdd";

export const productos = {
  title: "Crear y editar productos y/o servicios",
  icon: <ProductosIcon />,
  pathname: "/productos",
};

export const adquirentes = {
  title: "Crear y editar clientes",
  icon: <AdquirentesIcon />,
  pathname: "/adquirentes",
};
