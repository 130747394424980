import React from 'react';
import {
  getTotalDescuento,
  getTributoDetalle,
  getTotalImpuestosRetenciones,
  getTotalTributo,
} from 'utils/DocumentoUtils';

const useDetalles = (isExportacion) => {
  const [detalle, setData] = React.useState({});
  const [errores, setErrores] = React.useState({});

  const setDetalle = (data) => setData((detalle) => ({ ...detalle, ...data }));

  const reset = () => setData({});

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setDetalle({ [name]: value });
  };

  const handleChangeObsequio = ({ target }) => {
    const { checked } = target;
    const { precioUnitario, precioReferencia } = detalle;
    const dataReplace = checked
      ? { precioUnitario: 0, precioReferencia: precioUnitario }
      : {
          precioUnitario: precioReferencia,
          precioReferencia: undefined,
          tipoPrecioReferencia: undefined,
        };
    setDetalle({ esObsequio: checked, ...dataReplace });
  };

  const handleChangeDiaSinIva = ({ target }) => {
    const { checked } = target;
    const { porcentajeIva, ivaReplazado } = detalle;
    const dataReplace = checked
      ? { porcentajeIva: undefined, ivaReplazado: porcentajeIva }
      : { porcentajeIva: ivaReplazado, ivaReplazado: undefined };
    setDetalle({
      esSinIva: checked,
      Dia_Sin_IVA: checked ? 'Bienes Cubiertos' : undefined,
      ...dataReplace,
    });
  };

  const addCargoDescuento = (nuevoCargoDescuento) => {
    const { cargosDescuentos = [] } = detalle;
    setDetalle({
      cargosDescuentos: [
        ...cargosDescuentos,
        { ...nuevoCargoDescuento, id: cargosDescuentos.length },
      ],
    });
  };

  const removeCargoDescuento = (index) => {
    const { cargosDescuentos = [] } = detalle;
    setDetalle({
      cargosDescuentos: cargosDescuentos
        .filter(({ id }) => id !== index)
        .map((cd, id) => ({ ...cd, id })),
    });
  };

  const getDetalleFinal = () => {
    const {
      cantidad,
      precioUnitario,
      cargosDescuentos = [],
      porcentajeIva,
      porcentajeConsumo,
      otroImpuesto,
      otroImpuestoValor,
      otroImpuestoVolumen: volumen,
      precioReferencia,
      tipoPrecioReferencia,
      esObsequio,
      otroImpuestoPorcentaje,
    } = detalle;
    const valorTotalBruto = cantidad * precioUnitario;
    const descuentoTotal = getTotalDescuento(cargosDescuentos);
    const valorBase = valorTotalBruto - descuentoTotal;
    const impuestosRetenciones = getArrayImpuestos();
    const valorTotalImpuestosRetenciones = getTotalImpuestosRetenciones(impuestosRetenciones);
    const valorTotalArticulo = valorTotalBruto - descuentoTotal + valorTotalImpuestosRetenciones;
    return {
      ...detalle,
      valorTotalBruto,
      valorTotalDescuento: descuentoTotal,
      impuestosRetenciones,
      valorTotalImpuestosRetenciones,
      // TODO: Validar en donde se utiliza este campo y remplazarlo con "valorTotalBruto"
      valorBrutoItem: valorTotalBruto,
      cargoTotal: 0,
      descuentoTotal,
      valorTotalArticulo: valorTotalBruto, // Este campo se utiliza para la representacion grafica de FireProtection en el valor total del detalle
      cargosDescuentos,
      // TODO: Validar en donde se utiliza este campo y remplazarlo con "valorTotalArticulo"
      valorTotalArticuloDetalle: valorTotalArticulo,
      obsequio: esObsequio ? [{ precioReferencia, tipoPrecioReferencia }] : undefined,
      // TODO: Validar en donde se utiliza este campo y valorar si es posible eliminarlo (No es un campo de domina)
      valorIva: getTotalTributo(impuestosRetenciones, 'IVA'),
      // TODO: Validar en donde se utiliza este campo y valorar si es posible eliminarlo (No es un campo de domina)
      impuestoConsumo: getTotalTributo(impuestosRetenciones, 'INC'),
      valorBase: impuestosRetenciones.length > 0 ? valorBase : 0,
      otroImpuestoTotal: ['ICL', 'INPP', 'IBUA', 'ICUI'].reduce((total, tributo) => {
        return total + getTotalTributo(impuestosRetenciones, tributo);
      }, 0),
    };

    function getArrayImpuestos() {
      const validacion = (porcentaje) => porcentaje !== null && porcentaje !== undefined;
      return [
        validacion(porcentajeIva) &&
          getTributoDetalle({ tributo: 'IVA', porcentaje: porcentajeIva, valorBase }),
        porcentajeConsumo > 0 &&
          getTributoDetalle({ tributo: 'INC', porcentaje: porcentajeConsumo, valorBase }),
        validacion(otroImpuesto) &&
          getTributoDetalle({
            porcentaje: otroImpuestoPorcentaje,
            tributo: otroImpuesto,
            valorUnitario: otroImpuestoValor,
            unidadMedida: 'UNIDAD',
            volumen,
            valorBase,
          }),
      ].filter((ir) => Boolean(ir));
    }
  };

  const validarCampos = () => {
    const finalDetalle = getDetalleFinal();
    const {
      precioReferencia,
      esObsequio,
      tipoPrecioReferencia,
      precioUnitario,
      codigoArticulo,
      descripcionArticulo = '',
      cantidad,
      unidadMedida,
      nombreMarca,
      nombreModelo,
      estandarProducto,
      otroImpuesto,
      otroImpuestoValor,
      otroImpuestoVolumen,
      otroImpuestoPorcentaje,
    } = finalDetalle;
    let errors = {};
    if (Boolean(otroImpuesto)) {
      if (otroImpuesto === 'ICUI') {
        if (!otroImpuestoPorcentaje)
          errors['otroImpuestoPorcentaje'] = 'El porcentaje es obligatorio';
        if (!(parseFloat(otroImpuestoPorcentaje) >= 0 && parseFloat(otroImpuestoPorcentaje) <= 100))
          errors['otroImpuestoPorcentaje'] = 'El porcentaje no puede ser superior a 100';
      } else {
        if (!otroImpuestoValor)
          errors['otroImpuestoValor'] = 'El valor es obligatorio o debe ser mayor a cero';
        if (!otroImpuestoVolumen)
          errors['otroImpuestoVolumen'] = 'El volumen es obligatorio o debe ser mayor a cero';
      }
    }
    if (esObsequio) {
      if (!precioReferencia)
        errors['precioReferencia'] = 'El precio referencia no puede ser cero o vacío';
      if (!tipoPrecioReferencia)
        errors['tipoPrecioReferencia'] = 'Seleccione un tipo precio referencia';
    }
    if ((!esObsequio && parseInt(precioUnitario) <= 0) || (!esObsequio && !precioUnitario))
      errors['precioUnitario'] = 'El valor debe ser mayor a cero';
    if (!codigoArticulo) errors['codigoArticulo'] = 'Es obligatorio seleccionar un articulo';
    if (!(descripcionArticulo.length >= 5 && descripcionArticulo.length <= 200))
      errors['descripcionArticulo'] =
        'La descripción no puede estar vacía y debe tener entre 5 y 200 caracterés';
    if (!cantidad) errors['cantidad'] = 'La cantidad debe ser superior a cero';
    if (!unidadMedida) errors['unidadMedida'] = 'La unidad de medida es obligatoria';
    if (isExportacion) {
      if (!nombreMarca) errors['nombreMarca'] = 'La marca del producto es obligatoria';
      if (!nombreModelo) errors['nombreModelo'] = 'El módelo del producto es obligatorio';
      if (!estandarProducto) errors['estandarProducto'] = 'El estandar del producto es obligatorio';
    }
    setErrores(errors);
    return errors;
  };

  const handleChangePrecioUnitario = (nmValorUnitario, porcentajeIva, snIvaIncluido) => {
    const nmIva = (100 + parseInt(porcentajeIva)) / 100;
    setDetalle({
      precioUnitario: snIvaIncluido
        ? (parseFloat(nmValorUnitario) / nmIva).toFixed(2)
        : nmValorUnitario,
      valorMasIva: snIvaIncluido
        ? nmValorUnitario
        : (parseFloat(nmValorUnitario) * nmIva).toFixed(2),
    });
  };

  return {
    reset,
    detalle,
    errores,
    setDetalle,
    validarCampos,
    getDetalleFinal,
    handleChangeInput,
    addCargoDescuento,
    removeCargoDescuento,
    handleChangeObsequio,
    handleChangeDiaSinIva,
    handleChangePrecioUnitario,
  };
};

export default useDetalles;
